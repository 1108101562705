@use 'sass:math';

#user-page {
  a {
    text-decoration: none !important;
  }

  .tasks-table {
    $s: 40px;
    $gap: 10px;

    border-spacing: 5px;
    border-collapse: separate;

    td {
      padding: 0;
    }

    color: white;

    .section {
      font-size: $s * 0.6;
      @media (min-width: 500px) {
        font-size: $s;
      }
      padding-left: $gap;
    }
    .dow {
      text-align: center;
      font-size: $s * 0.4;
      padding: 0;
    }

    .task {
      font-size: $s * 0.6;
      height: $s;
      line-height: $s;
      white-space: nowrap;

      .task-name {
        height: $s;
        width: 70px;
        overflow: visible;
        padding-left: $gap;

        p {
          margin: 0;
        }

        img {
          max-width: 32px;
          max-height: 32px;
        }
      }

      .day {
        font-size: $s * 0.4;
        font-weight: bold;
        text-align: center;
        width: $s;
        overflow: hidden;

        &.empty,
        .today {
          background-color: #ffdfae;
        }

        &.empty {
          &.skip {
            background-color: transparent;
          }
        }

        $good-color: #24cc8f;
        $bad-color: #db2955;

        .up,
        .down {
          height: 100%;
        }
        .up {
          background-color: $good-color;
        }
        .down {
          background-color: $bad-color;
        }

        .today,
        .completed,
        .is-due {
          height: 100%;
        }
        .completed {
          background-color: $good-color;
        }
        .is-due {
          background-color: $bad-color;
        }
      }
    }
  }
}

$todo-table-h: 115px;
$header-h: 100px; // same as avatar size
#user-page {
  .header {
    display: flex;

    .back {
      height: $header-h;
      line-height: $header-h;
      width: $header-h;
      font-size: $header-h * 0.7;
      text-align: center;
      vertical-align: middle;
    }
  }
}

#user-page {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'table-todo'
    'table-habit'
    'table-daily';
  grid-template-columns: auto;
  grid-template-rows: math.max($header-h, $todo-table-h) auto auto auto;
  @media (min-width: 1000px) {
    grid-template-areas:
      'header table-todo'
      'table-habit table-daily';
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-template-rows: math.max($header-h, $todo-table-h) auto;
    column-gap: 20px;
    row-gap: 20px;
  }

  // fix scroll
  margin-bottom: 90px;

  .header {
    grid-area: header;
    align-items: center;
  }

  & > .table-daily {
    grid-area: table-daily;
    overflow: hidden;
  }
  & > .table-habit {
    grid-area: table-habit;
    overflow: hidden;
  }
  & > .table-todo {
    grid-area: table-todo;
    overflow: hidden;
  }
}
