@import '../../habitica/external/assets/scss/colors.scss';

$night-pic-size: 70px;
$night-dow-h: 30px;
$night-s: $night-pic-size + $night-dow-h;
$night-sidebar-gap: 20px;
$quest-s: 120px;

#overview {
  display: grid;
  grid-template-areas:
    'nights'
    'quest'
    'lock'
    'user-0'
    'user-1'
    'user-2'
    'user-3';
  grid-template-columns: auto;
  grid-template-rows: $night-s * 2 $quest-s auto auto auto auto;
  @media (min-width: ($night-pic-size * 7)) {
    grid-template-rows: $night-s $quest-s auto auto auto auto;
  }
  @media (min-width: 1000px) {
    grid-template-areas:
      'nights user-0 user-1 quest'
      'nights user-2 user-3 lock';
    grid-template-columns: $night-s + $night-sidebar-gap auto auto $quest-s + $night-sidebar-gap;
    grid-template-rows: auto auto;

    .nights {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .night {
        position: relative;

        .day-of-the-week {
          transform-origin: 0 0;
          transform: rotate(-90deg) translateX(-$night-pic-size);
          position: absolute;
          top: 0;
          left: 0;
        }
        .pic {
          margin-left: $night-dow-h;
        }
      }
    }
  }

  // fix scroll
  margin-bottom: 90px;

  & > .nights {
    grid-area: nights;
  }
  & > .quest {
    grid-area: quest;
  }
  & > .lock {
    grid-area: lock;
  }

  & > .user-0 {
    grid-area: user-0;
  }
  & > .user-1 {
    grid-area: user-1;
  }
  & > .user-2 {
    grid-area: user-2;
  }
  & > .user-3 {
    grid-area: user-3;
  }

  .nights {
    .night {
      display: inline-block;
      .day-of-the-week {
        width: $night-pic-size;
        text-align: center;
        line-height: $night-dow-h;
        vertical-align: middle;
      }
      .pic {
        width: $night-pic-size;
        height: $night-pic-size;
        object-fit: cover;
      }

      color: white;
      &.previous-week {
        color: grey;

        .pic {
          opacity: 0.3;
        }
      }
    }
  }

  .user-panel {
    padding: 5px;
    min-height: 200px;

    $icon-s: 32px;
    $badge-s: $icon-s * 0.6;

    .tasks {
      vertical-align: baseline;
      margin: 10px 0;
    }

    .task-icon-container {
      display: inline-block;
      height: $icon-s;
      width: $icon-s;

      margin: 5px;

      position: relative;

      .badge {
        height: $badge-s;
        width: $badge-s;
        position: absolute;
        right: -$badge-s * 0.3;
        top: -$badge-s * 0.3;

        &.pending {
          background: url('../../assets/blue_circle_lock_privacy_safe_icon.svg');
        }
        &.completed {
          background: url('../../assets/approved_check_checkbox_checkmark_confirm_icon.svg');
        }
        &.down {
          background: url('../../assets/cancel_cercle_close_delete_dismiss_icon.svg');
        }
      }
    }

    .task-icon {
      height: $icon-s;
      width: $icon-s;
      line-height: $icon-s;
      overflow: hidden;

      &.img {
        vertical-align: baseline;
      }
      &.emoji {
        font-size: $icon-s * 0.8;
        text-align: center;
      }
      &.letter {
        font-size: $icon-s;
        font-weight: bold;
        color: white;
        text-align: center;
      }
    }
  }

  .quest {
    $name-h: 2em;

    display: grid;
    grid-template-areas:
      'boss-pic boss-name'
      'boss-pic boss-hp';
    grid-template-columns: $quest-s auto;
    grid-template-rows: $name-h auto;

    .boss {
      grid-area: boss-pic;
      background-size: contain;
      background-repeat: no-repeat;
      width: $quest-s;
      height: $quest-s;
    }

    .boss-name {
      grid-area: boss-name;
      text-align: center;
      vertical-align: middle;
      line-height: $name-h;
    }

    .stats-bar {
      $pb-h: 10px;
      .label {
        display: inline-block;
        width: 30px;
      }

      .progress {
        display: inline-block;
        height: $pb-h;
        width: 100%;

        .progress-bar {
          height: $pb-h;
        }
      }
      .value {
        display: block;
        text-align: center;
      }
    }

    .boss-health {
      grid-area: boss-hp;
      .stats-bar {
        .progress {
          background: $yellow-50;
          .progress-bar {
            background: $red-50;
          }
        }
      }
    }

    .quest-collect {
      display: flex;

      .stats-bar {
        flex: 1;
        .progress {
          background: $gray-50;
          .progress-bar {
            background: $green-100;
          }
        }
      }
      .quest-item-icon {
        width: 32px;
        height: 32px;
        background-size: cover;
        margin-right: 10px;
      }
    }

    @media (min-width: 1000px) {
      padding-left: $night-sidebar-gap;

      grid-template-areas:
        'boss-pic'
        'boss-name'
        'boss-hp';
      grid-template-columns: auto;
      grid-template-rows: $quest-s max-content auto;
    }
  }

  .lock {
    .icon {
      width: 64px;
      height: 64px;
      margin: auto;
      background-size: cover;
      &.locked {
        background-image: url(../../assets/Paomedia-Small-N-Flat-Lock.svg);
      }
      &.unlocked {
        background-image: url(../../assets/Paomedia-Small-N-Flat-Lock-open.svg);
      }
    }
    .timer {
      width: 100px;
      margin: 10px auto 0 auto;
    }
  }
}
