@use 'sass:math';

@import './external/assets/css/sprites.css';
@import './external/assets/css/sprites/spritesmith-main.css';

.avatar-container {
  $oh: 147px;
  $ow: 141px;
  $s: 100px;

  .avatar {
    width: $ow;
    height: $oh;
    image-rendering: pixelated;
    position: relative;
    cursor: pointer;

    .character-sprites {
      margin: 0 auto 0 24px;

      & > span {
        position: absolute;
      }

      .current-pet {
        left: 0;
        bottom: 0;
      }
    }
    transform: scale(math.div($s, $oh));
    transform-origin: left top;
  }

  height: $s;
  width: $s;
}
