@import './external/assets/scss/colors.scss';

.member-details {
  @import './external/assets/scss/stats.scss';

  .stats-bar {
    $pb-w: 100px;
    $pb-h: 10px;
    .label {
      display: inline-block;
      width: 30px;
    }

    .progress {
      display: inline-block;
      width: $pb-w;
      height: $pb-h;
      background-color: #271b3d;

      .progress-bar {
        height: $pb-h;
      }
    }

    .value {
      margin-left: 8px;
    }
  }

  $icon-s: 20px;
  .icon {
    width: $icon-s;
    height: $icon-s;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;

    &.class {
      &.healer {
        background-image: url('./external/assets/svg/healer.svg');
      }
      &.rogue {
        background-image: url('./external/assets/svg/rogue.svg');
      }
      &.warrior {
        background-image: url('./external/assets/svg/warrior.svg');
      }
      &.wizard {
        background-image: url('./external/assets/svg/wizard.svg');
      }
    }
    &.balance {
      background-image: url('../assets/icons8-dollar-coin-32.png');
    }
    &.gold {
      background-image: url('./external/assets/svg/gold.svg');
    }
    &.gem {
      background-image: url('./external/assets/svg/gem.svg');
    }
    &.sword {
      background-image: url('./external/assets/svg/sword.svg');
    }
  }

  .name {
    font-weight: bold;

    &.perfect {
      background-image: linear-gradient(
        to right,
        #ef5350,
        #f48fb1,
        #7e57c2,
        #2196f3,
        #26c6da,
        #43a047,
        #eeff41,
        #f9a825,
        #ff5722
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;

      animation: rainbow 2s infinite;
    }

    @keyframes rainbow {
      0% {
        background-image: linear-gradient(
          to right,
          #ef5350,
          #f48fb1,
          #7e57c2,
          #2196f3,
          #26c6da,
          #43a047,
          #eeff41,
          #f9a825,
          #ff5722
        );
      }
      11% {
        background-image: linear-gradient(
          to right,
          #f48fb1,
          #7e57c2,
          #2196f3,
          #26c6da,
          #43a047,
          #eeff41,
          #f9a825,
          #ff5722,
          #ef5350
        );
      }
      22% {
        background-image: linear-gradient(
          to right,
          #7e57c2,
          #2196f3,
          #26c6da,
          #43a047,
          #eeff41,
          #f9a825,
          #ff5722,
          #ef5350,
          #f48fb1
        );
      }
      33% {
        background-image: linear-gradient(
          to right,
          #2196f3,
          #26c6da,
          #43a047,
          #eeff41,
          #f9a825,
          #ff5722,
          #ef5350,
          #f48fb1,
          #7e57c2
        );
      }
      44% {
        background-image: linear-gradient(
          to right,
          #26c6da,
          #43a047,
          #eeff41,
          #f9a825,
          #ff5722,
          #ef5350,
          #f48fb1,
          #7e57c2,
          #2196f3
        );
      }
      55% {
        background-image: linear-gradient(
          to right,
          #43a047,
          #eeff41,
          #f9a825,
          #ff5722,
          #ef5350,
          #f48fb1,
          #7e57c2,
          #2196f3,
          #26c6da
        );
      }
      66% {
        background-image: linear-gradient(
          to right,
          #eeff41,
          #f9a825,
          #ff5722,
          #ef5350,
          #f48fb1,
          #7e57c2,
          #2196f3,
          #26c6da,
          #43a047
        );
      }
      77% {
        background-image: linear-gradient(
          to right,
          #f9a825,
          #ff5722,
          #ef5350,
          #f48fb1,
          #7e57c2,
          #2196f3,
          #26c6da,
          #43a047,
          #eeff41
        );
      }
      88% {
        background-image: linear-gradient(
          to right,
          #ff5722,
          #ef5350,
          #f48fb1,
          #7e57c2,
          #2196f3,
          #26c6da,
          #43a047,
          #eeff41,
          #f9a825
        );
      }
    }
  }
}
