body.taskboard {
  background: #36205d;
  color: #d5c8ff;

  min-height: 100%;

  padding: 0;
  margin: 10px;
}

#root {
  height: 100%;
  overflow: auto;
}

.card-grid-container {
  min-height: 100%;
}