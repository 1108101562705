// Colors taken from the Habitica Color Palette
// The palette is available at https://habitica.fandom.com/wiki/Guidance_for_Comrades?file=Color_palette_official.png
// The colors are named from the darkest to the lightest

$white: #FFFFFF;
$black: #1A181D;

$gray-10: #34313A;
$gray-50: #4E4A57;
$gray-100: #686274;
$gray-200: #878190;
$gray-300: #A5A1AC;
$gray-400: #C3C0C7;
$gray-500: #E1E0E3;
$gray-600: #EDECEE;
$gray-700: #F9F9F9;

$red-1: #6C0406;
$red-10: #F23035;
$red-50: #F74E52;
$red-100: #FF6165;
$red-500: #FFB6B8;

$maroon-10: #B01515;
$maroon-50: #C92B2B;
$maroon-100: #DE3F3F;
$maroon-500: #F19595;

$orange-1: #7F3300;
$orange-10: #F47825;
$orange-50: #FA8537;
$orange-100: #FF944C;
$orange-500: #FFC8A7;

$yellow-1: #794B00;
$yellow-5: #EE9109;
$yellow-10: #FFA624;
$yellow-50: #FFB445;
$yellow-100: #FFBE5D;
$yellow-500: #FEDEAD;

$green-1: #005737;
$green-10: #1CA372;
$green-50: #20B780;
$green-100: #24CC8F;
$green-500: #77F4C7;

$teal-1: #005158;
$teal-10: #26A0AB;
$teal-50: #34B5C1;
$teal-100: #3BCAD7;
$teal-500: #8EEDF6;

$blue-1: #033F5E;
$blue-10: #2995CD;
$blue-50: #46A7D9;
$blue-100: #50B5E9;
$blue-500: #A9DCF6;

$purple-50: #36205D;
$purple-100: #432874;
$purple-200: #4F2A93;
$purple-300: #6133B4;
$purple-400: #925CF3;
$purple-500: #BDA8FF;
$purple-600: #D5C8FF;

$header-color: #D5C8FF;
$header-dark-background: #271B3D;

$healer-color: #FFA624;
$rogue-color: #4F2A93;
$warrior-color: #C92B2B;
$wizard-color: #2995CD;

$gems-color: #24CC8F;
$gold-color: #FFA624;
$hourglass-color: #2995CD;

$purple-task: #925cf3;
