.achievement-alien {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-alien.png');
  width: 24px;
  height: 26px;
}
.achievement-alien2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-alien2x.png');
  width: 48px;
  height: 52px;
}
.achievement-allThatGlitters2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-allThatGlitters2x.png');
  width: 64px;
  height: 56px;
}
.achievement-allYourBase2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-allYourBase2x.png');
  width: 64px;
  height: 56px;
}
.achievement-alpha2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-alpha2x.png');
  width: 48px;
  height: 52px;
}
.achievement-aridAuthority2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-aridAuthority2x.png');
  width: 64px;
  height: 56px;
}
.achievement-armor2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-armor2x.png');
  width: 48px;
  height: 52px;
}
.achievement-backToBasics2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-backToBasics2x.png');
  width: 48px;
  height: 56px;
}
.achievement-bareNecessities2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bareNecessities2x.png');
  width: 68px;
  height: 68px;
}
.achievement-bewilder2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bewilder2x.png');
  width: 48px;
  height: 52px;
}
.achievement-birdsOfAFeather2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-birdsOfAFeather2x.png');
  width: 60px;
  height: 64px;
}
.achievement-birthday2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-birthday2x.png');
  width: 48px;
  height: 52px;
}
.achievement-boneCollector2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-boneCollector2x.png');
  width: 48px;
  height: 56px;
}
.achievement-boneToPick2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-boneToPick2x.png');
  width: 68px;
  height: 68px;
}
.achievement-boot2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-boot2x.png');
  width: 48px;
  height: 52px;
}
.achievement-bow2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bow2x.png');
  width: 48px;
  height: 52px;
}
.achievement-bugBonanza2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bugBonanza2x.png');
  width: 60px;
  height: 64px;
}
.achievement-burnout2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-burnout2x.png');
  width: 48px;
  height: 52px;
}
.achievement-cactus2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-cactus2x.png');
  width: 48px;
  height: 52px;
}
.achievement-cake2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-cake2x.png');
  width: 48px;
  height: 52px;
}
.achievement-cave2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-cave2x.png');
  width: 48px;
  height: 52px;
}
.achievement-challenge2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-challenge2x.png');
  width: 48px;
  height: 52px;
}
.achievement-comment2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-comment2x.png');
  width: 48px;
  height: 52px;
}
.achievement-completedTask2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-completedTask2x.png');
  width: 48px;
  height: 56px;
}
.achievement-congrats2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-congrats2x.png');
  width: 48px;
  height: 52px;
}
.achievement-costumeContest2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-costumeContest2x.png');
  width: 48px;
  height: 52px;
}
.achievement-createdTask2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-createdTask2x.png');
  width: 48px;
  height: 56px;
}
.achievement-dilatory2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dilatory2x.png');
  width: 48px;
  height: 52px;
}
.achievement-dinosaurDynasty2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dinosaurDynasty2x.png');
  width: 68px;
  height: 68px;
}
.achievement-domesticated2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-domesticated2x.png');
  width: 60px;
  height: 64px;
}
.achievement-dustDevil2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dustDevil2x.png');
  width: 48px;
  height: 56px;
}
.achievement-dysheartener2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dysheartener2x.png');
  width: 48px;
  height: 52px;
}
.achievement-fedPet2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-fedPet2x.png');
  width: 48px;
  height: 56px;
}
.achievement-freshwaterFriends2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-freshwaterFriends2x.png');
  width: 68px;
  height: 68px;
}
.achievement-friends2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-friends2x.png');
  width: 48px;
  height: 52px;
}
.achievement-getwell2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-getwell2x.png');
  width: 48px;
  height: 52px;
}
.achievement-goodAsGold2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-goodAsGold2x.png');
  width: 48px;
  height: 56px;
}
.achievement-goodluck2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-goodluck2x.png');
  width: 48px;
  height: 52px;
}
.achievement-greeting2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-greeting2x.png');
  width: 48px;
  height: 52px;
}
.achievement-groupsBeta20222x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-groupsBeta20222x.png');
  width: 67px;
  height: 67px;
}
.achievement-guild2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-guild2x.png');
  width: 48px;
  height: 52px;
}
.achievement-habitBirthday2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-habitBirthday2x.png');
  width: 48px;
  height: 52px;
}
.achievement-habiticaDay2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-habiticaDay2x.png');
  width: 48px;
  height: 52px;
}
.achievement-hatchedPet2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-hatchedPet2x.png');
  width: 48px;
  height: 56px;
}
.achievement-heart2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-heart2x.png');
  width: 48px;
  height: 52px;
}
.achievement-justAddWater2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-justAddWater2x.png');
  width: 60px;
  height: 64px;
}
.achievement-karaoke-2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-karaoke-2x.png');
  width: 48px;
  height: 52px;
}
.achievement-karaoke {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-karaoke.png');
  width: 24px;
  height: 26px;
}
.achievement-kickstarter20192x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-kickstarter20192x.png');
  width: 68px;
  height: 68px;
}
.achievement-legendaryBestiary2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-legendaryBestiary2x.png');
  width: 60px;
  height: 64px;
}
.achievement-lostMasterclasser2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-lostMasterclasser2x.png');
  width: 48px;
  height: 52px;
}
.achievement-mindOverMatter2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-mindOverMatter2x.png');
  width: 60px;
  height: 64px;
}
.achievement-monsterMagus2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-monsterMagus2x.png');
  width: 48px;
  height: 56px;
}
.achievement-ninja2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ninja2x.png');
  width: 48px;
  height: 52px;
}
.achievement-npc2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-npc2x.png');
  width: 48px;
  height: 52px;
}
.achievement-nye2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-nye2x.png');
  width: 48px;
  height: 52px;
}
.achievement-partyOn2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-partyOn2x.png');
  width: 48px;
  height: 52px;
}
.achievement-partyUp2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-partyUp2x.png');
  width: 48px;
  height: 52px;
}
.achievement-pearlyPro2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-pearlyPro2x.png');
  width: 64px;
  height: 56px;
}
.achievement-perfect2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-perfect2x.png');
  width: 48px;
  height: 52px;
}
.achievement-plantParent2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-plantParent2x.png');
  width: 64px;
  height: 68px;
}
.achievement-polarPro2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-polarPro2x.png');
  width: 68px;
  height: 68px;
}
.achievement-primedForPainting2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-primedForPainting2x.png');
  width: 48px;
  height: 56px;
}
.achievement-purchasedEquipment2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-purchasedEquipment2x.png');
  width: 48px;
  height: 56px;
}
.achievement-rat2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-rat2x.png');
  width: 48px;
  height: 52px;
}
.achievement-redLetterDay2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-redLetterDay2x.png');
  width: 64px;
  height: 56px;
}
.achievement-reptacularRumble2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-reptacularRumble2x.png');
  width: 60px;
  height: 64px;
}
.achievement-rosyOutlook2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-rosyOutlook2x.png');
  width: 64px;
  height: 56px;
}
.achievement-royally-loyal2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-royally-loyal2x.png');
  width: 48px;
  height: 52px;
}
.achievement-seafoam2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-seafoam2x.png');
  width: 48px;
  height: 52px;
}
.achievement-seasonalSpecialist2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-seasonalSpecialist2x.png');
  width: 60px;
  height: 64px;
}
.achievement-seeingRed2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-seeingRed2x.png');
  width: 48px;
  height: 56px;
}
.achievement-shadeOfItAll2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shadeOfItAll2x.png');
  width: 64px;
  height: 56px;
}
.achievement-shadyCustomer2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shadyCustomer2x.png');
  width: 48px;
  height: 56px;
}
.achievement-shield2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shield2x.png');
  width: 48px;
  height: 52px;
}
.achievement-shinySeed2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shinySeed2x.png');
  width: 48px;
  height: 52px;
}
.achievement-skeletonCrew2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-skeletonCrew2x.png');
  width: 64px;
  height: 56px;
}
.achievement-snowball2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-snowball2x.png');
  width: 48px;
  height: 52px;
}
.achievement-spookySparkles2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-spookySparkles2x.png');
  width: 48px;
  height: 52px;
}
.achievement-stoikalm2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-stoikalm2x.png');
  width: 48px;
  height: 52px;
}
.achievement-sun2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-sun2x.png');
  width: 48px;
  height: 52px;
}
.achievement-sword2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-sword2x.png');
  width: 48px;
  height: 52px;
}
.achievement-thankyou2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-thankyou2x.png');
  width: 48px;
  height: 52px;
}
.achievement-thermometer2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-thermometer2x.png');
  width: 48px;
  height: 52px;
}
.achievement-tickledPink2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-tickledPink2x.png');
  width: 48px;
  height: 56px;
}
.achievement-tree2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-tree2x.png');
  width: 48px;
  height: 52px;
}
.achievement-triadbingo2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-triadbingo2x.png');
  width: 48px;
  height: 52px;
}
.achievement-ultimate-healer2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-healer2x.png');
  width: 48px;
  height: 52px;
}
.achievement-ultimate-mage2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-mage2x.png');
  width: 48px;
  height: 52px;
}
.achievement-ultimate-rogue2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-rogue2x.png');
  width: 48px;
  height: 52px;
}
.achievement-ultimate-warrior2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-warrior2x.png');
  width: 48px;
  height: 52px;
}
.achievement-undeadUndertaker2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-undeadUndertaker2x.png');
  width: 64px;
  height: 56px;
}
.achievement-unearned2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-unearned2x.png');
  width: 48px;
  height: 52px;
}
.achievement-valentine2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-valentine2x.png');
  width: 48px;
  height: 52px;
}
.achievement-violetsAreBlue2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-violetsAreBlue2x.png');
  width: 48px;
  height: 56px;
}
.achievement-wildBlueYonder2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-wildBlueYonder2x.png');
  width: 64px;
  height: 56px;
}
.achievement-wolf2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-wolf2x.png');
  width: 48px;
  height: 52px;
}
.achievement-woodlandWizard2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-woodlandWizard2x.png');
  width: 60px;
  height: 64px;
}
.achievement-zodiac2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-zodiac2x.png');
  width: 60px;
  height: 64px;
}
.background_afternoon_picnic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_afternoon_picnic.png');
  width: 141px;
  height: 147px;
}
.background_alpine_slopes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_alpine_slopes.png');
  width: 141px;
  height: 147px;
}
.background_amid_ancient_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_amid_ancient_ruins.png');
  width: 141px;
  height: 147px;
}
.background_among_cattails {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_cattails.png');
  width: 141px;
  height: 147px;
}
.background_among_giant_anemones {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_anemones.png');
  width: 141px;
  height: 147px;
}
.background_among_giant_flowers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_flowers.png');
  width: 141px;
  height: 147px;
}
.customize-option.background_among_giant_flowers {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_flowers.png');
  width: 60px;
  height: 60px;
}
.background_among_giant_mushrooms {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_mushrooms.png');
  width: 141px;
  height: 147px;
}
.background_animal_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_animal_clouds.png');
  width: 141px;
  height: 147px;
}
.background_animals_den {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_animals_den.png');
  width: 141px;
  height: 147px;
}
.background_apple_picking {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_apple_picking.png');
  width: 141px;
  height: 147px;
}
.background_aquarium {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_aquarium.png');
  width: 141px;
  height: 147px;
}
.background_archaeological_dig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_archaeological_dig.png');
  width: 141px;
  height: 147px;
}
.background_archery_range {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_archery_range.png');
  width: 141px;
  height: 147px;
}
.background_at_the_docks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_at_the_docks.png');
  width: 141px;
  height: 147px;
}
.background_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_aurora.png');
  width: 141px;
  height: 147px;
}
.background_autumn_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_bridge.png');
  width: 141px;
  height: 147px;
}
.background_autumn_flower_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_flower_garden.png');
  width: 141px;
  height: 147px;
}
.customize-option.background_autumn_flower_garden {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_flower_garden.png');
  width: 60px;
  height: 60px;
}
.background_autumn_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_forest.png');
  width: 141px;
  height: 147px;
}
.background_autumn_lakeshore {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_lakeshore.png');
  width: 141px;
  height: 147px;
}
.background_autumn_picnic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_picnic.png');
  width: 141px;
  height: 147px;
}
.background_autumn_poplars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_poplars.png');
  width: 141px;
  height: 147px;
}
.background_avalanche {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_avalanche.png');
  width: 141px;
  height: 147px;
}
.background_back_alley {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_back_alley.png');
  width: 141px;
  height: 147px;
}
.background_back_of_giant_beast {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_back_of_giant_beast.png');
  width: 141px;
  height: 147px;
}
.background_bamboo_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bamboo_forest.png');
  width: 141px;
  height: 147px;
}
.background_bayou {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bayou.png');
  width: 141px;
  height: 147px;
}
.background_beach {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beach.png');
  width: 141px;
  height: 147px;
}
.background_beach_cabana {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beach_cabana.png');
  width: 141px;
  height: 147px;
}
.background_beach_with_dunes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beach_with_dunes.png');
  width: 141px;
  height: 147px;
}
.background_beehive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beehive.png');
  width: 141px;
  height: 147px;
}
.background_bell_tower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bell_tower.png');
  width: 141px;
  height: 147px;
}
.background_beside_well {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beside_well.png');
  width: 141px;
  height: 147px;
}
.background_bioluminescent_waves {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bioluminescent_waves.png');
  width: 141px;
  height: 147px;
}
.background_birch_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_birch_forest.png');
  width: 141px;
  height: 147px;
}
.background_birthday_bash {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_birthday_bash.png');
  width: 141px;
  height: 147px;
}
.background_birthday_party {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_birthday_party.png');
  width: 141px;
  height: 147px;
}
.background_blacksmithy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blacksmithy.png');
  width: 141px;
  height: 147px;
}
.background_blizzard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blizzard.png');
  width: 141px;
  height: 147px;
}
.background_blossoming_desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blossoming_desert.png');
  width: 141px;
  height: 147px;
}
.background_blossoming_trees {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blossoming_trees.png');
  width: 141px;
  height: 147px;
}
.background_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blue.png');
  width: 141px;
  height: 147px;
}
.background_branches_of_a_holiday_tree {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_branches_of_a_holiday_tree.png');
  width: 141px;
  height: 147px;
}
.background_brick_wall_with_ivy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_brick_wall_with_ivy.png');
  width: 141px;
  height: 147px;
}
.background_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bridge.png');
  width: 141px;
  height: 147px;
}
.background_bug_covered_log {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bug_covered_log.png');
  width: 141px;
  height: 147px;
}
.background_buried_treasure {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_buried_treasure.png');
  width: 141px;
  height: 147px;
}
.background_butterfly_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_butterfly_garden.png');
  width: 141px;
  height: 147px;
}
.background_by_a_campfire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_by_a_campfire.png');
  width: 141px;
  height: 147px;
}
.background_camping_out {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_camping_out.png');
  width: 141px;
  height: 147px;
}
.background_castle_gate {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_castle_gate.png');
  width: 141px;
  height: 147px;
}
.background_cemetery_gate {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cemetery_gate.png');
  width: 141px;
  height: 147px;
}
.background_champions_colosseum {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_champions_colosseum.png');
  width: 141px;
  height: 147px;
}
.background_cherry_trees {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cherry_trees.png');
  width: 141px;
  height: 147px;
}
.background_chessboard_land {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_chessboard_land.png');
  width: 141px;
  height: 147px;
}
.background_clothesline {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_clothesline.png');
  width: 141px;
  height: 147px;
}
.background_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_clouds.png');
  width: 141px;
  height: 147px;
}
.background_coral_reef {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_coral_reef.png');
  width: 141px;
  height: 147px;
}
.background_cornfields {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cornfields.png');
  width: 141px;
  height: 147px;
}
.background_cottage_construction {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cottage_construction.png');
  width: 141px;
  height: 147px;
}
.background_cozy_barn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cozy_barn.png');
  width: 141px;
  height: 147px;
}
.background_cozy_bedroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cozy_bedroom.png');
  width: 141px;
  height: 147px;
}
.background_cozy_library {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cozy_library.png');
  width: 141px;
  height: 147px;
}
.background_creepy_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_creepy_castle.png');
  width: 141px;
  height: 147px;
}
.background_crescent_moon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_crescent_moon.png');
  width: 141px;
  height: 147px;
}
.background_cretaceous_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cretaceous_forest.png');
  width: 141px;
  height: 147px;
}
.background_crosscountry_ski_trail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_crosscountry_ski_trail.png');
  width: 141px;
  height: 147px;
}
.background_cryptic_candles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cryptic_candles.png');
  width: 141px;
  height: 147px;
}
.background_crystal_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_crystal_cave.png');
  width: 141px;
  height: 147px;
}
.background_dark_deep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dark_deep.png');
  width: 141px;
  height: 147px;
}
.background_daytime_misty_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_daytime_misty_forest.png');
  width: 141px;
  height: 147px;
}
.background_deep_mine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_deep_mine.png');
  width: 141px;
  height: 147px;
}
.background_deep_sea {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_deep_sea.png');
  width: 141px;
  height: 147px;
}
.background_desert_dunes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_desert_dunes.png');
  width: 141px;
  height: 147px;
}
.background_desert_with_snow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_desert_with_snow.png');
  width: 141px;
  height: 147px;
}
.background_dilatory_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dilatory_castle.png');
  width: 141px;
  height: 147px;
}
.background_dilatory_city {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dilatory_city.png');
  width: 141px;
  height: 147px;
}
.background_dilatory_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dilatory_ruins.png');
  width: 141px;
  height: 147px;
}
.background_distant_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_distant_castle.png');
  width: 141px;
  height: 147px;
}
.background_dojo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dojo.png');
  width: 141px;
  height: 147px;
}
.background_dragons_lair {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dragons_lair.png');
  width: 141px;
  height: 147px;
}
.background_drifting_raft {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_drifting_raft.png');
  width: 141px;
  height: 147px;
}
.background_driving_a_coach {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_driving_a_coach.png');
  width: 141px;
  height: 147px;
}
.background_driving_a_sleigh {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_driving_a_sleigh.png');
  width: 141px;
  height: 147px;
}
.background_duck_pond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_duck_pond.png');
  width: 141px;
  height: 147px;
}
.background_dungeon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dungeon.png');
  width: 141px;
  height: 147px;
}
.background_dusty_canyons {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dusty_canyons.png');
  width: 141px;
  height: 147px;
}
.background_elegant_balcony {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_elegant_balcony.png');
  width: 141px;
  height: 147px;
}
.background_elegant_ballroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_elegant_ballroom.png');
  width: 141px;
  height: 147px;
}
.background_elegant_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_elegant_garden.png');
  width: 141px;
  height: 147px;
}
.background_enchanted_music_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_enchanted_music_room.png');
  width: 141px;
  height: 147px;
}
.background_fairy_ring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fairy_ring.png');
  width: 141px;
  height: 147px;
}
.background_fancy_bedroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fancy_bedroom.png');
  width: 141px;
  height: 147px;
}
.background_fantastical_shoe_store {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fantastical_shoe_store.png');
  width: 141px;
  height: 147px;
}
.background_farmers_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_farmers_market.png');
  width: 141px;
  height: 147px;
}
.background_farmhouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_farmhouse.png');
  width: 141px;
  height: 147px;
}
.background_fiber_arts_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fiber_arts_room.png');
  width: 141px;
  height: 147px;
}
.background_field_with_colored_eggs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_field_with_colored_eggs.png');
  width: 141px;
  height: 147px;
}
.background_floating_islands {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_floating_islands.png');
  width: 141px;
  height: 147px;
}
.background_floral_meadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_floral_meadow.png');
  width: 141px;
  height: 147px;
}
.background_flower_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_market.png');
  width: 141px;
  height: 147px;
}
.customize-option.background_flower_market {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_market.png');
  width: 60px;
  height: 60px;
}
.background_flower_shop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_shop.png');
  width: 141px;
  height: 147px;
}
.customize-option.background_flower_shop {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_shop.png');
  width: 60px;
  height: 60px;
}
.background_flowering_prairie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flowering_prairie.png');
  width: 141px;
  height: 147px;
}
.customize-option.background_flowering_prairie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flowering_prairie.png');
  width: 60px;
  height: 60px;
}
.background_flying_in_a_thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_in_a_thunderstorm.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_a_field_of_wildflowers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_a_field_of_wildflowers.png');
  width: 141px;
  height: 147px;
}
.customize-option.background_flying_over_a_field_of_wildflowers {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_a_field_of_wildflowers.png');
  width: 60px;
  height: 60px;
}
.background_flying_over_an_ancient_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_an_ancient_forest.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_an_autumn_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_an_autumn_forest.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_glacier {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_glacier.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_hedge_maze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_hedge_maze.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_icy_steppes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_icy_steppes.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_rocky_canyon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_rocky_canyon.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_snowy_mountains {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_snowy_mountains.png');
  width: 141px;
  height: 147px;
}
.background_flying_over_tropical_islands {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_tropical_islands.png');
  width: 141px;
  height: 147px;
}
.background_foggy_moor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_foggy_moor.png');
  width: 141px;
  height: 147px;
}
.background_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_forest.png');
  width: 141px;
  height: 147px;
}
.background_forested_lakeshore {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_forested_lakeshore.png');
  width: 141px;
  height: 147px;
}
.background_fortune_tellers_shop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fortune_tellers_shop.png');
  width: 141px;
  height: 147px;
}
.background_frigid_peak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frigid_peak.png');
  width: 141px;
  height: 147px;
}
.background_frosty_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frosty_forest.png');
  width: 141px;
  height: 147px;
}
.background_frozen_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frozen_lake.png');
  width: 141px;
  height: 147px;
}
.background_frozen_polar_waters {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frozen_polar_waters.png');
  width: 141px;
  height: 147px;
}
.background_garden_shed {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_garden_shed.png');
  width: 141px;
  height: 147px;
}
.background_gazebo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gazebo.png');
  width: 141px;
  height: 147px;
}
.background_ghost_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ghost_ship.png');
  width: 141px;
  height: 147px;
}
.background_giant_autumn_leaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_autumn_leaf.png');
  width: 141px;
  height: 147px;
}
.background_giant_birdhouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_birdhouse.png');
  width: 141px;
  height: 147px;
}
.background_giant_book {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_book.png');
  width: 141px;
  height: 147px;
}
.background_giant_dandelions {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_dandelions.png');
  width: 141px;
  height: 147px;
}
.background_giant_florals {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_florals.png');
  width: 141px;
  height: 147px;
}
.background_giant_seashell {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_seashell.png');
  width: 141px;
  height: 147px;
}
.background_giant_wave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_wave.png');
  width: 141px;
  height: 147px;
}
.background_gingerbread_house {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gingerbread_house.png');
  width: 141px;
  height: 147px;
}
.background_glowing_mushroom_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_glowing_mushroom_cave.png');
  width: 141px;
  height: 147px;
}
.background_golden_birdcage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_golden_birdcage.png');
  width: 141px;
  height: 147px;
}
.background_gorgeous_greenhouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gorgeous_greenhouse.png');
  width: 141px;
  height: 147px;
}
.background_grand_staircase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_grand_staircase.png');
  width: 141px;
  height: 147px;
}
.background_graveyard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_graveyard.png');
  width: 141px;
  height: 147px;
}
.background_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_green.png');
  width: 141px;
  height: 147px;
}
.background_guardian_statues {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_guardian_statues.png');
  width: 141px;
  height: 147px;
}
.background_gumdrop_land {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gumdrop_land.png');
  width: 141px;
  height: 147px;
}
.background_habit_city_rooftops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_habit_city_rooftops.png');
  width: 141px;
  height: 147px;
}
.background_habit_city_streets {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_habit_city_streets.png');
  width: 141px;
  height: 147px;
}
.background_halflings_house {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_halflings_house.png');
  width: 141px;
  height: 147px;
}
.background_hall_of_heroes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_hall_of_heroes.png');
  width: 141px;
  height: 147px;
}
.background_harvest_feast {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_harvest_feast.png');
  width: 141px;
  height: 147px;
}
.background_harvest_fields {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_harvest_fields.png');
  width: 141px;
  height: 147px;
}
.background_harvest_moon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_harvest_moon.png');
  width: 141px;
  height: 147px;
}
.background_haunted_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_haunted_forest.png');
  width: 141px;
  height: 147px;
}
.background_haunted_house {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_haunted_house.png');
  width: 141px;
  height: 147px;
}
.background_haunted_photo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_haunted_photo.png');
  width: 141px;
  height: 147px;
}
.background_heart_shaped_bubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_heart_shaped_bubbles.png');
  width: 141px;
  height: 147px;
}
.background_heather_field {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_heather_field.png');
  width: 141px;
  height: 147px;
}
.background_herding_sheep_in_autumn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_herding_sheep_in_autumn.png');
  width: 141px;
  height: 147px;
}
.background_holiday_hearth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_holiday_hearth.png');
  width: 141px;
  height: 147px;
}
.background_holiday_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_holiday_market.png');
  width: 141px;
  height: 147px;
}
.background_holiday_wreath {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_holiday_wreath.png');
  width: 141px;
  height: 147px;
}
.background_hot_air_balloon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_hot_air_balloon.png');
  width: 141px;
  height: 147px;
}
.background_hot_spring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_hot_spring.png');
  width: 141px;
  height: 147px;
}
.background_ice_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ice_cave.png');
  width: 141px;
  height: 147px;
}
.background_ice_palace {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ice_palace.png');
  width: 141px;
  height: 147px;
}
.background_iceberg {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_iceberg.png');
  width: 141px;
  height: 147px;
}
.background_icicle_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_icicle_bridge.png');
  width: 141px;
  height: 147px;
}
.background_idyllic_cabin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_idyllic_cabin.png');
  width: 141px;
  height: 147px;
}
.background_in_a_classroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_a_classroom.png');
  width: 141px;
  height: 147px;
}
.background_in_a_painting {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_a_painting.png');
  width: 141px;
  height: 147px;
}
.background_in_an_ancient_tomb {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_an_ancient_tomb.png');
  width: 141px;
  height: 147px;
}
.background_in_front_of_fountain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_front_of_fountain.png');
  width: 141px;
  height: 147px;
}
.background_in_the_armory {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_the_armory.png');
  width: 141px;
  height: 147px;
}
.background_inside_a_crystal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_inside_a_crystal.png');
  width: 141px;
  height: 147px;
}
.background_inside_a_potion_bottle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_inside_a_potion_bottle.png');
  width: 141px;
  height: 147px;
}
.background_inside_an_ornament {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_inside_an_ornament.png');
  width: 141px;
  height: 147px;
}
.background_iridescent_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_iridescent_clouds.png');
  width: 141px;
  height: 147px;
}
.background_island_waterfalls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_island_waterfalls.png');
  width: 141px;
  height: 147px;
}
.background_jungle_canopy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_jungle_canopy.png');
  width: 141px;
  height: 147px;
}
.background_jungle_watering_hole {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_jungle_watering_hole.png');
  width: 141px;
  height: 147px;
}
.background_kelp_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_kelp_forest.png');
  width: 141px;
  height: 147px;
}
.background_lake_with_floating_lanterns {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_lake_with_floating_lanterns.png');
  width: 141px;
  height: 147px;
}
.background_leafy_tree_tunnel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_leafy_tree_tunnel.png');
  width: 141px;
  height: 147px;
}
.background_lighthouse_shore {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_lighthouse_shore.png');
  width: 141px;
  height: 147px;
}
.background_lilypad {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_lilypad.png');
  width: 141px;
  height: 147px;
}
.background_magic_beanstalk {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_magic_beanstalk.png');
  width: 141px;
  height: 147px;
}
.background_magical_candles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_magical_candles.png');
  width: 141px;
  height: 147px;
}
.background_magical_museum {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_magical_museum.png');
  width: 141px;
  height: 147px;
}
.background_mangrove_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mangrove_forest.png');
  width: 141px;
  height: 147px;
}
.background_marble_temple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_marble_temple.png');
  width: 141px;
  height: 147px;
}
.background_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_market.png');
  width: 141px;
  height: 147px;
}
.background_mask_makers_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mask_makers_workshop.png');
  width: 141px;
  height: 147px;
}
.background_meandering_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_meandering_cave.png');
  width: 141px;
  height: 147px;
}
.background_medieval_kitchen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_medieval_kitchen.png');
  width: 141px;
  height: 147px;
}
.background_messy_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_messy_room.png');
  width: 141px;
  height: 147px;
}
.background_meteor_shower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_meteor_shower.png');
  width: 141px;
  height: 147px;
}
.background_midnight_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_midnight_castle.png');
  width: 141px;
  height: 147px;
}
.background_midnight_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_midnight_clouds.png');
  width: 141px;
  height: 147px;
}
.background_midnight_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_midnight_lake.png');
  width: 141px;
  height: 147px;
}
.background_mist_shrouded_mountain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mist_shrouded_mountain.png');
  width: 141px;
  height: 147px;
}
.background_mistiflying_circus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mistiflying_circus.png');
  width: 141px;
  height: 147px;
}
.background_misty_autumn_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_misty_autumn_forest.png');
  width: 141px;
  height: 147px;
}
.background_monster_makers_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_monster_makers_workshop.png');
  width: 141px;
  height: 147px;
}
.background_mountain_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mountain_lake.png');
  width: 141px;
  height: 147px;
}
.background_mountain_pyramid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mountain_pyramid.png');
  width: 141px;
  height: 147px;
}
.background_mountain_waterfall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mountain_waterfall.png');
  width: 141px;
  height: 147px;
}
.background_mystical_observatory {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mystical_observatory.png');
  width: 141px;
  height: 147px;
}
.background_night_dunes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_night_dunes.png');
  width: 141px;
  height: 147px;
}
.background_ocean_sunrise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ocean_sunrise.png');
  width: 141px;
  height: 147px;
}
.background_old_fashioned_bakery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_old_fashioned_bakery.png');
  width: 141px;
  height: 147px;
}
.background_old_photo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_old_photo.png');
  width: 141px;
  height: 147px;
}
.background_old_timey_basketball_court {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_old_timey_basketball_court.png');
  width: 141px;
  height: 147px;
}
.background_on_a_castle_wall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_on_a_castle_wall.png');
  width: 141px;
  height: 147px;
}
.background_on_tree_branch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_on_tree_branch.png');
  width: 141px;
  height: 147px;
}
.background_open_waters {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_open_waters.png');
  width: 141px;
  height: 147px;
}
.background_orange_grove {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_orange_grove.png');
  width: 141px;
  height: 147px;
}
.background_orchard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_orchard.png');
  width: 141px;
  height: 147px;
}
.background_pagodas {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pagodas.png');
  width: 141px;
  height: 147px;
}
.background_palm_tree_with_fairy_lights {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_palm_tree_with_fairy_lights.png');
  width: 141px;
  height: 147px;
}
.background_park_with_statue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_park_with_statue.png');
  width: 141px;
  height: 147px;
}
.background_pirate_flag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pirate_flag.png');
  width: 141px;
  height: 147px;
}
.background_pixelists_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pixelists_workshop.png');
  width: 141px;
  height: 147px;
}
.background_potion_shop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_potion_shop.png');
  width: 141px;
  height: 147px;
}
.background_productivity_plaza {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_productivity_plaza.png');
  width: 141px;
  height: 147px;
}
.background_pumpkin_carriage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pumpkin_carriage.png');
  width: 141px;
  height: 147px;
}
.background_pumpkin_patch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pumpkin_patch.png');
  width: 141px;
  height: 147px;
}
.background_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_purple.png');
  width: 141px;
  height: 147px;
}
.background_pyramids {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pyramids.png');
  width: 141px;
  height: 147px;
}
.background_raging_river {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_raging_river.png');
  width: 141px;
  height: 147px;
}
.background_rainbow_eucalyptus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainbow_eucalyptus.png');
  width: 141px;
  height: 147px;
}
.background_rainbow_meadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainbow_meadow.png');
  width: 141px;
  height: 147px;
}
.background_rainbows_end {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainbows_end.png');
  width: 141px;
  height: 147px;
}
.background_rainforest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainforest.png');
  width: 141px;
  height: 147px;
}
.background_rainy_barnyard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainy_barnyard.png');
  width: 141px;
  height: 147px;
}
.background_rainy_city {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainy_city.png');
  width: 141px;
  height: 147px;
}
.background_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_red.png');
  width: 141px;
  height: 147px;
}
.background_relaxation_river {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_relaxation_river.png');
  width: 141px;
  height: 147px;
}
.background_resting_in_the_inn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_resting_in_the_inn.png');
  width: 141px;
  height: 147px;
}
.background_rime_ice {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rime_ice.png');
  width: 141px;
  height: 147px;
}
.background_river_of_lava {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_river_of_lava.png');
  width: 141px;
  height: 147px;
}
.background_rolling_hills {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rolling_hills.png');
  width: 141px;
  height: 147px;
}
.background_rope_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rope_bridge.png');
  width: 141px;
  height: 147px;
}
.background_rose_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rose_garden.png');
  width: 141px;
  height: 147px;
}
.background_rowboat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rowboat.png');
  width: 141px;
  height: 147px;
}
.background_sailboat_at_sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sailboat_at_sunset.png');
  width: 141px;
  height: 147px;
}
.background_salt_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_salt_lake.png');
  width: 141px;
  height: 147px;
}
.background_sandcastle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sandcastle.png');
  width: 141px;
  height: 147px;
}
.background_school_of_fish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_school_of_fish.png');
  width: 141px;
  height: 147px;
}
.background_scribes_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_scribes_workshop.png');
  width: 141px;
  height: 147px;
}
.background_seafarer_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_seafarer_ship.png');
  width: 141px;
  height: 147px;
}
.background_seaside_cliffs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_seaside_cliffs.png');
  width: 141px;
  height: 147px;
}
.background_shimmering_ice_prism {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_shimmering_ice_prism.png');
  width: 141px;
  height: 147px;
}
.background_shimmery_bubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_shimmery_bubbles.png');
  width: 141px;
  height: 147px;
}
.background_slimy_swamp {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_slimy_swamp.png');
  width: 141px;
  height: 147px;
}
.background_snowglobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowglobe.png');
  width: 141px;
  height: 147px;
}
.background_snowman_army {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowman_army.png');
  width: 141px;
  height: 147px;
}
.background_snowy_day_fireplace {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_day_fireplace.png');
  width: 141px;
  height: 147px;
}
.background_snowy_farm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_farm.png');
  width: 141px;
  height: 147px;
}
.background_snowy_pines {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_pines.png');
  width: 141px;
  height: 147px;
}
.background_snowy_sunrise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_sunrise.png');
  width: 141px;
  height: 147px;
}
.background_snowy_temple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_temple.png');
  width: 141px;
  height: 147px;
}
.background_snowy_village {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_village.png');
  width: 141px;
  height: 147px;
}
.background_south_pole {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_south_pole.png');
  width: 141px;
  height: 147px;
}
.background_sparkling_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sparkling_snowflake.png');
  width: 141px;
  height: 147px;
}
.background_spider_web {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spider_web.png');
  width: 141px;
  height: 147px;
}
.background_spiral_staircase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spiral_staircase.png');
  width: 141px;
  height: 147px;
}
.background_splash_in_a_puddle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_splash_in_a_puddle.png');
  width: 141px;
  height: 147px;
}
.background_spooky_hotel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spooky_hotel.png');
  width: 141px;
  height: 147px;
}
.background_spooky_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spooky_ruins.png');
  width: 141px;
  height: 147px;
}
.background_spooky_scarecrow_field {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spooky_scarecrow_field.png');
  width: 141px;
  height: 147px;
}
.background_spring_rain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spring_rain.png');
  width: 141px;
  height: 147px;
}
.background_spring_thaw {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spring_thaw.png');
  width: 141px;
  height: 147px;
}
.background_springtime_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_springtime_lake.png');
  width: 141px;
  height: 147px;
}
.background_springtime_shower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_springtime_shower.png');
  width: 141px;
  height: 147px;
}
.background_stable {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stable.png');
  width: 141px;
  height: 147px;
}
.background_stained_glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stained_glass.png');
  width: 141px;
  height: 147px;
}
.background_starry_skies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_starry_skies.png');
  width: 141px;
  height: 147px;
}
.background_starry_winter_night {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_starry_winter_night.png');
  width: 141px;
  height: 147px;
}
.background_stoikalm_volcanoes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stoikalm_volcanoes.png');
  width: 141px;
  height: 147px;
}
.background_stone_circle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stone_circle.png');
  width: 141px;
  height: 147px;
}
.background_stone_tower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stone_tower.png');
  width: 141px;
  height: 147px;
}
.background_stormy_rooftops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stormy_rooftops.png');
  width: 141px;
  height: 147px;
}
.background_stormy_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stormy_ship.png');
  width: 141px;
  height: 147px;
}
.background_strange_sewers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_strange_sewers.png');
  width: 141px;
  height: 147px;
}
.background_strawberry_patch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_strawberry_patch.png');
  width: 141px;
  height: 147px;
}
.background_succulent_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_succulent_garden.png');
  width: 141px;
  height: 147px;
}
.background_summer_fireworks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_summer_fireworks.png');
  width: 141px;
  height: 147px;
}
.background_sunken_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunken_ship.png');
  width: 141px;
  height: 147px;
}
.background_sunset_meadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunset_meadow.png');
  width: 141px;
  height: 147px;
}
.background_sunset_oasis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunset_oasis.png');
  width: 141px;
  height: 147px;
}
.background_sunset_savannah {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunset_savannah.png');
  width: 141px;
  height: 147px;
}
.background_swarming_darkness {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_swarming_darkness.png');
  width: 141px;
  height: 147px;
}
.background_swimming_among_jellyfish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_swimming_among_jellyfish.png');
  width: 141px;
  height: 147px;
}
.background_tar_pits {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tar_pits.png');
  width: 141px;
  height: 147px;
}
.background_tavern {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tavern.png');
  width: 141px;
  height: 147px;
}
.background_tea_party {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tea_party.png');
  width: 141px;
  height: 147px;
}
.background_terraced_rice_field {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_terraced_rice_field.png');
  width: 141px;
  height: 147px;
}
.background_theatre_stage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_theatre_stage.png');
  width: 141px;
  height: 147px;
}
.background_throne_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_throne_room.png');
  width: 141px;
  height: 147px;
}
.background_thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_thunderstorm.png');
  width: 141px;
  height: 147px;
}
.background_tide_pool {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tide_pool.png');
  width: 141px;
  height: 147px;
}
.background_tornado {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tornado.png');
  width: 141px;
  height: 147px;
}
.background_toymakers_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_toymakers_workshop.png');
  width: 141px;
  height: 147px;
}
.background_training_grounds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_training_grounds.png');
  width: 141px;
  height: 147px;
}
.background_treasure_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_treasure_room.png');
  width: 141px;
  height: 147px;
}
.background_tree_roots {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tree_roots.png');
  width: 141px;
  height: 147px;
}
.background_treehouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_treehouse.png');
  width: 141px;
  height: 147px;
}
.background_tulip_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tulip_garden.png');
  width: 141px;
  height: 147px;
}
.background_twinkly_lights {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_twinkly_lights.png');
  width: 141px;
  height: 147px;
}
.background_twinkly_party_lights {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_twinkly_party_lights.png');
  width: 141px;
  height: 147px;
}
.background_undead_hands {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_undead_hands.png');
  width: 141px;
  height: 147px;
}
.background_under_wisteria {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_under_wisteria.png');
  width: 141px;
  height: 147px;
}
.background_underwater_among_koi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_among_koi.png');
  width: 141px;
  height: 147px;
}
.background_underwater_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_cave.png');
  width: 141px;
  height: 147px;
}
.background_underwater_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_ruins.png');
  width: 141px;
  height: 147px;
}
.background_underwater_statues {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_statues.png');
  width: 141px;
  height: 147px;
}
.background_underwater_vents {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_vents.png');
  width: 141px;
  height: 147px;
}
.background_valentines_day_feasting_hall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_valentines_day_feasting_hall.png');
  width: 141px;
  height: 147px;
}
.background_viking_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_viking_ship.png');
  width: 141px;
  height: 147px;
}
.background_vineyard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_vineyard.png');
  width: 141px;
  height: 147px;
}
.background_violet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_violet.png');
  width: 141px;
  height: 147px;
}
.background_volcano {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_volcano.png');
  width: 141px;
  height: 147px;
}
.background_water_mill {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_water_mill.png');
  width: 141px;
  height: 147px;
}
.background_waterfall_rock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_waterfall_rock.png');
  width: 141px;
  height: 147px;
}
.background_wedding_arch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_wedding_arch.png');
  width: 141px;
  height: 147px;
}
.background_windmills {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_windmills.png');
  width: 141px;
  height: 147px;
}
.background_windy_autumn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_windy_autumn.png');
  width: 141px;
  height: 147px;
}
.background_winter_canyon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_canyon.png');
  width: 141px;
  height: 147px;
}
.background_winter_fireworks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_fireworks.png');
  width: 141px;
  height: 147px;
}
.background_winter_lake_with_swans {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_lake_with_swans.png');
  width: 141px;
  height: 147px;
}
.background_winter_night {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_night.png');
  width: 141px;
  height: 147px;
}
.background_winter_nocturne {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_nocturne.png');
  width: 141px;
  height: 147px;
}
.background_winter_storefront {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_storefront.png');
  width: 141px;
  height: 147px;
}
.background_winter_town {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_town.png');
  width: 141px;
  height: 147px;
}
.background_winter_waterfall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_waterfall.png');
  width: 141px;
  height: 147px;
}
.background_wintry_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_wintry_castle.png');
  width: 141px;
  height: 147px;
}
.background_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_yellow.png');
  width: 141px;
  height: 147px;
}
.icon_background_afternoon_picnic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_afternoon_picnic.png');
  width: 68px;
  height: 68px;
}
.icon_background_airship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_airship.png');
  width: 68px;
  height: 68px;
}
.icon_background_alpine_slopes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_alpine_slopes.png');
  width: 68px;
  height: 68px;
}
.icon_background_amid_ancient_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_amid_ancient_ruins.png');
  width: 68px;
  height: 68px;
}
.icon_background_among_cattails {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_cattails.png');
  width: 68px;
  height: 68px;
}
.icon_background_among_giant_anemones {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_anemones.png');
  width: 68px;
  height: 68px;
}
.icon_background_among_giant_flowers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_flowers.png');
  width: 68px;
  height: 68px;
}
.customize-option.icon_background_among_giant_flowers {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_flowers.png');
  width: 60px;
  height: 60px;
}
.icon_background_among_giant_mushrooms {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_mushrooms.png');
  width: 68px;
  height: 68px;
}
.icon_background_animal_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_animal_clouds.png');
  width: 68px;
  height: 68px;
}
.icon_background_animals_den {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_animals_den.png');
  width: 68px;
  height: 68px;
}
.icon_background_apple_picking {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_apple_picking.png');
  width: 68px;
  height: 68px;
}
.icon_background_aquarium {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_aquarium.png');
  width: 68px;
  height: 68px;
}
.icon_background_archaeological_dig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_archaeological_dig.png');
  width: 68px;
  height: 68px;
}
.icon_background_archery_range {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_archery_range.png');
  width: 68px;
  height: 68px;
}
.icon_background_at_the_docks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_at_the_docks.png');
  width: 68px;
  height: 68px;
}
.icon_background_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_aurora.png');
  width: 68px;
  height: 68px;
}
.icon_background_autumn_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_bridge.png');
  width: 68px;
  height: 68px;
}
.icon_background_autumn_flower_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_flower_garden.png');
  width: 68px;
  height: 68px;
}
.customize-option.icon_background_autumn_flower_garden {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_flower_garden.png');
  width: 60px;
  height: 60px;
}
.icon_background_autumn_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_autumn_lakeshore {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_lakeshore.png');
  width: 68px;
  height: 68px;
}
.icon_background_autumn_picnic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_picnic.png');
  width: 68px;
  height: 68px;
}
.icon_background_autumn_poplars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_poplars.png');
  width: 68px;
  height: 68px;
}
.icon_background_avalanche {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_avalanche.png');
  width: 68px;
  height: 68px;
}
.icon_background_back_alley {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_back_alley.png');
  width: 68px;
  height: 68px;
}
.icon_background_back_of_giant_beast {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_back_of_giant_beast.png');
  width: 68px;
  height: 68px;
}
.icon_background_bamboo_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bamboo_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_bayou {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bayou.png');
  width: 68px;
  height: 68px;
}
.icon_background_beach {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beach.png');
  width: 68px;
  height: 68px;
}
.icon_background_beach_cabana {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beach_cabana.png');
  width: 68px;
  height: 68px;
}
.icon_background_beach_with_dunes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beach_with_dunes.png');
  width: 67px;
  height: 69px;
}
.icon_background_beehive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beehive.png');
  width: 68px;
  height: 68px;
}
.icon_background_bell_tower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bell_tower.png');
  width: 68px;
  height: 68px;
}
.icon_background_beside_well {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beside_well.png');
  width: 68px;
  height: 68px;
}
.icon_background_bioluminescent_waves {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bioluminescent_waves.png');
  width: 68px;
  height: 68px;
}
.icon_background_birch_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_birch_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_birthday_bash {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_birthday_bash.png');
  width: 68px;
  height: 68px;
}
.icon_background_birthday_party {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_birthday_party.png');
  width: 68px;
  height: 68px;
}
.icon_background_blacksmithy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blacksmithy.png');
  width: 68px;
  height: 68px;
}
.icon_background_blizzard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blizzard.png');
  width: 68px;
  height: 68px;
}
.icon_background_blossoming_desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blossoming_desert.png');
  width: 68px;
  height: 68px;
}
.icon_background_blossoming_trees {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blossoming_trees.png');
  width: 68px;
  height: 68px;
}
.icon_background_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blue.png');
  width: 68px;
  height: 68px;
}
.icon_background_branches_of_a_holiday_tree {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_branches_of_a_holiday_tree.png');
  width: 68px;
  height: 68px;
}
.icon_background_brick_wall_with_ivy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_brick_wall_with_ivy.png');
  width: 68px;
  height: 68px;
}
.icon_background_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bridge.png');
  width: 68px;
  height: 68px;
}
.icon_background_bug_covered_log {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bug_covered_log.png');
  width: 68px;
  height: 68px;
}
.icon_background_buried_treasure {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_buried_treasure.png');
  width: 68px;
  height: 68px;
}
.icon_background_butterfly_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_butterfly_garden.png');
  width: 68px;
  height: 68px;
}
.icon_background_by_a_campfire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_by_a_campfire.png');
  width: 68px;
  height: 68px;
}
.icon_background_camping_out {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_camping_out.png');
  width: 68px;
  height: 68px;
}
.icon_background_castle_gate {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_castle_gate.png');
  width: 68px;
  height: 68px;
}
.icon_background_cemetery_gate {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cemetery_gate.png');
  width: 60px;
  height: 60px;
}
.icon_background_champions_colosseum {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_champions_colosseum.png');
  width: 68px;
  height: 68px;
}
.icon_background_cherry_trees {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cherry_trees.png');
  width: 68px;
  height: 68px;
}
.icon_background_chessboard_land {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_chessboard_land.png');
  width: 68px;
  height: 68px;
}
.icon_background_clocktower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_clocktower.png');
  width: 68px;
  height: 68px;
}
.icon_background_clothesline {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_clothesline.png');
  width: 68px;
  height: 68px;
}
.icon_background_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_clouds.png');
  width: 68px;
  height: 68px;
}
.icon_background_coral_reef {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_coral_reef.png');
  width: 68px;
  height: 68px;
}
.icon_background_cornfields {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cornfields.png');
  width: 68px;
  height: 68px;
}
.icon_background_cottage_construction {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cottage_construction.png');
  width: 68px;
  height: 68px;
}
.icon_background_cozy_barn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cozy_barn.png');
  width: 68px;
  height: 68px;
}
.icon_background_cozy_bedroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cozy_bedroom.png');
  width: 68px;
  height: 68px;
}
.icon_background_cozy_library {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cozy_library.png');
  width: 68px;
  height: 68px;
}
.icon_background_creepy_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_creepy_castle.png');
  width: 60px;
  height: 60px;
}
.icon_background_crescent_moon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_crescent_moon.png');
  width: 68px;
  height: 68px;
}
.icon_background_cretaceous_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cretaceous_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_crosscountry_ski_trail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_crosscountry_ski_trail.png');
  width: 68px;
  height: 68px;
}
.icon_background_cryptic_candles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cryptic_candles.png');
  width: 68px;
  height: 68px;
}
.icon_background_crystal_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_crystal_cave.png');
  width: 68px;
  height: 68px;
}
.icon_background_dark_deep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dark_deep.png');
  width: 68px;
  height: 68px;
}
.icon_background_daytime_misty_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_daytime_misty_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_deep_mine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_deep_mine.png');
  width: 68px;
  height: 68px;
}
.icon_background_deep_sea {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_deep_sea.png');
  width: 68px;
  height: 68px;
}
.icon_background_desert_dunes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_desert_dunes.png');
  width: 68px;
  height: 68px;
}
.icon_background_desert_with_snow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_desert_with_snow.png');
  width: 68px;
  height: 68px;
}
.icon_background_dilatory_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dilatory_castle.png');
  width: 68px;
  height: 68px;
}
.icon_background_dilatory_city {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dilatory_city.png');
  width: 68px;
  height: 68px;
}
.icon_background_dilatory_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dilatory_ruins.png');
  width: 68px;
  height: 68px;
}
.icon_background_distant_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_distant_castle.png');
  width: 68px;
  height: 68px;
}
.icon_background_dojo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dojo.png');
  width: 68px;
  height: 68px;
}
.icon_background_dragons_lair {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dragons_lair.png');
  width: 68px;
  height: 68px;
}
.icon_background_drifting_raft {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_drifting_raft.png');
  width: 68px;
  height: 68px;
}
.icon_background_driving_a_coach {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_driving_a_coach.png');
  width: 68px;
  height: 68px;
}
.icon_background_driving_a_sleigh {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_driving_a_sleigh.png');
  width: 68px;
  height: 68px;
}
.icon_background_duck_pond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_duck_pond.png');
  width: 68px;
  height: 68px;
}
.icon_background_dungeon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dungeon.png');
  width: 68px;
  height: 68px;
}
.icon_background_dusty_canyons {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dusty_canyons.png');
  width: 68px;
  height: 68px;
}
.icon_background_elegant_balcony {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_elegant_balcony.png');
  width: 68px;
  height: 68px;
}
.icon_background_elegant_ballroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_elegant_ballroom.png');
  width: 68px;
  height: 68px;
}
.icon_background_elegant_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_elegant_garden.png');
  width: 68px;
  height: 68px;
}
.icon_background_enchanted_music_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_enchanted_music_room.png');
  width: 68px;
  height: 68px;
}
.icon_background_fairy_ring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fairy_ring.png');
  width: 68px;
  height: 68px;
}
.icon_background_fancy_bedroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fancy_bedroom.png');
  width: 68px;
  height: 68px;
}
.icon_background_fantastical_shoe_store {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fantastical_shoe_store.png');
  width: 68px;
  height: 68px;
}
.icon_background_farmers_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_farmers_market.png');
  width: 68px;
  height: 68px;
}
.icon_background_farmhouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_farmhouse.png');
  width: 68px;
  height: 68px;
}
.icon_background_fiber_arts_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fiber_arts_room.png');
  width: 68px;
  height: 68px;
}
.icon_background_field_with_colored_eggs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_field_with_colored_eggs.png');
  width: 68px;
  height: 68px;
}
.icon_background_floating_islands {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_floating_islands.png');
  width: 68px;
  height: 68px;
}
.icon_background_floral_meadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_floral_meadow.png');
  width: 68px;
  height: 68px;
}
.icon_background_flower_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_market.png');
  width: 68px;
  height: 68px;
}
.customize-option.icon_background_flower_market {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_market.png');
  width: 60px;
  height: 60px;
}
.icon_background_flower_shop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_shop.png');
  width: 68px;
  height: 68px;
}
.customize-option.icon_background_flower_shop {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_shop.png');
  width: 60px;
  height: 60px;
}
.icon_background_flowering_prairie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flowering_prairie.png');
  width: 68px;
  height: 68px;
}
.customize-option.icon_background_flowering_prairie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flowering_prairie.png');
  width: 60px;
  height: 60px;
}
.icon_background_flying_in_a_thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_in_a_thunderstorm.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_a_field_of_wildflowers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_a_field_of_wildflowers.png');
  width: 68px;
  height: 68px;
}
.customize-option.icon_background_flying_over_a_field_of_wildflowers {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_a_field_of_wildflowers.png');
  width: 60px;
  height: 60px;
}
.icon_background_flying_over_an_ancient_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_an_ancient_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_an_autumn_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_an_autumn_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_glacier {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_glacier.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_hedge_maze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_hedge_maze.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_icy_steppes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_icy_steppes.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_rocky_canyon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_rocky_canyon.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_snowy_mountains {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_snowy_mountains.png');
  width: 68px;
  height: 68px;
}
.icon_background_flying_over_the_ocean {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_the_ocean.png');
  width: 68px;
  height: 68px;
}
.icon_background_foggy_moor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_foggy_moor.png');
  width: 68px;
  height: 68px;
}
.icon_background_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_forested_lakeshore {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_forested_lakeshore.png');
  width: 68px;
  height: 68px;
}
.icon_background_fortune_tellers_shop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fortune_tellers_shop.png');
  width: 68px;
  height: 68px;
}
.icon_background_frigid_peak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frigid_peak.png');
  width: 68px;
  height: 68px;
}
.icon_background_frosty_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frosty_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_frozen_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frozen_lake.png');
  width: 68px;
  height: 68px;
}
.icon_background_frozen_polar_waters {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frozen_polar_waters.png');
  width: 68px;
  height: 68px;
}
.icon_background_garden_shed {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_garden_shed.png');
  width: 68px;
  height: 68px;
}
.icon_background_gazebo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gazebo.png');
  width: 68px;
  height: 68px;
}
.icon_background_ghost_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ghost_ship.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_autumn_leaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_autumn_leaf.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_birdhouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_birdhouse.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_book {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_book.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_dandelions {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_dandelions.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_florals {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_florals.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_seashell {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_seashell.png');
  width: 68px;
  height: 68px;
}
.icon_background_giant_wave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_wave.png');
  width: 68px;
  height: 68px;
}
.icon_background_gingerbread_house {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gingerbread_house.png');
  width: 68px;
  height: 68px;
}
.icon_background_glowing_mushroom_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_glowing_mushroom_cave.png');
  width: 68px;
  height: 68px;
}
.icon_background_golden_birdcage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_golden_birdcage.png');
  width: 68px;
  height: 68px;
}
.icon_background_gorgeous_greenhouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gorgeous_greenhouse.png');
  width: 68px;
  height: 68px;
}
.icon_background_grand_staircase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_grand_staircase.png');
  width: 68px;
  height: 68px;
}
.icon_background_graveyard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_graveyard.png');
  width: 68px;
  height: 68px;
}
.icon_background_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_green.png');
  width: 68px;
  height: 68px;
}
.icon_background_guardian_statues {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_guardian_statues.png');
  width: 68px;
  height: 68px;
}
.icon_background_gumdrop_land {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gumdrop_land.png');
  width: 68px;
  height: 68px;
}
.icon_background_habit_city_rooftops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_habit_city_rooftops.png');
  width: 68px;
  height: 68px;
}
.icon_background_habit_city_streets {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_habit_city_streets.png');
  width: 68px;
  height: 68px;
}
.icon_background_halflings_house {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_halflings_house.png');
  width: 68px;
  height: 68px;
}
.icon_background_hall_of_heroes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_hall_of_heroes.png');
  width: 68px;
  height: 68px;
}
.icon_background_harvest_feast {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_harvest_feast.png');
  width: 68px;
  height: 68px;
}
.icon_background_harvest_fields {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_harvest_fields.png');
  width: 68px;
  height: 68px;
}
.icon_background_harvest_moon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_harvest_moon.png');
  width: 68px;
  height: 68px;
}
.icon_background_haunted_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_haunted_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_haunted_house {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_haunted_house.png');
  width: 68px;
  height: 68px;
}
.icon_background_haunted_photo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_haunted_photo.png');
  width: 68px;
  height: 68px;
}
.icon_background_heart_shaped_bubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_heart_shaped_bubbles.png');
  width: 68px;
  height: 68px;
}
.icon_background_heather_field {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_heather_field.png');
  width: 68px;
  height: 68px;
}
.icon_background_herding_sheep_in_autumn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_herding_sheep_in_autumn.png');
  width: 68px;
  height: 68px;
}
.icon_background_holiday_hearth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_holiday_hearth.png');
  width: 68px;
  height: 68px;
}
.icon_background_holiday_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_holiday_market.png');
  width: 68px;
  height: 68px;
}
.icon_background_holiday_wreath {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_holiday_wreath.png');
  width: 68px;
  height: 68px;
}
.icon_background_hot_air_balloon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_hot_air_balloon.png');
  width: 68px;
  height: 68px;
}
.icon_background_hot_spring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_hot_spring.png');
  width: 68px;
  height: 68px;
}
.icon_background_ice_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ice_cave.png');
  width: 68px;
  height: 68px;
}
.icon_background_ice_palace {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ice_palace.png');
  width: 68px;
  height: 68px;
}
.icon_background_iceberg {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_iceberg.png');
  width: 68px;
  height: 68px;
}
.icon_background_icicle_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_icicle_bridge.png');
  width: 68px;
  height: 68px;
}
.icon_background_idyllic_cabin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_idyllic_cabin.png');
  width: 68px;
  height: 68px;
}
.icon_background_in_a_classroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_a_classroom.png');
  width: 68px;
  height: 68px;
}
.icon_background_in_a_painting {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_a_painting.png');
  width: 68px;
  height: 68px;
}
.icon_background_in_an_ancient_tomb {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_an_ancient_tomb.png');
  width: 68px;
  height: 68px;
}
.icon_background_in_front_of_fountain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_front_of_fountain.png');
  width: 68px;
  height: 68px;
}
.icon_background_in_the_armory {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_the_armory.png');
  width: 68px;
  height: 68px;
}
.icon_background_inside_a_crystal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_inside_a_crystal.png');
  width: 68px;
  height: 68px;
}
.icon_background_inside_a_potion_bottle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_inside_a_potion_bottle.png');
  width: 68px;
  height: 68px;
}
.icon_background_inside_an_ornament {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_inside_an_ornament.png');
  width: 68px;
  height: 68px;
}
.icon_background_iridescent_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_iridescent_clouds.png');
  width: 68px;
  height: 68px;
}
.icon_background_island_waterfalls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_island_waterfalls.png');
  width: 68px;
  height: 68px;
}
.icon_background_jungle_canopy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_jungle_canopy.png');
  width: 68px;
  height: 68px;
}
.icon_background_jungle_watering_hole {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_jungle_watering_hole.png');
  width: 68px;
  height: 68px;
}
.icon_background_kelp_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_kelp_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_lake_with_floating_lanterns {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_lake_with_floating_lanterns.png');
  width: 68px;
  height: 68px;
}
.icon_background_leafy_tree_tunnel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_leafy_tree_tunnel.png');
  width: 68px;
  height: 68px;
}
.icon_background_lighthouse_shore {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_lighthouse_shore.png');
  width: 68px;
  height: 68px;
}
.icon_background_lilypad {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_lilypad.png');
  width: 68px;
  height: 68px;
}
.icon_background_magic_beanstalk {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_magic_beanstalk.png');
  width: 68px;
  height: 68px;
}
.icon_background_magical_candles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_magical_candles.png');
  width: 68px;
  height: 68px;
}
.icon_background_magical_museum {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_magical_museum.png');
  width: 68px;
  height: 68px;
}
.icon_background_mangrove_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mangrove_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_marble_temple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_marble_temple.png');
  width: 68px;
  height: 68px;
}
.icon_background_market {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_market.png');
  width: 68px;
  height: 68px;
}
.icon_background_mask_makers_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mask_makers_workshop.png');
  width: 68px;
  height: 68px;
}
.icon_background_meandering_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_meandering_cave.png');
  width: 68px;
  height: 68px;
}
.icon_background_medieval_kitchen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_medieval_kitchen.png');
  width: 68px;
  height: 68px;
}
.icon_background_messy_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_messy_room.png');
  width: 68px;
  height: 68px;
}
.icon_background_meteor_shower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_meteor_shower.png');
  width: 68px;
  height: 69px;
}
.icon_background_midnight_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_midnight_castle.png');
  width: 68px;
  height: 68px;
}
.icon_background_midnight_clouds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_midnight_clouds.png');
  width: 68px;
  height: 68px;
}
.icon_background_midnight_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_midnight_lake.png');
  width: 68px;
  height: 68px;
}
.icon_background_mist_shrouded_mountain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mist_shrouded_mountain.png');
  width: 68px;
  height: 68px;
}
.icon_background_mistiflying_circus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mistiflying_circus.png');
  width: 68px;
  height: 68px;
}
.icon_background_misty_autumn_forest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_misty_autumn_forest.png');
  width: 68px;
  height: 68px;
}
.icon_background_monster_makers_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_monster_makers_workshop.png');
  width: 68px;
  height: 68px;
}
.icon_background_mountain_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mountain_lake.png');
  width: 68px;
  height: 68px;
}
.icon_background_mountain_pyramid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mountain_pyramid.png');
  width: 68px;
  height: 68px;
}
.icon_background_mountain_waterfall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mountain_waterfall.png');
  width: 68px;
  height: 68px;
}
.icon_background_mystical_observatory {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mystical_observatory.png');
  width: 68px;
  height: 68px;
}
.icon_background_night_dunes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_night_dunes.png');
  width: 68px;
  height: 68px;
}
.icon_background_ocean_sunrise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ocean_sunrise.png');
  width: 68px;
  height: 68px;
}
.icon_background_old_fashioned_bakery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_old_fashioned_bakery.png');
  width: 68px;
  height: 68px;
}
.icon_background_old_photo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_old_photo.png');
  width: 68px;
  height: 68px;
}
.icon_background_old_timey_basketball_court {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_old_timey_basketball_court.png');
  width: 68px;
  height: 68px;
}
.icon_background_on_a_castle_wall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_on_a_castle_wall.png');
  width: 68px;
  height: 68px;
}
.icon_background_on_tree_branch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_on_tree_branch.png');
  width: 68px;
  height: 68px;
}
.icon_background_open_waters {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_open_waters.png');
  width: 68px;
  height: 68px;
}
.icon_background_orange_grove {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_orange_grove.png');
  width: 68px;
  height: 68px;
}
.icon_background_orchard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_orchard.png');
  width: 68px;
  height: 68px;
}
.icon_background_pagodas {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pagodas.png');
  width: 68px;
  height: 68px;
}
.icon_background_palm_tree_with_fairy_lights {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_palm_tree_with_fairy_lights.png');
  width: 68px;
  height: 68px;
}
.icon_background_park_with_statue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_park_with_statue.png');
  width: 68px;
  height: 68px;
}
.icon_background_pirate_flag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pirate_flag.png');
  width: 68px;
  height: 68px;
}
.icon_background_pixelists_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pixelists_workshop.png');
  width: 68px;
  height: 68px;
}
.icon_background_potion_shop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_potion_shop.png');
  width: 68px;
  height: 68px;
}
.icon_background_productivity_plaza {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_productivity_plaza.png');
  width: 68px;
  height: 68px;
}
.icon_background_pumpkin_carriage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pumpkin_carriage.png');
  width: 68px;
  height: 68px;
}
.icon_background_pumpkin_patch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pumpkin_patch.png');
  width: 68px;
  height: 68px;
}
.icon_background_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_purple.png');
  width: 68px;
  height: 68px;
}
.icon_background_pyramids {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pyramids.png');
  width: 68px;
  height: 68px;
}
.icon_background_raging_river {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_raging_river.png');
  width: 68px;
  height: 68px;
}
.icon_background_rainbow_eucalyptus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainbow_eucalyptus.png');
  width: 68px;
  height: 68px;
}
.icon_background_rainbow_meadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainbow_meadow.png');
  width: 68px;
  height: 68px;
}
.icon_background_rainbows_end {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainbows_end.png');
  width: 68px;
  height: 68px;
}
.icon_background_rainforest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainforest.png');
  width: 68px;
  height: 68px;
}
.icon_background_rainy_barnyard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainy_barnyard.png');
  width: 68px;
  height: 68px;
}
.icon_background_rainy_city {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainy_city.png');
  width: 68px;
  height: 68px;
}
.icon_background_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_red.png');
  width: 68px;
  height: 68px;
}
.icon_background_relaxation_river {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_relaxation_river.png');
  width: 68px;
  height: 68px;
}
.icon_background_resting_in_the_inn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_resting_in_the_inn.png');
  width: 68px;
  height: 68px;
}
.icon_background_rime_ice {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rime_ice.png');
  width: 60px;
  height: 60px;
}
.icon_background_river_of_lava {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_river_of_lava.png');
  width: 68px;
  height: 68px;
}
.icon_background_rolling_hills {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rolling_hills.png');
  width: 68px;
  height: 68px;
}
.icon_background_rope_bridge {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rope_bridge.png');
  width: 68px;
  height: 68px;
}
.icon_background_rose_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rose_garden.png');
  width: 68px;
  height: 68px;
}
.icon_background_rowboat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rowboat.png');
  width: 68px;
  height: 68px;
}
.icon_background_sailboat_at_sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sailboat_at_sunset.png');
  width: 68px;
  height: 68px;
}
.icon_background_salt_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_salt_lake.png');
  width: 68px;
  height: 68px;
}
.icon_background_sandcastle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sandcastle.png');
  width: 68px;
  height: 68px;
}
.icon_background_school_of_fish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_school_of_fish.png');
  width: 68px;
  height: 68px;
}
.icon_background_scribes_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_scribes_workshop.png');
  width: 68px;
  height: 68px;
}
.icon_background_seafarer_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_seafarer_ship.png');
  width: 68px;
  height: 68px;
}
.icon_background_seaside_cliffs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_seaside_cliffs.png');
  width: 68px;
  height: 68px;
}
.icon_background_shimmering_ice_prism {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_shimmering_ice_prism.png');
  width: 68px;
  height: 68px;
}
.icon_background_shimmery_bubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_shimmery_bubbles.png');
  width: 68px;
  height: 68px;
}
.icon_background_slimy_swamp {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_slimy_swamp.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowglobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowglobe.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowman_army {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowman_army.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowy_day_fireplace {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_day_fireplace.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowy_farm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_farm.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowy_pines {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_pines.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowy_sunrise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_sunrise.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowy_temple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_temple.png');
  width: 68px;
  height: 68px;
}
.icon_background_snowy_village {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_village.png');
  width: 68px;
  height: 68px;
}
.icon_background_south_pole {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_south_pole.png');
  width: 68px;
  height: 68px;
}
.icon_background_sparkling_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sparkling_snowflake.png');
  width: 68px;
  height: 68px;
}
.icon_background_spider_web {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spider_web.png');
  width: 68px;
  height: 68px;
}
.icon_background_spiral_staircase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spiral_staircase.png');
  width: 68px;
  height: 68px;
}
.icon_background_splash_in_a_puddle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_splash_in_a_puddle.png');
  width: 68px;
  height: 68px;
}
.icon_background_spooky_hotel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spooky_hotel.png');
  width: 68px;
  height: 68px;
}
.icon_background_spooky_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spooky_ruins.png');
  width: 68px;
  height: 68px;
}
.icon_background_spooky_scarecrow_field {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spooky_scarecrow_field.png');
  width: 68px;
  height: 68px;
}
.icon_background_spring_rain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spring_rain.png');
  width: 68px;
  height: 68px;
}
.icon_background_spring_thaw {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spring_thaw.png');
  width: 68px;
  height: 68px;
}
.icon_background_springtime_lake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_springtime_lake.png');
  width: 68px;
  height: 68px;
}
.icon_background_springtime_shower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_springtime_shower.png');
  width: 68px;
  height: 68px;
}
.icon_background_stable {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stable.png');
  width: 68px;
  height: 68px;
}
.icon_background_stained_glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stained_glass.png');
  width: 68px;
  height: 68px;
}
.icon_background_starry_skies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_starry_skies.png');
  width: 68px;
  height: 68px;
}
.icon_background_starry_winter_night {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_starry_winter_night.png');
  width: 68px;
  height: 68px;
}
.icon_background_steamworks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_steamworks.png');
  width: 68px;
  height: 68px;
}
.icon_background_stoikalm_volcanoes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stoikalm_volcanoes.png');
  width: 68px;
  height: 68px;
}
.icon_background_stone_circle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stone_circle.png');
  width: 68px;
  height: 68px;
}
.icon_background_stone_tower {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stone_tower.png');
  width: 68px;
  height: 68px;
}
.icon_background_stormy_rooftops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stormy_rooftops.png');
  width: 68px;
  height: 68px;
}
.icon_background_stormy_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stormy_ship.png');
  width: 68px;
  height: 68px;
}
.icon_background_strange_sewers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_strange_sewers.png');
  width: 68px;
  height: 68px;
}
.icon_background_strawberry_patch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_strawberry_patch.png');
  width: 68px;
  height: 68px;
}
.icon_background_succulent_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_succulent_garden.png');
  width: 68px;
  height: 68px;
}
.icon_background_summer_fireworks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_summer_fireworks.png');
  width: 68px;
  height: 68px;
}
.icon_background_sunken_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunken_ship.png');
  width: 68px;
  height: 68px;
}
.icon_background_sunset_meadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunset_meadow.png');
  width: 68px;
  height: 68px;
}
.icon_background_sunset_oasis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunset_oasis.png');
  width: 68px;
  height: 68px;
}
.icon_background_sunset_savannah {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunset_savannah.png');
  width: 68px;
  height: 68px;
}
.icon_background_swarming_darkness {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_swarming_darkness.png');
  width: 68px;
  height: 68px;
}
.icon_background_swimming_among_jellyfish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_swimming_among_jellyfish.png');
  width: 68px;
  height: 68px;
}
.icon_background_tar_pits {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tar_pits.png');
  width: 68px;
  height: 68px;
}
.icon_background_tavern {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tavern.png');
  width: 68px;
  height: 68px;
}
.icon_background_tea_party {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tea_party.png');
  width: 68px;
  height: 68px;
}
.icon_background_terraced_rice_field {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_terraced_rice_field.png');
  width: 68px;
  height: 68px;
}
.icon_background_theatre_stage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_theatre_stage.png');
  width: 68px;
  height: 68px;
}
.icon_background_throne_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_throne_room.png');
  width: 68px;
  height: 68px;
}
.icon_background_thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_thunderstorm.png');
  width: 68px;
  height: 68px;
}
.icon_background_tide_pool {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tide_pool.png');
  width: 68px;
  height: 68px;
}
.icon_background_tornado {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tornado.png');
  width: 68px;
  height: 68px;
}
.icon_background_toymakers_workshop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_toymakers_workshop.png');
  width: 68px;
  height: 68px;
}
.icon_background_training_grounds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_training_grounds.png');
  width: 68px;
  height: 68px;
}
.icon_background_treasure_room {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_treasure_room.png');
  width: 68px;
  height: 68px;
}
.icon_background_tree_roots {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tree_roots.png');
  width: 68px;
  height: 68px;
}
.icon_background_treehouse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_treehouse.png');
  width: 68px;
  height: 68px;
}
.icon_background_tulip_garden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tulip_garden.png');
  width: 68px;
  height: 68px;
}
.icon_background_twinkly_lights {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_twinkly_lights.png');
  width: 68px;
  height: 68px;
}
.icon_background_twinkly_party_lights {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_twinkly_party_lights.png');
  width: 68px;
  height: 68px;
}
.icon_background_undead_hands {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_undead_hands.png');
  width: 68px;
  height: 68px;
}
.icon_background_under_wisteria {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_under_wisteria.png');
  width: 68px;
  height: 68px;
}
.icon_background_underwater_among_koi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_among_koi.png');
  width: 68px;
  height: 68px;
}
.icon_background_underwater_cave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_cave.png');
  width: 68px;
  height: 68px;
}
.icon_background_underwater_ruins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_ruins.png');
  width: 68px;
  height: 68px;
}
.icon_background_underwater_statues {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_statues.png');
  width: 68px;
  height: 68px;
}
.icon_background_underwater_vents {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_vents.png');
  width: 68px;
  height: 68px;
}
.icon_background_valentines_day_feasting_hall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_valentines_day_feasting_hall.png');
  width: 68px;
  height: 68px;
}
.icon_background_viking_ship {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_viking_ship.png');
  width: 68px;
  height: 68px;
}
.icon_background_vineyard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_vineyard.png');
  width: 68px;
  height: 68px;
}
.icon_background_violet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_violet.png');
  width: 68px;
  height: 68px;
}
.icon_background_volcano {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_volcano.png');
  width: 68px;
  height: 68px;
}
.icon_background_water_mill {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_water_mill.png');
  width: 68px;
  height: 68px;
}
.icon_background_waterfall_rock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_waterfall_rock.png');
  width: 68px;
  height: 68px;
}
.icon_background_wedding_arch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_wedding_arch.png');
  width: 68px;
  height: 68px;
}
.icon_background_windmills {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_windmills.png');
  width: 68px;
  height: 68px;
}
.icon_background_windy_autumn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_windy_autumn.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_canyon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_canyon.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_fireworks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_fireworks.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_lake_with_swans {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_lake_with_swans.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_night {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_night.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_nocturne {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_nocturne.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_storefront {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_storefront.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_town {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_town.png');
  width: 68px;
  height: 68px;
}
.icon_background_winter_waterfall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_waterfall.png');
  width: 68px;
  height: 68px;
}
.icon_background_wintry_castle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_wintry_castle.png');
  width: 68px;
  height: 68px;
}
.icon_background_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_yellow.png');
  width: 68px;
  height: 68px;
}
.hair_beard_1_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_black.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blond.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blue.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_brown.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_festive.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_frost.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_green.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_holly.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_purple.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_red.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_white.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_black.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blond.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blue.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_brown.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_festive.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_frost.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_green.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_holly.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_purple.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_red.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_white.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_black.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blond.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blue.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_brown.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_festive.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_frost.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_green.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_holly.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_purple.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_red.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_white.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_black.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blond.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blue.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_brown.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_festive.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_frost.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_green.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_holly.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_purple.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_red.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_white.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_black.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blond.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blue.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_brown.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_festive.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_frost.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_green.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_holly.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_purple.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_red.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_white.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_zombie.png');
  width: 60px;
  height: 60px;
}
.button_chair_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_black.png');
  width: 60px;
  height: 60px;
}
.button_chair_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_blue.png');
  width: 60px;
  height: 60px;
}
.button_chair_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_green.png');
  width: 60px;
  height: 60px;
}
.button_chair_handleless_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_black.png');
  width: 68px;
  height: 68px;
}
.button_chair_handleless_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_blue.png');
  width: 68px;
  height: 68px;
}
.button_chair_handleless_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_green.png');
  width: 68px;
  height: 68px;
}
.button_chair_handleless_pink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_pink.png');
  width: 68px;
  height: 68px;
}
.button_chair_handleless_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_red.png');
  width: 68px;
  height: 68px;
}
.button_chair_handleless_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_yellow.png');
  width: 68px;
  height: 68px;
}
.button_chair_pink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_pink.png');
  width: 60px;
  height: 60px;
}
.button_chair_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_red.png');
  width: 60px;
  height: 60px;
}
.button_chair_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_yellow.png');
  width: 60px;
  height: 60px;
}
.chair_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_black.png');
  width: 90px;
  height: 90px;
}
.chair_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_blue.png');
  width: 90px;
  height: 90px;
}
.chair_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_green.png');
  width: 90px;
  height: 90px;
}
.chair_handleless_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_black.png');
  width: 90px;
  height: 90px;
}
.chair_handleless_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_blue.png');
  width: 90px;
  height: 90px;
}
.chair_handleless_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_green.png');
  width: 90px;
  height: 90px;
}
.chair_handleless_pink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_pink.png');
  width: 90px;
  height: 90px;
}
.chair_handleless_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_red.png');
  width: 90px;
  height: 90px;
}
.chair_handleless_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_yellow.png');
  width: 90px;
  height: 90px;
}
.chair_pink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_pink.png');
  width: 90px;
  height: 90px;
}
.chair_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_red.png');
  width: 90px;
  height: 90px;
}
.chair_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_yellow.png');
  width: 90px;
  height: 90px;
}
.hair_flower_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_1.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_flower_1 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_1.png');
  width: 60px;
  height: 60px;
}
.hair_flower_10 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_10.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_10 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_10.png');
  width: 60px;
  height: 60px;
}
.hair_flower_11 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_11.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_11 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_11.png');
  width: 60px;
  height: 60px;
}
.hair_flower_12 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_12.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_12 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_12.png');
  width: 60px;
  height: 60px;
}
.hair_flower_13 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_13.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_13 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_13.png');
  width: 60px;
  height: 60px;
}
.hair_flower_14 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_14.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_14 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_14.png');
  width: 60px;
  height: 60px;
}
.hair_flower_15 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_15.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_15 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_15.png');
  width: 60px;
  height: 60px;
}
.hair_flower_16 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_16.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_16 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_16.png');
  width: 60px;
  height: 60px;
}
.hair_flower_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_flower_2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_2.png');
  width: 60px;
  height: 60px;
}
.hair_flower_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_3.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_flower_3 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_3.png');
  width: 60px;
  height: 60px;
}
.hair_flower_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_4.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_flower_4 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_4.png');
  width: 60px;
  height: 60px;
}
.hair_flower_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_5.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_flower_5 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_5.png');
  width: 60px;
  height: 60px;
}
.hair_flower_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_6.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_flower_6 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_6.png');
  width: 60px;
  height: 60px;
}
.hair_flower_7 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_7.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_7 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_7.png');
  width: 60px;
  height: 60px;
}
.hair_flower_8 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_8.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_8 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_8.png');
  width: 60px;
  height: 60px;
}
.hair_flower_9 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_9.png');
  width: 114px;
  height: 90px;
}
.customize-option.hair_flower_9 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_9.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_black.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blond.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_brown.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_festive.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_frost.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_green.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_holly.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_purple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_red.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_white.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_1_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_1_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_black.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blond.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_brown.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_festive.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_frost.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_green.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_holly.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_purple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_red.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_white.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_2_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_2_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_black.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blond.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_brown.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_festive.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_frost.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_green.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_holly.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_purple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_red.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_white.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_3_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_3_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_black.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blond.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_brown.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_festive.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_frost.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_green.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_holly.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_purple.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_red.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_white.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_bangs_4_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_bangs_4_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_10_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_10_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_11_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_11_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_12_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_12_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_13_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_13_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_14_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_14_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_TRUred {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_aurora {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_black {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_blond {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_blue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_brown {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_candycane {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_candycorn {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_festive {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_frost {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_ghostwhite {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_green {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_halloween {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_holly {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_hollygreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_midnight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pblue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pblue2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_peppermint {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pgreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pgreen2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_porange {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_porange2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_ppink {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_ppink2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_ppurple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_ppurple2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pumpkin {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_purple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pyellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_pyellow2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_rainbow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_red {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_snowy {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_white {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_winternight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_winterstar {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_yellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_15_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_15_zombie {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_TRUred {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_aurora {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_black {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_blond {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_blue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_brown {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_candycane {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_candycorn {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_festive {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_frost {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_ghostwhite {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_green {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_halloween {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_holly {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_hollygreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_midnight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pblue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pblue2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_peppermint {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pgreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pgreen2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_porange {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_porange2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_ppink {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_ppink2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_ppurple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_ppurple2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pumpkin {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_purple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pyellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_pyellow2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_rainbow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_red {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_snowy {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_white {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_winternight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_winterstar {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_yellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_16_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_16_zombie {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_TRUred {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_aurora {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_black {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_blond {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_blue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_brown {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_candycane {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_candycorn {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_festive {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_frost {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_ghostwhite {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_green {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_halloween {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_holly {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_hollygreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_midnight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pblue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pblue2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_peppermint {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pgreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pgreen2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_porange {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_porange2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_ppink {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_ppink2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_ppurple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_ppurple2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pumpkin {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_purple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pyellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_pyellow2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_rainbow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_red {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_snowy {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_white {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_winternight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_winterstar {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_yellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_17_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_17_zombie {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_TRUred {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_aurora {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_black {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_blond {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_blue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_brown {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_candycane {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_candycorn {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_festive {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_frost {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_ghostwhite {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_green {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_halloween {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_holly {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_hollygreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_midnight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pblue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pblue2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_peppermint {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pgreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pgreen2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_porange {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_porange2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_ppink {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_ppink2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_ppurple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_ppurple2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pumpkin {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_purple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pyellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_pyellow2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_rainbow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_red {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_snowy {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_white {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_winternight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_winterstar {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_yellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_18_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_18_zombie {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_TRUred {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_aurora {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_black {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_blond {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_blue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_brown {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_candycane {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_candycorn {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_festive {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_frost {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_ghostwhite {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_green {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_halloween {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_holly {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_hollygreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_midnight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pblue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pblue2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_peppermint {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pgreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pgreen2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_porange {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_porange2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_ppink {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_ppink2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_ppurple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_ppurple2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pumpkin {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_purple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pyellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_pyellow2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_rainbow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_red {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_snowy {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_white {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_winternight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_winterstar {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_yellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_19_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_19_zombie {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_1_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_1_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_TRUred {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_aurora {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_black {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_blond {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_blue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_brown {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_candycane {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_candycorn {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_festive {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_frost {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_ghostwhite {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_green {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_halloween {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_holly {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_hollygreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_midnight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pblue {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pblue2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_peppermint {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pgreen {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pgreen2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_porange {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_porange2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_ppink {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_ppink2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_ppurple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_ppurple2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pumpkin {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_purple {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pyellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_pyellow2 {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_rainbow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_red {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_snowy {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_white {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_winternight {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_winterstar {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_yellow {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_20_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_20_zombie {
  background-position: -25px 0;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_2_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_2_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_3_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_3_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_4_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_4_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_5_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_5_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_6_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_6_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_7_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_7_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_8_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_8_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_TRUred {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_TRUred.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_TRUred {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_TRUred.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_aurora.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_black {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_black.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_blond {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blond.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_blond {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blond.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_blue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blue.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_brown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_brown.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_brown {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_brown.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycane.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_candycane {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycane.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycorn.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_festive.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_frost.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_frost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_frost.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_ghostwhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ghostwhite.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_ghostwhite {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ghostwhite.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_green {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_green.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_halloween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_halloween.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_halloween {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_halloween.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_holly.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_hollygreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_hollygreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_hollygreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_hollygreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_midnight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_midnight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_midnight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_midnight.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_peppermint.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_peppermint {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_peppermint.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pgreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pgreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_porange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_porange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_ppink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_ppink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_ppurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_ppurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pumpkin.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_purple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_purple.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pyellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pyellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_rainbow.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_red.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_red {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_red.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_snowy.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_white {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_white.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_winternight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winternight.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_winternight {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winternight.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winterstar.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_yellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_yellow.png');
  width: 60px;
  height: 60px;
}
.hair_base_9_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_base_9_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_zombie.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_1_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_1_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_2_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_2_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_beard_3_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_beard_3_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_1_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_1_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pblue2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pblue2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pgreen2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pgreen2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_porange2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_porange2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_ppink2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_ppink2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_ppurple2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_ppurple2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple2.png');
  width: 60px;
  height: 60px;
}
.hair_mustache_2_pyellow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.hair_mustache_2_pyellow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow2.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_black {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_black.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_blue {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_blue.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_convict {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_convict.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_convict {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_convict.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_cross {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_cross.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_cross {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_cross.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_fire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_fire.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_fire {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_fire.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_green {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_green.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_horizon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_horizon.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_horizon {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_horizon.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_ocean {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_ocean.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_ocean {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_ocean.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_pink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_pink.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_pink {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_pink.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_purple {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_purple.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_rainbow {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_rainbow.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_redblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_redblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_redblue {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_redblue.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_thunder {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_thunder.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_thunder {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_thunder.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_tropical {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_tropical.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_tropical {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_tropical.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_white {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_white.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_yellow {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_yellow.png');
  width: 60px;
  height: 60px;
}
.broad_shirt_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.broad_shirt_zombie {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_zombie.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_black.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_black {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_black.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_blue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_blue.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_blue {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_blue.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_convict {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_convict.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_convict {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_convict.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_cross {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_cross.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_cross {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_cross.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_fire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_fire.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_fire {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_fire.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_green {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_green.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_green {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_green.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_horizon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_horizon.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_horizon {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_horizon.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_ocean {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_ocean.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_ocean {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_ocean.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_pink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_pink.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_pink {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_pink.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_purple.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_purple {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_purple.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_rainbow {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_rainbow.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_redblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_redblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_redblue {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_redblue.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_thunder {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_thunder.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_thunder {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_thunder.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_tropical {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_tropical.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_tropical {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_tropical.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_white {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_white.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_white {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_white.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_yellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_yellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_yellow {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_yellow.png');
  width: 60px;
  height: 60px;
}
.slim_shirt_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.slim_shirt_zombie {
  background-position: -29px -42px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_zombie.png');
  width: 60px;
  height: 60px;
}
.skin_0ff591 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_0ff591 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591.png');
  width: 60px;
  height: 60px;
}
.skin_0ff591_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_0ff591_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_2b43f6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_2b43f6 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6.png');
  width: 60px;
  height: 60px;
}
.skin_2b43f6_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_2b43f6_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_6bd049 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_6bd049 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049.png');
  width: 60px;
  height: 60px;
}
.skin_6bd049_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_6bd049_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_800ed0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_800ed0 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0.png');
  width: 60px;
  height: 60px;
}
.skin_800ed0_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_800ed0_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_915533 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_915533 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533.png');
  width: 60px;
  height: 60px;
}
.skin_915533_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_915533_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_98461a {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_98461a {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a.png');
  width: 60px;
  height: 60px;
}
.skin_98461a_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_98461a_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_aurora {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora.png');
  width: 60px;
  height: 60px;
}
.skin_aurora_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_aurora_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_bear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_bear {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear.png');
  width: 60px;
  height: 60px;
}
.skin_bear_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_bear_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_c06534 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_c06534 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534.png');
  width: 60px;
  height: 60px;
}
.skin_c06534_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_c06534_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_c3e1dc {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_c3e1dc {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc.png');
  width: 60px;
  height: 60px;
}
.skin_c3e1dc_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_c3e1dc_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_cactus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_cactus {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus.png');
  width: 60px;
  height: 60px;
}
.skin_cactus_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_cactus_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_candycorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_candycorn {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn.png');
  width: 60px;
  height: 60px;
}
.skin_candycorn_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_candycorn_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_clownfish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_clownfish {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish.png');
  width: 60px;
  height: 60px;
}
.skin_clownfish_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_clownfish_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_d7a9f7 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_d7a9f7 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7.png');
  width: 60px;
  height: 60px;
}
.skin_d7a9f7_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_d7a9f7_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_dapper {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_dapper {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper.png');
  width: 60px;
  height: 60px;
}
.skin_dapper_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_dapper_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_ddc994 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ddc994 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994.png');
  width: 60px;
  height: 60px;
}
.skin_ddc994_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ddc994_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_deepocean {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_deepocean {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean.png');
  width: 60px;
  height: 60px;
}
.skin_deepocean_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_deepocean_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_ea8349 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ea8349 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349.png');
  width: 60px;
  height: 60px;
}
.skin_ea8349_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ea8349_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_eb052b {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_eb052b {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b.png');
  width: 60px;
  height: 60px;
}
.skin_eb052b_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_eb052b_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_f5a76e {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_f5a76e {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e.png');
  width: 60px;
  height: 60px;
}
.skin_f5a76e_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_f5a76e_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_f5d70f {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_f5d70f {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f.png');
  width: 60px;
  height: 60px;
}
.skin_f5d70f_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_f5d70f_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_f69922 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_f69922 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922.png');
  width: 60px;
  height: 60px;
}
.skin_f69922_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_f69922_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_festive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_festive {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive.png');
  width: 60px;
  height: 60px;
}
.skin_festive_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_festive_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_fox {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_fox {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox.png');
  width: 60px;
  height: 60px;
}
.skin_fox_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_fox_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ghost {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost.png');
  width: 60px;
  height: 60px;
}
.skin_ghost_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ghost_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_holly {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly.png');
  width: 60px;
  height: 60px;
}
.skin_holly_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_holly_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_lion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_lion {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion.png');
  width: 60px;
  height: 60px;
}
.skin_lion_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_lion_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_merblue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_merblue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue.png');
  width: 60px;
  height: 60px;
}
.skin_merblue_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_merblue_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_mergold {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_mergold {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold.png');
  width: 60px;
  height: 60px;
}
.skin_mergold_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_mergold_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_mergreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_mergreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen.png');
  width: 60px;
  height: 60px;
}
.skin_mergreen_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_mergreen_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_merruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_merruby {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby.png');
  width: 60px;
  height: 60px;
}
.skin_merruby_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_merruby_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_monster {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_monster {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster.png');
  width: 60px;
  height: 60px;
}
.skin_monster_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_monster_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_ogre {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ogre {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre.png');
  width: 60px;
  height: 60px;
}
.skin_ogre_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_ogre_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_panda {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_panda {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda.png');
  width: 60px;
  height: 60px;
}
.skin_panda_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_panda_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelBlue {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue.png');
  width: 60px;
  height: 60px;
}
.skin_pastelBlue_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelBlue_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelGreen {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelGreen {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen.png');
  width: 60px;
  height: 60px;
}
.skin_pastelGreen_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelGreen_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelOrange {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelOrange {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange.png');
  width: 60px;
  height: 60px;
}
.skin_pastelOrange_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelOrange_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelPink {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink.png');
  width: 60px;
  height: 60px;
}
.skin_pastelPink_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelPink_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelPurple {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple.png');
  width: 60px;
  height: 60px;
}
.skin_pastelPurple_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelPurple_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelRainbowChevron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelRainbowChevron {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron.png');
  width: 60px;
  height: 60px;
}
.skin_pastelRainbowChevron_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelRainbowChevron_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelRainbowDiagonal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelRainbowDiagonal {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal.png');
  width: 60px;
  height: 60px;
}
.skin_pastelRainbowDiagonal_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelRainbowDiagonal_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pastelYellow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelYellow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow.png');
  width: 60px;
  height: 60px;
}
.skin_pastelYellow_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pastelYellow_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pig {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig.png');
  width: 60px;
  height: 60px;
}
.skin_pig_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pig_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_polar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_polar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar.png');
  width: 60px;
  height: 60px;
}
.skin_polar_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_polar_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pumpkin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pumpkin {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin.png');
  width: 60px;
  height: 60px;
}
.skin_pumpkin2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pumpkin2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2.png');
  width: 60px;
  height: 60px;
}
.skin_pumpkin2_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pumpkin2_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_pumpkin_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_pumpkin_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_rainbow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow.png');
  width: 60px;
  height: 60px;
}
.skin_rainbow_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_rainbow_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_reptile {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_reptile {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile.png');
  width: 60px;
  height: 60px;
}
.skin_reptile_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_reptile_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_shadow {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow.png');
  width: 60px;
  height: 60px;
}
.skin_shadow2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_shadow2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2.png');
  width: 60px;
  height: 60px;
}
.skin_shadow2_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_shadow2_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_shadow_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_shadow_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_shark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_shark {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark.png');
  width: 60px;
  height: 60px;
}
.skin_shark_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_shark_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_skeleton {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton.png');
  width: 60px;
  height: 60px;
}
.skin_skeleton2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_skeleton2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2.png');
  width: 60px;
  height: 60px;
}
.skin_skeleton2_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_skeleton2_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_skeleton_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_skeleton_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_snowy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_snowy {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy.png');
  width: 60px;
  height: 60px;
}
.skin_snowy_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_snowy_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_sugar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_sugar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar.png');
  width: 60px;
  height: 60px;
}
.skin_sugar_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_sugar_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_tiger {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_tiger {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger.png');
  width: 60px;
  height: 60px;
}
.skin_tiger_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_tiger_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_transparent {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_transparent {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent.png');
  width: 60px;
  height: 60px;
}
.skin_transparent_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_transparent_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_tropicalwater {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_tropicalwater {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater.png');
  width: 60px;
  height: 60px;
}
.skin_tropicalwater_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_tropicalwater_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_winterstar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_winterstar {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar.png');
  width: 60px;
  height: 60px;
}
.skin_winterstar_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_winterstar_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_wolf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_wolf {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf.png');
  width: 60px;
  height: 60px;
}
.skin_wolf_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_wolf_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_zombie {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie.png');
  width: 60px;
  height: 60px;
}
.skin_zombie2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_zombie2 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2.png');
  width: 60px;
  height: 60px;
}
.skin_zombie2_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_zombie2_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2_sleep.png');
  width: 60px;
  height: 60px;
}
.skin_zombie_sleep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie_sleep.png');
  width: 90px;
  height: 90px;
}
.customize-option.skin_zombie_sleep {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie_sleep.png');
  width: 60px;
  height: 60px;
}
.body_armoire_clownsBowtie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_armoire_clownsBowtie.png');
  width: 114px;
  height: 87px;
}
.body_armoire_cozyScarf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_armoire_cozyScarf.png');
  width: 114px;
  height: 87px;
}
.body_armoire_lifeguardWhistle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_armoire_lifeguardWhistle.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_alchemistsRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_alchemistsRobe.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_antiProcrastinationArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_antiProcrastinationArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_astronomersRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_astronomersRobe.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_autumnEnchantersCloak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_autumnEnchantersCloak.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_bagpipersKilt {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_bagpipersKilt.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_barristerRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_barristerRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_baseballUniform {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_baseballUniform.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_basicArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_basicArcherArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_basketballUniform {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_basketballUniform.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_bathtub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_bathtub.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_blueMoonShozoku {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_blueMoonShozoku.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_bluePartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_bluePartyDress.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_boatingJacket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_boatingJacket.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_boxArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_boxArmor.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_candlestickMakerOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_candlestickMakerOutfit.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_cannoneerRags {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_cannoneerRags.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_chefsJacket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_chefsJacket.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_clownsMotley {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_clownsMotley.png');
  width: 114px;
  height: 87px;
}
.broad_armor_armoire_coachDriverLivery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_coachDriverLivery.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_cobblersCoveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_cobblersCoveralls.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_coverallsOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_coverallsOfBookbinding.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_crystalCrescentRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_crystalCrescentRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_doubletOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_doubletOfClubs.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_dragonTamerArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_dragonTamerArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_dressingGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_dressingGown.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_duffleCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_duffleCoat.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_falconerArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_falconerArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_fancyPirateSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_fancyPirateSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_farrierOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_farrierOutfit.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_fiddlersCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_fiddlersCoat.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_flutteryFrock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_flutteryFrock.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_gardenersOveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_gardenersOveralls.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_gladiatorArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_gladiatorArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_glassblowersCoveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_glassblowersCoveralls.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_goldenToga {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_goldenToga.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_gownOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_gownOfHearts.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_graduateRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_graduateRobe.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_greenFestivalYukata {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_greenFestivalYukata.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_guardiansGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_guardiansGown.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_heraldsTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_heraldsTunic.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_heroicHerbalistRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_heroicHerbalistRobe.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_hornedIronArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_hornedIronArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_invernessCape {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_invernessCape.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_ironBlueArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_ironBlueArcherArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_jadeArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jadeArmor.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_jesterCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jesterCostume.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_jeweledArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jeweledArcherArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_jewelersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jewelersApron.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_lamplightersGreatcoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_lamplightersGreatcoat.png');
  width: 114px;
  height: 87px;
}
.broad_armor_armoire_layerCakeArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_layerCakeArmor.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_lunarArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_lunarArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_matchMakersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_matchMakersApron.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_medievalLaundryDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_medievalLaundryDress.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_medievalLaundryOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_medievalLaundryOutfit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_merchantTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_merchantTunic.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_minerOveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_minerOveralls.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_mushroomDruidArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_mushroomDruidArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_nephriteArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_nephriteArmor.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_ogreArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_ogreArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_paintersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_paintersApron.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_pirateOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_pirateOutfit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_piraticalPrincessGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_piraticalPrincessGown.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_plagueDoctorOvercoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_plagueDoctorOvercoat.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_ramFleeceRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_ramFleeceRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_rancherRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_rancherRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_redPartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_redPartyDress.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_robeOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_robeOfDiamonds.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_robeOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_robeOfSpades.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_royalRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_royalRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_scribesRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_scribesRobe.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_shadowMastersRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shadowMastersRobe.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_shawlCollarCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shawlCollarCoat.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_sheetGhostCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_sheetGhostCostume.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_shepherdRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shepherdRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_shootingStarCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shootingStarCostume.png');
  width: 117px;
  height: 120px;
}
.broad_armor_armoire_softBlackSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softBlackSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_softBlueSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softBlueSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_softGreenSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softGreenSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_softPinkSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softPinkSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_softRedSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softRedSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_softVioletSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softVioletSuit.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_strawRaincoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_strawRaincoat.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_stripedSwimsuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_stripedSwimsuit.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_swanDancerTutu {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_swanDancerTutu.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_teaGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_teaGown.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_vermilionArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_vermilionArcherArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_vernalVestment {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_vernalVestment.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_vikingTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_vikingTunic.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_woodElfArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_woodElfArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_armoire_wovenRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_wovenRobes.png');
  width: 114px;
  height: 90px;
}
.broad_armor_armoire_yellowPartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_yellowPartyDress.png');
  width: 90px;
  height: 90px;
}
.eyewear_armoire_clownsNose {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_clownsNose.png');
  width: 114px;
  height: 87px;
}
.eyewear_armoire_comedyMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_comedyMask.png');
  width: 114px;
  height: 90px;
}
.eyewear_armoire_goofyGlasses {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_goofyGlasses.png');
  width: 90px;
  height: 90px;
}
.eyewear_armoire_jewelersEyeLoupe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_jewelersEyeLoupe.png');
  width: 114px;
  height: 90px;
}
.eyewear_armoire_plagueDoctorMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_plagueDoctorMask.png');
  width: 90px;
  height: 90px;
}
.eyewear_armoire_tragedyMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_tragedyMask.png');
  width: 114px;
  height: 90px;
}
.headAccessory_armoire_comicalArrow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_armoire_comicalArrow.png');
  width: 90px;
  height: 90px;
}
.headAccessory_armoire_gogglesOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_armoire_gogglesOfBookbinding.png');
  width: 114px;
  height: 90px;
}
.head_armoire_alchemistsHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_alchemistsHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_antiProcrastinationHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_antiProcrastinationHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_astronomersHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_astronomersHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_barristerWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_barristerWig.png');
  width: 90px;
  height: 90px;
}
.head_armoire_baseballCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_baseballCap.png');
  width: 114px;
  height: 90px;
}
.head_armoire_basicArcherCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_basicArcherCap.png');
  width: 90px;
  height: 90px;
}
.head_armoire_beaniePropellerHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_beaniePropellerHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_bigWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_bigWig.png');
  width: 90px;
  height: 90px;
}
.head_armoire_birdsNest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_birdsNest.png');
  width: 90px;
  height: 90px;
}
.head_armoire_blackCat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blackCat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_blackFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blackFloppyHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_blueFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blueFloppyHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_blueHairbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blueHairbow.png');
  width: 90px;
  height: 90px;
}
.head_armoire_blueMoonHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blueMoonHelm.png');
  width: 114px;
  height: 90px;
}
.head_armoire_boaterHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_boaterHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_candlestickMakerHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_candlestickMakerHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_cannoneerBandanna {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_cannoneerBandanna.png');
  width: 90px;
  height: 90px;
}
.head_armoire_capOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_capOfClubs.png');
  width: 114px;
  height: 90px;
}
.head_armoire_clownsWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_clownsWig.png');
  width: 114px;
  height: 87px;
}
.head_armoire_coachDriversHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_coachDriversHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_crownOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_crownOfDiamonds.png');
  width: 114px;
  height: 90px;
}
.head_armoire_crownOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_crownOfHearts.png');
  width: 90px;
  height: 90px;
}
.head_armoire_crystalCrescentHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_crystalCrescentHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_deerstalkerCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_deerstalkerCap.png');
  width: 114px;
  height: 90px;
}
.head_armoire_dragonTamerHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_dragonTamerHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_earflapHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_earflapHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_falconerCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_falconerCap.png');
  width: 90px;
  height: 90px;
}
.head_armoire_fancyPirateHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_fancyPirateHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_fiddlersCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_fiddlersCap.png');
  width: 114px;
  height: 90px;
}
.head_armoire_flutteryWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_flutteryWig.png');
  width: 114px;
  height: 90px;
}
.head_armoire_frostedHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_frostedHelm.png');
  width: 114px;
  height: 90px;
}
.head_armoire_gardenersSunHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_gardenersSunHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_gladiatorHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_gladiatorHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_glassblowersHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_glassblowersHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_glengarry {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_glengarry.png');
  width: 114px;
  height: 90px;
}
.head_armoire_goldenLaurels {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_goldenLaurels.png');
  width: 90px;
  height: 90px;
}
.head_armoire_graduateCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_graduateCap.png');
  width: 90px;
  height: 90px;
}
.head_armoire_greenFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_greenFloppyHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_guardiansBonnet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_guardiansBonnet.png');
  width: 114px;
  height: 87px;
}
.head_armoire_heraldsCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_heraldsCap.png');
  width: 114px;
  height: 90px;
}
.head_armoire_heroicHerbalistCrispinette {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_heroicHerbalistCrispinette.png');
  width: 90px;
  height: 90px;
}
.head_armoire_hornedIronHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_hornedIronHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_hornsOfAutumn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_hornsOfAutumn.png');
  width: 114px;
  height: 90px;
}
.head_armoire_ironBlueArcherHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_ironBlueArcherHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_jadeHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_jadeHelm.png');
  width: 114px;
  height: 90px;
}
.head_armoire_jesterCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_jesterCap.png');
  width: 90px;
  height: 90px;
}
.head_armoire_jeweledArcherHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_jeweledArcherHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_lamplightersTopHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_lamplightersTopHat.png');
  width: 114px;
  height: 87px;
}
.head_armoire_lunarCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_lunarCrown.png');
  width: 90px;
  height: 90px;
}
.head_armoire_matchMakersBeret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_matchMakersBeret.png');
  width: 114px;
  height: 90px;
}
.head_armoire_medievalLaundryCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_medievalLaundryCap.png');
  width: 114px;
  height: 90px;
}
.head_armoire_medievalLaundryHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_medievalLaundryHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_merchantChaperon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_merchantChaperon.png');
  width: 90px;
  height: 90px;
}
.head_armoire_minerHelmet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_minerHelmet.png');
  width: 90px;
  height: 90px;
}
.head_armoire_mushroomDruidCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_mushroomDruidCap.png');
  width: 90px;
  height: 90px;
}
.head_armoire_nephriteHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_nephriteHelm.png');
  width: 114px;
  height: 90px;
}
.head_armoire_nightcap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_nightcap.png');
  width: 114px;
  height: 90px;
}
.head_armoire_ogreMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_ogreMask.png');
  width: 90px;
  height: 90px;
}
.head_armoire_orangeCat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_orangeCat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_paintersBeret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_paintersBeret.png');
  width: 114px;
  height: 90px;
}
.head_armoire_paperBag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_paperBag.png');
  width: 90px;
  height: 90px;
}
.head_armoire_pinkFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_pinkFloppyHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_piraticalPrincessHeaddress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_piraticalPrincessHeaddress.png');
  width: 114px;
  height: 90px;
}
.head_armoire_plagueDoctorHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_plagueDoctorHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_ramHeaddress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_ramHeaddress.png');
  width: 90px;
  height: 90px;
}
.head_armoire_rancherHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_rancherHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_redFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_redFloppyHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_redHairbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_redHairbow.png');
  width: 90px;
  height: 90px;
}
.head_armoire_regalCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_regalCrown.png');
  width: 114px;
  height: 90px;
}
.head_armoire_royalCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_royalCrown.png');
  width: 90px;
  height: 90px;
}
.head_armoire_rubberDucky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_rubberDucky.png');
  width: 114px;
  height: 90px;
}
.head_armoire_shadowMastersHood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_shadowMastersHood.png');
  width: 114px;
  height: 90px;
}
.head_armoire_shepherdHeaddress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_shepherdHeaddress.png');
  width: 90px;
  height: 90px;
}
.head_armoire_shootingStarCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_shootingStarCrown.png');
  width: 117px;
  height: 120px;
}
.head_armoire_strawRainHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_strawRainHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_swanFeatherCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_swanFeatherCrown.png');
  width: 90px;
  height: 90px;
}
.head_armoire_teaHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_teaHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_toqueBlanche {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_toqueBlanche.png');
  width: 114px;
  height: 90px;
}
.head_armoire_tricornHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_tricornHat.png');
  width: 114px;
  height: 90px;
}
.head_armoire_veilOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_veilOfSpades.png');
  width: 114px;
  height: 90px;
}
.head_armoire_vermilionArcherHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_vermilionArcherHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_vernalHennin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_vernalHennin.png');
  width: 114px;
  height: 90px;
}
.head_armoire_vikingHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_vikingHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_violetFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_violetFloppyHat.png');
  width: 90px;
  height: 90px;
}
.head_armoire_woodElfHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_woodElfHelm.png');
  width: 90px;
  height: 90px;
}
.head_armoire_yellowHairbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_yellowHairbow.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_alchemistsScale {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_alchemistsScale.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_antiProcrastinationShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_antiProcrastinationShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_bagpipes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_bagpipes.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_baseballGlove {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_baseballGlove.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_basketball {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_basketball.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_birthdayBanner {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_birthdayBanner.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_blueCottonCandyFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_blueCottonCandyFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_blueMoonSai {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_blueMoonSai.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_bouncyBubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_bouncyBubbles.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_bubblingCauldron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_bubblingCauldron.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_chocolateFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_chocolateFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_clownsBalloons {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_clownsBalloons.png');
  width: 114px;
  height: 87px;
}
.shield_armoire_darkAutumnFlame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_darkAutumnFlame.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_dragonTamerShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_dragonTamerShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_dustpan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_dustpan.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_fancyBlownGlassVase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fancyBlownGlassVase.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_fancyShoe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fancyShoe.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_festivalParasol {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_festivalParasol.png');
  width: 114px;
  height: 87px;
}
.shield_armoire_fiddle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fiddle.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_fishFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fishFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_floralBouquet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_floralBouquet.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_flutteryFan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_flutteryFan.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_gardenersSpade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_gardenersSpade.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_gladiatorShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_gladiatorShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_goldenBaton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_goldenBaton.png');
  width: 99px;
  height: 90px;
}
.shield_armoire_handmadeCandlestick {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_handmadeCandlestick.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_heraldsMessageScroll {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_heraldsMessageScroll.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_hobbyHorse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_hobbyHorse.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_honeyFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_honeyFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_horseshoe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_horseshoe.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_jewelersPliers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_jewelersPliers.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_lifeBuoy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_lifeBuoy.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_masteredShadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_masteredShadow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_meatFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_meatFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_medievalLaundry {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_medievalLaundry.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_midnightShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_midnightShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_mightyPizza {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mightyPizza.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_mightyQuill {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mightyQuill.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_milkFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_milkFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_mortarAndPestle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mortarAndPestle.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_mushroomDruidShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mushroomDruidShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_mysticLamp {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mysticLamp.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_paintersPalette {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_paintersPalette.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_perchingFalcon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_perchingFalcon.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_perfectMatch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_perfectMatch.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_pinkCottonCandyFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_pinkCottonCandyFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_piratesCompanion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_piratesCompanion.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_piraticalSkullShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_piraticalSkullShield.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_polishedPocketwatch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_polishedPocketwatch.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_potatoFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_potatoFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_ramHornShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_ramHornShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_redRose {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_redRose.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_rottenMeatFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_rottenMeatFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_royalCane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_royalCane.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_sandyBucket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_sandyBucket.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_shieldOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_shieldOfDiamonds.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_snareDrum {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_snareDrum.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_softBlackPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softBlackPillow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_softBluePillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softBluePillow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_softGreenPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softGreenPillow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_softPinkPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softPinkPillow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_softRedPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softRedPillow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_softVioletPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softVioletPillow.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_spanishGuitar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_spanishGuitar.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_strawberryFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_strawberryFood.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_swanFeatherFan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_swanFeatherFan.png');
  width: 114px;
  height: 87px;
}
.shield_armoire_teaKettle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_teaKettle.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_treasureMap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_treasureMap.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_trustyUmbrella {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_trustyUmbrella.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_unfinishedTome {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_unfinishedTome.png');
  width: 114px;
  height: 90px;
}
.shield_armoire_vikingShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_vikingShield.png');
  width: 90px;
  height: 90px;
}
.shield_armoire_weaversShuttle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_weaversShuttle.png');
  width: 114px;
  height: 90px;
}
.shop_armor_armoire_alchemistsRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_alchemistsRobe.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_antiProcrastinationArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_antiProcrastinationArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_astronomersRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_astronomersRobe.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_autumnEnchantersCloak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_autumnEnchantersCloak.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_bagpipersKilt {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_bagpipersKilt.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_barristerRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_barristerRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_baseballUniform {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_baseballUniform.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_basicArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_basicArcherArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_basketballUniform {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_basketballUniform.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_bathtub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_bathtub.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_blueMoonShozoku {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_blueMoonShozoku.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_bluePartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_bluePartyDress.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_boatingJacket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_boatingJacket.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_boxArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_boxArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_candlestickMakerOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_candlestickMakerOutfit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_cannoneerRags {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_cannoneerRags.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_chefsJacket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_chefsJacket.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_clownsMotley {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_clownsMotley.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_coachDriverLivery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_coachDriverLivery.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_cobblersCoveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_cobblersCoveralls.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_coverallsOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_coverallsOfBookbinding.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_crystalCrescentRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_crystalCrescentRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_doubletOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_doubletOfClubs.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_dragonTamerArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_dragonTamerArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_dressingGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_dressingGown.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_duffleCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_duffleCoat.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_falconerArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_falconerArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_fancyPirateSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_fancyPirateSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_farrierOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_farrierOutfit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_fiddlersCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_fiddlersCoat.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_flutteryFrock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_flutteryFrock.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_gardenersOveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_gardenersOveralls.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_gladiatorArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_gladiatorArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_glassblowersCoveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_glassblowersCoveralls.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_goldenToga {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_goldenToga.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_gownOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_gownOfHearts.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_graduateRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_graduateRobe.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_greenFestivalYukata {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_greenFestivalYukata.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_guardiansGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_guardiansGown.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_heraldsTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_heraldsTunic.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_heroicHerbalistRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_heroicHerbalistRobe.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_hornedIronArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_hornedIronArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_invernessCape {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_invernessCape.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_ironBlueArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_ironBlueArcherArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_jadeArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jadeArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_jesterCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jesterCostume.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_jeweledArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jeweledArcherArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_jewelersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jewelersApron.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_lamplightersGreatcoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_lamplightersGreatcoat.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_layerCakeArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_layerCakeArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_lunarArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_lunarArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_matchMakersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_matchMakersApron.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_medievalLaundryDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_medievalLaundryDress.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_medievalLaundryOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_medievalLaundryOutfit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_merchantTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_merchantTunic.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_minerOveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_minerOveralls.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_mushroomDruidArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_mushroomDruidArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_nephriteArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_nephriteArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_ogreArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_ogreArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_paintersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_paintersApron.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_pirateOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_pirateOutfit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_piraticalPrincessGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_piraticalPrincessGown.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_plagueDoctorOvercoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_plagueDoctorOvercoat.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_ramFleeceRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_ramFleeceRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_rancherRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_rancherRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_redPartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_redPartyDress.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_robeOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_robeOfDiamonds.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_robeOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_robeOfSpades.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_royalRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_royalRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_scribesRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_scribesRobe.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_shadowMastersRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shadowMastersRobe.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_shawlCollarCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shawlCollarCoat.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_sheetGhostCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_sheetGhostCostume.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_shepherdRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shepherdRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_shootingStarCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shootingStarCostume.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_softBlackSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softBlackSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_softBlueSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softBlueSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_softGreenSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softGreenSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_softPinkSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softPinkSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_softRedSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softRedSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_softVioletSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softVioletSuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_strawRaincoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_strawRaincoat.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_stripedSwimsuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_stripedSwimsuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_swanDancerTutu {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_swanDancerTutu.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_teaGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_teaGown.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_vermilionArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_vermilionArcherArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_vernalVestment {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_vernalVestment.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_vikingTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_vikingTunic.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_woodElfArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_woodElfArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_wovenRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_wovenRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_armoire_yellowPartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_yellowPartyDress.png');
  width: 68px;
  height: 68px;
}
.shop_body_armoire_clownsBowtie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_armoire_clownsBowtie.png');
  width: 68px;
  height: 68px;
}
.shop_body_armoire_cozyScarf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_armoire_cozyScarf.png');
  width: 68px;
  height: 68px;
}
.shop_body_armoire_lifeguardWhistle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_armoire_lifeguardWhistle.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_armoire_clownsNose {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_clownsNose.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_armoire_comedyMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_comedyMask.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_armoire_goofyGlasses {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_goofyGlasses.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_armoire_jewelersEyeLoupe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_jewelersEyeLoupe.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_armoire_plagueDoctorMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_plagueDoctorMask.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_armoire_tragedyMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_tragedyMask.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_armoire_comicalArrow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_armoire_comicalArrow.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_armoire_gogglesOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_armoire_gogglesOfBookbinding.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_alchemistsHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_alchemistsHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_antiProcrastinationHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_antiProcrastinationHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_astronomersHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_astronomersHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_barristerWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_barristerWig.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_baseballCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_baseballCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_basicArcherCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_basicArcherCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_beaniePropellerHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_beaniePropellerHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_bigWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_bigWig.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_birdsNest {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_birdsNest.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_blackCat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blackCat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_blackFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blackFloppyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_blueFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blueFloppyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_blueHairbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blueHairbow.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_blueMoonHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blueMoonHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_boaterHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_boaterHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_candlestickMakerHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_candlestickMakerHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_cannoneerBandanna {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_cannoneerBandanna.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_capOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_capOfClubs.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_clownsWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_clownsWig.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_coachDriversHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_coachDriversHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_crownOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_crownOfDiamonds.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_crownOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_crownOfHearts.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_crystalCrescentHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_crystalCrescentHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_deerstalkerCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_deerstalkerCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_dragonTamerHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_dragonTamerHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_earflapHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_earflapHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_falconerCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_falconerCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_fancyPirateHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_fancyPirateHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_fiddlersCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_fiddlersCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_flutteryWig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_flutteryWig.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_frostedHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_frostedHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_gardenersSunHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_gardenersSunHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_gladiatorHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_gladiatorHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_glassblowersHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_glassblowersHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_glengarry {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_glengarry.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_goldenLaurels {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_goldenLaurels.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_graduateCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_graduateCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_greenFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_greenFloppyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_guardiansBonnet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_guardiansBonnet.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_heraldsCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_heraldsCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_heroicHerbalistCrispinette {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_heroicHerbalistCrispinette.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_hornedIronHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_hornedIronHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_hornsOfAutumn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_hornsOfAutumn.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_ironBlueArcherHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_ironBlueArcherHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_jadeHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_jadeHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_jesterCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_jesterCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_jeweledArcherHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_jeweledArcherHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_lamplightersTopHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_lamplightersTopHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_lunarCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_lunarCrown.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_matchMakersBeret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_matchMakersBeret.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_medievalLaundryCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_medievalLaundryCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_medievalLaundryHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_medievalLaundryHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_merchantChaperon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_merchantChaperon.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_minerHelmet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_minerHelmet.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_mushroomDruidCap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_mushroomDruidCap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_nephriteHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_nephriteHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_nightcap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_nightcap.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_ogreMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_ogreMask.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_orangeCat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_orangeCat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_paintersBeret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_paintersBeret.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_paperBag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_paperBag.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_pinkFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_pinkFloppyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_piraticalPrincessHeaddress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_piraticalPrincessHeaddress.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_plagueDoctorHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_plagueDoctorHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_ramHeaddress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_ramHeaddress.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_rancherHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_rancherHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_redFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_redFloppyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_redHairbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_redHairbow.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_regalCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_regalCrown.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_royalCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_royalCrown.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_rubberDucky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_rubberDucky.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_shadowMastersHood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_shadowMastersHood.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_shepherdHeaddress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_shepherdHeaddress.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_shootingStarCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_shootingStarCrown.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_strawRainHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_strawRainHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_swanFeatherCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_swanFeatherCrown.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_teaHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_teaHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_toqueBlanche {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_toqueBlanche.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_tricornHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_tricornHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_veilOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_veilOfSpades.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_vermilionArcherHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_vermilionArcherHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_vernalHennin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_vernalHennin.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_vikingHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_vikingHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_violetFloppyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_violetFloppyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_woodElfHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_woodElfHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_armoire_yellowHairbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_yellowHairbow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_alchemistsScale {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_alchemistsScale.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_antiProcrastinationShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_antiProcrastinationShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_bagpipes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_bagpipes.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_baseballGlove {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_baseballGlove.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_basketball {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_basketball.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_birthdayBanner {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_birthdayBanner.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_blueCottonCandyFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_blueCottonCandyFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_blueMoonSai {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_blueMoonSai.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_bouncyBubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_bouncyBubbles.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_bubblingCauldron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_bubblingCauldron.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_chocolateFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_chocolateFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_clownsBalloons {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_clownsBalloons.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_darkAutumnFlame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_darkAutumnFlame.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_dragonTamerShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_dragonTamerShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_dustpan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_dustpan.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_fancyBlownGlassVase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fancyBlownGlassVase.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_fancyShoe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fancyShoe.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_festivalParasol {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_festivalParasol.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_fiddle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fiddle.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_fishFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fishFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_floralBouquet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_floralBouquet.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_flutteryFan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_flutteryFan.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_gardenersSpade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_gardenersSpade.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_gladiatorShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_gladiatorShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_goldenBaton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_goldenBaton.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_handmadeCandlestick {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_handmadeCandlestick.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_heraldsMessageScroll {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_heraldsMessageScroll.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_hobbyHorse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_hobbyHorse.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_honeyFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_honeyFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_horseshoe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_horseshoe.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_jewelersPliers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_jewelersPliers.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_lifeBuoy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_lifeBuoy.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_masteredShadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_masteredShadow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_meatFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_meatFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_medievalLaundry {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_medievalLaundry.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_midnightShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_midnightShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_mightyPizza {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mightyPizza.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_mightyQuill {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mightyQuill.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_milkFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_milkFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_mortarAndPestle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mortarAndPestle.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_mushroomDruidShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mushroomDruidShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_mysticLamp {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mysticLamp.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_paintersPalette {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_paintersPalette.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_perchingFalcon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_perchingFalcon.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_perfectMatch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_perfectMatch.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_pinkCottonCandyFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_pinkCottonCandyFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_piratesCompanion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_piratesCompanion.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_piraticalSkullShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_piraticalSkullShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_polishedPocketwatch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_polishedPocketwatch.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_potatoFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_potatoFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_ramHornShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_ramHornShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_redRose {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_redRose.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_rottenMeatFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_rottenMeatFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_royalCane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_royalCane.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_sandyBucket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_sandyBucket.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_shieldOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_shieldOfDiamonds.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_snareDrum {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_snareDrum.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_softBlackPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softBlackPillow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_softBluePillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softBluePillow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_softGreenPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softGreenPillow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_softPinkPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softPinkPillow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_softRedPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softRedPillow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_softVioletPillow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softVioletPillow.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_spanishGuitar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_spanishGuitar.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_strawberryFood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_strawberryFood.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_swanFeatherFan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_swanFeatherFan.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_teaKettle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_teaKettle.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_treasureMap {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_treasureMap.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_trustyUmbrella {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_trustyUmbrella.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_unfinishedTome {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_unfinishedTome.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_vikingShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_vikingShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_armoire_weaversShuttle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_weaversShuttle.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_alchemistsDistiller {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_alchemistsDistiller.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_arcaneScroll {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_arcaneScroll.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_astronomersTelescope {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_astronomersTelescope.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_bambooCane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_bambooCane.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_barristerGavel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_barristerGavel.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_baseballBat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_baseballBat.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_basicCrossbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_basicCrossbow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_basicLongbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_basicLongbow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_batWand {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_batWand.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_battleAxe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_battleAxe.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_beachFlag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_beachFlag.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_blueKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_blueKite.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_blueLongbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_blueLongbow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_blueMoonSai {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_blueMoonSai.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_buoyantBubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_buoyantBubbles.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_cannon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_cannon.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_chefsSpoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_chefsSpoon.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_clubOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_clubOfClubs.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_coachDriversWhip {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_coachDriversWhip.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_cobblersHammer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_cobblersHammer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_crystalCrescentStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_crystalCrescentStaff.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_enchantersStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_enchantersStaff.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_eveningTea {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_eveningTea.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_featherDuster {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_featherDuster.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_festivalFirecracker {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_festivalFirecracker.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_fiddlersBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_fiddlersBow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_finelyCutGem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_finelyCutGem.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_floridFan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_floridFan.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_flutteryArmy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_flutteryArmy.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_forestFungusStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_forestFungusStaff.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_gardenersWateringCan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_gardenersWateringCan.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_glassblowersBlowpipe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_glassblowersBlowpipe.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_glowingSpear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_glowingSpear.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_goldWingStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_goldWingStaff.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_greenKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_greenKite.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_guardiansCrook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_guardiansCrook.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_habiticanDiploma {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_habiticanDiploma.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_handyHook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_handyHook.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_happyBanner {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_happyBanner.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_heraldsBuisine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_heraldsBuisine.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_hoofClippers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_hoofClippers.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_huntingHorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_huntingHorn.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_ironCrook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_ironCrook.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_jadeGlaive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jadeGlaive.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_jesterBaton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jesterBaton.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_jeweledArcherBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jeweledArcherBow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_jugglingBalls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jugglingBalls.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_lamplighter {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_lamplighter.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_livelyMatch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_livelyMatch.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_lunarSceptre {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_lunarSceptre.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_magicSpatula {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_magicSpatula.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_magnifyingGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_magnifyingGlass.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_medievalWashboard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_medievalWashboard.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_merchantsDisplayTray {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_merchantsDisplayTray.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_miningPickax {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_miningPickax.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_mythmakerSword {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_mythmakerSword.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_needleOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_needleOfBookbinding.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_nephriteBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_nephriteBow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_ogreClub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_ogreClub.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_orangeKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_orangeKite.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_paintbrush {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_paintbrush.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_paperCutter {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_paperCutter.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_pinkKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_pinkKite.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_pinkLongbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_pinkLongbow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_poisonedGoblet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_poisonedGoblet.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionBase.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionBlue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionDesert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionDesert.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionGolden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionGolden.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionPink.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionRed {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionRed.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionShade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionShade.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionSkeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionSkeleton.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionWhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionWhite.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_potionZombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionZombie.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_pushBroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_pushBroom.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_rancherLasso {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_rancherLasso.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_regalSceptre {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_regalSceptre.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_resplendentRapier {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_resplendentRapier.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_sandySpade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_sandySpade.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_scepterOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_scepterOfDiamonds.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_shadowMastersMace {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_shadowMastersMace.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_shepherdsCrook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_shepherdsCrook.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_shootingStarSpell {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_shootingStarSpell.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_skullLantern {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_skullLantern.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_slingshot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_slingshot.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_spearOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_spearOfSpades.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_vermilionArcherBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_vermilionArcherBow.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_vernalTaper {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_vernalTaper.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_wandOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_wandOfHearts.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_weaversComb {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_weaversComb.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_woodElfStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_woodElfStaff.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_armoire_yellowKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_yellowKite.png');
  width: 68px;
  height: 68px;
}
.slim_armor_armoire_alchemistsRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_alchemistsRobe.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_antiProcrastinationArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_antiProcrastinationArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_astronomersRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_astronomersRobe.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_autumnEnchantersCloak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_autumnEnchantersCloak.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_bagpipersKilt {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_bagpipersKilt.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_barristerRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_barristerRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_baseballUniform {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_baseballUniform.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_basicArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_basicArcherArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_basketballUniform {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_basketballUniform.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_bathtub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_bathtub.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_blueMoonShozoku {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_blueMoonShozoku.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_bluePartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_bluePartyDress.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_boatingJacket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_boatingJacket.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_boxArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_boxArmor.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_candlestickMakerOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_candlestickMakerOutfit.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_cannoneerRags {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_cannoneerRags.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_chefsJacket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_chefsJacket.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_clownsMotley {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_clownsMotley.png');
  width: 114px;
  height: 87px;
}
.slim_armor_armoire_coachDriverLivery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_coachDriverLivery.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_cobblersCoveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_cobblersCoveralls.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_coverallsOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_coverallsOfBookbinding.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_crystalCrescentRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_crystalCrescentRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_doubletOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_doubletOfClubs.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_dragonTamerArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_dragonTamerArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_dressingGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_dressingGown.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_duffleCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_duffleCoat.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_falconerArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_falconerArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_fancyPirateSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_fancyPirateSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_farrierOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_farrierOutfit.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_fiddlersCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_fiddlersCoat.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_flutteryFrock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_flutteryFrock.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_gardenersOveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_gardenersOveralls.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_gladiatorArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_gladiatorArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_glassblowersCoveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_glassblowersCoveralls.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_goldenToga {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_goldenToga.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_gownOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_gownOfHearts.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_graduateRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_graduateRobe.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_greenFestivalYukata {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_greenFestivalYukata.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_guardiansGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_guardiansGown.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_heraldsTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_heraldsTunic.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_heroicHerbalistRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_heroicHerbalistRobe.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_hornedIronArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_hornedIronArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_invernessCape {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_invernessCape.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_ironBlueArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_ironBlueArcherArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_jadeArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jadeArmor.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_jesterCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jesterCostume.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_jeweledArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jeweledArcherArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_jewelersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jewelersApron.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_lamplightersGreatcoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_lamplightersGreatcoat.png');
  width: 114px;
  height: 87px;
}
.slim_armor_armoire_layerCakeArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_layerCakeArmor.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_lunarArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_lunarArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_matchMakersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_matchMakersApron.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_medievalLaundryDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_medievalLaundryDress.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_medievalLaundryOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_medievalLaundryOutfit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_merchantTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_merchantTunic.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_minerOveralls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_minerOveralls.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_mushroomDruidArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_mushroomDruidArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_nephriteArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_nephriteArmor.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_ogreArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_ogreArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_paintersApron {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_paintersApron.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_pirateOutfit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_pirateOutfit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_piraticalPrincessGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_piraticalPrincessGown.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_plagueDoctorOvercoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_plagueDoctorOvercoat.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_ramFleeceRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_ramFleeceRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_rancherRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_rancherRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_redPartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_redPartyDress.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_robeOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_robeOfDiamonds.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_robeOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_robeOfSpades.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_royalRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_royalRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_scribesRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_scribesRobe.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_shadowMastersRobe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shadowMastersRobe.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_shawlCollarCoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shawlCollarCoat.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_sheetGhostCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_sheetGhostCostume.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_shepherdRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shepherdRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_shootingStarCostume {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shootingStarCostume.png');
  width: 117px;
  height: 120px;
}
.slim_armor_armoire_softBlackSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softBlackSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_softBlueSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softBlueSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_softGreenSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softGreenSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_softPinkSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softPinkSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_softRedSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softRedSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_softVioletSuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softVioletSuit.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_strawRaincoat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_strawRaincoat.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_stripedSwimsuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_stripedSwimsuit.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_swanDancerTutu {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_swanDancerTutu.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_teaGown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_teaGown.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_vermilionArcherArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_vermilionArcherArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_vernalVestment {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_vernalVestment.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_vikingTunic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_vikingTunic.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_woodElfArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_woodElfArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_armoire_wovenRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_wovenRobes.png');
  width: 114px;
  height: 90px;
}
.slim_armor_armoire_yellowPartyDress {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_yellowPartyDress.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_alchemistsDistiller {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_alchemistsDistiller.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_arcaneScroll {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_arcaneScroll.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_astronomersTelescope {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_astronomersTelescope.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_bambooCane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_bambooCane.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_barristerGavel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_barristerGavel.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_baseballBat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_baseballBat.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_basicCrossbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_basicCrossbow.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_basicLongbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_basicLongbow.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_batWand {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_batWand.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_battleAxe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_battleAxe.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_beachFlag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_beachFlag.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_blueKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_blueKite.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_blueLongbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_blueLongbow.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_blueMoonSai {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_blueMoonSai.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_buoyantBubbles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_buoyantBubbles.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_cannon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_cannon.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_chefsSpoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_chefsSpoon.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_clubOfClubs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_clubOfClubs.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_coachDriversWhip {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_coachDriversWhip.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_cobblersHammer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_cobblersHammer.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_crystalCrescentStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_crystalCrescentStaff.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_enchantersStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_enchantersStaff.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_eveningTea {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_eveningTea.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_featherDuster {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_featherDuster.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_festivalFirecracker {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_festivalFirecracker.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_fiddlersBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_fiddlersBow.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_finelyCutGem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_finelyCutGem.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_floridFan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_floridFan.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_flutteryArmy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_flutteryArmy.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_forestFungusStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_forestFungusStaff.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_gardenersWateringCan {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_gardenersWateringCan.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_glassblowersBlowpipe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_glassblowersBlowpipe.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_glowingSpear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_glowingSpear.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_goldWingStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_goldWingStaff.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_greenKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_greenKite.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_guardiansCrook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_guardiansCrook.png');
  width: 114px;
  height: 87px;
}
.weapon_armoire_habiticanDiploma {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_habiticanDiploma.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_handyHook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_handyHook.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_happyBanner {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_happyBanner.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_heraldsBuisine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_heraldsBuisine.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_hoofClippers {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_hoofClippers.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_huntingHorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_huntingHorn.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_ironCrook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_ironCrook.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_jadeGlaive {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jadeGlaive.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_jesterBaton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jesterBaton.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_jeweledArcherBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jeweledArcherBow.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_jugglingBalls {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jugglingBalls.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_lamplighter {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_lamplighter.png');
  width: 114px;
  height: 87px;
}
.weapon_armoire_livelyMatch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_livelyMatch.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_lunarSceptre {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_lunarSceptre.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_magicSpatula {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_magicSpatula.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_magnifyingGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_magnifyingGlass.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_medievalWashboard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_medievalWashboard.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_merchantsDisplayTray {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_merchantsDisplayTray.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_miningPickax {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_miningPickax.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_mythmakerSword {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_mythmakerSword.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_needleOfBookbinding {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_needleOfBookbinding.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_nephriteBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_nephriteBow.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_ogreClub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_ogreClub.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_orangeKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_orangeKite.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_paintbrush {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_paintbrush.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_paperCutter {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_paperCutter.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_pinkKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_pinkKite.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_pinkLongbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_pinkLongbow.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_poisonedGoblet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_poisonedGoblet.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionBase.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionBlue.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionDesert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionDesert.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionGolden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionGolden.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionPink.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionRed {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionRed.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionShade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionShade.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionSkeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionSkeleton.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionWhite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionWhite.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_potionZombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionZombie.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_pushBroom {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_pushBroom.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_rancherLasso {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_rancherLasso.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_regalSceptre {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_regalSceptre.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_resplendentRapier {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_resplendentRapier.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_sandySpade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_sandySpade.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_scepterOfDiamonds {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_scepterOfDiamonds.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_shadowMastersMace {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_shadowMastersMace.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_shepherdsCrook {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_shepherdsCrook.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_shootingStarSpell {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_shootingStarSpell.png');
  width: 117px;
  height: 120px;
}
.weapon_armoire_skullLantern {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_skullLantern.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_slingshot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_slingshot.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_spearOfSpades {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_spearOfSpades.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_vermilionArcherBow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_vermilionArcherBow.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_vernalTaper {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_vernalTaper.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_wandOfHearts {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_wandOfHearts.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_weaversComb {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_weaversComb.png');
  width: 114px;
  height: 90px;
}
.weapon_armoire_woodElfStaff {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_woodElfStaff.png');
  width: 90px;
  height: 90px;
}
.weapon_armoire_yellowKite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_yellowKite.png');
  width: 114px;
  height: 90px;
}
.armor_special_bardRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/armor_special_bardRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_1.png');
  width: 90px;
  height: 90px;
}
.broad_armor_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_2.png');
  width: 90px;
  height: 90px;
}
.broad_armor_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_3.png');
  width: 90px;
  height: 90px;
}
.broad_armor_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_4.png');
  width: 90px;
  height: 90px;
}
.broad_armor_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_5.png');
  width: 90px;
  height: 90px;
}
.broad_armor_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_1.png');
  width: 90px;
  height: 90px;
}
.broad_armor_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_2.png');
  width: 90px;
  height: 90px;
}
.broad_armor_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_3.png');
  width: 90px;
  height: 90px;
}
.broad_armor_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_4.png');
  width: 90px;
  height: 90px;
}
.broad_armor_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_5.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_2.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_bardRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_bardRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_dandySuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_dandySuit.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_finnedOceanicArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_finnedOceanicArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_lunarWarriorArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_lunarWarriorArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_mammothRiderArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_mammothRiderArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_nomadsCuirass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_nomadsCuirass.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_pageArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_pageArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_pyromancersRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_pyromancersRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_roguishRainbowMessengerRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_roguishRainbowMessengerRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_samuraiArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_samuraiArmor.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_sneakthiefRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_sneakthiefRobes.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_snowSovereignRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_snowSovereignRobes.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_turkeyArmorBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_turkeyArmorBase.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_turkeyArmorGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_turkeyArmorGilded.png');
  width: 114px;
  height: 90px;
}
.broad_armor_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_1.png');
  width: 90px;
  height: 90px;
}
.broad_armor_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_2.png');
  width: 90px;
  height: 90px;
}
.broad_armor_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_3.png');
  width: 90px;
  height: 90px;
}
.broad_armor_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_4.png');
  width: 90px;
  height: 90px;
}
.broad_armor_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_5.png');
  width: 90px;
  height: 90px;
}
.broad_armor_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_1.png');
  width: 90px;
  height: 90px;
}
.broad_armor_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_2.png');
  width: 90px;
  height: 90px;
}
.broad_armor_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_3.png');
  width: 90px;
  height: 90px;
}
.broad_armor_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_4.png');
  width: 90px;
  height: 90px;
}
.broad_armor_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_5.png');
  width: 90px;
  height: 90px;
}
.shop_armor_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_1.png');
  width: 68px;
  height: 68px;
}
.shop_armor_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_2.png');
  width: 68px;
  height: 68px;
}
.shop_armor_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_3.png');
  width: 68px;
  height: 68px;
}
.shop_armor_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_4.png');
  width: 68px;
  height: 68px;
}
.shop_armor_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_5.png');
  width: 68px;
  height: 68px;
}
.shop_armor_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_1.png');
  width: 68px;
  height: 68px;
}
.shop_armor_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_2.png');
  width: 68px;
  height: 68px;
}
.shop_armor_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_3.png');
  width: 68px;
  height: 68px;
}
.shop_armor_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_4.png');
  width: 68px;
  height: 68px;
}
.shop_armor_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_5.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_0.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_1.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_2.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_bardRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_bardRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_dandySuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_dandySuit.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_finnedOceanicArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_finnedOceanicArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_lunarWarriorArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_lunarWarriorArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_mammothRiderArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_mammothRiderArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_nomadsCuirass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_nomadsCuirass.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_pageArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_pageArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_pyromancersRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_pyromancersRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_roguishRainbowMessengerRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_roguishRainbowMessengerRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_samuraiArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_samuraiArmor.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_sneakthiefRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_sneakthiefRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_snowSovereignRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_snowSovereignRobes.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_turkeyArmorBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_turkeyArmorBase.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_turkeyArmorGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_turkeyArmorGilded.png');
  width: 68px;
  height: 68px;
}
.shop_armor_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_1.png');
  width: 68px;
  height: 68px;
}
.shop_armor_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_2.png');
  width: 68px;
  height: 68px;
}
.shop_armor_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_3.png');
  width: 68px;
  height: 68px;
}
.shop_armor_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_4.png');
  width: 68px;
  height: 68px;
}
.shop_armor_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_5.png');
  width: 68px;
  height: 68px;
}
.shop_armor_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_1.png');
  width: 68px;
  height: 68px;
}
.shop_armor_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_2.png');
  width: 68px;
  height: 68px;
}
.shop_armor_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_3.png');
  width: 68px;
  height: 68px;
}
.shop_armor_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_4.png');
  width: 68px;
  height: 68px;
}
.shop_armor_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_5.png');
  width: 68px;
  height: 68px;
}
.slim_armor_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_1.png');
  width: 90px;
  height: 90px;
}
.slim_armor_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_2.png');
  width: 90px;
  height: 90px;
}
.slim_armor_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_3.png');
  width: 90px;
  height: 90px;
}
.slim_armor_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_4.png');
  width: 90px;
  height: 90px;
}
.slim_armor_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_5.png');
  width: 90px;
  height: 90px;
}
.slim_armor_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_1.png');
  width: 90px;
  height: 90px;
}
.slim_armor_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_2.png');
  width: 90px;
  height: 90px;
}
.slim_armor_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_3.png');
  width: 90px;
  height: 90px;
}
.slim_armor_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_4.png');
  width: 90px;
  height: 90px;
}
.slim_armor_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_5.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_2.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_bardRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_bardRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_dandySuit {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_dandySuit.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_finnedOceanicArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_finnedOceanicArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_lunarWarriorArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_lunarWarriorArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_mammothRiderArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_mammothRiderArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_nomadsCuirass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_nomadsCuirass.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_pageArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_pageArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_pyromancersRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_pyromancersRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_roguishRainbowMessengerRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_roguishRainbowMessengerRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_samuraiArmor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_samuraiArmor.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_sneakthiefRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_sneakthiefRobes.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_snowSovereignRobes {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_snowSovereignRobes.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_turkeyArmorBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_turkeyArmorBase.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_turkeyArmorGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_turkeyArmorGilded.png');
  width: 114px;
  height: 90px;
}
.slim_armor_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_1.png');
  width: 90px;
  height: 90px;
}
.slim_armor_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_2.png');
  width: 90px;
  height: 90px;
}
.slim_armor_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_3.png');
  width: 90px;
  height: 90px;
}
.slim_armor_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_4.png');
  width: 90px;
  height: 90px;
}
.slim_armor_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_5.png');
  width: 90px;
  height: 90px;
}
.slim_armor_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_1.png');
  width: 90px;
  height: 90px;
}
.slim_armor_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_2.png');
  width: 90px;
  height: 90px;
}
.slim_armor_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_3.png');
  width: 90px;
  height: 90px;
}
.slim_armor_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_4.png');
  width: 90px;
  height: 90px;
}
.slim_armor_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_5.png');
  width: 90px;
  height: 90px;
}
.back_special_aetherCloak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_aetherCloak.png');
  width: 114px;
  height: 90px;
}
.back_special_bearTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_bearTail.png');
  width: 90px;
  height: 90px;
}
.back_special_cactusTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_cactusTail.png');
  width: 90px;
  height: 90px;
}
.back_special_foxTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_foxTail.png');
  width: 90px;
  height: 90px;
}
.back_special_lionTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_lionTail.png');
  width: 90px;
  height: 90px;
}
.back_special_namingDay2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_namingDay2020.png');
  width: 114px;
  height: 90px;
}
.back_special_pandaTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_pandaTail.png');
  width: 90px;
  height: 90px;
}
.back_special_pigTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_pigTail.png');
  width: 90px;
  height: 90px;
}
.back_special_snowdriftVeil {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_snowdriftVeil.png');
  width: 114px;
  height: 87px;
}
.back_special_tigerTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_tigerTail.png');
  width: 90px;
  height: 90px;
}
.back_special_turkeyTailBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_turkeyTailBase.png');
  width: 114px;
  height: 90px;
}
.back_special_turkeyTailGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_turkeyTailGilded.png');
  width: 114px;
  height: 90px;
}
.back_special_wolfTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_wolfTail.png');
  width: 90px;
  height: 90px;
}
.icon_back_special_bearTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_bearTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_cactusTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_cactusTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_foxTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_foxTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_lionTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_lionTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_pandaTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_pandaTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_pigTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_pigTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_tigerTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_tigerTail.png');
  width: 60px;
  height: 60px;
}
.icon_back_special_wolfTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_wolfTail.png');
  width: 60px;
  height: 60px;
}
.shop_back_special_aetherCloak {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_aetherCloak.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_bearTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_bearTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_cactusTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_cactusTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_foxTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_foxTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_lionTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_lionTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_namingDay2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_namingDay2020.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_pandaTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_pandaTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_pigTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_pigTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_snowdriftVeil {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_snowdriftVeil.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_tigerTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_tigerTail.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_turkeyTailBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_turkeyTailBase.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_turkeyTailGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_turkeyTailGilded.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_wolfTail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_wolfTail.png');
  width: 68px;
  height: 68px;
}
.body_special_aetherAmulet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_aetherAmulet.png');
  width: 114px;
  height: 90px;
}
.body_special_namingDay2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_namingDay2018.png');
  width: 90px;
  height: 90px;
}
.shop_body_special_aetherAmulet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_aetherAmulet.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_namingDay2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_namingDay2018.png');
  width: 68px;
  height: 68px;
}
.back_special_anniversary {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_anniversary.png');
  width: 114px;
  height: 90px;
}
.body_special_anniversary {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_anniversary.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_birthday2015 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2015.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_birthday2016 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2016.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_birthday2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2017.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_birthday2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2018.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_birthday2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2019.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_birthday2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2020.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_birthday2021 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2021.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_birthday2022 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2022.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_birthday2023 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2023.png');
  width: 114px;
  height: 90px;
}
.eyewear_special_anniversary {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_anniversary.png');
  width: 90px;
  height: 90px;
}
.shop_armor_special_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2015 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2015.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2016 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2016.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2017.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2018.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2019.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2020.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2021 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2021.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2022 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2022.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_birthday2023 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2023.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_anniversary {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_anniversary.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_anniversary {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_anniversary.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_anniversary {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_anniversary.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_birthday2015 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2015.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_birthday2016 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2016.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_birthday2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2017.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_birthday2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2018.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_birthday2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2019.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_birthday2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2020.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_birthday2021 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2021.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_birthday2022 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2022.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_birthday2023 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2023.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Healer.png');
  width: 93px;
  height: 90px;
}
.broad_armor_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Mage.png');
  width: 105px;
  height: 90px;
}
.broad_armor_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Rogue.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Warrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Healer.png');
  width: 114px;
  height: 87px;
}
.broad_armor_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Mage.png');
  width: 114px;
  height: 87px;
}
.broad_armor_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Rogue.png');
  width: 114px;
  height: 87px;
}
.broad_armor_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Warrior.png');
  width: 114px;
  height: 87px;
}
.broad_armor_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallHealer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallMage.png');
  width: 120px;
  height: 90px;
}
.broad_armor_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallRogue.png');
  width: 105px;
  height: 90px;
}
.broad_armor_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallWarrior.png');
  width: 90px;
  height: 90px;
}
.eyewear_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_fall2019Healer.png');
  width: 114px;
  height: 90px;
}
.eyewear_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_fall2019Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Healer.png');
  width: 93px;
  height: 90px;
}
.head_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Mage.png');
  width: 105px;
  height: 90px;
}
.head_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Rogue.png');
  width: 90px;
  height: 90px;
}
.head_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Healer.png');
  width: 114px;
  height: 87px;
}
.head_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Mage.png');
  width: 114px;
  height: 87px;
}
.head_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Rogue.png');
  width: 114px;
  height: 87px;
}
.head_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Warrior.png');
  width: 114px;
  height: 87px;
}
.head_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallHealer.png');
  width: 90px;
  height: 90px;
}
.head_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallMage.png');
  width: 120px;
  height: 90px;
}
.head_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallRogue.png');
  width: 105px;
  height: 90px;
}
.head_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallWarrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2015Healer.png');
  width: 93px;
  height: 90px;
}
.shield_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2015Rogue.png');
  width: 105px;
  height: 90px;
}
.shield_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2015Warrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2016Healer.png');
  width: 114px;
  height: 87px;
}
.shield_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2016Rogue.png');
  width: 114px;
  height: 87px;
}
.shield_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2016Warrior.png');
  width: 114px;
  height: 87px;
}
.shield_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2017Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2017Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2017Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2018Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2018Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2018Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2019Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2019Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2019Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2020Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2020Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2020Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2021Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2021Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2021Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2022Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2022Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2022Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fallHealer.png');
  width: 90px;
  height: 90px;
}
.shield_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fallRogue.png');
  width: 105px;
  height: 90px;
}
.shield_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fallWarrior.png');
  width: 90px;
  height: 90px;
}
.shop_armor_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallHealer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallMage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallRogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_fall2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_fall2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallHealer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallMage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallRogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fallHealer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fallRogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fallWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallHealer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallMage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallRogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallWarrior.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Healer.png');
  width: 93px;
  height: 90px;
}
.slim_armor_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Mage.png');
  width: 105px;
  height: 90px;
}
.slim_armor_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Rogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Warrior.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Healer.png');
  width: 114px;
  height: 87px;
}
.slim_armor_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Mage.png');
  width: 114px;
  height: 87px;
}
.slim_armor_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Rogue.png');
  width: 114px;
  height: 87px;
}
.slim_armor_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Warrior.png');
  width: 114px;
  height: 87px;
}
.slim_armor_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallHealer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallMage.png');
  width: 120px;
  height: 90px;
}
.slim_armor_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallRogue.png');
  width: 105px;
  height: 90px;
}
.slim_armor_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallWarrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_fall2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Healer.png');
  width: 93px;
  height: 90px;
}
.weapon_special_fall2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Mage.png');
  width: 105px;
  height: 90px;
}
.weapon_special_fall2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Rogue.png');
  width: 90px;
  height: 90px;
}
.weapon_special_fall2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_fall2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Healer.png');
  width: 114px;
  height: 87px;
}
.weapon_special_fall2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Mage.png');
  width: 114px;
  height: 87px;
}
.weapon_special_fall2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Rogue.png');
  width: 114px;
  height: 87px;
}
.weapon_special_fall2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Warrior.png');
  width: 114px;
  height: 87px;
}
.weapon_special_fall2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fall2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_fallHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallHealer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_fallMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallMage.png');
  width: 120px;
  height: 90px;
}
.weapon_special_fallRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallRogue.png');
  width: 105px;
  height: 90px;
}
.weapon_special_fallWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallWarrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_gaymerx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_gaymerx.png');
  width: 90px;
  height: 90px;
}
.head_special_gaymerx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_gaymerx.png');
  width: 90px;
  height: 90px;
}
.shop_armor_special_gaymerx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_gaymerx.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_gaymerx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_gaymerx.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_gaymerx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_gaymerx.png');
  width: 90px;
  height: 90px;
}
.shop_armor_special_ks2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_ks2019.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_ks2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_ks2019.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_ks2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_ks2019.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_ks2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_ks2019.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_ks2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_ks2019.png');
  width: 68px;
  height: 68px;
}
.back_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201402.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201402.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201402.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201402.png');
  width: 68px;
  height: 68px;
}
.shop_back_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201402.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201402.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201402.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201402 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201402.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201403 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201403.png');
  width: 90px;
  height: 90px;
}
.headAccessory_mystery_201403 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201403.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201403 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201403.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201403 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201403.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201403 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201403.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201403 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201403.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201404.png');
  width: 90px;
  height: 90px;
}
.headAccessory_mystery_201404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201404.png');
  width: 90px;
  height: 90px;
}
.shop_back_mystery_201404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201404.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201404.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201404.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201405.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201405.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201405.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201405.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201405.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201405.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201406 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201406.png');
  width: 90px;
  height: 96px;
}
.head_mystery_201406 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201406.png');
  width: 90px;
  height: 96px;
}
.shop_armor_mystery_201406 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201406.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201406 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201406.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201406 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201406.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201406 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201406.png');
  width: 90px;
  height: 96px;
}
.broad_armor_mystery_201407 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201407.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201407 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201407.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201407 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201407.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201407 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201407.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201407 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201407.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201407 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201407.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201408 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201408.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201408 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201408.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201408 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201408.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201408 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201408.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201408 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201408.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201408 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201408.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201409 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201409.png');
  width: 90px;
  height: 90px;
}
.headAccessory_mystery_201409 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201409.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201409 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201409.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201409 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201409.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201409 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201409.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201409 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201409.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201410 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201410.png');
  width: 93px;
  height: 90px;
}
.broad_armor_mystery_201410 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201410.png');
  width: 93px;
  height: 90px;
}
.shop_armor_mystery_201410 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201410.png');
  width: 68px;
  height: 68px;
}
.shop_back_mystery_201410 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201410.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201410 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201410.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201410 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201410.png');
  width: 93px;
  height: 90px;
}
.head_mystery_201411 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201411.png');
  width: 90px;
  height: 90px;
}
.shop_head_mystery_201411 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201411.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201411 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201411.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_201411 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201411.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_201411 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201411.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201412 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201412.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201412 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201412.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201412 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201412.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201412 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201412.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201412 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201412.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201412 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201412.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201501 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201501.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201501 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201501.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201501 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201501.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201501 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201501.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201501 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201501.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201501 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201501.png');
  width: 90px;
  height: 90px;
}
.headAccessory_mystery_201502 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201502.png');
  width: 90px;
  height: 90px;
}
.shop_headAccessory_mystery_201502 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201502.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201502 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201502.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_201502 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201502.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_201502 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201502.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201503 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201503.png');
  width: 90px;
  height: 90px;
}
.eyewear_mystery_201503 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201503.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201503 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201503.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_201503 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201503.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201503 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201503.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201503 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201503.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201504 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201504.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201504 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201504.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201504 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201504.png');
  width: 68px;
  height: 68px;
}
.shop_back_mystery_201504 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201504.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201504 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201504.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201504 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201504.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201505 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201505.png');
  width: 90px;
  height: 90px;
}
.shop_head_mystery_201505 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201505.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201505 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201505.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_201505 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201505.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_201505 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201505.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201506 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201506.png');
  width: 90px;
  height: 105px;
}
.eyewear_mystery_201506 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201506.png');
  width: 90px;
  height: 105px;
}
.shop_armor_mystery_201506 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201506.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_201506 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201506.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201506 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201506.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201506 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201506.png');
  width: 90px;
  height: 105px;
}
.back_mystery_201507 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201507.png');
  width: 90px;
  height: 105px;
}
.eyewear_mystery_201507 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201507.png');
  width: 90px;
  height: 105px;
}
.shop_back_mystery_201507 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201507.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_201507 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201507.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201507 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201507.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201508 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201508.png');
  width: 93px;
  height: 90px;
}
.head_mystery_201508 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201508.png');
  width: 93px;
  height: 90px;
}
.shop_armor_mystery_201508 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201508.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201508 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201508.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201508 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201508.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201508 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201508.png');
  width: 93px;
  height: 90px;
}
.broad_armor_mystery_201509 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201509.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201509 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201509.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201509 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201509.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201509 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201509.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201509 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201509.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201509 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201509.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201510 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201510.png');
  width: 105px;
  height: 90px;
}
.headAccessory_mystery_201510 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201510.png');
  width: 93px;
  height: 90px;
}
.shop_back_mystery_201510 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201510.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201510 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201510.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201510 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201510.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201511 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201511.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201511 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201511.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201511 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201511.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201511 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201511.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201511 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201511.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201511 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201511.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201512 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201512.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201512 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201512.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201512 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201512.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201512 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201512.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201512 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201512.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201512 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201512.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201601 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201601.png');
  width: 120px;
  height: 90px;
}
.shield_mystery_201601 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201601.png');
  width: 120px;
  height: 90px;
}
.shop_head_mystery_201601 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201601.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201601 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201601.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_201601 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201601.png');
  width: 68px;
  height: 68px;
}
.back_mystery_201602 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201602.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201602 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201602.png');
  width: 90px;
  height: 90px;
}
.shop_back_mystery_201602 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201602.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201602 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201602.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201602 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201602.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201603 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201603.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201603 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201603.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201603 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201603.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201603 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201603.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201603 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201603.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201603 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201603.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201604 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201604.png');
  width: 93px;
  height: 90px;
}
.head_mystery_201604 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201604.png');
  width: 93px;
  height: 90px;
}
.shop_armor_mystery_201604 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201604.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201604 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201604.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201604 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201604.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201604 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201604.png');
  width: 93px;
  height: 90px;
}
.broad_armor_mystery_201605 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201605.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201605 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201605.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201605 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201605.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201605 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201605.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201605 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201605.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201605 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201605.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201606 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201606.png');
  width: 90px;
  height: 105px;
}
.head_mystery_201606 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201606.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201606 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201606.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201606 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201606.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201606 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201606.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201606 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201606.png');
  width: 90px;
  height: 105px;
}
.broad_armor_mystery_201607 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201607.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201607 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201607.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201607 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201607.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201607 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201607.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201607 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201607.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201607 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201607.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201608 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201608.png');
  width: 93px;
  height: 90px;
}
.head_mystery_201608 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201608.png');
  width: 93px;
  height: 90px;
}
.shop_back_mystery_201608 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201608.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201608 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201608.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201608 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201608.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201609 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201609.png');
  width: 93px;
  height: 90px;
}
.head_mystery_201609 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201609.png');
  width: 93px;
  height: 90px;
}
.shop_armor_mystery_201609 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201609.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201609 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201609.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201609 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201609.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201609 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201609.png');
  width: 93px;
  height: 90px;
}
.broad_armor_mystery_201610 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201610.png');
  width: 90px;
  height: 99px;
}
.head_mystery_201610 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201610.png');
  width: 90px;
  height: 99px;
}
.shop_armor_mystery_201610 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201610.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201610 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201610.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201610 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201610.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201610 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201610.png');
  width: 90px;
  height: 99px;
}
.head_mystery_201611 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201611.png');
  width: 90px;
  height: 90px;
}
.shop_head_mystery_201611 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201611.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201611 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201611.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_201611 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201611.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_201611 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201611.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201612 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201612.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201612 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201612.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201612 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201612.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201612 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201612.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201612 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201612.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201612 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201612.png');
  width: 90px;
  height: 90px;
}
.eyewear_mystery_201701 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201701.png');
  width: 90px;
  height: 105px;
}
.shield_mystery_201701 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201701.png');
  width: 90px;
  height: 105px;
}
.shop_eyewear_mystery_201701 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201701.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201701 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201701.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_201701 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201701.png');
  width: 68px;
  height: 68px;
}
.back_mystery_201702 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201702.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201702 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201702.png');
  width: 90px;
  height: 90px;
}
.shop_back_mystery_201702 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201702.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201702 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201702.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201702 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201702.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201703.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201703.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201703.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201703.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201703.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201703.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201704.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_201704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201704.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_201704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201704.png');
  width: 68px;
  height: 68px;
}
.shop_back_mystery_201704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201704.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201704.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201704.png');
  width: 90px;
  height: 90px;
}
.body_mystery_201705 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201705.png');
  width: 90px;
  height: 90px;
}
.head_mystery_201705 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201705.png');
  width: 90px;
  height: 90px;
}
.shop_body_mystery_201705 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201705.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201705 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201705.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201705 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201705.png');
  width: 68px;
  height: 68px;
}
.back_mystery_201706 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201706.png');
  width: 111px;
  height: 90px;
}
.body_mystery_201706 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201706.png');
  width: 111px;
  height: 90px;
}
.shop_back_mystery_201706 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201706.png');
  width: 68px;
  height: 68px;
}
.shop_body_mystery_201706 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201706.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201706 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201706.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201707 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201707.png');
  width: 105px;
  height: 90px;
}
.head_mystery_201707 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201707.png');
  width: 105px;
  height: 90px;
}
.shop_armor_mystery_201707 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201707.png');
  width: 40px;
  height: 40px;
}
.shop_head_mystery_201707 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201707.png');
  width: 40px;
  height: 40px;
}
.shop_set_mystery_201707 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201707.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201707 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201707.png');
  width: 105px;
  height: 90px;
}
.shield_mystery_201708 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201708.png');
  width: 90px;
  height: 90px;
}
.shop_set_mystery_201708 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201708.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_201708 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201708.png');
  width: 40px;
  height: 40px;
}
.shop_weapon_mystery_201708 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201708.png');
  width: 40px;
  height: 40px;
}
.weapon_mystery_201708 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201708.png');
  width: 90px;
  height: 90px;
}
.back_mystery_201709 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201709.png');
  width: 114px;
  height: 90px;
}
.shield_mystery_201709 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201709.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201709 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201709.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201709 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201709.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_201709 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201709.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201710 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201710.png');
  width: 93px;
  height: 90px;
}
.head_mystery_201710 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201710.png');
  width: 93px;
  height: 90px;
}
.shop_armor_mystery_201710 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201710.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201710 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201710.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201710 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201710.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201710 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201710.png');
  width: 93px;
  height: 90px;
}
.body_mystery_201711 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201711.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201711 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201711.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201711 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201711.png');
  width: 68px;
  height: 68px;
}
.shop_body_mystery_201711 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201711.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201711 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201711.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201711 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201711.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201712 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201712.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201712 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201712.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201712 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201712.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201712 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201712.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201712 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201712.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201712 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201712.png');
  width: 114px;
  height: 90px;
}
.back_mystery_201801 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201801.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_201801 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201801.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201801 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201801.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201801 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201801.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201801 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201801.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201802.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201802.png');
  width: 114px;
  height: 90px;
}
.shield_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201802.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201802.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201802.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201802.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201802.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201802 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201802.png');
  width: 114px;
  height: 90px;
}
.back_mystery_201803 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201803.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201803 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201803.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201803 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201803.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201803 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201803.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201803 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201803.png');
  width: 68px;
  height: 68px;
}
.back_mystery_201804 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201804.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_201804 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201804.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201804 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201804.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201804 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201804.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201804 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201804.png');
  width: 68px;
  height: 68px;
}
.back_mystery_201805 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201805.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201805 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201805.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201805 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201805.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201805 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201805.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201805 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201805.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201806 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201806.png');
  width: 114px;
  height: 120px;
}
.head_mystery_201806 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201806.png');
  width: 114px;
  height: 120px;
}
.shop_armor_mystery_201806 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201806.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201806 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201806.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201806 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201806.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201806 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201806.png');
  width: 114px;
  height: 120px;
}
.broad_armor_mystery_201807 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201807.png');
  width: 114px;
  height: 120px;
}
.head_mystery_201807 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201807.png');
  width: 114px;
  height: 120px;
}
.shop_armor_mystery_201807 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201807.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201807 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201807.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201807 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201807.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201807 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201807.png');
  width: 114px;
  height: 120px;
}
.broad_armor_mystery_201808 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201808.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201808 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201808.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201808 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201808.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201808 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201808.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201808 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201808.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201808 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201808.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201809 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201809.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201809 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201809.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201809 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201809.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201809 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201809.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201809 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201809.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201809 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201809.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201810 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201810.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201810 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201810.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201810 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201810.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201810 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201810.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201810 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201810.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201810 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201810.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201811 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201811.png');
  width: 114px;
  height: 90px;
}
.shop_head_mystery_201811 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201811.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201811 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201811.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_201811 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201811.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_201811 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201811.png');
  width: 114px;
  height: 90px;
}
.back_mystery_201812 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201812.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_201812 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201812.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201812 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201812.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201812 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201812.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201812 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201812.png');
  width: 68px;
  height: 68px;
}
.body_mystery_201901 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201901.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201901 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201901.png');
  width: 114px;
  height: 90px;
}
.shop_body_mystery_201901 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201901.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201901 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201901.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201901 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201901.png');
  width: 68px;
  height: 68px;
}
.eyewear_mystery_201902 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201902.png');
  width: 114px;
  height: 90px;
}
.shield_mystery_201902 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201902.png');
  width: 114px;
  height: 90px;
}
.shop_eyewear_mystery_201902 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201902.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201902 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201902.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_201902 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201902.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201903 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201903.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201903 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201903.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201903 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201903.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201903 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201903.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201903 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201903.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201903 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201903.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201904 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201904.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201904 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201904.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201904 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201904.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201904 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201904.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201904 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201904.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201904 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201904.png');
  width: 114px;
  height: 90px;
}
.back_mystery_201905 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201905.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_201905 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201905.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201905 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201905.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201905 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201905.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201905 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201905.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_201906 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201906.png');
  width: 117px;
  height: 120px;
}
.headAccessory_mystery_201906 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201906.png');
  width: 117px;
  height: 120px;
}
.shop_armor_mystery_201906 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201906.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201906 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201906.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201906 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201906.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201906 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201906.png');
  width: 117px;
  height: 120px;
}
.broad_armor_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201907.png');
  width: 114px;
  height: 90px;
}
.eyewear_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201907.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201907.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201907.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201907.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201907.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201907.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201907 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201907.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201908 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201908.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_201908 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201908.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201908 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201908.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_201908 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201908.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201908 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201908.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201908 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201908.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201909 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201909.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201909 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201909.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201909 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201909.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201909 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201909.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201909 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201909.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201909 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201909.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_201910 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201910.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201910 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201910.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_201910 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201910.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201910 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201910.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201910 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201910.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_201910 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201910.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201911 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201911.png');
  width: 114px;
  height: 90px;
}
.shop_head_mystery_201911 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201911.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201911 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201911.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_201911 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201911.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_201911 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201911.png');
  width: 114px;
  height: 90px;
}
.back_mystery_201912 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201912.png');
  width: 114px;
  height: 90px;
}
.head_mystery_201912 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201912.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_201912 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201912.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_201912 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201912.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_201912 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201912.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202001 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202001.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202001 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202001.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202001 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202001.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202001 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202001.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202001 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202001.png');
  width: 68px;
  height: 68px;
}
.body_mystery_202002 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202002.png');
  width: 114px;
  height: 90px;
}
.shop_body_mystery_202002 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202002.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202002 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202002.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202002 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202002.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_202002 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202002.png');
  width: 114px;
  height: 90px;
}
.body_mystery_202003 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202003.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202003 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202003.png');
  width: 114px;
  height: 90px;
}
.shop_body_mystery_202003 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202003.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202003 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202003.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202003 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202003.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202004 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202004.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202004 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202004.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202004 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202004.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202004 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202004.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202004 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202004.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202005 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202005.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202005 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202005.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202005 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202005.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202005 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202005.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202005 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202005.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202006 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202006.png');
  width: 120px;
  height: 120px;
}
.head_mystery_202006 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202006.png');
  width: 120px;
  height: 120px;
}
.shop_armor_mystery_202006 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202006.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202006 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202006.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202006 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202006.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202006 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202006.png');
  width: 120px;
  height: 120px;
}
.broad_armor_mystery_202007 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202007.png');
  width: 116px;
  height: 119px;
}
.head_mystery_202007 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202007.png');
  width: 116px;
  height: 119px;
}
.shop_armor_mystery_202007 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202007.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202007 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202007.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202007 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202007.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202007 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202007.png');
  width: 116px;
  height: 119px;
}
.body_mystery_202008 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202008.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202008 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202008.png');
  width: 114px;
  height: 90px;
}
.shop_body_mystery_202008 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202008.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202008 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202008.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202008 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202008.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202009 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202009.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202009 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202009.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202009 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202009.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202009 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202009.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202009 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202009.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202010 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202010.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202010 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202010.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202010 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202010.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202010 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202010.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202010 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202010.png');
  width: 68px;
  height: 68px;
}
.head_mystery_202011 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202011.png');
  width: 114px;
  height: 90px;
}
.shield_mystery_202011 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_202011.png');
  width: 114px;
  height: 90px;
}
.shop_head_mystery_202011 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202011.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202011 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202011.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_202011 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_202011.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202012 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202012.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202012 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202012.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202012 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202012.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202012 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202012.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202012 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202012.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202101 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202101.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202101 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202101.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202101 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202101.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202101 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202101.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202101 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202101.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202101 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202101.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202102.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202102.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202102.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202102.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202102.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202102.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202102.png');
  width: 114px;
  height: 90px;
}
.weapon_mystery_202102 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202102.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_202103 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202103.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202103 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202103.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202103 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202103.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202103 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202103.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202103 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202103.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202103 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202103.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_202104 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202104.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202104 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202104.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202104 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202104.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202104 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202104.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202104 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202104.png');
  width: 114px;
  height: 90px;
}
.weapon_mystery_202104 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202104.png');
  width: 114px;
  height: 90px;
}
.back_mystery_202105 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202105.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202105 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202105.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202105 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202105.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202105 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202105.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202105 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202105.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202106 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202106.png');
  width: 117px;
  height: 120px;
}
.head_mystery_202106 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202106.png');
  width: 117px;
  height: 120px;
}
.shop_armor_mystery_202106 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202106.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202106 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202106.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202106 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202106.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202106 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202106.png');
  width: 117px;
  height: 120px;
}
.body_mystery_202107 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202107.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202107 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202107.png');
  width: 114px;
  height: 90px;
}
.shop_body_mystery_202107 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202107.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202107 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202107.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202107 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202107.png');
  width: 68px;
  height: 68px;
}
.eyewear_mystery_202108 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202108.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202108 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202108.png');
  width: 114px;
  height: 90px;
}
.shop_eyewear_mystery_202108 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202108.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202108 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202108.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202108 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202108.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202109 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202109.png');
  width: 117px;
  height: 120px;
}
.headAccessory_mystery_202109 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202109.png');
  width: 117px;
  height: 120px;
}
.shop_back_mystery_202109 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202109.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202109 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202109.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202109 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202109.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202110 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202110.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202110 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202110.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202110 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202110.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202110 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202110.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202110 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202110.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202110 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202110.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202111 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202111.png');
  width: 114px;
  height: 90px;
}
.shop_head_mystery_202111 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202111.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202111 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202111.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202111 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202111.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_202111 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202111.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_202112 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202112.png');
  width: 117px;
  height: 120px;
}
.head_mystery_202112 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202112.png');
  width: 117px;
  height: 120px;
}
.shop_armor_mystery_202112 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202112.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202112 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202112.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202112 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202112.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202112 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202112.png');
  width: 117px;
  height: 120px;
}
.eyewear_mystery_202201 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202201.png');
  width: 114px;
  height: 90px;
}
.shop_eyewear_mystery_202201 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202201.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202201 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202201.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202201 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202201.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_202201 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202201.png');
  width: 114px;
  height: 90px;
}
.eyewear_mystery_202202 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202202.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202202 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202202.png');
  width: 114px;
  height: 90px;
}
.shop_eyewear_mystery_202202 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202202.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202202 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202202.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202202 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202202.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202203 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202203.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202203 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202203.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202203 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202203.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202203 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202203.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202203 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202203.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202204 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202204.png');
  width: 114px;
  height: 90px;
}
.eyewear_mystery_202204A {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202204A.png');
  width: 114px;
  height: 90px;
}
.eyewear_mystery_202204B {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202204B.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202204 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202204.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_202204A {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202204A.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_202204B {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202204B.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202204 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202204.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202204 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202204.png');
  width: 114px;
  height: 90px;
}
.back_mystery_202205 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202205.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202205 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202205.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202205 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202205.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202205 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202205.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202205 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202205.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202206 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202206.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202206 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202206.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202206 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202206.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202206 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202206.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202206 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202206.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202207 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202207.png');
  width: 117px;
  height: 120px;
}
.head_mystery_202207 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202207.png');
  width: 117px;
  height: 120px;
}
.shop_armor_mystery_202207 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202207.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202207 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202207.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202207 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202207.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202207 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202207.png');
  width: 117px;
  height: 120px;
}
.eyewear_mystery_202208 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202208.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202208 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202208.png');
  width: 114px;
  height: 90px;
}
.shop_eyewear_mystery_202208 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202208.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202208 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202208.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202208 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202208.png');
  width: 68px;
  height: 68px;
}
.shield_mystery_202209 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_202209.png');
  width: 114px;
  height: 90px;
}
.shop_set_mystery_202209 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202209.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_202209 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_202209.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202209 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202209.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_202209 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202209.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_202210 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202210.png');
  width: 117px;
  height: 120px;
}
.head_mystery_202210 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202210.png');
  width: 117px;
  height: 120px;
}
.shop_armor_mystery_202210 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202210.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202210 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202210.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202210 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202210.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202210 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202210.png');
  width: 117px;
  height: 120px;
}
.head_mystery_202211 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202211.png');
  width: 114px;
  height: 90px;
}
.shop_head_mystery_202211 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202211.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202211 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202211.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202211 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202211.png');
  width: 68px;
  height: 68px;
}
.weapon_mystery_202211 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202211.png');
  width: 114px;
  height: 90px;
}
.broad_armor_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202212.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202212.png');
  width: 114px;
  height: 90px;
}
.shop_armor_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202212.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202212.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202212.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202212.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202212.png');
  width: 114px;
  height: 90px;
}
.weapon_mystery_202212 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202212.png');
  width: 114px;
  height: 90px;
}
.back_mystery_202301 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202301.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202301 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202301.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202301 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202301.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202301 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202301.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202301 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202301.png');
  width: 68px;
  height: 68px;
}
.back_mystery_202302 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202302.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202302 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202302.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202302 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202302.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202302 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202302.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202302 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202302.png');
  width: 68px;
  height: 68px;
}
.eyewear_mystery_202303 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202303.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202303 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202303.png');
  width: 114px;
  height: 90px;
}
.shop_eyewear_mystery_202303 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202303.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202303 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202303.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202303 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202303.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_202304 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202304.png');
  width: 114px;
  height: 90px;
}
.head_mystery_202304 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202304.png');
  width: 114px;
  height: 90px;
}
.set_mystery_202304 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/set_mystery_202304.png');
  width: 68px;
  height: 68px;
}
.shop_armor_mystery_202304 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202304.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_202304 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202304.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_202304 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202304.png');
  width: 114px;
  height: 90px;
}
.back_mystery_202305 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202305.png');
  width: 114px;
  height: 90px;
}
.headAccessory_mystery_202305 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202305.png');
  width: 114px;
  height: 90px;
}
.shop_back_mystery_202305 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202305.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_202305 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202305.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_202305 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202305.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_301404.png');
  width: 90px;
  height: 90px;
}
.eyewear_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_301404.png');
  width: 90px;
  height: 90px;
}
.head_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301404.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_301404.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_301404.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301404.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301404.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_301404.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_301404.png');
  width: 90px;
  height: 90px;
}
.weapon_mystery_301404 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_301404.png');
  width: 90px;
  height: 90px;
}
.eyewear_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_301405.png');
  width: 90px;
  height: 90px;
}
.headAccessory_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_301405.png');
  width: 90px;
  height: 90px;
}
.head_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301405.png');
  width: 90px;
  height: 90px;
}
.shield_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_301405.png');
  width: 90px;
  height: 90px;
}
.shop_eyewear_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_301405.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_301405.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301405.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301405.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_301405 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_301405.png');
  width: 68px;
  height: 68px;
}
.broad_armor_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_301703.png');
  width: 90px;
  height: 90px;
}
.eyewear_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_301703.png');
  width: 90px;
  height: 90px;
}
.head_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301703.png');
  width: 114px;
  height: 87px;
}
.shop_armor_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_301703.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_301703.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301703.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301703.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_301703 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_301703.png');
  width: 90px;
  height: 90px;
}
.broad_armor_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_301704.png');
  width: 90px;
  height: 90px;
}
.head_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301704.png');
  width: 90px;
  height: 90px;
}
.shield_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_301704.png');
  width: 90px;
  height: 90px;
}
.shop_armor_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_301704.png');
  width: 68px;
  height: 68px;
}
.shop_head_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301704.png');
  width: 68px;
  height: 68px;
}
.shop_set_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301704.png');
  width: 68px;
  height: 68px;
}
.shop_shield_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_301704.png');
  width: 68px;
  height: 68px;
}
.slim_armor_mystery_301704 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_301704.png');
  width: 90px;
  height: 90px;
}
.head_special_piDay {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_piDay.png');
  width: 114px;
  height: 90px;
}
.shield_special_piDay {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_piDay.png');
  width: 114px;
  height: 90px;
}
.shop_head_special_piDay {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_piDay.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_piDay {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_piDay.png');
  width: 68px;
  height: 68px;
}
.broad_armor_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Healer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Rogue.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Warrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Healer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Rogue.png');
  width: 102px;
  height: 90px;
}
.broad_armor_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Warrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Healer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Rogue.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Warrior.png');
  width: 114px;
  height: 87px;
}
.broad_armor_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Mage.png');
  width: 116px;
  height: 119px;
}
.broad_armor_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Rogue.png');
  width: 114px;
  height: 117px;
}
.broad_armor_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Healer.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Mage.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Warrior.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springHealer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springMage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springRogue.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springWarrior.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Healer.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Mage.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Rogue.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Warrior.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Healer.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Mage.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Rogue.png');
  width: 102px;
  height: 90px;
}
.headAccessory_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Warrior.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Healer.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Mage.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Rogue.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Warrior.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springHealer.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springMage.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springRogue.png');
  width: 90px;
  height: 90px;
}
.headAccessory_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springWarrior.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Rogue.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Rogue.png');
  width: 102px;
  height: 90px;
}
.head_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Rogue.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Mage.png');
  width: 116px;
  height: 119px;
}
.head_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Rogue.png');
  width: 114px;
  height: 117px;
}
.head_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Healer.png');
  width: 117px;
  height: 120px;
}
.head_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Mage.png');
  width: 117px;
  height: 120px;
}
.head_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Warrior.png');
  width: 117px;
  height: 120px;
}
.head_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springHealer.png');
  width: 90px;
  height: 90px;
}
.head_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springMage.png');
  width: 90px;
  height: 90px;
}
.head_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springRogue.png');
  width: 90px;
  height: 90px;
}
.head_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springWarrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2015Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2015Rogue.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2015Warrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2016Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2016Rogue.png');
  width: 102px;
  height: 90px;
}
.shield_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2016Warrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2017Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2017Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2017Warrior.png');
  width: 114px;
  height: 87px;
}
.shield_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2018Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2018Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2018Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2019Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2019Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2019Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2020Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2020Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2020Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2021Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2021Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2021Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2022Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2022Rogue.png');
  width: 114px;
  height: 117px;
}
.shield_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2022Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2023Healer.png');
  width: 117px;
  height: 120px;
}
.shield_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2023Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2023Warrior.png');
  width: 117px;
  height: 120px;
}
.shield_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_springHealer.png');
  width: 90px;
  height: 90px;
}
.shield_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_springRogue.png');
  width: 90px;
  height: 90px;
}
.shield_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_springWarrior.png');
  width: 90px;
  height: 90px;
}
.shop_armor_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springHealer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springMage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springRogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springHealer.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springMage.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springRogue.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springHealer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springMage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springRogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_springHealer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_springRogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_springWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springHealer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springMage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springRogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springWarrior.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Healer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Rogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Warrior.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Healer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Rogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Warrior.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Healer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Rogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Warrior.png');
  width: 114px;
  height: 87px;
}
.slim_armor_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Mage.png');
  width: 116px;
  height: 119px;
}
.slim_armor_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Rogue.png');
  width: 114px;
  height: 117px;
}
.slim_armor_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Healer.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Mage.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Warrior.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springHealer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springMage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springRogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springWarrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Rogue.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Rogue.png');
  width: 102px;
  height: 90px;
}
.weapon_special_spring2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Rogue.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_spring2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Mage.png');
  width: 116px;
  height: 119px;
}
.weapon_special_spring2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Rogue.png');
  width: 114px;
  height: 117px;
}
.weapon_special_spring2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Healer.png');
  width: 117px;
  height: 120px;
}
.weapon_special_spring2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Mage.png');
  width: 117px;
  height: 120px;
}
.weapon_special_spring2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_spring2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Warrior.png');
  width: 117px;
  height: 120px;
}
.weapon_special_springHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springHealer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_springMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springMage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_springRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springRogue.png');
  width: 90px;
  height: 90px;
}
.weapon_special_springWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springWarrior.png');
  width: 90px;
  height: 90px;
}
.body_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Healer.png');
  width: 90px;
  height: 90px;
}
.body_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Mage.png');
  width: 90px;
  height: 90px;
}
.body_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Rogue.png');
  width: 102px;
  height: 105px;
}
.body_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Warrior.png');
  width: 90px;
  height: 105px;
}
.body_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summerHealer.png');
  width: 90px;
  height: 105px;
}
.body_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summerMage.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Healer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Rogue.png');
  width: 102px;
  height: 105px;
}
.broad_armor_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Warrior.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Healer.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Mage.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Rogue.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Warrior.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Healer.png');
  width: 114px;
  height: 105px;
}
.broad_armor_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Rogue.png');
  width: 105px;
  height: 105px;
}
.broad_armor_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Healer.png');
  width: 114px;
  height: 120px;
}
.broad_armor_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Mage.png');
  width: 114px;
  height: 120px;
}
.broad_armor_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Warrior.png');
  width: 114px;
  height: 120px;
}
.broad_armor_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Healer.png');
  width: 116px;
  height: 119px;
}
.broad_armor_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Rogue.png');
  width: 116px;
  height: 119px;
}
.broad_armor_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Mage.png');
  width: 116px;
  height: 119px;
}
.broad_armor_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Warrior.png');
  width: 116px;
  height: 119px;
}
.broad_armor_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Healer.png');
  width: 114px;
  height: 117px;
}
.broad_armor_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Mage.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Rogue.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Warrior.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Healer.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Mage.png');
  width: 124px;
  height: 120px;
}
.broad_armor_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Warrior.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerHealer.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerMage.png');
  width: 90px;
  height: 105px;
}
.broad_armor_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerRogue.png');
  width: 111px;
  height: 90px;
}
.broad_armor_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerWarrior.png');
  width: 111px;
  height: 90px;
}
.eyewear_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_summerRogue.png');
  width: 111px;
  height: 90px;
}
.eyewear_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_summerWarrior.png');
  width: 111px;
  height: 90px;
}
.head_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Rogue.png');
  width: 102px;
  height: 105px;
}
.head_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Warrior.png');
  width: 90px;
  height: 105px;
}
.head_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Rogue.png');
  width: 108px;
  height: 108px;
}
.head_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Healer.png');
  width: 114px;
  height: 102px;
}
.head_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Rogue.png');
  width: 105px;
  height: 105px;
}
.head_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Healer.png');
  width: 114px;
  height: 120px;
}
.head_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Mage.png');
  width: 114px;
  height: 120px;
}
.head_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Warrior.png');
  width: 114px;
  height: 120px;
}
.head_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Healer.png');
  width: 116px;
  height: 119px;
}
.head_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Rogue.png');
  width: 116px;
  height: 119px;
}
.head_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Mage.png');
  width: 116px;
  height: 119px;
}
.head_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Warrior.png');
  width: 116px;
  height: 119px;
}
.head_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Healer.png');
  width: 114px;
  height: 117px;
}
.head_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Mage.png');
  width: 117px;
  height: 120px;
}
.head_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Rogue.png');
  width: 117px;
  height: 120px;
}
.head_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Warrior.png');
  width: 117px;
  height: 120px;
}
.head_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Healer.png');
  width: 117px;
  height: 120px;
}
.head_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Mage.png');
  width: 117px;
  height: 120px;
}
.head_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Warrior.png');
  width: 117px;
  height: 120px;
}
.head_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerHealer.png');
  width: 90px;
  height: 105px;
}
.head_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerMage.png');
  width: 90px;
  height: 105px;
}
.head_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerRogue.png');
  width: 111px;
  height: 90px;
}
.head_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerWarrior.png');
  width: 111px;
  height: 90px;
}
.shield_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2015Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2015Rogue.png');
  width: 102px;
  height: 105px;
}
.shield_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2015Warrior.png');
  width: 90px;
  height: 105px;
}
.shield_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2016Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2016Rogue.png');
  width: 108px;
  height: 108px;
}
.shield_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2016Warrior.png');
  width: 102px;
  height: 105px;
}
.shield_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2017Healer.png');
  width: 114px;
  height: 102px;
}
.shield_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2017Rogue.png');
  width: 105px;
  height: 105px;
}
.shield_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2017Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2018Healer.png');
  width: 114px;
  height: 120px;
}
.shield_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2018Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2018Warrior.png');
  width: 114px;
  height: 120px;
}
.shield_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Healer.png');
  width: 116px;
  height: 119px;
}
.shield_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Mage.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Rogue.png');
  width: 116px;
  height: 119px;
}
.shield_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2020Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2020Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2020Warrior.png');
  width: 116px;
  height: 119px;
}
.shield_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2021Healer.png');
  width: 114px;
  height: 117px;
}
.shield_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2021Rogue.png');
  width: 117px;
  height: 120px;
}
.shield_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2021Warrior.png');
  width: 114px;
  height: 117px;
}
.shield_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2022Healer.png');
  width: 117px;
  height: 120px;
}
.shield_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2022Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2022Warrior.png');
  width: 117px;
  height: 120px;
}
.shield_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summerHealer.png');
  width: 90px;
  height: 105px;
}
.shield_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summerRogue.png');
  width: 111px;
  height: 90px;
}
.shield_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summerWarrior.png');
  width: 111px;
  height: 90px;
}
.shop_armor_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerHealer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerMage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerRogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summerHealer.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summerMage.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_summerRogue.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_summerWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerHealer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerMage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerRogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summerHealer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summerRogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summerWarrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerHealer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerMage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerRogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerWarrior.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Healer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Rogue.png');
  width: 102px;
  height: 105px;
}
.slim_armor_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Warrior.png');
  width: 90px;
  height: 105px;
}
.slim_armor_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Healer.png');
  width: 90px;
  height: 105px;
}
.slim_armor_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Mage.png');
  width: 90px;
  height: 105px;
}
.slim_armor_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Rogue.png');
  width: 90px;
  height: 105px;
}
.slim_armor_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Warrior.png');
  width: 102px;
  height: 105px;
}
.slim_armor_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Healer.png');
  width: 114px;
  height: 105px;
}
.slim_armor_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Rogue.png');
  width: 105px;
  height: 105px;
}
.slim_armor_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Healer.png');
  width: 114px;
  height: 120px;
}
.slim_armor_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Mage.png');
  width: 114px;
  height: 120px;
}
.slim_armor_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Warrior.png');
  width: 114px;
  height: 120px;
}
.slim_armor_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Healer.png');
  width: 116px;
  height: 119px;
}
.slim_armor_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Rogue.png');
  width: 116px;
  height: 119px;
}
.slim_armor_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Mage.png');
  width: 116px;
  height: 119px;
}
.slim_armor_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Warrior.png');
  width: 116px;
  height: 119px;
}
.slim_armor_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Healer.png');
  width: 114px;
  height: 117px;
}
.slim_armor_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Mage.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Rogue.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Warrior.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Healer.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Mage.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Warrior.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerHealer.png');
  width: 90px;
  height: 105px;
}
.slim_armor_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerMage.png');
  width: 90px;
  height: 105px;
}
.slim_armor_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerRogue.png');
  width: 111px;
  height: 90px;
}
.slim_armor_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerWarrior.png');
  width: 111px;
  height: 90px;
}
.weapon_special_summer2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_summer2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_summer2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Rogue.png');
  width: 102px;
  height: 105px;
}
.weapon_special_summer2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Warrior.png');
  width: 90px;
  height: 105px;
}
.weapon_special_summer2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_summer2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_summer2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Rogue.png');
  width: 108px;
  height: 108px;
}
.weapon_special_summer2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Warrior.png');
  width: 102px;
  height: 105px;
}
.weapon_special_summer2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Healer.png');
  width: 114px;
  height: 102px;
}
.weapon_special_summer2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Mage.png');
  width: 105px;
  height: 90px;
}
.weapon_special_summer2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Rogue.png');
  width: 105px;
  height: 105px;
}
.weapon_special_summer2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Healer.png');
  width: 114px;
  height: 120px;
}
.weapon_special_summer2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Mage.png');
  width: 114px;
  height: 120px;
}
.weapon_special_summer2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Warrior.png');
  width: 114px;
  height: 120px;
}
.weapon_special_summer2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Healer.png');
  width: 116px;
  height: 119px;
}
.weapon_special_summer2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Rogue.png');
  width: 116px;
  height: 119px;
}
.weapon_special_summer2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Mage.png');
  width: 116px;
  height: 119px;
}
.weapon_special_summer2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Warrior.png');
  width: 116px;
  height: 119px;
}
.weapon_special_summer2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Healer.png');
  width: 114px;
  height: 117px;
}
.weapon_special_summer2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Mage.png');
  width: 117px;
  height: 120px;
}
.weapon_special_summer2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Rogue.png');
  width: 117px;
  height: 120px;
}
.weapon_special_summer2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Warrior.png');
  width: 141px;
  height: 147px;
}
.weapon_special_summer2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Healer.png');
  width: 117px;
  height: 120px;
}
.weapon_special_summer2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Mage.png');
  width: 117px;
  height: 120px;
}
.weapon_special_summer2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_summer2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Warrior.png');
  width: 117px;
  height: 120px;
}
.weapon_special_summerHealer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerHealer.png');
  width: 90px;
  height: 105px;
}
.weapon_special_summerMage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerMage.png');
  width: 90px;
  height: 105px;
}
.weapon_special_summerRogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerRogue.png');
  width: 111px;
  height: 90px;
}
.weapon_special_summerWarrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerWarrior.png');
  width: 111px;
  height: 90px;
}
.back_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_takeThis.png');
  width: 114px;
  height: 87px;
}
.body_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_takeThis.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_takeThis.png');
  width: 90px;
  height: 90px;
}
.head_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_takeThis.png');
  width: 90px;
  height: 90px;
}
.shield_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_takeThis.png');
  width: 93px;
  height: 90px;
}
.shop_armor_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_takeThis.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_takeThis.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_takeThis.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_takeThis.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_takeThis.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_takeThis.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_takeThis.png');
  width: 90px;
  height: 90px;
}
.weapon_special_takeThis {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_takeThis.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_candycane.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_ski.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_snowflake.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Healer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Rogue.png');
  width: 96px;
  height: 90px;
}
.broad_armor_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Warrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Healer.png');
  width: 93px;
  height: 90px;
}
.broad_armor_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Rogue.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Warrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Healer.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Mage.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Rogue.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Warrior.png');
  width: 90px;
  height: 90px;
}
.broad_armor_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Warrior.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Healer.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Rogue.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Warrior.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Healer.png');
  width: 117px;
  height: 120px;
}
.broad_armor_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Mage.png');
  width: 114px;
  height: 90px;
}
.broad_armor_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Rogue.png');
  width: 116px;
  height: 119px;
}
.broad_armor_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Warrior.png');
  width: 114px;
  height: 117px;
}
.broad_armor_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_yeti.png');
  width: 90px;
  height: 90px;
}
.head_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_candycane.png');
  width: 90px;
  height: 90px;
}
.head_special_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye.png');
  width: 90px;
  height: 90px;
}
.head_special_nye2014 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2014.png');
  width: 90px;
  height: 90px;
}
.head_special_nye2015 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2015.png');
  width: 90px;
  height: 90px;
}
.head_special_nye2016 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2016.png');
  width: 90px;
  height: 90px;
}
.head_special_nye2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2017.png');
  width: 90px;
  height: 90px;
}
.head_special_nye2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2018.png');
  width: 114px;
  height: 90px;
}
.head_special_nye2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2019.png');
  width: 90px;
  height: 90px;
}
.head_special_nye2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2020.png');
  width: 114px;
  height: 90px;
}
.head_special_nye2021 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2021.png');
  width: 114px;
  height: 90px;
}
.head_special_nye2022 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2022.png');
  width: 114px;
  height: 90px;
}
.head_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_ski.png');
  width: 90px;
  height: 90px;
}
.head_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_snowflake.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Rogue.png');
  width: 96px;
  height: 90px;
}
.head_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Healer.png');
  width: 93px;
  height: 90px;
}
.head_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Rogue.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Healer.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Mage.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Rogue.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Warrior.png');
  width: 90px;
  height: 90px;
}
.head_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Warrior.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Healer.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Rogue.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Warrior.png');
  width: 117px;
  height: 120px;
}
.head_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Healer.png');
  width: 117px;
  height: 120px;
}
.head_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Mage.png');
  width: 114px;
  height: 90px;
}
.head_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Rogue.png');
  width: 116px;
  height: 119px;
}
.head_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Warrior.png');
  width: 114px;
  height: 117px;
}
.head_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_yeti.png');
  width: 90px;
  height: 90px;
}
.shield_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_ski.png');
  width: 104px;
  height: 90px;
}
.shield_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_snowflake.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2015Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2015Rogue.png');
  width: 96px;
  height: 90px;
}
.shield_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2015Warrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2016Healer.png');
  width: 93px;
  height: 90px;
}
.shield_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2016Rogue.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2016Warrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2017Healer.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2017Rogue.png');
  width: 96px;
  height: 90px;
}
.shield_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2017Warrior.png');
  width: 90px;
  height: 90px;
}
.shield_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2018Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2018Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2018Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2019Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2019Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2019Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2020Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2020Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2020Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2021Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2021Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2021Warrior.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2022Healer.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2022Rogue.png');
  width: 114px;
  height: 90px;
}
.shield_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2022Warrior.png');
  width: 117px;
  height: 120px;
}
.shield_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2023Healer.png');
  width: 117px;
  height: 120px;
}
.shield_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2023Rogue.png');
  width: 116px;
  height: 119px;
}
.shield_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2023Warrior.png');
  width: 114px;
  height: 117px;
}
.shield_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_yeti.png');
  width: 90px;
  height: 90px;
}
.shop_armor_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_candycane.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_ski.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_snowflake.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Mage.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_armor_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_yeti.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_candycane.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2014 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2014.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2015 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2015.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2016 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2016.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2017.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2018 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2018.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2019 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2019.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2020 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2020.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2021 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2021.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_nye2022 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2022.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_ski.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_snowflake.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Mage.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_yeti.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_ski.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_snowflake.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_yeti.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_candycane.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_ski.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_snowflake.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Healer.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Mage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Rogue.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Warrior.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_yeti.png');
  width: 68px;
  height: 68px;
}
.slim_armor_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_candycane.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_ski.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_snowflake.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Healer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Rogue.png');
  width: 96px;
  height: 90px;
}
.slim_armor_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Warrior.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Healer.png');
  width: 93px;
  height: 90px;
}
.slim_armor_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Rogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Warrior.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Healer.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Mage.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Rogue.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Warrior.png');
  width: 90px;
  height: 90px;
}
.slim_armor_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Warrior.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Healer.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Rogue.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Warrior.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Healer.png');
  width: 117px;
  height: 120px;
}
.slim_armor_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Mage.png');
  width: 114px;
  height: 90px;
}
.slim_armor_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Rogue.png');
  width: 116px;
  height: 119px;
}
.slim_armor_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Warrior.png');
  width: 114px;
  height: 117px;
}
.slim_armor_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_yeti.png');
  width: 90px;
  height: 90px;
}
.weapon_special_candycane {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_candycane.png');
  width: 90px;
  height: 90px;
}
.weapon_special_ski {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_ski.png');
  width: 90px;
  height: 90px;
}
.weapon_special_snowflake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_snowflake.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2015Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2015Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2015Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Rogue.png');
  width: 96px;
  height: 90px;
}
.weapon_special_winter2015Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2016Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Healer.png');
  width: 93px;
  height: 90px;
}
.weapon_special_winter2016Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2016Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Rogue.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2016Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2017Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Healer.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2017Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Mage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2017Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Rogue.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2017Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Warrior.png');
  width: 90px;
  height: 90px;
}
.weapon_special_winter2018Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2018Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2018Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2018Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2019Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2019Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2019Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2019Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2020Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2020Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2020Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2020Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2021Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2021Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2021Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2021Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Warrior.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2022Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Healer.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2022Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2022Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Rogue.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2022Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Warrior.png');
  width: 117px;
  height: 120px;
}
.weapon_special_winter2023Healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Healer.png');
  width: 117px;
  height: 120px;
}
.weapon_special_winter2023Mage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Mage.png');
  width: 114px;
  height: 90px;
}
.weapon_special_winter2023Rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Rogue.png');
  width: 116px;
  height: 119px;
}
.weapon_special_winter2023Warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Warrior.png');
  width: 114px;
  height: 117px;
}
.weapon_special_yeti {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_yeti.png');
  width: 90px;
  height: 90px;
}
.back_special_wondercon_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_wondercon_black.png');
  width: 90px;
  height: 90px;
}
.back_special_wondercon_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_wondercon_red.png');
  width: 90px;
  height: 90px;
}
.body_special_wondercon_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_wondercon_black.png');
  width: 90px;
  height: 90px;
}
.body_special_wondercon_gold {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_wondercon_gold.png');
  width: 90px;
  height: 90px;
}
.body_special_wondercon_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_wondercon_red.png');
  width: 90px;
  height: 90px;
}
.eyewear_special_wondercon_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_wondercon_black.png');
  width: 90px;
  height: 90px;
}
.eyewear_special_wondercon_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_wondercon_red.png');
  width: 90px;
  height: 90px;
}
.shop_back_special_wondercon_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_wondercon_black.png');
  width: 68px;
  height: 68px;
}
.shop_back_special_wondercon_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_wondercon_red.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_wondercon_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_wondercon_black.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_wondercon_gold {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_wondercon_gold.png');
  width: 68px;
  height: 68px;
}
.shop_body_special_wondercon_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_wondercon_red.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_wondercon_black {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_wondercon_black.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_wondercon_red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_wondercon_red.png');
  width: 68px;
  height: 68px;
}
.eyewear_special_aetherMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_aetherMask.png');
  width: 114px;
  height: 90px;
}
.eyewear_special_blackHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_blackHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_blackTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_blackTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackTopFrame.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_blueHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_blueHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_blueTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_blueTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueTopFrame.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_greenHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_greenHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_greenTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_greenTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenTopFrame.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_pinkHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_pinkHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_pinkTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_pinkTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkTopFrame.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_redHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_redHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_redTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_redTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redTopFrame.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_whiteHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_whiteHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_whiteTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_whiteTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteTopFrame.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_yellowHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowHalfMoon.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_yellowHalfMoon {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowHalfMoon.png');
  width: 60px;
  height: 60px;
}
.eyewear_special_yellowTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowTopFrame.png');
  width: 90px;
  height: 90px;
}
.customize-option.eyewear_special_yellowTopFrame {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowTopFrame.png');
  width: 60px;
  height: 60px;
}
.shop_eyewear_special_aetherMask {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_aetherMask.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_blackHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blackHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_blackTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blackTopFrame.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_blueHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blueHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_blueTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blueTopFrame.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_greenHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_greenHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_greenTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_greenTopFrame.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_pinkHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_pinkHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_pinkTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_pinkTopFrame.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_redHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_redHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_redTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_redTopFrame.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_whiteHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_whiteHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_whiteTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_whiteTopFrame.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_yellowHalfMoon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_yellowHalfMoon.png');
  width: 68px;
  height: 68px;
}
.shop_eyewear_special_yellowTopFrame {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_yellowTopFrame.png');
  width: 68px;
  height: 68px;
}
.head_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_0.png');
  width: 90px;
  height: 90px;
}
.customize-option.head_0 {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_0.png');
  width: 60px;
  height: 60px;
}
.head_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_1.png');
  width: 90px;
  height: 90px;
}
.head_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_2.png');
  width: 90px;
  height: 90px;
}
.head_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_3.png');
  width: 90px;
  height: 90px;
}
.head_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_4.png');
  width: 90px;
  height: 90px;
}
.head_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_5.png');
  width: 90px;
  height: 90px;
}
.head_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_1.png');
  width: 90px;
  height: 90px;
}
.head_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_2.png');
  width: 90px;
  height: 90px;
}
.head_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_3.png');
  width: 90px;
  height: 90px;
}
.head_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_4.png');
  width: 90px;
  height: 90px;
}
.head_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_5.png');
  width: 90px;
  height: 90px;
}
.head_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_2.png');
  width: 90px;
  height: 90px;
}
.head_special_bardHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_bardHat.png');
  width: 90px;
  height: 90px;
}
.head_special_clandestineCowl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_clandestineCowl.png');
  width: 90px;
  height: 90px;
}
.head_special_dandyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_dandyHat.png');
  width: 90px;
  height: 90px;
}
.head_special_fireCoralCirclet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fireCoralCirclet.png');
  width: 90px;
  height: 90px;
}
.head_special_kabuto {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_kabuto.png');
  width: 90px;
  height: 90px;
}
.head_special_lunarWarriorHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_lunarWarriorHelm.png');
  width: 90px;
  height: 90px;
}
.head_special_mammothRiderHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_mammothRiderHelm.png');
  width: 90px;
  height: 90px;
}
.head_special_namingDay2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_namingDay2017.png');
  width: 90px;
  height: 90px;
}
.head_special_pageHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_pageHelm.png');
  width: 90px;
  height: 90px;
}
.head_special_pyromancersTurban {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_pyromancersTurban.png');
  width: 90px;
  height: 90px;
}
.head_special_roguishRainbowMessengerHood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_roguishRainbowMessengerHood.png');
  width: 90px;
  height: 90px;
}
.head_special_snowSovereignCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_snowSovereignCrown.png');
  width: 90px;
  height: 90px;
}
.head_special_spikedHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spikedHelm.png');
  width: 90px;
  height: 90px;
}
.head_special_turkeyHelmBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_turkeyHelmBase.png');
  width: 114px;
  height: 90px;
}
.head_special_turkeyHelmGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_turkeyHelmGilded.png');
  width: 114px;
  height: 90px;
}
.head_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_1.png');
  width: 90px;
  height: 90px;
}
.head_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_2.png');
  width: 90px;
  height: 90px;
}
.head_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_3.png');
  width: 90px;
  height: 90px;
}
.head_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_4.png');
  width: 90px;
  height: 90px;
}
.head_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_5.png');
  width: 90px;
  height: 90px;
}
.head_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_1.png');
  width: 90px;
  height: 90px;
}
.head_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_2.png');
  width: 90px;
  height: 90px;
}
.head_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_3.png');
  width: 90px;
  height: 90px;
}
.head_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_4.png');
  width: 90px;
  height: 90px;
}
.head_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_5.png');
  width: 90px;
  height: 90px;
}
.shop_head_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_1.png');
  width: 68px;
  height: 68px;
}
.shop_head_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_2.png');
  width: 68px;
  height: 68px;
}
.shop_head_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_3.png');
  width: 68px;
  height: 68px;
}
.shop_head_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_4.png');
  width: 68px;
  height: 68px;
}
.shop_head_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_5.png');
  width: 68px;
  height: 68px;
}
.shop_head_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_1.png');
  width: 68px;
  height: 68px;
}
.shop_head_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_2.png');
  width: 68px;
  height: 68px;
}
.shop_head_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_3.png');
  width: 68px;
  height: 68px;
}
.shop_head_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_4.png');
  width: 68px;
  height: 68px;
}
.shop_head_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_5.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_0.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_1.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_2.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_bardHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_bardHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_clandestineCowl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_clandestineCowl.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_dandyHat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_dandyHat.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_fireCoralCirclet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fireCoralCirclet.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_kabuto {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_kabuto.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_lunarWarriorHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_lunarWarriorHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_mammothRiderHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_mammothRiderHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_namingDay2017 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_namingDay2017.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_pageHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_pageHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_pyromancersTurban {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_pyromancersTurban.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_roguishRainbowMessengerHood {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_roguishRainbowMessengerHood.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_snowSovereignCrown {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_snowSovereignCrown.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_spikedHelm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spikedHelm.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_turkeyHelmBase {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_turkeyHelmBase.png');
  width: 68px;
  height: 68px;
}
.shop_head_special_turkeyHelmGilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_turkeyHelmGilded.png');
  width: 68px;
  height: 68px;
}
.shop_head_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_1.png');
  width: 68px;
  height: 68px;
}
.shop_head_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_2.png');
  width: 68px;
  height: 68px;
}
.shop_head_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_3.png');
  width: 68px;
  height: 68px;
}
.shop_head_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_4.png');
  width: 68px;
  height: 68px;
}
.shop_head_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_5.png');
  width: 68px;
  height: 68px;
}
.shop_head_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_1.png');
  width: 68px;
  height: 68px;
}
.shop_head_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_2.png');
  width: 68px;
  height: 68px;
}
.shop_head_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_3.png');
  width: 68px;
  height: 68px;
}
.shop_head_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_4.png');
  width: 68px;
  height: 68px;
}
.shop_head_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_5.png');
  width: 68px;
  height: 68px;
}
.headAccessory_special_bearEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_bearEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_bearEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_bearEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_blackHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_blackHeadband.png');
  width: 114px;
  height: 90px;
}
.headAccessory_special_blueHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_blueHeadband.png');
  width: 114px;
  height: 90px;
}
.headAccessory_special_cactusEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_cactusEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_cactusEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_cactusEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_foxEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_foxEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_foxEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_foxEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_greenHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_greenHeadband.png');
  width: 114px;
  height: 90px;
}
.headAccessory_special_lionEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_lionEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_lionEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_lionEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_pandaEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pandaEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_pandaEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pandaEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_pigEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pigEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_pigEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pigEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_pinkHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pinkHeadband.png');
  width: 114px;
  height: 90px;
}
.headAccessory_special_redHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_redHeadband.png');
  width: 114px;
  height: 90px;
}
.headAccessory_special_tigerEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_tigerEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_tigerEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_tigerEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_whiteHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_whiteHeadband.png');
  width: 114px;
  height: 90px;
}
.headAccessory_special_wolfEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_wolfEars.png');
  width: 90px;
  height: 90px;
}
.customize-option.headAccessory_special_wolfEars {
  background-position: -25px -15px;
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_wolfEars.png');
  width: 60px;
  height: 60px;
}
.headAccessory_special_yellowHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_yellowHeadband.png');
  width: 114px;
  height: 90px;
}
.shop_headAccessory_special_bearEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_bearEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_blackHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_blackHeadband.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_blueHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_blueHeadband.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_cactusEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_cactusEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_foxEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_foxEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_greenHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_greenHeadband.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_lionEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_lionEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_pandaEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_pandaEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_pigEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_pigEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_pinkHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_pinkHeadband.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_redHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_redHeadband.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_tigerEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_tigerEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_whiteHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_whiteHeadband.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_wolfEars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_wolfEars.png');
  width: 68px;
  height: 68px;
}
.shop_headAccessory_special_yellowHeadband {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_yellowHeadband.png');
  width: 68px;
  height: 68px;
}
.shield_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_1.png');
  width: 90px;
  height: 90px;
}
.shield_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_2.png');
  width: 90px;
  height: 90px;
}
.shield_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_3.png');
  width: 90px;
  height: 90px;
}
.shield_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_4.png');
  width: 90px;
  height: 90px;
}
.shield_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_5.png');
  width: 90px;
  height: 90px;
}
.shield_rogue_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_0.png');
  width: 90px;
  height: 90px;
}
.shield_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_1.png');
  width: 103px;
  height: 90px;
}
.shield_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_2.png');
  width: 103px;
  height: 90px;
}
.shield_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_3.png');
  width: 114px;
  height: 90px;
}
.shield_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_4.png');
  width: 96px;
  height: 90px;
}
.shield_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_5.png');
  width: 114px;
  height: 90px;
}
.shield_rogue_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_6.png');
  width: 114px;
  height: 90px;
}
.shield_special_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_1.png');
  width: 90px;
  height: 90px;
}
.shield_special_diamondStave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_diamondStave.png');
  width: 102px;
  height: 90px;
}
.shield_special_goldenknight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_goldenknight.png');
  width: 111px;
  height: 90px;
}
.shield_special_lootBag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_lootBag.png');
  width: 90px;
  height: 90px;
}
.shield_special_mammothRiderHorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_mammothRiderHorn.png');
  width: 90px;
  height: 90px;
}
.shield_special_moonpearlShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_moonpearlShield.png');
  width: 90px;
  height: 90px;
}
.shield_special_roguishRainbowMessage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_roguishRainbowMessage.png');
  width: 90px;
  height: 90px;
}
.shield_special_wakizashi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_wakizashi.png');
  width: 114px;
  height: 87px;
}
.shield_special_wintryMirror {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_wintryMirror.png');
  width: 114px;
  height: 87px;
}
.shield_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_1.png');
  width: 90px;
  height: 90px;
}
.shield_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_2.png');
  width: 90px;
  height: 90px;
}
.shield_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_3.png');
  width: 90px;
  height: 90px;
}
.shield_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_4.png');
  width: 90px;
  height: 90px;
}
.shield_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_5.png');
  width: 90px;
  height: 90px;
}
.shop_shield_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_1.png');
  width: 68px;
  height: 68px;
}
.shop_shield_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_2.png');
  width: 68px;
  height: 68px;
}
.shop_shield_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_3.png');
  width: 68px;
  height: 68px;
}
.shop_shield_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_4.png');
  width: 68px;
  height: 68px;
}
.shop_shield_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_5.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_0.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_1.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_2.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_3.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_4.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_5.png');
  width: 68px;
  height: 68px;
}
.shop_shield_rogue_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_6.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_0.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_1.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_diamondStave {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_diamondStave.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_goldenknight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_goldenknight.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_lootBag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_lootBag.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_mammothRiderHorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_mammothRiderHorn.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_moonpearlShield {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_moonpearlShield.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_roguishRainbowMessage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_roguishRainbowMessage.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_wakizashi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_wakizashi.png');
  width: 68px;
  height: 68px;
}
.shop_shield_special_wintryMirror {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_wintryMirror.png');
  width: 68px;
  height: 68px;
}
.shop_shield_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_1.png');
  width: 68px;
  height: 68px;
}
.shop_shield_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_2.png');
  width: 68px;
  height: 68px;
}
.shop_shield_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_3.png');
  width: 68px;
  height: 68px;
}
.shop_shield_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_4.png');
  width: 68px;
  height: 68px;
}
.shop_shield_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_5.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_0.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_1.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_2.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_3.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_4.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_5.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_healer_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_6.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_0.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_1.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_2.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_3.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_4.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_5.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_rogue_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_6.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_0.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_1.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_2.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_3.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_aetherCrystals {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_aetherCrystals.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_bardInstrument {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_bardInstrument.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_critical {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_critical.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_fencingFoil {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fencingFoil.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_lunarScythe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_lunarScythe.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_mammothRiderSpear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_mammothRiderSpear.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_nomadsScimitar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_nomadsScimitar.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_pageBanner {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_pageBanner.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_roguishRainbowMessage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_roguishRainbowMessage.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_skeletonKey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_skeletonKey.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_tachi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_tachi.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_taskwoodsLantern {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_taskwoodsLantern.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_special_tridentOfCrashingTides {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_tridentOfCrashingTides.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_0.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_1.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_2.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_3.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_4.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_5.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_warrior_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_6.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_0.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_1.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_2.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_3.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_4.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_5.png');
  width: 68px;
  height: 68px;
}
.shop_weapon_wizard_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_6.png');
  width: 68px;
  height: 68px;
}
.weapon_healer_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_0.png');
  width: 90px;
  height: 90px;
}
.weapon_healer_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_1.png');
  width: 90px;
  height: 90px;
}
.weapon_healer_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_2.png');
  width: 90px;
  height: 90px;
}
.weapon_healer_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_3.png');
  width: 90px;
  height: 90px;
}
.weapon_healer_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_4.png');
  width: 90px;
  height: 90px;
}
.weapon_healer_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_5.png');
  width: 90px;
  height: 90px;
}
.weapon_healer_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_6.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_0.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_1.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_2.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_3.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_4.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_5.png');
  width: 90px;
  height: 90px;
}
.weapon_rogue_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_6.png');
  width: 90px;
  height: 90px;
}
.weapon_special_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_1.png');
  width: 102px;
  height: 90px;
}
.weapon_special_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_2.png');
  width: 90px;
  height: 90px;
}
.weapon_special_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_3.png');
  width: 90px;
  height: 90px;
}
.weapon_special_aetherCrystals {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_aetherCrystals.png');
  width: 114px;
  height: 90px;
}
.weapon_special_bardInstrument {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_bardInstrument.png');
  width: 90px;
  height: 90px;
}
.weapon_special_fencingFoil {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fencingFoil.png');
  width: 90px;
  height: 90px;
}
.weapon_special_lunarScythe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_lunarScythe.png');
  width: 90px;
  height: 90px;
}
.weapon_special_mammothRiderSpear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_mammothRiderSpear.png');
  width: 90px;
  height: 90px;
}
.weapon_special_nomadsScimitar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_nomadsScimitar.png');
  width: 90px;
  height: 90px;
}
.weapon_special_pageBanner {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_pageBanner.png');
  width: 90px;
  height: 90px;
}
.weapon_special_roguishRainbowMessage {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_roguishRainbowMessage.png');
  width: 90px;
  height: 90px;
}
.weapon_special_skeletonKey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_skeletonKey.png');
  width: 90px;
  height: 90px;
}
.weapon_special_tachi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_tachi.png');
  width: 90px;
  height: 90px;
}
.weapon_special_taskwoodsLantern {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_taskwoodsLantern.png');
  width: 90px;
  height: 90px;
}
.weapon_special_tridentOfCrashingTides {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_tridentOfCrashingTides.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_0.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_1.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_2.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_3.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_4.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_5.png');
  width: 90px;
  height: 90px;
}
.weapon_warrior_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_6.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_0 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_0.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_1.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_2.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_3.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_4.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_5 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_5.png');
  width: 90px;
  height: 90px;
}
.weapon_wizard_6 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_6.png');
  width: 90px;
  height: 90px;
}
.Pet_Currency_Gem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Currency_Gem.png');
  width: 68px;
  height: 68px;
}
.Pet_Currency_Gem1x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Currency_Gem1x.png');
  width: 15px;
  height: 13px;
}
.Pet_Currency_Gem2x {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Currency_Gem2x.png');
  width: 30px;
  height: 26px;
}
.PixelPaw-Gold {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/PixelPaw-Gold.png');
  width: 51px;
  height: 51px;
}
.PixelPaw {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/PixelPaw.png');
  width: 51px;
  height: 51px;
}
.PixelPaw002 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/PixelPaw002.png');
  width: 51px;
  height: 51px;
}
.avatar_floral_healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_healer.png');
  width: 99px;
  height: 99px;
}
.avatar_floral_rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_rogue.png');
  width: 99px;
  height: 99px;
}
.avatar_floral_warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_warrior.png');
  width: 99px;
  height: 99px;
}
.avatar_floral_wizard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_wizard.png');
  width: 99px;
  height: 99px;
}
.avatar_snowball_healer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_healer.png');
  width: 120px;
  height: 105px;
}
.avatar_snowball_rogue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_rogue.png');
  width: 120px;
  height: 105px;
}
.avatar_snowball_warrior {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_warrior.png');
  width: 120px;
  height: 105px;
}
.avatar_snowball_wizard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_wizard.png');
  width: 120px;
  height: 105px;
}
.empty_bottles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/empty_bottles.png');
  width: 64px;
  height: 54px;
}
.ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/ghost.png');
  width: 90px;
  height: 90px;
}
.inventory_present {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present.png');
  width: 68px;
  height: 68px;
}
.inventory_present_01 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_01.png');
  width: 68px;
  height: 68px;
}
.inventory_present_02 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_02.png');
  width: 68px;
  height: 68px;
}
.inventory_present_03 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_03.png');
  width: 68px;
  height: 68px;
}
.inventory_present_04 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_04.png');
  width: 68px;
  height: 68px;
}
.inventory_present_05 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_05.png');
  width: 68px;
  height: 68px;
}
.inventory_present_06 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_06.png');
  width: 68px;
  height: 68px;
}
.inventory_present_07 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_07.png');
  width: 68px;
  height: 68px;
}
.inventory_present_08 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_08.png');
  width: 68px;
  height: 68px;
}
.inventory_present_09 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_09.png');
  width: 68px;
  height: 68px;
}
.inventory_present_10 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_10.png');
  width: 68px;
  height: 68px;
}
.inventory_present_11 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_11.png');
  width: 68px;
  height: 68px;
}
.inventory_present_12 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_12.png');
  width: 68px;
  height: 68px;
}
.inventory_special_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_birthday.png');
  width: 68px;
  height: 68px;
}
.inventory_special_congrats {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_congrats.png');
  width: 68px;
  height: 68px;
}
.inventory_special_fortify {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_fortify.png');
  width: 68px;
  height: 68px;
}
.inventory_special_getwell {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_getwell.png');
  width: 68px;
  height: 68px;
}
.inventory_special_goodluck {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_goodluck.png');
  width: 68px;
  height: 68px;
}
.inventory_special_greeting {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_greeting.png');
  width: 68px;
  height: 68px;
}
.inventory_special_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_nye.png');
  width: 68px;
  height: 68px;
}
.inventory_special_opaquePotion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_opaquePotion.png');
  width: 68px;
  height: 68px;
}
.inventory_special_seafoam {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_seafoam.png');
  width: 68px;
  height: 68px;
}
.inventory_special_shinySeed {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_shinySeed.png');
  width: 68px;
  height: 68px;
}
.inventory_special_snowball {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_snowball.png');
  width: 68px;
  height: 68px;
}
.inventory_special_spookySparkles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_spookySparkles.png');
  width: 68px;
  height: 68px;
}
.inventory_special_thankyou {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_thankyou.png');
  width: 68px;
  height: 68px;
}
.inventory_special_trinket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_trinket.png');
  width: 68px;
  height: 68px;
}
.inventory_special_valentine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_valentine.png');
  width: 68px;
  height: 68px;
}
.knockout {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/knockout.png');
  width: 120px;
  height: 47px;
}
.pet_key {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/pet_key.png');
  width: 68px;
  height: 68px;
}
.rebirth_orb {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/rebirth_orb.png');
  width: 68px;
  height: 68px;
}
.seafoam_star {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/seafoam_star.png');
  width: 90px;
  height: 90px;
}
.shop_armoire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armoire.png');
  width: 68px;
  height: 68px;
}
.zzz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/zzz.png');
  width: 40px;
  height: 40px;
}
.zzz_light {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/zzz_light.png');
  width: 40px;
  height: 40px;
}
.notif_head_special_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_head_special_nye.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_01 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_01.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_02 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_02.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_03 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_03.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_04 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_04.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_05 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_05.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_06 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_06.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_07 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_07.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_08 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_08.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_09 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_09.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_10 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_10.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_11 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_11.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_present_12 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_12.png');
  width: 28px;
  height: 28px;
}
.notif_inventory_special_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_birthday.png');
  width: 20px;
  height: 24px;
}
.notif_inventory_special_congrats {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_congrats.png');
  width: 20px;
  height: 22px;
}
.notif_inventory_special_getwell {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_getwell.png');
  width: 20px;
  height: 22px;
}
.notif_inventory_special_goodluck {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_goodluck.png');
  width: 20px;
  height: 26px;
}
.notif_inventory_special_greeting {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_greeting.png');
  width: 20px;
  height: 22px;
}
.notif_inventory_special_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_nye.png');
  width: 24px;
  height: 26px;
}
.notif_inventory_special_thankyou {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_thankyou.png');
  width: 20px;
  height: 24px;
}
.notif_inventory_special_valentine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_valentine.png');
  width: 20px;
  height: 24px;
}
.npc_bailey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey.png');
  width: 60px;
  height: 72px;
}
.npc_bailey_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_birthday.png');
  width: 63px;
  height: 93px;
}
.npc_bailey_fall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_fall.png');
  width: 60px;
  height: 72px;
}
.npc_bailey_habitoween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_habitoween.png');
  width: 54px;
  height: 72px;
}
.npc_bailey_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_nye.png');
  width: 54px;
  height: 93px;
}
.npc_bailey_spring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_spring.png');
  width: 60px;
  height: 72px;
}
.npc_bailey_summer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_summer.png');
  width: 71px;
  height: 101px;
}
.npc_bailey_thanksgiving {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_thanksgiving.png');
  width: 60px;
  height: 72px;
}
.npc_bailey_valentines {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_valentines.png');
  width: 63px;
  height: 66px;
}
.npc_bailey_winter {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_winter.png');
  width: 54px;
  height: 78px;
}
.npc_matt {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt.png');
  width: 195px;
  height: 138px;
}
.npc_matt_birthday {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_birthday.png');
  width: 195px;
  height: 138px;
}
.npc_matt_fall {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_fall.png');
  width: 195px;
  height: 138px;
}
.npc_matt_habitoween {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_habitoween.png');
  width: 195px;
  height: 138px;
}
.npc_matt_nye {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_nye.png');
  width: 195px;
  height: 138px;
}
.npc_matt_spring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_spring.png');
  width: 195px;
  height: 138px;
}
.npc_matt_summer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_summer.png');
  width: 195px;
  height: 138px;
}
.npc_matt_thanksgiving {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_thanksgiving.png');
  width: 195px;
  height: 138px;
}
.npc_matt_winter {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_winter.png');
  width: 195px;
  height: 138px;
}
.quest_TEMPLATE_FOR_MISSING_IMAGE {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_TEMPLATE_FOR_MISSING_IMAGE.png');
  width: 221px;
  height: 39px;
}
.quest_alligator {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_alligator.png');
  width: 201px;
  height: 213px;
}
.quest_amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_amber.png');
  width: 219px;
  height: 219px;
}
.quest_armadillo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_armadillo.png');
  width: 219px;
  height: 219px;
}
.quest_atom1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom1.png');
  width: 250px;
  height: 150px;
}
.quest_atom2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom2.png');
  width: 207px;
  height: 138px;
}
.quest_atom3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom3.png');
  width: 216px;
  height: 180px;
}
.quest_axolotl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_axolotl.png');
  width: 219px;
  height: 219px;
}
.quest_badger {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_badger.png');
  width: 219px;
  height: 219px;
}
.quest_basilist {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_basilist.png');
  width: 189px;
  height: 141px;
}
.quest_beetle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_beetle.png');
  width: 204px;
  height: 201px;
}
.quest_blackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_blackPearl.png');
  width: 216px;
  height: 216px;
}
.quest_bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bronze.png');
  width: 219px;
  height: 219px;
}
.quest_bunny {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bunny.png');
  width: 210px;
  height: 186px;
}
.quest_butterfly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_butterfly.png');
  width: 219px;
  height: 219px;
}
.quest_cheetah {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_cheetah.png');
  width: 219px;
  height: 219px;
}
.quest_cow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_cow.png');
  width: 174px;
  height: 213px;
}
.quest_dilatory {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatory.png');
  width: 219px;
  height: 219px;
}
.quest_dilatoryDistress1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress1.png');
  width: 210px;
  height: 210px;
}
.quest_dilatoryDistress2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress2.png');
  width: 150px;
  height: 150px;
}
.quest_dilatoryDistress3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress3.png');
  width: 219px;
  height: 219px;
}
.quest_dilatory_derby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatory_derby.png');
  width: 219px;
  height: 219px;
}
.quest_dolphin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dolphin.png');
  width: 219px;
  height: 219px;
}
.quest_dustbunnies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dustbunnies.png');
  width: 219px;
  height: 219px;
}
.quest_egg {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_egg.png');
  width: 165px;
  height: 207px;
}
.quest_evilsanta {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta.png');
  width: 118px;
  height: 131px;
}
.quest_evilsanta2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta2.png');
  width: 219px;
  height: 219px;
}
.quest_falcon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_falcon.png');
  width: 219px;
  height: 219px;
}
.quest_ferret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ferret.png');
  width: 219px;
  height: 219px;
}
.quest_fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_fluorite.png');
  width: 219px;
  height: 219px;
}
.quest_frog {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_frog.png');
  width: 221px;
  height: 213px;
}
.quest_ghost_stag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ghost_stag.png');
  width: 219px;
  height: 219px;
}
.quest_goldenknight1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight1.png');
  width: 219px;
  height: 219px;
}
.quest_goldenknight2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight2.png');
  width: 250px;
  height: 150px;
}
.quest_goldenknight3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight3.png');
  width: 219px;
  height: 231px;
}
.quest_gryphon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_gryphon.png');
  width: 216px;
  height: 177px;
}
.quest_guineapig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_guineapig.png');
  width: 219px;
  height: 219px;
}
.quest_harpy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_harpy.png');
  width: 219px;
  height: 219px;
}
.quest_hedgehog {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_hedgehog.png');
  width: 219px;
  height: 186px;
}
.quest_hippo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_hippo.png');
  width: 219px;
  height: 219px;
}
.quest_horse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_horse.png');
  width: 219px;
  height: 219px;
}
.quest_kangaroo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_kangaroo.png');
  width: 219px;
  height: 219px;
}
.quest_kraken {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_kraken.png');
  width: 216px;
  height: 177px;
}
.quest_lostMasterclasser1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1.png');
  width: 219px;
  height: 219px;
}
.quest_lostMasterclasser2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser2.png');
  width: 219px;
  height: 219px;
}
.quest_lostMasterclasser3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser3.png');
  width: 219px;
  height: 219px;
}
.quest_mayhemMistiflying1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying1.png');
  width: 150px;
  height: 150px;
}
.quest_mayhemMistiflying2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2.png');
  width: 219px;
  height: 219px;
}
.quest_mayhemMistiflying3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying3.png');
  width: 219px;
  height: 219px;
}
.quest_monkey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_monkey.png');
  width: 219px;
  height: 219px;
}
.quest_moon1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon1.png');
  width: 216px;
  height: 216px;
}
.quest_moon2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon2.png');
  width: 219px;
  height: 219px;
}
.quest_moon3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon3.png');
  width: 219px;
  height: 219px;
}
.quest_moonstone1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone1.png');
  width: 219px;
  height: 219px;
}
.quest_moonstone2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone2.png');
  width: 219px;
  height: 219px;
}
.quest_moonstone3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone3.png');
  width: 219px;
  height: 219px;
}
.quest_nudibranch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_nudibranch.png');
  width: 216px;
  height: 216px;
}
.quest_octopus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_octopus.png');
  width: 222px;
  height: 177px;
}
.quest_onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx.png');
  width: 219px;
  height: 219px;
}
.quest_owl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_owl.png');
  width: 219px;
  height: 219px;
}
.quest_peacock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_peacock.png');
  width: 216px;
  height: 216px;
}
.quest_penguin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_penguin.png');
  width: 190px;
  height: 183px;
}
.quest_pinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_pinkMarble.png');
  width: 219px;
  height: 219px;
}
.quest_pterodactyl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_pterodactyl.png');
  width: 219px;
  height: 219px;
}
.quest_rat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_rat.png');
  width: 219px;
  height: 219px;
}
.quest_robot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot.png');
  width: 219px;
  height: 219px;
}
.quest_rock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_rock.png');
  width: 216px;
  height: 216px;
}
.quest_rooster {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_rooster.png');
  width: 213px;
  height: 174px;
}
.quest_ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby.png');
  width: 219px;
  height: 219px;
}
.quest_sabretooth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_sabretooth.png');
  width: 219px;
  height: 219px;
}
.quest_seaserpent {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_seaserpent.png');
  width: 219px;
  height: 219px;
}
.quest_sheep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_sheep.png');
  width: 219px;
  height: 219px;
}
.quest_silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver.png');
  width: 219px;
  height: 219px;
}
.quest_slime {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_slime.png');
  width: 219px;
  height: 219px;
}
.quest_sloth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_sloth.png');
  width: 219px;
  height: 219px;
}
.quest_snail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_snail.png');
  width: 219px;
  height: 213px;
}
.quest_snake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_snake.png');
  width: 216px;
  height: 177px;
}
.quest_spider {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_spider.png');
  width: 250px;
  height: 150px;
}
.quest_squirrel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_squirrel.png');
  width: 219px;
  height: 219px;
}
.quest_stoikalmCalamity1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity1.png');
  width: 150px;
  height: 150px;
}
.quest_stoikalmCalamity2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity2.png');
  width: 219px;
  height: 219px;
}
.quest_stoikalmCalamity3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity3.png');
  width: 219px;
  height: 219px;
}
.quest_stone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone.png');
  width: 219px;
  height: 219px;
}
.quest_taskwoodsTerror1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror1.png');
  width: 150px;
  height: 150px;
}
.quest_taskwoodsTerror2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2.png');
  width: 216px;
  height: 216px;
}
.quest_taskwoodsTerror3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror3.png');
  width: 219px;
  height: 219px;
}
.quest_treeling {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_treeling.png');
  width: 216px;
  height: 177px;
}
.quest_trex {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_trex.png');
  width: 204px;
  height: 177px;
}
.quest_trex_undead {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_trex_undead.png');
  width: 216px;
  height: 177px;
}
.quest_triceratops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_triceratops.png');
  width: 219px;
  height: 219px;
}
.quest_turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise.png');
  width: 219px;
  height: 219px;
}
.quest_turtle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turtle.png');
  width: 219px;
  height: 219px;
}
.quest_unicorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_unicorn.png');
  width: 219px;
  height: 219px;
}
.quest_velociraptor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_velociraptor.png');
  width: 222px;
  height: 225px;
}
.quest_vice1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice1.png');
  width: 216px;
  height: 177px;
}
.quest_vice2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice2.png');
  width: 219px;
  height: 219px;
}
.quest_vice3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice3.png');
  width: 216px;
  height: 177px;
}
.quest_waffle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_waffle.png');
  width: 219px;
  height: 219px;
}
.quest_whale {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_whale.png');
  width: 219px;
  height: 219px;
}
.quest_yarn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_yarn.png');
  width: 216px;
  height: 216px;
}
.quest_atom1_soapBars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom1_soapBars.png');
  width: 48px;
  height: 51px;
}
.quest_dilatoryDistress1_blueFins {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress1_blueFins.png');
  width: 51px;
  height: 48px;
}
.quest_dilatoryDistress1_fireCoral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress1_fireCoral.png');
  width: 48px;
  height: 51px;
}
.quest_egg_plainEgg {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_egg_plainEgg.png');
  width: 48px;
  height: 51px;
}
.quest_evilsanta2_branches {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta2_branches.png');
  width: 48px;
  height: 51px;
}
.quest_evilsanta2_tracks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta2_tracks.png');
  width: 54px;
  height: 60px;
}
.quest_goldenknight1_testimony {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight1_testimony.png');
  width: 48px;
  height: 51px;
}
.quest_lostMasterclasser1_ancientTome {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1_ancientTome.png');
  width: 33px;
  height: 42px;
}
.quest_lostMasterclasser1_forbiddenTome {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1_forbiddenTome.png');
  width: 33px;
  height: 42px;
}
.quest_lostMasterclasser1_hiddenTome {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1_hiddenTome.png');
  width: 33px;
  height: 42px;
}
.quest_mayhemMistiflying2_mistifly1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2_mistifly1.png');
  width: 48px;
  height: 51px;
}
.quest_mayhemMistiflying2_mistifly2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2_mistifly2.png');
  width: 48px;
  height: 51px;
}
.quest_mayhemMistiflying2_mistifly3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2_mistifly3.png');
  width: 48px;
  height: 51px;
}
.quest_moon1_shard {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon1_shard.png');
  width: 42px;
  height: 42px;
}
.quest_moonstone1_moonstone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone1_moonstone.png');
  width: 30px;
  height: 30px;
}
.quest_onyx_leoRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx_leoRune.png');
  width: 39px;
  height: 39px;
}
.quest_onyx_onyxStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx_onyxStone.png');
  width: 48px;
  height: 51px;
}
.quest_onyx_plutoRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx_plutoRune.png');
  width: 39px;
  height: 39px;
}
.quest_robot_bolt {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot_bolt.png');
  width: 48px;
  height: 51px;
}
.quest_robot_gear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot_gear.png');
  width: 48px;
  height: 51px;
}
.quest_robot_spring {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot_spring.png');
  width: 48px;
  height: 51px;
}
.quest_ruby_aquariusRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby_aquariusRune.png');
  width: 39px;
  height: 40px;
}
.quest_ruby_rubyGem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby_rubyGem.png');
  width: 48px;
  height: 51px;
}
.quest_ruby_venusRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby_venusRune.png');
  width: 39px;
  height: 39px;
}
.quest_silver_cancerRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver_cancerRune.png');
  width: 39px;
  height: 39px;
}
.quest_silver_moonRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver_moonRune.png');
  width: 39px;
  height: 42px;
}
.quest_silver_silverIngot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver_silverIngot.png');
  width: 48px;
  height: 51px;
}
.quest_stoikalmCalamity2_icicleCoin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity2_icicleCoin.png');
  width: 48px;
  height: 51px;
}
.quest_stone_capricornRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone_capricornRune.png');
  width: 42px;
  height: 42px;
}
.quest_stone_marsRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone_marsRune.png');
  width: 42px;
  height: 42px;
}
.quest_stone_mossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone_mossyStone.png');
  width: 48px;
  height: 51px;
}
.quest_taskwoodsTerror2_brownie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2_brownie.png');
  width: 48px;
  height: 51px;
}
.quest_taskwoodsTerror2_dryad {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2_dryad.png');
  width: 48px;
  height: 51px;
}
.quest_taskwoodsTerror2_pixie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2_pixie.png');
  width: 48px;
  height: 51px;
}
.quest_turquoise_neptuneRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise_neptuneRune.png');
  width: 39px;
  height: 39px;
}
.quest_turquoise_sagittariusRune {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise_sagittariusRune.png');
  width: 39px;
  height: 39px;
}
.quest_turquoise_turquoiseGem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise_turquoiseGem.png');
  width: 48px;
  height: 51px;
}
.quest_vice2_lightCrystal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice2_lightCrystal.png');
  width: 40px;
  height: 40px;
}
.inventory_quest_scroll_alligator {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_alligator.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_amber.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_armadillo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_armadillo.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_atom1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_atom1_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom1_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_atom2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_atom2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_atom3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_atom3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_axolotl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_axolotl.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_badger {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_badger.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_basilist {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_basilist.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_beetle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_beetle.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_blackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_blackPearl.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_bronze.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_bunny {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_bunny.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_butterfly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_butterfly.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_cheetah {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_cheetah.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_cow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_cow.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dilatoryDistress1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dilatoryDistress2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dilatoryDistress2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dilatoryDistress3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dilatoryDistress3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dilatory_derby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatory_derby.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dolphin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dolphin.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_dustbunnies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dustbunnies.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_egg {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_egg.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_evilsanta {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_evilsanta.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_evilsanta2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_evilsanta2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_falcon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_falcon.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_ferret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_ferret.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_fluorite.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_frog {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_frog.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_ghost_stag {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_ghost_stag.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_goldenknight1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_goldenknight1_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight1_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_goldenknight2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_goldenknight2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_goldenknight3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_goldenknight3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_gryphon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_gryphon.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_guineapig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_guineapig.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_harpy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_harpy.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_hedgehog {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_hedgehog.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_hippo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_hippo.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_horse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_horse.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_kangaroo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_kangaroo.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_kraken {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_kraken.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser1_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser1_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser4 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser4.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_lostMasterclasser4_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser4_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_mayhemMistiflying1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_mayhemMistiflying2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_mayhemMistiflying2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_mayhemMistiflying3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_mayhemMistiflying3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_monkey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_monkey.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moon1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moon1_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon1_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moon2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moon2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moon3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moon3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moonstone1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moonstone1_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone1_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moonstone2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moonstone2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moonstone3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_moonstone3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_nudibranch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_nudibranch.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_octopus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_octopus.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_onyx.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_owl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_owl.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_peacock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_peacock.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_penguin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_penguin.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_pinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_pinkMarble.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_pterodactyl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_pterodactyl.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_rat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_rat.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_robot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_robot.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_rock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_rock.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_rooster {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_rooster.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_ruby.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_sabretooth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_sabretooth.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_seaserpent {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_seaserpent.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_sheep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_sheep.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_silver.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_slime {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_slime.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_sloth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_sloth.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_snail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_snail.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_snake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_snake.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_solarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_solarSystem.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_spider {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_spider.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_squirrel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_squirrel.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_stoikalmCalamity1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_stoikalmCalamity2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_stoikalmCalamity2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_stoikalmCalamity3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_stoikalmCalamity3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_stone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stone.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_taskwoodsTerror1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_taskwoodsTerror2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_taskwoodsTerror2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_taskwoodsTerror3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_taskwoodsTerror3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_treeling {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_treeling.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_trex {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_trex.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_trex_undead {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_trex_undead.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_triceratops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_triceratops.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_turquoise.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_turtle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_turtle.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_unicorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_unicorn.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_velociraptor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_velociraptor.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_vice1 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice1.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_vice1_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice1_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_vice2 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice2.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_vice2_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice2_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_vice3 {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice3.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_vice3_locked {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice3_locked.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_virtualpet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_virtualpet.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_waffle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_waffle.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_whale {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_whale.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_windup.png');
  width: 68px;
  height: 68px;
}
.inventory_quest_scroll_yarn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_yarn.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_aquaticAmigos {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_aquaticAmigos.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_birdBuddies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_birdBuddies.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_cuddleBuddies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_cuddleBuddies.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_delightfulDinos {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_delightfulDinos.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_farmFriends {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_farmFriends.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_featheredFriends {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_featheredFriends.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_forestFriends {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_forestFriends.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_hugabug {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_hugabug.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_jungleBuddies {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_jungleBuddies.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_mythicalMarvels {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_mythicalMarvels.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_oddballs {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_oddballs.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_rockingReptiles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_rockingReptiles.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_sandySidekicks {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_sandySidekicks.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_splashyPals {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_splashyPals.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_winterQuests {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_winterQuests.png');
  width: 68px;
  height: 68px;
}
.quest_bundle_witchyFamiliars {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_witchyFamiliars.png');
  width: 68px;
  height: 68px;
}
.shop_gem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_gem.png');
  width: 68px;
  height: 68px;
}
.shop_opaquePotion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_opaquePotion.png');
  width: 68px;
  height: 68px;
}
.shop_petalFreePotion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_petalFreePotion.png');
  width: 68px;
  height: 68px;
}
.shop_potion {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_potion.png');
  width: 68px;
  height: 68px;
}
.shop_salt {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_salt.png');
  width: 68px;
  height: 68px;
}
.shop_sand {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_sand.png');
  width: 68px;
  height: 68px;
}
.shop_seafoam {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_seafoam.png');
  width: 68px;
  height: 68px;
}
.shop_shinySeed {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shinySeed.png');
  width: 68px;
  height: 68px;
}
.shop_snowball {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_snowball.png');
  width: 68px;
  height: 68px;
}
.shop_spookySparkles {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_spookySparkles.png');
  width: 68px;
  height: 68px;
}
.shop_mounts_Hippogriff-Hopeful {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_Hippogriff-Hopeful.png');
  width: 68px;
  height: 68px;
}
.shop_mounts_MagicalBee-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_MagicalBee-Base.png');
  width: 68px;
  height: 68px;
}
.shop_mounts_Mammoth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_Mammoth-Base.png');
  width: 68px;
  height: 68px;
}
.shop_mounts_MantisShrimp-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_MantisShrimp-Base.png');
  width: 68px;
  height: 68px;
}
.shop_mounts_Phoenix-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_Phoenix-Base.png');
  width: 68px;
  height: 68px;
}
.shop_pets_Hippogriff-Hopeful {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_Hippogriff-Hopeful.png');
  width: 68px;
  height: 68px;
}
.shop_pets_MagicalBee-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_MagicalBee-Base.png');
  width: 68px;
  height: 68px;
}
.shop_pets_Mammoth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_Mammoth-Base.png');
  width: 68px;
  height: 68px;
}
.shop_pets_MantisShrimp-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_MantisShrimp-Base.png');
  width: 68px;
  height: 68px;
}
.shop_pets_Phoenix-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_Phoenix-Base.png');
  width: 68px;
  height: 68px;
}
.shop_backStab {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_backStab.png');
  width: 40px;
  height: 40px;
}
.shop_brightness {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_brightness.png');
  width: 40px;
  height: 40px;
}
.shop_defensiveStance {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_defensiveStance.png');
  width: 40px;
  height: 40px;
}
.shop_earth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_earth.png');
  width: 40px;
  height: 40px;
}
.shop_fireball {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_fireball.png');
  width: 40px;
  height: 40px;
}
.shop_frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_frost.png');
  width: 40px;
  height: 40px;
}
.shop_heal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_heal.png');
  width: 40px;
  height: 40px;
}
.shop_healAll {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_healAll.png');
  width: 40px;
  height: 40px;
}
.shop_intimidate {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_intimidate.png');
  width: 40px;
  height: 40px;
}
.shop_mpheal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mpheal.png');
  width: 40px;
  height: 40px;
}
.shop_pickPocket {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pickPocket.png');
  width: 40px;
  height: 40px;
}
.shop_protectAura {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_protectAura.png');
  width: 40px;
  height: 40px;
}
.shop_smash {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_smash.png');
  width: 40px;
  height: 40px;
}
.shop_stealth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_stealth.png');
  width: 40px;
  height: 40px;
}
.shop_toolsOfTrade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_toolsOfTrade.png');
  width: 40px;
  height: 40px;
}
.shop_valorousPresence {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_valorousPresence.png');
  width: 40px;
  height: 40px;
}
.Pet_Egg_Alligator {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Alligator.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Armadillo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Armadillo.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Axolotl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Axolotl.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Badger {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Badger.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_BearCub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_BearCub.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Beetle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Beetle.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Bunny {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Bunny.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Butterfly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Butterfly.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Cactus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cactus.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Cheetah {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cheetah.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Cow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cow.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Cuttlefish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cuttlefish.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Deer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Deer.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Dolphin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Dolphin.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Dragon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Dragon.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Egg {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Egg.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Falcon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Falcon.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Ferret {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Ferret.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_FlyingPig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_FlyingPig.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Fox {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Fox.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Frog {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Frog.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Gryphon {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Gryphon.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_GuineaPig {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_GuineaPig.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Hedgehog {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Hedgehog.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Hippo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Hippo.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Horse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Horse.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Kangaroo {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Kangaroo.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_LionCub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_LionCub.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Monkey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Monkey.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Nudibranch {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Nudibranch.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Octopus {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Octopus.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Owl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Owl.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_PandaCub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_PandaCub.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Parrot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Parrot.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Peacock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Peacock.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Penguin {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Penguin.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_PolarBear {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_PolarBear.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Pterodactyl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Pterodactyl.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Rat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Rat.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Robot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Robot.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Rock {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Rock.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Rooster {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Rooster.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Sabretooth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Sabretooth.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_SeaSerpent {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_SeaSerpent.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Seahorse {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Seahorse.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Sheep {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Sheep.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Slime {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Slime.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Sloth {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Sloth.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Snail {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Snail.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Snake {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Snake.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Spider {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Spider.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Squirrel {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Squirrel.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_TRex {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_TRex.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_TigerCub {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_TigerCub.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Treeling {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Treeling.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Triceratops {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Triceratops.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Turtle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Turtle.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Unicorn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Unicorn.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Velociraptor {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Velociraptor.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Whale {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Whale.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Wolf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Wolf.png');
  width: 68px;
  height: 68px;
}
.Pet_Egg_Yarn {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Yarn.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Base.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_CottonCandyBlue.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_CottonCandyPink.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Desert.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Golden.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Red.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Shade.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Skeleton.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_White.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Cake_Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Zombie.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Base.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_CottonCandyBlue.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_CottonCandyPink.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Desert.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Golden.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Red.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Shade.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Skeleton.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_White.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Candy_Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Zombie.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Chocolate {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Chocolate.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_CottonCandyBlue.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_CottonCandyPink.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Fish {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Fish.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Honey {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Honey.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Meat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Meat.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Milk {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Milk.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Base.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_CottonCandyBlue.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_CottonCandyPink.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Desert.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Golden.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Red.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Shade.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Skeleton.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_White.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Pie_Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Zombie.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Potatoe {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Potatoe.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_RottenMeat {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_RottenMeat.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Saddle {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Saddle.png');
  width: 68px;
  height: 68px;
}
.Pet_Food_Strawberry {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Strawberry.png');
  width: 68px;
  height: 68px;
}
.Mount_Body_Alligator-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Alligator-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Armadillo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Axolotl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Badger-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Polar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Polar.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_BearCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_BearCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Beetle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Bunny-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Butterfly-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Base.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-CottonCandyBlue.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-CottonCandyPink.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Desert.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Golden.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Red.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Shade.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Skeleton.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-White.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Butterfly-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Zombie.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_Cactus-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_Cactus-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cactus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cheetah-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cow-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Cuttlefish-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Base.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-CottonCandyBlue.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-CottonCandyPink.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Desert.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Golden.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Red.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Shade.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Skeleton.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-White.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Cuttlefish-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Zombie.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Deer-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Deer-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dolphin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_Dragon-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Dragon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Egg-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Falcon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Ferret-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_FlyingPig-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_FlyingPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_Fox-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Fox-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Frog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Base.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-CottonCandyBlue.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-CottonCandyPink.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Desert.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Golden.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Red.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Shade.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Skeleton.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-White.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Frog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Zombie.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Gryphon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Gryphon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_GuineaPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hedgehog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Hippogriff-Hopeful {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippogriff-Hopeful.png');
  width: 111px;
  height: 108px;
}
.Mount_Body_Horse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Horse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_JackOLantern-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-Base.png');
  width: 90px;
  height: 105px;
}
.Mount_Body_JackOLantern-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-Ghost.png');
  width: 90px;
  height: 105px;
}
.Mount_Body_JackOLantern-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-Glow.png');
  width: 93px;
  height: 105px;
}
.Mount_Body_JackOLantern-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-RoyalPurple.png');
  width: 93px;
  height: 105px;
}
.Mount_Body_Jackalope-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Jackalope-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Kangaroo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Ethereal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ethereal.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Skeleton.png');
  width: 111px;
  height: 105px;
}
.Mount_Body_LionCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_LionCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_LionCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_MagicalBee-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_MagicalBee-Base.png');
  width: 105px;
  height: 114px;
}
.Mount_Body_Mammoth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Mammoth-Base.png');
  width: 105px;
  height: 123px;
}
.Mount_Body_MantisShrimp-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_MantisShrimp-Base.png');
  width: 108px;
  height: 105px;
}
.Mount_Body_Monkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Monkey-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Nudibranch-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Octopus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Orca-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Orca-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Owl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_PandaCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_PandaCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Parrot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Peacock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Penguin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Phoenix-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Phoenix-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Pterodactyl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rat-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Robot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Rooster-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sabretooth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Base.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-CottonCandyBlue.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-CottonCandyPink.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Desert.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Golden.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Red.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Shade.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Skeleton.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-White.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Sabretooth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Zombie.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_SeaSerpent-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_SeaSerpent-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Seahorse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sheep-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Slime-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Sloth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snail-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Snake-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Spider-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Squirrel-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Base.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-CottonCandyBlue.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-CottonCandyPink.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Desert.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Golden.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Red.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Shade.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Skeleton.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-White.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_Squirrel-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Zombie.png');
  width: 105px;
  height: 108px;
}
.Mount_Body_TRex-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Base.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-CottonCandyBlue.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-CottonCandyPink.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Desert.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Golden.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Red.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Shade.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Skeleton.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-White.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TRex-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Zombie.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_TigerCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Body_TigerCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_TigerCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Treeling-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Triceratops-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turkey-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turkey-Gilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turkey-Gilded.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Turtle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Unicorn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Velociraptor-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Whale-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Wolf-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Amber.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Aquatic.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Aurora.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-AutumnLeaf.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Base.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-BirchBark.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-BlackPearl.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Bronze.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Celestial.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-CottonCandyBlue.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-CottonCandyPink.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Cupid.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Desert.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Ember.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Fairy.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Floral.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Fluorite.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Frost.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Ghost.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Glass.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Glow.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Golden.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Holly.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-IcySnow.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Moonglow.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-MossyStone.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Onyx.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Peppermint.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-PinkMarble.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-PolkaDot.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Porcelain.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Rainbow.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Red.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-RoseQuartz.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-RoyalPurple.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Ruby.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-SandSculpture.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Shade.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Shadow.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Shimmer.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Silver.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Skeleton.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-SolarSystem.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Spooky.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-StainedGlass.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-StarryNight.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Sunset.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Sunshine.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Thunderstorm.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Turquoise.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Vampire.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Watery.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-White.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Windup.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Wolf-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Zombie.png');
  width: 135px;
  height: 135px;
}
.Mount_Body_Yarn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Body_Yarn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Alligator-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Armadillo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Axolotl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Badger-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Polar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Polar.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_BearCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_BearCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Beetle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Bunny-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Butterfly-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Base.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-CottonCandyBlue.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-CottonCandyPink.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Desert.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Golden.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Red.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Shade.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Skeleton.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-White.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Butterfly-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Zombie.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_Cactus-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_Cactus-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cactus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cheetah-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cow-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Cuttlefish-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Base.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-CottonCandyBlue.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-CottonCandyPink.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Desert.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Golden.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Red.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Shade.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Skeleton.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-White.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Cuttlefish-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Zombie.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Deer-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Deer-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dolphin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_Dragon-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Dragon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Egg-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Falcon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Ferret-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_FlyingPig-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_FlyingPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_Fox-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Fox-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Frog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Base.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-CottonCandyBlue.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-CottonCandyPink.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Desert.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Golden.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Red.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Shade.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Skeleton.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-White.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Frog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Zombie.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Gryphon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Gryphon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_GuineaPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hedgehog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Hippogriff-Hopeful {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippogriff-Hopeful.png');
  width: 105px;
  height: 111px;
}
.Mount_Head_Horse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Horse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_JackOLantern-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-Base.png');
  width: 90px;
  height: 105px;
}
.Mount_Head_JackOLantern-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-Ghost.png');
  width: 90px;
  height: 105px;
}
.Mount_Head_JackOLantern-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-Glow.png');
  width: 93px;
  height: 105px;
}
.Mount_Head_JackOLantern-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-RoyalPurple.png');
  width: 93px;
  height: 105px;
}
.Mount_Head_Jackalope-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Jackalope-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Kangaroo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Ethereal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ethereal.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Skeleton.png');
  width: 105px;
  height: 110px;
}
.Mount_Head_LionCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_LionCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_LionCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_MagicalBee-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_MagicalBee-Base.png');
  width: 105px;
  height: 114px;
}
.Mount_Head_Mammoth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Mammoth-Base.png');
  width: 105px;
  height: 123px;
}
.Mount_Head_MantisShrimp-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_MantisShrimp-Base.png');
  width: 108px;
  height: 105px;
}
.Mount_Head_Monkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Monkey-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Nudibranch-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Octopus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Orca-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Orca-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Owl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_PandaCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_PandaCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Parrot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Peacock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Penguin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Phoenix-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Phoenix-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Pterodactyl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rat-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Robot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Rooster-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sabretooth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Base.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-CottonCandyBlue.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-CottonCandyPink.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Desert.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Golden.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Red.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Shade.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Skeleton.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-White.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Sabretooth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Zombie.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_SeaSerpent-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_SeaSerpent-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Seahorse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sheep-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Slime-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Sloth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snail-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Snake-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Spider-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Squirrel-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TRex-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Base.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-CottonCandyBlue.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-CottonCandyPink.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Desert.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Golden.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Red.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Shade.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Skeleton.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-White.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TRex-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Zombie.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_TigerCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Amber.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Aquatic.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Aurora.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-AutumnLeaf.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-BirchBark.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-BlackPearl.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Bronze.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Celestial.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Cupid.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Ember.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Fairy.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Floral.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Fluorite.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Frost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Ghost.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Glass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Glow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Holly.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-IcySnow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Moonglow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-MossyStone.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Onyx.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Peppermint.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-PinkMarble.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-PolkaDot.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Porcelain.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Rainbow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-RoseQuartz.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-RoyalPurple.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Ruby.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-SandSculpture.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Shadow.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Shimmer.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Silver.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-SolarSystem.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Spooky.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-StainedGlass.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-StarryNight.png');
  width: 120px;
  height: 120px;
}
.Mount_Head_TigerCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Sunset.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Sunshine.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Thunderstorm.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Turquoise.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Vampire.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Watery.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Windup.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_TigerCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Treeling-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Triceratops-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turkey-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turkey-Gilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turkey-Gilded.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Turtle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Unicorn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Velociraptor-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Whale-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Wolf-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Amber.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Aquatic.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Aurora.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-AutumnLeaf.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Base.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-BirchBark.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-BlackPearl.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Bronze.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Celestial.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-CottonCandyBlue.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-CottonCandyPink.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Cupid.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Desert.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Ember.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Fairy.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Floral.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Fluorite.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Frost.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Ghost.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Glass.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Glow.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Golden.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Holly.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-IcySnow.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Moonglow.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-MossyStone.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Onyx.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Peppermint.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-PinkMarble.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-PolkaDot.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Porcelain.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Rainbow.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Red.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-RoseQuartz.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-RoyalPurple.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Ruby.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-SandSculpture.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Shade.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Shadow.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Shimmer.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Silver.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Skeleton.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-SolarSystem.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Spooky.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-StainedGlass.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-StarryNight.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Sunset.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Sunshine.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Thunderstorm.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Turquoise.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Vampire.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Watery.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-White.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Windup.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Wolf-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Zombie.png');
  width: 135px;
  height: 135px;
}
.Mount_Head_Yarn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Base.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-CottonCandyBlue.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-CottonCandyPink.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Desert.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Golden.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Red.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Shade.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Skeleton.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-White.png');
  width: 105px;
  height: 105px;
}
.Mount_Head_Yarn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Zombie.png');
  width: 105px;
  height: 105px;
}
.Mount_Icon_Aether-Invisible {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Aether-Invisible.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Alligator-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Armadillo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Axolotl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Badger-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Polar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Polar.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_BearCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Beetle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Bunny-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Butterfly-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cactus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cheetah-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cow-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Cuttlefish-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Deer-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dolphin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Dragon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Egg-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Falcon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Ferret-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_FlyingPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Fox-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Frog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Gryphatrice {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Gryphatrice.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Gryphon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_GuineaPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hedgehog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Hippogriff-Hopeful {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippogriff-Hopeful.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Horse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_JackOLantern-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_JackOLantern-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_JackOLantern-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_JackOLantern-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Jackalope-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Jackalope-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Kangaroo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Ethereal {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ethereal.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_LionCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_MagicalBee-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_MagicalBee-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Mammoth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Mammoth-Base.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_MantisShrimp-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_MantisShrimp-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Monkey-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Nudibranch-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Octopus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Orca-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Orca-Base.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Owl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Owl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_PandaCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Parrot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Peacock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Penguin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Phoenix-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Phoenix-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Pterodactyl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rat-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Robot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Rooster-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sabretooth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_SeaSerpent-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Seahorse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sheep-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Slime-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Sloth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snail-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Snake-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Spider-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Squirrel-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TRex-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_TigerCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Treeling-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Triceratops-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turkey-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turkey-Gilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turkey-Gilded.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Turtle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Unicorn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Velociraptor-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Whale-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Base.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-CottonCandyBlue.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-CottonCandyPink.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Desert.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Golden.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Red.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Shade.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Skeleton.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-White.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Whale-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Zombie.png');
  width: 78px;
  height: 86px;
}
.Mount_Icon_Wolf-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Amber.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Aurora.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Bronze.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Celestial.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Cupid.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Ember.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Fairy.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Floral.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Frost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Ghost.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Glass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Glow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Holly.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Onyx.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Ruby.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Shadow.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Silver.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Spooky.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Sunset.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Vampire.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Watery.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Windup.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Wolf-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Zombie.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Base.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Desert.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Golden.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Red.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Shade.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-White.png');
  width: 81px;
  height: 99px;
}
.Mount_Icon_Yarn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Alligator-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Armadillo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Axolotl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Badger-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Bear-Veteran {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bear-Veteran.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Polar {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Polar.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-White.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-BearCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Beetle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Bunny-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Butterfly-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-Cactus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Cheetah-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Cow-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Cuttlefish-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Deer-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Dolphin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Hydra {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Hydra.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-StarryNight.png');
  width: 81px;
  height: 102px;
}
.Pet-Dragon-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-Dragon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Egg-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Falcon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Ferret-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-White.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-FlyingPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Veteran {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Veteran.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-Fox-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Frog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Gryphon-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-White.png');
  width: 81px;
  height: 99px;
}
.Pet-GuineaPig-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Hedgehog-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Hippogriff-Hopeful {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippogriff-Hopeful.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Horse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-JackOLantern-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-JackOLantern-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-JackOLantern-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-JackOLantern-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Jackalope-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Jackalope-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Kangaroo-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Lion-Veteran {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Lion-Veteran.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-White.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-LionCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-MagicalBee-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-MagicalBee-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Mammoth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Mammoth-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-MantisShrimp-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-MantisShrimp-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Monkey-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Nudibranch-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Octopus-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Orca-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Orca-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Owl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-White.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-PandaCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Parrot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Peacock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Penguin-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Phoenix-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Phoenix-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Pterodactyl-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Rat-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Robot-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Rock-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Rooster-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Sabretooth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-White.png');
  width: 81px;
  height: 99px;
}
.Pet-SeaSerpent-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Seahorse-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Sheep-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Slime-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Sloth-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Snail-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Snake-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Spider-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Squirrel-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-White.png');
  width: 81px;
  height: 99px;
}
.Pet-TRex-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Tiger-Veteran {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Tiger-Veteran.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Koi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Koi.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-White.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-TigerCub-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Treeling-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Triceratops-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Turkey-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turkey-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Turkey-Gilded {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turkey-Gilded.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Turtle-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Unicorn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Velociraptor-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Whale-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Amber.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Aquatic.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Aurora.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-AutumnLeaf.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-BirchBark.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-BlackPearl.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Bronze.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Celestial.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Cupid.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Dessert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Dessert.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Ember.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Fairy.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Floral.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Fluorite.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Frost.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Ghost.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Glass.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Glow.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Holly.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-IcySnow.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Koi {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Koi.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Moonglow.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-MossyStone.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Onyx.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Peppermint.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-PinkMarble.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-PolkaDot.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Porcelain.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Rainbow.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-RoseQuartz.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-RoyalPurple.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Ruby.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-SandSculpture.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Shadow.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Shimmer.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Silver.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-SolarSystem.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Spooky.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-StainedGlass.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-StarryNight.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Sunset.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Sunshine.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-TeaShop.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Thunderstorm.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Turquoise.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Vampire.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Veggie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Veggie.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Veteran {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Veteran.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-VirtualPet {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-VirtualPet.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Watery.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Windup {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Windup.png');
  width: 81px;
  height: 99px;
}
.Pet-Wolf-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Base.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-CottonCandyBlue.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-CottonCandyPink.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Desert.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Golden.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Red.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Shade.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Skeleton.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-White.png');
  width: 81px;
  height: 99px;
}
.Pet-Yarn-Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Zombie.png');
  width: 81px;
  height: 99px;
}
.Pet_HatchingPotion_Amber {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Amber.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Aquatic {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Aquatic.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Aurora {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Aurora.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_AutumnLeaf {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_AutumnLeaf.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Base {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Base.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_BirchBark {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_BirchBark.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_BlackPearl {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_BlackPearl.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Bronze {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Bronze.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Celestial {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Celestial.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_CottonCandyBlue {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_CottonCandyBlue.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_CottonCandyPink {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_CottonCandyPink.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Cupid {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Cupid.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Desert {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Desert.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Ember {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Ember.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Fairy {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Fairy.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Floral {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Floral.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Fluorite {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Fluorite.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Frost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Frost.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Ghost {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Ghost.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Glass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Glass.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Glow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Glow.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Golden {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Golden.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Holly {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Holly.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_IcySnow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_IcySnow.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Moonglow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Moonglow.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_MossyStone {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_MossyStone.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Onyx {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Onyx.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Peppermint {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Peppermint.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_PinkMarble {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_PinkMarble.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_PolkaDot {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_PolkaDot.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Porcelain {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Porcelain.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Purple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Purple.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Rainbow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Rainbow.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Red {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Red.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_RoseQuartz {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_RoseQuartz.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_RoyalPurple {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_RoyalPurple.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Ruby {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Ruby.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_SandSculpture {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_SandSculpture.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Shade {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shade.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Shadow {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shadow.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Shimmer {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shimmer.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Silver {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Silver.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Skeleton {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Skeleton.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_SolarSystem {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_SolarSystem.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Spooky {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Spooky.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_StainedGlass {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_StainedGlass.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_StarryNight {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_StarryNight.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Sunset {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Sunset.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Sunshine {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Sunshine.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_TeaShop {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_TeaShop.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Thunderstorm {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Thunderstorm.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Turquoise {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Turquoise.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Vampire {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Vampire.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Watery {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Watery.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_White {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_White.png');
  width: 68px;
  height: 68px;
}
.Pet_HatchingPotion_Zombie {
  background-image: url('https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Zombie.png');
  width: 68px;
  height: 68px;
}
