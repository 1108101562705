.quest_lostMasterclasser4 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser4.gif") no-repeat;
  width: 219px;
  height: 219px;
}

.quest_windup {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_windup.gif") no-repeat;
  width: 219px;
  height: 219px;
}

.quest_solarSystem {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_solarSystem.gif") no-repeat;
  width: 219px;
  height: 219px;
}

.quest_virtualpet {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_virtualpet.gif") no-repeat;
  width: 219px;
  height: 219px;
}

.Pet_HatchingPotion_Dessert, .Pet_HatchingPotion_Veggie, .Pet_HatchingPotion_Windup, .Pet_HatchingPotion_VirtualPet {
  width: 68px;
  height: 68px;
}

.Pet_HatchingPotion_Dessert {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Dessert.gif") no-repeat;
}

.Pet_HatchingPotion_Veggie {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Veggie.gif") no-repeat;
}

.Pet_HatchingPotion_Windup {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Windup.gif") no-repeat;
}

.Pet_HatchingPotion_VirtualPet {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_VirtualPet.gif") no-repeat;
}

.Gems {
  display:inline-block;
  margin-right:5px;
  border-style:none;
  margin-left:0px;
  margin-top:2px;
}

.inline-gems {
  vertical-align: middle;
  margin-left: 0px;
  display: inline-block;
}

/* Both */
.customize-menu .locked {
  background-color: #727272;
}

/* These sprites are oversized until a move to 105 avatar box happens*/

/* Backer */
.weapon_special_0, .head_special_0 {
  width: 105px;
  height: 105px;
  margin-left: -3px;
  margin-top: -18px;
}
.slim_armor_special_0, .broad_armor_special_0, .shield_special_0 {
  width: 90px;
  height: 90px;
}

/* Critical */
.weapon_special_critical {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_critical.gif") no-repeat;
  width: 90px;
  height: 90px;
  margin-left:-12px;
  margin-top:12px;
}

/* This is oversized until a move to 105 avatar box happens*/
.weapon_special_1 {
  margin-left: -12px;
}
.broad_armor_special_1, .slim_armor_special_1, .head_special_1 {
  width: 90px;
  height: 90px;
}

.head_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-ShadeHelmet.gif") no-repeat;
}
.head_special_1 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/ContributorOnly-Equip-CrystalHelmet.gif") no-repeat;
  margin-top: 3px;
}

.broad_armor_special_0,.slim_armor_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-ShadeArmor.gif") no-repeat;
}
.broad_armor_special_1,.slim_armor_special_1 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/ContributorOnly-Equip-CrystalArmor.gif") no-repeat;
}

.shield_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Shield-TormentedSkull.gif") no-repeat;
}

.weapon_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Weapon-DarkSoulsBlade.gif") no-repeat;
}

.Pet-Wolf-Cerberus {
  width: 105px;
  height: 72px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Pet-CerberusPup.gif") no-repeat;
}

.broad_armor_special_ks2019, .slim_armor_special_ks2019, .eyewear_special_ks2019, .head_special_ks2019, .shield_special_ks2019 {
  width: 117px;
  height: 120px;
}

.broad_armor_special_ks2019, .slim_armor_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonArmor.gif") no-repeat;
}

.eyewear_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonVisor.gif") no-repeat;
}

.head_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonHelm.gif") no-repeat;
}

.shield_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonShield.gif") no-repeat;
}

.weapon_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonGlaive.gif") no-repeat;
  width: 120px;
  height: 120px;
}

.Pet-Gryphon-Gryphatrice {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Pet-Gryphatrice.gif") no-repeat;
  width: 81px;
  height: 99px;
}

.Pet-Gryphatrice-Jubilant {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphatrice-Jubilant.gif") no-repeat;
  width: 81px;
  height: 96px;
}

.Mount_Head_Gryphon-Gryphatrice, .Mount_Body_Gryphon-Gryphatrice {
  width: 135px;
  height: 135px;
}

.Mount_Head_Gryphon-Gryphatrice {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Mount-Head-Gryphatrice.gif") no-repeat;
}

.Mount_Body_Gryphon-Gryphatrice {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Mount-Body-Gryphatrice.gif") no-repeat;
}

.background_airship, .background_clocktower, .background_steamworks {
  width: 141px;
  height: 147px;
}

.background_airship {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_airship.gif") no-repeat;
}

.background_clocktower {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_clocktower.gif") no-repeat;
}

.background_steamworks {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_steamworks.gif") no-repeat;
}

/* FIXME figure out how to handle customize menu!!
.customize-menu .f_head_0 {
  width: 60px;
  height: 60px;
  background-position: -1917px -9px;
}
*/

[class*="Mount_Head_"],
[class*="Mount_Body_"] {
  margin-top:18px; /* Sprite accommodates 105x123 box */
}

.Pet_Currency_Gem {
  margin-top: 5px;
  margin-bottom: 5px
}
