body.taskboard {
  color: #d5c8ff;
  min-height: 100%;
  background: #36205d;
  margin: 10px;
  padding: 0;
}

#root {
  height: 100%;
  overflow: auto;
}

.card-grid-container {
  min-height: 100%;
}

#bank-page a {
  text-decoration: none !important;
}

#bank-page .header {
  display: flex;
}

#bank-page .header .back {
  height: 100px;
  width: 100px;
  text-align: center;
  vertical-align: middle;
  font-size: 70px;
  line-height: 100px;
}

#bank-page {
  width: 100%;
  min-height: 100%;
  grid-template: "header" 100px
                 "details"
                 / auto;
  margin-bottom: 90px;
  display: grid;
}

#bank-page .header {
  grid-area: header;
  align-items: center;
}

#bank-page > .bank-details {
  grid-area: details;
}

.user-card {
  column-gap: 10px;
  display: flex;
}

.quest_lostMasterclasser4 {
  width: 219px;
  height: 219px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser4.gif") no-repeat;
}

.quest_windup {
  width: 219px;
  height: 219px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_windup.gif") no-repeat;
}

.quest_solarSystem {
  width: 219px;
  height: 219px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_solarSystem.gif") no-repeat;
}

.quest_virtualpet {
  width: 219px;
  height: 219px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_virtualpet.gif") no-repeat;
}

.Pet_HatchingPotion_Dessert, .Pet_HatchingPotion_Veggie, .Pet_HatchingPotion_Windup, .Pet_HatchingPotion_VirtualPet {
  width: 68px;
  height: 68px;
}

.Pet_HatchingPotion_Dessert {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Dessert.gif") no-repeat;
}

.Pet_HatchingPotion_Veggie {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Veggie.gif") no-repeat;
}

.Pet_HatchingPotion_Windup {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Windup.gif") no-repeat;
}

.Pet_HatchingPotion_VirtualPet {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_VirtualPet.gif") no-repeat;
}

.Gems {
  border-style: none;
  margin-top: 2px;
  margin-left: 0;
  margin-right: 5px;
  display: inline-block;
}

.inline-gems {
  vertical-align: middle;
  margin-left: 0;
  display: inline-block;
}

.customize-menu .locked {
  background-color: #727272;
}

.weapon_special_0, .head_special_0 {
  width: 105px;
  height: 105px;
  margin-top: -18px;
  margin-left: -3px;
}

.slim_armor_special_0, .broad_armor_special_0, .shield_special_0 {
  width: 90px;
  height: 90px;
}

.weapon_special_critical {
  width: 90px;
  height: 90px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_critical.gif") no-repeat;
  margin-top: 12px;
  margin-left: -12px;
}

.weapon_special_1 {
  margin-left: -12px;
}

.broad_armor_special_1, .slim_armor_special_1, .head_special_1 {
  width: 90px;
  height: 90px;
}

.head_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-ShadeHelmet.gif") no-repeat;
}

.head_special_1 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/ContributorOnly-Equip-CrystalHelmet.gif") no-repeat;
  margin-top: 3px;
}

.broad_armor_special_0, .slim_armor_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-ShadeArmor.gif") no-repeat;
}

.broad_armor_special_1, .slim_armor_special_1 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/ContributorOnly-Equip-CrystalArmor.gif") no-repeat;
}

.shield_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Shield-TormentedSkull.gif") no-repeat;
}

.weapon_special_0 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Weapon-DarkSoulsBlade.gif") no-repeat;
}

.Pet-Wolf-Cerberus {
  width: 105px;
  height: 72px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Pet-CerberusPup.gif") no-repeat;
}

.broad_armor_special_ks2019, .slim_armor_special_ks2019, .eyewear_special_ks2019, .head_special_ks2019, .shield_special_ks2019 {
  width: 117px;
  height: 120px;
}

.broad_armor_special_ks2019, .slim_armor_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonArmor.gif") no-repeat;
}

.eyewear_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonVisor.gif") no-repeat;
}

.head_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonHelm.gif") no-repeat;
}

.shield_special_ks2019 {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonShield.gif") no-repeat;
}

.weapon_special_ks2019 {
  width: 120px;
  height: 120px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Equip-MythicGryphonGlaive.gif") no-repeat;
}

.Pet-Gryphon-Gryphatrice {
  width: 81px;
  height: 99px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Pet-Gryphatrice.gif") no-repeat;
}

.Pet-Gryphatrice-Jubilant {
  width: 81px;
  height: 96px;
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphatrice-Jubilant.gif") no-repeat;
}

.Mount_Head_Gryphon-Gryphatrice, .Mount_Body_Gryphon-Gryphatrice {
  width: 135px;
  height: 135px;
}

.Mount_Head_Gryphon-Gryphatrice {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Mount-Head-Gryphatrice.gif") no-repeat;
}

.Mount_Body_Gryphon-Gryphatrice {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/BackerOnly-Mount-Body-Gryphatrice.gif") no-repeat;
}

.background_airship, .background_clocktower, .background_steamworks {
  width: 141px;
  height: 147px;
}

.background_airship {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_airship.gif") no-repeat;
}

.background_clocktower {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_clocktower.gif") no-repeat;
}

.background_steamworks {
  background: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_steamworks.gif") no-repeat;
}

[class*="Mount_Head_"], [class*="Mount_Body_"] {
  margin-top: 18px;
}

.Pet_Currency_Gem {
  margin-top: 5px;
  margin-bottom: 5px;
}

.achievement-alien {
  width: 24px;
  height: 26px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-alien.png");
}

.achievement-alien2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-alien2x.png");
}

.achievement-allThatGlitters2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-allThatGlitters2x.png");
}

.achievement-allYourBase2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-allYourBase2x.png");
}

.achievement-alpha2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-alpha2x.png");
}

.achievement-aridAuthority2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-aridAuthority2x.png");
}

.achievement-armor2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-armor2x.png");
}

.achievement-backToBasics2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-backToBasics2x.png");
}

.achievement-bareNecessities2x {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bareNecessities2x.png");
}

.achievement-bewilder2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bewilder2x.png");
}

.achievement-birdsOfAFeather2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-birdsOfAFeather2x.png");
}

.achievement-birthday2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-birthday2x.png");
}

.achievement-boneCollector2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-boneCollector2x.png");
}

.achievement-boneToPick2x {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-boneToPick2x.png");
}

.achievement-boot2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-boot2x.png");
}

.achievement-bow2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bow2x.png");
}

.achievement-bugBonanza2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-bugBonanza2x.png");
}

.achievement-burnout2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-burnout2x.png");
}

.achievement-cactus2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-cactus2x.png");
}

.achievement-cake2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-cake2x.png");
}

.achievement-cave2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-cave2x.png");
}

.achievement-challenge2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-challenge2x.png");
}

.achievement-comment2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-comment2x.png");
}

.achievement-completedTask2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-completedTask2x.png");
}

.achievement-congrats2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-congrats2x.png");
}

.achievement-costumeContest2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-costumeContest2x.png");
}

.achievement-createdTask2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-createdTask2x.png");
}

.achievement-dilatory2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dilatory2x.png");
}

.achievement-dinosaurDynasty2x {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dinosaurDynasty2x.png");
}

.achievement-domesticated2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-domesticated2x.png");
}

.achievement-dustDevil2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dustDevil2x.png");
}

.achievement-dysheartener2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-dysheartener2x.png");
}

.achievement-fedPet2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-fedPet2x.png");
}

.achievement-freshwaterFriends2x {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-freshwaterFriends2x.png");
}

.achievement-friends2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-friends2x.png");
}

.achievement-getwell2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-getwell2x.png");
}

.achievement-goodAsGold2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-goodAsGold2x.png");
}

.achievement-goodluck2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-goodluck2x.png");
}

.achievement-greeting2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-greeting2x.png");
}

.achievement-groupsBeta20222x {
  width: 67px;
  height: 67px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-groupsBeta20222x.png");
}

.achievement-guild2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-guild2x.png");
}

.achievement-habitBirthday2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-habitBirthday2x.png");
}

.achievement-habiticaDay2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-habiticaDay2x.png");
}

.achievement-hatchedPet2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-hatchedPet2x.png");
}

.achievement-heart2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-heart2x.png");
}

.achievement-justAddWater2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-justAddWater2x.png");
}

.achievement-karaoke-2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-karaoke-2x.png");
}

.achievement-karaoke {
  width: 24px;
  height: 26px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-karaoke.png");
}

.achievement-kickstarter20192x {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-kickstarter20192x.png");
}

.achievement-legendaryBestiary2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-legendaryBestiary2x.png");
}

.achievement-lostMasterclasser2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-lostMasterclasser2x.png");
}

.achievement-mindOverMatter2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-mindOverMatter2x.png");
}

.achievement-monsterMagus2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-monsterMagus2x.png");
}

.achievement-ninja2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ninja2x.png");
}

.achievement-npc2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-npc2x.png");
}

.achievement-nye2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-nye2x.png");
}

.achievement-partyOn2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-partyOn2x.png");
}

.achievement-partyUp2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-partyUp2x.png");
}

.achievement-pearlyPro2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-pearlyPro2x.png");
}

.achievement-perfect2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-perfect2x.png");
}

.achievement-plantParent2x {
  width: 64px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-plantParent2x.png");
}

.achievement-polarPro2x {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-polarPro2x.png");
}

.achievement-primedForPainting2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-primedForPainting2x.png");
}

.achievement-purchasedEquipment2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-purchasedEquipment2x.png");
}

.achievement-rat2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-rat2x.png");
}

.achievement-redLetterDay2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-redLetterDay2x.png");
}

.achievement-reptacularRumble2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-reptacularRumble2x.png");
}

.achievement-rosyOutlook2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-rosyOutlook2x.png");
}

.achievement-royally-loyal2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-royally-loyal2x.png");
}

.achievement-seafoam2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-seafoam2x.png");
}

.achievement-seasonalSpecialist2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-seasonalSpecialist2x.png");
}

.achievement-seeingRed2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-seeingRed2x.png");
}

.achievement-shadeOfItAll2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shadeOfItAll2x.png");
}

.achievement-shadyCustomer2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shadyCustomer2x.png");
}

.achievement-shield2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shield2x.png");
}

.achievement-shinySeed2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-shinySeed2x.png");
}

.achievement-skeletonCrew2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-skeletonCrew2x.png");
}

.achievement-snowball2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-snowball2x.png");
}

.achievement-spookySparkles2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-spookySparkles2x.png");
}

.achievement-stoikalm2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-stoikalm2x.png");
}

.achievement-sun2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-sun2x.png");
}

.achievement-sword2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-sword2x.png");
}

.achievement-thankyou2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-thankyou2x.png");
}

.achievement-thermometer2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-thermometer2x.png");
}

.achievement-tickledPink2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-tickledPink2x.png");
}

.achievement-tree2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-tree2x.png");
}

.achievement-triadbingo2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-triadbingo2x.png");
}

.achievement-ultimate-healer2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-healer2x.png");
}

.achievement-ultimate-mage2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-mage2x.png");
}

.achievement-ultimate-rogue2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-rogue2x.png");
}

.achievement-ultimate-warrior2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-ultimate-warrior2x.png");
}

.achievement-undeadUndertaker2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-undeadUndertaker2x.png");
}

.achievement-unearned2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-unearned2x.png");
}

.achievement-valentine2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-valentine2x.png");
}

.achievement-violetsAreBlue2x {
  width: 48px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-violetsAreBlue2x.png");
}

.achievement-wildBlueYonder2x {
  width: 64px;
  height: 56px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-wildBlueYonder2x.png");
}

.achievement-wolf2x {
  width: 48px;
  height: 52px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-wolf2x.png");
}

.achievement-woodlandWizard2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-woodlandWizard2x.png");
}

.achievement-zodiac2x {
  width: 60px;
  height: 64px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/achievement-zodiac2x.png");
}

.background_afternoon_picnic {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_afternoon_picnic.png");
}

.background_alpine_slopes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_alpine_slopes.png");
}

.background_amid_ancient_ruins {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_amid_ancient_ruins.png");
}

.background_among_cattails {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_cattails.png");
}

.background_among_giant_anemones {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_anemones.png");
}

.background_among_giant_flowers {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_flowers.png");
}

.customize-option.background_among_giant_flowers {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_flowers.png");
  background-position: -25px -15px;
}

.background_among_giant_mushrooms {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_among_giant_mushrooms.png");
}

.background_animal_clouds {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_animal_clouds.png");
}

.background_animals_den {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_animals_den.png");
}

.background_apple_picking {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_apple_picking.png");
}

.background_aquarium {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_aquarium.png");
}

.background_archaeological_dig {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_archaeological_dig.png");
}

.background_archery_range {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_archery_range.png");
}

.background_at_the_docks {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_at_the_docks.png");
}

.background_aurora {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_aurora.png");
}

.background_autumn_bridge {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_bridge.png");
}

.background_autumn_flower_garden {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_flower_garden.png");
}

.customize-option.background_autumn_flower_garden {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_flower_garden.png");
  background-position: -25px -15px;
}

.background_autumn_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_forest.png");
}

.background_autumn_lakeshore {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_lakeshore.png");
}

.background_autumn_picnic {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_picnic.png");
}

.background_autumn_poplars {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_autumn_poplars.png");
}

.background_avalanche {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_avalanche.png");
}

.background_back_alley {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_back_alley.png");
}

.background_back_of_giant_beast {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_back_of_giant_beast.png");
}

.background_bamboo_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bamboo_forest.png");
}

.background_bayou {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bayou.png");
}

.background_beach {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beach.png");
}

.background_beach_cabana {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beach_cabana.png");
}

.background_beach_with_dunes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beach_with_dunes.png");
}

.background_beehive {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beehive.png");
}

.background_bell_tower {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bell_tower.png");
}

.background_beside_well {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_beside_well.png");
}

.background_bioluminescent_waves {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bioluminescent_waves.png");
}

.background_birch_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_birch_forest.png");
}

.background_birthday_bash {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_birthday_bash.png");
}

.background_birthday_party {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_birthday_party.png");
}

.background_blacksmithy {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blacksmithy.png");
}

.background_blizzard {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blizzard.png");
}

.background_blossoming_desert {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blossoming_desert.png");
}

.background_blossoming_trees {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blossoming_trees.png");
}

.background_blue {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_blue.png");
}

.background_branches_of_a_holiday_tree {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_branches_of_a_holiday_tree.png");
}

.background_brick_wall_with_ivy {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_brick_wall_with_ivy.png");
}

.background_bridge {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bridge.png");
}

.background_bug_covered_log {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_bug_covered_log.png");
}

.background_buried_treasure {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_buried_treasure.png");
}

.background_butterfly_garden {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_butterfly_garden.png");
}

.background_by_a_campfire {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_by_a_campfire.png");
}

.background_camping_out {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_camping_out.png");
}

.background_castle_gate {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_castle_gate.png");
}

.background_cemetery_gate {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cemetery_gate.png");
}

.background_champions_colosseum {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_champions_colosseum.png");
}

.background_cherry_trees {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cherry_trees.png");
}

.background_chessboard_land {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_chessboard_land.png");
}

.background_clothesline {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_clothesline.png");
}

.background_clouds {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_clouds.png");
}

.background_coral_reef {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_coral_reef.png");
}

.background_cornfields {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cornfields.png");
}

.background_cottage_construction {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cottage_construction.png");
}

.background_cozy_barn {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cozy_barn.png");
}

.background_cozy_bedroom {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cozy_bedroom.png");
}

.background_cozy_library {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cozy_library.png");
}

.background_creepy_castle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_creepy_castle.png");
}

.background_crescent_moon {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_crescent_moon.png");
}

.background_cretaceous_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cretaceous_forest.png");
}

.background_crosscountry_ski_trail {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_crosscountry_ski_trail.png");
}

.background_cryptic_candles {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_cryptic_candles.png");
}

.background_crystal_cave {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_crystal_cave.png");
}

.background_dark_deep {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dark_deep.png");
}

.background_daytime_misty_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_daytime_misty_forest.png");
}

.background_deep_mine {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_deep_mine.png");
}

.background_deep_sea {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_deep_sea.png");
}

.background_desert_dunes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_desert_dunes.png");
}

.background_desert_with_snow {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_desert_with_snow.png");
}

.background_dilatory_castle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dilatory_castle.png");
}

.background_dilatory_city {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dilatory_city.png");
}

.background_dilatory_ruins {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dilatory_ruins.png");
}

.background_distant_castle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_distant_castle.png");
}

.background_dojo {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dojo.png");
}

.background_dragons_lair {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dragons_lair.png");
}

.background_drifting_raft {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_drifting_raft.png");
}

.background_driving_a_coach {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_driving_a_coach.png");
}

.background_driving_a_sleigh {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_driving_a_sleigh.png");
}

.background_duck_pond {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_duck_pond.png");
}

.background_dungeon {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dungeon.png");
}

.background_dusty_canyons {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_dusty_canyons.png");
}

.background_elegant_balcony {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_elegant_balcony.png");
}

.background_elegant_ballroom {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_elegant_ballroom.png");
}

.background_elegant_garden {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_elegant_garden.png");
}

.background_enchanted_music_room {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_enchanted_music_room.png");
}

.background_fairy_ring {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fairy_ring.png");
}

.background_fancy_bedroom {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fancy_bedroom.png");
}

.background_fantastical_shoe_store {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fantastical_shoe_store.png");
}

.background_farmers_market {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_farmers_market.png");
}

.background_farmhouse {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_farmhouse.png");
}

.background_fiber_arts_room {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fiber_arts_room.png");
}

.background_field_with_colored_eggs {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_field_with_colored_eggs.png");
}

.background_floating_islands {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_floating_islands.png");
}

.background_floral_meadow {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_floral_meadow.png");
}

.background_flower_market {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_market.png");
}

.customize-option.background_flower_market {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_market.png");
  background-position: -25px -15px;
}

.background_flower_shop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_shop.png");
}

.customize-option.background_flower_shop {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flower_shop.png");
  background-position: -25px -15px;
}

.background_flowering_prairie {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flowering_prairie.png");
}

.customize-option.background_flowering_prairie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flowering_prairie.png");
  background-position: -25px -15px;
}

.background_flying_in_a_thunderstorm {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_in_a_thunderstorm.png");
}

.background_flying_over_a_field_of_wildflowers {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_a_field_of_wildflowers.png");
}

.customize-option.background_flying_over_a_field_of_wildflowers {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_a_field_of_wildflowers.png");
  background-position: -25px -15px;
}

.background_flying_over_an_ancient_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_an_ancient_forest.png");
}

.background_flying_over_an_autumn_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_an_autumn_forest.png");
}

.background_flying_over_glacier {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_glacier.png");
}

.background_flying_over_hedge_maze {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_hedge_maze.png");
}

.background_flying_over_icy_steppes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_icy_steppes.png");
}

.background_flying_over_rocky_canyon {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_rocky_canyon.png");
}

.background_flying_over_snowy_mountains {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_snowy_mountains.png");
}

.background_flying_over_tropical_islands {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_flying_over_tropical_islands.png");
}

.background_foggy_moor {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_foggy_moor.png");
}

.background_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_forest.png");
}

.background_forested_lakeshore {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_forested_lakeshore.png");
}

.background_fortune_tellers_shop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_fortune_tellers_shop.png");
}

.background_frigid_peak {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frigid_peak.png");
}

.background_frosty_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frosty_forest.png");
}

.background_frozen_lake {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frozen_lake.png");
}

.background_frozen_polar_waters {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_frozen_polar_waters.png");
}

.background_garden_shed {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_garden_shed.png");
}

.background_gazebo {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gazebo.png");
}

.background_ghost_ship {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ghost_ship.png");
}

.background_giant_autumn_leaf {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_autumn_leaf.png");
}

.background_giant_birdhouse {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_birdhouse.png");
}

.background_giant_book {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_book.png");
}

.background_giant_dandelions {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_dandelions.png");
}

.background_giant_florals {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_florals.png");
}

.background_giant_seashell {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_seashell.png");
}

.background_giant_wave {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_giant_wave.png");
}

.background_gingerbread_house {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gingerbread_house.png");
}

.background_glowing_mushroom_cave {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_glowing_mushroom_cave.png");
}

.background_golden_birdcage {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_golden_birdcage.png");
}

.background_gorgeous_greenhouse {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gorgeous_greenhouse.png");
}

.background_grand_staircase {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_grand_staircase.png");
}

.background_graveyard {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_graveyard.png");
}

.background_green {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_green.png");
}

.background_guardian_statues {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_guardian_statues.png");
}

.background_gumdrop_land {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_gumdrop_land.png");
}

.background_habit_city_rooftops {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_habit_city_rooftops.png");
}

.background_habit_city_streets {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_habit_city_streets.png");
}

.background_halflings_house {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_halflings_house.png");
}

.background_hall_of_heroes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_hall_of_heroes.png");
}

.background_harvest_feast {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_harvest_feast.png");
}

.background_harvest_fields {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_harvest_fields.png");
}

.background_harvest_moon {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_harvest_moon.png");
}

.background_haunted_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_haunted_forest.png");
}

.background_haunted_house {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_haunted_house.png");
}

.background_haunted_photo {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_haunted_photo.png");
}

.background_heart_shaped_bubbles {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_heart_shaped_bubbles.png");
}

.background_heather_field {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_heather_field.png");
}

.background_herding_sheep_in_autumn {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_herding_sheep_in_autumn.png");
}

.background_holiday_hearth {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_holiday_hearth.png");
}

.background_holiday_market {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_holiday_market.png");
}

.background_holiday_wreath {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_holiday_wreath.png");
}

.background_hot_air_balloon {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_hot_air_balloon.png");
}

.background_hot_spring {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_hot_spring.png");
}

.background_ice_cave {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ice_cave.png");
}

.background_ice_palace {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ice_palace.png");
}

.background_iceberg {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_iceberg.png");
}

.background_icicle_bridge {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_icicle_bridge.png");
}

.background_idyllic_cabin {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_idyllic_cabin.png");
}

.background_in_a_classroom {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_a_classroom.png");
}

.background_in_a_painting {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_a_painting.png");
}

.background_in_an_ancient_tomb {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_an_ancient_tomb.png");
}

.background_in_front_of_fountain {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_front_of_fountain.png");
}

.background_in_the_armory {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_in_the_armory.png");
}

.background_inside_a_crystal {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_inside_a_crystal.png");
}

.background_inside_a_potion_bottle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_inside_a_potion_bottle.png");
}

.background_inside_an_ornament {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_inside_an_ornament.png");
}

.background_iridescent_clouds {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_iridescent_clouds.png");
}

.background_island_waterfalls {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_island_waterfalls.png");
}

.background_jungle_canopy {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_jungle_canopy.png");
}

.background_jungle_watering_hole {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_jungle_watering_hole.png");
}

.background_kelp_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_kelp_forest.png");
}

.background_lake_with_floating_lanterns {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_lake_with_floating_lanterns.png");
}

.background_leafy_tree_tunnel {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_leafy_tree_tunnel.png");
}

.background_lighthouse_shore {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_lighthouse_shore.png");
}

.background_lilypad {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_lilypad.png");
}

.background_magic_beanstalk {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_magic_beanstalk.png");
}

.background_magical_candles {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_magical_candles.png");
}

.background_magical_museum {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_magical_museum.png");
}

.background_mangrove_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mangrove_forest.png");
}

.background_marble_temple {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_marble_temple.png");
}

.background_market {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_market.png");
}

.background_mask_makers_workshop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mask_makers_workshop.png");
}

.background_meandering_cave {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_meandering_cave.png");
}

.background_medieval_kitchen {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_medieval_kitchen.png");
}

.background_messy_room {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_messy_room.png");
}

.background_meteor_shower {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_meteor_shower.png");
}

.background_midnight_castle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_midnight_castle.png");
}

.background_midnight_clouds {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_midnight_clouds.png");
}

.background_midnight_lake {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_midnight_lake.png");
}

.background_mist_shrouded_mountain {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mist_shrouded_mountain.png");
}

.background_mistiflying_circus {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mistiflying_circus.png");
}

.background_misty_autumn_forest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_misty_autumn_forest.png");
}

.background_monster_makers_workshop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_monster_makers_workshop.png");
}

.background_mountain_lake {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mountain_lake.png");
}

.background_mountain_pyramid {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mountain_pyramid.png");
}

.background_mountain_waterfall {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mountain_waterfall.png");
}

.background_mystical_observatory {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_mystical_observatory.png");
}

.background_night_dunes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_night_dunes.png");
}

.background_ocean_sunrise {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_ocean_sunrise.png");
}

.background_old_fashioned_bakery {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_old_fashioned_bakery.png");
}

.background_old_photo {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_old_photo.png");
}

.background_old_timey_basketball_court {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_old_timey_basketball_court.png");
}

.background_on_a_castle_wall {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_on_a_castle_wall.png");
}

.background_on_tree_branch {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_on_tree_branch.png");
}

.background_open_waters {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_open_waters.png");
}

.background_orange_grove {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_orange_grove.png");
}

.background_orchard {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_orchard.png");
}

.background_pagodas {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pagodas.png");
}

.background_palm_tree_with_fairy_lights {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_palm_tree_with_fairy_lights.png");
}

.background_park_with_statue {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_park_with_statue.png");
}

.background_pirate_flag {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pirate_flag.png");
}

.background_pixelists_workshop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pixelists_workshop.png");
}

.background_potion_shop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_potion_shop.png");
}

.background_productivity_plaza {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_productivity_plaza.png");
}

.background_pumpkin_carriage {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pumpkin_carriage.png");
}

.background_pumpkin_patch {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pumpkin_patch.png");
}

.background_purple {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_purple.png");
}

.background_pyramids {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_pyramids.png");
}

.background_raging_river {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_raging_river.png");
}

.background_rainbow_eucalyptus {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainbow_eucalyptus.png");
}

.background_rainbow_meadow {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainbow_meadow.png");
}

.background_rainbows_end {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainbows_end.png");
}

.background_rainforest {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainforest.png");
}

.background_rainy_barnyard {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainy_barnyard.png");
}

.background_rainy_city {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rainy_city.png");
}

.background_red {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_red.png");
}

.background_relaxation_river {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_relaxation_river.png");
}

.background_resting_in_the_inn {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_resting_in_the_inn.png");
}

.background_rime_ice {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rime_ice.png");
}

.background_river_of_lava {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_river_of_lava.png");
}

.background_rolling_hills {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rolling_hills.png");
}

.background_rope_bridge {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rope_bridge.png");
}

.background_rose_garden {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rose_garden.png");
}

.background_rowboat {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_rowboat.png");
}

.background_sailboat_at_sunset {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sailboat_at_sunset.png");
}

.background_salt_lake {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_salt_lake.png");
}

.background_sandcastle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sandcastle.png");
}

.background_school_of_fish {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_school_of_fish.png");
}

.background_scribes_workshop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_scribes_workshop.png");
}

.background_seafarer_ship {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_seafarer_ship.png");
}

.background_seaside_cliffs {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_seaside_cliffs.png");
}

.background_shimmering_ice_prism {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_shimmering_ice_prism.png");
}

.background_shimmery_bubbles {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_shimmery_bubbles.png");
}

.background_slimy_swamp {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_slimy_swamp.png");
}

.background_snowglobe {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowglobe.png");
}

.background_snowman_army {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowman_army.png");
}

.background_snowy_day_fireplace {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_day_fireplace.png");
}

.background_snowy_farm {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_farm.png");
}

.background_snowy_pines {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_pines.png");
}

.background_snowy_sunrise {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_sunrise.png");
}

.background_snowy_temple {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_temple.png");
}

.background_snowy_village {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_snowy_village.png");
}

.background_south_pole {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_south_pole.png");
}

.background_sparkling_snowflake {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sparkling_snowflake.png");
}

.background_spider_web {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spider_web.png");
}

.background_spiral_staircase {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spiral_staircase.png");
}

.background_splash_in_a_puddle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_splash_in_a_puddle.png");
}

.background_spooky_hotel {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spooky_hotel.png");
}

.background_spooky_ruins {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spooky_ruins.png");
}

.background_spooky_scarecrow_field {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spooky_scarecrow_field.png");
}

.background_spring_rain {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spring_rain.png");
}

.background_spring_thaw {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_spring_thaw.png");
}

.background_springtime_lake {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_springtime_lake.png");
}

.background_springtime_shower {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_springtime_shower.png");
}

.background_stable {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stable.png");
}

.background_stained_glass {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stained_glass.png");
}

.background_starry_skies {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_starry_skies.png");
}

.background_starry_winter_night {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_starry_winter_night.png");
}

.background_stoikalm_volcanoes {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stoikalm_volcanoes.png");
}

.background_stone_circle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stone_circle.png");
}

.background_stone_tower {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stone_tower.png");
}

.background_stormy_rooftops {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stormy_rooftops.png");
}

.background_stormy_ship {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_stormy_ship.png");
}

.background_strange_sewers {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_strange_sewers.png");
}

.background_strawberry_patch {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_strawberry_patch.png");
}

.background_succulent_garden {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_succulent_garden.png");
}

.background_summer_fireworks {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_summer_fireworks.png");
}

.background_sunken_ship {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunken_ship.png");
}

.background_sunset_meadow {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunset_meadow.png");
}

.background_sunset_oasis {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunset_oasis.png");
}

.background_sunset_savannah {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_sunset_savannah.png");
}

.background_swarming_darkness {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_swarming_darkness.png");
}

.background_swimming_among_jellyfish {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_swimming_among_jellyfish.png");
}

.background_tar_pits {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tar_pits.png");
}

.background_tavern {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tavern.png");
}

.background_tea_party {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tea_party.png");
}

.background_terraced_rice_field {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_terraced_rice_field.png");
}

.background_theatre_stage {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_theatre_stage.png");
}

.background_throne_room {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_throne_room.png");
}

.background_thunderstorm {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_thunderstorm.png");
}

.background_tide_pool {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tide_pool.png");
}

.background_tornado {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tornado.png");
}

.background_toymakers_workshop {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_toymakers_workshop.png");
}

.background_training_grounds {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_training_grounds.png");
}

.background_treasure_room {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_treasure_room.png");
}

.background_tree_roots {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tree_roots.png");
}

.background_treehouse {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_treehouse.png");
}

.background_tulip_garden {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_tulip_garden.png");
}

.background_twinkly_lights {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_twinkly_lights.png");
}

.background_twinkly_party_lights {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_twinkly_party_lights.png");
}

.background_undead_hands {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_undead_hands.png");
}

.background_under_wisteria {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_under_wisteria.png");
}

.background_underwater_among_koi {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_among_koi.png");
}

.background_underwater_cave {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_cave.png");
}

.background_underwater_ruins {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_ruins.png");
}

.background_underwater_statues {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_statues.png");
}

.background_underwater_vents {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_underwater_vents.png");
}

.background_valentines_day_feasting_hall {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_valentines_day_feasting_hall.png");
}

.background_viking_ship {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_viking_ship.png");
}

.background_vineyard {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_vineyard.png");
}

.background_violet {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_violet.png");
}

.background_volcano {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_volcano.png");
}

.background_water_mill {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_water_mill.png");
}

.background_waterfall_rock {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_waterfall_rock.png");
}

.background_wedding_arch {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_wedding_arch.png");
}

.background_windmills {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_windmills.png");
}

.background_windy_autumn {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_windy_autumn.png");
}

.background_winter_canyon {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_canyon.png");
}

.background_winter_fireworks {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_fireworks.png");
}

.background_winter_lake_with_swans {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_lake_with_swans.png");
}

.background_winter_night {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_night.png");
}

.background_winter_nocturne {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_nocturne.png");
}

.background_winter_storefront {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_storefront.png");
}

.background_winter_town {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_town.png");
}

.background_winter_waterfall {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_winter_waterfall.png");
}

.background_wintry_castle {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_wintry_castle.png");
}

.background_yellow {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/background_yellow.png");
}

.icon_background_afternoon_picnic {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_afternoon_picnic.png");
}

.icon_background_airship {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_airship.png");
}

.icon_background_alpine_slopes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_alpine_slopes.png");
}

.icon_background_amid_ancient_ruins {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_amid_ancient_ruins.png");
}

.icon_background_among_cattails {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_cattails.png");
}

.icon_background_among_giant_anemones {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_anemones.png");
}

.icon_background_among_giant_flowers {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_flowers.png");
}

.customize-option.icon_background_among_giant_flowers {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_flowers.png");
  background-position: -25px -15px;
}

.icon_background_among_giant_mushrooms {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_among_giant_mushrooms.png");
}

.icon_background_animal_clouds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_animal_clouds.png");
}

.icon_background_animals_den {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_animals_den.png");
}

.icon_background_apple_picking {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_apple_picking.png");
}

.icon_background_aquarium {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_aquarium.png");
}

.icon_background_archaeological_dig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_archaeological_dig.png");
}

.icon_background_archery_range {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_archery_range.png");
}

.icon_background_at_the_docks {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_at_the_docks.png");
}

.icon_background_aurora {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_aurora.png");
}

.icon_background_autumn_bridge {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_bridge.png");
}

.icon_background_autumn_flower_garden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_flower_garden.png");
}

.customize-option.icon_background_autumn_flower_garden {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_flower_garden.png");
  background-position: -25px -15px;
}

.icon_background_autumn_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_forest.png");
}

.icon_background_autumn_lakeshore {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_lakeshore.png");
}

.icon_background_autumn_picnic {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_picnic.png");
}

.icon_background_autumn_poplars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_autumn_poplars.png");
}

.icon_background_avalanche {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_avalanche.png");
}

.icon_background_back_alley {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_back_alley.png");
}

.icon_background_back_of_giant_beast {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_back_of_giant_beast.png");
}

.icon_background_bamboo_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bamboo_forest.png");
}

.icon_background_bayou {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bayou.png");
}

.icon_background_beach {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beach.png");
}

.icon_background_beach_cabana {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beach_cabana.png");
}

.icon_background_beach_with_dunes {
  width: 67px;
  height: 69px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beach_with_dunes.png");
}

.icon_background_beehive {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beehive.png");
}

.icon_background_bell_tower {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bell_tower.png");
}

.icon_background_beside_well {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_beside_well.png");
}

.icon_background_bioluminescent_waves {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bioluminescent_waves.png");
}

.icon_background_birch_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_birch_forest.png");
}

.icon_background_birthday_bash {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_birthday_bash.png");
}

.icon_background_birthday_party {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_birthday_party.png");
}

.icon_background_blacksmithy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blacksmithy.png");
}

.icon_background_blizzard {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blizzard.png");
}

.icon_background_blossoming_desert {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blossoming_desert.png");
}

.icon_background_blossoming_trees {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blossoming_trees.png");
}

.icon_background_blue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_blue.png");
}

.icon_background_branches_of_a_holiday_tree {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_branches_of_a_holiday_tree.png");
}

.icon_background_brick_wall_with_ivy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_brick_wall_with_ivy.png");
}

.icon_background_bridge {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bridge.png");
}

.icon_background_bug_covered_log {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_bug_covered_log.png");
}

.icon_background_buried_treasure {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_buried_treasure.png");
}

.icon_background_butterfly_garden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_butterfly_garden.png");
}

.icon_background_by_a_campfire {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_by_a_campfire.png");
}

.icon_background_camping_out {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_camping_out.png");
}

.icon_background_castle_gate {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_castle_gate.png");
}

.icon_background_cemetery_gate {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cemetery_gate.png");
}

.icon_background_champions_colosseum {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_champions_colosseum.png");
}

.icon_background_cherry_trees {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cherry_trees.png");
}

.icon_background_chessboard_land {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_chessboard_land.png");
}

.icon_background_clocktower {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_clocktower.png");
}

.icon_background_clothesline {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_clothesline.png");
}

.icon_background_clouds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_clouds.png");
}

.icon_background_coral_reef {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_coral_reef.png");
}

.icon_background_cornfields {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cornfields.png");
}

.icon_background_cottage_construction {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cottage_construction.png");
}

.icon_background_cozy_barn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cozy_barn.png");
}

.icon_background_cozy_bedroom {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cozy_bedroom.png");
}

.icon_background_cozy_library {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cozy_library.png");
}

.icon_background_creepy_castle {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_creepy_castle.png");
}

.icon_background_crescent_moon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_crescent_moon.png");
}

.icon_background_cretaceous_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cretaceous_forest.png");
}

.icon_background_crosscountry_ski_trail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_crosscountry_ski_trail.png");
}

.icon_background_cryptic_candles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_cryptic_candles.png");
}

.icon_background_crystal_cave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_crystal_cave.png");
}

.icon_background_dark_deep {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dark_deep.png");
}

.icon_background_daytime_misty_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_daytime_misty_forest.png");
}

.icon_background_deep_mine {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_deep_mine.png");
}

.icon_background_deep_sea {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_deep_sea.png");
}

.icon_background_desert_dunes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_desert_dunes.png");
}

.icon_background_desert_with_snow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_desert_with_snow.png");
}

.icon_background_dilatory_castle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dilatory_castle.png");
}

.icon_background_dilatory_city {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dilatory_city.png");
}

.icon_background_dilatory_ruins {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dilatory_ruins.png");
}

.icon_background_distant_castle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_distant_castle.png");
}

.icon_background_dojo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dojo.png");
}

.icon_background_dragons_lair {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dragons_lair.png");
}

.icon_background_drifting_raft {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_drifting_raft.png");
}

.icon_background_driving_a_coach {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_driving_a_coach.png");
}

.icon_background_driving_a_sleigh {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_driving_a_sleigh.png");
}

.icon_background_duck_pond {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_duck_pond.png");
}

.icon_background_dungeon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dungeon.png");
}

.icon_background_dusty_canyons {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_dusty_canyons.png");
}

.icon_background_elegant_balcony {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_elegant_balcony.png");
}

.icon_background_elegant_ballroom {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_elegant_ballroom.png");
}

.icon_background_elegant_garden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_elegant_garden.png");
}

.icon_background_enchanted_music_room {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_enchanted_music_room.png");
}

.icon_background_fairy_ring {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fairy_ring.png");
}

.icon_background_fancy_bedroom {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fancy_bedroom.png");
}

.icon_background_fantastical_shoe_store {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fantastical_shoe_store.png");
}

.icon_background_farmers_market {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_farmers_market.png");
}

.icon_background_farmhouse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_farmhouse.png");
}

.icon_background_fiber_arts_room {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fiber_arts_room.png");
}

.icon_background_field_with_colored_eggs {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_field_with_colored_eggs.png");
}

.icon_background_floating_islands {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_floating_islands.png");
}

.icon_background_floral_meadow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_floral_meadow.png");
}

.icon_background_flower_market {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_market.png");
}

.customize-option.icon_background_flower_market {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_market.png");
  background-position: -25px -15px;
}

.icon_background_flower_shop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_shop.png");
}

.customize-option.icon_background_flower_shop {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flower_shop.png");
  background-position: -25px -15px;
}

.icon_background_flowering_prairie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flowering_prairie.png");
}

.customize-option.icon_background_flowering_prairie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flowering_prairie.png");
  background-position: -25px -15px;
}

.icon_background_flying_in_a_thunderstorm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_in_a_thunderstorm.png");
}

.icon_background_flying_over_a_field_of_wildflowers {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_a_field_of_wildflowers.png");
}

.customize-option.icon_background_flying_over_a_field_of_wildflowers {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_a_field_of_wildflowers.png");
  background-position: -25px -15px;
}

.icon_background_flying_over_an_ancient_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_an_ancient_forest.png");
}

.icon_background_flying_over_an_autumn_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_an_autumn_forest.png");
}

.icon_background_flying_over_glacier {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_glacier.png");
}

.icon_background_flying_over_hedge_maze {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_hedge_maze.png");
}

.icon_background_flying_over_icy_steppes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_icy_steppes.png");
}

.icon_background_flying_over_rocky_canyon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_rocky_canyon.png");
}

.icon_background_flying_over_snowy_mountains {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_snowy_mountains.png");
}

.icon_background_flying_over_the_ocean {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_flying_over_the_ocean.png");
}

.icon_background_foggy_moor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_foggy_moor.png");
}

.icon_background_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_forest.png");
}

.icon_background_forested_lakeshore {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_forested_lakeshore.png");
}

.icon_background_fortune_tellers_shop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_fortune_tellers_shop.png");
}

.icon_background_frigid_peak {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frigid_peak.png");
}

.icon_background_frosty_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frosty_forest.png");
}

.icon_background_frozen_lake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frozen_lake.png");
}

.icon_background_frozen_polar_waters {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_frozen_polar_waters.png");
}

.icon_background_garden_shed {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_garden_shed.png");
}

.icon_background_gazebo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gazebo.png");
}

.icon_background_ghost_ship {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ghost_ship.png");
}

.icon_background_giant_autumn_leaf {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_autumn_leaf.png");
}

.icon_background_giant_birdhouse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_birdhouse.png");
}

.icon_background_giant_book {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_book.png");
}

.icon_background_giant_dandelions {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_dandelions.png");
}

.icon_background_giant_florals {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_florals.png");
}

.icon_background_giant_seashell {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_seashell.png");
}

.icon_background_giant_wave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_giant_wave.png");
}

.icon_background_gingerbread_house {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gingerbread_house.png");
}

.icon_background_glowing_mushroom_cave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_glowing_mushroom_cave.png");
}

.icon_background_golden_birdcage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_golden_birdcage.png");
}

.icon_background_gorgeous_greenhouse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gorgeous_greenhouse.png");
}

.icon_background_grand_staircase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_grand_staircase.png");
}

.icon_background_graveyard {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_graveyard.png");
}

.icon_background_green {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_green.png");
}

.icon_background_guardian_statues {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_guardian_statues.png");
}

.icon_background_gumdrop_land {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_gumdrop_land.png");
}

.icon_background_habit_city_rooftops {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_habit_city_rooftops.png");
}

.icon_background_habit_city_streets {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_habit_city_streets.png");
}

.icon_background_halflings_house {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_halflings_house.png");
}

.icon_background_hall_of_heroes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_hall_of_heroes.png");
}

.icon_background_harvest_feast {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_harvest_feast.png");
}

.icon_background_harvest_fields {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_harvest_fields.png");
}

.icon_background_harvest_moon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_harvest_moon.png");
}

.icon_background_haunted_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_haunted_forest.png");
}

.icon_background_haunted_house {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_haunted_house.png");
}

.icon_background_haunted_photo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_haunted_photo.png");
}

.icon_background_heart_shaped_bubbles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_heart_shaped_bubbles.png");
}

.icon_background_heather_field {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_heather_field.png");
}

.icon_background_herding_sheep_in_autumn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_herding_sheep_in_autumn.png");
}

.icon_background_holiday_hearth {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_holiday_hearth.png");
}

.icon_background_holiday_market {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_holiday_market.png");
}

.icon_background_holiday_wreath {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_holiday_wreath.png");
}

.icon_background_hot_air_balloon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_hot_air_balloon.png");
}

.icon_background_hot_spring {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_hot_spring.png");
}

.icon_background_ice_cave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ice_cave.png");
}

.icon_background_ice_palace {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ice_palace.png");
}

.icon_background_iceberg {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_iceberg.png");
}

.icon_background_icicle_bridge {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_icicle_bridge.png");
}

.icon_background_idyllic_cabin {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_idyllic_cabin.png");
}

.icon_background_in_a_classroom {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_a_classroom.png");
}

.icon_background_in_a_painting {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_a_painting.png");
}

.icon_background_in_an_ancient_tomb {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_an_ancient_tomb.png");
}

.icon_background_in_front_of_fountain {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_front_of_fountain.png");
}

.icon_background_in_the_armory {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_in_the_armory.png");
}

.icon_background_inside_a_crystal {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_inside_a_crystal.png");
}

.icon_background_inside_a_potion_bottle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_inside_a_potion_bottle.png");
}

.icon_background_inside_an_ornament {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_inside_an_ornament.png");
}

.icon_background_iridescent_clouds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_iridescent_clouds.png");
}

.icon_background_island_waterfalls {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_island_waterfalls.png");
}

.icon_background_jungle_canopy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_jungle_canopy.png");
}

.icon_background_jungle_watering_hole {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_jungle_watering_hole.png");
}

.icon_background_kelp_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_kelp_forest.png");
}

.icon_background_lake_with_floating_lanterns {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_lake_with_floating_lanterns.png");
}

.icon_background_leafy_tree_tunnel {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_leafy_tree_tunnel.png");
}

.icon_background_lighthouse_shore {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_lighthouse_shore.png");
}

.icon_background_lilypad {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_lilypad.png");
}

.icon_background_magic_beanstalk {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_magic_beanstalk.png");
}

.icon_background_magical_candles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_magical_candles.png");
}

.icon_background_magical_museum {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_magical_museum.png");
}

.icon_background_mangrove_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mangrove_forest.png");
}

.icon_background_marble_temple {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_marble_temple.png");
}

.icon_background_market {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_market.png");
}

.icon_background_mask_makers_workshop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mask_makers_workshop.png");
}

.icon_background_meandering_cave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_meandering_cave.png");
}

.icon_background_medieval_kitchen {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_medieval_kitchen.png");
}

.icon_background_messy_room {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_messy_room.png");
}

.icon_background_meteor_shower {
  width: 68px;
  height: 69px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_meteor_shower.png");
}

.icon_background_midnight_castle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_midnight_castle.png");
}

.icon_background_midnight_clouds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_midnight_clouds.png");
}

.icon_background_midnight_lake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_midnight_lake.png");
}

.icon_background_mist_shrouded_mountain {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mist_shrouded_mountain.png");
}

.icon_background_mistiflying_circus {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mistiflying_circus.png");
}

.icon_background_misty_autumn_forest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_misty_autumn_forest.png");
}

.icon_background_monster_makers_workshop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_monster_makers_workshop.png");
}

.icon_background_mountain_lake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mountain_lake.png");
}

.icon_background_mountain_pyramid {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mountain_pyramid.png");
}

.icon_background_mountain_waterfall {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mountain_waterfall.png");
}

.icon_background_mystical_observatory {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_mystical_observatory.png");
}

.icon_background_night_dunes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_night_dunes.png");
}

.icon_background_ocean_sunrise {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_ocean_sunrise.png");
}

.icon_background_old_fashioned_bakery {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_old_fashioned_bakery.png");
}

.icon_background_old_photo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_old_photo.png");
}

.icon_background_old_timey_basketball_court {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_old_timey_basketball_court.png");
}

.icon_background_on_a_castle_wall {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_on_a_castle_wall.png");
}

.icon_background_on_tree_branch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_on_tree_branch.png");
}

.icon_background_open_waters {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_open_waters.png");
}

.icon_background_orange_grove {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_orange_grove.png");
}

.icon_background_orchard {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_orchard.png");
}

.icon_background_pagodas {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pagodas.png");
}

.icon_background_palm_tree_with_fairy_lights {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_palm_tree_with_fairy_lights.png");
}

.icon_background_park_with_statue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_park_with_statue.png");
}

.icon_background_pirate_flag {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pirate_flag.png");
}

.icon_background_pixelists_workshop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pixelists_workshop.png");
}

.icon_background_potion_shop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_potion_shop.png");
}

.icon_background_productivity_plaza {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_productivity_plaza.png");
}

.icon_background_pumpkin_carriage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pumpkin_carriage.png");
}

.icon_background_pumpkin_patch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pumpkin_patch.png");
}

.icon_background_purple {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_purple.png");
}

.icon_background_pyramids {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_pyramids.png");
}

.icon_background_raging_river {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_raging_river.png");
}

.icon_background_rainbow_eucalyptus {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainbow_eucalyptus.png");
}

.icon_background_rainbow_meadow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainbow_meadow.png");
}

.icon_background_rainbows_end {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainbows_end.png");
}

.icon_background_rainforest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainforest.png");
}

.icon_background_rainy_barnyard {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainy_barnyard.png");
}

.icon_background_rainy_city {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rainy_city.png");
}

.icon_background_red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_red.png");
}

.icon_background_relaxation_river {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_relaxation_river.png");
}

.icon_background_resting_in_the_inn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_resting_in_the_inn.png");
}

.icon_background_rime_ice {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rime_ice.png");
}

.icon_background_river_of_lava {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_river_of_lava.png");
}

.icon_background_rolling_hills {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rolling_hills.png");
}

.icon_background_rope_bridge {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rope_bridge.png");
}

.icon_background_rose_garden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rose_garden.png");
}

.icon_background_rowboat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_rowboat.png");
}

.icon_background_sailboat_at_sunset {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sailboat_at_sunset.png");
}

.icon_background_salt_lake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_salt_lake.png");
}

.icon_background_sandcastle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sandcastle.png");
}

.icon_background_school_of_fish {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_school_of_fish.png");
}

.icon_background_scribes_workshop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_scribes_workshop.png");
}

.icon_background_seafarer_ship {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_seafarer_ship.png");
}

.icon_background_seaside_cliffs {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_seaside_cliffs.png");
}

.icon_background_shimmering_ice_prism {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_shimmering_ice_prism.png");
}

.icon_background_shimmery_bubbles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_shimmery_bubbles.png");
}

.icon_background_slimy_swamp {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_slimy_swamp.png");
}

.icon_background_snowglobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowglobe.png");
}

.icon_background_snowman_army {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowman_army.png");
}

.icon_background_snowy_day_fireplace {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_day_fireplace.png");
}

.icon_background_snowy_farm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_farm.png");
}

.icon_background_snowy_pines {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_pines.png");
}

.icon_background_snowy_sunrise {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_sunrise.png");
}

.icon_background_snowy_temple {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_temple.png");
}

.icon_background_snowy_village {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_snowy_village.png");
}

.icon_background_south_pole {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_south_pole.png");
}

.icon_background_sparkling_snowflake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sparkling_snowflake.png");
}

.icon_background_spider_web {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spider_web.png");
}

.icon_background_spiral_staircase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spiral_staircase.png");
}

.icon_background_splash_in_a_puddle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_splash_in_a_puddle.png");
}

.icon_background_spooky_hotel {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spooky_hotel.png");
}

.icon_background_spooky_ruins {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spooky_ruins.png");
}

.icon_background_spooky_scarecrow_field {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spooky_scarecrow_field.png");
}

.icon_background_spring_rain {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spring_rain.png");
}

.icon_background_spring_thaw {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_spring_thaw.png");
}

.icon_background_springtime_lake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_springtime_lake.png");
}

.icon_background_springtime_shower {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_springtime_shower.png");
}

.icon_background_stable {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stable.png");
}

.icon_background_stained_glass {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stained_glass.png");
}

.icon_background_starry_skies {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_starry_skies.png");
}

.icon_background_starry_winter_night {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_starry_winter_night.png");
}

.icon_background_steamworks {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_steamworks.png");
}

.icon_background_stoikalm_volcanoes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stoikalm_volcanoes.png");
}

.icon_background_stone_circle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stone_circle.png");
}

.icon_background_stone_tower {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stone_tower.png");
}

.icon_background_stormy_rooftops {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stormy_rooftops.png");
}

.icon_background_stormy_ship {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_stormy_ship.png");
}

.icon_background_strange_sewers {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_strange_sewers.png");
}

.icon_background_strawberry_patch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_strawberry_patch.png");
}

.icon_background_succulent_garden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_succulent_garden.png");
}

.icon_background_summer_fireworks {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_summer_fireworks.png");
}

.icon_background_sunken_ship {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunken_ship.png");
}

.icon_background_sunset_meadow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunset_meadow.png");
}

.icon_background_sunset_oasis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunset_oasis.png");
}

.icon_background_sunset_savannah {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_sunset_savannah.png");
}

.icon_background_swarming_darkness {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_swarming_darkness.png");
}

.icon_background_swimming_among_jellyfish {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_swimming_among_jellyfish.png");
}

.icon_background_tar_pits {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tar_pits.png");
}

.icon_background_tavern {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tavern.png");
}

.icon_background_tea_party {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tea_party.png");
}

.icon_background_terraced_rice_field {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_terraced_rice_field.png");
}

.icon_background_theatre_stage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_theatre_stage.png");
}

.icon_background_throne_room {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_throne_room.png");
}

.icon_background_thunderstorm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_thunderstorm.png");
}

.icon_background_tide_pool {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tide_pool.png");
}

.icon_background_tornado {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tornado.png");
}

.icon_background_toymakers_workshop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_toymakers_workshop.png");
}

.icon_background_training_grounds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_training_grounds.png");
}

.icon_background_treasure_room {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_treasure_room.png");
}

.icon_background_tree_roots {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tree_roots.png");
}

.icon_background_treehouse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_treehouse.png");
}

.icon_background_tulip_garden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_tulip_garden.png");
}

.icon_background_twinkly_lights {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_twinkly_lights.png");
}

.icon_background_twinkly_party_lights {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_twinkly_party_lights.png");
}

.icon_background_undead_hands {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_undead_hands.png");
}

.icon_background_under_wisteria {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_under_wisteria.png");
}

.icon_background_underwater_among_koi {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_among_koi.png");
}

.icon_background_underwater_cave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_cave.png");
}

.icon_background_underwater_ruins {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_ruins.png");
}

.icon_background_underwater_statues {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_statues.png");
}

.icon_background_underwater_vents {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_underwater_vents.png");
}

.icon_background_valentines_day_feasting_hall {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_valentines_day_feasting_hall.png");
}

.icon_background_viking_ship {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_viking_ship.png");
}

.icon_background_vineyard {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_vineyard.png");
}

.icon_background_violet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_violet.png");
}

.icon_background_volcano {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_volcano.png");
}

.icon_background_water_mill {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_water_mill.png");
}

.icon_background_waterfall_rock {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_waterfall_rock.png");
}

.icon_background_wedding_arch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_wedding_arch.png");
}

.icon_background_windmills {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_windmills.png");
}

.icon_background_windy_autumn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_windy_autumn.png");
}

.icon_background_winter_canyon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_canyon.png");
}

.icon_background_winter_fireworks {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_fireworks.png");
}

.icon_background_winter_lake_with_swans {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_lake_with_swans.png");
}

.icon_background_winter_night {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_night.png");
}

.icon_background_winter_nocturne {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_nocturne.png");
}

.icon_background_winter_storefront {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_storefront.png");
}

.icon_background_winter_town {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_town.png");
}

.icon_background_winter_waterfall {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_winter_waterfall.png");
}

.icon_background_wintry_castle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_wintry_castle.png");
}

.icon_background_yellow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_background_yellow.png");
}

.hair_beard_1_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_TRUred.png");
}

.customize-option.hair_beard_1_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_TRUred.png");
  background-position: -25px -15px;
}

.hair_beard_1_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_aurora.png");
}

.customize-option.hair_beard_1_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_aurora.png");
  background-position: -25px -15px;
}

.hair_beard_1_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_black.png");
}

.customize-option.hair_beard_1_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_black.png");
  background-position: -25px -15px;
}

.hair_beard_1_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blond.png");
}

.customize-option.hair_beard_1_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blond.png");
  background-position: -25px -15px;
}

.hair_beard_1_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blue.png");
}

.customize-option.hair_beard_1_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_blue.png");
  background-position: -25px -15px;
}

.hair_beard_1_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_brown.png");
}

.customize-option.hair_beard_1_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_brown.png");
  background-position: -25px -15px;
}

.hair_beard_1_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycane.png");
}

.customize-option.hair_beard_1_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycane.png");
  background-position: -25px -15px;
}

.hair_beard_1_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycorn.png");
}

.customize-option.hair_beard_1_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_candycorn.png");
  background-position: -25px -15px;
}

.hair_beard_1_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_festive.png");
}

.customize-option.hair_beard_1_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_festive.png");
  background-position: -25px -15px;
}

.hair_beard_1_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_frost.png");
}

.customize-option.hair_beard_1_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_frost.png");
  background-position: -25px -15px;
}

.hair_beard_1_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ghostwhite.png");
}

.customize-option.hair_beard_1_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_beard_1_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_green.png");
}

.customize-option.hair_beard_1_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_green.png");
  background-position: -25px -15px;
}

.hair_beard_1_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_halloween.png");
}

.customize-option.hair_beard_1_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_halloween.png");
  background-position: -25px -15px;
}

.hair_beard_1_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_holly.png");
}

.customize-option.hair_beard_1_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_holly.png");
  background-position: -25px -15px;
}

.hair_beard_1_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_hollygreen.png");
}

.customize-option.hair_beard_1_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_hollygreen.png");
  background-position: -25px -15px;
}

.hair_beard_1_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_midnight.png");
}

.customize-option.hair_beard_1_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_midnight.png");
  background-position: -25px -15px;
}

.hair_beard_1_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue.png");
}

.customize-option.hair_beard_1_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue.png");
  background-position: -25px -15px;
}

.hair_beard_1_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_peppermint.png");
}

.customize-option.hair_beard_1_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_peppermint.png");
  background-position: -25px -15px;
}

.hair_beard_1_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen.png");
}

.customize-option.hair_beard_1_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen.png");
  background-position: -25px -15px;
}

.hair_beard_1_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange.png");
}

.customize-option.hair_beard_1_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange.png");
  background-position: -25px -15px;
}

.hair_beard_1_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink.png");
}

.customize-option.hair_beard_1_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink.png");
  background-position: -25px -15px;
}

.hair_beard_1_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple.png");
}

.customize-option.hair_beard_1_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple.png");
  background-position: -25px -15px;
}

.hair_beard_1_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pumpkin.png");
}

.customize-option.hair_beard_1_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pumpkin.png");
  background-position: -25px -15px;
}

.hair_beard_1_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_purple.png");
}

.customize-option.hair_beard_1_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_purple.png");
  background-position: -25px -15px;
}

.hair_beard_1_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow.png");
}

.customize-option.hair_beard_1_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow.png");
  background-position: -25px -15px;
}

.hair_beard_1_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_rainbow.png");
}

.customize-option.hair_beard_1_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_rainbow.png");
  background-position: -25px -15px;
}

.hair_beard_1_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_red.png");
}

.customize-option.hair_beard_1_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_red.png");
  background-position: -25px -15px;
}

.hair_beard_1_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_snowy.png");
}

.customize-option.hair_beard_1_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_snowy.png");
  background-position: -25px -15px;
}

.hair_beard_1_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_white.png");
}

.customize-option.hair_beard_1_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_white.png");
  background-position: -25px -15px;
}

.hair_beard_1_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winternight.png");
}

.customize-option.hair_beard_1_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winternight.png");
  background-position: -25px -15px;
}

.hair_beard_1_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winterstar.png");
}

.customize-option.hair_beard_1_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_winterstar.png");
  background-position: -25px -15px;
}

.hair_beard_1_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_yellow.png");
}

.customize-option.hair_beard_1_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_yellow.png");
  background-position: -25px -15px;
}

.hair_beard_1_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_zombie.png");
}

.customize-option.hair_beard_1_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_zombie.png");
  background-position: -25px -15px;
}

.hair_beard_2_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_TRUred.png");
}

.customize-option.hair_beard_2_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_TRUred.png");
  background-position: -25px -15px;
}

.hair_beard_2_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_aurora.png");
}

.customize-option.hair_beard_2_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_aurora.png");
  background-position: -25px -15px;
}

.hair_beard_2_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_black.png");
}

.customize-option.hair_beard_2_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_black.png");
  background-position: -25px -15px;
}

.hair_beard_2_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blond.png");
}

.customize-option.hair_beard_2_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blond.png");
  background-position: -25px -15px;
}

.hair_beard_2_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blue.png");
}

.customize-option.hair_beard_2_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_blue.png");
  background-position: -25px -15px;
}

.hair_beard_2_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_brown.png");
}

.customize-option.hair_beard_2_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_brown.png");
  background-position: -25px -15px;
}

.hair_beard_2_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycane.png");
}

.customize-option.hair_beard_2_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycane.png");
  background-position: -25px -15px;
}

.hair_beard_2_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycorn.png");
}

.customize-option.hair_beard_2_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_candycorn.png");
  background-position: -25px -15px;
}

.hair_beard_2_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_festive.png");
}

.customize-option.hair_beard_2_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_festive.png");
  background-position: -25px -15px;
}

.hair_beard_2_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_frost.png");
}

.customize-option.hair_beard_2_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_frost.png");
  background-position: -25px -15px;
}

.hair_beard_2_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ghostwhite.png");
}

.customize-option.hair_beard_2_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_beard_2_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_green.png");
}

.customize-option.hair_beard_2_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_green.png");
  background-position: -25px -15px;
}

.hair_beard_2_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_halloween.png");
}

.customize-option.hair_beard_2_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_halloween.png");
  background-position: -25px -15px;
}

.hair_beard_2_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_holly.png");
}

.customize-option.hair_beard_2_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_holly.png");
  background-position: -25px -15px;
}

.hair_beard_2_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_hollygreen.png");
}

.customize-option.hair_beard_2_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_hollygreen.png");
  background-position: -25px -15px;
}

.hair_beard_2_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_midnight.png");
}

.customize-option.hair_beard_2_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_midnight.png");
  background-position: -25px -15px;
}

.hair_beard_2_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue.png");
}

.customize-option.hair_beard_2_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue.png");
  background-position: -25px -15px;
}

.hair_beard_2_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_peppermint.png");
}

.customize-option.hair_beard_2_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_peppermint.png");
  background-position: -25px -15px;
}

.hair_beard_2_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen.png");
}

.customize-option.hair_beard_2_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen.png");
  background-position: -25px -15px;
}

.hair_beard_2_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange.png");
}

.customize-option.hair_beard_2_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange.png");
  background-position: -25px -15px;
}

.hair_beard_2_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink.png");
}

.customize-option.hair_beard_2_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink.png");
  background-position: -25px -15px;
}

.hair_beard_2_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple.png");
}

.customize-option.hair_beard_2_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple.png");
  background-position: -25px -15px;
}

.hair_beard_2_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pumpkin.png");
}

.customize-option.hair_beard_2_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pumpkin.png");
  background-position: -25px -15px;
}

.hair_beard_2_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_purple.png");
}

.customize-option.hair_beard_2_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_purple.png");
  background-position: -25px -15px;
}

.hair_beard_2_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow.png");
}

.customize-option.hair_beard_2_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow.png");
  background-position: -25px -15px;
}

.hair_beard_2_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_rainbow.png");
}

.customize-option.hair_beard_2_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_rainbow.png");
  background-position: -25px -15px;
}

.hair_beard_2_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_red.png");
}

.customize-option.hair_beard_2_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_red.png");
  background-position: -25px -15px;
}

.hair_beard_2_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_snowy.png");
}

.customize-option.hair_beard_2_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_snowy.png");
  background-position: -25px -15px;
}

.hair_beard_2_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_white.png");
}

.customize-option.hair_beard_2_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_white.png");
  background-position: -25px -15px;
}

.hair_beard_2_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winternight.png");
}

.customize-option.hair_beard_2_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winternight.png");
  background-position: -25px -15px;
}

.hair_beard_2_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winterstar.png");
}

.customize-option.hair_beard_2_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_winterstar.png");
  background-position: -25px -15px;
}

.hair_beard_2_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_yellow.png");
}

.customize-option.hair_beard_2_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_yellow.png");
  background-position: -25px -15px;
}

.hair_beard_2_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_zombie.png");
}

.customize-option.hair_beard_2_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_zombie.png");
  background-position: -25px -15px;
}

.hair_beard_3_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_TRUred.png");
}

.customize-option.hair_beard_3_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_TRUred.png");
  background-position: -25px -15px;
}

.hair_beard_3_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_aurora.png");
}

.customize-option.hair_beard_3_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_aurora.png");
  background-position: -25px -15px;
}

.hair_beard_3_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_black.png");
}

.customize-option.hair_beard_3_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_black.png");
  background-position: -25px -15px;
}

.hair_beard_3_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blond.png");
}

.customize-option.hair_beard_3_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blond.png");
  background-position: -25px -15px;
}

.hair_beard_3_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blue.png");
}

.customize-option.hair_beard_3_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_blue.png");
  background-position: -25px -15px;
}

.hair_beard_3_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_brown.png");
}

.customize-option.hair_beard_3_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_brown.png");
  background-position: -25px -15px;
}

.hair_beard_3_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycane.png");
}

.customize-option.hair_beard_3_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycane.png");
  background-position: -25px -15px;
}

.hair_beard_3_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycorn.png");
}

.customize-option.hair_beard_3_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_candycorn.png");
  background-position: -25px -15px;
}

.hair_beard_3_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_festive.png");
}

.customize-option.hair_beard_3_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_festive.png");
  background-position: -25px -15px;
}

.hair_beard_3_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_frost.png");
}

.customize-option.hair_beard_3_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_frost.png");
  background-position: -25px -15px;
}

.hair_beard_3_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ghostwhite.png");
}

.customize-option.hair_beard_3_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_beard_3_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_green.png");
}

.customize-option.hair_beard_3_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_green.png");
  background-position: -25px -15px;
}

.hair_beard_3_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_halloween.png");
}

.customize-option.hair_beard_3_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_halloween.png");
  background-position: -25px -15px;
}

.hair_beard_3_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_holly.png");
}

.customize-option.hair_beard_3_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_holly.png");
  background-position: -25px -15px;
}

.hair_beard_3_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_hollygreen.png");
}

.customize-option.hair_beard_3_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_hollygreen.png");
  background-position: -25px -15px;
}

.hair_beard_3_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_midnight.png");
}

.customize-option.hair_beard_3_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_midnight.png");
  background-position: -25px -15px;
}

.hair_beard_3_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue.png");
}

.customize-option.hair_beard_3_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue.png");
  background-position: -25px -15px;
}

.hair_beard_3_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_peppermint.png");
}

.customize-option.hair_beard_3_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_peppermint.png");
  background-position: -25px -15px;
}

.hair_beard_3_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen.png");
}

.customize-option.hair_beard_3_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen.png");
  background-position: -25px -15px;
}

.hair_beard_3_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange.png");
}

.customize-option.hair_beard_3_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange.png");
  background-position: -25px -15px;
}

.hair_beard_3_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink.png");
}

.customize-option.hair_beard_3_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink.png");
  background-position: -25px -15px;
}

.hair_beard_3_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple.png");
}

.customize-option.hair_beard_3_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple.png");
  background-position: -25px -15px;
}

.hair_beard_3_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pumpkin.png");
}

.customize-option.hair_beard_3_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pumpkin.png");
  background-position: -25px -15px;
}

.hair_beard_3_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_purple.png");
}

.customize-option.hair_beard_3_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_purple.png");
  background-position: -25px -15px;
}

.hair_beard_3_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow.png");
}

.customize-option.hair_beard_3_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow.png");
  background-position: -25px -15px;
}

.hair_beard_3_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_rainbow.png");
}

.customize-option.hair_beard_3_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_rainbow.png");
  background-position: -25px -15px;
}

.hair_beard_3_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_red.png");
}

.customize-option.hair_beard_3_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_red.png");
  background-position: -25px -15px;
}

.hair_beard_3_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_snowy.png");
}

.customize-option.hair_beard_3_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_snowy.png");
  background-position: -25px -15px;
}

.hair_beard_3_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_white.png");
}

.customize-option.hair_beard_3_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_white.png");
  background-position: -25px -15px;
}

.hair_beard_3_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winternight.png");
}

.customize-option.hair_beard_3_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winternight.png");
  background-position: -25px -15px;
}

.hair_beard_3_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winterstar.png");
}

.customize-option.hair_beard_3_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_winterstar.png");
  background-position: -25px -15px;
}

.hair_beard_3_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_yellow.png");
}

.customize-option.hair_beard_3_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_yellow.png");
  background-position: -25px -15px;
}

.hair_beard_3_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_zombie.png");
}

.customize-option.hair_beard_3_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_zombie.png");
  background-position: -25px -15px;
}

.hair_mustache_1_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_TRUred.png");
}

.customize-option.hair_mustache_1_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_TRUred.png");
  background-position: -25px -15px;
}

.hair_mustache_1_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_aurora.png");
}

.customize-option.hair_mustache_1_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_aurora.png");
  background-position: -25px -15px;
}

.hair_mustache_1_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_black.png");
}

.customize-option.hair_mustache_1_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_black.png");
  background-position: -25px -15px;
}

.hair_mustache_1_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blond.png");
}

.customize-option.hair_mustache_1_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blond.png");
  background-position: -25px -15px;
}

.hair_mustache_1_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blue.png");
}

.customize-option.hair_mustache_1_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_blue.png");
  background-position: -25px -15px;
}

.hair_mustache_1_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_brown.png");
}

.customize-option.hair_mustache_1_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_brown.png");
  background-position: -25px -15px;
}

.hair_mustache_1_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycane.png");
}

.customize-option.hair_mustache_1_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycane.png");
  background-position: -25px -15px;
}

.hair_mustache_1_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycorn.png");
}

.customize-option.hair_mustache_1_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_candycorn.png");
  background-position: -25px -15px;
}

.hair_mustache_1_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_festive.png");
}

.customize-option.hair_mustache_1_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_festive.png");
  background-position: -25px -15px;
}

.hair_mustache_1_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_frost.png");
}

.customize-option.hair_mustache_1_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_frost.png");
  background-position: -25px -15px;
}

.hair_mustache_1_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ghostwhite.png");
}

.customize-option.hair_mustache_1_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_mustache_1_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_green.png");
}

.customize-option.hair_mustache_1_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_green.png");
  background-position: -25px -15px;
}

.hair_mustache_1_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_halloween.png");
}

.customize-option.hair_mustache_1_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_halloween.png");
  background-position: -25px -15px;
}

.hair_mustache_1_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_holly.png");
}

.customize-option.hair_mustache_1_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_holly.png");
  background-position: -25px -15px;
}

.hair_mustache_1_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_hollygreen.png");
}

.customize-option.hair_mustache_1_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_hollygreen.png");
  background-position: -25px -15px;
}

.hair_mustache_1_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_midnight.png");
}

.customize-option.hair_mustache_1_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_midnight.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue.png");
}

.customize-option.hair_mustache_1_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue.png");
  background-position: -25px -15px;
}

.hair_mustache_1_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_peppermint.png");
}

.customize-option.hair_mustache_1_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_peppermint.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen.png");
}

.customize-option.hair_mustache_1_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen.png");
  background-position: -25px -15px;
}

.hair_mustache_1_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange.png");
}

.customize-option.hair_mustache_1_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange.png");
  background-position: -25px -15px;
}

.hair_mustache_1_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink.png");
}

.customize-option.hair_mustache_1_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink.png");
  background-position: -25px -15px;
}

.hair_mustache_1_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple.png");
}

.customize-option.hair_mustache_1_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pumpkin.png");
}

.customize-option.hair_mustache_1_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pumpkin.png");
  background-position: -25px -15px;
}

.hair_mustache_1_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_purple.png");
}

.customize-option.hair_mustache_1_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_purple.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow.png");
}

.customize-option.hair_mustache_1_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow.png");
  background-position: -25px -15px;
}

.hair_mustache_1_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_rainbow.png");
}

.customize-option.hair_mustache_1_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_rainbow.png");
  background-position: -25px -15px;
}

.hair_mustache_1_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_red.png");
}

.customize-option.hair_mustache_1_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_red.png");
  background-position: -25px -15px;
}

.hair_mustache_1_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_snowy.png");
}

.customize-option.hair_mustache_1_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_snowy.png");
  background-position: -25px -15px;
}

.hair_mustache_1_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_white.png");
}

.customize-option.hair_mustache_1_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_white.png");
  background-position: -25px -15px;
}

.hair_mustache_1_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winternight.png");
}

.customize-option.hair_mustache_1_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winternight.png");
  background-position: -25px -15px;
}

.hair_mustache_1_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winterstar.png");
}

.customize-option.hair_mustache_1_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_winterstar.png");
  background-position: -25px -15px;
}

.hair_mustache_1_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_yellow.png");
}

.customize-option.hair_mustache_1_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_yellow.png");
  background-position: -25px -15px;
}

.hair_mustache_1_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_zombie.png");
}

.customize-option.hair_mustache_1_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_zombie.png");
  background-position: -25px -15px;
}

.hair_mustache_2_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_TRUred.png");
}

.customize-option.hair_mustache_2_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_TRUred.png");
  background-position: -25px -15px;
}

.hair_mustache_2_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_aurora.png");
}

.customize-option.hair_mustache_2_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_aurora.png");
  background-position: -25px -15px;
}

.hair_mustache_2_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_black.png");
}

.customize-option.hair_mustache_2_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_black.png");
  background-position: -25px -15px;
}

.hair_mustache_2_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blond.png");
}

.customize-option.hair_mustache_2_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blond.png");
  background-position: -25px -15px;
}

.hair_mustache_2_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blue.png");
}

.customize-option.hair_mustache_2_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_blue.png");
  background-position: -25px -15px;
}

.hair_mustache_2_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_brown.png");
}

.customize-option.hair_mustache_2_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_brown.png");
  background-position: -25px -15px;
}

.hair_mustache_2_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycane.png");
}

.customize-option.hair_mustache_2_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycane.png");
  background-position: -25px -15px;
}

.hair_mustache_2_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycorn.png");
}

.customize-option.hair_mustache_2_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_candycorn.png");
  background-position: -25px -15px;
}

.hair_mustache_2_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_festive.png");
}

.customize-option.hair_mustache_2_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_festive.png");
  background-position: -25px -15px;
}

.hair_mustache_2_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_frost.png");
}

.customize-option.hair_mustache_2_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_frost.png");
  background-position: -25px -15px;
}

.hair_mustache_2_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ghostwhite.png");
}

.customize-option.hair_mustache_2_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_mustache_2_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_green.png");
}

.customize-option.hair_mustache_2_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_green.png");
  background-position: -25px -15px;
}

.hair_mustache_2_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_halloween.png");
}

.customize-option.hair_mustache_2_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_halloween.png");
  background-position: -25px -15px;
}

.hair_mustache_2_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_holly.png");
}

.customize-option.hair_mustache_2_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_holly.png");
  background-position: -25px -15px;
}

.hair_mustache_2_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_hollygreen.png");
}

.customize-option.hair_mustache_2_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_hollygreen.png");
  background-position: -25px -15px;
}

.hair_mustache_2_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_midnight.png");
}

.customize-option.hair_mustache_2_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_midnight.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue.png");
}

.customize-option.hair_mustache_2_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue.png");
  background-position: -25px -15px;
}

.hair_mustache_2_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_peppermint.png");
}

.customize-option.hair_mustache_2_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_peppermint.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen.png");
}

.customize-option.hair_mustache_2_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen.png");
  background-position: -25px -15px;
}

.hair_mustache_2_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange.png");
}

.customize-option.hair_mustache_2_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange.png");
  background-position: -25px -15px;
}

.hair_mustache_2_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink.png");
}

.customize-option.hair_mustache_2_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink.png");
  background-position: -25px -15px;
}

.hair_mustache_2_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple.png");
}

.customize-option.hair_mustache_2_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pumpkin.png");
}

.customize-option.hair_mustache_2_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pumpkin.png");
  background-position: -25px -15px;
}

.hair_mustache_2_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_purple.png");
}

.customize-option.hair_mustache_2_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_purple.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow.png");
}

.customize-option.hair_mustache_2_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow.png");
  background-position: -25px -15px;
}

.hair_mustache_2_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_rainbow.png");
}

.customize-option.hair_mustache_2_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_rainbow.png");
  background-position: -25px -15px;
}

.hair_mustache_2_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_red.png");
}

.customize-option.hair_mustache_2_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_red.png");
  background-position: -25px -15px;
}

.hair_mustache_2_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_snowy.png");
}

.customize-option.hair_mustache_2_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_snowy.png");
  background-position: -25px -15px;
}

.hair_mustache_2_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_white.png");
}

.customize-option.hair_mustache_2_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_white.png");
  background-position: -25px -15px;
}

.hair_mustache_2_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winternight.png");
}

.customize-option.hair_mustache_2_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winternight.png");
  background-position: -25px -15px;
}

.hair_mustache_2_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winterstar.png");
}

.customize-option.hair_mustache_2_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_winterstar.png");
  background-position: -25px -15px;
}

.hair_mustache_2_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_yellow.png");
}

.customize-option.hair_mustache_2_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_yellow.png");
  background-position: -25px -15px;
}

.hair_mustache_2_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_zombie.png");
}

.customize-option.hair_mustache_2_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_zombie.png");
  background-position: -25px -15px;
}

.button_chair_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_black.png");
}

.button_chair_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_blue.png");
}

.button_chair_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_green.png");
}

.button_chair_handleless_black {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_black.png");
}

.button_chair_handleless_blue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_blue.png");
}

.button_chair_handleless_green {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_green.png");
}

.button_chair_handleless_pink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_pink.png");
}

.button_chair_handleless_red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_red.png");
}

.button_chair_handleless_yellow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_handleless_yellow.png");
}

.button_chair_pink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_pink.png");
}

.button_chair_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_red.png");
}

.button_chair_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/button_chair_yellow.png");
}

.chair_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_black.png");
}

.chair_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_blue.png");
}

.chair_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_green.png");
}

.chair_handleless_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_black.png");
}

.chair_handleless_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_blue.png");
}

.chair_handleless_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_green.png");
}

.chair_handleless_pink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_pink.png");
}

.chair_handleless_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_red.png");
}

.chair_handleless_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_handleless_yellow.png");
}

.chair_pink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_pink.png");
}

.chair_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_red.png");
}

.chair_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/chair_yellow.png");
}

.hair_flower_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_1.png");
}

.customize-option.hair_flower_1 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_1.png");
  background-position: -25px -15px;
}

.hair_flower_10 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_10.png");
}

.customize-option.hair_flower_10 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_10.png");
  background-position: -25px -15px;
}

.hair_flower_11 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_11.png");
}

.customize-option.hair_flower_11 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_11.png");
  background-position: -25px -15px;
}

.hair_flower_12 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_12.png");
}

.customize-option.hair_flower_12 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_12.png");
  background-position: -25px -15px;
}

.hair_flower_13 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_13.png");
}

.customize-option.hair_flower_13 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_13.png");
  background-position: -25px -15px;
}

.hair_flower_14 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_14.png");
}

.customize-option.hair_flower_14 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_14.png");
  background-position: -25px -15px;
}

.hair_flower_15 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_15.png");
}

.customize-option.hair_flower_15 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_15.png");
  background-position: -25px -15px;
}

.hair_flower_16 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_16.png");
}

.customize-option.hair_flower_16 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_16.png");
  background-position: -25px -15px;
}

.hair_flower_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_2.png");
}

.customize-option.hair_flower_2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_2.png");
  background-position: -25px -15px;
}

.hair_flower_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_3.png");
}

.customize-option.hair_flower_3 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_3.png");
  background-position: -25px -15px;
}

.hair_flower_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_4.png");
}

.customize-option.hair_flower_4 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_4.png");
  background-position: -25px -15px;
}

.hair_flower_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_5.png");
}

.customize-option.hair_flower_5 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_5.png");
  background-position: -25px -15px;
}

.hair_flower_6 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_6.png");
}

.customize-option.hair_flower_6 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_6.png");
  background-position: -25px -15px;
}

.hair_flower_7 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_7.png");
}

.customize-option.hair_flower_7 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_7.png");
  background-position: -25px -15px;
}

.hair_flower_8 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_8.png");
}

.customize-option.hair_flower_8 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_8.png");
  background-position: -25px -15px;
}

.hair_flower_9 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_9.png");
}

.customize-option.hair_flower_9 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_flower_9.png");
  background-position: -25px -15px;
}

.hair_bangs_1_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_TRUred.png");
}

.customize-option.hair_bangs_1_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_TRUred.png");
  background-position: -25px -15px;
}

.hair_bangs_1_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_aurora.png");
}

.customize-option.hair_bangs_1_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_aurora.png");
  background-position: -25px -15px;
}

.hair_bangs_1_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_black.png");
}

.customize-option.hair_bangs_1_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_black.png");
  background-position: -25px -15px;
}

.hair_bangs_1_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blond.png");
}

.customize-option.hair_bangs_1_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blond.png");
  background-position: -25px -15px;
}

.hair_bangs_1_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blue.png");
}

.customize-option.hair_bangs_1_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_blue.png");
  background-position: -25px -15px;
}

.hair_bangs_1_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_brown.png");
}

.customize-option.hair_bangs_1_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_brown.png");
  background-position: -25px -15px;
}

.hair_bangs_1_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycane.png");
}

.customize-option.hair_bangs_1_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycane.png");
  background-position: -25px -15px;
}

.hair_bangs_1_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycorn.png");
}

.customize-option.hair_bangs_1_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_candycorn.png");
  background-position: -25px -15px;
}

.hair_bangs_1_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_festive.png");
}

.customize-option.hair_bangs_1_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_festive.png");
  background-position: -25px -15px;
}

.hair_bangs_1_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_frost.png");
}

.customize-option.hair_bangs_1_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_frost.png");
  background-position: -25px -15px;
}

.hair_bangs_1_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ghostwhite.png");
}

.customize-option.hair_bangs_1_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_bangs_1_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_green.png");
}

.customize-option.hair_bangs_1_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_green.png");
  background-position: -25px -15px;
}

.hair_bangs_1_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_halloween.png");
}

.customize-option.hair_bangs_1_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_halloween.png");
  background-position: -25px -15px;
}

.hair_bangs_1_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_holly.png");
}

.customize-option.hair_bangs_1_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_holly.png");
  background-position: -25px -15px;
}

.hair_bangs_1_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_hollygreen.png");
}

.customize-option.hair_bangs_1_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_hollygreen.png");
  background-position: -25px -15px;
}

.hair_bangs_1_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_midnight.png");
}

.customize-option.hair_bangs_1_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_midnight.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue.png");
}

.customize-option.hair_bangs_1_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue2.png");
}

.customize-option.hair_bangs_1_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pblue2.png");
  background-position: -25px -15px;
}

.hair_bangs_1_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_peppermint.png");
}

.customize-option.hair_bangs_1_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_peppermint.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen.png");
}

.customize-option.hair_bangs_1_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen2.png");
}

.customize-option.hair_bangs_1_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pgreen2.png");
  background-position: -25px -15px;
}

.hair_bangs_1_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange.png");
}

.customize-option.hair_bangs_1_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange.png");
  background-position: -25px -15px;
}

.hair_bangs_1_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange2.png");
}

.customize-option.hair_bangs_1_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_porange2.png");
  background-position: -25px -15px;
}

.hair_bangs_1_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink.png");
}

.customize-option.hair_bangs_1_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink.png");
  background-position: -25px -15px;
}

.hair_bangs_1_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink2.png");
}

.customize-option.hair_bangs_1_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppink2.png");
  background-position: -25px -15px;
}

.hair_bangs_1_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple.png");
}

.customize-option.hair_bangs_1_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple.png");
  background-position: -25px -15px;
}

.hair_bangs_1_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple2.png");
}

.customize-option.hair_bangs_1_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_ppurple2.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pumpkin.png");
}

.customize-option.hair_bangs_1_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pumpkin.png");
  background-position: -25px -15px;
}

.hair_bangs_1_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_purple.png");
}

.customize-option.hair_bangs_1_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_purple.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow.png");
}

.customize-option.hair_bangs_1_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow.png");
  background-position: -25px -15px;
}

.hair_bangs_1_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow2.png");
}

.customize-option.hair_bangs_1_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_pyellow2.png");
  background-position: -25px -15px;
}

.hair_bangs_1_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_rainbow.png");
}

.customize-option.hair_bangs_1_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_rainbow.png");
  background-position: -25px -15px;
}

.hair_bangs_1_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_red.png");
}

.customize-option.hair_bangs_1_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_red.png");
  background-position: -25px -15px;
}

.hair_bangs_1_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_snowy.png");
}

.customize-option.hair_bangs_1_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_snowy.png");
  background-position: -25px -15px;
}

.hair_bangs_1_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_white.png");
}

.customize-option.hair_bangs_1_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_white.png");
  background-position: -25px -15px;
}

.hair_bangs_1_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winternight.png");
}

.customize-option.hair_bangs_1_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winternight.png");
  background-position: -25px -15px;
}

.hair_bangs_1_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winterstar.png");
}

.customize-option.hair_bangs_1_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_winterstar.png");
  background-position: -25px -15px;
}

.hair_bangs_1_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_yellow.png");
}

.customize-option.hair_bangs_1_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_yellow.png");
  background-position: -25px -15px;
}

.hair_bangs_1_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_zombie.png");
}

.customize-option.hair_bangs_1_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_1_zombie.png");
  background-position: -25px -15px;
}

.hair_bangs_2_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_TRUred.png");
}

.customize-option.hair_bangs_2_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_TRUred.png");
  background-position: -25px -15px;
}

.hair_bangs_2_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_aurora.png");
}

.customize-option.hair_bangs_2_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_aurora.png");
  background-position: -25px -15px;
}

.hair_bangs_2_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_black.png");
}

.customize-option.hair_bangs_2_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_black.png");
  background-position: -25px -15px;
}

.hair_bangs_2_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blond.png");
}

.customize-option.hair_bangs_2_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blond.png");
  background-position: -25px -15px;
}

.hair_bangs_2_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blue.png");
}

.customize-option.hair_bangs_2_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_blue.png");
  background-position: -25px -15px;
}

.hair_bangs_2_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_brown.png");
}

.customize-option.hair_bangs_2_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_brown.png");
  background-position: -25px -15px;
}

.hair_bangs_2_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycane.png");
}

.customize-option.hair_bangs_2_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycane.png");
  background-position: -25px -15px;
}

.hair_bangs_2_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycorn.png");
}

.customize-option.hair_bangs_2_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_candycorn.png");
  background-position: -25px -15px;
}

.hair_bangs_2_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_festive.png");
}

.customize-option.hair_bangs_2_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_festive.png");
  background-position: -25px -15px;
}

.hair_bangs_2_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_frost.png");
}

.customize-option.hair_bangs_2_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_frost.png");
  background-position: -25px -15px;
}

.hair_bangs_2_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ghostwhite.png");
}

.customize-option.hair_bangs_2_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_bangs_2_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_green.png");
}

.customize-option.hair_bangs_2_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_green.png");
  background-position: -25px -15px;
}

.hair_bangs_2_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_halloween.png");
}

.customize-option.hair_bangs_2_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_halloween.png");
  background-position: -25px -15px;
}

.hair_bangs_2_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_holly.png");
}

.customize-option.hair_bangs_2_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_holly.png");
  background-position: -25px -15px;
}

.hair_bangs_2_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_hollygreen.png");
}

.customize-option.hair_bangs_2_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_hollygreen.png");
  background-position: -25px -15px;
}

.hair_bangs_2_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_midnight.png");
}

.customize-option.hair_bangs_2_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_midnight.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue.png");
}

.customize-option.hair_bangs_2_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue2.png");
}

.customize-option.hair_bangs_2_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pblue2.png");
  background-position: -25px -15px;
}

.hair_bangs_2_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_peppermint.png");
}

.customize-option.hair_bangs_2_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_peppermint.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen.png");
}

.customize-option.hair_bangs_2_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen2.png");
}

.customize-option.hair_bangs_2_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pgreen2.png");
  background-position: -25px -15px;
}

.hair_bangs_2_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange.png");
}

.customize-option.hair_bangs_2_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange.png");
  background-position: -25px -15px;
}

.hair_bangs_2_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange2.png");
}

.customize-option.hair_bangs_2_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_porange2.png");
  background-position: -25px -15px;
}

.hair_bangs_2_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink.png");
}

.customize-option.hair_bangs_2_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink.png");
  background-position: -25px -15px;
}

.hair_bangs_2_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink2.png");
}

.customize-option.hair_bangs_2_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppink2.png");
  background-position: -25px -15px;
}

.hair_bangs_2_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple.png");
}

.customize-option.hair_bangs_2_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple.png");
  background-position: -25px -15px;
}

.hair_bangs_2_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple2.png");
}

.customize-option.hair_bangs_2_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_ppurple2.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pumpkin.png");
}

.customize-option.hair_bangs_2_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pumpkin.png");
  background-position: -25px -15px;
}

.hair_bangs_2_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_purple.png");
}

.customize-option.hair_bangs_2_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_purple.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow.png");
}

.customize-option.hair_bangs_2_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow.png");
  background-position: -25px -15px;
}

.hair_bangs_2_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow2.png");
}

.customize-option.hair_bangs_2_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_pyellow2.png");
  background-position: -25px -15px;
}

.hair_bangs_2_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_rainbow.png");
}

.customize-option.hair_bangs_2_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_rainbow.png");
  background-position: -25px -15px;
}

.hair_bangs_2_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_red.png");
}

.customize-option.hair_bangs_2_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_red.png");
  background-position: -25px -15px;
}

.hair_bangs_2_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_snowy.png");
}

.customize-option.hair_bangs_2_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_snowy.png");
  background-position: -25px -15px;
}

.hair_bangs_2_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_white.png");
}

.customize-option.hair_bangs_2_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_white.png");
  background-position: -25px -15px;
}

.hair_bangs_2_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winternight.png");
}

.customize-option.hair_bangs_2_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winternight.png");
  background-position: -25px -15px;
}

.hair_bangs_2_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winterstar.png");
}

.customize-option.hair_bangs_2_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_winterstar.png");
  background-position: -25px -15px;
}

.hair_bangs_2_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_yellow.png");
}

.customize-option.hair_bangs_2_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_yellow.png");
  background-position: -25px -15px;
}

.hair_bangs_2_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_zombie.png");
}

.customize-option.hair_bangs_2_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_2_zombie.png");
  background-position: -25px -15px;
}

.hair_bangs_3_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_TRUred.png");
}

.customize-option.hair_bangs_3_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_TRUred.png");
  background-position: -25px -15px;
}

.hair_bangs_3_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_aurora.png");
}

.customize-option.hair_bangs_3_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_aurora.png");
  background-position: -25px -15px;
}

.hair_bangs_3_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_black.png");
}

.customize-option.hair_bangs_3_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_black.png");
  background-position: -25px -15px;
}

.hair_bangs_3_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blond.png");
}

.customize-option.hair_bangs_3_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blond.png");
  background-position: -25px -15px;
}

.hair_bangs_3_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blue.png");
}

.customize-option.hair_bangs_3_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_blue.png");
  background-position: -25px -15px;
}

.hair_bangs_3_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_brown.png");
}

.customize-option.hair_bangs_3_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_brown.png");
  background-position: -25px -15px;
}

.hair_bangs_3_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycane.png");
}

.customize-option.hair_bangs_3_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycane.png");
  background-position: -25px -15px;
}

.hair_bangs_3_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycorn.png");
}

.customize-option.hair_bangs_3_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_candycorn.png");
  background-position: -25px -15px;
}

.hair_bangs_3_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_festive.png");
}

.customize-option.hair_bangs_3_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_festive.png");
  background-position: -25px -15px;
}

.hair_bangs_3_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_frost.png");
}

.customize-option.hair_bangs_3_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_frost.png");
  background-position: -25px -15px;
}

.hair_bangs_3_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ghostwhite.png");
}

.customize-option.hair_bangs_3_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_bangs_3_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_green.png");
}

.customize-option.hair_bangs_3_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_green.png");
  background-position: -25px -15px;
}

.hair_bangs_3_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_halloween.png");
}

.customize-option.hair_bangs_3_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_halloween.png");
  background-position: -25px -15px;
}

.hair_bangs_3_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_holly.png");
}

.customize-option.hair_bangs_3_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_holly.png");
  background-position: -25px -15px;
}

.hair_bangs_3_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_hollygreen.png");
}

.customize-option.hair_bangs_3_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_hollygreen.png");
  background-position: -25px -15px;
}

.hair_bangs_3_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_midnight.png");
}

.customize-option.hair_bangs_3_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_midnight.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue.png");
}

.customize-option.hair_bangs_3_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue2.png");
}

.customize-option.hair_bangs_3_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pblue2.png");
  background-position: -25px -15px;
}

.hair_bangs_3_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_peppermint.png");
}

.customize-option.hair_bangs_3_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_peppermint.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen.png");
}

.customize-option.hair_bangs_3_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen2.png");
}

.customize-option.hair_bangs_3_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pgreen2.png");
  background-position: -25px -15px;
}

.hair_bangs_3_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange.png");
}

.customize-option.hair_bangs_3_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange.png");
  background-position: -25px -15px;
}

.hair_bangs_3_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange2.png");
}

.customize-option.hair_bangs_3_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_porange2.png");
  background-position: -25px -15px;
}

.hair_bangs_3_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink.png");
}

.customize-option.hair_bangs_3_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink.png");
  background-position: -25px -15px;
}

.hair_bangs_3_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink2.png");
}

.customize-option.hair_bangs_3_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppink2.png");
  background-position: -25px -15px;
}

.hair_bangs_3_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple.png");
}

.customize-option.hair_bangs_3_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple.png");
  background-position: -25px -15px;
}

.hair_bangs_3_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple2.png");
}

.customize-option.hair_bangs_3_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_ppurple2.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pumpkin.png");
}

.customize-option.hair_bangs_3_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pumpkin.png");
  background-position: -25px -15px;
}

.hair_bangs_3_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_purple.png");
}

.customize-option.hair_bangs_3_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_purple.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow.png");
}

.customize-option.hair_bangs_3_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow.png");
  background-position: -25px -15px;
}

.hair_bangs_3_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow2.png");
}

.customize-option.hair_bangs_3_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_pyellow2.png");
  background-position: -25px -15px;
}

.hair_bangs_3_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_rainbow.png");
}

.customize-option.hair_bangs_3_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_rainbow.png");
  background-position: -25px -15px;
}

.hair_bangs_3_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_red.png");
}

.customize-option.hair_bangs_3_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_red.png");
  background-position: -25px -15px;
}

.hair_bangs_3_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_snowy.png");
}

.customize-option.hair_bangs_3_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_snowy.png");
  background-position: -25px -15px;
}

.hair_bangs_3_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_white.png");
}

.customize-option.hair_bangs_3_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_white.png");
  background-position: -25px -15px;
}

.hair_bangs_3_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winternight.png");
}

.customize-option.hair_bangs_3_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winternight.png");
  background-position: -25px -15px;
}

.hair_bangs_3_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winterstar.png");
}

.customize-option.hair_bangs_3_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_winterstar.png");
  background-position: -25px -15px;
}

.hair_bangs_3_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_yellow.png");
}

.customize-option.hair_bangs_3_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_yellow.png");
  background-position: -25px -15px;
}

.hair_bangs_3_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_zombie.png");
}

.customize-option.hair_bangs_3_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_3_zombie.png");
  background-position: -25px -15px;
}

.hair_bangs_4_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_TRUred.png");
}

.customize-option.hair_bangs_4_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_TRUred.png");
  background-position: -25px -15px;
}

.hair_bangs_4_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_aurora.png");
}

.customize-option.hair_bangs_4_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_aurora.png");
  background-position: -25px -15px;
}

.hair_bangs_4_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_black.png");
}

.customize-option.hair_bangs_4_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_black.png");
  background-position: -25px -15px;
}

.hair_bangs_4_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blond.png");
}

.customize-option.hair_bangs_4_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blond.png");
  background-position: -25px -15px;
}

.hair_bangs_4_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blue.png");
}

.customize-option.hair_bangs_4_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_blue.png");
  background-position: -25px -15px;
}

.hair_bangs_4_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_brown.png");
}

.customize-option.hair_bangs_4_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_brown.png");
  background-position: -25px -15px;
}

.hair_bangs_4_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycane.png");
}

.customize-option.hair_bangs_4_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycane.png");
  background-position: -25px -15px;
}

.hair_bangs_4_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycorn.png");
}

.customize-option.hair_bangs_4_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_candycorn.png");
  background-position: -25px -15px;
}

.hair_bangs_4_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_festive.png");
}

.customize-option.hair_bangs_4_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_festive.png");
  background-position: -25px -15px;
}

.hair_bangs_4_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_frost.png");
}

.customize-option.hair_bangs_4_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_frost.png");
  background-position: -25px -15px;
}

.hair_bangs_4_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ghostwhite.png");
}

.customize-option.hair_bangs_4_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_bangs_4_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_green.png");
}

.customize-option.hair_bangs_4_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_green.png");
  background-position: -25px -15px;
}

.hair_bangs_4_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_halloween.png");
}

.customize-option.hair_bangs_4_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_halloween.png");
  background-position: -25px -15px;
}

.hair_bangs_4_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_holly.png");
}

.customize-option.hair_bangs_4_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_holly.png");
  background-position: -25px -15px;
}

.hair_bangs_4_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_hollygreen.png");
}

.customize-option.hair_bangs_4_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_hollygreen.png");
  background-position: -25px -15px;
}

.hair_bangs_4_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_midnight.png");
}

.customize-option.hair_bangs_4_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_midnight.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue.png");
}

.customize-option.hair_bangs_4_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue2.png");
}

.customize-option.hair_bangs_4_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pblue2.png");
  background-position: -25px -15px;
}

.hair_bangs_4_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_peppermint.png");
}

.customize-option.hair_bangs_4_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_peppermint.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen.png");
}

.customize-option.hair_bangs_4_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen2.png");
}

.customize-option.hair_bangs_4_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pgreen2.png");
  background-position: -25px -15px;
}

.hair_bangs_4_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange.png");
}

.customize-option.hair_bangs_4_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange.png");
  background-position: -25px -15px;
}

.hair_bangs_4_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange2.png");
}

.customize-option.hair_bangs_4_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_porange2.png");
  background-position: -25px -15px;
}

.hair_bangs_4_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink.png");
}

.customize-option.hair_bangs_4_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink.png");
  background-position: -25px -15px;
}

.hair_bangs_4_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink2.png");
}

.customize-option.hair_bangs_4_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppink2.png");
  background-position: -25px -15px;
}

.hair_bangs_4_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple.png");
}

.customize-option.hair_bangs_4_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple.png");
  background-position: -25px -15px;
}

.hair_bangs_4_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple2.png");
}

.customize-option.hair_bangs_4_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_ppurple2.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pumpkin.png");
}

.customize-option.hair_bangs_4_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pumpkin.png");
  background-position: -25px -15px;
}

.hair_bangs_4_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_purple.png");
}

.customize-option.hair_bangs_4_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_purple.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow.png");
}

.customize-option.hair_bangs_4_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow.png");
  background-position: -25px -15px;
}

.hair_bangs_4_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow2.png");
}

.customize-option.hair_bangs_4_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_pyellow2.png");
  background-position: -25px -15px;
}

.hair_bangs_4_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_rainbow.png");
}

.customize-option.hair_bangs_4_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_rainbow.png");
  background-position: -25px -15px;
}

.hair_bangs_4_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_red.png");
}

.customize-option.hair_bangs_4_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_red.png");
  background-position: -25px -15px;
}

.hair_bangs_4_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_snowy.png");
}

.customize-option.hair_bangs_4_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_snowy.png");
  background-position: -25px -15px;
}

.hair_bangs_4_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_white.png");
}

.customize-option.hair_bangs_4_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_white.png");
  background-position: -25px -15px;
}

.hair_bangs_4_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winternight.png");
}

.customize-option.hair_bangs_4_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winternight.png");
  background-position: -25px -15px;
}

.hair_bangs_4_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winterstar.png");
}

.customize-option.hair_bangs_4_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_winterstar.png");
  background-position: -25px -15px;
}

.hair_bangs_4_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_yellow.png");
}

.customize-option.hair_bangs_4_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_yellow.png");
  background-position: -25px -15px;
}

.hair_bangs_4_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_zombie.png");
}

.customize-option.hair_bangs_4_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_bangs_4_zombie.png");
  background-position: -25px -15px;
}

.hair_base_10_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_TRUred.png");
}

.customize-option.hair_base_10_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_10_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_aurora.png");
}

.customize-option.hair_base_10_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_aurora.png");
  background-position: -25px -15px;
}

.hair_base_10_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_black.png");
}

.customize-option.hair_base_10_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_black.png");
  background-position: -25px -15px;
}

.hair_base_10_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blond.png");
}

.customize-option.hair_base_10_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blond.png");
  background-position: -25px -15px;
}

.hair_base_10_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blue.png");
}

.customize-option.hair_base_10_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_blue.png");
  background-position: -25px -15px;
}

.hair_base_10_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_brown.png");
}

.customize-option.hair_base_10_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_brown.png");
  background-position: -25px -15px;
}

.hair_base_10_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycane.png");
}

.customize-option.hair_base_10_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycane.png");
  background-position: -25px -15px;
}

.hair_base_10_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycorn.png");
}

.customize-option.hair_base_10_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_10_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_festive.png");
}

.customize-option.hair_base_10_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_festive.png");
  background-position: -25px -15px;
}

.hair_base_10_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_frost.png");
}

.customize-option.hair_base_10_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_frost.png");
  background-position: -25px -15px;
}

.hair_base_10_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ghostwhite.png");
}

.customize-option.hair_base_10_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_10_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_green.png");
}

.customize-option.hair_base_10_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_green.png");
  background-position: -25px -15px;
}

.hair_base_10_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_halloween.png");
}

.customize-option.hair_base_10_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_halloween.png");
  background-position: -25px -15px;
}

.hair_base_10_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_holly.png");
}

.customize-option.hair_base_10_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_holly.png");
  background-position: -25px -15px;
}

.hair_base_10_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_hollygreen.png");
}

.customize-option.hair_base_10_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_10_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_midnight.png");
}

.customize-option.hair_base_10_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_midnight.png");
  background-position: -25px -15px;
}

.hair_base_10_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue.png");
}

.customize-option.hair_base_10_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue.png");
  background-position: -25px -15px;
}

.hair_base_10_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue2.png");
}

.customize-option.hair_base_10_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_10_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_peppermint.png");
}

.customize-option.hair_base_10_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_10_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen.png");
}

.customize-option.hair_base_10_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_10_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen2.png");
}

.customize-option.hair_base_10_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_10_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange.png");
}

.customize-option.hair_base_10_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange.png");
  background-position: -25px -15px;
}

.hair_base_10_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange2.png");
}

.customize-option.hair_base_10_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_porange2.png");
  background-position: -25px -15px;
}

.hair_base_10_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink.png");
}

.customize-option.hair_base_10_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink.png");
  background-position: -25px -15px;
}

.hair_base_10_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink2.png");
}

.customize-option.hair_base_10_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_10_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple.png");
}

.customize-option.hair_base_10_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_10_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple2.png");
}

.customize-option.hair_base_10_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_10_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pumpkin.png");
}

.customize-option.hair_base_10_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_10_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_purple.png");
}

.customize-option.hair_base_10_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_purple.png");
  background-position: -25px -15px;
}

.hair_base_10_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow.png");
}

.customize-option.hair_base_10_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_10_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow2.png");
}

.customize-option.hair_base_10_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_10_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_rainbow.png");
}

.customize-option.hair_base_10_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_10_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_red.png");
}

.customize-option.hair_base_10_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_red.png");
  background-position: -25px -15px;
}

.hair_base_10_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_snowy.png");
}

.customize-option.hair_base_10_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_snowy.png");
  background-position: -25px -15px;
}

.hair_base_10_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_white.png");
}

.customize-option.hair_base_10_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_white.png");
  background-position: -25px -15px;
}

.hair_base_10_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winternight.png");
}

.customize-option.hair_base_10_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winternight.png");
  background-position: -25px -15px;
}

.hair_base_10_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winterstar.png");
}

.customize-option.hair_base_10_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_10_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_yellow.png");
}

.customize-option.hair_base_10_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_yellow.png");
  background-position: -25px -15px;
}

.hair_base_10_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_zombie.png");
}

.customize-option.hair_base_10_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_10_zombie.png");
  background-position: -25px -15px;
}

.hair_base_11_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_TRUred.png");
}

.customize-option.hair_base_11_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_11_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_aurora.png");
}

.customize-option.hair_base_11_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_aurora.png");
  background-position: -25px -15px;
}

.hair_base_11_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_black.png");
}

.customize-option.hair_base_11_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_black.png");
  background-position: -25px -15px;
}

.hair_base_11_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blond.png");
}

.customize-option.hair_base_11_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blond.png");
  background-position: -25px -15px;
}

.hair_base_11_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blue.png");
}

.customize-option.hair_base_11_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_blue.png");
  background-position: -25px -15px;
}

.hair_base_11_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_brown.png");
}

.customize-option.hair_base_11_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_brown.png");
  background-position: -25px -15px;
}

.hair_base_11_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycane.png");
}

.customize-option.hair_base_11_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycane.png");
  background-position: -25px -15px;
}

.hair_base_11_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycorn.png");
}

.customize-option.hair_base_11_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_11_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_festive.png");
}

.customize-option.hair_base_11_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_festive.png");
  background-position: -25px -15px;
}

.hair_base_11_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_frost.png");
}

.customize-option.hair_base_11_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_frost.png");
  background-position: -25px -15px;
}

.hair_base_11_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ghostwhite.png");
}

.customize-option.hair_base_11_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_11_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_green.png");
}

.customize-option.hair_base_11_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_green.png");
  background-position: -25px -15px;
}

.hair_base_11_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_halloween.png");
}

.customize-option.hair_base_11_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_halloween.png");
  background-position: -25px -15px;
}

.hair_base_11_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_holly.png");
}

.customize-option.hair_base_11_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_holly.png");
  background-position: -25px -15px;
}

.hair_base_11_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_hollygreen.png");
}

.customize-option.hair_base_11_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_11_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_midnight.png");
}

.customize-option.hair_base_11_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_midnight.png");
  background-position: -25px -15px;
}

.hair_base_11_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue.png");
}

.customize-option.hair_base_11_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue.png");
  background-position: -25px -15px;
}

.hair_base_11_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue2.png");
}

.customize-option.hair_base_11_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_11_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_peppermint.png");
}

.customize-option.hair_base_11_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_11_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen.png");
}

.customize-option.hair_base_11_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_11_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen2.png");
}

.customize-option.hair_base_11_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_11_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange.png");
}

.customize-option.hair_base_11_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange.png");
  background-position: -25px -15px;
}

.hair_base_11_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange2.png");
}

.customize-option.hair_base_11_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_porange2.png");
  background-position: -25px -15px;
}

.hair_base_11_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink.png");
}

.customize-option.hair_base_11_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink.png");
  background-position: -25px -15px;
}

.hair_base_11_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink2.png");
}

.customize-option.hair_base_11_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_11_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple.png");
}

.customize-option.hair_base_11_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_11_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple2.png");
}

.customize-option.hair_base_11_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_11_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pumpkin.png");
}

.customize-option.hair_base_11_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_11_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_purple.png");
}

.customize-option.hair_base_11_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_purple.png");
  background-position: -25px -15px;
}

.hair_base_11_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow.png");
}

.customize-option.hair_base_11_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_11_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow2.png");
}

.customize-option.hair_base_11_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_11_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_rainbow.png");
}

.customize-option.hair_base_11_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_11_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_red.png");
}

.customize-option.hair_base_11_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_red.png");
  background-position: -25px -15px;
}

.hair_base_11_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_snowy.png");
}

.customize-option.hair_base_11_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_snowy.png");
  background-position: -25px -15px;
}

.hair_base_11_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_white.png");
}

.customize-option.hair_base_11_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_white.png");
  background-position: -25px -15px;
}

.hair_base_11_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winternight.png");
}

.customize-option.hair_base_11_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winternight.png");
  background-position: -25px -15px;
}

.hair_base_11_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winterstar.png");
}

.customize-option.hair_base_11_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_11_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_yellow.png");
}

.customize-option.hair_base_11_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_yellow.png");
  background-position: -25px -15px;
}

.hair_base_11_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_zombie.png");
}

.customize-option.hair_base_11_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_11_zombie.png");
  background-position: -25px -15px;
}

.hair_base_12_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_TRUred.png");
}

.customize-option.hair_base_12_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_12_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_aurora.png");
}

.customize-option.hair_base_12_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_aurora.png");
  background-position: -25px -15px;
}

.hair_base_12_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_black.png");
}

.customize-option.hair_base_12_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_black.png");
  background-position: -25px -15px;
}

.hair_base_12_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blond.png");
}

.customize-option.hair_base_12_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blond.png");
  background-position: -25px -15px;
}

.hair_base_12_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blue.png");
}

.customize-option.hair_base_12_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_blue.png");
  background-position: -25px -15px;
}

.hair_base_12_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_brown.png");
}

.customize-option.hair_base_12_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_brown.png");
  background-position: -25px -15px;
}

.hair_base_12_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycane.png");
}

.customize-option.hair_base_12_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycane.png");
  background-position: -25px -15px;
}

.hair_base_12_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycorn.png");
}

.customize-option.hair_base_12_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_12_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_festive.png");
}

.customize-option.hair_base_12_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_festive.png");
  background-position: -25px -15px;
}

.hair_base_12_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_frost.png");
}

.customize-option.hair_base_12_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_frost.png");
  background-position: -25px -15px;
}

.hair_base_12_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ghostwhite.png");
}

.customize-option.hair_base_12_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_12_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_green.png");
}

.customize-option.hair_base_12_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_green.png");
  background-position: -25px -15px;
}

.hair_base_12_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_halloween.png");
}

.customize-option.hair_base_12_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_halloween.png");
  background-position: -25px -15px;
}

.hair_base_12_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_holly.png");
}

.customize-option.hair_base_12_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_holly.png");
  background-position: -25px -15px;
}

.hair_base_12_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_hollygreen.png");
}

.customize-option.hair_base_12_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_12_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_midnight.png");
}

.customize-option.hair_base_12_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_midnight.png");
  background-position: -25px -15px;
}

.hair_base_12_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue.png");
}

.customize-option.hair_base_12_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue.png");
  background-position: -25px -15px;
}

.hair_base_12_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue2.png");
}

.customize-option.hair_base_12_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_12_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_peppermint.png");
}

.customize-option.hair_base_12_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_12_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen.png");
}

.customize-option.hair_base_12_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_12_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen2.png");
}

.customize-option.hair_base_12_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_12_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange.png");
}

.customize-option.hair_base_12_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange.png");
  background-position: -25px -15px;
}

.hair_base_12_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange2.png");
}

.customize-option.hair_base_12_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_porange2.png");
  background-position: -25px -15px;
}

.hair_base_12_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink.png");
}

.customize-option.hair_base_12_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink.png");
  background-position: -25px -15px;
}

.hair_base_12_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink2.png");
}

.customize-option.hair_base_12_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_12_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple.png");
}

.customize-option.hair_base_12_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_12_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple2.png");
}

.customize-option.hair_base_12_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_12_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pumpkin.png");
}

.customize-option.hair_base_12_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_12_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_purple.png");
}

.customize-option.hair_base_12_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_purple.png");
  background-position: -25px -15px;
}

.hair_base_12_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow.png");
}

.customize-option.hair_base_12_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_12_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow2.png");
}

.customize-option.hair_base_12_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_12_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_rainbow.png");
}

.customize-option.hair_base_12_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_12_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_red.png");
}

.customize-option.hair_base_12_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_red.png");
  background-position: -25px -15px;
}

.hair_base_12_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_snowy.png");
}

.customize-option.hair_base_12_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_snowy.png");
  background-position: -25px -15px;
}

.hair_base_12_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_white.png");
}

.customize-option.hair_base_12_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_white.png");
  background-position: -25px -15px;
}

.hair_base_12_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winternight.png");
}

.customize-option.hair_base_12_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winternight.png");
  background-position: -25px -15px;
}

.hair_base_12_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winterstar.png");
}

.customize-option.hair_base_12_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_12_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_yellow.png");
}

.customize-option.hair_base_12_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_yellow.png");
  background-position: -25px -15px;
}

.hair_base_12_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_zombie.png");
}

.customize-option.hair_base_12_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_12_zombie.png");
  background-position: -25px -15px;
}

.hair_base_13_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_TRUred.png");
}

.customize-option.hair_base_13_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_13_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_aurora.png");
}

.customize-option.hair_base_13_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_aurora.png");
  background-position: -25px -15px;
}

.hair_base_13_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_black.png");
}

.customize-option.hair_base_13_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_black.png");
  background-position: -25px -15px;
}

.hair_base_13_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blond.png");
}

.customize-option.hair_base_13_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blond.png");
  background-position: -25px -15px;
}

.hair_base_13_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blue.png");
}

.customize-option.hair_base_13_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_blue.png");
  background-position: -25px -15px;
}

.hair_base_13_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_brown.png");
}

.customize-option.hair_base_13_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_brown.png");
  background-position: -25px -15px;
}

.hair_base_13_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycane.png");
}

.customize-option.hair_base_13_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycane.png");
  background-position: -25px -15px;
}

.hair_base_13_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycorn.png");
}

.customize-option.hair_base_13_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_13_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_festive.png");
}

.customize-option.hair_base_13_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_festive.png");
  background-position: -25px -15px;
}

.hair_base_13_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_frost.png");
}

.customize-option.hair_base_13_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_frost.png");
  background-position: -25px -15px;
}

.hair_base_13_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ghostwhite.png");
}

.customize-option.hair_base_13_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_13_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_green.png");
}

.customize-option.hair_base_13_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_green.png");
  background-position: -25px -15px;
}

.hair_base_13_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_halloween.png");
}

.customize-option.hair_base_13_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_halloween.png");
  background-position: -25px -15px;
}

.hair_base_13_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_holly.png");
}

.customize-option.hair_base_13_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_holly.png");
  background-position: -25px -15px;
}

.hair_base_13_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_hollygreen.png");
}

.customize-option.hair_base_13_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_13_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_midnight.png");
}

.customize-option.hair_base_13_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_midnight.png");
  background-position: -25px -15px;
}

.hair_base_13_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue.png");
}

.customize-option.hair_base_13_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue.png");
  background-position: -25px -15px;
}

.hair_base_13_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue2.png");
}

.customize-option.hair_base_13_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_13_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_peppermint.png");
}

.customize-option.hair_base_13_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_13_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen.png");
}

.customize-option.hair_base_13_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_13_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen2.png");
}

.customize-option.hair_base_13_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_13_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange.png");
}

.customize-option.hair_base_13_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange.png");
  background-position: -25px -15px;
}

.hair_base_13_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange2.png");
}

.customize-option.hair_base_13_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_porange2.png");
  background-position: -25px -15px;
}

.hair_base_13_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink.png");
}

.customize-option.hair_base_13_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink.png");
  background-position: -25px -15px;
}

.hair_base_13_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink2.png");
}

.customize-option.hair_base_13_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_13_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple.png");
}

.customize-option.hair_base_13_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_13_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple2.png");
}

.customize-option.hair_base_13_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_13_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pumpkin.png");
}

.customize-option.hair_base_13_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_13_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_purple.png");
}

.customize-option.hair_base_13_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_purple.png");
  background-position: -25px -15px;
}

.hair_base_13_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow.png");
}

.customize-option.hair_base_13_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_13_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow2.png");
}

.customize-option.hair_base_13_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_13_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_rainbow.png");
}

.customize-option.hair_base_13_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_13_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_red.png");
}

.customize-option.hair_base_13_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_red.png");
  background-position: -25px -15px;
}

.hair_base_13_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_snowy.png");
}

.customize-option.hair_base_13_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_snowy.png");
  background-position: -25px -15px;
}

.hair_base_13_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_white.png");
}

.customize-option.hair_base_13_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_white.png");
  background-position: -25px -15px;
}

.hair_base_13_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winternight.png");
}

.customize-option.hair_base_13_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winternight.png");
  background-position: -25px -15px;
}

.hair_base_13_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winterstar.png");
}

.customize-option.hair_base_13_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_13_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_yellow.png");
}

.customize-option.hair_base_13_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_yellow.png");
  background-position: -25px -15px;
}

.hair_base_13_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_zombie.png");
}

.customize-option.hair_base_13_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_13_zombie.png");
  background-position: -25px -15px;
}

.hair_base_14_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_TRUred.png");
}

.customize-option.hair_base_14_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_14_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_aurora.png");
}

.customize-option.hair_base_14_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_aurora.png");
  background-position: -25px -15px;
}

.hair_base_14_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_black.png");
}

.customize-option.hair_base_14_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_black.png");
  background-position: -25px -15px;
}

.hair_base_14_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blond.png");
}

.customize-option.hair_base_14_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blond.png");
  background-position: -25px -15px;
}

.hair_base_14_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blue.png");
}

.customize-option.hair_base_14_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_blue.png");
  background-position: -25px -15px;
}

.hair_base_14_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_brown.png");
}

.customize-option.hair_base_14_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_brown.png");
  background-position: -25px -15px;
}

.hair_base_14_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycane.png");
}

.customize-option.hair_base_14_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycane.png");
  background-position: -25px -15px;
}

.hair_base_14_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycorn.png");
}

.customize-option.hair_base_14_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_14_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_festive.png");
}

.customize-option.hair_base_14_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_festive.png");
  background-position: -25px -15px;
}

.hair_base_14_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_frost.png");
}

.customize-option.hair_base_14_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_frost.png");
  background-position: -25px -15px;
}

.hair_base_14_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ghostwhite.png");
}

.customize-option.hair_base_14_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_14_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_green.png");
}

.customize-option.hair_base_14_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_green.png");
  background-position: -25px -15px;
}

.hair_base_14_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_halloween.png");
}

.customize-option.hair_base_14_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_halloween.png");
  background-position: -25px -15px;
}

.hair_base_14_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_holly.png");
}

.customize-option.hair_base_14_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_holly.png");
  background-position: -25px -15px;
}

.hair_base_14_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_hollygreen.png");
}

.customize-option.hair_base_14_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_14_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_midnight.png");
}

.customize-option.hair_base_14_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_midnight.png");
  background-position: -25px -15px;
}

.hair_base_14_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue.png");
}

.customize-option.hair_base_14_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue.png");
  background-position: -25px -15px;
}

.hair_base_14_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue2.png");
}

.customize-option.hair_base_14_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_14_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_peppermint.png");
}

.customize-option.hair_base_14_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_14_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen.png");
}

.customize-option.hair_base_14_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_14_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen2.png");
}

.customize-option.hair_base_14_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_14_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange.png");
}

.customize-option.hair_base_14_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange.png");
  background-position: -25px -15px;
}

.hair_base_14_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange2.png");
}

.customize-option.hair_base_14_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_porange2.png");
  background-position: -25px -15px;
}

.hair_base_14_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink.png");
}

.customize-option.hair_base_14_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink.png");
  background-position: -25px -15px;
}

.hair_base_14_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink2.png");
}

.customize-option.hair_base_14_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_14_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple.png");
}

.customize-option.hair_base_14_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_14_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple2.png");
}

.customize-option.hair_base_14_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_14_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pumpkin.png");
}

.customize-option.hair_base_14_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_14_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_purple.png");
}

.customize-option.hair_base_14_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_purple.png");
  background-position: -25px -15px;
}

.hair_base_14_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow.png");
}

.customize-option.hair_base_14_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_14_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow2.png");
}

.customize-option.hair_base_14_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_14_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_rainbow.png");
}

.customize-option.hair_base_14_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_14_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_red.png");
}

.customize-option.hair_base_14_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_red.png");
  background-position: -25px -15px;
}

.hair_base_14_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_snowy.png");
}

.customize-option.hair_base_14_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_snowy.png");
  background-position: -25px -15px;
}

.hair_base_14_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_white.png");
}

.customize-option.hair_base_14_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_white.png");
  background-position: -25px -15px;
}

.hair_base_14_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winternight.png");
}

.customize-option.hair_base_14_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winternight.png");
  background-position: -25px -15px;
}

.hair_base_14_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winterstar.png");
}

.customize-option.hair_base_14_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_14_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_yellow.png");
}

.customize-option.hair_base_14_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_yellow.png");
  background-position: -25px -15px;
}

.hair_base_14_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_zombie.png");
}

.customize-option.hair_base_14_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_14_zombie.png");
  background-position: -25px -15px;
}

.hair_base_15_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_TRUred.png");
}

.customize-option.hair_base_15_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_TRUred.png");
  background-position: -25px 0;
}

.hair_base_15_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_aurora.png");
}

.customize-option.hair_base_15_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_aurora.png");
  background-position: -25px 0;
}

.hair_base_15_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_black.png");
}

.customize-option.hair_base_15_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_black.png");
  background-position: -25px 0;
}

.hair_base_15_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blond.png");
}

.customize-option.hair_base_15_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blond.png");
  background-position: -25px 0;
}

.hair_base_15_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blue.png");
}

.customize-option.hair_base_15_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_blue.png");
  background-position: -25px 0;
}

.hair_base_15_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_brown.png");
}

.customize-option.hair_base_15_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_brown.png");
  background-position: -25px 0;
}

.hair_base_15_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycane.png");
}

.customize-option.hair_base_15_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycane.png");
  background-position: -25px 0;
}

.hair_base_15_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycorn.png");
}

.customize-option.hair_base_15_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_candycorn.png");
  background-position: -25px 0;
}

.hair_base_15_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_festive.png");
}

.customize-option.hair_base_15_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_festive.png");
  background-position: -25px 0;
}

.hair_base_15_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_frost.png");
}

.customize-option.hair_base_15_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_frost.png");
  background-position: -25px 0;
}

.hair_base_15_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ghostwhite.png");
}

.customize-option.hair_base_15_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ghostwhite.png");
  background-position: -25px 0;
}

.hair_base_15_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_green.png");
}

.customize-option.hair_base_15_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_green.png");
  background-position: -25px 0;
}

.hair_base_15_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_halloween.png");
}

.customize-option.hair_base_15_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_halloween.png");
  background-position: -25px 0;
}

.hair_base_15_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_holly.png");
}

.customize-option.hair_base_15_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_holly.png");
  background-position: -25px 0;
}

.hair_base_15_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_hollygreen.png");
}

.customize-option.hair_base_15_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_hollygreen.png");
  background-position: -25px 0;
}

.hair_base_15_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_midnight.png");
}

.customize-option.hair_base_15_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_midnight.png");
  background-position: -25px 0;
}

.hair_base_15_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue.png");
}

.customize-option.hair_base_15_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue.png");
  background-position: -25px 0;
}

.hair_base_15_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue2.png");
}

.customize-option.hair_base_15_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pblue2.png");
  background-position: -25px 0;
}

.hair_base_15_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_peppermint.png");
}

.customize-option.hair_base_15_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_peppermint.png");
  background-position: -25px 0;
}

.hair_base_15_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen.png");
}

.customize-option.hair_base_15_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen.png");
  background-position: -25px 0;
}

.hair_base_15_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen2.png");
}

.customize-option.hair_base_15_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pgreen2.png");
  background-position: -25px 0;
}

.hair_base_15_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange.png");
}

.customize-option.hair_base_15_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange.png");
  background-position: -25px 0;
}

.hair_base_15_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange2.png");
}

.customize-option.hair_base_15_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_porange2.png");
  background-position: -25px 0;
}

.hair_base_15_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink.png");
}

.customize-option.hair_base_15_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink.png");
  background-position: -25px 0;
}

.hair_base_15_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink2.png");
}

.customize-option.hair_base_15_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppink2.png");
  background-position: -25px 0;
}

.hair_base_15_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple.png");
}

.customize-option.hair_base_15_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple.png");
  background-position: -25px 0;
}

.hair_base_15_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple2.png");
}

.customize-option.hair_base_15_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_ppurple2.png");
  background-position: -25px 0;
}

.hair_base_15_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pumpkin.png");
}

.customize-option.hair_base_15_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pumpkin.png");
  background-position: -25px 0;
}

.hair_base_15_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_purple.png");
}

.customize-option.hair_base_15_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_purple.png");
  background-position: -25px 0;
}

.hair_base_15_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow.png");
}

.customize-option.hair_base_15_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow.png");
  background-position: -25px 0;
}

.hair_base_15_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow2.png");
}

.customize-option.hair_base_15_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_pyellow2.png");
  background-position: -25px 0;
}

.hair_base_15_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_rainbow.png");
}

.customize-option.hair_base_15_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_rainbow.png");
  background-position: -25px 0;
}

.hair_base_15_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_red.png");
}

.customize-option.hair_base_15_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_red.png");
  background-position: -25px 0;
}

.hair_base_15_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_snowy.png");
}

.customize-option.hair_base_15_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_snowy.png");
  background-position: -25px 0;
}

.hair_base_15_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_white.png");
}

.customize-option.hair_base_15_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_white.png");
  background-position: -25px 0;
}

.hair_base_15_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winternight.png");
}

.customize-option.hair_base_15_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winternight.png");
  background-position: -25px 0;
}

.hair_base_15_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winterstar.png");
}

.customize-option.hair_base_15_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_winterstar.png");
  background-position: -25px 0;
}

.hair_base_15_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_yellow.png");
}

.customize-option.hair_base_15_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_yellow.png");
  background-position: -25px 0;
}

.hair_base_15_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_zombie.png");
}

.customize-option.hair_base_15_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_15_zombie.png");
  background-position: -25px 0;
}

.hair_base_16_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_TRUred.png");
}

.customize-option.hair_base_16_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_TRUred.png");
  background-position: -25px 0;
}

.hair_base_16_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_aurora.png");
}

.customize-option.hair_base_16_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_aurora.png");
  background-position: -25px 0;
}

.hair_base_16_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_black.png");
}

.customize-option.hair_base_16_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_black.png");
  background-position: -25px 0;
}

.hair_base_16_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blond.png");
}

.customize-option.hair_base_16_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blond.png");
  background-position: -25px 0;
}

.hair_base_16_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blue.png");
}

.customize-option.hair_base_16_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_blue.png");
  background-position: -25px 0;
}

.hair_base_16_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_brown.png");
}

.customize-option.hair_base_16_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_brown.png");
  background-position: -25px 0;
}

.hair_base_16_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycane.png");
}

.customize-option.hair_base_16_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycane.png");
  background-position: -25px 0;
}

.hair_base_16_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycorn.png");
}

.customize-option.hair_base_16_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_candycorn.png");
  background-position: -25px 0;
}

.hair_base_16_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_festive.png");
}

.customize-option.hair_base_16_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_festive.png");
  background-position: -25px 0;
}

.hair_base_16_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_frost.png");
}

.customize-option.hair_base_16_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_frost.png");
  background-position: -25px 0;
}

.hair_base_16_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ghostwhite.png");
}

.customize-option.hair_base_16_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ghostwhite.png");
  background-position: -25px 0;
}

.hair_base_16_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_green.png");
}

.customize-option.hair_base_16_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_green.png");
  background-position: -25px 0;
}

.hair_base_16_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_halloween.png");
}

.customize-option.hair_base_16_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_halloween.png");
  background-position: -25px 0;
}

.hair_base_16_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_holly.png");
}

.customize-option.hair_base_16_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_holly.png");
  background-position: -25px 0;
}

.hair_base_16_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_hollygreen.png");
}

.customize-option.hair_base_16_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_hollygreen.png");
  background-position: -25px 0;
}

.hair_base_16_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_midnight.png");
}

.customize-option.hair_base_16_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_midnight.png");
  background-position: -25px 0;
}

.hair_base_16_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue.png");
}

.customize-option.hair_base_16_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue.png");
  background-position: -25px 0;
}

.hair_base_16_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue2.png");
}

.customize-option.hair_base_16_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pblue2.png");
  background-position: -25px 0;
}

.hair_base_16_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_peppermint.png");
}

.customize-option.hair_base_16_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_peppermint.png");
  background-position: -25px 0;
}

.hair_base_16_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen.png");
}

.customize-option.hair_base_16_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen.png");
  background-position: -25px 0;
}

.hair_base_16_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen2.png");
}

.customize-option.hair_base_16_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pgreen2.png");
  background-position: -25px 0;
}

.hair_base_16_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange.png");
}

.customize-option.hair_base_16_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange.png");
  background-position: -25px 0;
}

.hair_base_16_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange2.png");
}

.customize-option.hair_base_16_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_porange2.png");
  background-position: -25px 0;
}

.hair_base_16_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink.png");
}

.customize-option.hair_base_16_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink.png");
  background-position: -25px 0;
}

.hair_base_16_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink2.png");
}

.customize-option.hair_base_16_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppink2.png");
  background-position: -25px 0;
}

.hair_base_16_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple.png");
}

.customize-option.hair_base_16_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple.png");
  background-position: -25px 0;
}

.hair_base_16_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple2.png");
}

.customize-option.hair_base_16_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_ppurple2.png");
  background-position: -25px 0;
}

.hair_base_16_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pumpkin.png");
}

.customize-option.hair_base_16_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pumpkin.png");
  background-position: -25px 0;
}

.hair_base_16_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_purple.png");
}

.customize-option.hair_base_16_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_purple.png");
  background-position: -25px 0;
}

.hair_base_16_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow.png");
}

.customize-option.hair_base_16_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow.png");
  background-position: -25px 0;
}

.hair_base_16_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow2.png");
}

.customize-option.hair_base_16_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_pyellow2.png");
  background-position: -25px 0;
}

.hair_base_16_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_rainbow.png");
}

.customize-option.hair_base_16_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_rainbow.png");
  background-position: -25px 0;
}

.hair_base_16_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_red.png");
}

.customize-option.hair_base_16_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_red.png");
  background-position: -25px 0;
}

.hair_base_16_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_snowy.png");
}

.customize-option.hair_base_16_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_snowy.png");
  background-position: -25px 0;
}

.hair_base_16_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_white.png");
}

.customize-option.hair_base_16_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_white.png");
  background-position: -25px 0;
}

.hair_base_16_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winternight.png");
}

.customize-option.hair_base_16_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winternight.png");
  background-position: -25px 0;
}

.hair_base_16_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winterstar.png");
}

.customize-option.hair_base_16_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_winterstar.png");
  background-position: -25px 0;
}

.hair_base_16_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_yellow.png");
}

.customize-option.hair_base_16_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_yellow.png");
  background-position: -25px 0;
}

.hair_base_16_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_zombie.png");
}

.customize-option.hair_base_16_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_16_zombie.png");
  background-position: -25px 0;
}

.hair_base_17_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_TRUred.png");
}

.customize-option.hair_base_17_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_TRUred.png");
  background-position: -25px 0;
}

.hair_base_17_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_aurora.png");
}

.customize-option.hair_base_17_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_aurora.png");
  background-position: -25px 0;
}

.hair_base_17_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_black.png");
}

.customize-option.hair_base_17_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_black.png");
  background-position: -25px 0;
}

.hair_base_17_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blond.png");
}

.customize-option.hair_base_17_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blond.png");
  background-position: -25px 0;
}

.hair_base_17_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blue.png");
}

.customize-option.hair_base_17_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_blue.png");
  background-position: -25px 0;
}

.hair_base_17_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_brown.png");
}

.customize-option.hair_base_17_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_brown.png");
  background-position: -25px 0;
}

.hair_base_17_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycane.png");
}

.customize-option.hair_base_17_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycane.png");
  background-position: -25px 0;
}

.hair_base_17_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycorn.png");
}

.customize-option.hair_base_17_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_candycorn.png");
  background-position: -25px 0;
}

.hair_base_17_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_festive.png");
}

.customize-option.hair_base_17_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_festive.png");
  background-position: -25px 0;
}

.hair_base_17_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_frost.png");
}

.customize-option.hair_base_17_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_frost.png");
  background-position: -25px 0;
}

.hair_base_17_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ghostwhite.png");
}

.customize-option.hair_base_17_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ghostwhite.png");
  background-position: -25px 0;
}

.hair_base_17_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_green.png");
}

.customize-option.hair_base_17_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_green.png");
  background-position: -25px 0;
}

.hair_base_17_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_halloween.png");
}

.customize-option.hair_base_17_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_halloween.png");
  background-position: -25px 0;
}

.hair_base_17_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_holly.png");
}

.customize-option.hair_base_17_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_holly.png");
  background-position: -25px 0;
}

.hair_base_17_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_hollygreen.png");
}

.customize-option.hair_base_17_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_hollygreen.png");
  background-position: -25px 0;
}

.hair_base_17_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_midnight.png");
}

.customize-option.hair_base_17_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_midnight.png");
  background-position: -25px 0;
}

.hair_base_17_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue.png");
}

.customize-option.hair_base_17_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue.png");
  background-position: -25px 0;
}

.hair_base_17_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue2.png");
}

.customize-option.hair_base_17_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pblue2.png");
  background-position: -25px 0;
}

.hair_base_17_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_peppermint.png");
}

.customize-option.hair_base_17_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_peppermint.png");
  background-position: -25px 0;
}

.hair_base_17_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen.png");
}

.customize-option.hair_base_17_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen.png");
  background-position: -25px 0;
}

.hair_base_17_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen2.png");
}

.customize-option.hair_base_17_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pgreen2.png");
  background-position: -25px 0;
}

.hair_base_17_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange.png");
}

.customize-option.hair_base_17_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange.png");
  background-position: -25px 0;
}

.hair_base_17_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange2.png");
}

.customize-option.hair_base_17_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_porange2.png");
  background-position: -25px 0;
}

.hair_base_17_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink.png");
}

.customize-option.hair_base_17_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink.png");
  background-position: -25px 0;
}

.hair_base_17_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink2.png");
}

.customize-option.hair_base_17_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppink2.png");
  background-position: -25px 0;
}

.hair_base_17_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple.png");
}

.customize-option.hair_base_17_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple.png");
  background-position: -25px 0;
}

.hair_base_17_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple2.png");
}

.customize-option.hair_base_17_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_ppurple2.png");
  background-position: -25px 0;
}

.hair_base_17_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pumpkin.png");
}

.customize-option.hair_base_17_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pumpkin.png");
  background-position: -25px 0;
}

.hair_base_17_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_purple.png");
}

.customize-option.hair_base_17_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_purple.png");
  background-position: -25px 0;
}

.hair_base_17_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow.png");
}

.customize-option.hair_base_17_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow.png");
  background-position: -25px 0;
}

.hair_base_17_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow2.png");
}

.customize-option.hair_base_17_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_pyellow2.png");
  background-position: -25px 0;
}

.hair_base_17_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_rainbow.png");
}

.customize-option.hair_base_17_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_rainbow.png");
  background-position: -25px 0;
}

.hair_base_17_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_red.png");
}

.customize-option.hair_base_17_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_red.png");
  background-position: -25px 0;
}

.hair_base_17_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_snowy.png");
}

.customize-option.hair_base_17_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_snowy.png");
  background-position: -25px 0;
}

.hair_base_17_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_white.png");
}

.customize-option.hair_base_17_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_white.png");
  background-position: -25px 0;
}

.hair_base_17_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winternight.png");
}

.customize-option.hair_base_17_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winternight.png");
  background-position: -25px 0;
}

.hair_base_17_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winterstar.png");
}

.customize-option.hair_base_17_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_winterstar.png");
  background-position: -25px 0;
}

.hair_base_17_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_yellow.png");
}

.customize-option.hair_base_17_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_yellow.png");
  background-position: -25px 0;
}

.hair_base_17_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_zombie.png");
}

.customize-option.hair_base_17_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_17_zombie.png");
  background-position: -25px 0;
}

.hair_base_18_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_TRUred.png");
}

.customize-option.hair_base_18_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_TRUred.png");
  background-position: -25px 0;
}

.hair_base_18_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_aurora.png");
}

.customize-option.hair_base_18_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_aurora.png");
  background-position: -25px 0;
}

.hair_base_18_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_black.png");
}

.customize-option.hair_base_18_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_black.png");
  background-position: -25px 0;
}

.hair_base_18_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blond.png");
}

.customize-option.hair_base_18_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blond.png");
  background-position: -25px 0;
}

.hair_base_18_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blue.png");
}

.customize-option.hair_base_18_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_blue.png");
  background-position: -25px 0;
}

.hair_base_18_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_brown.png");
}

.customize-option.hair_base_18_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_brown.png");
  background-position: -25px 0;
}

.hair_base_18_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycane.png");
}

.customize-option.hair_base_18_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycane.png");
  background-position: -25px 0;
}

.hair_base_18_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycorn.png");
}

.customize-option.hair_base_18_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_candycorn.png");
  background-position: -25px 0;
}

.hair_base_18_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_festive.png");
}

.customize-option.hair_base_18_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_festive.png");
  background-position: -25px 0;
}

.hair_base_18_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_frost.png");
}

.customize-option.hair_base_18_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_frost.png");
  background-position: -25px 0;
}

.hair_base_18_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ghostwhite.png");
}

.customize-option.hair_base_18_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ghostwhite.png");
  background-position: -25px 0;
}

.hair_base_18_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_green.png");
}

.customize-option.hair_base_18_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_green.png");
  background-position: -25px 0;
}

.hair_base_18_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_halloween.png");
}

.customize-option.hair_base_18_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_halloween.png");
  background-position: -25px 0;
}

.hair_base_18_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_holly.png");
}

.customize-option.hair_base_18_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_holly.png");
  background-position: -25px 0;
}

.hair_base_18_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_hollygreen.png");
}

.customize-option.hair_base_18_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_hollygreen.png");
  background-position: -25px 0;
}

.hair_base_18_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_midnight.png");
}

.customize-option.hair_base_18_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_midnight.png");
  background-position: -25px 0;
}

.hair_base_18_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue.png");
}

.customize-option.hair_base_18_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue.png");
  background-position: -25px 0;
}

.hair_base_18_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue2.png");
}

.customize-option.hair_base_18_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pblue2.png");
  background-position: -25px 0;
}

.hair_base_18_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_peppermint.png");
}

.customize-option.hair_base_18_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_peppermint.png");
  background-position: -25px 0;
}

.hair_base_18_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen.png");
}

.customize-option.hair_base_18_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen.png");
  background-position: -25px 0;
}

.hair_base_18_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen2.png");
}

.customize-option.hair_base_18_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pgreen2.png");
  background-position: -25px 0;
}

.hair_base_18_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange.png");
}

.customize-option.hair_base_18_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange.png");
  background-position: -25px 0;
}

.hair_base_18_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange2.png");
}

.customize-option.hair_base_18_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_porange2.png");
  background-position: -25px 0;
}

.hair_base_18_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink.png");
}

.customize-option.hair_base_18_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink.png");
  background-position: -25px 0;
}

.hair_base_18_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink2.png");
}

.customize-option.hair_base_18_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppink2.png");
  background-position: -25px 0;
}

.hair_base_18_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple.png");
}

.customize-option.hair_base_18_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple.png");
  background-position: -25px 0;
}

.hair_base_18_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple2.png");
}

.customize-option.hair_base_18_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_ppurple2.png");
  background-position: -25px 0;
}

.hair_base_18_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pumpkin.png");
}

.customize-option.hair_base_18_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pumpkin.png");
  background-position: -25px 0;
}

.hair_base_18_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_purple.png");
}

.customize-option.hair_base_18_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_purple.png");
  background-position: -25px 0;
}

.hair_base_18_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow.png");
}

.customize-option.hair_base_18_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow.png");
  background-position: -25px 0;
}

.hair_base_18_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow2.png");
}

.customize-option.hair_base_18_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_pyellow2.png");
  background-position: -25px 0;
}

.hair_base_18_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_rainbow.png");
}

.customize-option.hair_base_18_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_rainbow.png");
  background-position: -25px 0;
}

.hair_base_18_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_red.png");
}

.customize-option.hair_base_18_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_red.png");
  background-position: -25px 0;
}

.hair_base_18_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_snowy.png");
}

.customize-option.hair_base_18_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_snowy.png");
  background-position: -25px 0;
}

.hair_base_18_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_white.png");
}

.customize-option.hair_base_18_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_white.png");
  background-position: -25px 0;
}

.hair_base_18_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winternight.png");
}

.customize-option.hair_base_18_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winternight.png");
  background-position: -25px 0;
}

.hair_base_18_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winterstar.png");
}

.customize-option.hair_base_18_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_winterstar.png");
  background-position: -25px 0;
}

.hair_base_18_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_yellow.png");
}

.customize-option.hair_base_18_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_yellow.png");
  background-position: -25px 0;
}

.hair_base_18_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_zombie.png");
}

.customize-option.hair_base_18_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_18_zombie.png");
  background-position: -25px 0;
}

.hair_base_19_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_TRUred.png");
}

.customize-option.hair_base_19_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_TRUred.png");
  background-position: -25px 0;
}

.hair_base_19_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_aurora.png");
}

.customize-option.hair_base_19_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_aurora.png");
  background-position: -25px 0;
}

.hair_base_19_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_black.png");
}

.customize-option.hair_base_19_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_black.png");
  background-position: -25px 0;
}

.hair_base_19_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blond.png");
}

.customize-option.hair_base_19_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blond.png");
  background-position: -25px 0;
}

.hair_base_19_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blue.png");
}

.customize-option.hair_base_19_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_blue.png");
  background-position: -25px 0;
}

.hair_base_19_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_brown.png");
}

.customize-option.hair_base_19_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_brown.png");
  background-position: -25px 0;
}

.hair_base_19_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycane.png");
}

.customize-option.hair_base_19_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycane.png");
  background-position: -25px 0;
}

.hair_base_19_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycorn.png");
}

.customize-option.hair_base_19_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_candycorn.png");
  background-position: -25px 0;
}

.hair_base_19_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_festive.png");
}

.customize-option.hair_base_19_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_festive.png");
  background-position: -25px 0;
}

.hair_base_19_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_frost.png");
}

.customize-option.hair_base_19_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_frost.png");
  background-position: -25px 0;
}

.hair_base_19_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ghostwhite.png");
}

.customize-option.hair_base_19_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ghostwhite.png");
  background-position: -25px 0;
}

.hair_base_19_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_green.png");
}

.customize-option.hair_base_19_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_green.png");
  background-position: -25px 0;
}

.hair_base_19_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_halloween.png");
}

.customize-option.hair_base_19_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_halloween.png");
  background-position: -25px 0;
}

.hair_base_19_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_holly.png");
}

.customize-option.hair_base_19_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_holly.png");
  background-position: -25px 0;
}

.hair_base_19_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_hollygreen.png");
}

.customize-option.hair_base_19_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_hollygreen.png");
  background-position: -25px 0;
}

.hair_base_19_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_midnight.png");
}

.customize-option.hair_base_19_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_midnight.png");
  background-position: -25px 0;
}

.hair_base_19_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue.png");
}

.customize-option.hair_base_19_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue.png");
  background-position: -25px 0;
}

.hair_base_19_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue2.png");
}

.customize-option.hair_base_19_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pblue2.png");
  background-position: -25px 0;
}

.hair_base_19_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_peppermint.png");
}

.customize-option.hair_base_19_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_peppermint.png");
  background-position: -25px 0;
}

.hair_base_19_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen.png");
}

.customize-option.hair_base_19_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen.png");
  background-position: -25px 0;
}

.hair_base_19_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen2.png");
}

.customize-option.hair_base_19_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pgreen2.png");
  background-position: -25px 0;
}

.hair_base_19_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange.png");
}

.customize-option.hair_base_19_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange.png");
  background-position: -25px 0;
}

.hair_base_19_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange2.png");
}

.customize-option.hair_base_19_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_porange2.png");
  background-position: -25px 0;
}

.hair_base_19_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink.png");
}

.customize-option.hair_base_19_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink.png");
  background-position: -25px 0;
}

.hair_base_19_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink2.png");
}

.customize-option.hair_base_19_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppink2.png");
  background-position: -25px 0;
}

.hair_base_19_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple.png");
}

.customize-option.hair_base_19_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple.png");
  background-position: -25px 0;
}

.hair_base_19_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple2.png");
}

.customize-option.hair_base_19_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_ppurple2.png");
  background-position: -25px 0;
}

.hair_base_19_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pumpkin.png");
}

.customize-option.hair_base_19_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pumpkin.png");
  background-position: -25px 0;
}

.hair_base_19_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_purple.png");
}

.customize-option.hair_base_19_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_purple.png");
  background-position: -25px 0;
}

.hair_base_19_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow.png");
}

.customize-option.hair_base_19_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow.png");
  background-position: -25px 0;
}

.hair_base_19_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow2.png");
}

.customize-option.hair_base_19_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_pyellow2.png");
  background-position: -25px 0;
}

.hair_base_19_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_rainbow.png");
}

.customize-option.hair_base_19_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_rainbow.png");
  background-position: -25px 0;
}

.hair_base_19_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_red.png");
}

.customize-option.hair_base_19_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_red.png");
  background-position: -25px 0;
}

.hair_base_19_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_snowy.png");
}

.customize-option.hair_base_19_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_snowy.png");
  background-position: -25px 0;
}

.hair_base_19_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_white.png");
}

.customize-option.hair_base_19_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_white.png");
  background-position: -25px 0;
}

.hair_base_19_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winternight.png");
}

.customize-option.hair_base_19_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winternight.png");
  background-position: -25px 0;
}

.hair_base_19_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winterstar.png");
}

.customize-option.hair_base_19_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_winterstar.png");
  background-position: -25px 0;
}

.hair_base_19_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_yellow.png");
}

.customize-option.hair_base_19_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_yellow.png");
  background-position: -25px 0;
}

.hair_base_19_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_zombie.png");
}

.customize-option.hair_base_19_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_19_zombie.png");
  background-position: -25px 0;
}

.hair_base_1_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_TRUred.png");
}

.customize-option.hair_base_1_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_1_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_aurora.png");
}

.customize-option.hair_base_1_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_aurora.png");
  background-position: -25px -15px;
}

.hair_base_1_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_black.png");
}

.customize-option.hair_base_1_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_black.png");
  background-position: -25px -15px;
}

.hair_base_1_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blond.png");
}

.customize-option.hair_base_1_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blond.png");
  background-position: -25px -15px;
}

.hair_base_1_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blue.png");
}

.customize-option.hair_base_1_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_blue.png");
  background-position: -25px -15px;
}

.hair_base_1_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_brown.png");
}

.customize-option.hair_base_1_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_brown.png");
  background-position: -25px -15px;
}

.hair_base_1_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycane.png");
}

.customize-option.hair_base_1_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycane.png");
  background-position: -25px -15px;
}

.hair_base_1_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycorn.png");
}

.customize-option.hair_base_1_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_1_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_festive.png");
}

.customize-option.hair_base_1_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_festive.png");
  background-position: -25px -15px;
}

.hair_base_1_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_frost.png");
}

.customize-option.hair_base_1_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_frost.png");
  background-position: -25px -15px;
}

.hair_base_1_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ghostwhite.png");
}

.customize-option.hair_base_1_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_1_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_green.png");
}

.customize-option.hair_base_1_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_green.png");
  background-position: -25px -15px;
}

.hair_base_1_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_halloween.png");
}

.customize-option.hair_base_1_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_halloween.png");
  background-position: -25px -15px;
}

.hair_base_1_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_holly.png");
}

.customize-option.hair_base_1_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_holly.png");
  background-position: -25px -15px;
}

.hair_base_1_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_hollygreen.png");
}

.customize-option.hair_base_1_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_1_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_midnight.png");
}

.customize-option.hair_base_1_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_midnight.png");
  background-position: -25px -15px;
}

.hair_base_1_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue.png");
}

.customize-option.hair_base_1_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue.png");
  background-position: -25px -15px;
}

.hair_base_1_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue2.png");
}

.customize-option.hair_base_1_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_1_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_peppermint.png");
}

.customize-option.hair_base_1_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_1_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen.png");
}

.customize-option.hair_base_1_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_1_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen2.png");
}

.customize-option.hair_base_1_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_1_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange.png");
}

.customize-option.hair_base_1_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange.png");
  background-position: -25px -15px;
}

.hair_base_1_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange2.png");
}

.customize-option.hair_base_1_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_porange2.png");
  background-position: -25px -15px;
}

.hair_base_1_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink.png");
}

.customize-option.hair_base_1_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink.png");
  background-position: -25px -15px;
}

.hair_base_1_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink2.png");
}

.customize-option.hair_base_1_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_1_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple.png");
}

.customize-option.hair_base_1_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_1_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple2.png");
}

.customize-option.hair_base_1_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_1_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pumpkin.png");
}

.customize-option.hair_base_1_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_1_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_purple.png");
}

.customize-option.hair_base_1_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_purple.png");
  background-position: -25px -15px;
}

.hair_base_1_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow.png");
}

.customize-option.hair_base_1_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_1_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow2.png");
}

.customize-option.hair_base_1_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_1_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_rainbow.png");
}

.customize-option.hair_base_1_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_1_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_red.png");
}

.customize-option.hair_base_1_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_red.png");
  background-position: -25px -15px;
}

.hair_base_1_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_snowy.png");
}

.customize-option.hair_base_1_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_snowy.png");
  background-position: -25px -15px;
}

.hair_base_1_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_white.png");
}

.customize-option.hair_base_1_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_white.png");
  background-position: -25px -15px;
}

.hair_base_1_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winternight.png");
}

.customize-option.hair_base_1_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winternight.png");
  background-position: -25px -15px;
}

.hair_base_1_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winterstar.png");
}

.customize-option.hair_base_1_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_1_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_yellow.png");
}

.customize-option.hair_base_1_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_yellow.png");
  background-position: -25px -15px;
}

.hair_base_1_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_zombie.png");
}

.customize-option.hair_base_1_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_1_zombie.png");
  background-position: -25px -15px;
}

.hair_base_20_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_TRUred.png");
}

.customize-option.hair_base_20_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_TRUred.png");
  background-position: -25px 0;
}

.hair_base_20_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_aurora.png");
}

.customize-option.hair_base_20_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_aurora.png");
  background-position: -25px 0;
}

.hair_base_20_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_black.png");
}

.customize-option.hair_base_20_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_black.png");
  background-position: -25px 0;
}

.hair_base_20_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blond.png");
}

.customize-option.hair_base_20_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blond.png");
  background-position: -25px 0;
}

.hair_base_20_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blue.png");
}

.customize-option.hair_base_20_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_blue.png");
  background-position: -25px 0;
}

.hair_base_20_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_brown.png");
}

.customize-option.hair_base_20_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_brown.png");
  background-position: -25px 0;
}

.hair_base_20_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycane.png");
}

.customize-option.hair_base_20_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycane.png");
  background-position: -25px 0;
}

.hair_base_20_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycorn.png");
}

.customize-option.hair_base_20_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_candycorn.png");
  background-position: -25px 0;
}

.hair_base_20_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_festive.png");
}

.customize-option.hair_base_20_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_festive.png");
  background-position: -25px 0;
}

.hair_base_20_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_frost.png");
}

.customize-option.hair_base_20_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_frost.png");
  background-position: -25px 0;
}

.hair_base_20_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ghostwhite.png");
}

.customize-option.hair_base_20_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ghostwhite.png");
  background-position: -25px 0;
}

.hair_base_20_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_green.png");
}

.customize-option.hair_base_20_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_green.png");
  background-position: -25px 0;
}

.hair_base_20_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_halloween.png");
}

.customize-option.hair_base_20_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_halloween.png");
  background-position: -25px 0;
}

.hair_base_20_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_holly.png");
}

.customize-option.hair_base_20_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_holly.png");
  background-position: -25px 0;
}

.hair_base_20_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_hollygreen.png");
}

.customize-option.hair_base_20_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_hollygreen.png");
  background-position: -25px 0;
}

.hair_base_20_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_midnight.png");
}

.customize-option.hair_base_20_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_midnight.png");
  background-position: -25px 0;
}

.hair_base_20_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue.png");
}

.customize-option.hair_base_20_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue.png");
  background-position: -25px 0;
}

.hair_base_20_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue2.png");
}

.customize-option.hair_base_20_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pblue2.png");
  background-position: -25px 0;
}

.hair_base_20_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_peppermint.png");
}

.customize-option.hair_base_20_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_peppermint.png");
  background-position: -25px 0;
}

.hair_base_20_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen.png");
}

.customize-option.hair_base_20_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen.png");
  background-position: -25px 0;
}

.hair_base_20_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen2.png");
}

.customize-option.hair_base_20_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pgreen2.png");
  background-position: -25px 0;
}

.hair_base_20_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange.png");
}

.customize-option.hair_base_20_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange.png");
  background-position: -25px 0;
}

.hair_base_20_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange2.png");
}

.customize-option.hair_base_20_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_porange2.png");
  background-position: -25px 0;
}

.hair_base_20_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink.png");
}

.customize-option.hair_base_20_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink.png");
  background-position: -25px 0;
}

.hair_base_20_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink2.png");
}

.customize-option.hair_base_20_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppink2.png");
  background-position: -25px 0;
}

.hair_base_20_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple.png");
}

.customize-option.hair_base_20_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple.png");
  background-position: -25px 0;
}

.hair_base_20_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple2.png");
}

.customize-option.hair_base_20_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_ppurple2.png");
  background-position: -25px 0;
}

.hair_base_20_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pumpkin.png");
}

.customize-option.hair_base_20_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pumpkin.png");
  background-position: -25px 0;
}

.hair_base_20_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_purple.png");
}

.customize-option.hair_base_20_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_purple.png");
  background-position: -25px 0;
}

.hair_base_20_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow.png");
}

.customize-option.hair_base_20_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow.png");
  background-position: -25px 0;
}

.hair_base_20_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow2.png");
}

.customize-option.hair_base_20_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_pyellow2.png");
  background-position: -25px 0;
}

.hair_base_20_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_rainbow.png");
}

.customize-option.hair_base_20_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_rainbow.png");
  background-position: -25px 0;
}

.hair_base_20_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_red.png");
}

.customize-option.hair_base_20_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_red.png");
  background-position: -25px 0;
}

.hair_base_20_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_snowy.png");
}

.customize-option.hair_base_20_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_snowy.png");
  background-position: -25px 0;
}

.hair_base_20_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_white.png");
}

.customize-option.hair_base_20_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_white.png");
  background-position: -25px 0;
}

.hair_base_20_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winternight.png");
}

.customize-option.hair_base_20_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winternight.png");
  background-position: -25px 0;
}

.hair_base_20_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winterstar.png");
}

.customize-option.hair_base_20_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_winterstar.png");
  background-position: -25px 0;
}

.hair_base_20_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_yellow.png");
}

.customize-option.hair_base_20_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_yellow.png");
  background-position: -25px 0;
}

.hair_base_20_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_zombie.png");
}

.customize-option.hair_base_20_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_20_zombie.png");
  background-position: -25px 0;
}

.hair_base_2_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_TRUred.png");
}

.customize-option.hair_base_2_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_2_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_aurora.png");
}

.customize-option.hair_base_2_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_aurora.png");
  background-position: -25px -15px;
}

.hair_base_2_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_black.png");
}

.customize-option.hair_base_2_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_black.png");
  background-position: -25px -15px;
}

.hair_base_2_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blond.png");
}

.customize-option.hair_base_2_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blond.png");
  background-position: -25px -15px;
}

.hair_base_2_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blue.png");
}

.customize-option.hair_base_2_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_blue.png");
  background-position: -25px -15px;
}

.hair_base_2_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_brown.png");
}

.customize-option.hair_base_2_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_brown.png");
  background-position: -25px -15px;
}

.hair_base_2_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycane.png");
}

.customize-option.hair_base_2_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycane.png");
  background-position: -25px -15px;
}

.hair_base_2_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycorn.png");
}

.customize-option.hair_base_2_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_2_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_festive.png");
}

.customize-option.hair_base_2_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_festive.png");
  background-position: -25px -15px;
}

.hair_base_2_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_frost.png");
}

.customize-option.hair_base_2_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_frost.png");
  background-position: -25px -15px;
}

.hair_base_2_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ghostwhite.png");
}

.customize-option.hair_base_2_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_2_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_green.png");
}

.customize-option.hair_base_2_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_green.png");
  background-position: -25px -15px;
}

.hair_base_2_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_halloween.png");
}

.customize-option.hair_base_2_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_halloween.png");
  background-position: -25px -15px;
}

.hair_base_2_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_holly.png");
}

.customize-option.hair_base_2_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_holly.png");
  background-position: -25px -15px;
}

.hair_base_2_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_hollygreen.png");
}

.customize-option.hair_base_2_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_2_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_midnight.png");
}

.customize-option.hair_base_2_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_midnight.png");
  background-position: -25px -15px;
}

.hair_base_2_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue.png");
}

.customize-option.hair_base_2_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue.png");
  background-position: -25px -15px;
}

.hair_base_2_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue2.png");
}

.customize-option.hair_base_2_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_2_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_peppermint.png");
}

.customize-option.hair_base_2_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_2_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen.png");
}

.customize-option.hair_base_2_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_2_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen2.png");
}

.customize-option.hair_base_2_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_2_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange.png");
}

.customize-option.hair_base_2_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange.png");
  background-position: -25px -15px;
}

.hair_base_2_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange2.png");
}

.customize-option.hair_base_2_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_porange2.png");
  background-position: -25px -15px;
}

.hair_base_2_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink.png");
}

.customize-option.hair_base_2_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink.png");
  background-position: -25px -15px;
}

.hair_base_2_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink2.png");
}

.customize-option.hair_base_2_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_2_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple.png");
}

.customize-option.hair_base_2_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_2_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple2.png");
}

.customize-option.hair_base_2_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_2_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pumpkin.png");
}

.customize-option.hair_base_2_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_2_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_purple.png");
}

.customize-option.hair_base_2_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_purple.png");
  background-position: -25px -15px;
}

.hair_base_2_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow.png");
}

.customize-option.hair_base_2_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_2_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow2.png");
}

.customize-option.hair_base_2_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_2_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_rainbow.png");
}

.customize-option.hair_base_2_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_2_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_red.png");
}

.customize-option.hair_base_2_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_red.png");
  background-position: -25px -15px;
}

.hair_base_2_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_snowy.png");
}

.customize-option.hair_base_2_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_snowy.png");
  background-position: -25px -15px;
}

.hair_base_2_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_white.png");
}

.customize-option.hair_base_2_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_white.png");
  background-position: -25px -15px;
}

.hair_base_2_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winternight.png");
}

.customize-option.hair_base_2_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winternight.png");
  background-position: -25px -15px;
}

.hair_base_2_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winterstar.png");
}

.customize-option.hair_base_2_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_2_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_yellow.png");
}

.customize-option.hair_base_2_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_yellow.png");
  background-position: -25px -15px;
}

.hair_base_2_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_zombie.png");
}

.customize-option.hair_base_2_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_2_zombie.png");
  background-position: -25px -15px;
}

.hair_base_3_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_TRUred.png");
}

.customize-option.hair_base_3_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_3_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_aurora.png");
}

.customize-option.hair_base_3_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_aurora.png");
  background-position: -25px -15px;
}

.hair_base_3_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_black.png");
}

.customize-option.hair_base_3_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_black.png");
  background-position: -25px -15px;
}

.hair_base_3_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blond.png");
}

.customize-option.hair_base_3_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blond.png");
  background-position: -25px -15px;
}

.hair_base_3_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blue.png");
}

.customize-option.hair_base_3_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_blue.png");
  background-position: -25px -15px;
}

.hair_base_3_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_brown.png");
}

.customize-option.hair_base_3_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_brown.png");
  background-position: -25px -15px;
}

.hair_base_3_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycane.png");
}

.customize-option.hair_base_3_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycane.png");
  background-position: -25px -15px;
}

.hair_base_3_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycorn.png");
}

.customize-option.hair_base_3_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_3_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_festive.png");
}

.customize-option.hair_base_3_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_festive.png");
  background-position: -25px -15px;
}

.hair_base_3_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_frost.png");
}

.customize-option.hair_base_3_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_frost.png");
  background-position: -25px -15px;
}

.hair_base_3_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ghostwhite.png");
}

.customize-option.hair_base_3_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_3_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_green.png");
}

.customize-option.hair_base_3_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_green.png");
  background-position: -25px -15px;
}

.hair_base_3_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_halloween.png");
}

.customize-option.hair_base_3_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_halloween.png");
  background-position: -25px -15px;
}

.hair_base_3_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_holly.png");
}

.customize-option.hair_base_3_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_holly.png");
  background-position: -25px -15px;
}

.hair_base_3_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_hollygreen.png");
}

.customize-option.hair_base_3_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_3_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_midnight.png");
}

.customize-option.hair_base_3_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_midnight.png");
  background-position: -25px -15px;
}

.hair_base_3_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue.png");
}

.customize-option.hair_base_3_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue.png");
  background-position: -25px -15px;
}

.hair_base_3_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue2.png");
}

.customize-option.hair_base_3_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_3_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_peppermint.png");
}

.customize-option.hair_base_3_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_3_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen.png");
}

.customize-option.hair_base_3_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_3_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen2.png");
}

.customize-option.hair_base_3_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_3_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange.png");
}

.customize-option.hair_base_3_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange.png");
  background-position: -25px -15px;
}

.hair_base_3_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange2.png");
}

.customize-option.hair_base_3_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_porange2.png");
  background-position: -25px -15px;
}

.hair_base_3_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink.png");
}

.customize-option.hair_base_3_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink.png");
  background-position: -25px -15px;
}

.hair_base_3_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink2.png");
}

.customize-option.hair_base_3_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_3_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple.png");
}

.customize-option.hair_base_3_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_3_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple2.png");
}

.customize-option.hair_base_3_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_3_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pumpkin.png");
}

.customize-option.hair_base_3_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_3_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_purple.png");
}

.customize-option.hair_base_3_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_purple.png");
  background-position: -25px -15px;
}

.hair_base_3_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow.png");
}

.customize-option.hair_base_3_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_3_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow2.png");
}

.customize-option.hair_base_3_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_3_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_rainbow.png");
}

.customize-option.hair_base_3_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_3_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_red.png");
}

.customize-option.hair_base_3_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_red.png");
  background-position: -25px -15px;
}

.hair_base_3_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_snowy.png");
}

.customize-option.hair_base_3_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_snowy.png");
  background-position: -25px -15px;
}

.hair_base_3_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_white.png");
}

.customize-option.hair_base_3_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_white.png");
  background-position: -25px -15px;
}

.hair_base_3_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winternight.png");
}

.customize-option.hair_base_3_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winternight.png");
  background-position: -25px -15px;
}

.hair_base_3_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winterstar.png");
}

.customize-option.hair_base_3_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_3_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_yellow.png");
}

.customize-option.hair_base_3_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_yellow.png");
  background-position: -25px -15px;
}

.hair_base_3_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_zombie.png");
}

.customize-option.hair_base_3_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_3_zombie.png");
  background-position: -25px -15px;
}

.hair_base_4_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_TRUred.png");
}

.customize-option.hair_base_4_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_4_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_aurora.png");
}

.customize-option.hair_base_4_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_aurora.png");
  background-position: -25px -15px;
}

.hair_base_4_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_black.png");
}

.customize-option.hair_base_4_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_black.png");
  background-position: -25px -15px;
}

.hair_base_4_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blond.png");
}

.customize-option.hair_base_4_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blond.png");
  background-position: -25px -15px;
}

.hair_base_4_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blue.png");
}

.customize-option.hair_base_4_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_blue.png");
  background-position: -25px -15px;
}

.hair_base_4_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_brown.png");
}

.customize-option.hair_base_4_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_brown.png");
  background-position: -25px -15px;
}

.hair_base_4_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycane.png");
}

.customize-option.hair_base_4_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycane.png");
  background-position: -25px -15px;
}

.hair_base_4_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycorn.png");
}

.customize-option.hair_base_4_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_4_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_festive.png");
}

.customize-option.hair_base_4_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_festive.png");
  background-position: -25px -15px;
}

.hair_base_4_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_frost.png");
}

.customize-option.hair_base_4_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_frost.png");
  background-position: -25px -15px;
}

.hair_base_4_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ghostwhite.png");
}

.customize-option.hair_base_4_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_4_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_green.png");
}

.customize-option.hair_base_4_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_green.png");
  background-position: -25px -15px;
}

.hair_base_4_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_halloween.png");
}

.customize-option.hair_base_4_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_halloween.png");
  background-position: -25px -15px;
}

.hair_base_4_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_holly.png");
}

.customize-option.hair_base_4_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_holly.png");
  background-position: -25px -15px;
}

.hair_base_4_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_hollygreen.png");
}

.customize-option.hair_base_4_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_4_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_midnight.png");
}

.customize-option.hair_base_4_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_midnight.png");
  background-position: -25px -15px;
}

.hair_base_4_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue.png");
}

.customize-option.hair_base_4_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue.png");
  background-position: -25px -15px;
}

.hair_base_4_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue2.png");
}

.customize-option.hair_base_4_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_4_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_peppermint.png");
}

.customize-option.hair_base_4_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_4_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen.png");
}

.customize-option.hair_base_4_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_4_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen2.png");
}

.customize-option.hair_base_4_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_4_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange.png");
}

.customize-option.hair_base_4_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange.png");
  background-position: -25px -15px;
}

.hair_base_4_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange2.png");
}

.customize-option.hair_base_4_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_porange2.png");
  background-position: -25px -15px;
}

.hair_base_4_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink.png");
}

.customize-option.hair_base_4_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink.png");
  background-position: -25px -15px;
}

.hair_base_4_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink2.png");
}

.customize-option.hair_base_4_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_4_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple.png");
}

.customize-option.hair_base_4_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_4_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple2.png");
}

.customize-option.hair_base_4_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_4_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pumpkin.png");
}

.customize-option.hair_base_4_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_4_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_purple.png");
}

.customize-option.hair_base_4_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_purple.png");
  background-position: -25px -15px;
}

.hair_base_4_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow.png");
}

.customize-option.hair_base_4_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_4_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow2.png");
}

.customize-option.hair_base_4_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_4_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_rainbow.png");
}

.customize-option.hair_base_4_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_4_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_red.png");
}

.customize-option.hair_base_4_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_red.png");
  background-position: -25px -15px;
}

.hair_base_4_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_snowy.png");
}

.customize-option.hair_base_4_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_snowy.png");
  background-position: -25px -15px;
}

.hair_base_4_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_white.png");
}

.customize-option.hair_base_4_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_white.png");
  background-position: -25px -15px;
}

.hair_base_4_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winternight.png");
}

.customize-option.hair_base_4_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winternight.png");
  background-position: -25px -15px;
}

.hair_base_4_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winterstar.png");
}

.customize-option.hair_base_4_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_4_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_yellow.png");
}

.customize-option.hair_base_4_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_yellow.png");
  background-position: -25px -15px;
}

.hair_base_4_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_zombie.png");
}

.customize-option.hair_base_4_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_4_zombie.png");
  background-position: -25px -15px;
}

.hair_base_5_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_TRUred.png");
}

.customize-option.hair_base_5_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_5_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_aurora.png");
}

.customize-option.hair_base_5_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_aurora.png");
  background-position: -25px -15px;
}

.hair_base_5_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_black.png");
}

.customize-option.hair_base_5_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_black.png");
  background-position: -25px -15px;
}

.hair_base_5_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blond.png");
}

.customize-option.hair_base_5_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blond.png");
  background-position: -25px -15px;
}

.hair_base_5_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blue.png");
}

.customize-option.hair_base_5_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_blue.png");
  background-position: -25px -15px;
}

.hair_base_5_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_brown.png");
}

.customize-option.hair_base_5_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_brown.png");
  background-position: -25px -15px;
}

.hair_base_5_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycane.png");
}

.customize-option.hair_base_5_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycane.png");
  background-position: -25px -15px;
}

.hair_base_5_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycorn.png");
}

.customize-option.hair_base_5_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_5_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_festive.png");
}

.customize-option.hair_base_5_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_festive.png");
  background-position: -25px -15px;
}

.hair_base_5_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_frost.png");
}

.customize-option.hair_base_5_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_frost.png");
  background-position: -25px -15px;
}

.hair_base_5_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ghostwhite.png");
}

.customize-option.hair_base_5_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_5_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_green.png");
}

.customize-option.hair_base_5_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_green.png");
  background-position: -25px -15px;
}

.hair_base_5_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_halloween.png");
}

.customize-option.hair_base_5_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_halloween.png");
  background-position: -25px -15px;
}

.hair_base_5_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_holly.png");
}

.customize-option.hair_base_5_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_holly.png");
  background-position: -25px -15px;
}

.hair_base_5_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_hollygreen.png");
}

.customize-option.hair_base_5_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_5_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_midnight.png");
}

.customize-option.hair_base_5_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_midnight.png");
  background-position: -25px -15px;
}

.hair_base_5_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue.png");
}

.customize-option.hair_base_5_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue.png");
  background-position: -25px -15px;
}

.hair_base_5_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue2.png");
}

.customize-option.hair_base_5_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_5_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_peppermint.png");
}

.customize-option.hair_base_5_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_5_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen.png");
}

.customize-option.hair_base_5_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_5_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen2.png");
}

.customize-option.hair_base_5_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_5_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange.png");
}

.customize-option.hair_base_5_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange.png");
  background-position: -25px -15px;
}

.hair_base_5_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange2.png");
}

.customize-option.hair_base_5_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_porange2.png");
  background-position: -25px -15px;
}

.hair_base_5_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink.png");
}

.customize-option.hair_base_5_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink.png");
  background-position: -25px -15px;
}

.hair_base_5_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink2.png");
}

.customize-option.hair_base_5_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_5_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple.png");
}

.customize-option.hair_base_5_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_5_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple2.png");
}

.customize-option.hair_base_5_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_5_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pumpkin.png");
}

.customize-option.hair_base_5_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_5_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_purple.png");
}

.customize-option.hair_base_5_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_purple.png");
  background-position: -25px -15px;
}

.hair_base_5_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow.png");
}

.customize-option.hair_base_5_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_5_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow2.png");
}

.customize-option.hair_base_5_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_5_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_rainbow.png");
}

.customize-option.hair_base_5_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_5_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_red.png");
}

.customize-option.hair_base_5_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_red.png");
  background-position: -25px -15px;
}

.hair_base_5_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_snowy.png");
}

.customize-option.hair_base_5_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_snowy.png");
  background-position: -25px -15px;
}

.hair_base_5_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_white.png");
}

.customize-option.hair_base_5_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_white.png");
  background-position: -25px -15px;
}

.hair_base_5_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winternight.png");
}

.customize-option.hair_base_5_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winternight.png");
  background-position: -25px -15px;
}

.hair_base_5_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winterstar.png");
}

.customize-option.hair_base_5_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_5_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_yellow.png");
}

.customize-option.hair_base_5_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_yellow.png");
  background-position: -25px -15px;
}

.hair_base_5_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_zombie.png");
}

.customize-option.hair_base_5_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_5_zombie.png");
  background-position: -25px -15px;
}

.hair_base_6_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_TRUred.png");
}

.customize-option.hair_base_6_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_6_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_aurora.png");
}

.customize-option.hair_base_6_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_aurora.png");
  background-position: -25px -15px;
}

.hair_base_6_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_black.png");
}

.customize-option.hair_base_6_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_black.png");
  background-position: -25px -15px;
}

.hair_base_6_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blond.png");
}

.customize-option.hair_base_6_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blond.png");
  background-position: -25px -15px;
}

.hair_base_6_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blue.png");
}

.customize-option.hair_base_6_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_blue.png");
  background-position: -25px -15px;
}

.hair_base_6_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_brown.png");
}

.customize-option.hair_base_6_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_brown.png");
  background-position: -25px -15px;
}

.hair_base_6_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycane.png");
}

.customize-option.hair_base_6_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycane.png");
  background-position: -25px -15px;
}

.hair_base_6_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycorn.png");
}

.customize-option.hair_base_6_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_6_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_festive.png");
}

.customize-option.hair_base_6_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_festive.png");
  background-position: -25px -15px;
}

.hair_base_6_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_frost.png");
}

.customize-option.hair_base_6_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_frost.png");
  background-position: -25px -15px;
}

.hair_base_6_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ghostwhite.png");
}

.customize-option.hair_base_6_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_6_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_green.png");
}

.customize-option.hair_base_6_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_green.png");
  background-position: -25px -15px;
}

.hair_base_6_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_halloween.png");
}

.customize-option.hair_base_6_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_halloween.png");
  background-position: -25px -15px;
}

.hair_base_6_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_holly.png");
}

.customize-option.hair_base_6_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_holly.png");
  background-position: -25px -15px;
}

.hair_base_6_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_hollygreen.png");
}

.customize-option.hair_base_6_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_6_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_midnight.png");
}

.customize-option.hair_base_6_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_midnight.png");
  background-position: -25px -15px;
}

.hair_base_6_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue.png");
}

.customize-option.hair_base_6_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue.png");
  background-position: -25px -15px;
}

.hair_base_6_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue2.png");
}

.customize-option.hair_base_6_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_6_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_peppermint.png");
}

.customize-option.hair_base_6_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_6_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen.png");
}

.customize-option.hair_base_6_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_6_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen2.png");
}

.customize-option.hair_base_6_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_6_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange.png");
}

.customize-option.hair_base_6_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange.png");
  background-position: -25px -15px;
}

.hair_base_6_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange2.png");
}

.customize-option.hair_base_6_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_porange2.png");
  background-position: -25px -15px;
}

.hair_base_6_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink.png");
}

.customize-option.hair_base_6_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink.png");
  background-position: -25px -15px;
}

.hair_base_6_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink2.png");
}

.customize-option.hair_base_6_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_6_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple.png");
}

.customize-option.hair_base_6_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_6_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple2.png");
}

.customize-option.hair_base_6_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_6_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pumpkin.png");
}

.customize-option.hair_base_6_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_6_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_purple.png");
}

.customize-option.hair_base_6_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_purple.png");
  background-position: -25px -15px;
}

.hair_base_6_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow.png");
}

.customize-option.hair_base_6_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_6_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow2.png");
}

.customize-option.hair_base_6_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_6_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_rainbow.png");
}

.customize-option.hair_base_6_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_6_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_red.png");
}

.customize-option.hair_base_6_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_red.png");
  background-position: -25px -15px;
}

.hair_base_6_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_snowy.png");
}

.customize-option.hair_base_6_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_snowy.png");
  background-position: -25px -15px;
}

.hair_base_6_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_white.png");
}

.customize-option.hair_base_6_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_white.png");
  background-position: -25px -15px;
}

.hair_base_6_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winternight.png");
}

.customize-option.hair_base_6_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winternight.png");
  background-position: -25px -15px;
}

.hair_base_6_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winterstar.png");
}

.customize-option.hair_base_6_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_6_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_yellow.png");
}

.customize-option.hair_base_6_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_yellow.png");
  background-position: -25px -15px;
}

.hair_base_6_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_zombie.png");
}

.customize-option.hair_base_6_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_6_zombie.png");
  background-position: -25px -15px;
}

.hair_base_7_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_TRUred.png");
}

.customize-option.hair_base_7_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_7_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_aurora.png");
}

.customize-option.hair_base_7_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_aurora.png");
  background-position: -25px -15px;
}

.hair_base_7_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_black.png");
}

.customize-option.hair_base_7_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_black.png");
  background-position: -25px -15px;
}

.hair_base_7_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blond.png");
}

.customize-option.hair_base_7_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blond.png");
  background-position: -25px -15px;
}

.hair_base_7_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blue.png");
}

.customize-option.hair_base_7_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_blue.png");
  background-position: -25px -15px;
}

.hair_base_7_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_brown.png");
}

.customize-option.hair_base_7_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_brown.png");
  background-position: -25px -15px;
}

.hair_base_7_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycane.png");
}

.customize-option.hair_base_7_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycane.png");
  background-position: -25px -15px;
}

.hair_base_7_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycorn.png");
}

.customize-option.hair_base_7_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_7_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_festive.png");
}

.customize-option.hair_base_7_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_festive.png");
  background-position: -25px -15px;
}

.hair_base_7_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_frost.png");
}

.customize-option.hair_base_7_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_frost.png");
  background-position: -25px -15px;
}

.hair_base_7_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ghostwhite.png");
}

.customize-option.hair_base_7_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_7_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_green.png");
}

.customize-option.hair_base_7_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_green.png");
  background-position: -25px -15px;
}

.hair_base_7_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_halloween.png");
}

.customize-option.hair_base_7_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_halloween.png");
  background-position: -25px -15px;
}

.hair_base_7_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_holly.png");
}

.customize-option.hair_base_7_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_holly.png");
  background-position: -25px -15px;
}

.hair_base_7_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_hollygreen.png");
}

.customize-option.hair_base_7_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_7_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_midnight.png");
}

.customize-option.hair_base_7_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_midnight.png");
  background-position: -25px -15px;
}

.hair_base_7_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue.png");
}

.customize-option.hair_base_7_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue.png");
  background-position: -25px -15px;
}

.hair_base_7_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue2.png");
}

.customize-option.hair_base_7_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_7_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_peppermint.png");
}

.customize-option.hair_base_7_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_7_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen.png");
}

.customize-option.hair_base_7_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_7_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen2.png");
}

.customize-option.hair_base_7_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_7_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange.png");
}

.customize-option.hair_base_7_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange.png");
  background-position: -25px -15px;
}

.hair_base_7_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange2.png");
}

.customize-option.hair_base_7_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_porange2.png");
  background-position: -25px -15px;
}

.hair_base_7_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink.png");
}

.customize-option.hair_base_7_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink.png");
  background-position: -25px -15px;
}

.hair_base_7_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink2.png");
}

.customize-option.hair_base_7_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_7_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple.png");
}

.customize-option.hair_base_7_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_7_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple2.png");
}

.customize-option.hair_base_7_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_7_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pumpkin.png");
}

.customize-option.hair_base_7_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_7_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_purple.png");
}

.customize-option.hair_base_7_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_purple.png");
  background-position: -25px -15px;
}

.hair_base_7_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow.png");
}

.customize-option.hair_base_7_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_7_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow2.png");
}

.customize-option.hair_base_7_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_7_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_rainbow.png");
}

.customize-option.hair_base_7_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_7_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_red.png");
}

.customize-option.hair_base_7_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_red.png");
  background-position: -25px -15px;
}

.hair_base_7_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_snowy.png");
}

.customize-option.hair_base_7_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_snowy.png");
  background-position: -25px -15px;
}

.hair_base_7_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_white.png");
}

.customize-option.hair_base_7_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_white.png");
  background-position: -25px -15px;
}

.hair_base_7_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winternight.png");
}

.customize-option.hair_base_7_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winternight.png");
  background-position: -25px -15px;
}

.hair_base_7_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winterstar.png");
}

.customize-option.hair_base_7_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_7_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_yellow.png");
}

.customize-option.hair_base_7_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_yellow.png");
  background-position: -25px -15px;
}

.hair_base_7_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_zombie.png");
}

.customize-option.hair_base_7_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_7_zombie.png");
  background-position: -25px -15px;
}

.hair_base_8_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_TRUred.png");
}

.customize-option.hair_base_8_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_8_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_aurora.png");
}

.customize-option.hair_base_8_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_aurora.png");
  background-position: -25px -15px;
}

.hair_base_8_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_black.png");
}

.customize-option.hair_base_8_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_black.png");
  background-position: -25px -15px;
}

.hair_base_8_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blond.png");
}

.customize-option.hair_base_8_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blond.png");
  background-position: -25px -15px;
}

.hair_base_8_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blue.png");
}

.customize-option.hair_base_8_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_blue.png");
  background-position: -25px -15px;
}

.hair_base_8_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_brown.png");
}

.customize-option.hair_base_8_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_brown.png");
  background-position: -25px -15px;
}

.hair_base_8_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycane.png");
}

.customize-option.hair_base_8_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycane.png");
  background-position: -25px -15px;
}

.hair_base_8_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycorn.png");
}

.customize-option.hair_base_8_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_8_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_festive.png");
}

.customize-option.hair_base_8_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_festive.png");
  background-position: -25px -15px;
}

.hair_base_8_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_frost.png");
}

.customize-option.hair_base_8_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_frost.png");
  background-position: -25px -15px;
}

.hair_base_8_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ghostwhite.png");
}

.customize-option.hair_base_8_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_8_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_green.png");
}

.customize-option.hair_base_8_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_green.png");
  background-position: -25px -15px;
}

.hair_base_8_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_halloween.png");
}

.customize-option.hair_base_8_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_halloween.png");
  background-position: -25px -15px;
}

.hair_base_8_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_holly.png");
}

.customize-option.hair_base_8_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_holly.png");
  background-position: -25px -15px;
}

.hair_base_8_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_hollygreen.png");
}

.customize-option.hair_base_8_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_8_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_midnight.png");
}

.customize-option.hair_base_8_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_midnight.png");
  background-position: -25px -15px;
}

.hair_base_8_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue.png");
}

.customize-option.hair_base_8_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue.png");
  background-position: -25px -15px;
}

.hair_base_8_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue2.png");
}

.customize-option.hair_base_8_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_8_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_peppermint.png");
}

.customize-option.hair_base_8_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_8_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen.png");
}

.customize-option.hair_base_8_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_8_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen2.png");
}

.customize-option.hair_base_8_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_8_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange.png");
}

.customize-option.hair_base_8_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange.png");
  background-position: -25px -15px;
}

.hair_base_8_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange2.png");
}

.customize-option.hair_base_8_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_porange2.png");
  background-position: -25px -15px;
}

.hair_base_8_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink.png");
}

.customize-option.hair_base_8_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink.png");
  background-position: -25px -15px;
}

.hair_base_8_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink2.png");
}

.customize-option.hair_base_8_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_8_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple.png");
}

.customize-option.hair_base_8_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_8_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple2.png");
}

.customize-option.hair_base_8_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_8_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pumpkin.png");
}

.customize-option.hair_base_8_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_8_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_purple.png");
}

.customize-option.hair_base_8_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_purple.png");
  background-position: -25px -15px;
}

.hair_base_8_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow.png");
}

.customize-option.hair_base_8_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_8_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow2.png");
}

.customize-option.hair_base_8_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_8_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_rainbow.png");
}

.customize-option.hair_base_8_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_8_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_red.png");
}

.customize-option.hair_base_8_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_red.png");
  background-position: -25px -15px;
}

.hair_base_8_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_snowy.png");
}

.customize-option.hair_base_8_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_snowy.png");
  background-position: -25px -15px;
}

.hair_base_8_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_white.png");
}

.customize-option.hair_base_8_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_white.png");
  background-position: -25px -15px;
}

.hair_base_8_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winternight.png");
}

.customize-option.hair_base_8_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winternight.png");
  background-position: -25px -15px;
}

.hair_base_8_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winterstar.png");
}

.customize-option.hair_base_8_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_8_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_yellow.png");
}

.customize-option.hair_base_8_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_yellow.png");
  background-position: -25px -15px;
}

.hair_base_8_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_zombie.png");
}

.customize-option.hair_base_8_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_8_zombie.png");
  background-position: -25px -15px;
}

.hair_base_9_TRUred {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_TRUred.png");
}

.customize-option.hair_base_9_TRUred {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_TRUred.png");
  background-position: -25px -15px;
}

.hair_base_9_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_aurora.png");
}

.customize-option.hair_base_9_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_aurora.png");
  background-position: -25px -15px;
}

.hair_base_9_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_black.png");
}

.customize-option.hair_base_9_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_black.png");
  background-position: -25px -15px;
}

.hair_base_9_blond {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blond.png");
}

.customize-option.hair_base_9_blond {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blond.png");
  background-position: -25px -15px;
}

.hair_base_9_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blue.png");
}

.customize-option.hair_base_9_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_blue.png");
  background-position: -25px -15px;
}

.hair_base_9_brown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_brown.png");
}

.customize-option.hair_base_9_brown {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_brown.png");
  background-position: -25px -15px;
}

.hair_base_9_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycane.png");
}

.customize-option.hair_base_9_candycane {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycane.png");
  background-position: -25px -15px;
}

.hair_base_9_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycorn.png");
}

.customize-option.hair_base_9_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_candycorn.png");
  background-position: -25px -15px;
}

.hair_base_9_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_festive.png");
}

.customize-option.hair_base_9_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_festive.png");
  background-position: -25px -15px;
}

.hair_base_9_frost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_frost.png");
}

.customize-option.hair_base_9_frost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_frost.png");
  background-position: -25px -15px;
}

.hair_base_9_ghostwhite {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ghostwhite.png");
}

.customize-option.hair_base_9_ghostwhite {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ghostwhite.png");
  background-position: -25px -15px;
}

.hair_base_9_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_green.png");
}

.customize-option.hair_base_9_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_green.png");
  background-position: -25px -15px;
}

.hair_base_9_halloween {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_halloween.png");
}

.customize-option.hair_base_9_halloween {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_halloween.png");
  background-position: -25px -15px;
}

.hair_base_9_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_holly.png");
}

.customize-option.hair_base_9_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_holly.png");
  background-position: -25px -15px;
}

.hair_base_9_hollygreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_hollygreen.png");
}

.customize-option.hair_base_9_hollygreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_hollygreen.png");
  background-position: -25px -15px;
}

.hair_base_9_midnight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_midnight.png");
}

.customize-option.hair_base_9_midnight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_midnight.png");
  background-position: -25px -15px;
}

.hair_base_9_pblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue.png");
}

.customize-option.hair_base_9_pblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue.png");
  background-position: -25px -15px;
}

.hair_base_9_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue2.png");
}

.customize-option.hair_base_9_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pblue2.png");
  background-position: -25px -15px;
}

.hair_base_9_peppermint {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_peppermint.png");
}

.customize-option.hair_base_9_peppermint {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_peppermint.png");
  background-position: -25px -15px;
}

.hair_base_9_pgreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen.png");
}

.customize-option.hair_base_9_pgreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen.png");
  background-position: -25px -15px;
}

.hair_base_9_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen2.png");
}

.customize-option.hair_base_9_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pgreen2.png");
  background-position: -25px -15px;
}

.hair_base_9_porange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange.png");
}

.customize-option.hair_base_9_porange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange.png");
  background-position: -25px -15px;
}

.hair_base_9_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange2.png");
}

.customize-option.hair_base_9_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_porange2.png");
  background-position: -25px -15px;
}

.hair_base_9_ppink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink.png");
}

.customize-option.hair_base_9_ppink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink.png");
  background-position: -25px -15px;
}

.hair_base_9_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink2.png");
}

.customize-option.hair_base_9_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppink2.png");
  background-position: -25px -15px;
}

.hair_base_9_ppurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple.png");
}

.customize-option.hair_base_9_ppurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple.png");
  background-position: -25px -15px;
}

.hair_base_9_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple2.png");
}

.customize-option.hair_base_9_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_ppurple2.png");
  background-position: -25px -15px;
}

.hair_base_9_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pumpkin.png");
}

.customize-option.hair_base_9_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pumpkin.png");
  background-position: -25px -15px;
}

.hair_base_9_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_purple.png");
}

.customize-option.hair_base_9_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_purple.png");
  background-position: -25px -15px;
}

.hair_base_9_pyellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow.png");
}

.customize-option.hair_base_9_pyellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow.png");
  background-position: -25px -15px;
}

.hair_base_9_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow2.png");
}

.customize-option.hair_base_9_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_pyellow2.png");
  background-position: -25px -15px;
}

.hair_base_9_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_rainbow.png");
}

.customize-option.hair_base_9_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_rainbow.png");
  background-position: -25px -15px;
}

.hair_base_9_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_red.png");
}

.customize-option.hair_base_9_red {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_red.png");
  background-position: -25px -15px;
}

.hair_base_9_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_snowy.png");
}

.customize-option.hair_base_9_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_snowy.png");
  background-position: -25px -15px;
}

.hair_base_9_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_white.png");
}

.customize-option.hair_base_9_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_white.png");
  background-position: -25px -15px;
}

.hair_base_9_winternight {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winternight.png");
}

.customize-option.hair_base_9_winternight {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winternight.png");
  background-position: -25px -15px;
}

.hair_base_9_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winterstar.png");
}

.customize-option.hair_base_9_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_winterstar.png");
  background-position: -25px -15px;
}

.hair_base_9_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_yellow.png");
}

.customize-option.hair_base_9_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_yellow.png");
  background-position: -25px -15px;
}

.hair_base_9_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_zombie.png");
}

.customize-option.hair_base_9_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_base_9_zombie.png");
  background-position: -25px -15px;
}

.hair_beard_1_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue2.png");
}

.customize-option.hair_beard_1_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pblue2.png");
  background-position: -25px -15px;
}

.hair_beard_1_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen2.png");
}

.customize-option.hair_beard_1_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pgreen2.png");
  background-position: -25px -15px;
}

.hair_beard_1_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange2.png");
}

.customize-option.hair_beard_1_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_porange2.png");
  background-position: -25px -15px;
}

.hair_beard_1_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink2.png");
}

.customize-option.hair_beard_1_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppink2.png");
  background-position: -25px -15px;
}

.hair_beard_1_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple2.png");
}

.customize-option.hair_beard_1_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_ppurple2.png");
  background-position: -25px -15px;
}

.hair_beard_1_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow2.png");
}

.customize-option.hair_beard_1_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_1_pyellow2.png");
  background-position: -25px -15px;
}

.hair_beard_2_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue2.png");
}

.customize-option.hair_beard_2_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pblue2.png");
  background-position: -25px -15px;
}

.hair_beard_2_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen2.png");
}

.customize-option.hair_beard_2_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pgreen2.png");
  background-position: -25px -15px;
}

.hair_beard_2_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange2.png");
}

.customize-option.hair_beard_2_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_porange2.png");
  background-position: -25px -15px;
}

.hair_beard_2_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink2.png");
}

.customize-option.hair_beard_2_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppink2.png");
  background-position: -25px -15px;
}

.hair_beard_2_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple2.png");
}

.customize-option.hair_beard_2_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_ppurple2.png");
  background-position: -25px -15px;
}

.hair_beard_2_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow2.png");
}

.customize-option.hair_beard_2_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_2_pyellow2.png");
  background-position: -25px -15px;
}

.hair_beard_3_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue2.png");
}

.customize-option.hair_beard_3_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pblue2.png");
  background-position: -25px -15px;
}

.hair_beard_3_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen2.png");
}

.customize-option.hair_beard_3_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pgreen2.png");
  background-position: -25px -15px;
}

.hair_beard_3_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange2.png");
}

.customize-option.hair_beard_3_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_porange2.png");
  background-position: -25px -15px;
}

.hair_beard_3_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink2.png");
}

.customize-option.hair_beard_3_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppink2.png");
  background-position: -25px -15px;
}

.hair_beard_3_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple2.png");
}

.customize-option.hair_beard_3_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_ppurple2.png");
  background-position: -25px -15px;
}

.hair_beard_3_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow2.png");
}

.customize-option.hair_beard_3_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_beard_3_pyellow2.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue2.png");
}

.customize-option.hair_mustache_1_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pblue2.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen2.png");
}

.customize-option.hair_mustache_1_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pgreen2.png");
  background-position: -25px -15px;
}

.hair_mustache_1_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange2.png");
}

.customize-option.hair_mustache_1_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_porange2.png");
  background-position: -25px -15px;
}

.hair_mustache_1_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink2.png");
}

.customize-option.hair_mustache_1_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppink2.png");
  background-position: -25px -15px;
}

.hair_mustache_1_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple2.png");
}

.customize-option.hair_mustache_1_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_ppurple2.png");
  background-position: -25px -15px;
}

.hair_mustache_1_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow2.png");
}

.customize-option.hair_mustache_1_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_1_pyellow2.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pblue2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue2.png");
}

.customize-option.hair_mustache_2_pblue2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pblue2.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pgreen2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen2.png");
}

.customize-option.hair_mustache_2_pgreen2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pgreen2.png");
  background-position: -25px -15px;
}

.hair_mustache_2_porange2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange2.png");
}

.customize-option.hair_mustache_2_porange2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_porange2.png");
  background-position: -25px -15px;
}

.hair_mustache_2_ppink2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink2.png");
}

.customize-option.hair_mustache_2_ppink2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppink2.png");
  background-position: -25px -15px;
}

.hair_mustache_2_ppurple2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple2.png");
}

.customize-option.hair_mustache_2_ppurple2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_ppurple2.png");
  background-position: -25px -15px;
}

.hair_mustache_2_pyellow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow2.png");
}

.customize-option.hair_mustache_2_pyellow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/hair_mustache_2_pyellow2.png");
  background-position: -25px -15px;
}

.broad_shirt_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_black.png");
}

.customize-option.broad_shirt_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_black.png");
  background-position: -29px -42px;
}

.broad_shirt_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_blue.png");
}

.customize-option.broad_shirt_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_blue.png");
  background-position: -29px -42px;
}

.broad_shirt_convict {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_convict.png");
}

.customize-option.broad_shirt_convict {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_convict.png");
  background-position: -29px -42px;
}

.broad_shirt_cross {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_cross.png");
}

.customize-option.broad_shirt_cross {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_cross.png");
  background-position: -29px -42px;
}

.broad_shirt_fire {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_fire.png");
}

.customize-option.broad_shirt_fire {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_fire.png");
  background-position: -29px -42px;
}

.broad_shirt_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_green.png");
}

.customize-option.broad_shirt_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_green.png");
  background-position: -29px -42px;
}

.broad_shirt_horizon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_horizon.png");
}

.customize-option.broad_shirt_horizon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_horizon.png");
  background-position: -29px -42px;
}

.broad_shirt_ocean {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_ocean.png");
}

.customize-option.broad_shirt_ocean {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_ocean.png");
  background-position: -29px -42px;
}

.broad_shirt_pink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_pink.png");
}

.customize-option.broad_shirt_pink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_pink.png");
  background-position: -29px -42px;
}

.broad_shirt_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_purple.png");
}

.customize-option.broad_shirt_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_purple.png");
  background-position: -29px -42px;
}

.broad_shirt_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_rainbow.png");
}

.customize-option.broad_shirt_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_rainbow.png");
  background-position: -29px -42px;
}

.broad_shirt_redblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_redblue.png");
}

.customize-option.broad_shirt_redblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_redblue.png");
  background-position: -29px -42px;
}

.broad_shirt_thunder {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_thunder.png");
}

.customize-option.broad_shirt_thunder {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_thunder.png");
  background-position: -29px -42px;
}

.broad_shirt_tropical {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_tropical.png");
}

.customize-option.broad_shirt_tropical {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_tropical.png");
  background-position: -29px -42px;
}

.broad_shirt_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_white.png");
}

.customize-option.broad_shirt_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_white.png");
  background-position: -29px -42px;
}

.broad_shirt_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_yellow.png");
}

.customize-option.broad_shirt_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_yellow.png");
  background-position: -29px -42px;
}

.broad_shirt_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_zombie.png");
}

.customize-option.broad_shirt_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_shirt_zombie.png");
  background-position: -29px -42px;
}

.slim_shirt_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_black.png");
}

.customize-option.slim_shirt_black {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_black.png");
  background-position: -29px -42px;
}

.slim_shirt_blue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_blue.png");
}

.customize-option.slim_shirt_blue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_blue.png");
  background-position: -29px -42px;
}

.slim_shirt_convict {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_convict.png");
}

.customize-option.slim_shirt_convict {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_convict.png");
  background-position: -29px -42px;
}

.slim_shirt_cross {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_cross.png");
}

.customize-option.slim_shirt_cross {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_cross.png");
  background-position: -29px -42px;
}

.slim_shirt_fire {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_fire.png");
}

.customize-option.slim_shirt_fire {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_fire.png");
  background-position: -29px -42px;
}

.slim_shirt_green {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_green.png");
}

.customize-option.slim_shirt_green {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_green.png");
  background-position: -29px -42px;
}

.slim_shirt_horizon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_horizon.png");
}

.customize-option.slim_shirt_horizon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_horizon.png");
  background-position: -29px -42px;
}

.slim_shirt_ocean {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_ocean.png");
}

.customize-option.slim_shirt_ocean {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_ocean.png");
  background-position: -29px -42px;
}

.slim_shirt_pink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_pink.png");
}

.customize-option.slim_shirt_pink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_pink.png");
  background-position: -29px -42px;
}

.slim_shirt_purple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_purple.png");
}

.customize-option.slim_shirt_purple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_purple.png");
  background-position: -29px -42px;
}

.slim_shirt_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_rainbow.png");
}

.customize-option.slim_shirt_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_rainbow.png");
  background-position: -29px -42px;
}

.slim_shirt_redblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_redblue.png");
}

.customize-option.slim_shirt_redblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_redblue.png");
  background-position: -29px -42px;
}

.slim_shirt_thunder {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_thunder.png");
}

.customize-option.slim_shirt_thunder {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_thunder.png");
  background-position: -29px -42px;
}

.slim_shirt_tropical {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_tropical.png");
}

.customize-option.slim_shirt_tropical {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_tropical.png");
  background-position: -29px -42px;
}

.slim_shirt_white {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_white.png");
}

.customize-option.slim_shirt_white {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_white.png");
  background-position: -29px -42px;
}

.slim_shirt_yellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_yellow.png");
}

.customize-option.slim_shirt_yellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_yellow.png");
  background-position: -29px -42px;
}

.slim_shirt_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_zombie.png");
}

.customize-option.slim_shirt_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_shirt_zombie.png");
  background-position: -29px -42px;
}

.skin_0ff591 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591.png");
}

.customize-option.skin_0ff591 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591.png");
  background-position: -25px -15px;
}

.skin_0ff591_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591_sleep.png");
}

.customize-option.skin_0ff591_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_0ff591_sleep.png");
  background-position: -25px -15px;
}

.skin_2b43f6 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6.png");
}

.customize-option.skin_2b43f6 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6.png");
  background-position: -25px -15px;
}

.skin_2b43f6_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6_sleep.png");
}

.customize-option.skin_2b43f6_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_2b43f6_sleep.png");
  background-position: -25px -15px;
}

.skin_6bd049 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049.png");
}

.customize-option.skin_6bd049 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049.png");
  background-position: -25px -15px;
}

.skin_6bd049_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049_sleep.png");
}

.customize-option.skin_6bd049_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_6bd049_sleep.png");
  background-position: -25px -15px;
}

.skin_800ed0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0.png");
}

.customize-option.skin_800ed0 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0.png");
  background-position: -25px -15px;
}

.skin_800ed0_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0_sleep.png");
}

.customize-option.skin_800ed0_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_800ed0_sleep.png");
  background-position: -25px -15px;
}

.skin_915533 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533.png");
}

.customize-option.skin_915533 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533.png");
  background-position: -25px -15px;
}

.skin_915533_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533_sleep.png");
}

.customize-option.skin_915533_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_915533_sleep.png");
  background-position: -25px -15px;
}

.skin_98461a {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a.png");
}

.customize-option.skin_98461a {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a.png");
  background-position: -25px -15px;
}

.skin_98461a_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a_sleep.png");
}

.customize-option.skin_98461a_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_98461a_sleep.png");
  background-position: -25px -15px;
}

.skin_aurora {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora.png");
}

.customize-option.skin_aurora {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora.png");
  background-position: -25px -15px;
}

.skin_aurora_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora_sleep.png");
}

.customize-option.skin_aurora_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_aurora_sleep.png");
  background-position: -25px -15px;
}

.skin_bear {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear.png");
}

.customize-option.skin_bear {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear.png");
  background-position: -25px -15px;
}

.skin_bear_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear_sleep.png");
}

.customize-option.skin_bear_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_bear_sleep.png");
  background-position: -25px -15px;
}

.skin_c06534 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534.png");
}

.customize-option.skin_c06534 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534.png");
  background-position: -25px -15px;
}

.skin_c06534_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534_sleep.png");
}

.customize-option.skin_c06534_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c06534_sleep.png");
  background-position: -25px -15px;
}

.skin_c3e1dc {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc.png");
}

.customize-option.skin_c3e1dc {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc.png");
  background-position: -25px -15px;
}

.skin_c3e1dc_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc_sleep.png");
}

.customize-option.skin_c3e1dc_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_c3e1dc_sleep.png");
  background-position: -25px -15px;
}

.skin_cactus {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus.png");
}

.customize-option.skin_cactus {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus.png");
  background-position: -25px -15px;
}

.skin_cactus_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus_sleep.png");
}

.customize-option.skin_cactus_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_cactus_sleep.png");
  background-position: -25px -15px;
}

.skin_candycorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn.png");
}

.customize-option.skin_candycorn {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn.png");
  background-position: -25px -15px;
}

.skin_candycorn_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn_sleep.png");
}

.customize-option.skin_candycorn_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_candycorn_sleep.png");
  background-position: -25px -15px;
}

.skin_clownfish {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish.png");
}

.customize-option.skin_clownfish {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish.png");
  background-position: -25px -15px;
}

.skin_clownfish_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish_sleep.png");
}

.customize-option.skin_clownfish_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_clownfish_sleep.png");
  background-position: -25px -15px;
}

.skin_d7a9f7 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7.png");
}

.customize-option.skin_d7a9f7 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7.png");
  background-position: -25px -15px;
}

.skin_d7a9f7_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7_sleep.png");
}

.customize-option.skin_d7a9f7_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_d7a9f7_sleep.png");
  background-position: -25px -15px;
}

.skin_dapper {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper.png");
}

.customize-option.skin_dapper {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper.png");
  background-position: -25px -15px;
}

.skin_dapper_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper_sleep.png");
}

.customize-option.skin_dapper_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_dapper_sleep.png");
  background-position: -25px -15px;
}

.skin_ddc994 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994.png");
}

.customize-option.skin_ddc994 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994.png");
  background-position: -25px -15px;
}

.skin_ddc994_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994_sleep.png");
}

.customize-option.skin_ddc994_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ddc994_sleep.png");
  background-position: -25px -15px;
}

.skin_deepocean {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean.png");
}

.customize-option.skin_deepocean {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean.png");
  background-position: -25px -15px;
}

.skin_deepocean_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean_sleep.png");
}

.customize-option.skin_deepocean_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_deepocean_sleep.png");
  background-position: -25px -15px;
}

.skin_ea8349 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349.png");
}

.customize-option.skin_ea8349 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349.png");
  background-position: -25px -15px;
}

.skin_ea8349_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349_sleep.png");
}

.customize-option.skin_ea8349_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ea8349_sleep.png");
  background-position: -25px -15px;
}

.skin_eb052b {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b.png");
}

.customize-option.skin_eb052b {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b.png");
  background-position: -25px -15px;
}

.skin_eb052b_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b_sleep.png");
}

.customize-option.skin_eb052b_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_eb052b_sleep.png");
  background-position: -25px -15px;
}

.skin_f5a76e {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e.png");
}

.customize-option.skin_f5a76e {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e.png");
  background-position: -25px -15px;
}

.skin_f5a76e_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e_sleep.png");
}

.customize-option.skin_f5a76e_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5a76e_sleep.png");
  background-position: -25px -15px;
}

.skin_f5d70f {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f.png");
}

.customize-option.skin_f5d70f {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f.png");
  background-position: -25px -15px;
}

.skin_f5d70f_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f_sleep.png");
}

.customize-option.skin_f5d70f_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f5d70f_sleep.png");
  background-position: -25px -15px;
}

.skin_f69922 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922.png");
}

.customize-option.skin_f69922 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922.png");
  background-position: -25px -15px;
}

.skin_f69922_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922_sleep.png");
}

.customize-option.skin_f69922_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_f69922_sleep.png");
  background-position: -25px -15px;
}

.skin_festive {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive.png");
}

.customize-option.skin_festive {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive.png");
  background-position: -25px -15px;
}

.skin_festive_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive_sleep.png");
}

.customize-option.skin_festive_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_festive_sleep.png");
  background-position: -25px -15px;
}

.skin_fox {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox.png");
}

.customize-option.skin_fox {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox.png");
  background-position: -25px -15px;
}

.skin_fox_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox_sleep.png");
}

.customize-option.skin_fox_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_fox_sleep.png");
  background-position: -25px -15px;
}

.skin_ghost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost.png");
}

.customize-option.skin_ghost {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost.png");
  background-position: -25px -15px;
}

.skin_ghost_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost_sleep.png");
}

.customize-option.skin_ghost_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ghost_sleep.png");
  background-position: -25px -15px;
}

.skin_holly {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly.png");
}

.customize-option.skin_holly {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly.png");
  background-position: -25px -15px;
}

.skin_holly_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly_sleep.png");
}

.customize-option.skin_holly_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_holly_sleep.png");
  background-position: -25px -15px;
}

.skin_lion {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion.png");
}

.customize-option.skin_lion {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion.png");
  background-position: -25px -15px;
}

.skin_lion_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion_sleep.png");
}

.customize-option.skin_lion_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_lion_sleep.png");
  background-position: -25px -15px;
}

.skin_merblue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue.png");
}

.customize-option.skin_merblue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue.png");
  background-position: -25px -15px;
}

.skin_merblue_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue_sleep.png");
}

.customize-option.skin_merblue_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merblue_sleep.png");
  background-position: -25px -15px;
}

.skin_mergold {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold.png");
}

.customize-option.skin_mergold {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold.png");
  background-position: -25px -15px;
}

.skin_mergold_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold_sleep.png");
}

.customize-option.skin_mergold_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergold_sleep.png");
  background-position: -25px -15px;
}

.skin_mergreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen.png");
}

.customize-option.skin_mergreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen.png");
  background-position: -25px -15px;
}

.skin_mergreen_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen_sleep.png");
}

.customize-option.skin_mergreen_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_mergreen_sleep.png");
  background-position: -25px -15px;
}

.skin_merruby {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby.png");
}

.customize-option.skin_merruby {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby.png");
  background-position: -25px -15px;
}

.skin_merruby_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby_sleep.png");
}

.customize-option.skin_merruby_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_merruby_sleep.png");
  background-position: -25px -15px;
}

.skin_monster {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster.png");
}

.customize-option.skin_monster {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster.png");
  background-position: -25px -15px;
}

.skin_monster_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster_sleep.png");
}

.customize-option.skin_monster_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_monster_sleep.png");
  background-position: -25px -15px;
}

.skin_ogre {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre.png");
}

.customize-option.skin_ogre {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre.png");
  background-position: -25px -15px;
}

.skin_ogre_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre_sleep.png");
}

.customize-option.skin_ogre_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_ogre_sleep.png");
  background-position: -25px -15px;
}

.skin_panda {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda.png");
}

.customize-option.skin_panda {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda.png");
  background-position: -25px -15px;
}

.skin_panda_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda_sleep.png");
}

.customize-option.skin_panda_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_panda_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelBlue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue.png");
}

.customize-option.skin_pastelBlue {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue.png");
  background-position: -25px -15px;
}

.skin_pastelBlue_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue_sleep.png");
}

.customize-option.skin_pastelBlue_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelBlue_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelGreen {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen.png");
}

.customize-option.skin_pastelGreen {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen.png");
  background-position: -25px -15px;
}

.skin_pastelGreen_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen_sleep.png");
}

.customize-option.skin_pastelGreen_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelGreen_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelOrange {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange.png");
}

.customize-option.skin_pastelOrange {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange.png");
  background-position: -25px -15px;
}

.skin_pastelOrange_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange_sleep.png");
}

.customize-option.skin_pastelOrange_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelOrange_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelPink {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink.png");
}

.customize-option.skin_pastelPink {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink.png");
  background-position: -25px -15px;
}

.skin_pastelPink_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink_sleep.png");
}

.customize-option.skin_pastelPink_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPink_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelPurple {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple.png");
}

.customize-option.skin_pastelPurple {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple.png");
  background-position: -25px -15px;
}

.skin_pastelPurple_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple_sleep.png");
}

.customize-option.skin_pastelPurple_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelPurple_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelRainbowChevron {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron.png");
}

.customize-option.skin_pastelRainbowChevron {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron.png");
  background-position: -25px -15px;
}

.skin_pastelRainbowChevron_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron_sleep.png");
}

.customize-option.skin_pastelRainbowChevron_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowChevron_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelRainbowDiagonal {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal.png");
}

.customize-option.skin_pastelRainbowDiagonal {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal.png");
  background-position: -25px -15px;
}

.skin_pastelRainbowDiagonal_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal_sleep.png");
}

.customize-option.skin_pastelRainbowDiagonal_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelRainbowDiagonal_sleep.png");
  background-position: -25px -15px;
}

.skin_pastelYellow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow.png");
}

.customize-option.skin_pastelYellow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow.png");
  background-position: -25px -15px;
}

.skin_pastelYellow_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow_sleep.png");
}

.customize-option.skin_pastelYellow_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pastelYellow_sleep.png");
  background-position: -25px -15px;
}

.skin_pig {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig.png");
}

.customize-option.skin_pig {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig.png");
  background-position: -25px -15px;
}

.skin_pig_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig_sleep.png");
}

.customize-option.skin_pig_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pig_sleep.png");
  background-position: -25px -15px;
}

.skin_polar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar.png");
}

.customize-option.skin_polar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar.png");
  background-position: -25px -15px;
}

.skin_polar_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar_sleep.png");
}

.customize-option.skin_polar_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_polar_sleep.png");
  background-position: -25px -15px;
}

.skin_pumpkin {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin.png");
}

.customize-option.skin_pumpkin {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin.png");
  background-position: -25px -15px;
}

.skin_pumpkin2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2.png");
}

.customize-option.skin_pumpkin2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2.png");
  background-position: -25px -15px;
}

.skin_pumpkin2_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2_sleep.png");
}

.customize-option.skin_pumpkin2_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin2_sleep.png");
  background-position: -25px -15px;
}

.skin_pumpkin_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin_sleep.png");
}

.customize-option.skin_pumpkin_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_pumpkin_sleep.png");
  background-position: -25px -15px;
}

.skin_rainbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow.png");
}

.customize-option.skin_rainbow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow.png");
  background-position: -25px -15px;
}

.skin_rainbow_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow_sleep.png");
}

.customize-option.skin_rainbow_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_rainbow_sleep.png");
  background-position: -25px -15px;
}

.skin_reptile {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile.png");
}

.customize-option.skin_reptile {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile.png");
  background-position: -25px -15px;
}

.skin_reptile_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile_sleep.png");
}

.customize-option.skin_reptile_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_reptile_sleep.png");
  background-position: -25px -15px;
}

.skin_shadow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow.png");
}

.customize-option.skin_shadow {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow.png");
  background-position: -25px -15px;
}

.skin_shadow2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2.png");
}

.customize-option.skin_shadow2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2.png");
  background-position: -25px -15px;
}

.skin_shadow2_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2_sleep.png");
}

.customize-option.skin_shadow2_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow2_sleep.png");
  background-position: -25px -15px;
}

.skin_shadow_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow_sleep.png");
}

.customize-option.skin_shadow_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shadow_sleep.png");
  background-position: -25px -15px;
}

.skin_shark {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark.png");
}

.customize-option.skin_shark {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark.png");
  background-position: -25px -15px;
}

.skin_shark_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark_sleep.png");
}

.customize-option.skin_shark_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_shark_sleep.png");
  background-position: -25px -15px;
}

.skin_skeleton {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton.png");
}

.customize-option.skin_skeleton {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton.png");
  background-position: -25px -15px;
}

.skin_skeleton2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2.png");
}

.customize-option.skin_skeleton2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2.png");
  background-position: -25px -15px;
}

.skin_skeleton2_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2_sleep.png");
}

.customize-option.skin_skeleton2_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton2_sleep.png");
  background-position: -25px -15px;
}

.skin_skeleton_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton_sleep.png");
}

.customize-option.skin_skeleton_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_skeleton_sleep.png");
  background-position: -25px -15px;
}

.skin_snowy {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy.png");
}

.customize-option.skin_snowy {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy.png");
  background-position: -25px -15px;
}

.skin_snowy_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy_sleep.png");
}

.customize-option.skin_snowy_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_snowy_sleep.png");
  background-position: -25px -15px;
}

.skin_sugar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar.png");
}

.customize-option.skin_sugar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar.png");
  background-position: -25px -15px;
}

.skin_sugar_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar_sleep.png");
}

.customize-option.skin_sugar_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_sugar_sleep.png");
  background-position: -25px -15px;
}

.skin_tiger {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger.png");
}

.customize-option.skin_tiger {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger.png");
  background-position: -25px -15px;
}

.skin_tiger_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger_sleep.png");
}

.customize-option.skin_tiger_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tiger_sleep.png");
  background-position: -25px -15px;
}

.skin_transparent {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent.png");
}

.customize-option.skin_transparent {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent.png");
  background-position: -25px -15px;
}

.skin_transparent_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent_sleep.png");
}

.customize-option.skin_transparent_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_transparent_sleep.png");
  background-position: -25px -15px;
}

.skin_tropicalwater {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater.png");
}

.customize-option.skin_tropicalwater {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater.png");
  background-position: -25px -15px;
}

.skin_tropicalwater_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater_sleep.png");
}

.customize-option.skin_tropicalwater_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_tropicalwater_sleep.png");
  background-position: -25px -15px;
}

.skin_winterstar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar.png");
}

.customize-option.skin_winterstar {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar.png");
  background-position: -25px -15px;
}

.skin_winterstar_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar_sleep.png");
}

.customize-option.skin_winterstar_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_winterstar_sleep.png");
  background-position: -25px -15px;
}

.skin_wolf {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf.png");
}

.customize-option.skin_wolf {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf.png");
  background-position: -25px -15px;
}

.skin_wolf_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf_sleep.png");
}

.customize-option.skin_wolf_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_wolf_sleep.png");
  background-position: -25px -15px;
}

.skin_zombie {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie.png");
}

.customize-option.skin_zombie {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie.png");
  background-position: -25px -15px;
}

.skin_zombie2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2.png");
}

.customize-option.skin_zombie2 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2.png");
  background-position: -25px -15px;
}

.skin_zombie2_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2_sleep.png");
}

.customize-option.skin_zombie2_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie2_sleep.png");
  background-position: -25px -15px;
}

.skin_zombie_sleep {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie_sleep.png");
}

.customize-option.skin_zombie_sleep {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/skin_zombie_sleep.png");
  background-position: -25px -15px;
}

.body_armoire_clownsBowtie {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_armoire_clownsBowtie.png");
}

.body_armoire_cozyScarf {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_armoire_cozyScarf.png");
}

.body_armoire_lifeguardWhistle {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_armoire_lifeguardWhistle.png");
}

.broad_armor_armoire_alchemistsRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_alchemistsRobe.png");
}

.broad_armor_armoire_antiProcrastinationArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_antiProcrastinationArmor.png");
}

.broad_armor_armoire_astronomersRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_astronomersRobe.png");
}

.broad_armor_armoire_autumnEnchantersCloak {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_autumnEnchantersCloak.png");
}

.broad_armor_armoire_bagpipersKilt {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_bagpipersKilt.png");
}

.broad_armor_armoire_barristerRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_barristerRobes.png");
}

.broad_armor_armoire_baseballUniform {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_baseballUniform.png");
}

.broad_armor_armoire_basicArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_basicArcherArmor.png");
}

.broad_armor_armoire_basketballUniform {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_basketballUniform.png");
}

.broad_armor_armoire_bathtub {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_bathtub.png");
}

.broad_armor_armoire_blueMoonShozoku {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_blueMoonShozoku.png");
}

.broad_armor_armoire_bluePartyDress {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_bluePartyDress.png");
}

.broad_armor_armoire_boatingJacket {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_boatingJacket.png");
}

.broad_armor_armoire_boxArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_boxArmor.png");
}

.broad_armor_armoire_candlestickMakerOutfit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_candlestickMakerOutfit.png");
}

.broad_armor_armoire_cannoneerRags {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_cannoneerRags.png");
}

.broad_armor_armoire_chefsJacket {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_chefsJacket.png");
}

.broad_armor_armoire_clownsMotley {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_clownsMotley.png");
}

.broad_armor_armoire_coachDriverLivery {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_coachDriverLivery.png");
}

.broad_armor_armoire_cobblersCoveralls {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_cobblersCoveralls.png");
}

.broad_armor_armoire_coverallsOfBookbinding {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_coverallsOfBookbinding.png");
}

.broad_armor_armoire_crystalCrescentRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_crystalCrescentRobes.png");
}

.broad_armor_armoire_doubletOfClubs {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_doubletOfClubs.png");
}

.broad_armor_armoire_dragonTamerArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_dragonTamerArmor.png");
}

.broad_armor_armoire_dressingGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_dressingGown.png");
}

.broad_armor_armoire_duffleCoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_duffleCoat.png");
}

.broad_armor_armoire_falconerArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_falconerArmor.png");
}

.broad_armor_armoire_fancyPirateSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_fancyPirateSuit.png");
}

.broad_armor_armoire_farrierOutfit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_farrierOutfit.png");
}

.broad_armor_armoire_fiddlersCoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_fiddlersCoat.png");
}

.broad_armor_armoire_flutteryFrock {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_flutteryFrock.png");
}

.broad_armor_armoire_gardenersOveralls {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_gardenersOveralls.png");
}

.broad_armor_armoire_gladiatorArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_gladiatorArmor.png");
}

.broad_armor_armoire_glassblowersCoveralls {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_glassblowersCoveralls.png");
}

.broad_armor_armoire_goldenToga {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_goldenToga.png");
}

.broad_armor_armoire_gownOfHearts {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_gownOfHearts.png");
}

.broad_armor_armoire_graduateRobe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_graduateRobe.png");
}

.broad_armor_armoire_greenFestivalYukata {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_greenFestivalYukata.png");
}

.broad_armor_armoire_guardiansGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_guardiansGown.png");
}

.broad_armor_armoire_heraldsTunic {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_heraldsTunic.png");
}

.broad_armor_armoire_heroicHerbalistRobe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_heroicHerbalistRobe.png");
}

.broad_armor_armoire_hornedIronArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_hornedIronArmor.png");
}

.broad_armor_armoire_invernessCape {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_invernessCape.png");
}

.broad_armor_armoire_ironBlueArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_ironBlueArcherArmor.png");
}

.broad_armor_armoire_jadeArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jadeArmor.png");
}

.broad_armor_armoire_jesterCostume {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jesterCostume.png");
}

.broad_armor_armoire_jeweledArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jeweledArcherArmor.png");
}

.broad_armor_armoire_jewelersApron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_jewelersApron.png");
}

.broad_armor_armoire_lamplightersGreatcoat {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_lamplightersGreatcoat.png");
}

.broad_armor_armoire_layerCakeArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_layerCakeArmor.png");
}

.broad_armor_armoire_lunarArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_lunarArmor.png");
}

.broad_armor_armoire_matchMakersApron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_matchMakersApron.png");
}

.broad_armor_armoire_medievalLaundryDress {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_medievalLaundryDress.png");
}

.broad_armor_armoire_medievalLaundryOutfit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_medievalLaundryOutfit.png");
}

.broad_armor_armoire_merchantTunic {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_merchantTunic.png");
}

.broad_armor_armoire_minerOveralls {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_minerOveralls.png");
}

.broad_armor_armoire_mushroomDruidArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_mushroomDruidArmor.png");
}

.broad_armor_armoire_nephriteArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_nephriteArmor.png");
}

.broad_armor_armoire_ogreArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_ogreArmor.png");
}

.broad_armor_armoire_paintersApron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_paintersApron.png");
}

.broad_armor_armoire_pirateOutfit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_pirateOutfit.png");
}

.broad_armor_armoire_piraticalPrincessGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_piraticalPrincessGown.png");
}

.broad_armor_armoire_plagueDoctorOvercoat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_plagueDoctorOvercoat.png");
}

.broad_armor_armoire_ramFleeceRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_ramFleeceRobes.png");
}

.broad_armor_armoire_rancherRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_rancherRobes.png");
}

.broad_armor_armoire_redPartyDress {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_redPartyDress.png");
}

.broad_armor_armoire_robeOfDiamonds {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_robeOfDiamonds.png");
}

.broad_armor_armoire_robeOfSpades {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_robeOfSpades.png");
}

.broad_armor_armoire_royalRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_royalRobes.png");
}

.broad_armor_armoire_scribesRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_scribesRobe.png");
}

.broad_armor_armoire_shadowMastersRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shadowMastersRobe.png");
}

.broad_armor_armoire_shawlCollarCoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shawlCollarCoat.png");
}

.broad_armor_armoire_sheetGhostCostume {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_sheetGhostCostume.png");
}

.broad_armor_armoire_shepherdRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shepherdRobes.png");
}

.broad_armor_armoire_shootingStarCostume {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_shootingStarCostume.png");
}

.broad_armor_armoire_softBlackSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softBlackSuit.png");
}

.broad_armor_armoire_softBlueSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softBlueSuit.png");
}

.broad_armor_armoire_softGreenSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softGreenSuit.png");
}

.broad_armor_armoire_softPinkSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softPinkSuit.png");
}

.broad_armor_armoire_softRedSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softRedSuit.png");
}

.broad_armor_armoire_softVioletSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_softVioletSuit.png");
}

.broad_armor_armoire_strawRaincoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_strawRaincoat.png");
}

.broad_armor_armoire_stripedSwimsuit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_stripedSwimsuit.png");
}

.broad_armor_armoire_swanDancerTutu {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_swanDancerTutu.png");
}

.broad_armor_armoire_teaGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_teaGown.png");
}

.broad_armor_armoire_vermilionArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_vermilionArcherArmor.png");
}

.broad_armor_armoire_vernalVestment {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_vernalVestment.png");
}

.broad_armor_armoire_vikingTunic {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_vikingTunic.png");
}

.broad_armor_armoire_woodElfArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_woodElfArmor.png");
}

.broad_armor_armoire_wovenRobes {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_wovenRobes.png");
}

.broad_armor_armoire_yellowPartyDress {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_armoire_yellowPartyDress.png");
}

.eyewear_armoire_clownsNose {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_clownsNose.png");
}

.eyewear_armoire_comedyMask {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_comedyMask.png");
}

.eyewear_armoire_goofyGlasses {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_goofyGlasses.png");
}

.eyewear_armoire_jewelersEyeLoupe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_jewelersEyeLoupe.png");
}

.eyewear_armoire_plagueDoctorMask {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_plagueDoctorMask.png");
}

.eyewear_armoire_tragedyMask {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_armoire_tragedyMask.png");
}

.headAccessory_armoire_comicalArrow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_armoire_comicalArrow.png");
}

.headAccessory_armoire_gogglesOfBookbinding {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_armoire_gogglesOfBookbinding.png");
}

.head_armoire_alchemistsHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_alchemistsHat.png");
}

.head_armoire_antiProcrastinationHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_antiProcrastinationHelm.png");
}

.head_armoire_astronomersHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_astronomersHat.png");
}

.head_armoire_barristerWig {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_barristerWig.png");
}

.head_armoire_baseballCap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_baseballCap.png");
}

.head_armoire_basicArcherCap {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_basicArcherCap.png");
}

.head_armoire_beaniePropellerHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_beaniePropellerHat.png");
}

.head_armoire_bigWig {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_bigWig.png");
}

.head_armoire_birdsNest {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_birdsNest.png");
}

.head_armoire_blackCat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blackCat.png");
}

.head_armoire_blackFloppyHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blackFloppyHat.png");
}

.head_armoire_blueFloppyHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blueFloppyHat.png");
}

.head_armoire_blueHairbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blueHairbow.png");
}

.head_armoire_blueMoonHelm {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_blueMoonHelm.png");
}

.head_armoire_boaterHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_boaterHat.png");
}

.head_armoire_candlestickMakerHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_candlestickMakerHat.png");
}

.head_armoire_cannoneerBandanna {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_cannoneerBandanna.png");
}

.head_armoire_capOfClubs {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_capOfClubs.png");
}

.head_armoire_clownsWig {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_clownsWig.png");
}

.head_armoire_coachDriversHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_coachDriversHat.png");
}

.head_armoire_crownOfDiamonds {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_crownOfDiamonds.png");
}

.head_armoire_crownOfHearts {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_crownOfHearts.png");
}

.head_armoire_crystalCrescentHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_crystalCrescentHat.png");
}

.head_armoire_deerstalkerCap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_deerstalkerCap.png");
}

.head_armoire_dragonTamerHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_dragonTamerHelm.png");
}

.head_armoire_earflapHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_earflapHat.png");
}

.head_armoire_falconerCap {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_falconerCap.png");
}

.head_armoire_fancyPirateHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_fancyPirateHat.png");
}

.head_armoire_fiddlersCap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_fiddlersCap.png");
}

.head_armoire_flutteryWig {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_flutteryWig.png");
}

.head_armoire_frostedHelm {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_frostedHelm.png");
}

.head_armoire_gardenersSunHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_gardenersSunHat.png");
}

.head_armoire_gladiatorHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_gladiatorHelm.png");
}

.head_armoire_glassblowersHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_glassblowersHat.png");
}

.head_armoire_glengarry {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_glengarry.png");
}

.head_armoire_goldenLaurels {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_goldenLaurels.png");
}

.head_armoire_graduateCap {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_graduateCap.png");
}

.head_armoire_greenFloppyHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_greenFloppyHat.png");
}

.head_armoire_guardiansBonnet {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_guardiansBonnet.png");
}

.head_armoire_heraldsCap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_heraldsCap.png");
}

.head_armoire_heroicHerbalistCrispinette {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_heroicHerbalistCrispinette.png");
}

.head_armoire_hornedIronHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_hornedIronHelm.png");
}

.head_armoire_hornsOfAutumn {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_hornsOfAutumn.png");
}

.head_armoire_ironBlueArcherHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_ironBlueArcherHelm.png");
}

.head_armoire_jadeHelm {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_jadeHelm.png");
}

.head_armoire_jesterCap {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_jesterCap.png");
}

.head_armoire_jeweledArcherHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_jeweledArcherHelm.png");
}

.head_armoire_lamplightersTopHat {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_lamplightersTopHat.png");
}

.head_armoire_lunarCrown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_lunarCrown.png");
}

.head_armoire_matchMakersBeret {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_matchMakersBeret.png");
}

.head_armoire_medievalLaundryCap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_medievalLaundryCap.png");
}

.head_armoire_medievalLaundryHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_medievalLaundryHat.png");
}

.head_armoire_merchantChaperon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_merchantChaperon.png");
}

.head_armoire_minerHelmet {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_minerHelmet.png");
}

.head_armoire_mushroomDruidCap {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_mushroomDruidCap.png");
}

.head_armoire_nephriteHelm {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_nephriteHelm.png");
}

.head_armoire_nightcap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_nightcap.png");
}

.head_armoire_ogreMask {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_ogreMask.png");
}

.head_armoire_orangeCat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_orangeCat.png");
}

.head_armoire_paintersBeret {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_paintersBeret.png");
}

.head_armoire_paperBag {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_paperBag.png");
}

.head_armoire_pinkFloppyHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_pinkFloppyHat.png");
}

.head_armoire_piraticalPrincessHeaddress {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_piraticalPrincessHeaddress.png");
}

.head_armoire_plagueDoctorHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_plagueDoctorHat.png");
}

.head_armoire_ramHeaddress {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_ramHeaddress.png");
}

.head_armoire_rancherHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_rancherHat.png");
}

.head_armoire_redFloppyHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_redFloppyHat.png");
}

.head_armoire_redHairbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_redHairbow.png");
}

.head_armoire_regalCrown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_regalCrown.png");
}

.head_armoire_royalCrown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_royalCrown.png");
}

.head_armoire_rubberDucky {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_rubberDucky.png");
}

.head_armoire_shadowMastersHood {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_shadowMastersHood.png");
}

.head_armoire_shepherdHeaddress {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_shepherdHeaddress.png");
}

.head_armoire_shootingStarCrown {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_shootingStarCrown.png");
}

.head_armoire_strawRainHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_strawRainHat.png");
}

.head_armoire_swanFeatherCrown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_swanFeatherCrown.png");
}

.head_armoire_teaHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_teaHat.png");
}

.head_armoire_toqueBlanche {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_toqueBlanche.png");
}

.head_armoire_tricornHat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_tricornHat.png");
}

.head_armoire_veilOfSpades {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_veilOfSpades.png");
}

.head_armoire_vermilionArcherHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_vermilionArcherHelm.png");
}

.head_armoire_vernalHennin {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_vernalHennin.png");
}

.head_armoire_vikingHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_vikingHelm.png");
}

.head_armoire_violetFloppyHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_violetFloppyHat.png");
}

.head_armoire_woodElfHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_woodElfHelm.png");
}

.head_armoire_yellowHairbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_armoire_yellowHairbow.png");
}

.shield_armoire_alchemistsScale {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_alchemistsScale.png");
}

.shield_armoire_antiProcrastinationShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_antiProcrastinationShield.png");
}

.shield_armoire_bagpipes {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_bagpipes.png");
}

.shield_armoire_baseballGlove {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_baseballGlove.png");
}

.shield_armoire_basketball {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_basketball.png");
}

.shield_armoire_birthdayBanner {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_birthdayBanner.png");
}

.shield_armoire_blueCottonCandyFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_blueCottonCandyFood.png");
}

.shield_armoire_blueMoonSai {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_blueMoonSai.png");
}

.shield_armoire_bouncyBubbles {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_bouncyBubbles.png");
}

.shield_armoire_bubblingCauldron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_bubblingCauldron.png");
}

.shield_armoire_chocolateFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_chocolateFood.png");
}

.shield_armoire_clownsBalloons {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_clownsBalloons.png");
}

.shield_armoire_darkAutumnFlame {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_darkAutumnFlame.png");
}

.shield_armoire_dragonTamerShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_dragonTamerShield.png");
}

.shield_armoire_dustpan {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_dustpan.png");
}

.shield_armoire_fancyBlownGlassVase {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fancyBlownGlassVase.png");
}

.shield_armoire_fancyShoe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fancyShoe.png");
}

.shield_armoire_festivalParasol {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_festivalParasol.png");
}

.shield_armoire_fiddle {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fiddle.png");
}

.shield_armoire_fishFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_fishFood.png");
}

.shield_armoire_floralBouquet {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_floralBouquet.png");
}

.shield_armoire_flutteryFan {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_flutteryFan.png");
}

.shield_armoire_gardenersSpade {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_gardenersSpade.png");
}

.shield_armoire_gladiatorShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_gladiatorShield.png");
}

.shield_armoire_goldenBaton {
  width: 99px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_goldenBaton.png");
}

.shield_armoire_handmadeCandlestick {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_handmadeCandlestick.png");
}

.shield_armoire_heraldsMessageScroll {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_heraldsMessageScroll.png");
}

.shield_armoire_hobbyHorse {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_hobbyHorse.png");
}

.shield_armoire_honeyFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_honeyFood.png");
}

.shield_armoire_horseshoe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_horseshoe.png");
}

.shield_armoire_jewelersPliers {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_jewelersPliers.png");
}

.shield_armoire_lifeBuoy {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_lifeBuoy.png");
}

.shield_armoire_masteredShadow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_masteredShadow.png");
}

.shield_armoire_meatFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_meatFood.png");
}

.shield_armoire_medievalLaundry {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_medievalLaundry.png");
}

.shield_armoire_midnightShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_midnightShield.png");
}

.shield_armoire_mightyPizza {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mightyPizza.png");
}

.shield_armoire_mightyQuill {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mightyQuill.png");
}

.shield_armoire_milkFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_milkFood.png");
}

.shield_armoire_mortarAndPestle {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mortarAndPestle.png");
}

.shield_armoire_mushroomDruidShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mushroomDruidShield.png");
}

.shield_armoire_mysticLamp {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_mysticLamp.png");
}

.shield_armoire_paintersPalette {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_paintersPalette.png");
}

.shield_armoire_perchingFalcon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_perchingFalcon.png");
}

.shield_armoire_perfectMatch {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_perfectMatch.png");
}

.shield_armoire_pinkCottonCandyFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_pinkCottonCandyFood.png");
}

.shield_armoire_piratesCompanion {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_piratesCompanion.png");
}

.shield_armoire_piraticalSkullShield {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_piraticalSkullShield.png");
}

.shield_armoire_polishedPocketwatch {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_polishedPocketwatch.png");
}

.shield_armoire_potatoFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_potatoFood.png");
}

.shield_armoire_ramHornShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_ramHornShield.png");
}

.shield_armoire_redRose {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_redRose.png");
}

.shield_armoire_rottenMeatFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_rottenMeatFood.png");
}

.shield_armoire_royalCane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_royalCane.png");
}

.shield_armoire_sandyBucket {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_sandyBucket.png");
}

.shield_armoire_shieldOfDiamonds {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_shieldOfDiamonds.png");
}

.shield_armoire_snareDrum {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_snareDrum.png");
}

.shield_armoire_softBlackPillow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softBlackPillow.png");
}

.shield_armoire_softBluePillow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softBluePillow.png");
}

.shield_armoire_softGreenPillow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softGreenPillow.png");
}

.shield_armoire_softPinkPillow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softPinkPillow.png");
}

.shield_armoire_softRedPillow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softRedPillow.png");
}

.shield_armoire_softVioletPillow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_softVioletPillow.png");
}

.shield_armoire_spanishGuitar {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_spanishGuitar.png");
}

.shield_armoire_strawberryFood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_strawberryFood.png");
}

.shield_armoire_swanFeatherFan {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_swanFeatherFan.png");
}

.shield_armoire_teaKettle {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_teaKettle.png");
}

.shield_armoire_treasureMap {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_treasureMap.png");
}

.shield_armoire_trustyUmbrella {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_trustyUmbrella.png");
}

.shield_armoire_unfinishedTome {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_unfinishedTome.png");
}

.shield_armoire_vikingShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_vikingShield.png");
}

.shield_armoire_weaversShuttle {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_armoire_weaversShuttle.png");
}

.shop_armor_armoire_alchemistsRobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_alchemistsRobe.png");
}

.shop_armor_armoire_antiProcrastinationArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_antiProcrastinationArmor.png");
}

.shop_armor_armoire_astronomersRobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_astronomersRobe.png");
}

.shop_armor_armoire_autumnEnchantersCloak {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_autumnEnchantersCloak.png");
}

.shop_armor_armoire_bagpipersKilt {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_bagpipersKilt.png");
}

.shop_armor_armoire_barristerRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_barristerRobes.png");
}

.shop_armor_armoire_baseballUniform {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_baseballUniform.png");
}

.shop_armor_armoire_basicArcherArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_basicArcherArmor.png");
}

.shop_armor_armoire_basketballUniform {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_basketballUniform.png");
}

.shop_armor_armoire_bathtub {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_bathtub.png");
}

.shop_armor_armoire_blueMoonShozoku {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_blueMoonShozoku.png");
}

.shop_armor_armoire_bluePartyDress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_bluePartyDress.png");
}

.shop_armor_armoire_boatingJacket {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_boatingJacket.png");
}

.shop_armor_armoire_boxArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_boxArmor.png");
}

.shop_armor_armoire_candlestickMakerOutfit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_candlestickMakerOutfit.png");
}

.shop_armor_armoire_cannoneerRags {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_cannoneerRags.png");
}

.shop_armor_armoire_chefsJacket {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_chefsJacket.png");
}

.shop_armor_armoire_clownsMotley {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_clownsMotley.png");
}

.shop_armor_armoire_coachDriverLivery {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_coachDriverLivery.png");
}

.shop_armor_armoire_cobblersCoveralls {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_cobblersCoveralls.png");
}

.shop_armor_armoire_coverallsOfBookbinding {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_coverallsOfBookbinding.png");
}

.shop_armor_armoire_crystalCrescentRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_crystalCrescentRobes.png");
}

.shop_armor_armoire_doubletOfClubs {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_doubletOfClubs.png");
}

.shop_armor_armoire_dragonTamerArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_dragonTamerArmor.png");
}

.shop_armor_armoire_dressingGown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_dressingGown.png");
}

.shop_armor_armoire_duffleCoat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_duffleCoat.png");
}

.shop_armor_armoire_falconerArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_falconerArmor.png");
}

.shop_armor_armoire_fancyPirateSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_fancyPirateSuit.png");
}

.shop_armor_armoire_farrierOutfit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_farrierOutfit.png");
}

.shop_armor_armoire_fiddlersCoat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_fiddlersCoat.png");
}

.shop_armor_armoire_flutteryFrock {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_flutteryFrock.png");
}

.shop_armor_armoire_gardenersOveralls {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_gardenersOveralls.png");
}

.shop_armor_armoire_gladiatorArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_gladiatorArmor.png");
}

.shop_armor_armoire_glassblowersCoveralls {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_glassblowersCoveralls.png");
}

.shop_armor_armoire_goldenToga {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_goldenToga.png");
}

.shop_armor_armoire_gownOfHearts {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_gownOfHearts.png");
}

.shop_armor_armoire_graduateRobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_graduateRobe.png");
}

.shop_armor_armoire_greenFestivalYukata {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_greenFestivalYukata.png");
}

.shop_armor_armoire_guardiansGown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_guardiansGown.png");
}

.shop_armor_armoire_heraldsTunic {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_heraldsTunic.png");
}

.shop_armor_armoire_heroicHerbalistRobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_heroicHerbalistRobe.png");
}

.shop_armor_armoire_hornedIronArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_hornedIronArmor.png");
}

.shop_armor_armoire_invernessCape {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_invernessCape.png");
}

.shop_armor_armoire_ironBlueArcherArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_ironBlueArcherArmor.png");
}

.shop_armor_armoire_jadeArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jadeArmor.png");
}

.shop_armor_armoire_jesterCostume {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jesterCostume.png");
}

.shop_armor_armoire_jeweledArcherArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jeweledArcherArmor.png");
}

.shop_armor_armoire_jewelersApron {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_jewelersApron.png");
}

.shop_armor_armoire_lamplightersGreatcoat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_lamplightersGreatcoat.png");
}

.shop_armor_armoire_layerCakeArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_layerCakeArmor.png");
}

.shop_armor_armoire_lunarArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_lunarArmor.png");
}

.shop_armor_armoire_matchMakersApron {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_matchMakersApron.png");
}

.shop_armor_armoire_medievalLaundryDress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_medievalLaundryDress.png");
}

.shop_armor_armoire_medievalLaundryOutfit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_medievalLaundryOutfit.png");
}

.shop_armor_armoire_merchantTunic {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_merchantTunic.png");
}

.shop_armor_armoire_minerOveralls {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_minerOveralls.png");
}

.shop_armor_armoire_mushroomDruidArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_mushroomDruidArmor.png");
}

.shop_armor_armoire_nephriteArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_nephriteArmor.png");
}

.shop_armor_armoire_ogreArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_ogreArmor.png");
}

.shop_armor_armoire_paintersApron {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_paintersApron.png");
}

.shop_armor_armoire_pirateOutfit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_pirateOutfit.png");
}

.shop_armor_armoire_piraticalPrincessGown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_piraticalPrincessGown.png");
}

.shop_armor_armoire_plagueDoctorOvercoat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_plagueDoctorOvercoat.png");
}

.shop_armor_armoire_ramFleeceRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_ramFleeceRobes.png");
}

.shop_armor_armoire_rancherRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_rancherRobes.png");
}

.shop_armor_armoire_redPartyDress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_redPartyDress.png");
}

.shop_armor_armoire_robeOfDiamonds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_robeOfDiamonds.png");
}

.shop_armor_armoire_robeOfSpades {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_robeOfSpades.png");
}

.shop_armor_armoire_royalRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_royalRobes.png");
}

.shop_armor_armoire_scribesRobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_scribesRobe.png");
}

.shop_armor_armoire_shadowMastersRobe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shadowMastersRobe.png");
}

.shop_armor_armoire_shawlCollarCoat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shawlCollarCoat.png");
}

.shop_armor_armoire_sheetGhostCostume {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_sheetGhostCostume.png");
}

.shop_armor_armoire_shepherdRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shepherdRobes.png");
}

.shop_armor_armoire_shootingStarCostume {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_shootingStarCostume.png");
}

.shop_armor_armoire_softBlackSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softBlackSuit.png");
}

.shop_armor_armoire_softBlueSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softBlueSuit.png");
}

.shop_armor_armoire_softGreenSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softGreenSuit.png");
}

.shop_armor_armoire_softPinkSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softPinkSuit.png");
}

.shop_armor_armoire_softRedSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softRedSuit.png");
}

.shop_armor_armoire_softVioletSuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_softVioletSuit.png");
}

.shop_armor_armoire_strawRaincoat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_strawRaincoat.png");
}

.shop_armor_armoire_stripedSwimsuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_stripedSwimsuit.png");
}

.shop_armor_armoire_swanDancerTutu {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_swanDancerTutu.png");
}

.shop_armor_armoire_teaGown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_teaGown.png");
}

.shop_armor_armoire_vermilionArcherArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_vermilionArcherArmor.png");
}

.shop_armor_armoire_vernalVestment {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_vernalVestment.png");
}

.shop_armor_armoire_vikingTunic {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_vikingTunic.png");
}

.shop_armor_armoire_woodElfArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_woodElfArmor.png");
}

.shop_armor_armoire_wovenRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_wovenRobes.png");
}

.shop_armor_armoire_yellowPartyDress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_armoire_yellowPartyDress.png");
}

.shop_body_armoire_clownsBowtie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_armoire_clownsBowtie.png");
}

.shop_body_armoire_cozyScarf {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_armoire_cozyScarf.png");
}

.shop_body_armoire_lifeguardWhistle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_armoire_lifeguardWhistle.png");
}

.shop_eyewear_armoire_clownsNose {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_clownsNose.png");
}

.shop_eyewear_armoire_comedyMask {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_comedyMask.png");
}

.shop_eyewear_armoire_goofyGlasses {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_goofyGlasses.png");
}

.shop_eyewear_armoire_jewelersEyeLoupe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_jewelersEyeLoupe.png");
}

.shop_eyewear_armoire_plagueDoctorMask {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_plagueDoctorMask.png");
}

.shop_eyewear_armoire_tragedyMask {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_armoire_tragedyMask.png");
}

.shop_headAccessory_armoire_comicalArrow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_armoire_comicalArrow.png");
}

.shop_headAccessory_armoire_gogglesOfBookbinding {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_armoire_gogglesOfBookbinding.png");
}

.shop_head_armoire_alchemistsHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_alchemistsHat.png");
}

.shop_head_armoire_antiProcrastinationHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_antiProcrastinationHelm.png");
}

.shop_head_armoire_astronomersHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_astronomersHat.png");
}

.shop_head_armoire_barristerWig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_barristerWig.png");
}

.shop_head_armoire_baseballCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_baseballCap.png");
}

.shop_head_armoire_basicArcherCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_basicArcherCap.png");
}

.shop_head_armoire_beaniePropellerHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_beaniePropellerHat.png");
}

.shop_head_armoire_bigWig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_bigWig.png");
}

.shop_head_armoire_birdsNest {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_birdsNest.png");
}

.shop_head_armoire_blackCat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blackCat.png");
}

.shop_head_armoire_blackFloppyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blackFloppyHat.png");
}

.shop_head_armoire_blueFloppyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blueFloppyHat.png");
}

.shop_head_armoire_blueHairbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blueHairbow.png");
}

.shop_head_armoire_blueMoonHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_blueMoonHelm.png");
}

.shop_head_armoire_boaterHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_boaterHat.png");
}

.shop_head_armoire_candlestickMakerHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_candlestickMakerHat.png");
}

.shop_head_armoire_cannoneerBandanna {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_cannoneerBandanna.png");
}

.shop_head_armoire_capOfClubs {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_capOfClubs.png");
}

.shop_head_armoire_clownsWig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_clownsWig.png");
}

.shop_head_armoire_coachDriversHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_coachDriversHat.png");
}

.shop_head_armoire_crownOfDiamonds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_crownOfDiamonds.png");
}

.shop_head_armoire_crownOfHearts {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_crownOfHearts.png");
}

.shop_head_armoire_crystalCrescentHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_crystalCrescentHat.png");
}

.shop_head_armoire_deerstalkerCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_deerstalkerCap.png");
}

.shop_head_armoire_dragonTamerHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_dragonTamerHelm.png");
}

.shop_head_armoire_earflapHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_earflapHat.png");
}

.shop_head_armoire_falconerCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_falconerCap.png");
}

.shop_head_armoire_fancyPirateHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_fancyPirateHat.png");
}

.shop_head_armoire_fiddlersCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_fiddlersCap.png");
}

.shop_head_armoire_flutteryWig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_flutteryWig.png");
}

.shop_head_armoire_frostedHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_frostedHelm.png");
}

.shop_head_armoire_gardenersSunHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_gardenersSunHat.png");
}

.shop_head_armoire_gladiatorHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_gladiatorHelm.png");
}

.shop_head_armoire_glassblowersHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_glassblowersHat.png");
}

.shop_head_armoire_glengarry {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_glengarry.png");
}

.shop_head_armoire_goldenLaurels {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_goldenLaurels.png");
}

.shop_head_armoire_graduateCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_graduateCap.png");
}

.shop_head_armoire_greenFloppyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_greenFloppyHat.png");
}

.shop_head_armoire_guardiansBonnet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_guardiansBonnet.png");
}

.shop_head_armoire_heraldsCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_heraldsCap.png");
}

.shop_head_armoire_heroicHerbalistCrispinette {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_heroicHerbalistCrispinette.png");
}

.shop_head_armoire_hornedIronHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_hornedIronHelm.png");
}

.shop_head_armoire_hornsOfAutumn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_hornsOfAutumn.png");
}

.shop_head_armoire_ironBlueArcherHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_ironBlueArcherHelm.png");
}

.shop_head_armoire_jadeHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_jadeHelm.png");
}

.shop_head_armoire_jesterCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_jesterCap.png");
}

.shop_head_armoire_jeweledArcherHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_jeweledArcherHelm.png");
}

.shop_head_armoire_lamplightersTopHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_lamplightersTopHat.png");
}

.shop_head_armoire_lunarCrown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_lunarCrown.png");
}

.shop_head_armoire_matchMakersBeret {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_matchMakersBeret.png");
}

.shop_head_armoire_medievalLaundryCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_medievalLaundryCap.png");
}

.shop_head_armoire_medievalLaundryHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_medievalLaundryHat.png");
}

.shop_head_armoire_merchantChaperon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_merchantChaperon.png");
}

.shop_head_armoire_minerHelmet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_minerHelmet.png");
}

.shop_head_armoire_mushroomDruidCap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_mushroomDruidCap.png");
}

.shop_head_armoire_nephriteHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_nephriteHelm.png");
}

.shop_head_armoire_nightcap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_nightcap.png");
}

.shop_head_armoire_ogreMask {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_ogreMask.png");
}

.shop_head_armoire_orangeCat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_orangeCat.png");
}

.shop_head_armoire_paintersBeret {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_paintersBeret.png");
}

.shop_head_armoire_paperBag {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_paperBag.png");
}

.shop_head_armoire_pinkFloppyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_pinkFloppyHat.png");
}

.shop_head_armoire_piraticalPrincessHeaddress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_piraticalPrincessHeaddress.png");
}

.shop_head_armoire_plagueDoctorHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_plagueDoctorHat.png");
}

.shop_head_armoire_ramHeaddress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_ramHeaddress.png");
}

.shop_head_armoire_rancherHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_rancherHat.png");
}

.shop_head_armoire_redFloppyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_redFloppyHat.png");
}

.shop_head_armoire_redHairbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_redHairbow.png");
}

.shop_head_armoire_regalCrown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_regalCrown.png");
}

.shop_head_armoire_royalCrown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_royalCrown.png");
}

.shop_head_armoire_rubberDucky {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_rubberDucky.png");
}

.shop_head_armoire_shadowMastersHood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_shadowMastersHood.png");
}

.shop_head_armoire_shepherdHeaddress {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_shepherdHeaddress.png");
}

.shop_head_armoire_shootingStarCrown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_shootingStarCrown.png");
}

.shop_head_armoire_strawRainHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_strawRainHat.png");
}

.shop_head_armoire_swanFeatherCrown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_swanFeatherCrown.png");
}

.shop_head_armoire_teaHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_teaHat.png");
}

.shop_head_armoire_toqueBlanche {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_toqueBlanche.png");
}

.shop_head_armoire_tricornHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_tricornHat.png");
}

.shop_head_armoire_veilOfSpades {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_veilOfSpades.png");
}

.shop_head_armoire_vermilionArcherHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_vermilionArcherHelm.png");
}

.shop_head_armoire_vernalHennin {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_vernalHennin.png");
}

.shop_head_armoire_vikingHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_vikingHelm.png");
}

.shop_head_armoire_violetFloppyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_violetFloppyHat.png");
}

.shop_head_armoire_woodElfHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_woodElfHelm.png");
}

.shop_head_armoire_yellowHairbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_armoire_yellowHairbow.png");
}

.shop_shield_armoire_alchemistsScale {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_alchemistsScale.png");
}

.shop_shield_armoire_antiProcrastinationShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_antiProcrastinationShield.png");
}

.shop_shield_armoire_bagpipes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_bagpipes.png");
}

.shop_shield_armoire_baseballGlove {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_baseballGlove.png");
}

.shop_shield_armoire_basketball {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_basketball.png");
}

.shop_shield_armoire_birthdayBanner {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_birthdayBanner.png");
}

.shop_shield_armoire_blueCottonCandyFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_blueCottonCandyFood.png");
}

.shop_shield_armoire_blueMoonSai {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_blueMoonSai.png");
}

.shop_shield_armoire_bouncyBubbles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_bouncyBubbles.png");
}

.shop_shield_armoire_bubblingCauldron {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_bubblingCauldron.png");
}

.shop_shield_armoire_chocolateFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_chocolateFood.png");
}

.shop_shield_armoire_clownsBalloons {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_clownsBalloons.png");
}

.shop_shield_armoire_darkAutumnFlame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_darkAutumnFlame.png");
}

.shop_shield_armoire_dragonTamerShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_dragonTamerShield.png");
}

.shop_shield_armoire_dustpan {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_dustpan.png");
}

.shop_shield_armoire_fancyBlownGlassVase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fancyBlownGlassVase.png");
}

.shop_shield_armoire_fancyShoe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fancyShoe.png");
}

.shop_shield_armoire_festivalParasol {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_festivalParasol.png");
}

.shop_shield_armoire_fiddle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fiddle.png");
}

.shop_shield_armoire_fishFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_fishFood.png");
}

.shop_shield_armoire_floralBouquet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_floralBouquet.png");
}

.shop_shield_armoire_flutteryFan {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_flutteryFan.png");
}

.shop_shield_armoire_gardenersSpade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_gardenersSpade.png");
}

.shop_shield_armoire_gladiatorShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_gladiatorShield.png");
}

.shop_shield_armoire_goldenBaton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_goldenBaton.png");
}

.shop_shield_armoire_handmadeCandlestick {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_handmadeCandlestick.png");
}

.shop_shield_armoire_heraldsMessageScroll {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_heraldsMessageScroll.png");
}

.shop_shield_armoire_hobbyHorse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_hobbyHorse.png");
}

.shop_shield_armoire_honeyFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_honeyFood.png");
}

.shop_shield_armoire_horseshoe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_horseshoe.png");
}

.shop_shield_armoire_jewelersPliers {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_jewelersPliers.png");
}

.shop_shield_armoire_lifeBuoy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_lifeBuoy.png");
}

.shop_shield_armoire_masteredShadow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_masteredShadow.png");
}

.shop_shield_armoire_meatFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_meatFood.png");
}

.shop_shield_armoire_medievalLaundry {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_medievalLaundry.png");
}

.shop_shield_armoire_midnightShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_midnightShield.png");
}

.shop_shield_armoire_mightyPizza {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mightyPizza.png");
}

.shop_shield_armoire_mightyQuill {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mightyQuill.png");
}

.shop_shield_armoire_milkFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_milkFood.png");
}

.shop_shield_armoire_mortarAndPestle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mortarAndPestle.png");
}

.shop_shield_armoire_mushroomDruidShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mushroomDruidShield.png");
}

.shop_shield_armoire_mysticLamp {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_mysticLamp.png");
}

.shop_shield_armoire_paintersPalette {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_paintersPalette.png");
}

.shop_shield_armoire_perchingFalcon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_perchingFalcon.png");
}

.shop_shield_armoire_perfectMatch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_perfectMatch.png");
}

.shop_shield_armoire_pinkCottonCandyFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_pinkCottonCandyFood.png");
}

.shop_shield_armoire_piratesCompanion {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_piratesCompanion.png");
}

.shop_shield_armoire_piraticalSkullShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_piraticalSkullShield.png");
}

.shop_shield_armoire_polishedPocketwatch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_polishedPocketwatch.png");
}

.shop_shield_armoire_potatoFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_potatoFood.png");
}

.shop_shield_armoire_ramHornShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_ramHornShield.png");
}

.shop_shield_armoire_redRose {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_redRose.png");
}

.shop_shield_armoire_rottenMeatFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_rottenMeatFood.png");
}

.shop_shield_armoire_royalCane {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_royalCane.png");
}

.shop_shield_armoire_sandyBucket {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_sandyBucket.png");
}

.shop_shield_armoire_shieldOfDiamonds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_shieldOfDiamonds.png");
}

.shop_shield_armoire_snareDrum {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_snareDrum.png");
}

.shop_shield_armoire_softBlackPillow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softBlackPillow.png");
}

.shop_shield_armoire_softBluePillow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softBluePillow.png");
}

.shop_shield_armoire_softGreenPillow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softGreenPillow.png");
}

.shop_shield_armoire_softPinkPillow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softPinkPillow.png");
}

.shop_shield_armoire_softRedPillow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softRedPillow.png");
}

.shop_shield_armoire_softVioletPillow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_softVioletPillow.png");
}

.shop_shield_armoire_spanishGuitar {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_spanishGuitar.png");
}

.shop_shield_armoire_strawberryFood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_strawberryFood.png");
}

.shop_shield_armoire_swanFeatherFan {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_swanFeatherFan.png");
}

.shop_shield_armoire_teaKettle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_teaKettle.png");
}

.shop_shield_armoire_treasureMap {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_treasureMap.png");
}

.shop_shield_armoire_trustyUmbrella {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_trustyUmbrella.png");
}

.shop_shield_armoire_unfinishedTome {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_unfinishedTome.png");
}

.shop_shield_armoire_vikingShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_vikingShield.png");
}

.shop_shield_armoire_weaversShuttle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_armoire_weaversShuttle.png");
}

.shop_weapon_armoire_alchemistsDistiller {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_alchemistsDistiller.png");
}

.shop_weapon_armoire_arcaneScroll {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_arcaneScroll.png");
}

.shop_weapon_armoire_astronomersTelescope {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_astronomersTelescope.png");
}

.shop_weapon_armoire_bambooCane {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_bambooCane.png");
}

.shop_weapon_armoire_barristerGavel {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_barristerGavel.png");
}

.shop_weapon_armoire_baseballBat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_baseballBat.png");
}

.shop_weapon_armoire_basicCrossbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_basicCrossbow.png");
}

.shop_weapon_armoire_basicLongbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_basicLongbow.png");
}

.shop_weapon_armoire_batWand {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_batWand.png");
}

.shop_weapon_armoire_battleAxe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_battleAxe.png");
}

.shop_weapon_armoire_beachFlag {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_beachFlag.png");
}

.shop_weapon_armoire_blueKite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_blueKite.png");
}

.shop_weapon_armoire_blueLongbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_blueLongbow.png");
}

.shop_weapon_armoire_blueMoonSai {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_blueMoonSai.png");
}

.shop_weapon_armoire_buoyantBubbles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_buoyantBubbles.png");
}

.shop_weapon_armoire_cannon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_cannon.png");
}

.shop_weapon_armoire_chefsSpoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_chefsSpoon.png");
}

.shop_weapon_armoire_clubOfClubs {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_clubOfClubs.png");
}

.shop_weapon_armoire_coachDriversWhip {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_coachDriversWhip.png");
}

.shop_weapon_armoire_cobblersHammer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_cobblersHammer.png");
}

.shop_weapon_armoire_crystalCrescentStaff {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_crystalCrescentStaff.png");
}

.shop_weapon_armoire_enchantersStaff {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_enchantersStaff.png");
}

.shop_weapon_armoire_eveningTea {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_eveningTea.png");
}

.shop_weapon_armoire_featherDuster {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_featherDuster.png");
}

.shop_weapon_armoire_festivalFirecracker {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_festivalFirecracker.png");
}

.shop_weapon_armoire_fiddlersBow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_fiddlersBow.png");
}

.shop_weapon_armoire_finelyCutGem {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_finelyCutGem.png");
}

.shop_weapon_armoire_floridFan {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_floridFan.png");
}

.shop_weapon_armoire_flutteryArmy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_flutteryArmy.png");
}

.shop_weapon_armoire_forestFungusStaff {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_forestFungusStaff.png");
}

.shop_weapon_armoire_gardenersWateringCan {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_gardenersWateringCan.png");
}

.shop_weapon_armoire_glassblowersBlowpipe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_glassblowersBlowpipe.png");
}

.shop_weapon_armoire_glowingSpear {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_glowingSpear.png");
}

.shop_weapon_armoire_goldWingStaff {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_goldWingStaff.png");
}

.shop_weapon_armoire_greenKite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_greenKite.png");
}

.shop_weapon_armoire_guardiansCrook {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_guardiansCrook.png");
}

.shop_weapon_armoire_habiticanDiploma {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_habiticanDiploma.png");
}

.shop_weapon_armoire_handyHook {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_handyHook.png");
}

.shop_weapon_armoire_happyBanner {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_happyBanner.png");
}

.shop_weapon_armoire_heraldsBuisine {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_heraldsBuisine.png");
}

.shop_weapon_armoire_hoofClippers {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_hoofClippers.png");
}

.shop_weapon_armoire_huntingHorn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_huntingHorn.png");
}

.shop_weapon_armoire_ironCrook {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_ironCrook.png");
}

.shop_weapon_armoire_jadeGlaive {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jadeGlaive.png");
}

.shop_weapon_armoire_jesterBaton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jesterBaton.png");
}

.shop_weapon_armoire_jeweledArcherBow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jeweledArcherBow.png");
}

.shop_weapon_armoire_jugglingBalls {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_jugglingBalls.png");
}

.shop_weapon_armoire_lamplighter {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_lamplighter.png");
}

.shop_weapon_armoire_livelyMatch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_livelyMatch.png");
}

.shop_weapon_armoire_lunarSceptre {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_lunarSceptre.png");
}

.shop_weapon_armoire_magicSpatula {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_magicSpatula.png");
}

.shop_weapon_armoire_magnifyingGlass {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_magnifyingGlass.png");
}

.shop_weapon_armoire_medievalWashboard {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_medievalWashboard.png");
}

.shop_weapon_armoire_merchantsDisplayTray {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_merchantsDisplayTray.png");
}

.shop_weapon_armoire_miningPickax {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_miningPickax.png");
}

.shop_weapon_armoire_mythmakerSword {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_mythmakerSword.png");
}

.shop_weapon_armoire_needleOfBookbinding {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_needleOfBookbinding.png");
}

.shop_weapon_armoire_nephriteBow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_nephriteBow.png");
}

.shop_weapon_armoire_ogreClub {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_ogreClub.png");
}

.shop_weapon_armoire_orangeKite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_orangeKite.png");
}

.shop_weapon_armoire_paintbrush {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_paintbrush.png");
}

.shop_weapon_armoire_paperCutter {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_paperCutter.png");
}

.shop_weapon_armoire_pinkKite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_pinkKite.png");
}

.shop_weapon_armoire_pinkLongbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_pinkLongbow.png");
}

.shop_weapon_armoire_poisonedGoblet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_poisonedGoblet.png");
}

.shop_weapon_armoire_potionBase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionBase.png");
}

.shop_weapon_armoire_potionBlue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionBlue.png");
}

.shop_weapon_armoire_potionDesert {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionDesert.png");
}

.shop_weapon_armoire_potionGolden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionGolden.png");
}

.shop_weapon_armoire_potionPink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionPink.png");
}

.shop_weapon_armoire_potionRed {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionRed.png");
}

.shop_weapon_armoire_potionShade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionShade.png");
}

.shop_weapon_armoire_potionSkeleton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionSkeleton.png");
}

.shop_weapon_armoire_potionWhite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionWhite.png");
}

.shop_weapon_armoire_potionZombie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_potionZombie.png");
}

.shop_weapon_armoire_pushBroom {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_pushBroom.png");
}

.shop_weapon_armoire_rancherLasso {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_rancherLasso.png");
}

.shop_weapon_armoire_regalSceptre {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_regalSceptre.png");
}

.shop_weapon_armoire_resplendentRapier {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_resplendentRapier.png");
}

.shop_weapon_armoire_sandySpade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_sandySpade.png");
}

.shop_weapon_armoire_scepterOfDiamonds {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_scepterOfDiamonds.png");
}

.shop_weapon_armoire_shadowMastersMace {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_shadowMastersMace.png");
}

.shop_weapon_armoire_shepherdsCrook {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_shepherdsCrook.png");
}

.shop_weapon_armoire_shootingStarSpell {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_shootingStarSpell.png");
}

.shop_weapon_armoire_skullLantern {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_skullLantern.png");
}

.shop_weapon_armoire_slingshot {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_slingshot.png");
}

.shop_weapon_armoire_spearOfSpades {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_spearOfSpades.png");
}

.shop_weapon_armoire_vermilionArcherBow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_vermilionArcherBow.png");
}

.shop_weapon_armoire_vernalTaper {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_vernalTaper.png");
}

.shop_weapon_armoire_wandOfHearts {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_wandOfHearts.png");
}

.shop_weapon_armoire_weaversComb {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_weaversComb.png");
}

.shop_weapon_armoire_woodElfStaff {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_woodElfStaff.png");
}

.shop_weapon_armoire_yellowKite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_armoire_yellowKite.png");
}

.slim_armor_armoire_alchemistsRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_alchemistsRobe.png");
}

.slim_armor_armoire_antiProcrastinationArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_antiProcrastinationArmor.png");
}

.slim_armor_armoire_astronomersRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_astronomersRobe.png");
}

.slim_armor_armoire_autumnEnchantersCloak {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_autumnEnchantersCloak.png");
}

.slim_armor_armoire_bagpipersKilt {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_bagpipersKilt.png");
}

.slim_armor_armoire_barristerRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_barristerRobes.png");
}

.slim_armor_armoire_baseballUniform {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_baseballUniform.png");
}

.slim_armor_armoire_basicArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_basicArcherArmor.png");
}

.slim_armor_armoire_basketballUniform {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_basketballUniform.png");
}

.slim_armor_armoire_bathtub {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_bathtub.png");
}

.slim_armor_armoire_blueMoonShozoku {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_blueMoonShozoku.png");
}

.slim_armor_armoire_bluePartyDress {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_bluePartyDress.png");
}

.slim_armor_armoire_boatingJacket {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_boatingJacket.png");
}

.slim_armor_armoire_boxArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_boxArmor.png");
}

.slim_armor_armoire_candlestickMakerOutfit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_candlestickMakerOutfit.png");
}

.slim_armor_armoire_cannoneerRags {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_cannoneerRags.png");
}

.slim_armor_armoire_chefsJacket {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_chefsJacket.png");
}

.slim_armor_armoire_clownsMotley {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_clownsMotley.png");
}

.slim_armor_armoire_coachDriverLivery {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_coachDriverLivery.png");
}

.slim_armor_armoire_cobblersCoveralls {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_cobblersCoveralls.png");
}

.slim_armor_armoire_coverallsOfBookbinding {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_coverallsOfBookbinding.png");
}

.slim_armor_armoire_crystalCrescentRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_crystalCrescentRobes.png");
}

.slim_armor_armoire_doubletOfClubs {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_doubletOfClubs.png");
}

.slim_armor_armoire_dragonTamerArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_dragonTamerArmor.png");
}

.slim_armor_armoire_dressingGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_dressingGown.png");
}

.slim_armor_armoire_duffleCoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_duffleCoat.png");
}

.slim_armor_armoire_falconerArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_falconerArmor.png");
}

.slim_armor_armoire_fancyPirateSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_fancyPirateSuit.png");
}

.slim_armor_armoire_farrierOutfit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_farrierOutfit.png");
}

.slim_armor_armoire_fiddlersCoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_fiddlersCoat.png");
}

.slim_armor_armoire_flutteryFrock {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_flutteryFrock.png");
}

.slim_armor_armoire_gardenersOveralls {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_gardenersOveralls.png");
}

.slim_armor_armoire_gladiatorArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_gladiatorArmor.png");
}

.slim_armor_armoire_glassblowersCoveralls {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_glassblowersCoveralls.png");
}

.slim_armor_armoire_goldenToga {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_goldenToga.png");
}

.slim_armor_armoire_gownOfHearts {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_gownOfHearts.png");
}

.slim_armor_armoire_graduateRobe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_graduateRobe.png");
}

.slim_armor_armoire_greenFestivalYukata {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_greenFestivalYukata.png");
}

.slim_armor_armoire_guardiansGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_guardiansGown.png");
}

.slim_armor_armoire_heraldsTunic {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_heraldsTunic.png");
}

.slim_armor_armoire_heroicHerbalistRobe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_heroicHerbalistRobe.png");
}

.slim_armor_armoire_hornedIronArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_hornedIronArmor.png");
}

.slim_armor_armoire_invernessCape {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_invernessCape.png");
}

.slim_armor_armoire_ironBlueArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_ironBlueArcherArmor.png");
}

.slim_armor_armoire_jadeArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jadeArmor.png");
}

.slim_armor_armoire_jesterCostume {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jesterCostume.png");
}

.slim_armor_armoire_jeweledArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jeweledArcherArmor.png");
}

.slim_armor_armoire_jewelersApron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_jewelersApron.png");
}

.slim_armor_armoire_lamplightersGreatcoat {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_lamplightersGreatcoat.png");
}

.slim_armor_armoire_layerCakeArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_layerCakeArmor.png");
}

.slim_armor_armoire_lunarArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_lunarArmor.png");
}

.slim_armor_armoire_matchMakersApron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_matchMakersApron.png");
}

.slim_armor_armoire_medievalLaundryDress {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_medievalLaundryDress.png");
}

.slim_armor_armoire_medievalLaundryOutfit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_medievalLaundryOutfit.png");
}

.slim_armor_armoire_merchantTunic {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_merchantTunic.png");
}

.slim_armor_armoire_minerOveralls {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_minerOveralls.png");
}

.slim_armor_armoire_mushroomDruidArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_mushroomDruidArmor.png");
}

.slim_armor_armoire_nephriteArmor {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_nephriteArmor.png");
}

.slim_armor_armoire_ogreArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_ogreArmor.png");
}

.slim_armor_armoire_paintersApron {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_paintersApron.png");
}

.slim_armor_armoire_pirateOutfit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_pirateOutfit.png");
}

.slim_armor_armoire_piraticalPrincessGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_piraticalPrincessGown.png");
}

.slim_armor_armoire_plagueDoctorOvercoat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_plagueDoctorOvercoat.png");
}

.slim_armor_armoire_ramFleeceRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_ramFleeceRobes.png");
}

.slim_armor_armoire_rancherRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_rancherRobes.png");
}

.slim_armor_armoire_redPartyDress {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_redPartyDress.png");
}

.slim_armor_armoire_robeOfDiamonds {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_robeOfDiamonds.png");
}

.slim_armor_armoire_robeOfSpades {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_robeOfSpades.png");
}

.slim_armor_armoire_royalRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_royalRobes.png");
}

.slim_armor_armoire_scribesRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_scribesRobe.png");
}

.slim_armor_armoire_shadowMastersRobe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shadowMastersRobe.png");
}

.slim_armor_armoire_shawlCollarCoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shawlCollarCoat.png");
}

.slim_armor_armoire_sheetGhostCostume {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_sheetGhostCostume.png");
}

.slim_armor_armoire_shepherdRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shepherdRobes.png");
}

.slim_armor_armoire_shootingStarCostume {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_shootingStarCostume.png");
}

.slim_armor_armoire_softBlackSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softBlackSuit.png");
}

.slim_armor_armoire_softBlueSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softBlueSuit.png");
}

.slim_armor_armoire_softGreenSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softGreenSuit.png");
}

.slim_armor_armoire_softPinkSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softPinkSuit.png");
}

.slim_armor_armoire_softRedSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softRedSuit.png");
}

.slim_armor_armoire_softVioletSuit {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_softVioletSuit.png");
}

.slim_armor_armoire_strawRaincoat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_strawRaincoat.png");
}

.slim_armor_armoire_stripedSwimsuit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_stripedSwimsuit.png");
}

.slim_armor_armoire_swanDancerTutu {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_swanDancerTutu.png");
}

.slim_armor_armoire_teaGown {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_teaGown.png");
}

.slim_armor_armoire_vermilionArcherArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_vermilionArcherArmor.png");
}

.slim_armor_armoire_vernalVestment {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_vernalVestment.png");
}

.slim_armor_armoire_vikingTunic {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_vikingTunic.png");
}

.slim_armor_armoire_woodElfArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_woodElfArmor.png");
}

.slim_armor_armoire_wovenRobes {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_wovenRobes.png");
}

.slim_armor_armoire_yellowPartyDress {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_armoire_yellowPartyDress.png");
}

.weapon_armoire_alchemistsDistiller {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_alchemistsDistiller.png");
}

.weapon_armoire_arcaneScroll {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_arcaneScroll.png");
}

.weapon_armoire_astronomersTelescope {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_astronomersTelescope.png");
}

.weapon_armoire_bambooCane {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_bambooCane.png");
}

.weapon_armoire_barristerGavel {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_barristerGavel.png");
}

.weapon_armoire_baseballBat {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_baseballBat.png");
}

.weapon_armoire_basicCrossbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_basicCrossbow.png");
}

.weapon_armoire_basicLongbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_basicLongbow.png");
}

.weapon_armoire_batWand {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_batWand.png");
}

.weapon_armoire_battleAxe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_battleAxe.png");
}

.weapon_armoire_beachFlag {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_beachFlag.png");
}

.weapon_armoire_blueKite {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_blueKite.png");
}

.weapon_armoire_blueLongbow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_blueLongbow.png");
}

.weapon_armoire_blueMoonSai {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_blueMoonSai.png");
}

.weapon_armoire_buoyantBubbles {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_buoyantBubbles.png");
}

.weapon_armoire_cannon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_cannon.png");
}

.weapon_armoire_chefsSpoon {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_chefsSpoon.png");
}

.weapon_armoire_clubOfClubs {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_clubOfClubs.png");
}

.weapon_armoire_coachDriversWhip {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_coachDriversWhip.png");
}

.weapon_armoire_cobblersHammer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_cobblersHammer.png");
}

.weapon_armoire_crystalCrescentStaff {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_crystalCrescentStaff.png");
}

.weapon_armoire_enchantersStaff {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_enchantersStaff.png");
}

.weapon_armoire_eveningTea {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_eveningTea.png");
}

.weapon_armoire_featherDuster {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_featherDuster.png");
}

.weapon_armoire_festivalFirecracker {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_festivalFirecracker.png");
}

.weapon_armoire_fiddlersBow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_fiddlersBow.png");
}

.weapon_armoire_finelyCutGem {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_finelyCutGem.png");
}

.weapon_armoire_floridFan {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_floridFan.png");
}

.weapon_armoire_flutteryArmy {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_flutteryArmy.png");
}

.weapon_armoire_forestFungusStaff {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_forestFungusStaff.png");
}

.weapon_armoire_gardenersWateringCan {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_gardenersWateringCan.png");
}

.weapon_armoire_glassblowersBlowpipe {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_glassblowersBlowpipe.png");
}

.weapon_armoire_glowingSpear {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_glowingSpear.png");
}

.weapon_armoire_goldWingStaff {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_goldWingStaff.png");
}

.weapon_armoire_greenKite {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_greenKite.png");
}

.weapon_armoire_guardiansCrook {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_guardiansCrook.png");
}

.weapon_armoire_habiticanDiploma {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_habiticanDiploma.png");
}

.weapon_armoire_handyHook {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_handyHook.png");
}

.weapon_armoire_happyBanner {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_happyBanner.png");
}

.weapon_armoire_heraldsBuisine {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_heraldsBuisine.png");
}

.weapon_armoire_hoofClippers {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_hoofClippers.png");
}

.weapon_armoire_huntingHorn {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_huntingHorn.png");
}

.weapon_armoire_ironCrook {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_ironCrook.png");
}

.weapon_armoire_jadeGlaive {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jadeGlaive.png");
}

.weapon_armoire_jesterBaton {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jesterBaton.png");
}

.weapon_armoire_jeweledArcherBow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jeweledArcherBow.png");
}

.weapon_armoire_jugglingBalls {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_jugglingBalls.png");
}

.weapon_armoire_lamplighter {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_lamplighter.png");
}

.weapon_armoire_livelyMatch {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_livelyMatch.png");
}

.weapon_armoire_lunarSceptre {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_lunarSceptre.png");
}

.weapon_armoire_magicSpatula {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_magicSpatula.png");
}

.weapon_armoire_magnifyingGlass {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_magnifyingGlass.png");
}

.weapon_armoire_medievalWashboard {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_medievalWashboard.png");
}

.weapon_armoire_merchantsDisplayTray {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_merchantsDisplayTray.png");
}

.weapon_armoire_miningPickax {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_miningPickax.png");
}

.weapon_armoire_mythmakerSword {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_mythmakerSword.png");
}

.weapon_armoire_needleOfBookbinding {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_needleOfBookbinding.png");
}

.weapon_armoire_nephriteBow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_nephriteBow.png");
}

.weapon_armoire_ogreClub {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_ogreClub.png");
}

.weapon_armoire_orangeKite {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_orangeKite.png");
}

.weapon_armoire_paintbrush {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_paintbrush.png");
}

.weapon_armoire_paperCutter {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_paperCutter.png");
}

.weapon_armoire_pinkKite {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_pinkKite.png");
}

.weapon_armoire_pinkLongbow {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_pinkLongbow.png");
}

.weapon_armoire_poisonedGoblet {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_poisonedGoblet.png");
}

.weapon_armoire_potionBase {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionBase.png");
}

.weapon_armoire_potionBlue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionBlue.png");
}

.weapon_armoire_potionDesert {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionDesert.png");
}

.weapon_armoire_potionGolden {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionGolden.png");
}

.weapon_armoire_potionPink {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionPink.png");
}

.weapon_armoire_potionRed {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionRed.png");
}

.weapon_armoire_potionShade {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionShade.png");
}

.weapon_armoire_potionSkeleton {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionSkeleton.png");
}

.weapon_armoire_potionWhite {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionWhite.png");
}

.weapon_armoire_potionZombie {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_potionZombie.png");
}

.weapon_armoire_pushBroom {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_pushBroom.png");
}

.weapon_armoire_rancherLasso {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_rancherLasso.png");
}

.weapon_armoire_regalSceptre {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_regalSceptre.png");
}

.weapon_armoire_resplendentRapier {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_resplendentRapier.png");
}

.weapon_armoire_sandySpade {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_sandySpade.png");
}

.weapon_armoire_scepterOfDiamonds {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_scepterOfDiamonds.png");
}

.weapon_armoire_shadowMastersMace {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_shadowMastersMace.png");
}

.weapon_armoire_shepherdsCrook {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_shepherdsCrook.png");
}

.weapon_armoire_shootingStarSpell {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_shootingStarSpell.png");
}

.weapon_armoire_skullLantern {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_skullLantern.png");
}

.weapon_armoire_slingshot {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_slingshot.png");
}

.weapon_armoire_spearOfSpades {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_spearOfSpades.png");
}

.weapon_armoire_vermilionArcherBow {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_vermilionArcherBow.png");
}

.weapon_armoire_vernalTaper {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_vernalTaper.png");
}

.weapon_armoire_wandOfHearts {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_wandOfHearts.png");
}

.weapon_armoire_weaversComb {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_weaversComb.png");
}

.weapon_armoire_woodElfStaff {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_woodElfStaff.png");
}

.weapon_armoire_yellowKite {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_armoire_yellowKite.png");
}

.armor_special_bardRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/armor_special_bardRobes.png");
}

.broad_armor_healer_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_1.png");
}

.broad_armor_healer_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_2.png");
}

.broad_armor_healer_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_3.png");
}

.broad_armor_healer_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_4.png");
}

.broad_armor_healer_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_healer_5.png");
}

.broad_armor_rogue_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_1.png");
}

.broad_armor_rogue_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_2.png");
}

.broad_armor_rogue_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_3.png");
}

.broad_armor_rogue_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_4.png");
}

.broad_armor_rogue_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_rogue_5.png");
}

.broad_armor_special_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_2.png");
}

.broad_armor_special_bardRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_bardRobes.png");
}

.broad_armor_special_dandySuit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_dandySuit.png");
}

.broad_armor_special_finnedOceanicArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_finnedOceanicArmor.png");
}

.broad_armor_special_lunarWarriorArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_lunarWarriorArmor.png");
}

.broad_armor_special_mammothRiderArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_mammothRiderArmor.png");
}

.broad_armor_special_nomadsCuirass {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_nomadsCuirass.png");
}

.broad_armor_special_pageArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_pageArmor.png");
}

.broad_armor_special_pyromancersRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_pyromancersRobes.png");
}

.broad_armor_special_roguishRainbowMessengerRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_roguishRainbowMessengerRobes.png");
}

.broad_armor_special_samuraiArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_samuraiArmor.png");
}

.broad_armor_special_sneakthiefRobes {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_sneakthiefRobes.png");
}

.broad_armor_special_snowSovereignRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_snowSovereignRobes.png");
}

.broad_armor_special_turkeyArmorBase {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_turkeyArmorBase.png");
}

.broad_armor_special_turkeyArmorGilded {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_turkeyArmorGilded.png");
}

.broad_armor_warrior_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_1.png");
}

.broad_armor_warrior_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_2.png");
}

.broad_armor_warrior_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_3.png");
}

.broad_armor_warrior_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_4.png");
}

.broad_armor_warrior_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_warrior_5.png");
}

.broad_armor_wizard_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_1.png");
}

.broad_armor_wizard_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_2.png");
}

.broad_armor_wizard_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_3.png");
}

.broad_armor_wizard_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_4.png");
}

.broad_armor_wizard_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_wizard_5.png");
}

.shop_armor_healer_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_1.png");
}

.shop_armor_healer_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_2.png");
}

.shop_armor_healer_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_3.png");
}

.shop_armor_healer_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_4.png");
}

.shop_armor_healer_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_healer_5.png");
}

.shop_armor_rogue_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_1.png");
}

.shop_armor_rogue_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_2.png");
}

.shop_armor_rogue_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_3.png");
}

.shop_armor_rogue_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_4.png");
}

.shop_armor_rogue_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_rogue_5.png");
}

.shop_armor_special_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_0.png");
}

.shop_armor_special_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_1.png");
}

.shop_armor_special_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_2.png");
}

.shop_armor_special_bardRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_bardRobes.png");
}

.shop_armor_special_dandySuit {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_dandySuit.png");
}

.shop_armor_special_finnedOceanicArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_finnedOceanicArmor.png");
}

.shop_armor_special_lunarWarriorArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_lunarWarriorArmor.png");
}

.shop_armor_special_mammothRiderArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_mammothRiderArmor.png");
}

.shop_armor_special_nomadsCuirass {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_nomadsCuirass.png");
}

.shop_armor_special_pageArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_pageArmor.png");
}

.shop_armor_special_pyromancersRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_pyromancersRobes.png");
}

.shop_armor_special_roguishRainbowMessengerRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_roguishRainbowMessengerRobes.png");
}

.shop_armor_special_samuraiArmor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_samuraiArmor.png");
}

.shop_armor_special_sneakthiefRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_sneakthiefRobes.png");
}

.shop_armor_special_snowSovereignRobes {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_snowSovereignRobes.png");
}

.shop_armor_special_turkeyArmorBase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_turkeyArmorBase.png");
}

.shop_armor_special_turkeyArmorGilded {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_turkeyArmorGilded.png");
}

.shop_armor_warrior_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_1.png");
}

.shop_armor_warrior_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_2.png");
}

.shop_armor_warrior_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_3.png");
}

.shop_armor_warrior_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_4.png");
}

.shop_armor_warrior_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_warrior_5.png");
}

.shop_armor_wizard_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_1.png");
}

.shop_armor_wizard_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_2.png");
}

.shop_armor_wizard_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_3.png");
}

.shop_armor_wizard_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_4.png");
}

.shop_armor_wizard_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_wizard_5.png");
}

.slim_armor_healer_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_1.png");
}

.slim_armor_healer_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_2.png");
}

.slim_armor_healer_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_3.png");
}

.slim_armor_healer_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_4.png");
}

.slim_armor_healer_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_healer_5.png");
}

.slim_armor_rogue_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_1.png");
}

.slim_armor_rogue_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_2.png");
}

.slim_armor_rogue_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_3.png");
}

.slim_armor_rogue_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_4.png");
}

.slim_armor_rogue_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_rogue_5.png");
}

.slim_armor_special_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_2.png");
}

.slim_armor_special_bardRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_bardRobes.png");
}

.slim_armor_special_dandySuit {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_dandySuit.png");
}

.slim_armor_special_finnedOceanicArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_finnedOceanicArmor.png");
}

.slim_armor_special_lunarWarriorArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_lunarWarriorArmor.png");
}

.slim_armor_special_mammothRiderArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_mammothRiderArmor.png");
}

.slim_armor_special_nomadsCuirass {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_nomadsCuirass.png");
}

.slim_armor_special_pageArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_pageArmor.png");
}

.slim_armor_special_pyromancersRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_pyromancersRobes.png");
}

.slim_armor_special_roguishRainbowMessengerRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_roguishRainbowMessengerRobes.png");
}

.slim_armor_special_samuraiArmor {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_samuraiArmor.png");
}

.slim_armor_special_sneakthiefRobes {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_sneakthiefRobes.png");
}

.slim_armor_special_snowSovereignRobes {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_snowSovereignRobes.png");
}

.slim_armor_special_turkeyArmorBase {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_turkeyArmorBase.png");
}

.slim_armor_special_turkeyArmorGilded {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_turkeyArmorGilded.png");
}

.slim_armor_warrior_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_1.png");
}

.slim_armor_warrior_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_2.png");
}

.slim_armor_warrior_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_3.png");
}

.slim_armor_warrior_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_4.png");
}

.slim_armor_warrior_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_warrior_5.png");
}

.slim_armor_wizard_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_1.png");
}

.slim_armor_wizard_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_2.png");
}

.slim_armor_wizard_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_3.png");
}

.slim_armor_wizard_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_4.png");
}

.slim_armor_wizard_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_wizard_5.png");
}

.back_special_aetherCloak {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_aetherCloak.png");
}

.back_special_bearTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_bearTail.png");
}

.back_special_cactusTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_cactusTail.png");
}

.back_special_foxTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_foxTail.png");
}

.back_special_lionTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_lionTail.png");
}

.back_special_namingDay2020 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_namingDay2020.png");
}

.back_special_pandaTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_pandaTail.png");
}

.back_special_pigTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_pigTail.png");
}

.back_special_snowdriftVeil {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_snowdriftVeil.png");
}

.back_special_tigerTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_tigerTail.png");
}

.back_special_turkeyTailBase {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_turkeyTailBase.png");
}

.back_special_turkeyTailGilded {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_turkeyTailGilded.png");
}

.back_special_wolfTail {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_wolfTail.png");
}

.icon_back_special_bearTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_bearTail.png");
}

.icon_back_special_cactusTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_cactusTail.png");
}

.icon_back_special_foxTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_foxTail.png");
}

.icon_back_special_lionTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_lionTail.png");
}

.icon_back_special_pandaTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_pandaTail.png");
}

.icon_back_special_pigTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_pigTail.png");
}

.icon_back_special_tigerTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_tigerTail.png");
}

.icon_back_special_wolfTail {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/icon_back_special_wolfTail.png");
}

.shop_back_special_aetherCloak {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_aetherCloak.png");
}

.shop_back_special_bearTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_bearTail.png");
}

.shop_back_special_cactusTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_cactusTail.png");
}

.shop_back_special_foxTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_foxTail.png");
}

.shop_back_special_lionTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_lionTail.png");
}

.shop_back_special_namingDay2020 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_namingDay2020.png");
}

.shop_back_special_pandaTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_pandaTail.png");
}

.shop_back_special_pigTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_pigTail.png");
}

.shop_back_special_snowdriftVeil {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_snowdriftVeil.png");
}

.shop_back_special_tigerTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_tigerTail.png");
}

.shop_back_special_turkeyTailBase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_turkeyTailBase.png");
}

.shop_back_special_turkeyTailGilded {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_turkeyTailGilded.png");
}

.shop_back_special_wolfTail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_wolfTail.png");
}

.body_special_aetherAmulet {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_aetherAmulet.png");
}

.body_special_namingDay2018 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_namingDay2018.png");
}

.shop_body_special_aetherAmulet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_aetherAmulet.png");
}

.shop_body_special_namingDay2018 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_namingDay2018.png");
}

.back_special_anniversary {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_anniversary.png");
}

.body_special_anniversary {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_anniversary.png");
}

.broad_armor_special_birthday {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday.png");
}

.broad_armor_special_birthday2015 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2015.png");
}

.broad_armor_special_birthday2016 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2016.png");
}

.broad_armor_special_birthday2017 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2017.png");
}

.broad_armor_special_birthday2018 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2018.png");
}

.broad_armor_special_birthday2019 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2019.png");
}

.broad_armor_special_birthday2020 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2020.png");
}

.broad_armor_special_birthday2021 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2021.png");
}

.broad_armor_special_birthday2022 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2022.png");
}

.broad_armor_special_birthday2023 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_birthday2023.png");
}

.eyewear_special_anniversary {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_anniversary.png");
}

.shop_armor_special_birthday {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday.png");
}

.shop_armor_special_birthday2015 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2015.png");
}

.shop_armor_special_birthday2016 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2016.png");
}

.shop_armor_special_birthday2017 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2017.png");
}

.shop_armor_special_birthday2018 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2018.png");
}

.shop_armor_special_birthday2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2019.png");
}

.shop_armor_special_birthday2020 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2020.png");
}

.shop_armor_special_birthday2021 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2021.png");
}

.shop_armor_special_birthday2022 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2022.png");
}

.shop_armor_special_birthday2023 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_birthday2023.png");
}

.shop_back_special_anniversary {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_anniversary.png");
}

.shop_body_special_anniversary {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_anniversary.png");
}

.shop_eyewear_special_anniversary {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_anniversary.png");
}

.slim_armor_special_birthday {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday.png");
}

.slim_armor_special_birthday2015 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2015.png");
}

.slim_armor_special_birthday2016 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2016.png");
}

.slim_armor_special_birthday2017 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2017.png");
}

.slim_armor_special_birthday2018 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2018.png");
}

.slim_armor_special_birthday2019 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2019.png");
}

.slim_armor_special_birthday2020 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2020.png");
}

.slim_armor_special_birthday2021 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2021.png");
}

.slim_armor_special_birthday2022 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2022.png");
}

.slim_armor_special_birthday2023 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_birthday2023.png");
}

.broad_armor_special_fall2015Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Healer.png");
}

.broad_armor_special_fall2015Mage {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Mage.png");
}

.broad_armor_special_fall2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Rogue.png");
}

.broad_armor_special_fall2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2015Warrior.png");
}

.broad_armor_special_fall2016Healer {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Healer.png");
}

.broad_armor_special_fall2016Mage {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Mage.png");
}

.broad_armor_special_fall2016Rogue {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Rogue.png");
}

.broad_armor_special_fall2016Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2016Warrior.png");
}

.broad_armor_special_fall2017Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Healer.png");
}

.broad_armor_special_fall2017Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Mage.png");
}

.broad_armor_special_fall2017Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Rogue.png");
}

.broad_armor_special_fall2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2017Warrior.png");
}

.broad_armor_special_fall2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Healer.png");
}

.broad_armor_special_fall2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Mage.png");
}

.broad_armor_special_fall2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Rogue.png");
}

.broad_armor_special_fall2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2018Warrior.png");
}

.broad_armor_special_fall2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Healer.png");
}

.broad_armor_special_fall2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Mage.png");
}

.broad_armor_special_fall2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Rogue.png");
}

.broad_armor_special_fall2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2019Warrior.png");
}

.broad_armor_special_fall2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Healer.png");
}

.broad_armor_special_fall2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Mage.png");
}

.broad_armor_special_fall2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Rogue.png");
}

.broad_armor_special_fall2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2020Warrior.png");
}

.broad_armor_special_fall2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Healer.png");
}

.broad_armor_special_fall2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Mage.png");
}

.broad_armor_special_fall2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Rogue.png");
}

.broad_armor_special_fall2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2021Warrior.png");
}

.broad_armor_special_fall2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Healer.png");
}

.broad_armor_special_fall2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Mage.png");
}

.broad_armor_special_fall2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Rogue.png");
}

.broad_armor_special_fall2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fall2022Warrior.png");
}

.broad_armor_special_fallHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallHealer.png");
}

.broad_armor_special_fallMage {
  width: 120px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallMage.png");
}

.broad_armor_special_fallRogue {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallRogue.png");
}

.broad_armor_special_fallWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_fallWarrior.png");
}

.eyewear_special_fall2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_fall2019Healer.png");
}

.eyewear_special_fall2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_fall2019Rogue.png");
}

.head_special_fall2015Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Healer.png");
}

.head_special_fall2015Mage {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Mage.png");
}

.head_special_fall2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Rogue.png");
}

.head_special_fall2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2015Warrior.png");
}

.head_special_fall2016Healer {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Healer.png");
}

.head_special_fall2016Mage {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Mage.png");
}

.head_special_fall2016Rogue {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Rogue.png");
}

.head_special_fall2016Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2016Warrior.png");
}

.head_special_fall2017Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Healer.png");
}

.head_special_fall2017Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Mage.png");
}

.head_special_fall2017Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Rogue.png");
}

.head_special_fall2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2017Warrior.png");
}

.head_special_fall2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Healer.png");
}

.head_special_fall2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Mage.png");
}

.head_special_fall2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Rogue.png");
}

.head_special_fall2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2018Warrior.png");
}

.head_special_fall2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Healer.png");
}

.head_special_fall2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Mage.png");
}

.head_special_fall2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Rogue.png");
}

.head_special_fall2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2019Warrior.png");
}

.head_special_fall2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Healer.png");
}

.head_special_fall2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Mage.png");
}

.head_special_fall2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Rogue.png");
}

.head_special_fall2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2020Warrior.png");
}

.head_special_fall2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Healer.png");
}

.head_special_fall2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Mage.png");
}

.head_special_fall2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Rogue.png");
}

.head_special_fall2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2021Warrior.png");
}

.head_special_fall2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Healer.png");
}

.head_special_fall2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Mage.png");
}

.head_special_fall2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Rogue.png");
}

.head_special_fall2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fall2022Warrior.png");
}

.head_special_fallHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallHealer.png");
}

.head_special_fallMage {
  width: 120px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallMage.png");
}

.head_special_fallRogue {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallRogue.png");
}

.head_special_fallWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fallWarrior.png");
}

.shield_special_fall2015Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2015Healer.png");
}

.shield_special_fall2015Rogue {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2015Rogue.png");
}

.shield_special_fall2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2015Warrior.png");
}

.shield_special_fall2016Healer {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2016Healer.png");
}

.shield_special_fall2016Rogue {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2016Rogue.png");
}

.shield_special_fall2016Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2016Warrior.png");
}

.shield_special_fall2017Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2017Healer.png");
}

.shield_special_fall2017Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2017Rogue.png");
}

.shield_special_fall2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2017Warrior.png");
}

.shield_special_fall2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2018Healer.png");
}

.shield_special_fall2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2018Rogue.png");
}

.shield_special_fall2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2018Warrior.png");
}

.shield_special_fall2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2019Healer.png");
}

.shield_special_fall2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2019Rogue.png");
}

.shield_special_fall2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2019Warrior.png");
}

.shield_special_fall2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2020Healer.png");
}

.shield_special_fall2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2020Rogue.png");
}

.shield_special_fall2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2020Warrior.png");
}

.shield_special_fall2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2021Healer.png");
}

.shield_special_fall2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2021Rogue.png");
}

.shield_special_fall2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2021Warrior.png");
}

.shield_special_fall2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2022Healer.png");
}

.shield_special_fall2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2022Rogue.png");
}

.shield_special_fall2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fall2022Warrior.png");
}

.shield_special_fallHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fallHealer.png");
}

.shield_special_fallRogue {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fallRogue.png");
}

.shield_special_fallWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_fallWarrior.png");
}

.shop_armor_special_fall2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Healer.png");
}

.shop_armor_special_fall2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Mage.png");
}

.shop_armor_special_fall2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Rogue.png");
}

.shop_armor_special_fall2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2015Warrior.png");
}

.shop_armor_special_fall2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Healer.png");
}

.shop_armor_special_fall2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Mage.png");
}

.shop_armor_special_fall2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Rogue.png");
}

.shop_armor_special_fall2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2016Warrior.png");
}

.shop_armor_special_fall2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Healer.png");
}

.shop_armor_special_fall2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Mage.png");
}

.shop_armor_special_fall2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Rogue.png");
}

.shop_armor_special_fall2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2017Warrior.png");
}

.shop_armor_special_fall2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Healer.png");
}

.shop_armor_special_fall2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Mage.png");
}

.shop_armor_special_fall2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Rogue.png");
}

.shop_armor_special_fall2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2018Warrior.png");
}

.shop_armor_special_fall2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Healer.png");
}

.shop_armor_special_fall2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Mage.png");
}

.shop_armor_special_fall2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Rogue.png");
}

.shop_armor_special_fall2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2019Warrior.png");
}

.shop_armor_special_fall2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Healer.png");
}

.shop_armor_special_fall2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Mage.png");
}

.shop_armor_special_fall2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Rogue.png");
}

.shop_armor_special_fall2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2020Warrior.png");
}

.shop_armor_special_fall2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Healer.png");
}

.shop_armor_special_fall2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Mage.png");
}

.shop_armor_special_fall2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Rogue.png");
}

.shop_armor_special_fall2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2021Warrior.png");
}

.shop_armor_special_fall2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Healer.png");
}

.shop_armor_special_fall2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Mage.png");
}

.shop_armor_special_fall2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Rogue.png");
}

.shop_armor_special_fall2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fall2022Warrior.png");
}

.shop_armor_special_fallHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallHealer.png");
}

.shop_armor_special_fallMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallMage.png");
}

.shop_armor_special_fallRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallRogue.png");
}

.shop_armor_special_fallWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_fallWarrior.png");
}

.shop_eyewear_special_fall2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_fall2019Healer.png");
}

.shop_eyewear_special_fall2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_fall2019Rogue.png");
}

.shop_head_special_fall2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Healer.png");
}

.shop_head_special_fall2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Mage.png");
}

.shop_head_special_fall2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Rogue.png");
}

.shop_head_special_fall2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2015Warrior.png");
}

.shop_head_special_fall2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Healer.png");
}

.shop_head_special_fall2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Mage.png");
}

.shop_head_special_fall2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Rogue.png");
}

.shop_head_special_fall2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2016Warrior.png");
}

.shop_head_special_fall2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Healer.png");
}

.shop_head_special_fall2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Mage.png");
}

.shop_head_special_fall2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Rogue.png");
}

.shop_head_special_fall2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2017Warrior.png");
}

.shop_head_special_fall2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Healer.png");
}

.shop_head_special_fall2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Mage.png");
}

.shop_head_special_fall2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Rogue.png");
}

.shop_head_special_fall2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2018Warrior.png");
}

.shop_head_special_fall2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Healer.png");
}

.shop_head_special_fall2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Mage.png");
}

.shop_head_special_fall2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Rogue.png");
}

.shop_head_special_fall2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2019Warrior.png");
}

.shop_head_special_fall2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Healer.png");
}

.shop_head_special_fall2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Mage.png");
}

.shop_head_special_fall2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Rogue.png");
}

.shop_head_special_fall2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2020Warrior.png");
}

.shop_head_special_fall2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Healer.png");
}

.shop_head_special_fall2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Mage.png");
}

.shop_head_special_fall2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Rogue.png");
}

.shop_head_special_fall2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2021Warrior.png");
}

.shop_head_special_fall2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Healer.png");
}

.shop_head_special_fall2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Mage.png");
}

.shop_head_special_fall2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Rogue.png");
}

.shop_head_special_fall2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fall2022Warrior.png");
}

.shop_head_special_fallHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallHealer.png");
}

.shop_head_special_fallMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallMage.png");
}

.shop_head_special_fallRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallRogue.png");
}

.shop_head_special_fallWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fallWarrior.png");
}

.shop_shield_special_fall2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2015Healer.png");
}

.shop_shield_special_fall2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2015Rogue.png");
}

.shop_shield_special_fall2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2015Warrior.png");
}

.shop_shield_special_fall2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2016Healer.png");
}

.shop_shield_special_fall2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2016Rogue.png");
}

.shop_shield_special_fall2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2016Warrior.png");
}

.shop_shield_special_fall2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2017Healer.png");
}

.shop_shield_special_fall2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2017Rogue.png");
}

.shop_shield_special_fall2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2017Warrior.png");
}

.shop_shield_special_fall2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2018Healer.png");
}

.shop_shield_special_fall2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2018Rogue.png");
}

.shop_shield_special_fall2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2018Warrior.png");
}

.shop_shield_special_fall2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2019Healer.png");
}

.shop_shield_special_fall2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2019Rogue.png");
}

.shop_shield_special_fall2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2019Warrior.png");
}

.shop_shield_special_fall2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2020Healer.png");
}

.shop_shield_special_fall2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2020Rogue.png");
}

.shop_shield_special_fall2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2020Warrior.png");
}

.shop_shield_special_fall2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2021Healer.png");
}

.shop_shield_special_fall2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2021Rogue.png");
}

.shop_shield_special_fall2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2021Warrior.png");
}

.shop_shield_special_fall2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2022Healer.png");
}

.shop_shield_special_fall2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2022Rogue.png");
}

.shop_shield_special_fall2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fall2022Warrior.png");
}

.shop_shield_special_fallHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fallHealer.png");
}

.shop_shield_special_fallRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fallRogue.png");
}

.shop_shield_special_fallWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_fallWarrior.png");
}

.shop_weapon_special_fall2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Healer.png");
}

.shop_weapon_special_fall2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Mage.png");
}

.shop_weapon_special_fall2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Rogue.png");
}

.shop_weapon_special_fall2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2015Warrior.png");
}

.shop_weapon_special_fall2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Healer.png");
}

.shop_weapon_special_fall2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Mage.png");
}

.shop_weapon_special_fall2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Rogue.png");
}

.shop_weapon_special_fall2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2016Warrior.png");
}

.shop_weapon_special_fall2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Healer.png");
}

.shop_weapon_special_fall2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Mage.png");
}

.shop_weapon_special_fall2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Rogue.png");
}

.shop_weapon_special_fall2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2017Warrior.png");
}

.shop_weapon_special_fall2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Healer.png");
}

.shop_weapon_special_fall2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Mage.png");
}

.shop_weapon_special_fall2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Rogue.png");
}

.shop_weapon_special_fall2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2018Warrior.png");
}

.shop_weapon_special_fall2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Healer.png");
}

.shop_weapon_special_fall2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Mage.png");
}

.shop_weapon_special_fall2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Rogue.png");
}

.shop_weapon_special_fall2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2019Warrior.png");
}

.shop_weapon_special_fall2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Healer.png");
}

.shop_weapon_special_fall2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Mage.png");
}

.shop_weapon_special_fall2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Rogue.png");
}

.shop_weapon_special_fall2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2020Warrior.png");
}

.shop_weapon_special_fall2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Healer.png");
}

.shop_weapon_special_fall2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Mage.png");
}

.shop_weapon_special_fall2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Rogue.png");
}

.shop_weapon_special_fall2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2021Warrior.png");
}

.shop_weapon_special_fall2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Healer.png");
}

.shop_weapon_special_fall2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Mage.png");
}

.shop_weapon_special_fall2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Rogue.png");
}

.shop_weapon_special_fall2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fall2022Warrior.png");
}

.shop_weapon_special_fallHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallHealer.png");
}

.shop_weapon_special_fallMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallMage.png");
}

.shop_weapon_special_fallRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallRogue.png");
}

.shop_weapon_special_fallWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fallWarrior.png");
}

.slim_armor_special_fall2015Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Healer.png");
}

.slim_armor_special_fall2015Mage {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Mage.png");
}

.slim_armor_special_fall2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Rogue.png");
}

.slim_armor_special_fall2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2015Warrior.png");
}

.slim_armor_special_fall2016Healer {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Healer.png");
}

.slim_armor_special_fall2016Mage {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Mage.png");
}

.slim_armor_special_fall2016Rogue {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Rogue.png");
}

.slim_armor_special_fall2016Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2016Warrior.png");
}

.slim_armor_special_fall2017Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Healer.png");
}

.slim_armor_special_fall2017Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Mage.png");
}

.slim_armor_special_fall2017Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Rogue.png");
}

.slim_armor_special_fall2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2017Warrior.png");
}

.slim_armor_special_fall2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Healer.png");
}

.slim_armor_special_fall2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Mage.png");
}

.slim_armor_special_fall2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Rogue.png");
}

.slim_armor_special_fall2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2018Warrior.png");
}

.slim_armor_special_fall2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Healer.png");
}

.slim_armor_special_fall2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Mage.png");
}

.slim_armor_special_fall2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Rogue.png");
}

.slim_armor_special_fall2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2019Warrior.png");
}

.slim_armor_special_fall2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Healer.png");
}

.slim_armor_special_fall2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Mage.png");
}

.slim_armor_special_fall2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Rogue.png");
}

.slim_armor_special_fall2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2020Warrior.png");
}

.slim_armor_special_fall2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Healer.png");
}

.slim_armor_special_fall2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Mage.png");
}

.slim_armor_special_fall2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Rogue.png");
}

.slim_armor_special_fall2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2021Warrior.png");
}

.slim_armor_special_fall2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Healer.png");
}

.slim_armor_special_fall2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Mage.png");
}

.slim_armor_special_fall2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Rogue.png");
}

.slim_armor_special_fall2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fall2022Warrior.png");
}

.slim_armor_special_fallHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallHealer.png");
}

.slim_armor_special_fallMage {
  width: 120px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallMage.png");
}

.slim_armor_special_fallRogue {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallRogue.png");
}

.slim_armor_special_fallWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_fallWarrior.png");
}

.weapon_special_fall2015Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Healer.png");
}

.weapon_special_fall2015Mage {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Mage.png");
}

.weapon_special_fall2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Rogue.png");
}

.weapon_special_fall2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2015Warrior.png");
}

.weapon_special_fall2016Healer {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Healer.png");
}

.weapon_special_fall2016Mage {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Mage.png");
}

.weapon_special_fall2016Rogue {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Rogue.png");
}

.weapon_special_fall2016Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2016Warrior.png");
}

.weapon_special_fall2017Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Healer.png");
}

.weapon_special_fall2017Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Mage.png");
}

.weapon_special_fall2017Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Rogue.png");
}

.weapon_special_fall2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2017Warrior.png");
}

.weapon_special_fall2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Healer.png");
}

.weapon_special_fall2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Mage.png");
}

.weapon_special_fall2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Rogue.png");
}

.weapon_special_fall2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2018Warrior.png");
}

.weapon_special_fall2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Healer.png");
}

.weapon_special_fall2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Mage.png");
}

.weapon_special_fall2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Rogue.png");
}

.weapon_special_fall2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2019Warrior.png");
}

.weapon_special_fall2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Healer.png");
}

.weapon_special_fall2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Mage.png");
}

.weapon_special_fall2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Rogue.png");
}

.weapon_special_fall2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2020Warrior.png");
}

.weapon_special_fall2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Healer.png");
}

.weapon_special_fall2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Mage.png");
}

.weapon_special_fall2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Rogue.png");
}

.weapon_special_fall2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2021Warrior.png");
}

.weapon_special_fall2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Healer.png");
}

.weapon_special_fall2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Mage.png");
}

.weapon_special_fall2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Rogue.png");
}

.weapon_special_fall2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fall2022Warrior.png");
}

.weapon_special_fallHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallHealer.png");
}

.weapon_special_fallMage {
  width: 120px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallMage.png");
}

.weapon_special_fallRogue {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallRogue.png");
}

.weapon_special_fallWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fallWarrior.png");
}

.broad_armor_special_gaymerx {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_gaymerx.png");
}

.head_special_gaymerx {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_gaymerx.png");
}

.shop_armor_special_gaymerx {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_gaymerx.png");
}

.shop_head_special_gaymerx {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_gaymerx.png");
}

.slim_armor_special_gaymerx {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_gaymerx.png");
}

.shop_armor_special_ks2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_ks2019.png");
}

.shop_eyewear_special_ks2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_ks2019.png");
}

.shop_head_special_ks2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_ks2019.png");
}

.shop_shield_special_ks2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_ks2019.png");
}

.shop_weapon_special_ks2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_ks2019.png");
}

.back_mystery_201402 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201402.png");
}

.broad_armor_mystery_201402 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201402.png");
}

.head_mystery_201402 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201402.png");
}

.shop_armor_mystery_201402 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201402.png");
}

.shop_back_mystery_201402 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201402.png");
}

.shop_head_mystery_201402 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201402.png");
}

.shop_set_mystery_201402 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201402.png");
}

.slim_armor_mystery_201402 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201402.png");
}

.broad_armor_mystery_201403 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201403.png");
}

.headAccessory_mystery_201403 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201403.png");
}

.shop_armor_mystery_201403 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201403.png");
}

.shop_headAccessory_mystery_201403 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201403.png");
}

.shop_set_mystery_201403 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201403.png");
}

.slim_armor_mystery_201403 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201403.png");
}

.back_mystery_201404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201404.png");
}

.headAccessory_mystery_201404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201404.png");
}

.shop_back_mystery_201404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201404.png");
}

.shop_headAccessory_mystery_201404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201404.png");
}

.shop_set_mystery_201404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201404.png");
}

.broad_armor_mystery_201405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201405.png");
}

.head_mystery_201405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201405.png");
}

.shop_armor_mystery_201405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201405.png");
}

.shop_head_mystery_201405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201405.png");
}

.shop_set_mystery_201405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201405.png");
}

.slim_armor_mystery_201405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201405.png");
}

.broad_armor_mystery_201406 {
  width: 90px;
  height: 96px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201406.png");
}

.head_mystery_201406 {
  width: 90px;
  height: 96px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201406.png");
}

.shop_armor_mystery_201406 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201406.png");
}

.shop_head_mystery_201406 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201406.png");
}

.shop_set_mystery_201406 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201406.png");
}

.slim_armor_mystery_201406 {
  width: 90px;
  height: 96px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201406.png");
}

.broad_armor_mystery_201407 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201407.png");
}

.head_mystery_201407 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201407.png");
}

.shop_armor_mystery_201407 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201407.png");
}

.shop_head_mystery_201407 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201407.png");
}

.shop_set_mystery_201407 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201407.png");
}

.slim_armor_mystery_201407 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201407.png");
}

.broad_armor_mystery_201408 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201408.png");
}

.head_mystery_201408 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201408.png");
}

.shop_armor_mystery_201408 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201408.png");
}

.shop_head_mystery_201408 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201408.png");
}

.shop_set_mystery_201408 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201408.png");
}

.slim_armor_mystery_201408 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201408.png");
}

.broad_armor_mystery_201409 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201409.png");
}

.headAccessory_mystery_201409 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201409.png");
}

.shop_armor_mystery_201409 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201409.png");
}

.shop_headAccessory_mystery_201409 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201409.png");
}

.shop_set_mystery_201409 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201409.png");
}

.slim_armor_mystery_201409 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201409.png");
}

.back_mystery_201410 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201410.png");
}

.broad_armor_mystery_201410 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201410.png");
}

.shop_armor_mystery_201410 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201410.png");
}

.shop_back_mystery_201410 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201410.png");
}

.shop_set_mystery_201410 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201410.png");
}

.slim_armor_mystery_201410 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201410.png");
}

.head_mystery_201411 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201411.png");
}

.shop_head_mystery_201411 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201411.png");
}

.shop_set_mystery_201411 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201411.png");
}

.shop_weapon_mystery_201411 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201411.png");
}

.weapon_mystery_201411 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201411.png");
}

.broad_armor_mystery_201412 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201412.png");
}

.head_mystery_201412 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201412.png");
}

.shop_armor_mystery_201412 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201412.png");
}

.shop_head_mystery_201412 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201412.png");
}

.shop_set_mystery_201412 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201412.png");
}

.slim_armor_mystery_201412 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201412.png");
}

.broad_armor_mystery_201501 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201501.png");
}

.head_mystery_201501 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201501.png");
}

.shop_armor_mystery_201501 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201501.png");
}

.shop_head_mystery_201501 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201501.png");
}

.shop_set_mystery_201501 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201501.png");
}

.slim_armor_mystery_201501 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201501.png");
}

.headAccessory_mystery_201502 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201502.png");
}

.shop_headAccessory_mystery_201502 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201502.png");
}

.shop_set_mystery_201502 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201502.png");
}

.shop_weapon_mystery_201502 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201502.png");
}

.weapon_mystery_201502 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201502.png");
}

.broad_armor_mystery_201503 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201503.png");
}

.eyewear_mystery_201503 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201503.png");
}

.shop_armor_mystery_201503 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201503.png");
}

.shop_eyewear_mystery_201503 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201503.png");
}

.shop_set_mystery_201503 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201503.png");
}

.slim_armor_mystery_201503 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201503.png");
}

.back_mystery_201504 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201504.png");
}

.broad_armor_mystery_201504 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201504.png");
}

.shop_armor_mystery_201504 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201504.png");
}

.shop_back_mystery_201504 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201504.png");
}

.shop_set_mystery_201504 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201504.png");
}

.slim_armor_mystery_201504 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201504.png");
}

.head_mystery_201505 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201505.png");
}

.shop_head_mystery_201505 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201505.png");
}

.shop_set_mystery_201505 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201505.png");
}

.shop_weapon_mystery_201505 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201505.png");
}

.weapon_mystery_201505 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201505.png");
}

.broad_armor_mystery_201506 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201506.png");
}

.eyewear_mystery_201506 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201506.png");
}

.shop_armor_mystery_201506 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201506.png");
}

.shop_eyewear_mystery_201506 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201506.png");
}

.shop_set_mystery_201506 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201506.png");
}

.slim_armor_mystery_201506 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201506.png");
}

.back_mystery_201507 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201507.png");
}

.eyewear_mystery_201507 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201507.png");
}

.shop_back_mystery_201507 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201507.png");
}

.shop_eyewear_mystery_201507 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201507.png");
}

.shop_set_mystery_201507 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201507.png");
}

.broad_armor_mystery_201508 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201508.png");
}

.head_mystery_201508 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201508.png");
}

.shop_armor_mystery_201508 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201508.png");
}

.shop_head_mystery_201508 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201508.png");
}

.shop_set_mystery_201508 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201508.png");
}

.slim_armor_mystery_201508 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201508.png");
}

.broad_armor_mystery_201509 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201509.png");
}

.head_mystery_201509 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201509.png");
}

.shop_armor_mystery_201509 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201509.png");
}

.shop_head_mystery_201509 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201509.png");
}

.shop_set_mystery_201509 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201509.png");
}

.slim_armor_mystery_201509 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201509.png");
}

.back_mystery_201510 {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201510.png");
}

.headAccessory_mystery_201510 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201510.png");
}

.shop_back_mystery_201510 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201510.png");
}

.shop_headAccessory_mystery_201510 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201510.png");
}

.shop_set_mystery_201510 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201510.png");
}

.broad_armor_mystery_201511 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201511.png");
}

.head_mystery_201511 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201511.png");
}

.shop_armor_mystery_201511 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201511.png");
}

.shop_head_mystery_201511 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201511.png");
}

.shop_set_mystery_201511 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201511.png");
}

.slim_armor_mystery_201511 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201511.png");
}

.broad_armor_mystery_201512 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201512.png");
}

.head_mystery_201512 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201512.png");
}

.shop_armor_mystery_201512 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201512.png");
}

.shop_head_mystery_201512 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201512.png");
}

.shop_set_mystery_201512 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201512.png");
}

.slim_armor_mystery_201512 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201512.png");
}

.head_mystery_201601 {
  width: 120px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201601.png");
}

.shield_mystery_201601 {
  width: 120px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201601.png");
}

.shop_head_mystery_201601 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201601.png");
}

.shop_set_mystery_201601 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201601.png");
}

.shop_shield_mystery_201601 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201601.png");
}

.back_mystery_201602 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201602.png");
}

.head_mystery_201602 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201602.png");
}

.shop_back_mystery_201602 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201602.png");
}

.shop_head_mystery_201602 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201602.png");
}

.shop_set_mystery_201602 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201602.png");
}

.broad_armor_mystery_201603 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201603.png");
}

.head_mystery_201603 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201603.png");
}

.shop_armor_mystery_201603 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201603.png");
}

.shop_head_mystery_201603 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201603.png");
}

.shop_set_mystery_201603 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201603.png");
}

.slim_armor_mystery_201603 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201603.png");
}

.broad_armor_mystery_201604 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201604.png");
}

.head_mystery_201604 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201604.png");
}

.shop_armor_mystery_201604 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201604.png");
}

.shop_head_mystery_201604 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201604.png");
}

.shop_set_mystery_201604 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201604.png");
}

.slim_armor_mystery_201604 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201604.png");
}

.broad_armor_mystery_201605 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201605.png");
}

.head_mystery_201605 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201605.png");
}

.shop_armor_mystery_201605 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201605.png");
}

.shop_head_mystery_201605 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201605.png");
}

.shop_set_mystery_201605 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201605.png");
}

.slim_armor_mystery_201605 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201605.png");
}

.broad_armor_mystery_201606 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201606.png");
}

.head_mystery_201606 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201606.png");
}

.shop_armor_mystery_201606 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201606.png");
}

.shop_head_mystery_201606 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201606.png");
}

.shop_set_mystery_201606 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201606.png");
}

.slim_armor_mystery_201606 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201606.png");
}

.broad_armor_mystery_201607 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201607.png");
}

.head_mystery_201607 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201607.png");
}

.shop_armor_mystery_201607 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201607.png");
}

.shop_head_mystery_201607 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201607.png");
}

.shop_set_mystery_201607 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201607.png");
}

.slim_armor_mystery_201607 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201607.png");
}

.back_mystery_201608 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201608.png");
}

.head_mystery_201608 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201608.png");
}

.shop_back_mystery_201608 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201608.png");
}

.shop_head_mystery_201608 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201608.png");
}

.shop_set_mystery_201608 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201608.png");
}

.broad_armor_mystery_201609 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201609.png");
}

.head_mystery_201609 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201609.png");
}

.shop_armor_mystery_201609 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201609.png");
}

.shop_head_mystery_201609 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201609.png");
}

.shop_set_mystery_201609 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201609.png");
}

.slim_armor_mystery_201609 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201609.png");
}

.broad_armor_mystery_201610 {
  width: 90px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201610.png");
}

.head_mystery_201610 {
  width: 90px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201610.png");
}

.shop_armor_mystery_201610 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201610.png");
}

.shop_head_mystery_201610 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201610.png");
}

.shop_set_mystery_201610 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201610.png");
}

.slim_armor_mystery_201610 {
  width: 90px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201610.png");
}

.head_mystery_201611 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201611.png");
}

.shop_head_mystery_201611 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201611.png");
}

.shop_set_mystery_201611 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201611.png");
}

.shop_weapon_mystery_201611 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201611.png");
}

.weapon_mystery_201611 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201611.png");
}

.broad_armor_mystery_201612 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201612.png");
}

.head_mystery_201612 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201612.png");
}

.shop_armor_mystery_201612 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201612.png");
}

.shop_head_mystery_201612 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201612.png");
}

.shop_set_mystery_201612 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201612.png");
}

.slim_armor_mystery_201612 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201612.png");
}

.eyewear_mystery_201701 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201701.png");
}

.shield_mystery_201701 {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201701.png");
}

.shop_eyewear_mystery_201701 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201701.png");
}

.shop_set_mystery_201701 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201701.png");
}

.shop_shield_mystery_201701 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201701.png");
}

.back_mystery_201702 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201702.png");
}

.head_mystery_201702 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201702.png");
}

.shop_back_mystery_201702 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201702.png");
}

.shop_head_mystery_201702 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201702.png");
}

.shop_set_mystery_201702 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201702.png");
}

.broad_armor_mystery_201703 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201703.png");
}

.head_mystery_201703 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201703.png");
}

.shop_armor_mystery_201703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201703.png");
}

.shop_head_mystery_201703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201703.png");
}

.shop_set_mystery_201703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201703.png");
}

.slim_armor_mystery_201703 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201703.png");
}

.back_mystery_201704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201704.png");
}

.broad_armor_mystery_201704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201704.png");
}

.shop_armor_mystery_201704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201704.png");
}

.shop_back_mystery_201704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201704.png");
}

.shop_set_mystery_201704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201704.png");
}

.slim_armor_mystery_201704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201704.png");
}

.body_mystery_201705 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201705.png");
}

.head_mystery_201705 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201705.png");
}

.shop_body_mystery_201705 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201705.png");
}

.shop_head_mystery_201705 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201705.png");
}

.shop_set_mystery_201705 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201705.png");
}

.back_mystery_201706 {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201706.png");
}

.body_mystery_201706 {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201706.png");
}

.shop_back_mystery_201706 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201706.png");
}

.shop_body_mystery_201706 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201706.png");
}

.shop_set_mystery_201706 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201706.png");
}

.broad_armor_mystery_201707 {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201707.png");
}

.head_mystery_201707 {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201707.png");
}

.shop_armor_mystery_201707 {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201707.png");
}

.shop_head_mystery_201707 {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201707.png");
}

.shop_set_mystery_201707 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201707.png");
}

.slim_armor_mystery_201707 {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201707.png");
}

.shield_mystery_201708 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201708.png");
}

.shop_set_mystery_201708 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201708.png");
}

.shop_shield_mystery_201708 {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201708.png");
}

.shop_weapon_mystery_201708 {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201708.png");
}

.weapon_mystery_201708 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201708.png");
}

.back_mystery_201709 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201709.png");
}

.shield_mystery_201709 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201709.png");
}

.shop_back_mystery_201709 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201709.png");
}

.shop_set_mystery_201709 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201709.png");
}

.shop_shield_mystery_201709 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201709.png");
}

.broad_armor_mystery_201710 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201710.png");
}

.head_mystery_201710 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201710.png");
}

.shop_armor_mystery_201710 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201710.png");
}

.shop_head_mystery_201710 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201710.png");
}

.shop_set_mystery_201710 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201710.png");
}

.slim_armor_mystery_201710 {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201710.png");
}

.body_mystery_201711 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201711.png");
}

.broad_armor_mystery_201711 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201711.png");
}

.shop_armor_mystery_201711 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201711.png");
}

.shop_body_mystery_201711 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201711.png");
}

.shop_set_mystery_201711 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201711.png");
}

.slim_armor_mystery_201711 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201711.png");
}

.broad_armor_mystery_201712 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201712.png");
}

.head_mystery_201712 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201712.png");
}

.shop_armor_mystery_201712 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201712.png");
}

.shop_head_mystery_201712 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201712.png");
}

.shop_set_mystery_201712 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201712.png");
}

.slim_armor_mystery_201712 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201712.png");
}

.back_mystery_201801 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201801.png");
}

.headAccessory_mystery_201801 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201801.png");
}

.shop_back_mystery_201801 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201801.png");
}

.shop_headAccessory_mystery_201801 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201801.png");
}

.shop_set_mystery_201801 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201801.png");
}

.broad_armor_mystery_201802 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201802.png");
}

.head_mystery_201802 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201802.png");
}

.shield_mystery_201802 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201802.png");
}

.shop_armor_mystery_201802 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201802.png");
}

.shop_head_mystery_201802 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201802.png");
}

.shop_set_mystery_201802 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201802.png");
}

.shop_shield_mystery_201802 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201802.png");
}

.slim_armor_mystery_201802 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201802.png");
}

.back_mystery_201803 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201803.png");
}

.head_mystery_201803 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201803.png");
}

.shop_back_mystery_201803 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201803.png");
}

.shop_head_mystery_201803 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201803.png");
}

.shop_set_mystery_201803 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201803.png");
}

.back_mystery_201804 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201804.png");
}

.headAccessory_mystery_201804 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201804.png");
}

.shop_back_mystery_201804 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201804.png");
}

.shop_headAccessory_mystery_201804 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201804.png");
}

.shop_set_mystery_201804 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201804.png");
}

.back_mystery_201805 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201805.png");
}

.head_mystery_201805 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201805.png");
}

.shop_back_mystery_201805 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201805.png");
}

.shop_head_mystery_201805 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201805.png");
}

.shop_set_mystery_201805 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201805.png");
}

.broad_armor_mystery_201806 {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201806.png");
}

.head_mystery_201806 {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201806.png");
}

.shop_armor_mystery_201806 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201806.png");
}

.shop_head_mystery_201806 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201806.png");
}

.shop_set_mystery_201806 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201806.png");
}

.slim_armor_mystery_201806 {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201806.png");
}

.broad_armor_mystery_201807 {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201807.png");
}

.head_mystery_201807 {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201807.png");
}

.shop_armor_mystery_201807 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201807.png");
}

.shop_head_mystery_201807 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201807.png");
}

.shop_set_mystery_201807 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201807.png");
}

.slim_armor_mystery_201807 {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201807.png");
}

.broad_armor_mystery_201808 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201808.png");
}

.head_mystery_201808 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201808.png");
}

.shop_armor_mystery_201808 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201808.png");
}

.shop_head_mystery_201808 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201808.png");
}

.shop_set_mystery_201808 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201808.png");
}

.slim_armor_mystery_201808 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201808.png");
}

.broad_armor_mystery_201809 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201809.png");
}

.head_mystery_201809 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201809.png");
}

.shop_armor_mystery_201809 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201809.png");
}

.shop_head_mystery_201809 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201809.png");
}

.shop_set_mystery_201809 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201809.png");
}

.slim_armor_mystery_201809 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201809.png");
}

.broad_armor_mystery_201810 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201810.png");
}

.head_mystery_201810 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201810.png");
}

.shop_armor_mystery_201810 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201810.png");
}

.shop_head_mystery_201810 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201810.png");
}

.shop_set_mystery_201810 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201810.png");
}

.slim_armor_mystery_201810 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201810.png");
}

.head_mystery_201811 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201811.png");
}

.shop_head_mystery_201811 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201811.png");
}

.shop_set_mystery_201811 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201811.png");
}

.shop_weapon_mystery_201811 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201811.png");
}

.weapon_mystery_201811 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201811.png");
}

.back_mystery_201812 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201812.png");
}

.headAccessory_mystery_201812 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201812.png");
}

.shop_back_mystery_201812 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201812.png");
}

.shop_headAccessory_mystery_201812 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201812.png");
}

.shop_set_mystery_201812 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201812.png");
}

.body_mystery_201901 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_201901.png");
}

.head_mystery_201901 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201901.png");
}

.shop_body_mystery_201901 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_201901.png");
}

.shop_head_mystery_201901 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201901.png");
}

.shop_set_mystery_201901 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201901.png");
}

.eyewear_mystery_201902 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201902.png");
}

.shield_mystery_201902 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_201902.png");
}

.shop_eyewear_mystery_201902 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201902.png");
}

.shop_set_mystery_201902 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201902.png");
}

.shop_shield_mystery_201902 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_201902.png");
}

.broad_armor_mystery_201903 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201903.png");
}

.head_mystery_201903 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201903.png");
}

.shop_armor_mystery_201903 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201903.png");
}

.shop_head_mystery_201903 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201903.png");
}

.shop_set_mystery_201903 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201903.png");
}

.slim_armor_mystery_201903 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201903.png");
}

.broad_armor_mystery_201904 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201904.png");
}

.head_mystery_201904 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201904.png");
}

.shop_armor_mystery_201904 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201904.png");
}

.shop_head_mystery_201904 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201904.png");
}

.shop_set_mystery_201904 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201904.png");
}

.slim_armor_mystery_201904 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201904.png");
}

.back_mystery_201905 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201905.png");
}

.headAccessory_mystery_201905 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201905.png");
}

.shop_back_mystery_201905 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201905.png");
}

.shop_headAccessory_mystery_201905 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201905.png");
}

.shop_set_mystery_201905 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201905.png");
}

.broad_armor_mystery_201906 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201906.png");
}

.headAccessory_mystery_201906 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201906.png");
}

.shop_armor_mystery_201906 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201906.png");
}

.shop_headAccessory_mystery_201906 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201906.png");
}

.shop_set_mystery_201906 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201906.png");
}

.slim_armor_mystery_201906 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201906.png");
}

.broad_armor_mystery_201907 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201907.png");
}

.eyewear_mystery_201907 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_201907.png");
}

.head_mystery_201907 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201907.png");
}

.shop_armor_mystery_201907 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201907.png");
}

.shop_eyewear_mystery_201907 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_201907.png");
}

.shop_head_mystery_201907 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201907.png");
}

.shop_set_mystery_201907 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201907.png");
}

.slim_armor_mystery_201907 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201907.png");
}

.broad_armor_mystery_201908 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201908.png");
}

.headAccessory_mystery_201908 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_201908.png");
}

.shop_armor_mystery_201908 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201908.png");
}

.shop_headAccessory_mystery_201908 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_201908.png");
}

.shop_set_mystery_201908 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201908.png");
}

.slim_armor_mystery_201908 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201908.png");
}

.broad_armor_mystery_201909 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201909.png");
}

.head_mystery_201909 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201909.png");
}

.shop_armor_mystery_201909 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201909.png");
}

.shop_head_mystery_201909 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201909.png");
}

.shop_set_mystery_201909 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201909.png");
}

.slim_armor_mystery_201909 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201909.png");
}

.broad_armor_mystery_201910 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_201910.png");
}

.head_mystery_201910 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201910.png");
}

.shop_armor_mystery_201910 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_201910.png");
}

.shop_head_mystery_201910 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201910.png");
}

.shop_set_mystery_201910 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201910.png");
}

.slim_armor_mystery_201910 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_201910.png");
}

.head_mystery_201911 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201911.png");
}

.shop_head_mystery_201911 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201911.png");
}

.shop_set_mystery_201911 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201911.png");
}

.shop_weapon_mystery_201911 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_201911.png");
}

.weapon_mystery_201911 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_201911.png");
}

.back_mystery_201912 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_201912.png");
}

.head_mystery_201912 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_201912.png");
}

.shop_back_mystery_201912 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_201912.png");
}

.shop_head_mystery_201912 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_201912.png");
}

.shop_set_mystery_201912 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_201912.png");
}

.back_mystery_202001 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202001.png");
}

.head_mystery_202001 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202001.png");
}

.shop_back_mystery_202001 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202001.png");
}

.shop_head_mystery_202001 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202001.png");
}

.shop_set_mystery_202001 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202001.png");
}

.body_mystery_202002 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202002.png");
}

.shop_body_mystery_202002 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202002.png");
}

.shop_set_mystery_202002 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202002.png");
}

.shop_weapon_mystery_202002 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202002.png");
}

.weapon_mystery_202002 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202002.png");
}

.body_mystery_202003 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202003.png");
}

.head_mystery_202003 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202003.png");
}

.shop_body_mystery_202003 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202003.png");
}

.shop_head_mystery_202003 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202003.png");
}

.shop_set_mystery_202003 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202003.png");
}

.back_mystery_202004 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202004.png");
}

.headAccessory_mystery_202004 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202004.png");
}

.shop_back_mystery_202004 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202004.png");
}

.shop_headAccessory_mystery_202004 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202004.png");
}

.shop_set_mystery_202004 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202004.png");
}

.back_mystery_202005 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202005.png");
}

.headAccessory_mystery_202005 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202005.png");
}

.shop_back_mystery_202005 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202005.png");
}

.shop_headAccessory_mystery_202005 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202005.png");
}

.shop_set_mystery_202005 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202005.png");
}

.broad_armor_mystery_202006 {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202006.png");
}

.head_mystery_202006 {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202006.png");
}

.shop_armor_mystery_202006 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202006.png");
}

.shop_head_mystery_202006 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202006.png");
}

.shop_set_mystery_202006 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202006.png");
}

.slim_armor_mystery_202006 {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202006.png");
}

.broad_armor_mystery_202007 {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202007.png");
}

.head_mystery_202007 {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202007.png");
}

.shop_armor_mystery_202007 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202007.png");
}

.shop_head_mystery_202007 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202007.png");
}

.shop_set_mystery_202007 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202007.png");
}

.slim_armor_mystery_202007 {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202007.png");
}

.body_mystery_202008 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202008.png");
}

.head_mystery_202008 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202008.png");
}

.shop_body_mystery_202008 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202008.png");
}

.shop_head_mystery_202008 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202008.png");
}

.shop_set_mystery_202008 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202008.png");
}

.back_mystery_202009 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202009.png");
}

.headAccessory_mystery_202009 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202009.png");
}

.shop_back_mystery_202009 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202009.png");
}

.shop_headAccessory_mystery_202009 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202009.png");
}

.shop_set_mystery_202009 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202009.png");
}

.back_mystery_202010 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202010.png");
}

.head_mystery_202010 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202010.png");
}

.shop_back_mystery_202010 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202010.png");
}

.shop_head_mystery_202010 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202010.png");
}

.shop_set_mystery_202010 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202010.png");
}

.head_mystery_202011 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202011.png");
}

.shield_mystery_202011 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_202011.png");
}

.shop_head_mystery_202011 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202011.png");
}

.shop_set_mystery_202011 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202011.png");
}

.shop_shield_mystery_202011 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_202011.png");
}

.back_mystery_202012 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202012.png");
}

.head_mystery_202012 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202012.png");
}

.shop_back_mystery_202012 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202012.png");
}

.shop_head_mystery_202012 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202012.png");
}

.shop_set_mystery_202012 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202012.png");
}

.broad_armor_mystery_202101 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202101.png");
}

.head_mystery_202101 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202101.png");
}

.shop_armor_mystery_202101 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202101.png");
}

.shop_head_mystery_202101 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202101.png");
}

.shop_set_mystery_202101 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202101.png");
}

.slim_armor_mystery_202101 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202101.png");
}

.broad_armor_mystery_202102 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202102.png");
}

.headAccessory_mystery_202102 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202102.png");
}

.shop_armor_mystery_202102 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202102.png");
}

.shop_headAccessory_mystery_202102 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202102.png");
}

.shop_set_mystery_202102 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202102.png");
}

.shop_weapon_mystery_202102 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202102.png");
}

.slim_armor_mystery_202102 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202102.png");
}

.weapon_mystery_202102 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202102.png");
}

.broad_armor_mystery_202103 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202103.png");
}

.head_mystery_202103 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202103.png");
}

.shop_armor_mystery_202103 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202103.png");
}

.shop_head_mystery_202103 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202103.png");
}

.shop_set_mystery_202103 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202103.png");
}

.slim_armor_mystery_202103 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202103.png");
}

.broad_armor_mystery_202104 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202104.png");
}

.shop_armor_mystery_202104 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202104.png");
}

.shop_set_mystery_202104 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202104.png");
}

.shop_weapon_mystery_202104 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202104.png");
}

.slim_armor_mystery_202104 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202104.png");
}

.weapon_mystery_202104 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202104.png");
}

.back_mystery_202105 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202105.png");
}

.headAccessory_mystery_202105 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202105.png");
}

.shop_back_mystery_202105 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202105.png");
}

.shop_headAccessory_mystery_202105 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202105.png");
}

.shop_set_mystery_202105 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202105.png");
}

.broad_armor_mystery_202106 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202106.png");
}

.head_mystery_202106 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202106.png");
}

.shop_armor_mystery_202106 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202106.png");
}

.shop_head_mystery_202106 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202106.png");
}

.shop_set_mystery_202106 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202106.png");
}

.slim_armor_mystery_202106 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202106.png");
}

.body_mystery_202107 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_mystery_202107.png");
}

.head_mystery_202107 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202107.png");
}

.shop_body_mystery_202107 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_mystery_202107.png");
}

.shop_head_mystery_202107 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202107.png");
}

.shop_set_mystery_202107 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202107.png");
}

.eyewear_mystery_202108 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202108.png");
}

.head_mystery_202108 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202108.png");
}

.shop_eyewear_mystery_202108 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202108.png");
}

.shop_head_mystery_202108 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202108.png");
}

.shop_set_mystery_202108 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202108.png");
}

.back_mystery_202109 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202109.png");
}

.headAccessory_mystery_202109 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202109.png");
}

.shop_back_mystery_202109 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202109.png");
}

.shop_headAccessory_mystery_202109 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202109.png");
}

.shop_set_mystery_202109 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202109.png");
}

.broad_armor_mystery_202110 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202110.png");
}

.head_mystery_202110 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202110.png");
}

.shop_armor_mystery_202110 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202110.png");
}

.shop_head_mystery_202110 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202110.png");
}

.shop_set_mystery_202110 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202110.png");
}

.slim_armor_mystery_202110 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202110.png");
}

.head_mystery_202111 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202111.png");
}

.shop_head_mystery_202111 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202111.png");
}

.shop_set_mystery_202111 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202111.png");
}

.shop_weapon_mystery_202111 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202111.png");
}

.weapon_mystery_202111 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202111.png");
}

.broad_armor_mystery_202112 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202112.png");
}

.head_mystery_202112 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202112.png");
}

.shop_armor_mystery_202112 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202112.png");
}

.shop_head_mystery_202112 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202112.png");
}

.shop_set_mystery_202112 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202112.png");
}

.slim_armor_mystery_202112 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202112.png");
}

.eyewear_mystery_202201 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202201.png");
}

.shop_eyewear_mystery_202201 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202201.png");
}

.shop_set_mystery_202201 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202201.png");
}

.shop_weapon_mystery_202201 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202201.png");
}

.weapon_mystery_202201 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202201.png");
}

.eyewear_mystery_202202 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202202.png");
}

.head_mystery_202202 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202202.png");
}

.shop_eyewear_mystery_202202 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202202.png");
}

.shop_head_mystery_202202 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202202.png");
}

.shop_set_mystery_202202 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202202.png");
}

.back_mystery_202203 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202203.png");
}

.headAccessory_mystery_202203 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202203.png");
}

.shop_back_mystery_202203 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202203.png");
}

.shop_headAccessory_mystery_202203 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202203.png");
}

.shop_set_mystery_202203 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202203.png");
}

.broad_armor_mystery_202204 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202204.png");
}

.eyewear_mystery_202204A {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202204A.png");
}

.eyewear_mystery_202204B {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202204B.png");
}

.shop_armor_mystery_202204 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202204.png");
}

.shop_eyewear_mystery_202204A {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202204A.png");
}

.shop_eyewear_mystery_202204B {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202204B.png");
}

.shop_set_mystery_202204 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202204.png");
}

.slim_armor_mystery_202204 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202204.png");
}

.back_mystery_202205 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202205.png");
}

.headAccessory_mystery_202205 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202205.png");
}

.shop_back_mystery_202205 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202205.png");
}

.shop_headAccessory_mystery_202205 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202205.png");
}

.shop_set_mystery_202205 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202205.png");
}

.back_mystery_202206 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202206.png");
}

.head_mystery_202206 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202206.png");
}

.shop_back_mystery_202206 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202206.png");
}

.shop_head_mystery_202206 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202206.png");
}

.shop_set_mystery_202206 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202206.png");
}

.broad_armor_mystery_202207 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202207.png");
}

.head_mystery_202207 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202207.png");
}

.shop_armor_mystery_202207 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202207.png");
}

.shop_head_mystery_202207 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202207.png");
}

.shop_set_mystery_202207 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202207.png");
}

.slim_armor_mystery_202207 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202207.png");
}

.eyewear_mystery_202208 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202208.png");
}

.head_mystery_202208 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202208.png");
}

.shop_eyewear_mystery_202208 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202208.png");
}

.shop_head_mystery_202208 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202208.png");
}

.shop_set_mystery_202208 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202208.png");
}

.shield_mystery_202209 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_202209.png");
}

.shop_set_mystery_202209 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202209.png");
}

.shop_shield_mystery_202209 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_202209.png");
}

.shop_weapon_mystery_202209 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202209.png");
}

.weapon_mystery_202209 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202209.png");
}

.broad_armor_mystery_202210 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202210.png");
}

.head_mystery_202210 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202210.png");
}

.shop_armor_mystery_202210 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202210.png");
}

.shop_head_mystery_202210 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202210.png");
}

.shop_set_mystery_202210 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202210.png");
}

.slim_armor_mystery_202210 {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202210.png");
}

.head_mystery_202211 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202211.png");
}

.shop_head_mystery_202211 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202211.png");
}

.shop_set_mystery_202211 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202211.png");
}

.shop_weapon_mystery_202211 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202211.png");
}

.weapon_mystery_202211 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202211.png");
}

.broad_armor_mystery_202212 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202212.png");
}

.headAccessory_mystery_202212 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202212.png");
}

.shop_armor_mystery_202212 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202212.png");
}

.shop_headAccessory_mystery_202212 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202212.png");
}

.shop_set_mystery_202212 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202212.png");
}

.shop_weapon_mystery_202212 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_202212.png");
}

.slim_armor_mystery_202212 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202212.png");
}

.weapon_mystery_202212 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_202212.png");
}

.back_mystery_202301 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202301.png");
}

.head_mystery_202301 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202301.png");
}

.shop_back_mystery_202301 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202301.png");
}

.shop_head_mystery_202301 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202301.png");
}

.shop_set_mystery_202301 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202301.png");
}

.back_mystery_202302 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202302.png");
}

.headAccessory_mystery_202302 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202302.png");
}

.shop_back_mystery_202302 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202302.png");
}

.shop_headAccessory_mystery_202302 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202302.png");
}

.shop_set_mystery_202302 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202302.png");
}

.eyewear_mystery_202303 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_202303.png");
}

.head_mystery_202303 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202303.png");
}

.shop_eyewear_mystery_202303 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_202303.png");
}

.shop_head_mystery_202303 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202303.png");
}

.shop_set_mystery_202303 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202303.png");
}

.broad_armor_mystery_202304 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_202304.png");
}

.head_mystery_202304 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_202304.png");
}

.set_mystery_202304 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/set_mystery_202304.png");
}

.shop_armor_mystery_202304 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_202304.png");
}

.shop_head_mystery_202304 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_202304.png");
}

.slim_armor_mystery_202304 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_202304.png");
}

.back_mystery_202305 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_mystery_202305.png");
}

.headAccessory_mystery_202305 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_202305.png");
}

.shop_back_mystery_202305 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_mystery_202305.png");
}

.shop_headAccessory_mystery_202305 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_202305.png");
}

.shop_set_mystery_202305 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_202305.png");
}

.broad_armor_mystery_301404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_301404.png");
}

.eyewear_mystery_301404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_301404.png");
}

.head_mystery_301404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301404.png");
}

.shop_armor_mystery_301404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_301404.png");
}

.shop_eyewear_mystery_301404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_301404.png");
}

.shop_head_mystery_301404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301404.png");
}

.shop_set_mystery_301404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301404.png");
}

.shop_weapon_mystery_301404 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_mystery_301404.png");
}

.slim_armor_mystery_301404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_301404.png");
}

.weapon_mystery_301404 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_mystery_301404.png");
}

.eyewear_mystery_301405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_301405.png");
}

.headAccessory_mystery_301405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_mystery_301405.png");
}

.head_mystery_301405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301405.png");
}

.shield_mystery_301405 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_301405.png");
}

.shop_eyewear_mystery_301405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_301405.png");
}

.shop_headAccessory_mystery_301405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_mystery_301405.png");
}

.shop_head_mystery_301405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301405.png");
}

.shop_set_mystery_301405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301405.png");
}

.shop_shield_mystery_301405 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_301405.png");
}

.broad_armor_mystery_301703 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_301703.png");
}

.eyewear_mystery_301703 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_mystery_301703.png");
}

.head_mystery_301703 {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301703.png");
}

.shop_armor_mystery_301703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_301703.png");
}

.shop_eyewear_mystery_301703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_mystery_301703.png");
}

.shop_head_mystery_301703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301703.png");
}

.shop_set_mystery_301703 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301703.png");
}

.slim_armor_mystery_301703 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_301703.png");
}

.broad_armor_mystery_301704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_mystery_301704.png");
}

.head_mystery_301704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_mystery_301704.png");
}

.shield_mystery_301704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_mystery_301704.png");
}

.shop_armor_mystery_301704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_mystery_301704.png");
}

.shop_head_mystery_301704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_mystery_301704.png");
}

.shop_set_mystery_301704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_set_mystery_301704.png");
}

.shop_shield_mystery_301704 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_mystery_301704.png");
}

.slim_armor_mystery_301704 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_mystery_301704.png");
}

.head_special_piDay {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_piDay.png");
}

.shield_special_piDay {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_piDay.png");
}

.shop_head_special_piDay {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_piDay.png");
}

.shop_shield_special_piDay {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_piDay.png");
}

.broad_armor_special_spring2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Healer.png");
}

.broad_armor_special_spring2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Mage.png");
}

.broad_armor_special_spring2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Rogue.png");
}

.broad_armor_special_spring2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2015Warrior.png");
}

.broad_armor_special_spring2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Healer.png");
}

.broad_armor_special_spring2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Mage.png");
}

.broad_armor_special_spring2016Rogue {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Rogue.png");
}

.broad_armor_special_spring2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2016Warrior.png");
}

.broad_armor_special_spring2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Healer.png");
}

.broad_armor_special_spring2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Mage.png");
}

.broad_armor_special_spring2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Rogue.png");
}

.broad_armor_special_spring2017Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2017Warrior.png");
}

.broad_armor_special_spring2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Healer.png");
}

.broad_armor_special_spring2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Mage.png");
}

.broad_armor_special_spring2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Rogue.png");
}

.broad_armor_special_spring2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2018Warrior.png");
}

.broad_armor_special_spring2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Healer.png");
}

.broad_armor_special_spring2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Mage.png");
}

.broad_armor_special_spring2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Rogue.png");
}

.broad_armor_special_spring2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2019Warrior.png");
}

.broad_armor_special_spring2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Healer.png");
}

.broad_armor_special_spring2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Mage.png");
}

.broad_armor_special_spring2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Rogue.png");
}

.broad_armor_special_spring2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2020Warrior.png");
}

.broad_armor_special_spring2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Healer.png");
}

.broad_armor_special_spring2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Mage.png");
}

.broad_armor_special_spring2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Rogue.png");
}

.broad_armor_special_spring2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2021Warrior.png");
}

.broad_armor_special_spring2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Healer.png");
}

.broad_armor_special_spring2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Mage.png");
}

.broad_armor_special_spring2022Rogue {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Rogue.png");
}

.broad_armor_special_spring2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2022Warrior.png");
}

.broad_armor_special_spring2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Healer.png");
}

.broad_armor_special_spring2023Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Mage.png");
}

.broad_armor_special_spring2023Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Rogue.png");
}

.broad_armor_special_spring2023Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_spring2023Warrior.png");
}

.broad_armor_special_springHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springHealer.png");
}

.broad_armor_special_springMage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springMage.png");
}

.broad_armor_special_springRogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springRogue.png");
}

.broad_armor_special_springWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_springWarrior.png");
}

.headAccessory_special_spring2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Healer.png");
}

.headAccessory_special_spring2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Mage.png");
}

.headAccessory_special_spring2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Rogue.png");
}

.headAccessory_special_spring2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2015Warrior.png");
}

.headAccessory_special_spring2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Healer.png");
}

.headAccessory_special_spring2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Mage.png");
}

.headAccessory_special_spring2016Rogue {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Rogue.png");
}

.headAccessory_special_spring2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2016Warrior.png");
}

.headAccessory_special_spring2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Healer.png");
}

.headAccessory_special_spring2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Mage.png");
}

.headAccessory_special_spring2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Rogue.png");
}

.headAccessory_special_spring2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_spring2017Warrior.png");
}

.headAccessory_special_springHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springHealer.png");
}

.headAccessory_special_springMage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springMage.png");
}

.headAccessory_special_springRogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springRogue.png");
}

.headAccessory_special_springWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_springWarrior.png");
}

.head_special_spring2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Healer.png");
}

.head_special_spring2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Mage.png");
}

.head_special_spring2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Rogue.png");
}

.head_special_spring2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2015Warrior.png");
}

.head_special_spring2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Healer.png");
}

.head_special_spring2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Mage.png");
}

.head_special_spring2016Rogue {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Rogue.png");
}

.head_special_spring2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2016Warrior.png");
}

.head_special_spring2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Healer.png");
}

.head_special_spring2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Mage.png");
}

.head_special_spring2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Rogue.png");
}

.head_special_spring2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2017Warrior.png");
}

.head_special_spring2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Healer.png");
}

.head_special_spring2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Mage.png");
}

.head_special_spring2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Rogue.png");
}

.head_special_spring2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2018Warrior.png");
}

.head_special_spring2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Healer.png");
}

.head_special_spring2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Mage.png");
}

.head_special_spring2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Rogue.png");
}

.head_special_spring2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2019Warrior.png");
}

.head_special_spring2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Healer.png");
}

.head_special_spring2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Mage.png");
}

.head_special_spring2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Rogue.png");
}

.head_special_spring2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2020Warrior.png");
}

.head_special_spring2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Healer.png");
}

.head_special_spring2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Mage.png");
}

.head_special_spring2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Rogue.png");
}

.head_special_spring2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2021Warrior.png");
}

.head_special_spring2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Healer.png");
}

.head_special_spring2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Mage.png");
}

.head_special_spring2022Rogue {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Rogue.png");
}

.head_special_spring2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2022Warrior.png");
}

.head_special_spring2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Healer.png");
}

.head_special_spring2023Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Mage.png");
}

.head_special_spring2023Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Rogue.png");
}

.head_special_spring2023Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spring2023Warrior.png");
}

.head_special_springHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springHealer.png");
}

.head_special_springMage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springMage.png");
}

.head_special_springRogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springRogue.png");
}

.head_special_springWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_springWarrior.png");
}

.shield_special_spring2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2015Healer.png");
}

.shield_special_spring2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2015Rogue.png");
}

.shield_special_spring2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2015Warrior.png");
}

.shield_special_spring2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2016Healer.png");
}

.shield_special_spring2016Rogue {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2016Rogue.png");
}

.shield_special_spring2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2016Warrior.png");
}

.shield_special_spring2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2017Healer.png");
}

.shield_special_spring2017Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2017Rogue.png");
}

.shield_special_spring2017Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2017Warrior.png");
}

.shield_special_spring2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2018Healer.png");
}

.shield_special_spring2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2018Rogue.png");
}

.shield_special_spring2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2018Warrior.png");
}

.shield_special_spring2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2019Healer.png");
}

.shield_special_spring2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2019Rogue.png");
}

.shield_special_spring2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2019Warrior.png");
}

.shield_special_spring2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2020Healer.png");
}

.shield_special_spring2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2020Rogue.png");
}

.shield_special_spring2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2020Warrior.png");
}

.shield_special_spring2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2021Healer.png");
}

.shield_special_spring2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2021Rogue.png");
}

.shield_special_spring2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2021Warrior.png");
}

.shield_special_spring2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2022Healer.png");
}

.shield_special_spring2022Rogue {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2022Rogue.png");
}

.shield_special_spring2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2022Warrior.png");
}

.shield_special_spring2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2023Healer.png");
}

.shield_special_spring2023Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2023Rogue.png");
}

.shield_special_spring2023Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_spring2023Warrior.png");
}

.shield_special_springHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_springHealer.png");
}

.shield_special_springRogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_springRogue.png");
}

.shield_special_springWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_springWarrior.png");
}

.shop_armor_special_spring2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Healer.png");
}

.shop_armor_special_spring2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Mage.png");
}

.shop_armor_special_spring2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Rogue.png");
}

.shop_armor_special_spring2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2015Warrior.png");
}

.shop_armor_special_spring2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Healer.png");
}

.shop_armor_special_spring2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Mage.png");
}

.shop_armor_special_spring2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Rogue.png");
}

.shop_armor_special_spring2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2016Warrior.png");
}

.shop_armor_special_spring2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Healer.png");
}

.shop_armor_special_spring2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Mage.png");
}

.shop_armor_special_spring2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Rogue.png");
}

.shop_armor_special_spring2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2017Warrior.png");
}

.shop_armor_special_spring2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Healer.png");
}

.shop_armor_special_spring2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Mage.png");
}

.shop_armor_special_spring2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Rogue.png");
}

.shop_armor_special_spring2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2018Warrior.png");
}

.shop_armor_special_spring2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Healer.png");
}

.shop_armor_special_spring2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Mage.png");
}

.shop_armor_special_spring2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Rogue.png");
}

.shop_armor_special_spring2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2019Warrior.png");
}

.shop_armor_special_spring2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Healer.png");
}

.shop_armor_special_spring2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Mage.png");
}

.shop_armor_special_spring2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Rogue.png");
}

.shop_armor_special_spring2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2020Warrior.png");
}

.shop_armor_special_spring2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Healer.png");
}

.shop_armor_special_spring2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Mage.png");
}

.shop_armor_special_spring2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Rogue.png");
}

.shop_armor_special_spring2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2021Warrior.png");
}

.shop_armor_special_spring2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Healer.png");
}

.shop_armor_special_spring2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Mage.png");
}

.shop_armor_special_spring2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Rogue.png");
}

.shop_armor_special_spring2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2022Warrior.png");
}

.shop_armor_special_spring2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Healer.png");
}

.shop_armor_special_spring2023Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Mage.png");
}

.shop_armor_special_spring2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Rogue.png");
}

.shop_armor_special_spring2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_spring2023Warrior.png");
}

.shop_armor_special_springHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springHealer.png");
}

.shop_armor_special_springMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springMage.png");
}

.shop_armor_special_springRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springRogue.png");
}

.shop_armor_special_springWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_springWarrior.png");
}

.shop_headAccessory_special_spring2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Healer.png");
}

.shop_headAccessory_special_spring2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Mage.png");
}

.shop_headAccessory_special_spring2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Rogue.png");
}

.shop_headAccessory_special_spring2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2015Warrior.png");
}

.shop_headAccessory_special_spring2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Healer.png");
}

.shop_headAccessory_special_spring2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Mage.png");
}

.shop_headAccessory_special_spring2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Rogue.png");
}

.shop_headAccessory_special_spring2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2016Warrior.png");
}

.shop_headAccessory_special_spring2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Healer.png");
}

.shop_headAccessory_special_spring2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Mage.png");
}

.shop_headAccessory_special_spring2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Rogue.png");
}

.shop_headAccessory_special_spring2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_spring2017Warrior.png");
}

.shop_headAccessory_special_springHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springHealer.png");
}

.shop_headAccessory_special_springMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springMage.png");
}

.shop_headAccessory_special_springRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springRogue.png");
}

.shop_headAccessory_special_springWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_springWarrior.png");
}

.shop_head_special_spring2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Healer.png");
}

.shop_head_special_spring2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Mage.png");
}

.shop_head_special_spring2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Rogue.png");
}

.shop_head_special_spring2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2015Warrior.png");
}

.shop_head_special_spring2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Healer.png");
}

.shop_head_special_spring2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Mage.png");
}

.shop_head_special_spring2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Rogue.png");
}

.shop_head_special_spring2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2016Warrior.png");
}

.shop_head_special_spring2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Healer.png");
}

.shop_head_special_spring2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Mage.png");
}

.shop_head_special_spring2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Rogue.png");
}

.shop_head_special_spring2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2017Warrior.png");
}

.shop_head_special_spring2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Healer.png");
}

.shop_head_special_spring2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Mage.png");
}

.shop_head_special_spring2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Rogue.png");
}

.shop_head_special_spring2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2018Warrior.png");
}

.shop_head_special_spring2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Healer.png");
}

.shop_head_special_spring2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Mage.png");
}

.shop_head_special_spring2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Rogue.png");
}

.shop_head_special_spring2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2019Warrior.png");
}

.shop_head_special_spring2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Healer.png");
}

.shop_head_special_spring2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Mage.png");
}

.shop_head_special_spring2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Rogue.png");
}

.shop_head_special_spring2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2020Warrior.png");
}

.shop_head_special_spring2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Healer.png");
}

.shop_head_special_spring2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Mage.png");
}

.shop_head_special_spring2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Rogue.png");
}

.shop_head_special_spring2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2021Warrior.png");
}

.shop_head_special_spring2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Healer.png");
}

.shop_head_special_spring2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Mage.png");
}

.shop_head_special_spring2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Rogue.png");
}

.shop_head_special_spring2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2022Warrior.png");
}

.shop_head_special_spring2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Healer.png");
}

.shop_head_special_spring2023Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Mage.png");
}

.shop_head_special_spring2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Rogue.png");
}

.shop_head_special_spring2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spring2023Warrior.png");
}

.shop_head_special_springHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springHealer.png");
}

.shop_head_special_springMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springMage.png");
}

.shop_head_special_springRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springRogue.png");
}

.shop_head_special_springWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_springWarrior.png");
}

.shop_shield_special_spring2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2015Healer.png");
}

.shop_shield_special_spring2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2015Rogue.png");
}

.shop_shield_special_spring2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2015Warrior.png");
}

.shop_shield_special_spring2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2016Healer.png");
}

.shop_shield_special_spring2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2016Rogue.png");
}

.shop_shield_special_spring2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2016Warrior.png");
}

.shop_shield_special_spring2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2017Healer.png");
}

.shop_shield_special_spring2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2017Rogue.png");
}

.shop_shield_special_spring2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2017Warrior.png");
}

.shop_shield_special_spring2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2018Healer.png");
}

.shop_shield_special_spring2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2018Rogue.png");
}

.shop_shield_special_spring2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2018Warrior.png");
}

.shop_shield_special_spring2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2019Healer.png");
}

.shop_shield_special_spring2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2019Rogue.png");
}

.shop_shield_special_spring2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2019Warrior.png");
}

.shop_shield_special_spring2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2020Healer.png");
}

.shop_shield_special_spring2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2020Rogue.png");
}

.shop_shield_special_spring2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2020Warrior.png");
}

.shop_shield_special_spring2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2021Healer.png");
}

.shop_shield_special_spring2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2021Rogue.png");
}

.shop_shield_special_spring2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2021Warrior.png");
}

.shop_shield_special_spring2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2022Healer.png");
}

.shop_shield_special_spring2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2022Rogue.png");
}

.shop_shield_special_spring2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2022Warrior.png");
}

.shop_shield_special_spring2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2023Healer.png");
}

.shop_shield_special_spring2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2023Rogue.png");
}

.shop_shield_special_spring2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_spring2023Warrior.png");
}

.shop_shield_special_springHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_springHealer.png");
}

.shop_shield_special_springRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_springRogue.png");
}

.shop_shield_special_springWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_springWarrior.png");
}

.shop_weapon_special_spring2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Healer.png");
}

.shop_weapon_special_spring2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Mage.png");
}

.shop_weapon_special_spring2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Rogue.png");
}

.shop_weapon_special_spring2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2015Warrior.png");
}

.shop_weapon_special_spring2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Healer.png");
}

.shop_weapon_special_spring2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Mage.png");
}

.shop_weapon_special_spring2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Rogue.png");
}

.shop_weapon_special_spring2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2016Warrior.png");
}

.shop_weapon_special_spring2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Healer.png");
}

.shop_weapon_special_spring2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Mage.png");
}

.shop_weapon_special_spring2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Rogue.png");
}

.shop_weapon_special_spring2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2017Warrior.png");
}

.shop_weapon_special_spring2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Healer.png");
}

.shop_weapon_special_spring2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Mage.png");
}

.shop_weapon_special_spring2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Rogue.png");
}

.shop_weapon_special_spring2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2018Warrior.png");
}

.shop_weapon_special_spring2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Healer.png");
}

.shop_weapon_special_spring2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Mage.png");
}

.shop_weapon_special_spring2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Rogue.png");
}

.shop_weapon_special_spring2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2019Warrior.png");
}

.shop_weapon_special_spring2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Healer.png");
}

.shop_weapon_special_spring2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Mage.png");
}

.shop_weapon_special_spring2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Rogue.png");
}

.shop_weapon_special_spring2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2020Warrior.png");
}

.shop_weapon_special_spring2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Healer.png");
}

.shop_weapon_special_spring2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Mage.png");
}

.shop_weapon_special_spring2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Rogue.png");
}

.shop_weapon_special_spring2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2021Warrior.png");
}

.shop_weapon_special_spring2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Healer.png");
}

.shop_weapon_special_spring2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Mage.png");
}

.shop_weapon_special_spring2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Rogue.png");
}

.shop_weapon_special_spring2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2022Warrior.png");
}

.shop_weapon_special_spring2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Healer.png");
}

.shop_weapon_special_spring2023Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Mage.png");
}

.shop_weapon_special_spring2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Rogue.png");
}

.shop_weapon_special_spring2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_spring2023Warrior.png");
}

.shop_weapon_special_springHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springHealer.png");
}

.shop_weapon_special_springMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springMage.png");
}

.shop_weapon_special_springRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springRogue.png");
}

.shop_weapon_special_springWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_springWarrior.png");
}

.slim_armor_special_spring2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Healer.png");
}

.slim_armor_special_spring2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Mage.png");
}

.slim_armor_special_spring2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Rogue.png");
}

.slim_armor_special_spring2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2015Warrior.png");
}

.slim_armor_special_spring2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Healer.png");
}

.slim_armor_special_spring2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Mage.png");
}

.slim_armor_special_spring2016Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Rogue.png");
}

.slim_armor_special_spring2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2016Warrior.png");
}

.slim_armor_special_spring2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Healer.png");
}

.slim_armor_special_spring2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Mage.png");
}

.slim_armor_special_spring2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Rogue.png");
}

.slim_armor_special_spring2017Warrior {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2017Warrior.png");
}

.slim_armor_special_spring2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Healer.png");
}

.slim_armor_special_spring2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Mage.png");
}

.slim_armor_special_spring2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Rogue.png");
}

.slim_armor_special_spring2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2018Warrior.png");
}

.slim_armor_special_spring2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Healer.png");
}

.slim_armor_special_spring2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Mage.png");
}

.slim_armor_special_spring2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Rogue.png");
}

.slim_armor_special_spring2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2019Warrior.png");
}

.slim_armor_special_spring2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Healer.png");
}

.slim_armor_special_spring2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Mage.png");
}

.slim_armor_special_spring2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Rogue.png");
}

.slim_armor_special_spring2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2020Warrior.png");
}

.slim_armor_special_spring2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Healer.png");
}

.slim_armor_special_spring2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Mage.png");
}

.slim_armor_special_spring2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Rogue.png");
}

.slim_armor_special_spring2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2021Warrior.png");
}

.slim_armor_special_spring2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Healer.png");
}

.slim_armor_special_spring2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Mage.png");
}

.slim_armor_special_spring2022Rogue {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Rogue.png");
}

.slim_armor_special_spring2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2022Warrior.png");
}

.slim_armor_special_spring2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Healer.png");
}

.slim_armor_special_spring2023Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Mage.png");
}

.slim_armor_special_spring2023Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Rogue.png");
}

.slim_armor_special_spring2023Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_spring2023Warrior.png");
}

.slim_armor_special_springHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springHealer.png");
}

.slim_armor_special_springMage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springMage.png");
}

.slim_armor_special_springRogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springRogue.png");
}

.slim_armor_special_springWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_springWarrior.png");
}

.weapon_special_spring2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Healer.png");
}

.weapon_special_spring2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Mage.png");
}

.weapon_special_spring2015Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Rogue.png");
}

.weapon_special_spring2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2015Warrior.png");
}

.weapon_special_spring2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Healer.png");
}

.weapon_special_spring2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Mage.png");
}

.weapon_special_spring2016Rogue {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Rogue.png");
}

.weapon_special_spring2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2016Warrior.png");
}

.weapon_special_spring2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Healer.png");
}

.weapon_special_spring2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Mage.png");
}

.weapon_special_spring2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Rogue.png");
}

.weapon_special_spring2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2017Warrior.png");
}

.weapon_special_spring2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Healer.png");
}

.weapon_special_spring2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Mage.png");
}

.weapon_special_spring2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Rogue.png");
}

.weapon_special_spring2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2018Warrior.png");
}

.weapon_special_spring2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Healer.png");
}

.weapon_special_spring2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Mage.png");
}

.weapon_special_spring2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Rogue.png");
}

.weapon_special_spring2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2019Warrior.png");
}

.weapon_special_spring2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Healer.png");
}

.weapon_special_spring2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Mage.png");
}

.weapon_special_spring2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Rogue.png");
}

.weapon_special_spring2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2020Warrior.png");
}

.weapon_special_spring2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Healer.png");
}

.weapon_special_spring2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Mage.png");
}

.weapon_special_spring2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Rogue.png");
}

.weapon_special_spring2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2021Warrior.png");
}

.weapon_special_spring2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Healer.png");
}

.weapon_special_spring2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Mage.png");
}

.weapon_special_spring2022Rogue {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Rogue.png");
}

.weapon_special_spring2022Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2022Warrior.png");
}

.weapon_special_spring2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Healer.png");
}

.weapon_special_spring2023Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Mage.png");
}

.weapon_special_spring2023Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Rogue.png");
}

.weapon_special_spring2023Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_spring2023Warrior.png");
}

.weapon_special_springHealer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springHealer.png");
}

.weapon_special_springMage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springMage.png");
}

.weapon_special_springRogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springRogue.png");
}

.weapon_special_springWarrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_springWarrior.png");
}

.body_special_summer2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Healer.png");
}

.body_special_summer2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Mage.png");
}

.body_special_summer2015Rogue {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Rogue.png");
}

.body_special_summer2015Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summer2015Warrior.png");
}

.body_special_summerHealer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summerHealer.png");
}

.body_special_summerMage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_summerMage.png");
}

.broad_armor_special_summer2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Healer.png");
}

.broad_armor_special_summer2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Mage.png");
}

.broad_armor_special_summer2015Rogue {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Rogue.png");
}

.broad_armor_special_summer2015Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2015Warrior.png");
}

.broad_armor_special_summer2016Healer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Healer.png");
}

.broad_armor_special_summer2016Mage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Mage.png");
}

.broad_armor_special_summer2016Rogue {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Rogue.png");
}

.broad_armor_special_summer2016Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2016Warrior.png");
}

.broad_armor_special_summer2017Healer {
  width: 114px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Healer.png");
}

.broad_armor_special_summer2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Mage.png");
}

.broad_armor_special_summer2017Rogue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Rogue.png");
}

.broad_armor_special_summer2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2017Warrior.png");
}

.broad_armor_special_summer2018Healer {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Healer.png");
}

.broad_armor_special_summer2018Mage {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Mage.png");
}

.broad_armor_special_summer2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Rogue.png");
}

.broad_armor_special_summer2018Warrior {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2018Warrior.png");
}

.broad_armor_special_summer2019Healer {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Healer.png");
}

.broad_armor_special_summer2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Mage.png");
}

.broad_armor_special_summer2019Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Rogue.png");
}

.broad_armor_special_summer2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2019Warrior.png");
}

.broad_armor_special_summer2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Healer.png");
}

.broad_armor_special_summer2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Mage.png");
}

.broad_armor_special_summer2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Rogue.png");
}

.broad_armor_special_summer2020Warrior {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2020Warrior.png");
}

.broad_armor_special_summer2021Healer {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Healer.png");
}

.broad_armor_special_summer2021Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Mage.png");
}

.broad_armor_special_summer2021Rogue {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Rogue.png");
}

.broad_armor_special_summer2021Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2021Warrior.png");
}

.broad_armor_special_summer2022Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Healer.png");
}

.broad_armor_special_summer2022Mage {
  width: 124px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Mage.png");
}

.broad_armor_special_summer2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Rogue.png");
}

.broad_armor_special_summer2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summer2022Warrior.png");
}

.broad_armor_special_summerHealer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerHealer.png");
}

.broad_armor_special_summerMage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerMage.png");
}

.broad_armor_special_summerRogue {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerRogue.png");
}

.broad_armor_special_summerWarrior {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_summerWarrior.png");
}

.eyewear_special_summerRogue {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_summerRogue.png");
}

.eyewear_special_summerWarrior {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_summerWarrior.png");
}

.head_special_summer2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Healer.png");
}

.head_special_summer2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Mage.png");
}

.head_special_summer2015Rogue {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Rogue.png");
}

.head_special_summer2015Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2015Warrior.png");
}

.head_special_summer2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Healer.png");
}

.head_special_summer2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Mage.png");
}

.head_special_summer2016Rogue {
  width: 108px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Rogue.png");
}

.head_special_summer2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2016Warrior.png");
}

.head_special_summer2017Healer {
  width: 114px;
  height: 102px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Healer.png");
}

.head_special_summer2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Mage.png");
}

.head_special_summer2017Rogue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Rogue.png");
}

.head_special_summer2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2017Warrior.png");
}

.head_special_summer2018Healer {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Healer.png");
}

.head_special_summer2018Mage {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Mage.png");
}

.head_special_summer2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Rogue.png");
}

.head_special_summer2018Warrior {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2018Warrior.png");
}

.head_special_summer2019Healer {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Healer.png");
}

.head_special_summer2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Mage.png");
}

.head_special_summer2019Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Rogue.png");
}

.head_special_summer2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2019Warrior.png");
}

.head_special_summer2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Healer.png");
}

.head_special_summer2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Mage.png");
}

.head_special_summer2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Rogue.png");
}

.head_special_summer2020Warrior {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2020Warrior.png");
}

.head_special_summer2021Healer {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Healer.png");
}

.head_special_summer2021Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Mage.png");
}

.head_special_summer2021Rogue {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Rogue.png");
}

.head_special_summer2021Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2021Warrior.png");
}

.head_special_summer2022Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Healer.png");
}

.head_special_summer2022Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Mage.png");
}

.head_special_summer2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Rogue.png");
}

.head_special_summer2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summer2022Warrior.png");
}

.head_special_summerHealer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerHealer.png");
}

.head_special_summerMage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerMage.png");
}

.head_special_summerRogue {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerRogue.png");
}

.head_special_summerWarrior {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_summerWarrior.png");
}

.shield_special_summer2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2015Healer.png");
}

.shield_special_summer2015Rogue {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2015Rogue.png");
}

.shield_special_summer2015Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2015Warrior.png");
}

.shield_special_summer2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2016Healer.png");
}

.shield_special_summer2016Rogue {
  width: 108px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2016Rogue.png");
}

.shield_special_summer2016Warrior {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2016Warrior.png");
}

.shield_special_summer2017Healer {
  width: 114px;
  height: 102px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2017Healer.png");
}

.shield_special_summer2017Rogue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2017Rogue.png");
}

.shield_special_summer2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2017Warrior.png");
}

.shield_special_summer2018Healer {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2018Healer.png");
}

.shield_special_summer2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2018Rogue.png");
}

.shield_special_summer2018Warrior {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2018Warrior.png");
}

.shield_special_summer2019Healer {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Healer.png");
}

.shield_special_summer2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Mage.png");
}

.shield_special_summer2019Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Rogue.png");
}

.shield_special_summer2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2019Warrior.png");
}

.shield_special_summer2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2020Healer.png");
}

.shield_special_summer2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2020Rogue.png");
}

.shield_special_summer2020Warrior {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2020Warrior.png");
}

.shield_special_summer2021Healer {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2021Healer.png");
}

.shield_special_summer2021Rogue {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2021Rogue.png");
}

.shield_special_summer2021Warrior {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2021Warrior.png");
}

.shield_special_summer2022Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2022Healer.png");
}

.shield_special_summer2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2022Rogue.png");
}

.shield_special_summer2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summer2022Warrior.png");
}

.shield_special_summerHealer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summerHealer.png");
}

.shield_special_summerRogue {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summerRogue.png");
}

.shield_special_summerWarrior {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_summerWarrior.png");
}

.shop_armor_special_summer2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Healer.png");
}

.shop_armor_special_summer2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Mage.png");
}

.shop_armor_special_summer2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Rogue.png");
}

.shop_armor_special_summer2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2015Warrior.png");
}

.shop_armor_special_summer2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Healer.png");
}

.shop_armor_special_summer2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Mage.png");
}

.shop_armor_special_summer2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Rogue.png");
}

.shop_armor_special_summer2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2016Warrior.png");
}

.shop_armor_special_summer2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Healer.png");
}

.shop_armor_special_summer2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Mage.png");
}

.shop_armor_special_summer2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Rogue.png");
}

.shop_armor_special_summer2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2017Warrior.png");
}

.shop_armor_special_summer2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Healer.png");
}

.shop_armor_special_summer2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Mage.png");
}

.shop_armor_special_summer2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Rogue.png");
}

.shop_armor_special_summer2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2018Warrior.png");
}

.shop_armor_special_summer2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Healer.png");
}

.shop_armor_special_summer2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Mage.png");
}

.shop_armor_special_summer2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Rogue.png");
}

.shop_armor_special_summer2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2019Warrior.png");
}

.shop_armor_special_summer2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Healer.png");
}

.shop_armor_special_summer2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Mage.png");
}

.shop_armor_special_summer2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Rogue.png");
}

.shop_armor_special_summer2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2020Warrior.png");
}

.shop_armor_special_summer2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Healer.png");
}

.shop_armor_special_summer2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Mage.png");
}

.shop_armor_special_summer2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Rogue.png");
}

.shop_armor_special_summer2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2021Warrior.png");
}

.shop_armor_special_summer2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Healer.png");
}

.shop_armor_special_summer2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Mage.png");
}

.shop_armor_special_summer2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Rogue.png");
}

.shop_armor_special_summer2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summer2022Warrior.png");
}

.shop_armor_special_summerHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerHealer.png");
}

.shop_armor_special_summerMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerMage.png");
}

.shop_armor_special_summerRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerRogue.png");
}

.shop_armor_special_summerWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_summerWarrior.png");
}

.shop_body_special_summer2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Healer.png");
}

.shop_body_special_summer2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Mage.png");
}

.shop_body_special_summer2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Rogue.png");
}

.shop_body_special_summer2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summer2015Warrior.png");
}

.shop_body_special_summerHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summerHealer.png");
}

.shop_body_special_summerMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_summerMage.png");
}

.shop_eyewear_special_summerRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_summerRogue.png");
}

.shop_eyewear_special_summerWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_summerWarrior.png");
}

.shop_head_special_summer2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Healer.png");
}

.shop_head_special_summer2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Mage.png");
}

.shop_head_special_summer2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Rogue.png");
}

.shop_head_special_summer2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2015Warrior.png");
}

.shop_head_special_summer2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Healer.png");
}

.shop_head_special_summer2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Mage.png");
}

.shop_head_special_summer2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Rogue.png");
}

.shop_head_special_summer2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2016Warrior.png");
}

.shop_head_special_summer2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Healer.png");
}

.shop_head_special_summer2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Mage.png");
}

.shop_head_special_summer2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Rogue.png");
}

.shop_head_special_summer2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2017Warrior.png");
}

.shop_head_special_summer2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Healer.png");
}

.shop_head_special_summer2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Mage.png");
}

.shop_head_special_summer2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Rogue.png");
}

.shop_head_special_summer2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2018Warrior.png");
}

.shop_head_special_summer2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Healer.png");
}

.shop_head_special_summer2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Mage.png");
}

.shop_head_special_summer2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Rogue.png");
}

.shop_head_special_summer2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2019Warrior.png");
}

.shop_head_special_summer2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Healer.png");
}

.shop_head_special_summer2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Mage.png");
}

.shop_head_special_summer2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Rogue.png");
}

.shop_head_special_summer2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2020Warrior.png");
}

.shop_head_special_summer2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Healer.png");
}

.shop_head_special_summer2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Mage.png");
}

.shop_head_special_summer2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Rogue.png");
}

.shop_head_special_summer2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2021Warrior.png");
}

.shop_head_special_summer2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Healer.png");
}

.shop_head_special_summer2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Mage.png");
}

.shop_head_special_summer2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Rogue.png");
}

.shop_head_special_summer2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summer2022Warrior.png");
}

.shop_head_special_summerHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerHealer.png");
}

.shop_head_special_summerMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerMage.png");
}

.shop_head_special_summerRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerRogue.png");
}

.shop_head_special_summerWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_summerWarrior.png");
}

.shop_shield_special_summer2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2015Healer.png");
}

.shop_shield_special_summer2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2015Rogue.png");
}

.shop_shield_special_summer2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2015Warrior.png");
}

.shop_shield_special_summer2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2016Healer.png");
}

.shop_shield_special_summer2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2016Rogue.png");
}

.shop_shield_special_summer2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2016Warrior.png");
}

.shop_shield_special_summer2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2017Healer.png");
}

.shop_shield_special_summer2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2017Rogue.png");
}

.shop_shield_special_summer2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2017Warrior.png");
}

.shop_shield_special_summer2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2018Healer.png");
}

.shop_shield_special_summer2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2018Rogue.png");
}

.shop_shield_special_summer2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2018Warrior.png");
}

.shop_shield_special_summer2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Healer.png");
}

.shop_shield_special_summer2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Mage.png");
}

.shop_shield_special_summer2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Rogue.png");
}

.shop_shield_special_summer2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2019Warrior.png");
}

.shop_shield_special_summer2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2020Healer.png");
}

.shop_shield_special_summer2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2020Rogue.png");
}

.shop_shield_special_summer2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2020Warrior.png");
}

.shop_shield_special_summer2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2021Healer.png");
}

.shop_shield_special_summer2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2021Rogue.png");
}

.shop_shield_special_summer2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2021Warrior.png");
}

.shop_shield_special_summer2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2022Healer.png");
}

.shop_shield_special_summer2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2022Rogue.png");
}

.shop_shield_special_summer2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summer2022Warrior.png");
}

.shop_shield_special_summerHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summerHealer.png");
}

.shop_shield_special_summerRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summerRogue.png");
}

.shop_shield_special_summerWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_summerWarrior.png");
}

.shop_weapon_special_summer2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Healer.png");
}

.shop_weapon_special_summer2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Mage.png");
}

.shop_weapon_special_summer2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Rogue.png");
}

.shop_weapon_special_summer2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2015Warrior.png");
}

.shop_weapon_special_summer2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Healer.png");
}

.shop_weapon_special_summer2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Mage.png");
}

.shop_weapon_special_summer2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Rogue.png");
}

.shop_weapon_special_summer2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2016Warrior.png");
}

.shop_weapon_special_summer2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Healer.png");
}

.shop_weapon_special_summer2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Mage.png");
}

.shop_weapon_special_summer2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Rogue.png");
}

.shop_weapon_special_summer2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2017Warrior.png");
}

.shop_weapon_special_summer2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Healer.png");
}

.shop_weapon_special_summer2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Mage.png");
}

.shop_weapon_special_summer2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Rogue.png");
}

.shop_weapon_special_summer2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2018Warrior.png");
}

.shop_weapon_special_summer2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Healer.png");
}

.shop_weapon_special_summer2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Mage.png");
}

.shop_weapon_special_summer2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Rogue.png");
}

.shop_weapon_special_summer2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2019Warrior.png");
}

.shop_weapon_special_summer2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Healer.png");
}

.shop_weapon_special_summer2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Mage.png");
}

.shop_weapon_special_summer2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Rogue.png");
}

.shop_weapon_special_summer2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2020Warrior.png");
}

.shop_weapon_special_summer2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Healer.png");
}

.shop_weapon_special_summer2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Mage.png");
}

.shop_weapon_special_summer2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Rogue.png");
}

.shop_weapon_special_summer2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2021Warrior.png");
}

.shop_weapon_special_summer2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Healer.png");
}

.shop_weapon_special_summer2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Mage.png");
}

.shop_weapon_special_summer2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Rogue.png");
}

.shop_weapon_special_summer2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summer2022Warrior.png");
}

.shop_weapon_special_summerHealer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerHealer.png");
}

.shop_weapon_special_summerMage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerMage.png");
}

.shop_weapon_special_summerRogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerRogue.png");
}

.shop_weapon_special_summerWarrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_summerWarrior.png");
}

.slim_armor_special_summer2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Healer.png");
}

.slim_armor_special_summer2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Mage.png");
}

.slim_armor_special_summer2015Rogue {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Rogue.png");
}

.slim_armor_special_summer2015Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2015Warrior.png");
}

.slim_armor_special_summer2016Healer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Healer.png");
}

.slim_armor_special_summer2016Mage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Mage.png");
}

.slim_armor_special_summer2016Rogue {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Rogue.png");
}

.slim_armor_special_summer2016Warrior {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2016Warrior.png");
}

.slim_armor_special_summer2017Healer {
  width: 114px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Healer.png");
}

.slim_armor_special_summer2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Mage.png");
}

.slim_armor_special_summer2017Rogue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Rogue.png");
}

.slim_armor_special_summer2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2017Warrior.png");
}

.slim_armor_special_summer2018Healer {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Healer.png");
}

.slim_armor_special_summer2018Mage {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Mage.png");
}

.slim_armor_special_summer2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Rogue.png");
}

.slim_armor_special_summer2018Warrior {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2018Warrior.png");
}

.slim_armor_special_summer2019Healer {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Healer.png");
}

.slim_armor_special_summer2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Mage.png");
}

.slim_armor_special_summer2019Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Rogue.png");
}

.slim_armor_special_summer2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2019Warrior.png");
}

.slim_armor_special_summer2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Healer.png");
}

.slim_armor_special_summer2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Mage.png");
}

.slim_armor_special_summer2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Rogue.png");
}

.slim_armor_special_summer2020Warrior {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2020Warrior.png");
}

.slim_armor_special_summer2021Healer {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Healer.png");
}

.slim_armor_special_summer2021Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Mage.png");
}

.slim_armor_special_summer2021Rogue {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Rogue.png");
}

.slim_armor_special_summer2021Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2021Warrior.png");
}

.slim_armor_special_summer2022Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Healer.png");
}

.slim_armor_special_summer2022Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Mage.png");
}

.slim_armor_special_summer2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Rogue.png");
}

.slim_armor_special_summer2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summer2022Warrior.png");
}

.slim_armor_special_summerHealer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerHealer.png");
}

.slim_armor_special_summerMage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerMage.png");
}

.slim_armor_special_summerRogue {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerRogue.png");
}

.slim_armor_special_summerWarrior {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_summerWarrior.png");
}

.weapon_special_summer2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Healer.png");
}

.weapon_special_summer2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Mage.png");
}

.weapon_special_summer2015Rogue {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Rogue.png");
}

.weapon_special_summer2015Warrior {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2015Warrior.png");
}

.weapon_special_summer2016Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Healer.png");
}

.weapon_special_summer2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Mage.png");
}

.weapon_special_summer2016Rogue {
  width: 108px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Rogue.png");
}

.weapon_special_summer2016Warrior {
  width: 102px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2016Warrior.png");
}

.weapon_special_summer2017Healer {
  width: 114px;
  height: 102px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Healer.png");
}

.weapon_special_summer2017Mage {
  width: 105px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Mage.png");
}

.weapon_special_summer2017Rogue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Rogue.png");
}

.weapon_special_summer2017Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2017Warrior.png");
}

.weapon_special_summer2018Healer {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Healer.png");
}

.weapon_special_summer2018Mage {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Mage.png");
}

.weapon_special_summer2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Rogue.png");
}

.weapon_special_summer2018Warrior {
  width: 114px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2018Warrior.png");
}

.weapon_special_summer2019Healer {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Healer.png");
}

.weapon_special_summer2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Mage.png");
}

.weapon_special_summer2019Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Rogue.png");
}

.weapon_special_summer2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2019Warrior.png");
}

.weapon_special_summer2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Healer.png");
}

.weapon_special_summer2020Mage {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Mage.png");
}

.weapon_special_summer2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Rogue.png");
}

.weapon_special_summer2020Warrior {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2020Warrior.png");
}

.weapon_special_summer2021Healer {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Healer.png");
}

.weapon_special_summer2021Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Mage.png");
}

.weapon_special_summer2021Rogue {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Rogue.png");
}

.weapon_special_summer2021Warrior {
  width: 141px;
  height: 147px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2021Warrior.png");
}

.weapon_special_summer2022Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Healer.png");
}

.weapon_special_summer2022Mage {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Mage.png");
}

.weapon_special_summer2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Rogue.png");
}

.weapon_special_summer2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summer2022Warrior.png");
}

.weapon_special_summerHealer {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerHealer.png");
}

.weapon_special_summerMage {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerMage.png");
}

.weapon_special_summerRogue {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerRogue.png");
}

.weapon_special_summerWarrior {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_summerWarrior.png");
}

.back_special_takeThis {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_takeThis.png");
}

.body_special_takeThis {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_takeThis.png");
}

.broad_armor_special_takeThis {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_takeThis.png");
}

.head_special_takeThis {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_takeThis.png");
}

.shield_special_takeThis {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_takeThis.png");
}

.shop_armor_special_takeThis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_takeThis.png");
}

.shop_back_special_takeThis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_takeThis.png");
}

.shop_body_special_takeThis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_takeThis.png");
}

.shop_head_special_takeThis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_takeThis.png");
}

.shop_shield_special_takeThis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_takeThis.png");
}

.shop_weapon_special_takeThis {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_takeThis.png");
}

.slim_armor_special_takeThis {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_takeThis.png");
}

.weapon_special_takeThis {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_takeThis.png");
}

.broad_armor_special_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_candycane.png");
}

.broad_armor_special_ski {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_ski.png");
}

.broad_armor_special_snowflake {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_snowflake.png");
}

.broad_armor_special_winter2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Healer.png");
}

.broad_armor_special_winter2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Mage.png");
}

.broad_armor_special_winter2015Rogue {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Rogue.png");
}

.broad_armor_special_winter2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2015Warrior.png");
}

.broad_armor_special_winter2016Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Healer.png");
}

.broad_armor_special_winter2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Mage.png");
}

.broad_armor_special_winter2016Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Rogue.png");
}

.broad_armor_special_winter2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2016Warrior.png");
}

.broad_armor_special_winter2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Healer.png");
}

.broad_armor_special_winter2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Mage.png");
}

.broad_armor_special_winter2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Rogue.png");
}

.broad_armor_special_winter2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2017Warrior.png");
}

.broad_armor_special_winter2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Healer.png");
}

.broad_armor_special_winter2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Mage.png");
}

.broad_armor_special_winter2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Rogue.png");
}

.broad_armor_special_winter2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2018Warrior.png");
}

.broad_armor_special_winter2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Healer.png");
}

.broad_armor_special_winter2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Mage.png");
}

.broad_armor_special_winter2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Rogue.png");
}

.broad_armor_special_winter2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2019Warrior.png");
}

.broad_armor_special_winter2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Healer.png");
}

.broad_armor_special_winter2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Mage.png");
}

.broad_armor_special_winter2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Rogue.png");
}

.broad_armor_special_winter2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2020Warrior.png");
}

.broad_armor_special_winter2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Healer.png");
}

.broad_armor_special_winter2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Mage.png");
}

.broad_armor_special_winter2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Rogue.png");
}

.broad_armor_special_winter2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2021Warrior.png");
}

.broad_armor_special_winter2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Healer.png");
}

.broad_armor_special_winter2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Mage.png");
}

.broad_armor_special_winter2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Rogue.png");
}

.broad_armor_special_winter2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2022Warrior.png");
}

.broad_armor_special_winter2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Healer.png");
}

.broad_armor_special_winter2023Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Mage.png");
}

.broad_armor_special_winter2023Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Rogue.png");
}

.broad_armor_special_winter2023Warrior {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_winter2023Warrior.png");
}

.broad_armor_special_yeti {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/broad_armor_special_yeti.png");
}

.head_special_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_candycane.png");
}

.head_special_nye {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye.png");
}

.head_special_nye2014 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2014.png");
}

.head_special_nye2015 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2015.png");
}

.head_special_nye2016 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2016.png");
}

.head_special_nye2017 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2017.png");
}

.head_special_nye2018 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2018.png");
}

.head_special_nye2019 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2019.png");
}

.head_special_nye2020 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2020.png");
}

.head_special_nye2021 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2021.png");
}

.head_special_nye2022 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_nye2022.png");
}

.head_special_ski {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_ski.png");
}

.head_special_snowflake {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_snowflake.png");
}

.head_special_winter2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Healer.png");
}

.head_special_winter2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Mage.png");
}

.head_special_winter2015Rogue {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Rogue.png");
}

.head_special_winter2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2015Warrior.png");
}

.head_special_winter2016Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Healer.png");
}

.head_special_winter2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Mage.png");
}

.head_special_winter2016Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Rogue.png");
}

.head_special_winter2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2016Warrior.png");
}

.head_special_winter2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Healer.png");
}

.head_special_winter2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Mage.png");
}

.head_special_winter2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Rogue.png");
}

.head_special_winter2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2017Warrior.png");
}

.head_special_winter2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Healer.png");
}

.head_special_winter2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Mage.png");
}

.head_special_winter2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Rogue.png");
}

.head_special_winter2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2018Warrior.png");
}

.head_special_winter2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Healer.png");
}

.head_special_winter2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Mage.png");
}

.head_special_winter2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Rogue.png");
}

.head_special_winter2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2019Warrior.png");
}

.head_special_winter2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Healer.png");
}

.head_special_winter2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Mage.png");
}

.head_special_winter2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Rogue.png");
}

.head_special_winter2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2020Warrior.png");
}

.head_special_winter2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Healer.png");
}

.head_special_winter2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Mage.png");
}

.head_special_winter2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Rogue.png");
}

.head_special_winter2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2021Warrior.png");
}

.head_special_winter2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Healer.png");
}

.head_special_winter2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Mage.png");
}

.head_special_winter2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Rogue.png");
}

.head_special_winter2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2022Warrior.png");
}

.head_special_winter2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Healer.png");
}

.head_special_winter2023Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Mage.png");
}

.head_special_winter2023Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Rogue.png");
}

.head_special_winter2023Warrior {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_winter2023Warrior.png");
}

.head_special_yeti {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_yeti.png");
}

.shield_special_ski {
  width: 104px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_ski.png");
}

.shield_special_snowflake {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_snowflake.png");
}

.shield_special_winter2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2015Healer.png");
}

.shield_special_winter2015Rogue {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2015Rogue.png");
}

.shield_special_winter2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2015Warrior.png");
}

.shield_special_winter2016Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2016Healer.png");
}

.shield_special_winter2016Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2016Rogue.png");
}

.shield_special_winter2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2016Warrior.png");
}

.shield_special_winter2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2017Healer.png");
}

.shield_special_winter2017Rogue {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2017Rogue.png");
}

.shield_special_winter2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2017Warrior.png");
}

.shield_special_winter2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2018Healer.png");
}

.shield_special_winter2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2018Rogue.png");
}

.shield_special_winter2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2018Warrior.png");
}

.shield_special_winter2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2019Healer.png");
}

.shield_special_winter2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2019Rogue.png");
}

.shield_special_winter2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2019Warrior.png");
}

.shield_special_winter2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2020Healer.png");
}

.shield_special_winter2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2020Rogue.png");
}

.shield_special_winter2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2020Warrior.png");
}

.shield_special_winter2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2021Healer.png");
}

.shield_special_winter2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2021Rogue.png");
}

.shield_special_winter2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2021Warrior.png");
}

.shield_special_winter2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2022Healer.png");
}

.shield_special_winter2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2022Rogue.png");
}

.shield_special_winter2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2022Warrior.png");
}

.shield_special_winter2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2023Healer.png");
}

.shield_special_winter2023Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2023Rogue.png");
}

.shield_special_winter2023Warrior {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_winter2023Warrior.png");
}

.shield_special_yeti {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_yeti.png");
}

.shop_armor_special_candycane {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_candycane.png");
}

.shop_armor_special_ski {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_ski.png");
}

.shop_armor_special_snowflake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_snowflake.png");
}

.shop_armor_special_winter2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Healer.png");
}

.shop_armor_special_winter2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Mage.png");
}

.shop_armor_special_winter2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Rogue.png");
}

.shop_armor_special_winter2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2015Warrior.png");
}

.shop_armor_special_winter2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Healer.png");
}

.shop_armor_special_winter2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Mage.png");
}

.shop_armor_special_winter2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Rogue.png");
}

.shop_armor_special_winter2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2016Warrior.png");
}

.shop_armor_special_winter2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Healer.png");
}

.shop_armor_special_winter2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Mage.png");
}

.shop_armor_special_winter2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Rogue.png");
}

.shop_armor_special_winter2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2017Warrior.png");
}

.shop_armor_special_winter2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Healer.png");
}

.shop_armor_special_winter2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Mage.png");
}

.shop_armor_special_winter2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Rogue.png");
}

.shop_armor_special_winter2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2018Warrior.png");
}

.shop_armor_special_winter2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Healer.png");
}

.shop_armor_special_winter2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Mage.png");
}

.shop_armor_special_winter2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Rogue.png");
}

.shop_armor_special_winter2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2019Warrior.png");
}

.shop_armor_special_winter2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Healer.png");
}

.shop_armor_special_winter2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Mage.png");
}

.shop_armor_special_winter2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Rogue.png");
}

.shop_armor_special_winter2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2020Warrior.png");
}

.shop_armor_special_winter2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Healer.png");
}

.shop_armor_special_winter2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Mage.png");
}

.shop_armor_special_winter2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Rogue.png");
}

.shop_armor_special_winter2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2021Warrior.png");
}

.shop_armor_special_winter2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Healer.png");
}

.shop_armor_special_winter2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Mage.png");
}

.shop_armor_special_winter2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Rogue.png");
}

.shop_armor_special_winter2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2022Warrior.png");
}

.shop_armor_special_winter2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Healer.png");
}

.shop_armor_special_winter2023Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Mage.png");
}

.shop_armor_special_winter2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Rogue.png");
}

.shop_armor_special_winter2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_winter2023Warrior.png");
}

.shop_armor_special_yeti {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armor_special_yeti.png");
}

.shop_head_special_candycane {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_candycane.png");
}

.shop_head_special_nye {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye.png");
}

.shop_head_special_nye2014 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2014.png");
}

.shop_head_special_nye2015 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2015.png");
}

.shop_head_special_nye2016 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2016.png");
}

.shop_head_special_nye2017 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2017.png");
}

.shop_head_special_nye2018 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2018.png");
}

.shop_head_special_nye2019 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2019.png");
}

.shop_head_special_nye2020 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2020.png");
}

.shop_head_special_nye2021 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2021.png");
}

.shop_head_special_nye2022 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_nye2022.png");
}

.shop_head_special_ski {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_ski.png");
}

.shop_head_special_snowflake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_snowflake.png");
}

.shop_head_special_winter2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Healer.png");
}

.shop_head_special_winter2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Mage.png");
}

.shop_head_special_winter2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Rogue.png");
}

.shop_head_special_winter2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2015Warrior.png");
}

.shop_head_special_winter2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Healer.png");
}

.shop_head_special_winter2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Mage.png");
}

.shop_head_special_winter2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Rogue.png");
}

.shop_head_special_winter2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2016Warrior.png");
}

.shop_head_special_winter2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Healer.png");
}

.shop_head_special_winter2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Mage.png");
}

.shop_head_special_winter2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Rogue.png");
}

.shop_head_special_winter2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2017Warrior.png");
}

.shop_head_special_winter2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Healer.png");
}

.shop_head_special_winter2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Mage.png");
}

.shop_head_special_winter2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Rogue.png");
}

.shop_head_special_winter2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2018Warrior.png");
}

.shop_head_special_winter2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Healer.png");
}

.shop_head_special_winter2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Mage.png");
}

.shop_head_special_winter2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Rogue.png");
}

.shop_head_special_winter2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2019Warrior.png");
}

.shop_head_special_winter2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Healer.png");
}

.shop_head_special_winter2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Mage.png");
}

.shop_head_special_winter2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Rogue.png");
}

.shop_head_special_winter2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2020Warrior.png");
}

.shop_head_special_winter2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Healer.png");
}

.shop_head_special_winter2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Mage.png");
}

.shop_head_special_winter2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Rogue.png");
}

.shop_head_special_winter2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2021Warrior.png");
}

.shop_head_special_winter2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Healer.png");
}

.shop_head_special_winter2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Mage.png");
}

.shop_head_special_winter2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Rogue.png");
}

.shop_head_special_winter2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2022Warrior.png");
}

.shop_head_special_winter2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Healer.png");
}

.shop_head_special_winter2023Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Mage.png");
}

.shop_head_special_winter2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Rogue.png");
}

.shop_head_special_winter2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_winter2023Warrior.png");
}

.shop_head_special_yeti {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_yeti.png");
}

.shop_shield_special_ski {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_ski.png");
}

.shop_shield_special_snowflake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_snowflake.png");
}

.shop_shield_special_winter2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2015Healer.png");
}

.shop_shield_special_winter2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2015Rogue.png");
}

.shop_shield_special_winter2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2015Warrior.png");
}

.shop_shield_special_winter2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2016Healer.png");
}

.shop_shield_special_winter2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2016Rogue.png");
}

.shop_shield_special_winter2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2016Warrior.png");
}

.shop_shield_special_winter2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2017Healer.png");
}

.shop_shield_special_winter2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2017Rogue.png");
}

.shop_shield_special_winter2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2017Warrior.png");
}

.shop_shield_special_winter2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2018Healer.png");
}

.shop_shield_special_winter2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2018Rogue.png");
}

.shop_shield_special_winter2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2018Warrior.png");
}

.shop_shield_special_winter2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2019Healer.png");
}

.shop_shield_special_winter2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2019Rogue.png");
}

.shop_shield_special_winter2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2019Warrior.png");
}

.shop_shield_special_winter2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2020Healer.png");
}

.shop_shield_special_winter2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2020Rogue.png");
}

.shop_shield_special_winter2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2020Warrior.png");
}

.shop_shield_special_winter2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2021Healer.png");
}

.shop_shield_special_winter2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2021Rogue.png");
}

.shop_shield_special_winter2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2021Warrior.png");
}

.shop_shield_special_winter2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2022Healer.png");
}

.shop_shield_special_winter2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2022Rogue.png");
}

.shop_shield_special_winter2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2022Warrior.png");
}

.shop_shield_special_winter2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2023Healer.png");
}

.shop_shield_special_winter2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2023Rogue.png");
}

.shop_shield_special_winter2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_winter2023Warrior.png");
}

.shop_shield_special_yeti {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_yeti.png");
}

.shop_weapon_special_candycane {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_candycane.png");
}

.shop_weapon_special_ski {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_ski.png");
}

.shop_weapon_special_snowflake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_snowflake.png");
}

.shop_weapon_special_winter2015Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Healer.png");
}

.shop_weapon_special_winter2015Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Mage.png");
}

.shop_weapon_special_winter2015Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Rogue.png");
}

.shop_weapon_special_winter2015Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2015Warrior.png");
}

.shop_weapon_special_winter2016Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Healer.png");
}

.shop_weapon_special_winter2016Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Mage.png");
}

.shop_weapon_special_winter2016Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Rogue.png");
}

.shop_weapon_special_winter2016Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2016Warrior.png");
}

.shop_weapon_special_winter2017Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Healer.png");
}

.shop_weapon_special_winter2017Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Mage.png");
}

.shop_weapon_special_winter2017Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Rogue.png");
}

.shop_weapon_special_winter2017Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2017Warrior.png");
}

.shop_weapon_special_winter2018Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Healer.png");
}

.shop_weapon_special_winter2018Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Mage.png");
}

.shop_weapon_special_winter2018Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Rogue.png");
}

.shop_weapon_special_winter2018Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2018Warrior.png");
}

.shop_weapon_special_winter2019Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Healer.png");
}

.shop_weapon_special_winter2019Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Mage.png");
}

.shop_weapon_special_winter2019Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Rogue.png");
}

.shop_weapon_special_winter2019Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2019Warrior.png");
}

.shop_weapon_special_winter2020Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Healer.png");
}

.shop_weapon_special_winter2020Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Mage.png");
}

.shop_weapon_special_winter2020Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Rogue.png");
}

.shop_weapon_special_winter2020Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2020Warrior.png");
}

.shop_weapon_special_winter2021Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Healer.png");
}

.shop_weapon_special_winter2021Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Mage.png");
}

.shop_weapon_special_winter2021Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Rogue.png");
}

.shop_weapon_special_winter2021Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2021Warrior.png");
}

.shop_weapon_special_winter2022Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Healer.png");
}

.shop_weapon_special_winter2022Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Mage.png");
}

.shop_weapon_special_winter2022Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Rogue.png");
}

.shop_weapon_special_winter2022Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2022Warrior.png");
}

.shop_weapon_special_winter2023Healer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Healer.png");
}

.shop_weapon_special_winter2023Mage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Mage.png");
}

.shop_weapon_special_winter2023Rogue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Rogue.png");
}

.shop_weapon_special_winter2023Warrior {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_winter2023Warrior.png");
}

.shop_weapon_special_yeti {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_yeti.png");
}

.slim_armor_special_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_candycane.png");
}

.slim_armor_special_ski {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_ski.png");
}

.slim_armor_special_snowflake {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_snowflake.png");
}

.slim_armor_special_winter2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Healer.png");
}

.slim_armor_special_winter2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Mage.png");
}

.slim_armor_special_winter2015Rogue {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Rogue.png");
}

.slim_armor_special_winter2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2015Warrior.png");
}

.slim_armor_special_winter2016Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Healer.png");
}

.slim_armor_special_winter2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Mage.png");
}

.slim_armor_special_winter2016Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Rogue.png");
}

.slim_armor_special_winter2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2016Warrior.png");
}

.slim_armor_special_winter2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Healer.png");
}

.slim_armor_special_winter2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Mage.png");
}

.slim_armor_special_winter2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Rogue.png");
}

.slim_armor_special_winter2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2017Warrior.png");
}

.slim_armor_special_winter2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Healer.png");
}

.slim_armor_special_winter2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Mage.png");
}

.slim_armor_special_winter2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Rogue.png");
}

.slim_armor_special_winter2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2018Warrior.png");
}

.slim_armor_special_winter2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Healer.png");
}

.slim_armor_special_winter2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Mage.png");
}

.slim_armor_special_winter2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Rogue.png");
}

.slim_armor_special_winter2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2019Warrior.png");
}

.slim_armor_special_winter2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Healer.png");
}

.slim_armor_special_winter2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Mage.png");
}

.slim_armor_special_winter2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Rogue.png");
}

.slim_armor_special_winter2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2020Warrior.png");
}

.slim_armor_special_winter2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Healer.png");
}

.slim_armor_special_winter2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Mage.png");
}

.slim_armor_special_winter2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Rogue.png");
}

.slim_armor_special_winter2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2021Warrior.png");
}

.slim_armor_special_winter2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Healer.png");
}

.slim_armor_special_winter2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Mage.png");
}

.slim_armor_special_winter2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Rogue.png");
}

.slim_armor_special_winter2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2022Warrior.png");
}

.slim_armor_special_winter2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Healer.png");
}

.slim_armor_special_winter2023Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Mage.png");
}

.slim_armor_special_winter2023Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Rogue.png");
}

.slim_armor_special_winter2023Warrior {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_winter2023Warrior.png");
}

.slim_armor_special_yeti {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/slim_armor_special_yeti.png");
}

.weapon_special_candycane {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_candycane.png");
}

.weapon_special_ski {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_ski.png");
}

.weapon_special_snowflake {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_snowflake.png");
}

.weapon_special_winter2015Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Healer.png");
}

.weapon_special_winter2015Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Mage.png");
}

.weapon_special_winter2015Rogue {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Rogue.png");
}

.weapon_special_winter2015Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2015Warrior.png");
}

.weapon_special_winter2016Healer {
  width: 93px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Healer.png");
}

.weapon_special_winter2016Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Mage.png");
}

.weapon_special_winter2016Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Rogue.png");
}

.weapon_special_winter2016Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2016Warrior.png");
}

.weapon_special_winter2017Healer {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Healer.png");
}

.weapon_special_winter2017Mage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Mage.png");
}

.weapon_special_winter2017Rogue {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Rogue.png");
}

.weapon_special_winter2017Warrior {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2017Warrior.png");
}

.weapon_special_winter2018Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Healer.png");
}

.weapon_special_winter2018Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Mage.png");
}

.weapon_special_winter2018Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Rogue.png");
}

.weapon_special_winter2018Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2018Warrior.png");
}

.weapon_special_winter2019Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Healer.png");
}

.weapon_special_winter2019Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Mage.png");
}

.weapon_special_winter2019Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Rogue.png");
}

.weapon_special_winter2019Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2019Warrior.png");
}

.weapon_special_winter2020Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Healer.png");
}

.weapon_special_winter2020Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Mage.png");
}

.weapon_special_winter2020Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Rogue.png");
}

.weapon_special_winter2020Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2020Warrior.png");
}

.weapon_special_winter2021Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Healer.png");
}

.weapon_special_winter2021Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Mage.png");
}

.weapon_special_winter2021Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Rogue.png");
}

.weapon_special_winter2021Warrior {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2021Warrior.png");
}

.weapon_special_winter2022Healer {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Healer.png");
}

.weapon_special_winter2022Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Mage.png");
}

.weapon_special_winter2022Rogue {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Rogue.png");
}

.weapon_special_winter2022Warrior {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2022Warrior.png");
}

.weapon_special_winter2023Healer {
  width: 117px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Healer.png");
}

.weapon_special_winter2023Mage {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Mage.png");
}

.weapon_special_winter2023Rogue {
  width: 116px;
  height: 119px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Rogue.png");
}

.weapon_special_winter2023Warrior {
  width: 114px;
  height: 117px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_winter2023Warrior.png");
}

.weapon_special_yeti {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_yeti.png");
}

.back_special_wondercon_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_wondercon_black.png");
}

.back_special_wondercon_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/back_special_wondercon_red.png");
}

.body_special_wondercon_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_wondercon_black.png");
}

.body_special_wondercon_gold {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_wondercon_gold.png");
}

.body_special_wondercon_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/body_special_wondercon_red.png");
}

.eyewear_special_wondercon_black {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_wondercon_black.png");
}

.eyewear_special_wondercon_red {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_wondercon_red.png");
}

.shop_back_special_wondercon_black {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_wondercon_black.png");
}

.shop_back_special_wondercon_red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_back_special_wondercon_red.png");
}

.shop_body_special_wondercon_black {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_wondercon_black.png");
}

.shop_body_special_wondercon_gold {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_wondercon_gold.png");
}

.shop_body_special_wondercon_red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_body_special_wondercon_red.png");
}

.shop_eyewear_special_wondercon_black {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_wondercon_black.png");
}

.shop_eyewear_special_wondercon_red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_wondercon_red.png");
}

.eyewear_special_aetherMask {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_aetherMask.png");
}

.eyewear_special_blackHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackHalfMoon.png");
}

.customize-option.eyewear_special_blackHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_blackTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackTopFrame.png");
}

.customize-option.eyewear_special_blackTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blackTopFrame.png");
  background-position: -25px -15px;
}

.eyewear_special_blueHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueHalfMoon.png");
}

.customize-option.eyewear_special_blueHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_blueTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueTopFrame.png");
}

.customize-option.eyewear_special_blueTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_blueTopFrame.png");
  background-position: -25px -15px;
}

.eyewear_special_greenHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenHalfMoon.png");
}

.customize-option.eyewear_special_greenHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_greenTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenTopFrame.png");
}

.customize-option.eyewear_special_greenTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_greenTopFrame.png");
  background-position: -25px -15px;
}

.eyewear_special_pinkHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkHalfMoon.png");
}

.customize-option.eyewear_special_pinkHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_pinkTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkTopFrame.png");
}

.customize-option.eyewear_special_pinkTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_pinkTopFrame.png");
  background-position: -25px -15px;
}

.eyewear_special_redHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redHalfMoon.png");
}

.customize-option.eyewear_special_redHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_redTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redTopFrame.png");
}

.customize-option.eyewear_special_redTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_redTopFrame.png");
  background-position: -25px -15px;
}

.eyewear_special_whiteHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteHalfMoon.png");
}

.customize-option.eyewear_special_whiteHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_whiteTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteTopFrame.png");
}

.customize-option.eyewear_special_whiteTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_whiteTopFrame.png");
  background-position: -25px -15px;
}

.eyewear_special_yellowHalfMoon {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowHalfMoon.png");
}

.customize-option.eyewear_special_yellowHalfMoon {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowHalfMoon.png");
  background-position: -25px -15px;
}

.eyewear_special_yellowTopFrame {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowTopFrame.png");
}

.customize-option.eyewear_special_yellowTopFrame {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/eyewear_special_yellowTopFrame.png");
  background-position: -25px -15px;
}

.shop_eyewear_special_aetherMask {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_aetherMask.png");
}

.shop_eyewear_special_blackHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blackHalfMoon.png");
}

.shop_eyewear_special_blackTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blackTopFrame.png");
}

.shop_eyewear_special_blueHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blueHalfMoon.png");
}

.shop_eyewear_special_blueTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_blueTopFrame.png");
}

.shop_eyewear_special_greenHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_greenHalfMoon.png");
}

.shop_eyewear_special_greenTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_greenTopFrame.png");
}

.shop_eyewear_special_pinkHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_pinkHalfMoon.png");
}

.shop_eyewear_special_pinkTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_pinkTopFrame.png");
}

.shop_eyewear_special_redHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_redHalfMoon.png");
}

.shop_eyewear_special_redTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_redTopFrame.png");
}

.shop_eyewear_special_whiteHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_whiteHalfMoon.png");
}

.shop_eyewear_special_whiteTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_whiteTopFrame.png");
}

.shop_eyewear_special_yellowHalfMoon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_yellowHalfMoon.png");
}

.shop_eyewear_special_yellowTopFrame {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_eyewear_special_yellowTopFrame.png");
}

.head_0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_0.png");
}

.customize-option.head_0 {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_0.png");
  background-position: -25px -15px;
}

.head_healer_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_1.png");
}

.head_healer_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_2.png");
}

.head_healer_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_3.png");
}

.head_healer_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_4.png");
}

.head_healer_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_healer_5.png");
}

.head_rogue_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_1.png");
}

.head_rogue_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_2.png");
}

.head_rogue_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_3.png");
}

.head_rogue_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_4.png");
}

.head_rogue_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_rogue_5.png");
}

.head_special_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_2.png");
}

.head_special_bardHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_bardHat.png");
}

.head_special_clandestineCowl {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_clandestineCowl.png");
}

.head_special_dandyHat {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_dandyHat.png");
}

.head_special_fireCoralCirclet {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_fireCoralCirclet.png");
}

.head_special_kabuto {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_kabuto.png");
}

.head_special_lunarWarriorHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_lunarWarriorHelm.png");
}

.head_special_mammothRiderHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_mammothRiderHelm.png");
}

.head_special_namingDay2017 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_namingDay2017.png");
}

.head_special_pageHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_pageHelm.png");
}

.head_special_pyromancersTurban {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_pyromancersTurban.png");
}

.head_special_roguishRainbowMessengerHood {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_roguishRainbowMessengerHood.png");
}

.head_special_snowSovereignCrown {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_snowSovereignCrown.png");
}

.head_special_spikedHelm {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_spikedHelm.png");
}

.head_special_turkeyHelmBase {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_turkeyHelmBase.png");
}

.head_special_turkeyHelmGilded {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_special_turkeyHelmGilded.png");
}

.head_warrior_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_1.png");
}

.head_warrior_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_2.png");
}

.head_warrior_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_3.png");
}

.head_warrior_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_4.png");
}

.head_warrior_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_warrior_5.png");
}

.head_wizard_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_1.png");
}

.head_wizard_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_2.png");
}

.head_wizard_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_3.png");
}

.head_wizard_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_4.png");
}

.head_wizard_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/head_wizard_5.png");
}

.shop_head_healer_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_1.png");
}

.shop_head_healer_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_2.png");
}

.shop_head_healer_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_3.png");
}

.shop_head_healer_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_4.png");
}

.shop_head_healer_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_healer_5.png");
}

.shop_head_rogue_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_1.png");
}

.shop_head_rogue_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_2.png");
}

.shop_head_rogue_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_3.png");
}

.shop_head_rogue_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_4.png");
}

.shop_head_rogue_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_rogue_5.png");
}

.shop_head_special_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_0.png");
}

.shop_head_special_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_1.png");
}

.shop_head_special_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_2.png");
}

.shop_head_special_bardHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_bardHat.png");
}

.shop_head_special_clandestineCowl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_clandestineCowl.png");
}

.shop_head_special_dandyHat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_dandyHat.png");
}

.shop_head_special_fireCoralCirclet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_fireCoralCirclet.png");
}

.shop_head_special_kabuto {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_kabuto.png");
}

.shop_head_special_lunarWarriorHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_lunarWarriorHelm.png");
}

.shop_head_special_mammothRiderHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_mammothRiderHelm.png");
}

.shop_head_special_namingDay2017 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_namingDay2017.png");
}

.shop_head_special_pageHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_pageHelm.png");
}

.shop_head_special_pyromancersTurban {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_pyromancersTurban.png");
}

.shop_head_special_roguishRainbowMessengerHood {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_roguishRainbowMessengerHood.png");
}

.shop_head_special_snowSovereignCrown {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_snowSovereignCrown.png");
}

.shop_head_special_spikedHelm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_spikedHelm.png");
}

.shop_head_special_turkeyHelmBase {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_turkeyHelmBase.png");
}

.shop_head_special_turkeyHelmGilded {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_special_turkeyHelmGilded.png");
}

.shop_head_warrior_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_1.png");
}

.shop_head_warrior_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_2.png");
}

.shop_head_warrior_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_3.png");
}

.shop_head_warrior_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_4.png");
}

.shop_head_warrior_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_warrior_5.png");
}

.shop_head_wizard_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_1.png");
}

.shop_head_wizard_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_2.png");
}

.shop_head_wizard_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_3.png");
}

.shop_head_wizard_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_4.png");
}

.shop_head_wizard_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_head_wizard_5.png");
}

.headAccessory_special_bearEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_bearEars.png");
}

.customize-option.headAccessory_special_bearEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_bearEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_blackHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_blackHeadband.png");
}

.headAccessory_special_blueHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_blueHeadband.png");
}

.headAccessory_special_cactusEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_cactusEars.png");
}

.customize-option.headAccessory_special_cactusEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_cactusEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_foxEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_foxEars.png");
}

.customize-option.headAccessory_special_foxEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_foxEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_greenHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_greenHeadband.png");
}

.headAccessory_special_lionEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_lionEars.png");
}

.customize-option.headAccessory_special_lionEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_lionEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_pandaEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pandaEars.png");
}

.customize-option.headAccessory_special_pandaEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pandaEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_pigEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pigEars.png");
}

.customize-option.headAccessory_special_pigEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pigEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_pinkHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_pinkHeadband.png");
}

.headAccessory_special_redHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_redHeadband.png");
}

.headAccessory_special_tigerEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_tigerEars.png");
}

.customize-option.headAccessory_special_tigerEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_tigerEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_whiteHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_whiteHeadband.png");
}

.headAccessory_special_wolfEars {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_wolfEars.png");
}

.customize-option.headAccessory_special_wolfEars {
  width: 60px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_wolfEars.png");
  background-position: -25px -15px;
}

.headAccessory_special_yellowHeadband {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/headAccessory_special_yellowHeadband.png");
}

.shop_headAccessory_special_bearEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_bearEars.png");
}

.shop_headAccessory_special_blackHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_blackHeadband.png");
}

.shop_headAccessory_special_blueHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_blueHeadband.png");
}

.shop_headAccessory_special_cactusEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_cactusEars.png");
}

.shop_headAccessory_special_foxEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_foxEars.png");
}

.shop_headAccessory_special_greenHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_greenHeadband.png");
}

.shop_headAccessory_special_lionEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_lionEars.png");
}

.shop_headAccessory_special_pandaEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_pandaEars.png");
}

.shop_headAccessory_special_pigEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_pigEars.png");
}

.shop_headAccessory_special_pinkHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_pinkHeadband.png");
}

.shop_headAccessory_special_redHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_redHeadband.png");
}

.shop_headAccessory_special_tigerEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_tigerEars.png");
}

.shop_headAccessory_special_whiteHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_whiteHeadband.png");
}

.shop_headAccessory_special_wolfEars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_wolfEars.png");
}

.shop_headAccessory_special_yellowHeadband {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_headAccessory_special_yellowHeadband.png");
}

.shield_healer_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_1.png");
}

.shield_healer_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_2.png");
}

.shield_healer_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_3.png");
}

.shield_healer_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_4.png");
}

.shield_healer_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_healer_5.png");
}

.shield_rogue_0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_0.png");
}

.shield_rogue_1 {
  width: 103px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_1.png");
}

.shield_rogue_2 {
  width: 103px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_2.png");
}

.shield_rogue_3 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_3.png");
}

.shield_rogue_4 {
  width: 96px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_4.png");
}

.shield_rogue_5 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_5.png");
}

.shield_rogue_6 {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_rogue_6.png");
}

.shield_special_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_1.png");
}

.shield_special_diamondStave {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_diamondStave.png");
}

.shield_special_goldenknight {
  width: 111px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_goldenknight.png");
}

.shield_special_lootBag {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_lootBag.png");
}

.shield_special_mammothRiderHorn {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_mammothRiderHorn.png");
}

.shield_special_moonpearlShield {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_moonpearlShield.png");
}

.shield_special_roguishRainbowMessage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_roguishRainbowMessage.png");
}

.shield_special_wakizashi {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_wakizashi.png");
}

.shield_special_wintryMirror {
  width: 114px;
  height: 87px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_special_wintryMirror.png");
}

.shield_warrior_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_1.png");
}

.shield_warrior_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_2.png");
}

.shield_warrior_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_3.png");
}

.shield_warrior_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_4.png");
}

.shield_warrior_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shield_warrior_5.png");
}

.shop_shield_healer_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_1.png");
}

.shop_shield_healer_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_2.png");
}

.shop_shield_healer_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_3.png");
}

.shop_shield_healer_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_4.png");
}

.shop_shield_healer_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_healer_5.png");
}

.shop_shield_rogue_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_0.png");
}

.shop_shield_rogue_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_1.png");
}

.shop_shield_rogue_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_2.png");
}

.shop_shield_rogue_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_3.png");
}

.shop_shield_rogue_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_4.png");
}

.shop_shield_rogue_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_5.png");
}

.shop_shield_rogue_6 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_rogue_6.png");
}

.shop_shield_special_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_0.png");
}

.shop_shield_special_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_1.png");
}

.shop_shield_special_diamondStave {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_diamondStave.png");
}

.shop_shield_special_goldenknight {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_goldenknight.png");
}

.shop_shield_special_lootBag {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_lootBag.png");
}

.shop_shield_special_mammothRiderHorn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_mammothRiderHorn.png");
}

.shop_shield_special_moonpearlShield {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_moonpearlShield.png");
}

.shop_shield_special_roguishRainbowMessage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_roguishRainbowMessage.png");
}

.shop_shield_special_wakizashi {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_wakizashi.png");
}

.shop_shield_special_wintryMirror {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_special_wintryMirror.png");
}

.shop_shield_warrior_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_1.png");
}

.shop_shield_warrior_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_2.png");
}

.shop_shield_warrior_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_3.png");
}

.shop_shield_warrior_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_4.png");
}

.shop_shield_warrior_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shield_warrior_5.png");
}

.shop_weapon_healer_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_0.png");
}

.shop_weapon_healer_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_1.png");
}

.shop_weapon_healer_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_2.png");
}

.shop_weapon_healer_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_3.png");
}

.shop_weapon_healer_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_4.png");
}

.shop_weapon_healer_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_5.png");
}

.shop_weapon_healer_6 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_healer_6.png");
}

.shop_weapon_rogue_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_0.png");
}

.shop_weapon_rogue_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_1.png");
}

.shop_weapon_rogue_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_2.png");
}

.shop_weapon_rogue_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_3.png");
}

.shop_weapon_rogue_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_4.png");
}

.shop_weapon_rogue_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_5.png");
}

.shop_weapon_rogue_6 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_rogue_6.png");
}

.shop_weapon_special_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_0.png");
}

.shop_weapon_special_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_1.png");
}

.shop_weapon_special_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_2.png");
}

.shop_weapon_special_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_3.png");
}

.shop_weapon_special_aetherCrystals {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_aetherCrystals.png");
}

.shop_weapon_special_bardInstrument {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_bardInstrument.png");
}

.shop_weapon_special_critical {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_critical.png");
}

.shop_weapon_special_fencingFoil {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_fencingFoil.png");
}

.shop_weapon_special_lunarScythe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_lunarScythe.png");
}

.shop_weapon_special_mammothRiderSpear {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_mammothRiderSpear.png");
}

.shop_weapon_special_nomadsScimitar {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_nomadsScimitar.png");
}

.shop_weapon_special_pageBanner {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_pageBanner.png");
}

.shop_weapon_special_roguishRainbowMessage {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_roguishRainbowMessage.png");
}

.shop_weapon_special_skeletonKey {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_skeletonKey.png");
}

.shop_weapon_special_tachi {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_tachi.png");
}

.shop_weapon_special_taskwoodsLantern {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_taskwoodsLantern.png");
}

.shop_weapon_special_tridentOfCrashingTides {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_special_tridentOfCrashingTides.png");
}

.shop_weapon_warrior_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_0.png");
}

.shop_weapon_warrior_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_1.png");
}

.shop_weapon_warrior_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_2.png");
}

.shop_weapon_warrior_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_3.png");
}

.shop_weapon_warrior_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_4.png");
}

.shop_weapon_warrior_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_5.png");
}

.shop_weapon_warrior_6 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_warrior_6.png");
}

.shop_weapon_wizard_0 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_0.png");
}

.shop_weapon_wizard_1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_1.png");
}

.shop_weapon_wizard_2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_2.png");
}

.shop_weapon_wizard_3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_3.png");
}

.shop_weapon_wizard_4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_4.png");
}

.shop_weapon_wizard_5 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_5.png");
}

.shop_weapon_wizard_6 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_weapon_wizard_6.png");
}

.weapon_healer_0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_0.png");
}

.weapon_healer_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_1.png");
}

.weapon_healer_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_2.png");
}

.weapon_healer_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_3.png");
}

.weapon_healer_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_4.png");
}

.weapon_healer_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_5.png");
}

.weapon_healer_6 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_healer_6.png");
}

.weapon_rogue_0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_0.png");
}

.weapon_rogue_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_1.png");
}

.weapon_rogue_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_2.png");
}

.weapon_rogue_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_3.png");
}

.weapon_rogue_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_4.png");
}

.weapon_rogue_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_5.png");
}

.weapon_rogue_6 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_rogue_6.png");
}

.weapon_special_1 {
  width: 102px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_1.png");
}

.weapon_special_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_2.png");
}

.weapon_special_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_3.png");
}

.weapon_special_aetherCrystals {
  width: 114px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_aetherCrystals.png");
}

.weapon_special_bardInstrument {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_bardInstrument.png");
}

.weapon_special_fencingFoil {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_fencingFoil.png");
}

.weapon_special_lunarScythe {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_lunarScythe.png");
}

.weapon_special_mammothRiderSpear {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_mammothRiderSpear.png");
}

.weapon_special_nomadsScimitar {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_nomadsScimitar.png");
}

.weapon_special_pageBanner {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_pageBanner.png");
}

.weapon_special_roguishRainbowMessage {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_roguishRainbowMessage.png");
}

.weapon_special_skeletonKey {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_skeletonKey.png");
}

.weapon_special_tachi {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_tachi.png");
}

.weapon_special_taskwoodsLantern {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_taskwoodsLantern.png");
}

.weapon_special_tridentOfCrashingTides {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_special_tridentOfCrashingTides.png");
}

.weapon_warrior_0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_0.png");
}

.weapon_warrior_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_1.png");
}

.weapon_warrior_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_2.png");
}

.weapon_warrior_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_3.png");
}

.weapon_warrior_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_4.png");
}

.weapon_warrior_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_5.png");
}

.weapon_warrior_6 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_warrior_6.png");
}

.weapon_wizard_0 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_0.png");
}

.weapon_wizard_1 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_1.png");
}

.weapon_wizard_2 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_2.png");
}

.weapon_wizard_3 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_3.png");
}

.weapon_wizard_4 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_4.png");
}

.weapon_wizard_5 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_5.png");
}

.weapon_wizard_6 {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/weapon_wizard_6.png");
}

.Pet_Currency_Gem {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Currency_Gem.png");
}

.Pet_Currency_Gem1x {
  width: 15px;
  height: 13px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Currency_Gem1x.png");
}

.Pet_Currency_Gem2x {
  width: 30px;
  height: 26px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Currency_Gem2x.png");
}

.PixelPaw-Gold {
  width: 51px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/PixelPaw-Gold.png");
}

.PixelPaw {
  width: 51px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/PixelPaw.png");
}

.PixelPaw002 {
  width: 51px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/PixelPaw002.png");
}

.avatar_floral_healer {
  width: 99px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_healer.png");
}

.avatar_floral_rogue {
  width: 99px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_rogue.png");
}

.avatar_floral_warrior {
  width: 99px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_warrior.png");
}

.avatar_floral_wizard {
  width: 99px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_floral_wizard.png");
}

.avatar_snowball_healer {
  width: 120px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_healer.png");
}

.avatar_snowball_rogue {
  width: 120px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_rogue.png");
}

.avatar_snowball_warrior {
  width: 120px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_warrior.png");
}

.avatar_snowball_wizard {
  width: 120px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/avatar_snowball_wizard.png");
}

.empty_bottles {
  width: 64px;
  height: 54px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/empty_bottles.png");
}

.ghost {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/ghost.png");
}

.inventory_present {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present.png");
}

.inventory_present_01 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_01.png");
}

.inventory_present_02 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_02.png");
}

.inventory_present_03 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_03.png");
}

.inventory_present_04 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_04.png");
}

.inventory_present_05 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_05.png");
}

.inventory_present_06 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_06.png");
}

.inventory_present_07 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_07.png");
}

.inventory_present_08 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_08.png");
}

.inventory_present_09 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_09.png");
}

.inventory_present_10 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_10.png");
}

.inventory_present_11 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_11.png");
}

.inventory_present_12 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_present_12.png");
}

.inventory_special_birthday {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_birthday.png");
}

.inventory_special_congrats {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_congrats.png");
}

.inventory_special_fortify {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_fortify.png");
}

.inventory_special_getwell {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_getwell.png");
}

.inventory_special_goodluck {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_goodluck.png");
}

.inventory_special_greeting {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_greeting.png");
}

.inventory_special_nye {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_nye.png");
}

.inventory_special_opaquePotion {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_opaquePotion.png");
}

.inventory_special_seafoam {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_seafoam.png");
}

.inventory_special_shinySeed {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_shinySeed.png");
}

.inventory_special_snowball {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_snowball.png");
}

.inventory_special_spookySparkles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_spookySparkles.png");
}

.inventory_special_thankyou {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_thankyou.png");
}

.inventory_special_trinket {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_trinket.png");
}

.inventory_special_valentine {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_special_valentine.png");
}

.knockout {
  width: 120px;
  height: 47px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/knockout.png");
}

.pet_key {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/pet_key.png");
}

.rebirth_orb {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/rebirth_orb.png");
}

.seafoam_star {
  width: 90px;
  height: 90px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/seafoam_star.png");
}

.shop_armoire {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_armoire.png");
}

.zzz {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/zzz.png");
}

.zzz_light {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/zzz_light.png");
}

.notif_head_special_nye {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_head_special_nye.png");
}

.notif_inventory_present_01 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_01.png");
}

.notif_inventory_present_02 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_02.png");
}

.notif_inventory_present_03 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_03.png");
}

.notif_inventory_present_04 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_04.png");
}

.notif_inventory_present_05 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_05.png");
}

.notif_inventory_present_06 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_06.png");
}

.notif_inventory_present_07 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_07.png");
}

.notif_inventory_present_08 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_08.png");
}

.notif_inventory_present_09 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_09.png");
}

.notif_inventory_present_10 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_10.png");
}

.notif_inventory_present_11 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_11.png");
}

.notif_inventory_present_12 {
  width: 28px;
  height: 28px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_present_12.png");
}

.notif_inventory_special_birthday {
  width: 20px;
  height: 24px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_birthday.png");
}

.notif_inventory_special_congrats {
  width: 20px;
  height: 22px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_congrats.png");
}

.notif_inventory_special_getwell {
  width: 20px;
  height: 22px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_getwell.png");
}

.notif_inventory_special_goodluck {
  width: 20px;
  height: 26px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_goodluck.png");
}

.notif_inventory_special_greeting {
  width: 20px;
  height: 22px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_greeting.png");
}

.notif_inventory_special_nye {
  width: 24px;
  height: 26px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_nye.png");
}

.notif_inventory_special_thankyou {
  width: 20px;
  height: 24px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_thankyou.png");
}

.notif_inventory_special_valentine {
  width: 20px;
  height: 24px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/notif_inventory_special_valentine.png");
}

.npc_bailey {
  width: 60px;
  height: 72px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey.png");
}

.npc_bailey_birthday {
  width: 63px;
  height: 93px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_birthday.png");
}

.npc_bailey_fall {
  width: 60px;
  height: 72px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_fall.png");
}

.npc_bailey_habitoween {
  width: 54px;
  height: 72px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_habitoween.png");
}

.npc_bailey_nye {
  width: 54px;
  height: 93px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_nye.png");
}

.npc_bailey_spring {
  width: 60px;
  height: 72px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_spring.png");
}

.npc_bailey_summer {
  width: 71px;
  height: 101px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_summer.png");
}

.npc_bailey_thanksgiving {
  width: 60px;
  height: 72px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_thanksgiving.png");
}

.npc_bailey_valentines {
  width: 63px;
  height: 66px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_valentines.png");
}

.npc_bailey_winter {
  width: 54px;
  height: 78px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_bailey_winter.png");
}

.npc_matt {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt.png");
}

.npc_matt_birthday {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_birthday.png");
}

.npc_matt_fall {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_fall.png");
}

.npc_matt_habitoween {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_habitoween.png");
}

.npc_matt_nye {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_nye.png");
}

.npc_matt_spring {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_spring.png");
}

.npc_matt_summer {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_summer.png");
}

.npc_matt_thanksgiving {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_thanksgiving.png");
}

.npc_matt_winter {
  width: 195px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/npc_matt_winter.png");
}

.quest_TEMPLATE_FOR_MISSING_IMAGE {
  width: 221px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_TEMPLATE_FOR_MISSING_IMAGE.png");
}

.quest_alligator {
  width: 201px;
  height: 213px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_alligator.png");
}

.quest_amber {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_amber.png");
}

.quest_armadillo {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_armadillo.png");
}

.quest_atom1 {
  width: 250px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom1.png");
}

.quest_atom2 {
  width: 207px;
  height: 138px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom2.png");
}

.quest_atom3 {
  width: 216px;
  height: 180px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom3.png");
}

.quest_axolotl {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_axolotl.png");
}

.quest_badger {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_badger.png");
}

.quest_basilist {
  width: 189px;
  height: 141px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_basilist.png");
}

.quest_beetle {
  width: 204px;
  height: 201px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_beetle.png");
}

.quest_blackPearl {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_blackPearl.png");
}

.quest_bronze {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bronze.png");
}

.quest_bunny {
  width: 210px;
  height: 186px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bunny.png");
}

.quest_butterfly {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_butterfly.png");
}

.quest_cheetah {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_cheetah.png");
}

.quest_cow {
  width: 174px;
  height: 213px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_cow.png");
}

.quest_dilatory {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatory.png");
}

.quest_dilatoryDistress1 {
  width: 210px;
  height: 210px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress1.png");
}

.quest_dilatoryDistress2 {
  width: 150px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress2.png");
}

.quest_dilatoryDistress3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress3.png");
}

.quest_dilatory_derby {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatory_derby.png");
}

.quest_dolphin {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dolphin.png");
}

.quest_dustbunnies {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dustbunnies.png");
}

.quest_egg {
  width: 165px;
  height: 207px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_egg.png");
}

.quest_evilsanta {
  width: 118px;
  height: 131px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta.png");
}

.quest_evilsanta2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta2.png");
}

.quest_falcon {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_falcon.png");
}

.quest_ferret {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ferret.png");
}

.quest_fluorite {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_fluorite.png");
}

.quest_frog {
  width: 221px;
  height: 213px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_frog.png");
}

.quest_ghost_stag {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ghost_stag.png");
}

.quest_goldenknight1 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight1.png");
}

.quest_goldenknight2 {
  width: 250px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight2.png");
}

.quest_goldenknight3 {
  width: 219px;
  height: 231px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight3.png");
}

.quest_gryphon {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_gryphon.png");
}

.quest_guineapig {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_guineapig.png");
}

.quest_harpy {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_harpy.png");
}

.quest_hedgehog {
  width: 219px;
  height: 186px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_hedgehog.png");
}

.quest_hippo {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_hippo.png");
}

.quest_horse {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_horse.png");
}

.quest_kangaroo {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_kangaroo.png");
}

.quest_kraken {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_kraken.png");
}

.quest_lostMasterclasser1 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1.png");
}

.quest_lostMasterclasser2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser2.png");
}

.quest_lostMasterclasser3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser3.png");
}

.quest_mayhemMistiflying1 {
  width: 150px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying1.png");
}

.quest_mayhemMistiflying2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2.png");
}

.quest_mayhemMistiflying3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying3.png");
}

.quest_monkey {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_monkey.png");
}

.quest_moon1 {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon1.png");
}

.quest_moon2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon2.png");
}

.quest_moon3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon3.png");
}

.quest_moonstone1 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone1.png");
}

.quest_moonstone2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone2.png");
}

.quest_moonstone3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone3.png");
}

.quest_nudibranch {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_nudibranch.png");
}

.quest_octopus {
  width: 222px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_octopus.png");
}

.quest_onyx {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx.png");
}

.quest_owl {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_owl.png");
}

.quest_peacock {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_peacock.png");
}

.quest_penguin {
  width: 190px;
  height: 183px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_penguin.png");
}

.quest_pinkMarble {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_pinkMarble.png");
}

.quest_pterodactyl {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_pterodactyl.png");
}

.quest_rat {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_rat.png");
}

.quest_robot {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot.png");
}

.quest_rock {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_rock.png");
}

.quest_rooster {
  width: 213px;
  height: 174px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_rooster.png");
}

.quest_ruby {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby.png");
}

.quest_sabretooth {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_sabretooth.png");
}

.quest_seaserpent {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_seaserpent.png");
}

.quest_sheep {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_sheep.png");
}

.quest_silver {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver.png");
}

.quest_slime {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_slime.png");
}

.quest_sloth {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_sloth.png");
}

.quest_snail {
  width: 219px;
  height: 213px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_snail.png");
}

.quest_snake {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_snake.png");
}

.quest_spider {
  width: 250px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_spider.png");
}

.quest_squirrel {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_squirrel.png");
}

.quest_stoikalmCalamity1 {
  width: 150px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity1.png");
}

.quest_stoikalmCalamity2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity2.png");
}

.quest_stoikalmCalamity3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity3.png");
}

.quest_stone {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone.png");
}

.quest_taskwoodsTerror1 {
  width: 150px;
  height: 150px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror1.png");
}

.quest_taskwoodsTerror2 {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2.png");
}

.quest_taskwoodsTerror3 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror3.png");
}

.quest_treeling {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_treeling.png");
}

.quest_trex {
  width: 204px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_trex.png");
}

.quest_trex_undead {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_trex_undead.png");
}

.quest_triceratops {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_triceratops.png");
}

.quest_turquoise {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise.png");
}

.quest_turtle {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turtle.png");
}

.quest_unicorn {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_unicorn.png");
}

.quest_velociraptor {
  width: 222px;
  height: 225px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_velociraptor.png");
}

.quest_vice1 {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice1.png");
}

.quest_vice2 {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice2.png");
}

.quest_vice3 {
  width: 216px;
  height: 177px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice3.png");
}

.quest_waffle {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_waffle.png");
}

.quest_whale {
  width: 219px;
  height: 219px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_whale.png");
}

.quest_yarn {
  width: 216px;
  height: 216px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_yarn.png");
}

.quest_atom1_soapBars {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_atom1_soapBars.png");
}

.quest_dilatoryDistress1_blueFins {
  width: 51px;
  height: 48px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress1_blueFins.png");
}

.quest_dilatoryDistress1_fireCoral {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_dilatoryDistress1_fireCoral.png");
}

.quest_egg_plainEgg {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_egg_plainEgg.png");
}

.quest_evilsanta2_branches {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta2_branches.png");
}

.quest_evilsanta2_tracks {
  width: 54px;
  height: 60px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_evilsanta2_tracks.png");
}

.quest_goldenknight1_testimony {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_goldenknight1_testimony.png");
}

.quest_lostMasterclasser1_ancientTome {
  width: 33px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1_ancientTome.png");
}

.quest_lostMasterclasser1_forbiddenTome {
  width: 33px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1_forbiddenTome.png");
}

.quest_lostMasterclasser1_hiddenTome {
  width: 33px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_lostMasterclasser1_hiddenTome.png");
}

.quest_mayhemMistiflying2_mistifly1 {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2_mistifly1.png");
}

.quest_mayhemMistiflying2_mistifly2 {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2_mistifly2.png");
}

.quest_mayhemMistiflying2_mistifly3 {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_mayhemMistiflying2_mistifly3.png");
}

.quest_moon1_shard {
  width: 42px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moon1_shard.png");
}

.quest_moonstone1_moonstone {
  width: 30px;
  height: 30px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_moonstone1_moonstone.png");
}

.quest_onyx_leoRune {
  width: 39px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx_leoRune.png");
}

.quest_onyx_onyxStone {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx_onyxStone.png");
}

.quest_onyx_plutoRune {
  width: 39px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_onyx_plutoRune.png");
}

.quest_robot_bolt {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot_bolt.png");
}

.quest_robot_gear {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot_gear.png");
}

.quest_robot_spring {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_robot_spring.png");
}

.quest_ruby_aquariusRune {
  width: 39px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby_aquariusRune.png");
}

.quest_ruby_rubyGem {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby_rubyGem.png");
}

.quest_ruby_venusRune {
  width: 39px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_ruby_venusRune.png");
}

.quest_silver_cancerRune {
  width: 39px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver_cancerRune.png");
}

.quest_silver_moonRune {
  width: 39px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver_moonRune.png");
}

.quest_silver_silverIngot {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_silver_silverIngot.png");
}

.quest_stoikalmCalamity2_icicleCoin {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stoikalmCalamity2_icicleCoin.png");
}

.quest_stone_capricornRune {
  width: 42px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone_capricornRune.png");
}

.quest_stone_marsRune {
  width: 42px;
  height: 42px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone_marsRune.png");
}

.quest_stone_mossyStone {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_stone_mossyStone.png");
}

.quest_taskwoodsTerror2_brownie {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2_brownie.png");
}

.quest_taskwoodsTerror2_dryad {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2_dryad.png");
}

.quest_taskwoodsTerror2_pixie {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_taskwoodsTerror2_pixie.png");
}

.quest_turquoise_neptuneRune {
  width: 39px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise_neptuneRune.png");
}

.quest_turquoise_sagittariusRune {
  width: 39px;
  height: 39px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise_sagittariusRune.png");
}

.quest_turquoise_turquoiseGem {
  width: 48px;
  height: 51px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_turquoise_turquoiseGem.png");
}

.quest_vice2_lightCrystal {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_vice2_lightCrystal.png");
}

.inventory_quest_scroll_alligator {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_alligator.png");
}

.inventory_quest_scroll_amber {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_amber.png");
}

.inventory_quest_scroll_armadillo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_armadillo.png");
}

.inventory_quest_scroll_atom1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom1.png");
}

.inventory_quest_scroll_atom1_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom1_locked.png");
}

.inventory_quest_scroll_atom2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom2.png");
}

.inventory_quest_scroll_atom2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom2_locked.png");
}

.inventory_quest_scroll_atom3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom3.png");
}

.inventory_quest_scroll_atom3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_atom3_locked.png");
}

.inventory_quest_scroll_axolotl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_axolotl.png");
}

.inventory_quest_scroll_badger {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_badger.png");
}

.inventory_quest_scroll_basilist {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_basilist.png");
}

.inventory_quest_scroll_beetle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_beetle.png");
}

.inventory_quest_scroll_blackPearl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_blackPearl.png");
}

.inventory_quest_scroll_bronze {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_bronze.png");
}

.inventory_quest_scroll_bunny {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_bunny.png");
}

.inventory_quest_scroll_butterfly {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_butterfly.png");
}

.inventory_quest_scroll_cheetah {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_cheetah.png");
}

.inventory_quest_scroll_cow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_cow.png");
}

.inventory_quest_scroll_dilatoryDistress1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress1.png");
}

.inventory_quest_scroll_dilatoryDistress2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress2.png");
}

.inventory_quest_scroll_dilatoryDistress2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress2_locked.png");
}

.inventory_quest_scroll_dilatoryDistress3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress3.png");
}

.inventory_quest_scroll_dilatoryDistress3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatoryDistress3_locked.png");
}

.inventory_quest_scroll_dilatory_derby {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dilatory_derby.png");
}

.inventory_quest_scroll_dolphin {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dolphin.png");
}

.inventory_quest_scroll_dustbunnies {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_dustbunnies.png");
}

.inventory_quest_scroll_egg {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_egg.png");
}

.inventory_quest_scroll_evilsanta {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_evilsanta.png");
}

.inventory_quest_scroll_evilsanta2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_evilsanta2.png");
}

.inventory_quest_scroll_falcon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_falcon.png");
}

.inventory_quest_scroll_ferret {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_ferret.png");
}

.inventory_quest_scroll_fluorite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_fluorite.png");
}

.inventory_quest_scroll_frog {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_frog.png");
}

.inventory_quest_scroll_ghost_stag {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_ghost_stag.png");
}

.inventory_quest_scroll_goldenknight1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight1.png");
}

.inventory_quest_scroll_goldenknight1_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight1_locked.png");
}

.inventory_quest_scroll_goldenknight2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight2.png");
}

.inventory_quest_scroll_goldenknight2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight2_locked.png");
}

.inventory_quest_scroll_goldenknight3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight3.png");
}

.inventory_quest_scroll_goldenknight3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_goldenknight3_locked.png");
}

.inventory_quest_scroll_gryphon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_gryphon.png");
}

.inventory_quest_scroll_guineapig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_guineapig.png");
}

.inventory_quest_scroll_harpy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_harpy.png");
}

.inventory_quest_scroll_hedgehog {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_hedgehog.png");
}

.inventory_quest_scroll_hippo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_hippo.png");
}

.inventory_quest_scroll_horse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_horse.png");
}

.inventory_quest_scroll_kangaroo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_kangaroo.png");
}

.inventory_quest_scroll_kraken {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_kraken.png");
}

.inventory_quest_scroll_lostMasterclasser1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser1.png");
}

.inventory_quest_scroll_lostMasterclasser1_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser1_locked.png");
}

.inventory_quest_scroll_lostMasterclasser2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser2.png");
}

.inventory_quest_scroll_lostMasterclasser2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser2_locked.png");
}

.inventory_quest_scroll_lostMasterclasser3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser3.png");
}

.inventory_quest_scroll_lostMasterclasser3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser3_locked.png");
}

.inventory_quest_scroll_lostMasterclasser4 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser4.png");
}

.inventory_quest_scroll_lostMasterclasser4_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_lostMasterclasser4_locked.png");
}

.inventory_quest_scroll_mayhemMistiflying1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying1.png");
}

.inventory_quest_scroll_mayhemMistiflying2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying2.png");
}

.inventory_quest_scroll_mayhemMistiflying2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying2_locked.png");
}

.inventory_quest_scroll_mayhemMistiflying3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying3.png");
}

.inventory_quest_scroll_mayhemMistiflying3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_mayhemMistiflying3_locked.png");
}

.inventory_quest_scroll_monkey {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_monkey.png");
}

.inventory_quest_scroll_moon1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon1.png");
}

.inventory_quest_scroll_moon1_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon1_locked.png");
}

.inventory_quest_scroll_moon2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon2.png");
}

.inventory_quest_scroll_moon2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon2_locked.png");
}

.inventory_quest_scroll_moon3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon3.png");
}

.inventory_quest_scroll_moon3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moon3_locked.png");
}

.inventory_quest_scroll_moonstone1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone1.png");
}

.inventory_quest_scroll_moonstone1_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone1_locked.png");
}

.inventory_quest_scroll_moonstone2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone2.png");
}

.inventory_quest_scroll_moonstone2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone2_locked.png");
}

.inventory_quest_scroll_moonstone3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone3.png");
}

.inventory_quest_scroll_moonstone3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_moonstone3_locked.png");
}

.inventory_quest_scroll_nudibranch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_nudibranch.png");
}

.inventory_quest_scroll_octopus {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_octopus.png");
}

.inventory_quest_scroll_onyx {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_onyx.png");
}

.inventory_quest_scroll_owl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_owl.png");
}

.inventory_quest_scroll_peacock {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_peacock.png");
}

.inventory_quest_scroll_penguin {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_penguin.png");
}

.inventory_quest_scroll_pinkMarble {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_pinkMarble.png");
}

.inventory_quest_scroll_pterodactyl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_pterodactyl.png");
}

.inventory_quest_scroll_rat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_rat.png");
}

.inventory_quest_scroll_robot {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_robot.png");
}

.inventory_quest_scroll_rock {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_rock.png");
}

.inventory_quest_scroll_rooster {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_rooster.png");
}

.inventory_quest_scroll_ruby {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_ruby.png");
}

.inventory_quest_scroll_sabretooth {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_sabretooth.png");
}

.inventory_quest_scroll_seaserpent {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_seaserpent.png");
}

.inventory_quest_scroll_sheep {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_sheep.png");
}

.inventory_quest_scroll_silver {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_silver.png");
}

.inventory_quest_scroll_slime {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_slime.png");
}

.inventory_quest_scroll_sloth {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_sloth.png");
}

.inventory_quest_scroll_snail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_snail.png");
}

.inventory_quest_scroll_snake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_snake.png");
}

.inventory_quest_scroll_solarSystem {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_solarSystem.png");
}

.inventory_quest_scroll_spider {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_spider.png");
}

.inventory_quest_scroll_squirrel {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_squirrel.png");
}

.inventory_quest_scroll_stoikalmCalamity1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity1.png");
}

.inventory_quest_scroll_stoikalmCalamity2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity2.png");
}

.inventory_quest_scroll_stoikalmCalamity2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity2_locked.png");
}

.inventory_quest_scroll_stoikalmCalamity3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity3.png");
}

.inventory_quest_scroll_stoikalmCalamity3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stoikalmCalamity3_locked.png");
}

.inventory_quest_scroll_stone {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_stone.png");
}

.inventory_quest_scroll_taskwoodsTerror1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror1.png");
}

.inventory_quest_scroll_taskwoodsTerror2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror2.png");
}

.inventory_quest_scroll_taskwoodsTerror2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror2_locked.png");
}

.inventory_quest_scroll_taskwoodsTerror3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror3.png");
}

.inventory_quest_scroll_taskwoodsTerror3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_taskwoodsTerror3_locked.png");
}

.inventory_quest_scroll_treeling {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_treeling.png");
}

.inventory_quest_scroll_trex {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_trex.png");
}

.inventory_quest_scroll_trex_undead {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_trex_undead.png");
}

.inventory_quest_scroll_triceratops {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_triceratops.png");
}

.inventory_quest_scroll_turquoise {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_turquoise.png");
}

.inventory_quest_scroll_turtle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_turtle.png");
}

.inventory_quest_scroll_unicorn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_unicorn.png");
}

.inventory_quest_scroll_velociraptor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_velociraptor.png");
}

.inventory_quest_scroll_vice1 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice1.png");
}

.inventory_quest_scroll_vice1_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice1_locked.png");
}

.inventory_quest_scroll_vice2 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice2.png");
}

.inventory_quest_scroll_vice2_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice2_locked.png");
}

.inventory_quest_scroll_vice3 {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice3.png");
}

.inventory_quest_scroll_vice3_locked {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_vice3_locked.png");
}

.inventory_quest_scroll_virtualpet {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_virtualpet.png");
}

.inventory_quest_scroll_waffle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_waffle.png");
}

.inventory_quest_scroll_whale {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_whale.png");
}

.inventory_quest_scroll_windup {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_windup.png");
}

.inventory_quest_scroll_yarn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/inventory_quest_scroll_yarn.png");
}

.quest_bundle_aquaticAmigos {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_aquaticAmigos.png");
}

.quest_bundle_birdBuddies {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_birdBuddies.png");
}

.quest_bundle_cuddleBuddies {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_cuddleBuddies.png");
}

.quest_bundle_delightfulDinos {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_delightfulDinos.png");
}

.quest_bundle_farmFriends {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_farmFriends.png");
}

.quest_bundle_featheredFriends {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_featheredFriends.png");
}

.quest_bundle_forestFriends {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_forestFriends.png");
}

.quest_bundle_hugabug {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_hugabug.png");
}

.quest_bundle_jungleBuddies {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_jungleBuddies.png");
}

.quest_bundle_mythicalMarvels {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_mythicalMarvels.png");
}

.quest_bundle_oddballs {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_oddballs.png");
}

.quest_bundle_rockingReptiles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_rockingReptiles.png");
}

.quest_bundle_sandySidekicks {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_sandySidekicks.png");
}

.quest_bundle_splashyPals {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_splashyPals.png");
}

.quest_bundle_winterQuests {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_winterQuests.png");
}

.quest_bundle_witchyFamiliars {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/quest_bundle_witchyFamiliars.png");
}

.shop_gem {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_gem.png");
}

.shop_opaquePotion {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_opaquePotion.png");
}

.shop_petalFreePotion {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_petalFreePotion.png");
}

.shop_potion {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_potion.png");
}

.shop_salt {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_salt.png");
}

.shop_sand {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_sand.png");
}

.shop_seafoam {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_seafoam.png");
}

.shop_shinySeed {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_shinySeed.png");
}

.shop_snowball {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_snowball.png");
}

.shop_spookySparkles {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_spookySparkles.png");
}

.shop_mounts_Hippogriff-Hopeful {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_Hippogriff-Hopeful.png");
}

.shop_mounts_MagicalBee-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_MagicalBee-Base.png");
}

.shop_mounts_Mammoth-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_Mammoth-Base.png");
}

.shop_mounts_MantisShrimp-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_MantisShrimp-Base.png");
}

.shop_mounts_Phoenix-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mounts_Phoenix-Base.png");
}

.shop_pets_Hippogriff-Hopeful {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_Hippogriff-Hopeful.png");
}

.shop_pets_MagicalBee-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_MagicalBee-Base.png");
}

.shop_pets_Mammoth-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_Mammoth-Base.png");
}

.shop_pets_MantisShrimp-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_MantisShrimp-Base.png");
}

.shop_pets_Phoenix-Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pets_Phoenix-Base.png");
}

.shop_backStab {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_backStab.png");
}

.shop_brightness {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_brightness.png");
}

.shop_defensiveStance {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_defensiveStance.png");
}

.shop_earth {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_earth.png");
}

.shop_fireball {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_fireball.png");
}

.shop_frost {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_frost.png");
}

.shop_heal {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_heal.png");
}

.shop_healAll {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_healAll.png");
}

.shop_intimidate {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_intimidate.png");
}

.shop_mpheal {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_mpheal.png");
}

.shop_pickPocket {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_pickPocket.png");
}

.shop_protectAura {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_protectAura.png");
}

.shop_smash {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_smash.png");
}

.shop_stealth {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_stealth.png");
}

.shop_toolsOfTrade {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_toolsOfTrade.png");
}

.shop_valorousPresence {
  width: 40px;
  height: 40px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/shop_valorousPresence.png");
}

.Pet_Egg_Alligator {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Alligator.png");
}

.Pet_Egg_Armadillo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Armadillo.png");
}

.Pet_Egg_Axolotl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Axolotl.png");
}

.Pet_Egg_Badger {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Badger.png");
}

.Pet_Egg_BearCub {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_BearCub.png");
}

.Pet_Egg_Beetle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Beetle.png");
}

.Pet_Egg_Bunny {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Bunny.png");
}

.Pet_Egg_Butterfly {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Butterfly.png");
}

.Pet_Egg_Cactus {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cactus.png");
}

.Pet_Egg_Cheetah {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cheetah.png");
}

.Pet_Egg_Cow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cow.png");
}

.Pet_Egg_Cuttlefish {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Cuttlefish.png");
}

.Pet_Egg_Deer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Deer.png");
}

.Pet_Egg_Dolphin {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Dolphin.png");
}

.Pet_Egg_Dragon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Dragon.png");
}

.Pet_Egg_Egg {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Egg.png");
}

.Pet_Egg_Falcon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Falcon.png");
}

.Pet_Egg_Ferret {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Ferret.png");
}

.Pet_Egg_FlyingPig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_FlyingPig.png");
}

.Pet_Egg_Fox {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Fox.png");
}

.Pet_Egg_Frog {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Frog.png");
}

.Pet_Egg_Gryphon {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Gryphon.png");
}

.Pet_Egg_GuineaPig {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_GuineaPig.png");
}

.Pet_Egg_Hedgehog {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Hedgehog.png");
}

.Pet_Egg_Hippo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Hippo.png");
}

.Pet_Egg_Horse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Horse.png");
}

.Pet_Egg_Kangaroo {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Kangaroo.png");
}

.Pet_Egg_LionCub {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_LionCub.png");
}

.Pet_Egg_Monkey {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Monkey.png");
}

.Pet_Egg_Nudibranch {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Nudibranch.png");
}

.Pet_Egg_Octopus {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Octopus.png");
}

.Pet_Egg_Owl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Owl.png");
}

.Pet_Egg_PandaCub {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_PandaCub.png");
}

.Pet_Egg_Parrot {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Parrot.png");
}

.Pet_Egg_Peacock {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Peacock.png");
}

.Pet_Egg_Penguin {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Penguin.png");
}

.Pet_Egg_PolarBear {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_PolarBear.png");
}

.Pet_Egg_Pterodactyl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Pterodactyl.png");
}

.Pet_Egg_Rat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Rat.png");
}

.Pet_Egg_Robot {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Robot.png");
}

.Pet_Egg_Rock {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Rock.png");
}

.Pet_Egg_Rooster {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Rooster.png");
}

.Pet_Egg_Sabretooth {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Sabretooth.png");
}

.Pet_Egg_SeaSerpent {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_SeaSerpent.png");
}

.Pet_Egg_Seahorse {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Seahorse.png");
}

.Pet_Egg_Sheep {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Sheep.png");
}

.Pet_Egg_Slime {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Slime.png");
}

.Pet_Egg_Sloth {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Sloth.png");
}

.Pet_Egg_Snail {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Snail.png");
}

.Pet_Egg_Snake {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Snake.png");
}

.Pet_Egg_Spider {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Spider.png");
}

.Pet_Egg_Squirrel {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Squirrel.png");
}

.Pet_Egg_TRex {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_TRex.png");
}

.Pet_Egg_TigerCub {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_TigerCub.png");
}

.Pet_Egg_Treeling {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Treeling.png");
}

.Pet_Egg_Triceratops {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Triceratops.png");
}

.Pet_Egg_Turtle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Turtle.png");
}

.Pet_Egg_Unicorn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Unicorn.png");
}

.Pet_Egg_Velociraptor {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Velociraptor.png");
}

.Pet_Egg_Whale {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Whale.png");
}

.Pet_Egg_Wolf {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Wolf.png");
}

.Pet_Egg_Yarn {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Egg_Yarn.png");
}

.Pet_Food_Cake_Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Base.png");
}

.Pet_Food_Cake_CottonCandyBlue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_CottonCandyBlue.png");
}

.Pet_Food_Cake_CottonCandyPink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_CottonCandyPink.png");
}

.Pet_Food_Cake_Desert {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Desert.png");
}

.Pet_Food_Cake_Golden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Golden.png");
}

.Pet_Food_Cake_Red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Red.png");
}

.Pet_Food_Cake_Shade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Shade.png");
}

.Pet_Food_Cake_Skeleton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Skeleton.png");
}

.Pet_Food_Cake_White {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_White.png");
}

.Pet_Food_Cake_Zombie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Cake_Zombie.png");
}

.Pet_Food_Candy_Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Base.png");
}

.Pet_Food_Candy_CottonCandyBlue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_CottonCandyBlue.png");
}

.Pet_Food_Candy_CottonCandyPink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_CottonCandyPink.png");
}

.Pet_Food_Candy_Desert {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Desert.png");
}

.Pet_Food_Candy_Golden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Golden.png");
}

.Pet_Food_Candy_Red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Red.png");
}

.Pet_Food_Candy_Shade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Shade.png");
}

.Pet_Food_Candy_Skeleton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Skeleton.png");
}

.Pet_Food_Candy_White {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_White.png");
}

.Pet_Food_Candy_Zombie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Candy_Zombie.png");
}

.Pet_Food_Chocolate {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Chocolate.png");
}

.Pet_Food_CottonCandyBlue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_CottonCandyBlue.png");
}

.Pet_Food_CottonCandyPink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_CottonCandyPink.png");
}

.Pet_Food_Fish {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Fish.png");
}

.Pet_Food_Honey {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Honey.png");
}

.Pet_Food_Meat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Meat.png");
}

.Pet_Food_Milk {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Milk.png");
}

.Pet_Food_Pie_Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Base.png");
}

.Pet_Food_Pie_CottonCandyBlue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_CottonCandyBlue.png");
}

.Pet_Food_Pie_CottonCandyPink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_CottonCandyPink.png");
}

.Pet_Food_Pie_Desert {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Desert.png");
}

.Pet_Food_Pie_Golden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Golden.png");
}

.Pet_Food_Pie_Red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Red.png");
}

.Pet_Food_Pie_Shade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Shade.png");
}

.Pet_Food_Pie_Skeleton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Skeleton.png");
}

.Pet_Food_Pie_White {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_White.png");
}

.Pet_Food_Pie_Zombie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Pie_Zombie.png");
}

.Pet_Food_Potatoe {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Potatoe.png");
}

.Pet_Food_RottenMeat {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_RottenMeat.png");
}

.Pet_Food_Saddle {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Saddle.png");
}

.Pet_Food_Strawberry {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_Food_Strawberry.png");
}

.Mount_Body_Alligator-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Base.png");
}

.Mount_Body_Alligator-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-CottonCandyBlue.png");
}

.Mount_Body_Alligator-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-CottonCandyPink.png");
}

.Mount_Body_Alligator-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Desert.png");
}

.Mount_Body_Alligator-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Golden.png");
}

.Mount_Body_Alligator-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Red.png");
}

.Mount_Body_Alligator-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Shade.png");
}

.Mount_Body_Alligator-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Skeleton.png");
}

.Mount_Body_Alligator-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-White.png");
}

.Mount_Body_Alligator-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Alligator-Zombie.png");
}

.Mount_Body_Armadillo-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Base.png");
}

.Mount_Body_Armadillo-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-CottonCandyBlue.png");
}

.Mount_Body_Armadillo-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-CottonCandyPink.png");
}

.Mount_Body_Armadillo-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Desert.png");
}

.Mount_Body_Armadillo-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Golden.png");
}

.Mount_Body_Armadillo-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Red.png");
}

.Mount_Body_Armadillo-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Shade.png");
}

.Mount_Body_Armadillo-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Skeleton.png");
}

.Mount_Body_Armadillo-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-White.png");
}

.Mount_Body_Armadillo-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Armadillo-Zombie.png");
}

.Mount_Body_Axolotl-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Base.png");
}

.Mount_Body_Axolotl-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-CottonCandyBlue.png");
}

.Mount_Body_Axolotl-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-CottonCandyPink.png");
}

.Mount_Body_Axolotl-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Desert.png");
}

.Mount_Body_Axolotl-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Golden.png");
}

.Mount_Body_Axolotl-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Red.png");
}

.Mount_Body_Axolotl-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Shade.png");
}

.Mount_Body_Axolotl-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Skeleton.png");
}

.Mount_Body_Axolotl-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-White.png");
}

.Mount_Body_Axolotl-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Axolotl-Zombie.png");
}

.Mount_Body_Badger-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Base.png");
}

.Mount_Body_Badger-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-CottonCandyBlue.png");
}

.Mount_Body_Badger-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-CottonCandyPink.png");
}

.Mount_Body_Badger-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Desert.png");
}

.Mount_Body_Badger-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Golden.png");
}

.Mount_Body_Badger-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Red.png");
}

.Mount_Body_Badger-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Shade.png");
}

.Mount_Body_Badger-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Skeleton.png");
}

.Mount_Body_Badger-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-White.png");
}

.Mount_Body_Badger-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Badger-Zombie.png");
}

.Mount_Body_BearCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Amber.png");
}

.Mount_Body_BearCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Aquatic.png");
}

.Mount_Body_BearCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Aurora.png");
}

.Mount_Body_BearCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-AutumnLeaf.png");
}

.Mount_Body_BearCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Base.png");
}

.Mount_Body_BearCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-BirchBark.png");
}

.Mount_Body_BearCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-BlackPearl.png");
}

.Mount_Body_BearCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Bronze.png");
}

.Mount_Body_BearCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Celestial.png");
}

.Mount_Body_BearCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-CottonCandyBlue.png");
}

.Mount_Body_BearCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-CottonCandyPink.png");
}

.Mount_Body_BearCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Cupid.png");
}

.Mount_Body_BearCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Desert.png");
}

.Mount_Body_BearCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Ember.png");
}

.Mount_Body_BearCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Fairy.png");
}

.Mount_Body_BearCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Floral.png");
}

.Mount_Body_BearCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Fluorite.png");
}

.Mount_Body_BearCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Frost.png");
}

.Mount_Body_BearCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Ghost.png");
}

.Mount_Body_BearCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Glass.png");
}

.Mount_Body_BearCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Glow.png");
}

.Mount_Body_BearCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Golden.png");
}

.Mount_Body_BearCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Holly.png");
}

.Mount_Body_BearCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-IcySnow.png");
}

.Mount_Body_BearCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Moonglow.png");
}

.Mount_Body_BearCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-MossyStone.png");
}

.Mount_Body_BearCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Onyx.png");
}

.Mount_Body_BearCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Peppermint.png");
}

.Mount_Body_BearCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-PinkMarble.png");
}

.Mount_Body_BearCub-Polar {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Polar.png");
}

.Mount_Body_BearCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-PolkaDot.png");
}

.Mount_Body_BearCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Porcelain.png");
}

.Mount_Body_BearCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Rainbow.png");
}

.Mount_Body_BearCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Red.png");
}

.Mount_Body_BearCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-RoseQuartz.png");
}

.Mount_Body_BearCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-RoyalPurple.png");
}

.Mount_Body_BearCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Ruby.png");
}

.Mount_Body_BearCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-SandSculpture.png");
}

.Mount_Body_BearCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Shade.png");
}

.Mount_Body_BearCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Shadow.png");
}

.Mount_Body_BearCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Shimmer.png");
}

.Mount_Body_BearCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Silver.png");
}

.Mount_Body_BearCub-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Skeleton.png");
}

.Mount_Body_BearCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-SolarSystem.png");
}

.Mount_Body_BearCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Spooky.png");
}

.Mount_Body_BearCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-StainedGlass.png");
}

.Mount_Body_BearCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-StarryNight.png");
}

.Mount_Body_BearCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Sunset.png");
}

.Mount_Body_BearCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Sunshine.png");
}

.Mount_Body_BearCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Thunderstorm.png");
}

.Mount_Body_BearCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Turquoise.png");
}

.Mount_Body_BearCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Vampire.png");
}

.Mount_Body_BearCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Watery.png");
}

.Mount_Body_BearCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-White.png");
}

.Mount_Body_BearCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Windup.png");
}

.Mount_Body_BearCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_BearCub-Zombie.png");
}

.Mount_Body_Beetle-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Base.png");
}

.Mount_Body_Beetle-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-CottonCandyBlue.png");
}

.Mount_Body_Beetle-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-CottonCandyPink.png");
}

.Mount_Body_Beetle-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Desert.png");
}

.Mount_Body_Beetle-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Golden.png");
}

.Mount_Body_Beetle-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Red.png");
}

.Mount_Body_Beetle-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Shade.png");
}

.Mount_Body_Beetle-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Skeleton.png");
}

.Mount_Body_Beetle-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-White.png");
}

.Mount_Body_Beetle-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Beetle-Zombie.png");
}

.Mount_Body_Bunny-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Base.png");
}

.Mount_Body_Bunny-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-CottonCandyBlue.png");
}

.Mount_Body_Bunny-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-CottonCandyPink.png");
}

.Mount_Body_Bunny-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Desert.png");
}

.Mount_Body_Bunny-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Golden.png");
}

.Mount_Body_Bunny-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Red.png");
}

.Mount_Body_Bunny-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Shade.png");
}

.Mount_Body_Bunny-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Skeleton.png");
}

.Mount_Body_Bunny-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-White.png");
}

.Mount_Body_Bunny-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Bunny-Zombie.png");
}

.Mount_Body_Butterfly-Base {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Base.png");
}

.Mount_Body_Butterfly-CottonCandyBlue {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-CottonCandyBlue.png");
}

.Mount_Body_Butterfly-CottonCandyPink {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-CottonCandyPink.png");
}

.Mount_Body_Butterfly-Desert {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Desert.png");
}

.Mount_Body_Butterfly-Golden {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Golden.png");
}

.Mount_Body_Butterfly-Red {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Red.png");
}

.Mount_Body_Butterfly-Shade {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Shade.png");
}

.Mount_Body_Butterfly-Skeleton {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Skeleton.png");
}

.Mount_Body_Butterfly-White {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-White.png");
}

.Mount_Body_Butterfly-Zombie {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Butterfly-Zombie.png");
}

.Mount_Body_Cactus-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Amber.png");
}

.Mount_Body_Cactus-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Aquatic.png");
}

.Mount_Body_Cactus-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Aurora.png");
}

.Mount_Body_Cactus-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-AutumnLeaf.png");
}

.Mount_Body_Cactus-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Base.png");
}

.Mount_Body_Cactus-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-BirchBark.png");
}

.Mount_Body_Cactus-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-BlackPearl.png");
}

.Mount_Body_Cactus-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Bronze.png");
}

.Mount_Body_Cactus-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Celestial.png");
}

.Mount_Body_Cactus-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-CottonCandyBlue.png");
}

.Mount_Body_Cactus-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-CottonCandyPink.png");
}

.Mount_Body_Cactus-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Cupid.png");
}

.Mount_Body_Cactus-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Desert.png");
}

.Mount_Body_Cactus-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Ember.png");
}

.Mount_Body_Cactus-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Fairy.png");
}

.Mount_Body_Cactus-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Floral.png");
}

.Mount_Body_Cactus-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Fluorite.png");
}

.Mount_Body_Cactus-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Frost.png");
}

.Mount_Body_Cactus-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Ghost.png");
}

.Mount_Body_Cactus-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Glass.png");
}

.Mount_Body_Cactus-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Glow.png");
}

.Mount_Body_Cactus-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Golden.png");
}

.Mount_Body_Cactus-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Holly.png");
}

.Mount_Body_Cactus-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-IcySnow.png");
}

.Mount_Body_Cactus-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Moonglow.png");
}

.Mount_Body_Cactus-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-MossyStone.png");
}

.Mount_Body_Cactus-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Onyx.png");
}

.Mount_Body_Cactus-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Peppermint.png");
}

.Mount_Body_Cactus-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-PinkMarble.png");
}

.Mount_Body_Cactus-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-PolkaDot.png");
}

.Mount_Body_Cactus-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Porcelain.png");
}

.Mount_Body_Cactus-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Rainbow.png");
}

.Mount_Body_Cactus-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Red.png");
}

.Mount_Body_Cactus-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-RoseQuartz.png");
}

.Mount_Body_Cactus-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-RoyalPurple.png");
}

.Mount_Body_Cactus-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Ruby.png");
}

.Mount_Body_Cactus-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-SandSculpture.png");
}

.Mount_Body_Cactus-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Shade.png");
}

.Mount_Body_Cactus-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Shadow.png");
}

.Mount_Body_Cactus-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Shimmer.png");
}

.Mount_Body_Cactus-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Silver.png");
}

.Mount_Body_Cactus-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Skeleton.png");
}

.Mount_Body_Cactus-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-SolarSystem.png");
}

.Mount_Body_Cactus-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Spooky.png");
}

.Mount_Body_Cactus-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-StainedGlass.png");
}

.Mount_Body_Cactus-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-StarryNight.png");
}

.Mount_Body_Cactus-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Sunset.png");
}

.Mount_Body_Cactus-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Sunshine.png");
}

.Mount_Body_Cactus-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Thunderstorm.png");
}

.Mount_Body_Cactus-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Turquoise.png");
}

.Mount_Body_Cactus-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Vampire.png");
}

.Mount_Body_Cactus-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Watery.png");
}

.Mount_Body_Cactus-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-White.png");
}

.Mount_Body_Cactus-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Windup.png");
}

.Mount_Body_Cactus-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cactus-Zombie.png");
}

.Mount_Body_Cheetah-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Base.png");
}

.Mount_Body_Cheetah-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-CottonCandyBlue.png");
}

.Mount_Body_Cheetah-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-CottonCandyPink.png");
}

.Mount_Body_Cheetah-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Desert.png");
}

.Mount_Body_Cheetah-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Golden.png");
}

.Mount_Body_Cheetah-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Red.png");
}

.Mount_Body_Cheetah-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Shade.png");
}

.Mount_Body_Cheetah-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Skeleton.png");
}

.Mount_Body_Cheetah-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-White.png");
}

.Mount_Body_Cheetah-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cheetah-Zombie.png");
}

.Mount_Body_Cow-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Base.png");
}

.Mount_Body_Cow-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-CottonCandyBlue.png");
}

.Mount_Body_Cow-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-CottonCandyPink.png");
}

.Mount_Body_Cow-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Desert.png");
}

.Mount_Body_Cow-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Golden.png");
}

.Mount_Body_Cow-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Red.png");
}

.Mount_Body_Cow-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Shade.png");
}

.Mount_Body_Cow-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Skeleton.png");
}

.Mount_Body_Cow-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-White.png");
}

.Mount_Body_Cow-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cow-Zombie.png");
}

.Mount_Body_Cuttlefish-Base {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Base.png");
}

.Mount_Body_Cuttlefish-CottonCandyBlue {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-CottonCandyBlue.png");
}

.Mount_Body_Cuttlefish-CottonCandyPink {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-CottonCandyPink.png");
}

.Mount_Body_Cuttlefish-Desert {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Desert.png");
}

.Mount_Body_Cuttlefish-Golden {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Golden.png");
}

.Mount_Body_Cuttlefish-Red {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Red.png");
}

.Mount_Body_Cuttlefish-Shade {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Shade.png");
}

.Mount_Body_Cuttlefish-Skeleton {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Skeleton.png");
}

.Mount_Body_Cuttlefish-White {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-White.png");
}

.Mount_Body_Cuttlefish-Zombie {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Cuttlefish-Zombie.png");
}

.Mount_Body_Deer-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Base.png");
}

.Mount_Body_Deer-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-CottonCandyBlue.png");
}

.Mount_Body_Deer-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-CottonCandyPink.png");
}

.Mount_Body_Deer-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Desert.png");
}

.Mount_Body_Deer-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Golden.png");
}

.Mount_Body_Deer-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Red.png");
}

.Mount_Body_Deer-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Shade.png");
}

.Mount_Body_Deer-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Skeleton.png");
}

.Mount_Body_Deer-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-White.png");
}

.Mount_Body_Deer-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Deer-Zombie.png");
}

.Mount_Body_Dolphin-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Base.png");
}

.Mount_Body_Dolphin-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-CottonCandyBlue.png");
}

.Mount_Body_Dolphin-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-CottonCandyPink.png");
}

.Mount_Body_Dolphin-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Desert.png");
}

.Mount_Body_Dolphin-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Golden.png");
}

.Mount_Body_Dolphin-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Red.png");
}

.Mount_Body_Dolphin-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Shade.png");
}

.Mount_Body_Dolphin-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Skeleton.png");
}

.Mount_Body_Dolphin-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-White.png");
}

.Mount_Body_Dolphin-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dolphin-Zombie.png");
}

.Mount_Body_Dragon-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Amber.png");
}

.Mount_Body_Dragon-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Aquatic.png");
}

.Mount_Body_Dragon-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Aurora.png");
}

.Mount_Body_Dragon-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-AutumnLeaf.png");
}

.Mount_Body_Dragon-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Base.png");
}

.Mount_Body_Dragon-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-BirchBark.png");
}

.Mount_Body_Dragon-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-BlackPearl.png");
}

.Mount_Body_Dragon-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Bronze.png");
}

.Mount_Body_Dragon-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Celestial.png");
}

.Mount_Body_Dragon-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-CottonCandyBlue.png");
}

.Mount_Body_Dragon-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-CottonCandyPink.png");
}

.Mount_Body_Dragon-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Cupid.png");
}

.Mount_Body_Dragon-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Desert.png");
}

.Mount_Body_Dragon-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Ember.png");
}

.Mount_Body_Dragon-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Fairy.png");
}

.Mount_Body_Dragon-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Floral.png");
}

.Mount_Body_Dragon-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Fluorite.png");
}

.Mount_Body_Dragon-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Frost.png");
}

.Mount_Body_Dragon-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Ghost.png");
}

.Mount_Body_Dragon-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Glass.png");
}

.Mount_Body_Dragon-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Glow.png");
}

.Mount_Body_Dragon-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Golden.png");
}

.Mount_Body_Dragon-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Holly.png");
}

.Mount_Body_Dragon-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-IcySnow.png");
}

.Mount_Body_Dragon-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Moonglow.png");
}

.Mount_Body_Dragon-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-MossyStone.png");
}

.Mount_Body_Dragon-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Onyx.png");
}

.Mount_Body_Dragon-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Peppermint.png");
}

.Mount_Body_Dragon-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-PinkMarble.png");
}

.Mount_Body_Dragon-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-PolkaDot.png");
}

.Mount_Body_Dragon-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Porcelain.png");
}

.Mount_Body_Dragon-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Rainbow.png");
}

.Mount_Body_Dragon-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Red.png");
}

.Mount_Body_Dragon-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-RoseQuartz.png");
}

.Mount_Body_Dragon-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-RoyalPurple.png");
}

.Mount_Body_Dragon-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Ruby.png");
}

.Mount_Body_Dragon-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-SandSculpture.png");
}

.Mount_Body_Dragon-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Shade.png");
}

.Mount_Body_Dragon-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Shadow.png");
}

.Mount_Body_Dragon-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Shimmer.png");
}

.Mount_Body_Dragon-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Silver.png");
}

.Mount_Body_Dragon-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Skeleton.png");
}

.Mount_Body_Dragon-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-SolarSystem.png");
}

.Mount_Body_Dragon-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Spooky.png");
}

.Mount_Body_Dragon-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-StainedGlass.png");
}

.Mount_Body_Dragon-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-StarryNight.png");
}

.Mount_Body_Dragon-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Sunset.png");
}

.Mount_Body_Dragon-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Sunshine.png");
}

.Mount_Body_Dragon-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Thunderstorm.png");
}

.Mount_Body_Dragon-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Turquoise.png");
}

.Mount_Body_Dragon-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Vampire.png");
}

.Mount_Body_Dragon-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Watery.png");
}

.Mount_Body_Dragon-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-White.png");
}

.Mount_Body_Dragon-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Windup.png");
}

.Mount_Body_Dragon-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Dragon-Zombie.png");
}

.Mount_Body_Egg-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Base.png");
}

.Mount_Body_Egg-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-CottonCandyBlue.png");
}

.Mount_Body_Egg-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-CottonCandyPink.png");
}

.Mount_Body_Egg-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Desert.png");
}

.Mount_Body_Egg-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Golden.png");
}

.Mount_Body_Egg-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Red.png");
}

.Mount_Body_Egg-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Shade.png");
}

.Mount_Body_Egg-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Skeleton.png");
}

.Mount_Body_Egg-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-White.png");
}

.Mount_Body_Egg-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Egg-Zombie.png");
}

.Mount_Body_Falcon-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Base.png");
}

.Mount_Body_Falcon-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-CottonCandyBlue.png");
}

.Mount_Body_Falcon-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-CottonCandyPink.png");
}

.Mount_Body_Falcon-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Desert.png");
}

.Mount_Body_Falcon-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Golden.png");
}

.Mount_Body_Falcon-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Red.png");
}

.Mount_Body_Falcon-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Shade.png");
}

.Mount_Body_Falcon-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Skeleton.png");
}

.Mount_Body_Falcon-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-White.png");
}

.Mount_Body_Falcon-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Falcon-Zombie.png");
}

.Mount_Body_Ferret-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Base.png");
}

.Mount_Body_Ferret-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-CottonCandyBlue.png");
}

.Mount_Body_Ferret-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-CottonCandyPink.png");
}

.Mount_Body_Ferret-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Desert.png");
}

.Mount_Body_Ferret-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Golden.png");
}

.Mount_Body_Ferret-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Red.png");
}

.Mount_Body_Ferret-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Shade.png");
}

.Mount_Body_Ferret-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Skeleton.png");
}

.Mount_Body_Ferret-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-White.png");
}

.Mount_Body_Ferret-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Ferret-Zombie.png");
}

.Mount_Body_FlyingPig-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Amber.png");
}

.Mount_Body_FlyingPig-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Aquatic.png");
}

.Mount_Body_FlyingPig-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Aurora.png");
}

.Mount_Body_FlyingPig-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-AutumnLeaf.png");
}

.Mount_Body_FlyingPig-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Base.png");
}

.Mount_Body_FlyingPig-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-BirchBark.png");
}

.Mount_Body_FlyingPig-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-BlackPearl.png");
}

.Mount_Body_FlyingPig-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Bronze.png");
}

.Mount_Body_FlyingPig-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Celestial.png");
}

.Mount_Body_FlyingPig-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-CottonCandyBlue.png");
}

.Mount_Body_FlyingPig-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-CottonCandyPink.png");
}

.Mount_Body_FlyingPig-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Cupid.png");
}

.Mount_Body_FlyingPig-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Desert.png");
}

.Mount_Body_FlyingPig-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Ember.png");
}

.Mount_Body_FlyingPig-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Fairy.png");
}

.Mount_Body_FlyingPig-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Floral.png");
}

.Mount_Body_FlyingPig-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Fluorite.png");
}

.Mount_Body_FlyingPig-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Frost.png");
}

.Mount_Body_FlyingPig-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Ghost.png");
}

.Mount_Body_FlyingPig-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Glass.png");
}

.Mount_Body_FlyingPig-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Glow.png");
}

.Mount_Body_FlyingPig-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Golden.png");
}

.Mount_Body_FlyingPig-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Holly.png");
}

.Mount_Body_FlyingPig-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-IcySnow.png");
}

.Mount_Body_FlyingPig-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Moonglow.png");
}

.Mount_Body_FlyingPig-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-MossyStone.png");
}

.Mount_Body_FlyingPig-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Onyx.png");
}

.Mount_Body_FlyingPig-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Peppermint.png");
}

.Mount_Body_FlyingPig-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-PinkMarble.png");
}

.Mount_Body_FlyingPig-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-PolkaDot.png");
}

.Mount_Body_FlyingPig-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Porcelain.png");
}

.Mount_Body_FlyingPig-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Rainbow.png");
}

.Mount_Body_FlyingPig-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Red.png");
}

.Mount_Body_FlyingPig-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-RoseQuartz.png");
}

.Mount_Body_FlyingPig-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-RoyalPurple.png");
}

.Mount_Body_FlyingPig-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Ruby.png");
}

.Mount_Body_FlyingPig-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-SandSculpture.png");
}

.Mount_Body_FlyingPig-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Shade.png");
}

.Mount_Body_FlyingPig-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Shadow.png");
}

.Mount_Body_FlyingPig-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Shimmer.png");
}

.Mount_Body_FlyingPig-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Silver.png");
}

.Mount_Body_FlyingPig-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Skeleton.png");
}

.Mount_Body_FlyingPig-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-SolarSystem.png");
}

.Mount_Body_FlyingPig-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Spooky.png");
}

.Mount_Body_FlyingPig-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-StainedGlass.png");
}

.Mount_Body_FlyingPig-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-StarryNight.png");
}

.Mount_Body_FlyingPig-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Sunset.png");
}

.Mount_Body_FlyingPig-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Sunshine.png");
}

.Mount_Body_FlyingPig-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Thunderstorm.png");
}

.Mount_Body_FlyingPig-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Turquoise.png");
}

.Mount_Body_FlyingPig-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Vampire.png");
}

.Mount_Body_FlyingPig-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Watery.png");
}

.Mount_Body_FlyingPig-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-White.png");
}

.Mount_Body_FlyingPig-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Windup.png");
}

.Mount_Body_FlyingPig-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_FlyingPig-Zombie.png");
}

.Mount_Body_Fox-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Amber.png");
}

.Mount_Body_Fox-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Aquatic.png");
}

.Mount_Body_Fox-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Aurora.png");
}

.Mount_Body_Fox-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-AutumnLeaf.png");
}

.Mount_Body_Fox-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Base.png");
}

.Mount_Body_Fox-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-BirchBark.png");
}

.Mount_Body_Fox-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-BlackPearl.png");
}

.Mount_Body_Fox-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Bronze.png");
}

.Mount_Body_Fox-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Celestial.png");
}

.Mount_Body_Fox-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-CottonCandyBlue.png");
}

.Mount_Body_Fox-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-CottonCandyPink.png");
}

.Mount_Body_Fox-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Cupid.png");
}

.Mount_Body_Fox-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Desert.png");
}

.Mount_Body_Fox-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Ember.png");
}

.Mount_Body_Fox-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Fairy.png");
}

.Mount_Body_Fox-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Floral.png");
}

.Mount_Body_Fox-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Fluorite.png");
}

.Mount_Body_Fox-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Frost.png");
}

.Mount_Body_Fox-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Ghost.png");
}

.Mount_Body_Fox-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Glass.png");
}

.Mount_Body_Fox-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Glow.png");
}

.Mount_Body_Fox-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Golden.png");
}

.Mount_Body_Fox-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Holly.png");
}

.Mount_Body_Fox-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-IcySnow.png");
}

.Mount_Body_Fox-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Moonglow.png");
}

.Mount_Body_Fox-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-MossyStone.png");
}

.Mount_Body_Fox-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Onyx.png");
}

.Mount_Body_Fox-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Peppermint.png");
}

.Mount_Body_Fox-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-PinkMarble.png");
}

.Mount_Body_Fox-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-PolkaDot.png");
}

.Mount_Body_Fox-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Porcelain.png");
}

.Mount_Body_Fox-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Rainbow.png");
}

.Mount_Body_Fox-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Red.png");
}

.Mount_Body_Fox-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-RoseQuartz.png");
}

.Mount_Body_Fox-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-RoyalPurple.png");
}

.Mount_Body_Fox-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Ruby.png");
}

.Mount_Body_Fox-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-SandSculpture.png");
}

.Mount_Body_Fox-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Shade.png");
}

.Mount_Body_Fox-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Shadow.png");
}

.Mount_Body_Fox-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Shimmer.png");
}

.Mount_Body_Fox-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Silver.png");
}

.Mount_Body_Fox-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Skeleton.png");
}

.Mount_Body_Fox-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-SolarSystem.png");
}

.Mount_Body_Fox-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Spooky.png");
}

.Mount_Body_Fox-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-StainedGlass.png");
}

.Mount_Body_Fox-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-StarryNight.png");
}

.Mount_Body_Fox-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Sunset.png");
}

.Mount_Body_Fox-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Sunshine.png");
}

.Mount_Body_Fox-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Thunderstorm.png");
}

.Mount_Body_Fox-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Turquoise.png");
}

.Mount_Body_Fox-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Vampire.png");
}

.Mount_Body_Fox-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Watery.png");
}

.Mount_Body_Fox-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-White.png");
}

.Mount_Body_Fox-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Windup.png");
}

.Mount_Body_Fox-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Fox-Zombie.png");
}

.Mount_Body_Frog-Base {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Base.png");
}

.Mount_Body_Frog-CottonCandyBlue {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-CottonCandyBlue.png");
}

.Mount_Body_Frog-CottonCandyPink {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-CottonCandyPink.png");
}

.Mount_Body_Frog-Desert {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Desert.png");
}

.Mount_Body_Frog-Golden {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Golden.png");
}

.Mount_Body_Frog-Red {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Red.png");
}

.Mount_Body_Frog-Shade {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Shade.png");
}

.Mount_Body_Frog-Skeleton {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Skeleton.png");
}

.Mount_Body_Frog-White {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-White.png");
}

.Mount_Body_Frog-Zombie {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Frog-Zombie.png");
}

.Mount_Body_Gryphon-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Base.png");
}

.Mount_Body_Gryphon-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-CottonCandyBlue.png");
}

.Mount_Body_Gryphon-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-CottonCandyPink.png");
}

.Mount_Body_Gryphon-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Desert.png");
}

.Mount_Body_Gryphon-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Golden.png");
}

.Mount_Body_Gryphon-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Red.png");
}

.Mount_Body_Gryphon-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-RoyalPurple.png");
}

.Mount_Body_Gryphon-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Shade.png");
}

.Mount_Body_Gryphon-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Skeleton.png");
}

.Mount_Body_Gryphon-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-White.png");
}

.Mount_Body_Gryphon-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Gryphon-Zombie.png");
}

.Mount_Body_GuineaPig-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Base.png");
}

.Mount_Body_GuineaPig-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-CottonCandyBlue.png");
}

.Mount_Body_GuineaPig-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-CottonCandyPink.png");
}

.Mount_Body_GuineaPig-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Desert.png");
}

.Mount_Body_GuineaPig-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Golden.png");
}

.Mount_Body_GuineaPig-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Red.png");
}

.Mount_Body_GuineaPig-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Shade.png");
}

.Mount_Body_GuineaPig-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Skeleton.png");
}

.Mount_Body_GuineaPig-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-White.png");
}

.Mount_Body_GuineaPig-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_GuineaPig-Zombie.png");
}

.Mount_Body_Hedgehog-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Base.png");
}

.Mount_Body_Hedgehog-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-CottonCandyBlue.png");
}

.Mount_Body_Hedgehog-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-CottonCandyPink.png");
}

.Mount_Body_Hedgehog-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Desert.png");
}

.Mount_Body_Hedgehog-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Golden.png");
}

.Mount_Body_Hedgehog-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Red.png");
}

.Mount_Body_Hedgehog-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Shade.png");
}

.Mount_Body_Hedgehog-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Skeleton.png");
}

.Mount_Body_Hedgehog-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-White.png");
}

.Mount_Body_Hedgehog-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hedgehog-Zombie.png");
}

.Mount_Body_Hippo-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Base.png");
}

.Mount_Body_Hippo-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-CottonCandyBlue.png");
}

.Mount_Body_Hippo-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-CottonCandyPink.png");
}

.Mount_Body_Hippo-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Desert.png");
}

.Mount_Body_Hippo-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Golden.png");
}

.Mount_Body_Hippo-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Red.png");
}

.Mount_Body_Hippo-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Shade.png");
}

.Mount_Body_Hippo-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Skeleton.png");
}

.Mount_Body_Hippo-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-White.png");
}

.Mount_Body_Hippo-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippo-Zombie.png");
}

.Mount_Body_Hippogriff-Hopeful {
  width: 111px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Hippogriff-Hopeful.png");
}

.Mount_Body_Horse-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Base.png");
}

.Mount_Body_Horse-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-CottonCandyBlue.png");
}

.Mount_Body_Horse-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-CottonCandyPink.png");
}

.Mount_Body_Horse-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Desert.png");
}

.Mount_Body_Horse-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Golden.png");
}

.Mount_Body_Horse-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Red.png");
}

.Mount_Body_Horse-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Shade.png");
}

.Mount_Body_Horse-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Skeleton.png");
}

.Mount_Body_Horse-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-White.png");
}

.Mount_Body_Horse-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Horse-Zombie.png");
}

.Mount_Body_JackOLantern-Base {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-Base.png");
}

.Mount_Body_JackOLantern-Ghost {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-Ghost.png");
}

.Mount_Body_JackOLantern-Glow {
  width: 93px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-Glow.png");
}

.Mount_Body_JackOLantern-RoyalPurple {
  width: 93px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_JackOLantern-RoyalPurple.png");
}

.Mount_Body_Jackalope-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Jackalope-RoyalPurple.png");
}

.Mount_Body_Kangaroo-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Base.png");
}

.Mount_Body_Kangaroo-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-CottonCandyBlue.png");
}

.Mount_Body_Kangaroo-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-CottonCandyPink.png");
}

.Mount_Body_Kangaroo-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Desert.png");
}

.Mount_Body_Kangaroo-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Golden.png");
}

.Mount_Body_Kangaroo-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Red.png");
}

.Mount_Body_Kangaroo-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Shade.png");
}

.Mount_Body_Kangaroo-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Skeleton.png");
}

.Mount_Body_Kangaroo-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-White.png");
}

.Mount_Body_Kangaroo-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Kangaroo-Zombie.png");
}

.Mount_Body_LionCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Amber.png");
}

.Mount_Body_LionCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Aquatic.png");
}

.Mount_Body_LionCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Aurora.png");
}

.Mount_Body_LionCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-AutumnLeaf.png");
}

.Mount_Body_LionCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Base.png");
}

.Mount_Body_LionCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-BirchBark.png");
}

.Mount_Body_LionCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-BlackPearl.png");
}

.Mount_Body_LionCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Bronze.png");
}

.Mount_Body_LionCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Celestial.png");
}

.Mount_Body_LionCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-CottonCandyBlue.png");
}

.Mount_Body_LionCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-CottonCandyPink.png");
}

.Mount_Body_LionCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Cupid.png");
}

.Mount_Body_LionCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Desert.png");
}

.Mount_Body_LionCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ember.png");
}

.Mount_Body_LionCub-Ethereal {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ethereal.png");
}

.Mount_Body_LionCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Fairy.png");
}

.Mount_Body_LionCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Floral.png");
}

.Mount_Body_LionCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Fluorite.png");
}

.Mount_Body_LionCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Frost.png");
}

.Mount_Body_LionCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ghost.png");
}

.Mount_Body_LionCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Glass.png");
}

.Mount_Body_LionCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Glow.png");
}

.Mount_Body_LionCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Golden.png");
}

.Mount_Body_LionCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Holly.png");
}

.Mount_Body_LionCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-IcySnow.png");
}

.Mount_Body_LionCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Moonglow.png");
}

.Mount_Body_LionCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-MossyStone.png");
}

.Mount_Body_LionCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Onyx.png");
}

.Mount_Body_LionCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Peppermint.png");
}

.Mount_Body_LionCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-PinkMarble.png");
}

.Mount_Body_LionCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-PolkaDot.png");
}

.Mount_Body_LionCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Porcelain.png");
}

.Mount_Body_LionCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Rainbow.png");
}

.Mount_Body_LionCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Red.png");
}

.Mount_Body_LionCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-RoseQuartz.png");
}

.Mount_Body_LionCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-RoyalPurple.png");
}

.Mount_Body_LionCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Ruby.png");
}

.Mount_Body_LionCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-SandSculpture.png");
}

.Mount_Body_LionCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Shade.png");
}

.Mount_Body_LionCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Shadow.png");
}

.Mount_Body_LionCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Shimmer.png");
}

.Mount_Body_LionCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Silver.png");
}

.Mount_Body_LionCub-Skeleton {
  width: 111px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Skeleton.png");
}

.Mount_Body_LionCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-SolarSystem.png");
}

.Mount_Body_LionCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Spooky.png");
}

.Mount_Body_LionCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-StainedGlass.png");
}

.Mount_Body_LionCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-StarryNight.png");
}

.Mount_Body_LionCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Sunset.png");
}

.Mount_Body_LionCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Sunshine.png");
}

.Mount_Body_LionCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Thunderstorm.png");
}

.Mount_Body_LionCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Turquoise.png");
}

.Mount_Body_LionCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Vampire.png");
}

.Mount_Body_LionCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Watery.png");
}

.Mount_Body_LionCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-White.png");
}

.Mount_Body_LionCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Windup.png");
}

.Mount_Body_LionCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_LionCub-Zombie.png");
}

.Mount_Body_MagicalBee-Base {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_MagicalBee-Base.png");
}

.Mount_Body_Mammoth-Base {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Mammoth-Base.png");
}

.Mount_Body_MantisShrimp-Base {
  width: 108px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_MantisShrimp-Base.png");
}

.Mount_Body_Monkey-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Base.png");
}

.Mount_Body_Monkey-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-CottonCandyBlue.png");
}

.Mount_Body_Monkey-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-CottonCandyPink.png");
}

.Mount_Body_Monkey-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Desert.png");
}

.Mount_Body_Monkey-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Golden.png");
}

.Mount_Body_Monkey-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Red.png");
}

.Mount_Body_Monkey-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Shade.png");
}

.Mount_Body_Monkey-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Skeleton.png");
}

.Mount_Body_Monkey-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-White.png");
}

.Mount_Body_Monkey-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Monkey-Zombie.png");
}

.Mount_Body_Nudibranch-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Base.png");
}

.Mount_Body_Nudibranch-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-CottonCandyBlue.png");
}

.Mount_Body_Nudibranch-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-CottonCandyPink.png");
}

.Mount_Body_Nudibranch-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Desert.png");
}

.Mount_Body_Nudibranch-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Golden.png");
}

.Mount_Body_Nudibranch-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Red.png");
}

.Mount_Body_Nudibranch-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Shade.png");
}

.Mount_Body_Nudibranch-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Skeleton.png");
}

.Mount_Body_Nudibranch-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-White.png");
}

.Mount_Body_Nudibranch-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Nudibranch-Zombie.png");
}

.Mount_Body_Octopus-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Base.png");
}

.Mount_Body_Octopus-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-CottonCandyBlue.png");
}

.Mount_Body_Octopus-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-CottonCandyPink.png");
}

.Mount_Body_Octopus-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Desert.png");
}

.Mount_Body_Octopus-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Golden.png");
}

.Mount_Body_Octopus-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Red.png");
}

.Mount_Body_Octopus-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Shade.png");
}

.Mount_Body_Octopus-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Skeleton.png");
}

.Mount_Body_Octopus-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-White.png");
}

.Mount_Body_Octopus-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Octopus-Zombie.png");
}

.Mount_Body_Orca-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Orca-Base.png");
}

.Mount_Body_Owl-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Base.png");
}

.Mount_Body_Owl-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-CottonCandyBlue.png");
}

.Mount_Body_Owl-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-CottonCandyPink.png");
}

.Mount_Body_Owl-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Desert.png");
}

.Mount_Body_Owl-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Golden.png");
}

.Mount_Body_Owl-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Red.png");
}

.Mount_Body_Owl-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Shade.png");
}

.Mount_Body_Owl-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Skeleton.png");
}

.Mount_Body_Owl-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-White.png");
}

.Mount_Body_Owl-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Owl-Zombie.png");
}

.Mount_Body_PandaCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Amber.png");
}

.Mount_Body_PandaCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Aquatic.png");
}

.Mount_Body_PandaCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Aurora.png");
}

.Mount_Body_PandaCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-AutumnLeaf.png");
}

.Mount_Body_PandaCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Base.png");
}

.Mount_Body_PandaCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-BirchBark.png");
}

.Mount_Body_PandaCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-BlackPearl.png");
}

.Mount_Body_PandaCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Bronze.png");
}

.Mount_Body_PandaCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Celestial.png");
}

.Mount_Body_PandaCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-CottonCandyBlue.png");
}

.Mount_Body_PandaCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-CottonCandyPink.png");
}

.Mount_Body_PandaCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Cupid.png");
}

.Mount_Body_PandaCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Desert.png");
}

.Mount_Body_PandaCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Ember.png");
}

.Mount_Body_PandaCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Fairy.png");
}

.Mount_Body_PandaCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Floral.png");
}

.Mount_Body_PandaCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Fluorite.png");
}

.Mount_Body_PandaCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Frost.png");
}

.Mount_Body_PandaCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Ghost.png");
}

.Mount_Body_PandaCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Glass.png");
}

.Mount_Body_PandaCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Glow.png");
}

.Mount_Body_PandaCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Golden.png");
}

.Mount_Body_PandaCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Holly.png");
}

.Mount_Body_PandaCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-IcySnow.png");
}

.Mount_Body_PandaCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Moonglow.png");
}

.Mount_Body_PandaCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-MossyStone.png");
}

.Mount_Body_PandaCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Onyx.png");
}

.Mount_Body_PandaCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Peppermint.png");
}

.Mount_Body_PandaCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-PinkMarble.png");
}

.Mount_Body_PandaCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-PolkaDot.png");
}

.Mount_Body_PandaCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Porcelain.png");
}

.Mount_Body_PandaCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Rainbow.png");
}

.Mount_Body_PandaCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Red.png");
}

.Mount_Body_PandaCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-RoseQuartz.png");
}

.Mount_Body_PandaCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-RoyalPurple.png");
}

.Mount_Body_PandaCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Ruby.png");
}

.Mount_Body_PandaCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-SandSculpture.png");
}

.Mount_Body_PandaCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Shade.png");
}

.Mount_Body_PandaCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Shadow.png");
}

.Mount_Body_PandaCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Shimmer.png");
}

.Mount_Body_PandaCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Silver.png");
}

.Mount_Body_PandaCub-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Skeleton.png");
}

.Mount_Body_PandaCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-SolarSystem.png");
}

.Mount_Body_PandaCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Spooky.png");
}

.Mount_Body_PandaCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-StainedGlass.png");
}

.Mount_Body_PandaCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-StarryNight.png");
}

.Mount_Body_PandaCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Sunset.png");
}

.Mount_Body_PandaCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Sunshine.png");
}

.Mount_Body_PandaCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Thunderstorm.png");
}

.Mount_Body_PandaCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Turquoise.png");
}

.Mount_Body_PandaCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Vampire.png");
}

.Mount_Body_PandaCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Watery.png");
}

.Mount_Body_PandaCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-White.png");
}

.Mount_Body_PandaCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Windup.png");
}

.Mount_Body_PandaCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_PandaCub-Zombie.png");
}

.Mount_Body_Parrot-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Base.png");
}

.Mount_Body_Parrot-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-CottonCandyBlue.png");
}

.Mount_Body_Parrot-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-CottonCandyPink.png");
}

.Mount_Body_Parrot-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Desert.png");
}

.Mount_Body_Parrot-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Golden.png");
}

.Mount_Body_Parrot-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Red.png");
}

.Mount_Body_Parrot-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Shade.png");
}

.Mount_Body_Parrot-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Skeleton.png");
}

.Mount_Body_Parrot-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-White.png");
}

.Mount_Body_Parrot-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Parrot-Zombie.png");
}

.Mount_Body_Peacock-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Base.png");
}

.Mount_Body_Peacock-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-CottonCandyBlue.png");
}

.Mount_Body_Peacock-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-CottonCandyPink.png");
}

.Mount_Body_Peacock-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Desert.png");
}

.Mount_Body_Peacock-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Golden.png");
}

.Mount_Body_Peacock-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Red.png");
}

.Mount_Body_Peacock-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Shade.png");
}

.Mount_Body_Peacock-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Skeleton.png");
}

.Mount_Body_Peacock-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-White.png");
}

.Mount_Body_Peacock-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Peacock-Zombie.png");
}

.Mount_Body_Penguin-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Base.png");
}

.Mount_Body_Penguin-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-CottonCandyBlue.png");
}

.Mount_Body_Penguin-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-CottonCandyPink.png");
}

.Mount_Body_Penguin-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Desert.png");
}

.Mount_Body_Penguin-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Golden.png");
}

.Mount_Body_Penguin-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Red.png");
}

.Mount_Body_Penguin-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Shade.png");
}

.Mount_Body_Penguin-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Skeleton.png");
}

.Mount_Body_Penguin-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-White.png");
}

.Mount_Body_Penguin-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Penguin-Zombie.png");
}

.Mount_Body_Phoenix-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Phoenix-Base.png");
}

.Mount_Body_Pterodactyl-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Base.png");
}

.Mount_Body_Pterodactyl-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-CottonCandyBlue.png");
}

.Mount_Body_Pterodactyl-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-CottonCandyPink.png");
}

.Mount_Body_Pterodactyl-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Desert.png");
}

.Mount_Body_Pterodactyl-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Golden.png");
}

.Mount_Body_Pterodactyl-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Red.png");
}

.Mount_Body_Pterodactyl-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Shade.png");
}

.Mount_Body_Pterodactyl-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Skeleton.png");
}

.Mount_Body_Pterodactyl-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-White.png");
}

.Mount_Body_Pterodactyl-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Pterodactyl-Zombie.png");
}

.Mount_Body_Rat-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Base.png");
}

.Mount_Body_Rat-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-CottonCandyBlue.png");
}

.Mount_Body_Rat-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-CottonCandyPink.png");
}

.Mount_Body_Rat-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Desert.png");
}

.Mount_Body_Rat-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Golden.png");
}

.Mount_Body_Rat-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Red.png");
}

.Mount_Body_Rat-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Shade.png");
}

.Mount_Body_Rat-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Skeleton.png");
}

.Mount_Body_Rat-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-White.png");
}

.Mount_Body_Rat-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rat-Zombie.png");
}

.Mount_Body_Robot-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Base.png");
}

.Mount_Body_Robot-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-CottonCandyBlue.png");
}

.Mount_Body_Robot-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-CottonCandyPink.png");
}

.Mount_Body_Robot-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Desert.png");
}

.Mount_Body_Robot-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Golden.png");
}

.Mount_Body_Robot-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Red.png");
}

.Mount_Body_Robot-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Shade.png");
}

.Mount_Body_Robot-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Skeleton.png");
}

.Mount_Body_Robot-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-White.png");
}

.Mount_Body_Robot-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Robot-Zombie.png");
}

.Mount_Body_Rock-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Base.png");
}

.Mount_Body_Rock-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-CottonCandyBlue.png");
}

.Mount_Body_Rock-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-CottonCandyPink.png");
}

.Mount_Body_Rock-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Desert.png");
}

.Mount_Body_Rock-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Golden.png");
}

.Mount_Body_Rock-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Red.png");
}

.Mount_Body_Rock-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Shade.png");
}

.Mount_Body_Rock-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Skeleton.png");
}

.Mount_Body_Rock-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-White.png");
}

.Mount_Body_Rock-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rock-Zombie.png");
}

.Mount_Body_Rooster-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Base.png");
}

.Mount_Body_Rooster-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-CottonCandyBlue.png");
}

.Mount_Body_Rooster-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-CottonCandyPink.png");
}

.Mount_Body_Rooster-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Desert.png");
}

.Mount_Body_Rooster-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Golden.png");
}

.Mount_Body_Rooster-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Red.png");
}

.Mount_Body_Rooster-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Shade.png");
}

.Mount_Body_Rooster-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Skeleton.png");
}

.Mount_Body_Rooster-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-White.png");
}

.Mount_Body_Rooster-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Rooster-Zombie.png");
}

.Mount_Body_Sabretooth-Base {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Base.png");
}

.Mount_Body_Sabretooth-CottonCandyBlue {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-CottonCandyBlue.png");
}

.Mount_Body_Sabretooth-CottonCandyPink {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-CottonCandyPink.png");
}

.Mount_Body_Sabretooth-Desert {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Desert.png");
}

.Mount_Body_Sabretooth-Golden {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Golden.png");
}

.Mount_Body_Sabretooth-Red {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Red.png");
}

.Mount_Body_Sabretooth-Shade {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Shade.png");
}

.Mount_Body_Sabretooth-Skeleton {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Skeleton.png");
}

.Mount_Body_Sabretooth-White {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-White.png");
}

.Mount_Body_Sabretooth-Zombie {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sabretooth-Zombie.png");
}

.Mount_Body_SeaSerpent-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Base.png");
}

.Mount_Body_SeaSerpent-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-CottonCandyBlue.png");
}

.Mount_Body_SeaSerpent-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-CottonCandyPink.png");
}

.Mount_Body_SeaSerpent-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Desert.png");
}

.Mount_Body_SeaSerpent-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Golden.png");
}

.Mount_Body_SeaSerpent-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Red.png");
}

.Mount_Body_SeaSerpent-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Shade.png");
}

.Mount_Body_SeaSerpent-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Skeleton.png");
}

.Mount_Body_SeaSerpent-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-White.png");
}

.Mount_Body_SeaSerpent-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_SeaSerpent-Zombie.png");
}

.Mount_Body_Seahorse-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Base.png");
}

.Mount_Body_Seahorse-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-CottonCandyBlue.png");
}

.Mount_Body_Seahorse-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-CottonCandyPink.png");
}

.Mount_Body_Seahorse-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Desert.png");
}

.Mount_Body_Seahorse-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Golden.png");
}

.Mount_Body_Seahorse-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Red.png");
}

.Mount_Body_Seahorse-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Shade.png");
}

.Mount_Body_Seahorse-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Skeleton.png");
}

.Mount_Body_Seahorse-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-White.png");
}

.Mount_Body_Seahorse-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Seahorse-Zombie.png");
}

.Mount_Body_Sheep-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Base.png");
}

.Mount_Body_Sheep-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-CottonCandyBlue.png");
}

.Mount_Body_Sheep-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-CottonCandyPink.png");
}

.Mount_Body_Sheep-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Desert.png");
}

.Mount_Body_Sheep-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Golden.png");
}

.Mount_Body_Sheep-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Red.png");
}

.Mount_Body_Sheep-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Shade.png");
}

.Mount_Body_Sheep-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Skeleton.png");
}

.Mount_Body_Sheep-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-White.png");
}

.Mount_Body_Sheep-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sheep-Zombie.png");
}

.Mount_Body_Slime-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Base.png");
}

.Mount_Body_Slime-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-CottonCandyBlue.png");
}

.Mount_Body_Slime-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-CottonCandyPink.png");
}

.Mount_Body_Slime-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Desert.png");
}

.Mount_Body_Slime-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Golden.png");
}

.Mount_Body_Slime-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Red.png");
}

.Mount_Body_Slime-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Shade.png");
}

.Mount_Body_Slime-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Skeleton.png");
}

.Mount_Body_Slime-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-White.png");
}

.Mount_Body_Slime-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Slime-Zombie.png");
}

.Mount_Body_Sloth-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Base.png");
}

.Mount_Body_Sloth-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-CottonCandyBlue.png");
}

.Mount_Body_Sloth-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-CottonCandyPink.png");
}

.Mount_Body_Sloth-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Desert.png");
}

.Mount_Body_Sloth-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Golden.png");
}

.Mount_Body_Sloth-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Red.png");
}

.Mount_Body_Sloth-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Shade.png");
}

.Mount_Body_Sloth-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Skeleton.png");
}

.Mount_Body_Sloth-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-White.png");
}

.Mount_Body_Sloth-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Sloth-Zombie.png");
}

.Mount_Body_Snail-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Base.png");
}

.Mount_Body_Snail-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-CottonCandyBlue.png");
}

.Mount_Body_Snail-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-CottonCandyPink.png");
}

.Mount_Body_Snail-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Desert.png");
}

.Mount_Body_Snail-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Golden.png");
}

.Mount_Body_Snail-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Red.png");
}

.Mount_Body_Snail-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Shade.png");
}

.Mount_Body_Snail-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Skeleton.png");
}

.Mount_Body_Snail-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-White.png");
}

.Mount_Body_Snail-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snail-Zombie.png");
}

.Mount_Body_Snake-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Base.png");
}

.Mount_Body_Snake-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-CottonCandyBlue.png");
}

.Mount_Body_Snake-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-CottonCandyPink.png");
}

.Mount_Body_Snake-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Desert.png");
}

.Mount_Body_Snake-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Golden.png");
}

.Mount_Body_Snake-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Red.png");
}

.Mount_Body_Snake-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Shade.png");
}

.Mount_Body_Snake-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Skeleton.png");
}

.Mount_Body_Snake-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-White.png");
}

.Mount_Body_Snake-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Snake-Zombie.png");
}

.Mount_Body_Spider-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Base.png");
}

.Mount_Body_Spider-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-CottonCandyBlue.png");
}

.Mount_Body_Spider-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-CottonCandyPink.png");
}

.Mount_Body_Spider-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Desert.png");
}

.Mount_Body_Spider-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Golden.png");
}

.Mount_Body_Spider-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Red.png");
}

.Mount_Body_Spider-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Shade.png");
}

.Mount_Body_Spider-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Skeleton.png");
}

.Mount_Body_Spider-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-White.png");
}

.Mount_Body_Spider-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Spider-Zombie.png");
}

.Mount_Body_Squirrel-Base {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Base.png");
}

.Mount_Body_Squirrel-CottonCandyBlue {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-CottonCandyBlue.png");
}

.Mount_Body_Squirrel-CottonCandyPink {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-CottonCandyPink.png");
}

.Mount_Body_Squirrel-Desert {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Desert.png");
}

.Mount_Body_Squirrel-Golden {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Golden.png");
}

.Mount_Body_Squirrel-Red {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Red.png");
}

.Mount_Body_Squirrel-Shade {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Shade.png");
}

.Mount_Body_Squirrel-Skeleton {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Skeleton.png");
}

.Mount_Body_Squirrel-White {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-White.png");
}

.Mount_Body_Squirrel-Zombie {
  width: 105px;
  height: 108px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Squirrel-Zombie.png");
}

.Mount_Body_TRex-Base {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Base.png");
}

.Mount_Body_TRex-CottonCandyBlue {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-CottonCandyBlue.png");
}

.Mount_Body_TRex-CottonCandyPink {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-CottonCandyPink.png");
}

.Mount_Body_TRex-Desert {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Desert.png");
}

.Mount_Body_TRex-Golden {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Golden.png");
}

.Mount_Body_TRex-Red {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Red.png");
}

.Mount_Body_TRex-Shade {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Shade.png");
}

.Mount_Body_TRex-Skeleton {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Skeleton.png");
}

.Mount_Body_TRex-White {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-White.png");
}

.Mount_Body_TRex-Zombie {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TRex-Zombie.png");
}

.Mount_Body_TigerCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Amber.png");
}

.Mount_Body_TigerCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Aquatic.png");
}

.Mount_Body_TigerCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Aurora.png");
}

.Mount_Body_TigerCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-AutumnLeaf.png");
}

.Mount_Body_TigerCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Base.png");
}

.Mount_Body_TigerCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-BirchBark.png");
}

.Mount_Body_TigerCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-BlackPearl.png");
}

.Mount_Body_TigerCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Bronze.png");
}

.Mount_Body_TigerCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Celestial.png");
}

.Mount_Body_TigerCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-CottonCandyBlue.png");
}

.Mount_Body_TigerCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-CottonCandyPink.png");
}

.Mount_Body_TigerCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Cupid.png");
}

.Mount_Body_TigerCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Desert.png");
}

.Mount_Body_TigerCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Ember.png");
}

.Mount_Body_TigerCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Fairy.png");
}

.Mount_Body_TigerCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Floral.png");
}

.Mount_Body_TigerCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Fluorite.png");
}

.Mount_Body_TigerCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Frost.png");
}

.Mount_Body_TigerCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Ghost.png");
}

.Mount_Body_TigerCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Glass.png");
}

.Mount_Body_TigerCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Glow.png");
}

.Mount_Body_TigerCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Golden.png");
}

.Mount_Body_TigerCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Holly.png");
}

.Mount_Body_TigerCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-IcySnow.png");
}

.Mount_Body_TigerCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Moonglow.png");
}

.Mount_Body_TigerCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-MossyStone.png");
}

.Mount_Body_TigerCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Onyx.png");
}

.Mount_Body_TigerCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Peppermint.png");
}

.Mount_Body_TigerCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-PinkMarble.png");
}

.Mount_Body_TigerCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-PolkaDot.png");
}

.Mount_Body_TigerCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Porcelain.png");
}

.Mount_Body_TigerCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Rainbow.png");
}

.Mount_Body_TigerCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Red.png");
}

.Mount_Body_TigerCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-RoseQuartz.png");
}

.Mount_Body_TigerCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-RoyalPurple.png");
}

.Mount_Body_TigerCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Ruby.png");
}

.Mount_Body_TigerCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-SandSculpture.png");
}

.Mount_Body_TigerCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Shade.png");
}

.Mount_Body_TigerCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Shadow.png");
}

.Mount_Body_TigerCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Shimmer.png");
}

.Mount_Body_TigerCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Silver.png");
}

.Mount_Body_TigerCub-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Skeleton.png");
}

.Mount_Body_TigerCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-SolarSystem.png");
}

.Mount_Body_TigerCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Spooky.png");
}

.Mount_Body_TigerCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-StainedGlass.png");
}

.Mount_Body_TigerCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-StarryNight.png");
}

.Mount_Body_TigerCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Sunset.png");
}

.Mount_Body_TigerCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Sunshine.png");
}

.Mount_Body_TigerCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Thunderstorm.png");
}

.Mount_Body_TigerCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Turquoise.png");
}

.Mount_Body_TigerCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Vampire.png");
}

.Mount_Body_TigerCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Watery.png");
}

.Mount_Body_TigerCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-White.png");
}

.Mount_Body_TigerCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Windup.png");
}

.Mount_Body_TigerCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_TigerCub-Zombie.png");
}

.Mount_Body_Treeling-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Base.png");
}

.Mount_Body_Treeling-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-CottonCandyBlue.png");
}

.Mount_Body_Treeling-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-CottonCandyPink.png");
}

.Mount_Body_Treeling-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Desert.png");
}

.Mount_Body_Treeling-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Golden.png");
}

.Mount_Body_Treeling-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Red.png");
}

.Mount_Body_Treeling-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Shade.png");
}

.Mount_Body_Treeling-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Skeleton.png");
}

.Mount_Body_Treeling-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-White.png");
}

.Mount_Body_Treeling-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Treeling-Zombie.png");
}

.Mount_Body_Triceratops-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Base.png");
}

.Mount_Body_Triceratops-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-CottonCandyBlue.png");
}

.Mount_Body_Triceratops-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-CottonCandyPink.png");
}

.Mount_Body_Triceratops-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Desert.png");
}

.Mount_Body_Triceratops-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Golden.png");
}

.Mount_Body_Triceratops-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Red.png");
}

.Mount_Body_Triceratops-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Shade.png");
}

.Mount_Body_Triceratops-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Skeleton.png");
}

.Mount_Body_Triceratops-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-White.png");
}

.Mount_Body_Triceratops-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Triceratops-Zombie.png");
}

.Mount_Body_Turkey-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turkey-Base.png");
}

.Mount_Body_Turkey-Gilded {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turkey-Gilded.png");
}

.Mount_Body_Turtle-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Base.png");
}

.Mount_Body_Turtle-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-CottonCandyBlue.png");
}

.Mount_Body_Turtle-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-CottonCandyPink.png");
}

.Mount_Body_Turtle-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Desert.png");
}

.Mount_Body_Turtle-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Golden.png");
}

.Mount_Body_Turtle-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Red.png");
}

.Mount_Body_Turtle-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Shade.png");
}

.Mount_Body_Turtle-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Skeleton.png");
}

.Mount_Body_Turtle-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-White.png");
}

.Mount_Body_Turtle-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Turtle-Zombie.png");
}

.Mount_Body_Unicorn-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Base.png");
}

.Mount_Body_Unicorn-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-CottonCandyBlue.png");
}

.Mount_Body_Unicorn-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-CottonCandyPink.png");
}

.Mount_Body_Unicorn-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Desert.png");
}

.Mount_Body_Unicorn-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Golden.png");
}

.Mount_Body_Unicorn-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Red.png");
}

.Mount_Body_Unicorn-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Shade.png");
}

.Mount_Body_Unicorn-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Skeleton.png");
}

.Mount_Body_Unicorn-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-White.png");
}

.Mount_Body_Unicorn-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Unicorn-Zombie.png");
}

.Mount_Body_Velociraptor-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Base.png");
}

.Mount_Body_Velociraptor-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-CottonCandyBlue.png");
}

.Mount_Body_Velociraptor-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-CottonCandyPink.png");
}

.Mount_Body_Velociraptor-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Desert.png");
}

.Mount_Body_Velociraptor-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Golden.png");
}

.Mount_Body_Velociraptor-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Red.png");
}

.Mount_Body_Velociraptor-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Shade.png");
}

.Mount_Body_Velociraptor-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Skeleton.png");
}

.Mount_Body_Velociraptor-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-White.png");
}

.Mount_Body_Velociraptor-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Velociraptor-Zombie.png");
}

.Mount_Body_Whale-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Base.png");
}

.Mount_Body_Whale-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-CottonCandyBlue.png");
}

.Mount_Body_Whale-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-CottonCandyPink.png");
}

.Mount_Body_Whale-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Desert.png");
}

.Mount_Body_Whale-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Golden.png");
}

.Mount_Body_Whale-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Red.png");
}

.Mount_Body_Whale-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Shade.png");
}

.Mount_Body_Whale-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Skeleton.png");
}

.Mount_Body_Whale-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-White.png");
}

.Mount_Body_Whale-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Whale-Zombie.png");
}

.Mount_Body_Wolf-Amber {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Amber.png");
}

.Mount_Body_Wolf-Aquatic {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Aquatic.png");
}

.Mount_Body_Wolf-Aurora {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Aurora.png");
}

.Mount_Body_Wolf-AutumnLeaf {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-AutumnLeaf.png");
}

.Mount_Body_Wolf-Base {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Base.png");
}

.Mount_Body_Wolf-BirchBark {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-BirchBark.png");
}

.Mount_Body_Wolf-BlackPearl {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-BlackPearl.png");
}

.Mount_Body_Wolf-Bronze {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Bronze.png");
}

.Mount_Body_Wolf-Celestial {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Celestial.png");
}

.Mount_Body_Wolf-CottonCandyBlue {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-CottonCandyBlue.png");
}

.Mount_Body_Wolf-CottonCandyPink {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-CottonCandyPink.png");
}

.Mount_Body_Wolf-Cupid {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Cupid.png");
}

.Mount_Body_Wolf-Desert {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Desert.png");
}

.Mount_Body_Wolf-Ember {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Ember.png");
}

.Mount_Body_Wolf-Fairy {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Fairy.png");
}

.Mount_Body_Wolf-Floral {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Floral.png");
}

.Mount_Body_Wolf-Fluorite {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Fluorite.png");
}

.Mount_Body_Wolf-Frost {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Frost.png");
}

.Mount_Body_Wolf-Ghost {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Ghost.png");
}

.Mount_Body_Wolf-Glass {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Glass.png");
}

.Mount_Body_Wolf-Glow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Glow.png");
}

.Mount_Body_Wolf-Golden {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Golden.png");
}

.Mount_Body_Wolf-Holly {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Holly.png");
}

.Mount_Body_Wolf-IcySnow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-IcySnow.png");
}

.Mount_Body_Wolf-Moonglow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Moonglow.png");
}

.Mount_Body_Wolf-MossyStone {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-MossyStone.png");
}

.Mount_Body_Wolf-Onyx {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Onyx.png");
}

.Mount_Body_Wolf-Peppermint {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Peppermint.png");
}

.Mount_Body_Wolf-PinkMarble {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-PinkMarble.png");
}

.Mount_Body_Wolf-PolkaDot {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-PolkaDot.png");
}

.Mount_Body_Wolf-Porcelain {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Porcelain.png");
}

.Mount_Body_Wolf-Rainbow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Rainbow.png");
}

.Mount_Body_Wolf-Red {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Red.png");
}

.Mount_Body_Wolf-RoseQuartz {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-RoseQuartz.png");
}

.Mount_Body_Wolf-RoyalPurple {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-RoyalPurple.png");
}

.Mount_Body_Wolf-Ruby {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Ruby.png");
}

.Mount_Body_Wolf-SandSculpture {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-SandSculpture.png");
}

.Mount_Body_Wolf-Shade {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Shade.png");
}

.Mount_Body_Wolf-Shadow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Shadow.png");
}

.Mount_Body_Wolf-Shimmer {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Shimmer.png");
}

.Mount_Body_Wolf-Silver {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Silver.png");
}

.Mount_Body_Wolf-Skeleton {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Skeleton.png");
}

.Mount_Body_Wolf-SolarSystem {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-SolarSystem.png");
}

.Mount_Body_Wolf-Spooky {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Spooky.png");
}

.Mount_Body_Wolf-StainedGlass {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-StainedGlass.png");
}

.Mount_Body_Wolf-StarryNight {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-StarryNight.png");
}

.Mount_Body_Wolf-Sunset {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Sunset.png");
}

.Mount_Body_Wolf-Sunshine {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Sunshine.png");
}

.Mount_Body_Wolf-Thunderstorm {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Thunderstorm.png");
}

.Mount_Body_Wolf-Turquoise {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Turquoise.png");
}

.Mount_Body_Wolf-Vampire {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Vampire.png");
}

.Mount_Body_Wolf-Watery {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Watery.png");
}

.Mount_Body_Wolf-White {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-White.png");
}

.Mount_Body_Wolf-Windup {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Windup.png");
}

.Mount_Body_Wolf-Zombie {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Wolf-Zombie.png");
}

.Mount_Body_Yarn-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Base.png");
}

.Mount_Body_Yarn-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-CottonCandyBlue.png");
}

.Mount_Body_Yarn-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-CottonCandyPink.png");
}

.Mount_Body_Yarn-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Desert.png");
}

.Mount_Body_Yarn-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Golden.png");
}

.Mount_Body_Yarn-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Red.png");
}

.Mount_Body_Yarn-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Shade.png");
}

.Mount_Body_Yarn-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Skeleton.png");
}

.Mount_Body_Yarn-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-White.png");
}

.Mount_Body_Yarn-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Body_Yarn-Zombie.png");
}

.Mount_Head_Alligator-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Base.png");
}

.Mount_Head_Alligator-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-CottonCandyBlue.png");
}

.Mount_Head_Alligator-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-CottonCandyPink.png");
}

.Mount_Head_Alligator-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Desert.png");
}

.Mount_Head_Alligator-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Golden.png");
}

.Mount_Head_Alligator-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Red.png");
}

.Mount_Head_Alligator-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Shade.png");
}

.Mount_Head_Alligator-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Skeleton.png");
}

.Mount_Head_Alligator-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-White.png");
}

.Mount_Head_Alligator-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Alligator-Zombie.png");
}

.Mount_Head_Armadillo-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Base.png");
}

.Mount_Head_Armadillo-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-CottonCandyBlue.png");
}

.Mount_Head_Armadillo-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-CottonCandyPink.png");
}

.Mount_Head_Armadillo-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Desert.png");
}

.Mount_Head_Armadillo-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Golden.png");
}

.Mount_Head_Armadillo-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Red.png");
}

.Mount_Head_Armadillo-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Shade.png");
}

.Mount_Head_Armadillo-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Skeleton.png");
}

.Mount_Head_Armadillo-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-White.png");
}

.Mount_Head_Armadillo-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Armadillo-Zombie.png");
}

.Mount_Head_Axolotl-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Base.png");
}

.Mount_Head_Axolotl-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-CottonCandyBlue.png");
}

.Mount_Head_Axolotl-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-CottonCandyPink.png");
}

.Mount_Head_Axolotl-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Desert.png");
}

.Mount_Head_Axolotl-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Golden.png");
}

.Mount_Head_Axolotl-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Red.png");
}

.Mount_Head_Axolotl-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Shade.png");
}

.Mount_Head_Axolotl-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Skeleton.png");
}

.Mount_Head_Axolotl-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-White.png");
}

.Mount_Head_Axolotl-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Axolotl-Zombie.png");
}

.Mount_Head_Badger-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Base.png");
}

.Mount_Head_Badger-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-CottonCandyBlue.png");
}

.Mount_Head_Badger-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-CottonCandyPink.png");
}

.Mount_Head_Badger-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Desert.png");
}

.Mount_Head_Badger-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Golden.png");
}

.Mount_Head_Badger-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Red.png");
}

.Mount_Head_Badger-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Shade.png");
}

.Mount_Head_Badger-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Skeleton.png");
}

.Mount_Head_Badger-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-White.png");
}

.Mount_Head_Badger-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Badger-Zombie.png");
}

.Mount_Head_BearCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Amber.png");
}

.Mount_Head_BearCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Aquatic.png");
}

.Mount_Head_BearCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Aurora.png");
}

.Mount_Head_BearCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-AutumnLeaf.png");
}

.Mount_Head_BearCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Base.png");
}

.Mount_Head_BearCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-BirchBark.png");
}

.Mount_Head_BearCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-BlackPearl.png");
}

.Mount_Head_BearCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Bronze.png");
}

.Mount_Head_BearCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Celestial.png");
}

.Mount_Head_BearCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-CottonCandyBlue.png");
}

.Mount_Head_BearCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-CottonCandyPink.png");
}

.Mount_Head_BearCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Cupid.png");
}

.Mount_Head_BearCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Desert.png");
}

.Mount_Head_BearCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Ember.png");
}

.Mount_Head_BearCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Fairy.png");
}

.Mount_Head_BearCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Floral.png");
}

.Mount_Head_BearCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Fluorite.png");
}

.Mount_Head_BearCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Frost.png");
}

.Mount_Head_BearCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Ghost.png");
}

.Mount_Head_BearCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Glass.png");
}

.Mount_Head_BearCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Glow.png");
}

.Mount_Head_BearCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Golden.png");
}

.Mount_Head_BearCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Holly.png");
}

.Mount_Head_BearCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-IcySnow.png");
}

.Mount_Head_BearCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Moonglow.png");
}

.Mount_Head_BearCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-MossyStone.png");
}

.Mount_Head_BearCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Onyx.png");
}

.Mount_Head_BearCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Peppermint.png");
}

.Mount_Head_BearCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-PinkMarble.png");
}

.Mount_Head_BearCub-Polar {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Polar.png");
}

.Mount_Head_BearCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-PolkaDot.png");
}

.Mount_Head_BearCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Porcelain.png");
}

.Mount_Head_BearCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Rainbow.png");
}

.Mount_Head_BearCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Red.png");
}

.Mount_Head_BearCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-RoseQuartz.png");
}

.Mount_Head_BearCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-RoyalPurple.png");
}

.Mount_Head_BearCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Ruby.png");
}

.Mount_Head_BearCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-SandSculpture.png");
}

.Mount_Head_BearCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Shade.png");
}

.Mount_Head_BearCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Shadow.png");
}

.Mount_Head_BearCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Shimmer.png");
}

.Mount_Head_BearCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Silver.png");
}

.Mount_Head_BearCub-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Skeleton.png");
}

.Mount_Head_BearCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-SolarSystem.png");
}

.Mount_Head_BearCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Spooky.png");
}

.Mount_Head_BearCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-StainedGlass.png");
}

.Mount_Head_BearCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-StarryNight.png");
}

.Mount_Head_BearCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Sunset.png");
}

.Mount_Head_BearCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Sunshine.png");
}

.Mount_Head_BearCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Thunderstorm.png");
}

.Mount_Head_BearCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Turquoise.png");
}

.Mount_Head_BearCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Vampire.png");
}

.Mount_Head_BearCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Watery.png");
}

.Mount_Head_BearCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-White.png");
}

.Mount_Head_BearCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Windup.png");
}

.Mount_Head_BearCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_BearCub-Zombie.png");
}

.Mount_Head_Beetle-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Base.png");
}

.Mount_Head_Beetle-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-CottonCandyBlue.png");
}

.Mount_Head_Beetle-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-CottonCandyPink.png");
}

.Mount_Head_Beetle-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Desert.png");
}

.Mount_Head_Beetle-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Golden.png");
}

.Mount_Head_Beetle-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Red.png");
}

.Mount_Head_Beetle-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Shade.png");
}

.Mount_Head_Beetle-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Skeleton.png");
}

.Mount_Head_Beetle-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-White.png");
}

.Mount_Head_Beetle-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Beetle-Zombie.png");
}

.Mount_Head_Bunny-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Base.png");
}

.Mount_Head_Bunny-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-CottonCandyBlue.png");
}

.Mount_Head_Bunny-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-CottonCandyPink.png");
}

.Mount_Head_Bunny-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Desert.png");
}

.Mount_Head_Bunny-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Golden.png");
}

.Mount_Head_Bunny-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Red.png");
}

.Mount_Head_Bunny-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Shade.png");
}

.Mount_Head_Bunny-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Skeleton.png");
}

.Mount_Head_Bunny-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-White.png");
}

.Mount_Head_Bunny-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Bunny-Zombie.png");
}

.Mount_Head_Butterfly-Base {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Base.png");
}

.Mount_Head_Butterfly-CottonCandyBlue {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-CottonCandyBlue.png");
}

.Mount_Head_Butterfly-CottonCandyPink {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-CottonCandyPink.png");
}

.Mount_Head_Butterfly-Desert {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Desert.png");
}

.Mount_Head_Butterfly-Golden {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Golden.png");
}

.Mount_Head_Butterfly-Red {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Red.png");
}

.Mount_Head_Butterfly-Shade {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Shade.png");
}

.Mount_Head_Butterfly-Skeleton {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Skeleton.png");
}

.Mount_Head_Butterfly-White {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-White.png");
}

.Mount_Head_Butterfly-Zombie {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Butterfly-Zombie.png");
}

.Mount_Head_Cactus-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Amber.png");
}

.Mount_Head_Cactus-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Aquatic.png");
}

.Mount_Head_Cactus-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Aurora.png");
}

.Mount_Head_Cactus-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-AutumnLeaf.png");
}

.Mount_Head_Cactus-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Base.png");
}

.Mount_Head_Cactus-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-BirchBark.png");
}

.Mount_Head_Cactus-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-BlackPearl.png");
}

.Mount_Head_Cactus-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Bronze.png");
}

.Mount_Head_Cactus-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Celestial.png");
}

.Mount_Head_Cactus-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-CottonCandyBlue.png");
}

.Mount_Head_Cactus-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-CottonCandyPink.png");
}

.Mount_Head_Cactus-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Cupid.png");
}

.Mount_Head_Cactus-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Desert.png");
}

.Mount_Head_Cactus-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Ember.png");
}

.Mount_Head_Cactus-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Fairy.png");
}

.Mount_Head_Cactus-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Floral.png");
}

.Mount_Head_Cactus-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Fluorite.png");
}

.Mount_Head_Cactus-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Frost.png");
}

.Mount_Head_Cactus-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Ghost.png");
}

.Mount_Head_Cactus-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Glass.png");
}

.Mount_Head_Cactus-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Glow.png");
}

.Mount_Head_Cactus-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Golden.png");
}

.Mount_Head_Cactus-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Holly.png");
}

.Mount_Head_Cactus-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-IcySnow.png");
}

.Mount_Head_Cactus-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Moonglow.png");
}

.Mount_Head_Cactus-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-MossyStone.png");
}

.Mount_Head_Cactus-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Onyx.png");
}

.Mount_Head_Cactus-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Peppermint.png");
}

.Mount_Head_Cactus-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-PinkMarble.png");
}

.Mount_Head_Cactus-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-PolkaDot.png");
}

.Mount_Head_Cactus-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Porcelain.png");
}

.Mount_Head_Cactus-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Rainbow.png");
}

.Mount_Head_Cactus-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Red.png");
}

.Mount_Head_Cactus-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-RoseQuartz.png");
}

.Mount_Head_Cactus-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-RoyalPurple.png");
}

.Mount_Head_Cactus-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Ruby.png");
}

.Mount_Head_Cactus-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-SandSculpture.png");
}

.Mount_Head_Cactus-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Shade.png");
}

.Mount_Head_Cactus-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Shadow.png");
}

.Mount_Head_Cactus-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Shimmer.png");
}

.Mount_Head_Cactus-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Silver.png");
}

.Mount_Head_Cactus-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Skeleton.png");
}

.Mount_Head_Cactus-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-SolarSystem.png");
}

.Mount_Head_Cactus-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Spooky.png");
}

.Mount_Head_Cactus-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-StainedGlass.png");
}

.Mount_Head_Cactus-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-StarryNight.png");
}

.Mount_Head_Cactus-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Sunset.png");
}

.Mount_Head_Cactus-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Sunshine.png");
}

.Mount_Head_Cactus-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Thunderstorm.png");
}

.Mount_Head_Cactus-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Turquoise.png");
}

.Mount_Head_Cactus-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Vampire.png");
}

.Mount_Head_Cactus-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Watery.png");
}

.Mount_Head_Cactus-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-White.png");
}

.Mount_Head_Cactus-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Windup.png");
}

.Mount_Head_Cactus-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cactus-Zombie.png");
}

.Mount_Head_Cheetah-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Base.png");
}

.Mount_Head_Cheetah-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-CottonCandyBlue.png");
}

.Mount_Head_Cheetah-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-CottonCandyPink.png");
}

.Mount_Head_Cheetah-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Desert.png");
}

.Mount_Head_Cheetah-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Golden.png");
}

.Mount_Head_Cheetah-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Red.png");
}

.Mount_Head_Cheetah-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Shade.png");
}

.Mount_Head_Cheetah-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Skeleton.png");
}

.Mount_Head_Cheetah-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-White.png");
}

.Mount_Head_Cheetah-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cheetah-Zombie.png");
}

.Mount_Head_Cow-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Base.png");
}

.Mount_Head_Cow-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-CottonCandyBlue.png");
}

.Mount_Head_Cow-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-CottonCandyPink.png");
}

.Mount_Head_Cow-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Desert.png");
}

.Mount_Head_Cow-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Golden.png");
}

.Mount_Head_Cow-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Red.png");
}

.Mount_Head_Cow-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Shade.png");
}

.Mount_Head_Cow-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Skeleton.png");
}

.Mount_Head_Cow-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-White.png");
}

.Mount_Head_Cow-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cow-Zombie.png");
}

.Mount_Head_Cuttlefish-Base {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Base.png");
}

.Mount_Head_Cuttlefish-CottonCandyBlue {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-CottonCandyBlue.png");
}

.Mount_Head_Cuttlefish-CottonCandyPink {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-CottonCandyPink.png");
}

.Mount_Head_Cuttlefish-Desert {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Desert.png");
}

.Mount_Head_Cuttlefish-Golden {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Golden.png");
}

.Mount_Head_Cuttlefish-Red {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Red.png");
}

.Mount_Head_Cuttlefish-Shade {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Shade.png");
}

.Mount_Head_Cuttlefish-Skeleton {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Skeleton.png");
}

.Mount_Head_Cuttlefish-White {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-White.png");
}

.Mount_Head_Cuttlefish-Zombie {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Cuttlefish-Zombie.png");
}

.Mount_Head_Deer-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Base.png");
}

.Mount_Head_Deer-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-CottonCandyBlue.png");
}

.Mount_Head_Deer-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-CottonCandyPink.png");
}

.Mount_Head_Deer-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Desert.png");
}

.Mount_Head_Deer-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Golden.png");
}

.Mount_Head_Deer-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Red.png");
}

.Mount_Head_Deer-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Shade.png");
}

.Mount_Head_Deer-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Skeleton.png");
}

.Mount_Head_Deer-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-White.png");
}

.Mount_Head_Deer-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Deer-Zombie.png");
}

.Mount_Head_Dolphin-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Base.png");
}

.Mount_Head_Dolphin-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-CottonCandyBlue.png");
}

.Mount_Head_Dolphin-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-CottonCandyPink.png");
}

.Mount_Head_Dolphin-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Desert.png");
}

.Mount_Head_Dolphin-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Golden.png");
}

.Mount_Head_Dolphin-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Red.png");
}

.Mount_Head_Dolphin-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Shade.png");
}

.Mount_Head_Dolphin-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Skeleton.png");
}

.Mount_Head_Dolphin-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-White.png");
}

.Mount_Head_Dolphin-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dolphin-Zombie.png");
}

.Mount_Head_Dragon-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Amber.png");
}

.Mount_Head_Dragon-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Aquatic.png");
}

.Mount_Head_Dragon-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Aurora.png");
}

.Mount_Head_Dragon-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-AutumnLeaf.png");
}

.Mount_Head_Dragon-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Base.png");
}

.Mount_Head_Dragon-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-BirchBark.png");
}

.Mount_Head_Dragon-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-BlackPearl.png");
}

.Mount_Head_Dragon-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Bronze.png");
}

.Mount_Head_Dragon-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Celestial.png");
}

.Mount_Head_Dragon-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-CottonCandyBlue.png");
}

.Mount_Head_Dragon-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-CottonCandyPink.png");
}

.Mount_Head_Dragon-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Cupid.png");
}

.Mount_Head_Dragon-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Desert.png");
}

.Mount_Head_Dragon-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Ember.png");
}

.Mount_Head_Dragon-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Fairy.png");
}

.Mount_Head_Dragon-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Floral.png");
}

.Mount_Head_Dragon-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Fluorite.png");
}

.Mount_Head_Dragon-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Frost.png");
}

.Mount_Head_Dragon-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Ghost.png");
}

.Mount_Head_Dragon-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Glass.png");
}

.Mount_Head_Dragon-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Glow.png");
}

.Mount_Head_Dragon-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Golden.png");
}

.Mount_Head_Dragon-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Holly.png");
}

.Mount_Head_Dragon-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-IcySnow.png");
}

.Mount_Head_Dragon-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Moonglow.png");
}

.Mount_Head_Dragon-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-MossyStone.png");
}

.Mount_Head_Dragon-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Onyx.png");
}

.Mount_Head_Dragon-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Peppermint.png");
}

.Mount_Head_Dragon-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-PinkMarble.png");
}

.Mount_Head_Dragon-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-PolkaDot.png");
}

.Mount_Head_Dragon-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Porcelain.png");
}

.Mount_Head_Dragon-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Rainbow.png");
}

.Mount_Head_Dragon-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Red.png");
}

.Mount_Head_Dragon-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-RoseQuartz.png");
}

.Mount_Head_Dragon-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-RoyalPurple.png");
}

.Mount_Head_Dragon-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Ruby.png");
}

.Mount_Head_Dragon-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-SandSculpture.png");
}

.Mount_Head_Dragon-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Shade.png");
}

.Mount_Head_Dragon-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Shadow.png");
}

.Mount_Head_Dragon-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Shimmer.png");
}

.Mount_Head_Dragon-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Silver.png");
}

.Mount_Head_Dragon-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Skeleton.png");
}

.Mount_Head_Dragon-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-SolarSystem.png");
}

.Mount_Head_Dragon-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Spooky.png");
}

.Mount_Head_Dragon-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-StainedGlass.png");
}

.Mount_Head_Dragon-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-StarryNight.png");
}

.Mount_Head_Dragon-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Sunset.png");
}

.Mount_Head_Dragon-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Sunshine.png");
}

.Mount_Head_Dragon-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Thunderstorm.png");
}

.Mount_Head_Dragon-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Turquoise.png");
}

.Mount_Head_Dragon-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Vampire.png");
}

.Mount_Head_Dragon-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Watery.png");
}

.Mount_Head_Dragon-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-White.png");
}

.Mount_Head_Dragon-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Windup.png");
}

.Mount_Head_Dragon-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Dragon-Zombie.png");
}

.Mount_Head_Egg-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Base.png");
}

.Mount_Head_Egg-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-CottonCandyBlue.png");
}

.Mount_Head_Egg-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-CottonCandyPink.png");
}

.Mount_Head_Egg-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Desert.png");
}

.Mount_Head_Egg-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Golden.png");
}

.Mount_Head_Egg-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Red.png");
}

.Mount_Head_Egg-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Shade.png");
}

.Mount_Head_Egg-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Skeleton.png");
}

.Mount_Head_Egg-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-White.png");
}

.Mount_Head_Egg-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Egg-Zombie.png");
}

.Mount_Head_Falcon-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Base.png");
}

.Mount_Head_Falcon-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-CottonCandyBlue.png");
}

.Mount_Head_Falcon-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-CottonCandyPink.png");
}

.Mount_Head_Falcon-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Desert.png");
}

.Mount_Head_Falcon-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Golden.png");
}

.Mount_Head_Falcon-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Red.png");
}

.Mount_Head_Falcon-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Shade.png");
}

.Mount_Head_Falcon-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Skeleton.png");
}

.Mount_Head_Falcon-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-White.png");
}

.Mount_Head_Falcon-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Falcon-Zombie.png");
}

.Mount_Head_Ferret-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Base.png");
}

.Mount_Head_Ferret-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-CottonCandyBlue.png");
}

.Mount_Head_Ferret-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-CottonCandyPink.png");
}

.Mount_Head_Ferret-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Desert.png");
}

.Mount_Head_Ferret-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Golden.png");
}

.Mount_Head_Ferret-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Red.png");
}

.Mount_Head_Ferret-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Shade.png");
}

.Mount_Head_Ferret-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Skeleton.png");
}

.Mount_Head_Ferret-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-White.png");
}

.Mount_Head_Ferret-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Ferret-Zombie.png");
}

.Mount_Head_FlyingPig-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Amber.png");
}

.Mount_Head_FlyingPig-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Aquatic.png");
}

.Mount_Head_FlyingPig-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Aurora.png");
}

.Mount_Head_FlyingPig-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-AutumnLeaf.png");
}

.Mount_Head_FlyingPig-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Base.png");
}

.Mount_Head_FlyingPig-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-BirchBark.png");
}

.Mount_Head_FlyingPig-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-BlackPearl.png");
}

.Mount_Head_FlyingPig-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Bronze.png");
}

.Mount_Head_FlyingPig-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Celestial.png");
}

.Mount_Head_FlyingPig-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-CottonCandyBlue.png");
}

.Mount_Head_FlyingPig-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-CottonCandyPink.png");
}

.Mount_Head_FlyingPig-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Cupid.png");
}

.Mount_Head_FlyingPig-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Desert.png");
}

.Mount_Head_FlyingPig-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Ember.png");
}

.Mount_Head_FlyingPig-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Fairy.png");
}

.Mount_Head_FlyingPig-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Floral.png");
}

.Mount_Head_FlyingPig-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Fluorite.png");
}

.Mount_Head_FlyingPig-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Frost.png");
}

.Mount_Head_FlyingPig-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Ghost.png");
}

.Mount_Head_FlyingPig-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Glass.png");
}

.Mount_Head_FlyingPig-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Glow.png");
}

.Mount_Head_FlyingPig-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Golden.png");
}

.Mount_Head_FlyingPig-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Holly.png");
}

.Mount_Head_FlyingPig-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-IcySnow.png");
}

.Mount_Head_FlyingPig-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Moonglow.png");
}

.Mount_Head_FlyingPig-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-MossyStone.png");
}

.Mount_Head_FlyingPig-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Onyx.png");
}

.Mount_Head_FlyingPig-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Peppermint.png");
}

.Mount_Head_FlyingPig-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-PinkMarble.png");
}

.Mount_Head_FlyingPig-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-PolkaDot.png");
}

.Mount_Head_FlyingPig-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Porcelain.png");
}

.Mount_Head_FlyingPig-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Rainbow.png");
}

.Mount_Head_FlyingPig-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Red.png");
}

.Mount_Head_FlyingPig-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-RoseQuartz.png");
}

.Mount_Head_FlyingPig-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-RoyalPurple.png");
}

.Mount_Head_FlyingPig-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Ruby.png");
}

.Mount_Head_FlyingPig-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-SandSculpture.png");
}

.Mount_Head_FlyingPig-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Shade.png");
}

.Mount_Head_FlyingPig-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Shadow.png");
}

.Mount_Head_FlyingPig-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Shimmer.png");
}

.Mount_Head_FlyingPig-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Silver.png");
}

.Mount_Head_FlyingPig-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Skeleton.png");
}

.Mount_Head_FlyingPig-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-SolarSystem.png");
}

.Mount_Head_FlyingPig-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Spooky.png");
}

.Mount_Head_FlyingPig-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-StainedGlass.png");
}

.Mount_Head_FlyingPig-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-StarryNight.png");
}

.Mount_Head_FlyingPig-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Sunset.png");
}

.Mount_Head_FlyingPig-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Sunshine.png");
}

.Mount_Head_FlyingPig-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Thunderstorm.png");
}

.Mount_Head_FlyingPig-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Turquoise.png");
}

.Mount_Head_FlyingPig-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Vampire.png");
}

.Mount_Head_FlyingPig-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Watery.png");
}

.Mount_Head_FlyingPig-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-White.png");
}

.Mount_Head_FlyingPig-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Windup.png");
}

.Mount_Head_FlyingPig-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_FlyingPig-Zombie.png");
}

.Mount_Head_Fox-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Amber.png");
}

.Mount_Head_Fox-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Aquatic.png");
}

.Mount_Head_Fox-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Aurora.png");
}

.Mount_Head_Fox-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-AutumnLeaf.png");
}

.Mount_Head_Fox-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Base.png");
}

.Mount_Head_Fox-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-BirchBark.png");
}

.Mount_Head_Fox-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-BlackPearl.png");
}

.Mount_Head_Fox-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Bronze.png");
}

.Mount_Head_Fox-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Celestial.png");
}

.Mount_Head_Fox-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-CottonCandyBlue.png");
}

.Mount_Head_Fox-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-CottonCandyPink.png");
}

.Mount_Head_Fox-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Cupid.png");
}

.Mount_Head_Fox-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Desert.png");
}

.Mount_Head_Fox-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Ember.png");
}

.Mount_Head_Fox-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Fairy.png");
}

.Mount_Head_Fox-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Floral.png");
}

.Mount_Head_Fox-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Fluorite.png");
}

.Mount_Head_Fox-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Frost.png");
}

.Mount_Head_Fox-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Ghost.png");
}

.Mount_Head_Fox-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Glass.png");
}

.Mount_Head_Fox-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Glow.png");
}

.Mount_Head_Fox-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Golden.png");
}

.Mount_Head_Fox-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Holly.png");
}

.Mount_Head_Fox-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-IcySnow.png");
}

.Mount_Head_Fox-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Moonglow.png");
}

.Mount_Head_Fox-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-MossyStone.png");
}

.Mount_Head_Fox-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Onyx.png");
}

.Mount_Head_Fox-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Peppermint.png");
}

.Mount_Head_Fox-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-PinkMarble.png");
}

.Mount_Head_Fox-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-PolkaDot.png");
}

.Mount_Head_Fox-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Porcelain.png");
}

.Mount_Head_Fox-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Rainbow.png");
}

.Mount_Head_Fox-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Red.png");
}

.Mount_Head_Fox-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-RoseQuartz.png");
}

.Mount_Head_Fox-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-RoyalPurple.png");
}

.Mount_Head_Fox-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Ruby.png");
}

.Mount_Head_Fox-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-SandSculpture.png");
}

.Mount_Head_Fox-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Shade.png");
}

.Mount_Head_Fox-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Shadow.png");
}

.Mount_Head_Fox-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Shimmer.png");
}

.Mount_Head_Fox-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Silver.png");
}

.Mount_Head_Fox-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Skeleton.png");
}

.Mount_Head_Fox-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-SolarSystem.png");
}

.Mount_Head_Fox-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Spooky.png");
}

.Mount_Head_Fox-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-StainedGlass.png");
}

.Mount_Head_Fox-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-StarryNight.png");
}

.Mount_Head_Fox-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Sunset.png");
}

.Mount_Head_Fox-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Sunshine.png");
}

.Mount_Head_Fox-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Thunderstorm.png");
}

.Mount_Head_Fox-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Turquoise.png");
}

.Mount_Head_Fox-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Vampire.png");
}

.Mount_Head_Fox-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Watery.png");
}

.Mount_Head_Fox-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-White.png");
}

.Mount_Head_Fox-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Windup.png");
}

.Mount_Head_Fox-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Fox-Zombie.png");
}

.Mount_Head_Frog-Base {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Base.png");
}

.Mount_Head_Frog-CottonCandyBlue {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-CottonCandyBlue.png");
}

.Mount_Head_Frog-CottonCandyPink {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-CottonCandyPink.png");
}

.Mount_Head_Frog-Desert {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Desert.png");
}

.Mount_Head_Frog-Golden {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Golden.png");
}

.Mount_Head_Frog-Red {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Red.png");
}

.Mount_Head_Frog-Shade {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Shade.png");
}

.Mount_Head_Frog-Skeleton {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Skeleton.png");
}

.Mount_Head_Frog-White {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-White.png");
}

.Mount_Head_Frog-Zombie {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Frog-Zombie.png");
}

.Mount_Head_Gryphon-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Base.png");
}

.Mount_Head_Gryphon-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-CottonCandyBlue.png");
}

.Mount_Head_Gryphon-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-CottonCandyPink.png");
}

.Mount_Head_Gryphon-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Desert.png");
}

.Mount_Head_Gryphon-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Golden.png");
}

.Mount_Head_Gryphon-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Red.png");
}

.Mount_Head_Gryphon-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-RoyalPurple.png");
}

.Mount_Head_Gryphon-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Shade.png");
}

.Mount_Head_Gryphon-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Skeleton.png");
}

.Mount_Head_Gryphon-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-White.png");
}

.Mount_Head_Gryphon-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Gryphon-Zombie.png");
}

.Mount_Head_GuineaPig-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Base.png");
}

.Mount_Head_GuineaPig-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-CottonCandyBlue.png");
}

.Mount_Head_GuineaPig-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-CottonCandyPink.png");
}

.Mount_Head_GuineaPig-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Desert.png");
}

.Mount_Head_GuineaPig-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Golden.png");
}

.Mount_Head_GuineaPig-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Red.png");
}

.Mount_Head_GuineaPig-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Shade.png");
}

.Mount_Head_GuineaPig-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Skeleton.png");
}

.Mount_Head_GuineaPig-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-White.png");
}

.Mount_Head_GuineaPig-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_GuineaPig-Zombie.png");
}

.Mount_Head_Hedgehog-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Base.png");
}

.Mount_Head_Hedgehog-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-CottonCandyBlue.png");
}

.Mount_Head_Hedgehog-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-CottonCandyPink.png");
}

.Mount_Head_Hedgehog-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Desert.png");
}

.Mount_Head_Hedgehog-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Golden.png");
}

.Mount_Head_Hedgehog-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Red.png");
}

.Mount_Head_Hedgehog-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Shade.png");
}

.Mount_Head_Hedgehog-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Skeleton.png");
}

.Mount_Head_Hedgehog-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-White.png");
}

.Mount_Head_Hedgehog-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hedgehog-Zombie.png");
}

.Mount_Head_Hippo-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Base.png");
}

.Mount_Head_Hippo-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-CottonCandyBlue.png");
}

.Mount_Head_Hippo-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-CottonCandyPink.png");
}

.Mount_Head_Hippo-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Desert.png");
}

.Mount_Head_Hippo-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Golden.png");
}

.Mount_Head_Hippo-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Red.png");
}

.Mount_Head_Hippo-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Shade.png");
}

.Mount_Head_Hippo-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Skeleton.png");
}

.Mount_Head_Hippo-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-White.png");
}

.Mount_Head_Hippo-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippo-Zombie.png");
}

.Mount_Head_Hippogriff-Hopeful {
  width: 105px;
  height: 111px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Hippogriff-Hopeful.png");
}

.Mount_Head_Horse-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Base.png");
}

.Mount_Head_Horse-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-CottonCandyBlue.png");
}

.Mount_Head_Horse-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-CottonCandyPink.png");
}

.Mount_Head_Horse-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Desert.png");
}

.Mount_Head_Horse-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Golden.png");
}

.Mount_Head_Horse-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Red.png");
}

.Mount_Head_Horse-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Shade.png");
}

.Mount_Head_Horse-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Skeleton.png");
}

.Mount_Head_Horse-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-White.png");
}

.Mount_Head_Horse-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Horse-Zombie.png");
}

.Mount_Head_JackOLantern-Base {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-Base.png");
}

.Mount_Head_JackOLantern-Ghost {
  width: 90px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-Ghost.png");
}

.Mount_Head_JackOLantern-Glow {
  width: 93px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-Glow.png");
}

.Mount_Head_JackOLantern-RoyalPurple {
  width: 93px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_JackOLantern-RoyalPurple.png");
}

.Mount_Head_Jackalope-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Jackalope-RoyalPurple.png");
}

.Mount_Head_Kangaroo-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Base.png");
}

.Mount_Head_Kangaroo-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-CottonCandyBlue.png");
}

.Mount_Head_Kangaroo-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-CottonCandyPink.png");
}

.Mount_Head_Kangaroo-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Desert.png");
}

.Mount_Head_Kangaroo-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Golden.png");
}

.Mount_Head_Kangaroo-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Red.png");
}

.Mount_Head_Kangaroo-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Shade.png");
}

.Mount_Head_Kangaroo-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Skeleton.png");
}

.Mount_Head_Kangaroo-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-White.png");
}

.Mount_Head_Kangaroo-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Kangaroo-Zombie.png");
}

.Mount_Head_LionCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Amber.png");
}

.Mount_Head_LionCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Aquatic.png");
}

.Mount_Head_LionCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Aurora.png");
}

.Mount_Head_LionCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-AutumnLeaf.png");
}

.Mount_Head_LionCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Base.png");
}

.Mount_Head_LionCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-BirchBark.png");
}

.Mount_Head_LionCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-BlackPearl.png");
}

.Mount_Head_LionCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Bronze.png");
}

.Mount_Head_LionCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Celestial.png");
}

.Mount_Head_LionCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-CottonCandyBlue.png");
}

.Mount_Head_LionCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-CottonCandyPink.png");
}

.Mount_Head_LionCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Cupid.png");
}

.Mount_Head_LionCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Desert.png");
}

.Mount_Head_LionCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ember.png");
}

.Mount_Head_LionCub-Ethereal {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ethereal.png");
}

.Mount_Head_LionCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Fairy.png");
}

.Mount_Head_LionCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Floral.png");
}

.Mount_Head_LionCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Fluorite.png");
}

.Mount_Head_LionCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Frost.png");
}

.Mount_Head_LionCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ghost.png");
}

.Mount_Head_LionCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Glass.png");
}

.Mount_Head_LionCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Glow.png");
}

.Mount_Head_LionCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Golden.png");
}

.Mount_Head_LionCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Holly.png");
}

.Mount_Head_LionCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-IcySnow.png");
}

.Mount_Head_LionCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Moonglow.png");
}

.Mount_Head_LionCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-MossyStone.png");
}

.Mount_Head_LionCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Onyx.png");
}

.Mount_Head_LionCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Peppermint.png");
}

.Mount_Head_LionCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-PinkMarble.png");
}

.Mount_Head_LionCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-PolkaDot.png");
}

.Mount_Head_LionCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Porcelain.png");
}

.Mount_Head_LionCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Rainbow.png");
}

.Mount_Head_LionCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Red.png");
}

.Mount_Head_LionCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-RoseQuartz.png");
}

.Mount_Head_LionCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-RoyalPurple.png");
}

.Mount_Head_LionCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Ruby.png");
}

.Mount_Head_LionCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-SandSculpture.png");
}

.Mount_Head_LionCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Shade.png");
}

.Mount_Head_LionCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Shadow.png");
}

.Mount_Head_LionCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Shimmer.png");
}

.Mount_Head_LionCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Silver.png");
}

.Mount_Head_LionCub-Skeleton {
  width: 105px;
  height: 110px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Skeleton.png");
}

.Mount_Head_LionCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-SolarSystem.png");
}

.Mount_Head_LionCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Spooky.png");
}

.Mount_Head_LionCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-StainedGlass.png");
}

.Mount_Head_LionCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-StarryNight.png");
}

.Mount_Head_LionCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Sunset.png");
}

.Mount_Head_LionCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Sunshine.png");
}

.Mount_Head_LionCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Thunderstorm.png");
}

.Mount_Head_LionCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Turquoise.png");
}

.Mount_Head_LionCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Vampire.png");
}

.Mount_Head_LionCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Watery.png");
}

.Mount_Head_LionCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-White.png");
}

.Mount_Head_LionCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Windup.png");
}

.Mount_Head_LionCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_LionCub-Zombie.png");
}

.Mount_Head_MagicalBee-Base {
  width: 105px;
  height: 114px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_MagicalBee-Base.png");
}

.Mount_Head_Mammoth-Base {
  width: 105px;
  height: 123px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Mammoth-Base.png");
}

.Mount_Head_MantisShrimp-Base {
  width: 108px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_MantisShrimp-Base.png");
}

.Mount_Head_Monkey-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Base.png");
}

.Mount_Head_Monkey-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-CottonCandyBlue.png");
}

.Mount_Head_Monkey-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-CottonCandyPink.png");
}

.Mount_Head_Monkey-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Desert.png");
}

.Mount_Head_Monkey-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Golden.png");
}

.Mount_Head_Monkey-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Red.png");
}

.Mount_Head_Monkey-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Shade.png");
}

.Mount_Head_Monkey-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Skeleton.png");
}

.Mount_Head_Monkey-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-White.png");
}

.Mount_Head_Monkey-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Monkey-Zombie.png");
}

.Mount_Head_Nudibranch-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Base.png");
}

.Mount_Head_Nudibranch-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-CottonCandyBlue.png");
}

.Mount_Head_Nudibranch-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-CottonCandyPink.png");
}

.Mount_Head_Nudibranch-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Desert.png");
}

.Mount_Head_Nudibranch-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Golden.png");
}

.Mount_Head_Nudibranch-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Red.png");
}

.Mount_Head_Nudibranch-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Shade.png");
}

.Mount_Head_Nudibranch-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Skeleton.png");
}

.Mount_Head_Nudibranch-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-White.png");
}

.Mount_Head_Nudibranch-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Nudibranch-Zombie.png");
}

.Mount_Head_Octopus-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Base.png");
}

.Mount_Head_Octopus-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-CottonCandyBlue.png");
}

.Mount_Head_Octopus-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-CottonCandyPink.png");
}

.Mount_Head_Octopus-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Desert.png");
}

.Mount_Head_Octopus-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Golden.png");
}

.Mount_Head_Octopus-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Red.png");
}

.Mount_Head_Octopus-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Shade.png");
}

.Mount_Head_Octopus-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Skeleton.png");
}

.Mount_Head_Octopus-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-White.png");
}

.Mount_Head_Octopus-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Octopus-Zombie.png");
}

.Mount_Head_Orca-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Orca-Base.png");
}

.Mount_Head_Owl-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Base.png");
}

.Mount_Head_Owl-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-CottonCandyBlue.png");
}

.Mount_Head_Owl-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-CottonCandyPink.png");
}

.Mount_Head_Owl-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Desert.png");
}

.Mount_Head_Owl-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Golden.png");
}

.Mount_Head_Owl-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Red.png");
}

.Mount_Head_Owl-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Shade.png");
}

.Mount_Head_Owl-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Skeleton.png");
}

.Mount_Head_Owl-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-White.png");
}

.Mount_Head_Owl-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Owl-Zombie.png");
}

.Mount_Head_PandaCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Amber.png");
}

.Mount_Head_PandaCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Aquatic.png");
}

.Mount_Head_PandaCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Aurora.png");
}

.Mount_Head_PandaCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-AutumnLeaf.png");
}

.Mount_Head_PandaCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Base.png");
}

.Mount_Head_PandaCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-BirchBark.png");
}

.Mount_Head_PandaCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-BlackPearl.png");
}

.Mount_Head_PandaCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Bronze.png");
}

.Mount_Head_PandaCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Celestial.png");
}

.Mount_Head_PandaCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-CottonCandyBlue.png");
}

.Mount_Head_PandaCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-CottonCandyPink.png");
}

.Mount_Head_PandaCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Cupid.png");
}

.Mount_Head_PandaCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Desert.png");
}

.Mount_Head_PandaCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Ember.png");
}

.Mount_Head_PandaCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Fairy.png");
}

.Mount_Head_PandaCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Floral.png");
}

.Mount_Head_PandaCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Fluorite.png");
}

.Mount_Head_PandaCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Frost.png");
}

.Mount_Head_PandaCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Ghost.png");
}

.Mount_Head_PandaCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Glass.png");
}

.Mount_Head_PandaCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Glow.png");
}

.Mount_Head_PandaCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Golden.png");
}

.Mount_Head_PandaCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Holly.png");
}

.Mount_Head_PandaCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-IcySnow.png");
}

.Mount_Head_PandaCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Moonglow.png");
}

.Mount_Head_PandaCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-MossyStone.png");
}

.Mount_Head_PandaCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Onyx.png");
}

.Mount_Head_PandaCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Peppermint.png");
}

.Mount_Head_PandaCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-PinkMarble.png");
}

.Mount_Head_PandaCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-PolkaDot.png");
}

.Mount_Head_PandaCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Porcelain.png");
}

.Mount_Head_PandaCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Rainbow.png");
}

.Mount_Head_PandaCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Red.png");
}

.Mount_Head_PandaCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-RoseQuartz.png");
}

.Mount_Head_PandaCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-RoyalPurple.png");
}

.Mount_Head_PandaCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Ruby.png");
}

.Mount_Head_PandaCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-SandSculpture.png");
}

.Mount_Head_PandaCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Shade.png");
}

.Mount_Head_PandaCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Shadow.png");
}

.Mount_Head_PandaCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Shimmer.png");
}

.Mount_Head_PandaCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Silver.png");
}

.Mount_Head_PandaCub-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Skeleton.png");
}

.Mount_Head_PandaCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-SolarSystem.png");
}

.Mount_Head_PandaCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Spooky.png");
}

.Mount_Head_PandaCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-StainedGlass.png");
}

.Mount_Head_PandaCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-StarryNight.png");
}

.Mount_Head_PandaCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Sunset.png");
}

.Mount_Head_PandaCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Sunshine.png");
}

.Mount_Head_PandaCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Thunderstorm.png");
}

.Mount_Head_PandaCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Turquoise.png");
}

.Mount_Head_PandaCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Vampire.png");
}

.Mount_Head_PandaCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Watery.png");
}

.Mount_Head_PandaCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-White.png");
}

.Mount_Head_PandaCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Windup.png");
}

.Mount_Head_PandaCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_PandaCub-Zombie.png");
}

.Mount_Head_Parrot-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Base.png");
}

.Mount_Head_Parrot-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-CottonCandyBlue.png");
}

.Mount_Head_Parrot-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-CottonCandyPink.png");
}

.Mount_Head_Parrot-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Desert.png");
}

.Mount_Head_Parrot-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Golden.png");
}

.Mount_Head_Parrot-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Red.png");
}

.Mount_Head_Parrot-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Shade.png");
}

.Mount_Head_Parrot-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Skeleton.png");
}

.Mount_Head_Parrot-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-White.png");
}

.Mount_Head_Parrot-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Parrot-Zombie.png");
}

.Mount_Head_Peacock-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Base.png");
}

.Mount_Head_Peacock-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-CottonCandyBlue.png");
}

.Mount_Head_Peacock-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-CottonCandyPink.png");
}

.Mount_Head_Peacock-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Desert.png");
}

.Mount_Head_Peacock-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Golden.png");
}

.Mount_Head_Peacock-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Red.png");
}

.Mount_Head_Peacock-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Shade.png");
}

.Mount_Head_Peacock-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Skeleton.png");
}

.Mount_Head_Peacock-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-White.png");
}

.Mount_Head_Peacock-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Peacock-Zombie.png");
}

.Mount_Head_Penguin-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Base.png");
}

.Mount_Head_Penguin-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-CottonCandyBlue.png");
}

.Mount_Head_Penguin-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-CottonCandyPink.png");
}

.Mount_Head_Penguin-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Desert.png");
}

.Mount_Head_Penguin-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Golden.png");
}

.Mount_Head_Penguin-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Red.png");
}

.Mount_Head_Penguin-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Shade.png");
}

.Mount_Head_Penguin-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Skeleton.png");
}

.Mount_Head_Penguin-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-White.png");
}

.Mount_Head_Penguin-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Penguin-Zombie.png");
}

.Mount_Head_Phoenix-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Phoenix-Base.png");
}

.Mount_Head_Pterodactyl-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Base.png");
}

.Mount_Head_Pterodactyl-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-CottonCandyBlue.png");
}

.Mount_Head_Pterodactyl-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-CottonCandyPink.png");
}

.Mount_Head_Pterodactyl-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Desert.png");
}

.Mount_Head_Pterodactyl-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Golden.png");
}

.Mount_Head_Pterodactyl-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Red.png");
}

.Mount_Head_Pterodactyl-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Shade.png");
}

.Mount_Head_Pterodactyl-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Skeleton.png");
}

.Mount_Head_Pterodactyl-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-White.png");
}

.Mount_Head_Pterodactyl-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Pterodactyl-Zombie.png");
}

.Mount_Head_Rat-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Base.png");
}

.Mount_Head_Rat-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-CottonCandyBlue.png");
}

.Mount_Head_Rat-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-CottonCandyPink.png");
}

.Mount_Head_Rat-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Desert.png");
}

.Mount_Head_Rat-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Golden.png");
}

.Mount_Head_Rat-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Red.png");
}

.Mount_Head_Rat-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Shade.png");
}

.Mount_Head_Rat-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Skeleton.png");
}

.Mount_Head_Rat-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-White.png");
}

.Mount_Head_Rat-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rat-Zombie.png");
}

.Mount_Head_Robot-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Base.png");
}

.Mount_Head_Robot-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-CottonCandyBlue.png");
}

.Mount_Head_Robot-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-CottonCandyPink.png");
}

.Mount_Head_Robot-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Desert.png");
}

.Mount_Head_Robot-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Golden.png");
}

.Mount_Head_Robot-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Red.png");
}

.Mount_Head_Robot-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Shade.png");
}

.Mount_Head_Robot-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Skeleton.png");
}

.Mount_Head_Robot-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-White.png");
}

.Mount_Head_Robot-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Robot-Zombie.png");
}

.Mount_Head_Rock-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Base.png");
}

.Mount_Head_Rock-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-CottonCandyBlue.png");
}

.Mount_Head_Rock-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-CottonCandyPink.png");
}

.Mount_Head_Rock-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Desert.png");
}

.Mount_Head_Rock-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Golden.png");
}

.Mount_Head_Rock-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Red.png");
}

.Mount_Head_Rock-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Shade.png");
}

.Mount_Head_Rock-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Skeleton.png");
}

.Mount_Head_Rock-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-White.png");
}

.Mount_Head_Rock-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rock-Zombie.png");
}

.Mount_Head_Rooster-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Base.png");
}

.Mount_Head_Rooster-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-CottonCandyBlue.png");
}

.Mount_Head_Rooster-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-CottonCandyPink.png");
}

.Mount_Head_Rooster-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Desert.png");
}

.Mount_Head_Rooster-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Golden.png");
}

.Mount_Head_Rooster-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Red.png");
}

.Mount_Head_Rooster-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Shade.png");
}

.Mount_Head_Rooster-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Skeleton.png");
}

.Mount_Head_Rooster-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-White.png");
}

.Mount_Head_Rooster-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Rooster-Zombie.png");
}

.Mount_Head_Sabretooth-Base {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Base.png");
}

.Mount_Head_Sabretooth-CottonCandyBlue {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-CottonCandyBlue.png");
}

.Mount_Head_Sabretooth-CottonCandyPink {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-CottonCandyPink.png");
}

.Mount_Head_Sabretooth-Desert {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Desert.png");
}

.Mount_Head_Sabretooth-Golden {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Golden.png");
}

.Mount_Head_Sabretooth-Red {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Red.png");
}

.Mount_Head_Sabretooth-Shade {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Shade.png");
}

.Mount_Head_Sabretooth-Skeleton {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Skeleton.png");
}

.Mount_Head_Sabretooth-White {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-White.png");
}

.Mount_Head_Sabretooth-Zombie {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sabretooth-Zombie.png");
}

.Mount_Head_SeaSerpent-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Base.png");
}

.Mount_Head_SeaSerpent-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-CottonCandyBlue.png");
}

.Mount_Head_SeaSerpent-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-CottonCandyPink.png");
}

.Mount_Head_SeaSerpent-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Desert.png");
}

.Mount_Head_SeaSerpent-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Golden.png");
}

.Mount_Head_SeaSerpent-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Red.png");
}

.Mount_Head_SeaSerpent-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Shade.png");
}

.Mount_Head_SeaSerpent-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Skeleton.png");
}

.Mount_Head_SeaSerpent-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-White.png");
}

.Mount_Head_SeaSerpent-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_SeaSerpent-Zombie.png");
}

.Mount_Head_Seahorse-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Base.png");
}

.Mount_Head_Seahorse-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-CottonCandyBlue.png");
}

.Mount_Head_Seahorse-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-CottonCandyPink.png");
}

.Mount_Head_Seahorse-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Desert.png");
}

.Mount_Head_Seahorse-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Golden.png");
}

.Mount_Head_Seahorse-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Red.png");
}

.Mount_Head_Seahorse-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Shade.png");
}

.Mount_Head_Seahorse-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Skeleton.png");
}

.Mount_Head_Seahorse-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-White.png");
}

.Mount_Head_Seahorse-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Seahorse-Zombie.png");
}

.Mount_Head_Sheep-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Base.png");
}

.Mount_Head_Sheep-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-CottonCandyBlue.png");
}

.Mount_Head_Sheep-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-CottonCandyPink.png");
}

.Mount_Head_Sheep-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Desert.png");
}

.Mount_Head_Sheep-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Golden.png");
}

.Mount_Head_Sheep-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Red.png");
}

.Mount_Head_Sheep-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Shade.png");
}

.Mount_Head_Sheep-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Skeleton.png");
}

.Mount_Head_Sheep-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-White.png");
}

.Mount_Head_Sheep-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sheep-Zombie.png");
}

.Mount_Head_Slime-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Base.png");
}

.Mount_Head_Slime-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-CottonCandyBlue.png");
}

.Mount_Head_Slime-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-CottonCandyPink.png");
}

.Mount_Head_Slime-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Desert.png");
}

.Mount_Head_Slime-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Golden.png");
}

.Mount_Head_Slime-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Red.png");
}

.Mount_Head_Slime-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Shade.png");
}

.Mount_Head_Slime-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Skeleton.png");
}

.Mount_Head_Slime-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-White.png");
}

.Mount_Head_Slime-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Slime-Zombie.png");
}

.Mount_Head_Sloth-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Base.png");
}

.Mount_Head_Sloth-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-CottonCandyBlue.png");
}

.Mount_Head_Sloth-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-CottonCandyPink.png");
}

.Mount_Head_Sloth-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Desert.png");
}

.Mount_Head_Sloth-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Golden.png");
}

.Mount_Head_Sloth-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Red.png");
}

.Mount_Head_Sloth-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Shade.png");
}

.Mount_Head_Sloth-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Skeleton.png");
}

.Mount_Head_Sloth-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-White.png");
}

.Mount_Head_Sloth-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Sloth-Zombie.png");
}

.Mount_Head_Snail-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Base.png");
}

.Mount_Head_Snail-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-CottonCandyBlue.png");
}

.Mount_Head_Snail-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-CottonCandyPink.png");
}

.Mount_Head_Snail-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Desert.png");
}

.Mount_Head_Snail-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Golden.png");
}

.Mount_Head_Snail-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Red.png");
}

.Mount_Head_Snail-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Shade.png");
}

.Mount_Head_Snail-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Skeleton.png");
}

.Mount_Head_Snail-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-White.png");
}

.Mount_Head_Snail-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snail-Zombie.png");
}

.Mount_Head_Snake-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Base.png");
}

.Mount_Head_Snake-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-CottonCandyBlue.png");
}

.Mount_Head_Snake-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-CottonCandyPink.png");
}

.Mount_Head_Snake-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Desert.png");
}

.Mount_Head_Snake-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Golden.png");
}

.Mount_Head_Snake-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Red.png");
}

.Mount_Head_Snake-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Shade.png");
}

.Mount_Head_Snake-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Skeleton.png");
}

.Mount_Head_Snake-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-White.png");
}

.Mount_Head_Snake-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Snake-Zombie.png");
}

.Mount_Head_Spider-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Base.png");
}

.Mount_Head_Spider-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-CottonCandyBlue.png");
}

.Mount_Head_Spider-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-CottonCandyPink.png");
}

.Mount_Head_Spider-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Desert.png");
}

.Mount_Head_Spider-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Golden.png");
}

.Mount_Head_Spider-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Red.png");
}

.Mount_Head_Spider-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Shade.png");
}

.Mount_Head_Spider-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Skeleton.png");
}

.Mount_Head_Spider-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-White.png");
}

.Mount_Head_Spider-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Spider-Zombie.png");
}

.Mount_Head_Squirrel-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Base.png");
}

.Mount_Head_Squirrel-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-CottonCandyBlue.png");
}

.Mount_Head_Squirrel-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-CottonCandyPink.png");
}

.Mount_Head_Squirrel-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Desert.png");
}

.Mount_Head_Squirrel-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Golden.png");
}

.Mount_Head_Squirrel-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Red.png");
}

.Mount_Head_Squirrel-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Shade.png");
}

.Mount_Head_Squirrel-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Skeleton.png");
}

.Mount_Head_Squirrel-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-White.png");
}

.Mount_Head_Squirrel-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Squirrel-Zombie.png");
}

.Mount_Head_TRex-Base {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Base.png");
}

.Mount_Head_TRex-CottonCandyBlue {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-CottonCandyBlue.png");
}

.Mount_Head_TRex-CottonCandyPink {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-CottonCandyPink.png");
}

.Mount_Head_TRex-Desert {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Desert.png");
}

.Mount_Head_TRex-Golden {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Golden.png");
}

.Mount_Head_TRex-Red {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Red.png");
}

.Mount_Head_TRex-Shade {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Shade.png");
}

.Mount_Head_TRex-Skeleton {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Skeleton.png");
}

.Mount_Head_TRex-White {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-White.png");
}

.Mount_Head_TRex-Zombie {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TRex-Zombie.png");
}

.Mount_Head_TigerCub-Amber {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Amber.png");
}

.Mount_Head_TigerCub-Aquatic {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Aquatic.png");
}

.Mount_Head_TigerCub-Aurora {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Aurora.png");
}

.Mount_Head_TigerCub-AutumnLeaf {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-AutumnLeaf.png");
}

.Mount_Head_TigerCub-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Base.png");
}

.Mount_Head_TigerCub-BirchBark {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-BirchBark.png");
}

.Mount_Head_TigerCub-BlackPearl {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-BlackPearl.png");
}

.Mount_Head_TigerCub-Bronze {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Bronze.png");
}

.Mount_Head_TigerCub-Celestial {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Celestial.png");
}

.Mount_Head_TigerCub-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-CottonCandyBlue.png");
}

.Mount_Head_TigerCub-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-CottonCandyPink.png");
}

.Mount_Head_TigerCub-Cupid {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Cupid.png");
}

.Mount_Head_TigerCub-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Desert.png");
}

.Mount_Head_TigerCub-Ember {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Ember.png");
}

.Mount_Head_TigerCub-Fairy {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Fairy.png");
}

.Mount_Head_TigerCub-Floral {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Floral.png");
}

.Mount_Head_TigerCub-Fluorite {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Fluorite.png");
}

.Mount_Head_TigerCub-Frost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Frost.png");
}

.Mount_Head_TigerCub-Ghost {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Ghost.png");
}

.Mount_Head_TigerCub-Glass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Glass.png");
}

.Mount_Head_TigerCub-Glow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Glow.png");
}

.Mount_Head_TigerCub-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Golden.png");
}

.Mount_Head_TigerCub-Holly {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Holly.png");
}

.Mount_Head_TigerCub-IcySnow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-IcySnow.png");
}

.Mount_Head_TigerCub-Moonglow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Moonglow.png");
}

.Mount_Head_TigerCub-MossyStone {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-MossyStone.png");
}

.Mount_Head_TigerCub-Onyx {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Onyx.png");
}

.Mount_Head_TigerCub-Peppermint {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Peppermint.png");
}

.Mount_Head_TigerCub-PinkMarble {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-PinkMarble.png");
}

.Mount_Head_TigerCub-PolkaDot {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-PolkaDot.png");
}

.Mount_Head_TigerCub-Porcelain {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Porcelain.png");
}

.Mount_Head_TigerCub-Rainbow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Rainbow.png");
}

.Mount_Head_TigerCub-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Red.png");
}

.Mount_Head_TigerCub-RoseQuartz {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-RoseQuartz.png");
}

.Mount_Head_TigerCub-RoyalPurple {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-RoyalPurple.png");
}

.Mount_Head_TigerCub-Ruby {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Ruby.png");
}

.Mount_Head_TigerCub-SandSculpture {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-SandSculpture.png");
}

.Mount_Head_TigerCub-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Shade.png");
}

.Mount_Head_TigerCub-Shadow {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Shadow.png");
}

.Mount_Head_TigerCub-Shimmer {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Shimmer.png");
}

.Mount_Head_TigerCub-Silver {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Silver.png");
}

.Mount_Head_TigerCub-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Skeleton.png");
}

.Mount_Head_TigerCub-SolarSystem {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-SolarSystem.png");
}

.Mount_Head_TigerCub-Spooky {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Spooky.png");
}

.Mount_Head_TigerCub-StainedGlass {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-StainedGlass.png");
}

.Mount_Head_TigerCub-StarryNight {
  width: 120px;
  height: 120px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-StarryNight.png");
}

.Mount_Head_TigerCub-Sunset {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Sunset.png");
}

.Mount_Head_TigerCub-Sunshine {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Sunshine.png");
}

.Mount_Head_TigerCub-Thunderstorm {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Thunderstorm.png");
}

.Mount_Head_TigerCub-Turquoise {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Turquoise.png");
}

.Mount_Head_TigerCub-Vampire {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Vampire.png");
}

.Mount_Head_TigerCub-Watery {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Watery.png");
}

.Mount_Head_TigerCub-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-White.png");
}

.Mount_Head_TigerCub-Windup {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Windup.png");
}

.Mount_Head_TigerCub-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_TigerCub-Zombie.png");
}

.Mount_Head_Treeling-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Base.png");
}

.Mount_Head_Treeling-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-CottonCandyBlue.png");
}

.Mount_Head_Treeling-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-CottonCandyPink.png");
}

.Mount_Head_Treeling-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Desert.png");
}

.Mount_Head_Treeling-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Golden.png");
}

.Mount_Head_Treeling-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Red.png");
}

.Mount_Head_Treeling-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Shade.png");
}

.Mount_Head_Treeling-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Skeleton.png");
}

.Mount_Head_Treeling-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-White.png");
}

.Mount_Head_Treeling-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Treeling-Zombie.png");
}

.Mount_Head_Triceratops-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Base.png");
}

.Mount_Head_Triceratops-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-CottonCandyBlue.png");
}

.Mount_Head_Triceratops-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-CottonCandyPink.png");
}

.Mount_Head_Triceratops-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Desert.png");
}

.Mount_Head_Triceratops-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Golden.png");
}

.Mount_Head_Triceratops-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Red.png");
}

.Mount_Head_Triceratops-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Shade.png");
}

.Mount_Head_Triceratops-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Skeleton.png");
}

.Mount_Head_Triceratops-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-White.png");
}

.Mount_Head_Triceratops-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Triceratops-Zombie.png");
}

.Mount_Head_Turkey-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turkey-Base.png");
}

.Mount_Head_Turkey-Gilded {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turkey-Gilded.png");
}

.Mount_Head_Turtle-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Base.png");
}

.Mount_Head_Turtle-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-CottonCandyBlue.png");
}

.Mount_Head_Turtle-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-CottonCandyPink.png");
}

.Mount_Head_Turtle-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Desert.png");
}

.Mount_Head_Turtle-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Golden.png");
}

.Mount_Head_Turtle-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Red.png");
}

.Mount_Head_Turtle-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Shade.png");
}

.Mount_Head_Turtle-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Skeleton.png");
}

.Mount_Head_Turtle-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-White.png");
}

.Mount_Head_Turtle-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Turtle-Zombie.png");
}

.Mount_Head_Unicorn-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Base.png");
}

.Mount_Head_Unicorn-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-CottonCandyBlue.png");
}

.Mount_Head_Unicorn-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-CottonCandyPink.png");
}

.Mount_Head_Unicorn-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Desert.png");
}

.Mount_Head_Unicorn-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Golden.png");
}

.Mount_Head_Unicorn-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Red.png");
}

.Mount_Head_Unicorn-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Shade.png");
}

.Mount_Head_Unicorn-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Skeleton.png");
}

.Mount_Head_Unicorn-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-White.png");
}

.Mount_Head_Unicorn-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Unicorn-Zombie.png");
}

.Mount_Head_Velociraptor-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Base.png");
}

.Mount_Head_Velociraptor-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-CottonCandyBlue.png");
}

.Mount_Head_Velociraptor-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-CottonCandyPink.png");
}

.Mount_Head_Velociraptor-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Desert.png");
}

.Mount_Head_Velociraptor-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Golden.png");
}

.Mount_Head_Velociraptor-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Red.png");
}

.Mount_Head_Velociraptor-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Shade.png");
}

.Mount_Head_Velociraptor-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Skeleton.png");
}

.Mount_Head_Velociraptor-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-White.png");
}

.Mount_Head_Velociraptor-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Velociraptor-Zombie.png");
}

.Mount_Head_Whale-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Base.png");
}

.Mount_Head_Whale-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-CottonCandyBlue.png");
}

.Mount_Head_Whale-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-CottonCandyPink.png");
}

.Mount_Head_Whale-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Desert.png");
}

.Mount_Head_Whale-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Golden.png");
}

.Mount_Head_Whale-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Red.png");
}

.Mount_Head_Whale-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Shade.png");
}

.Mount_Head_Whale-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Skeleton.png");
}

.Mount_Head_Whale-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-White.png");
}

.Mount_Head_Whale-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Whale-Zombie.png");
}

.Mount_Head_Wolf-Amber {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Amber.png");
}

.Mount_Head_Wolf-Aquatic {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Aquatic.png");
}

.Mount_Head_Wolf-Aurora {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Aurora.png");
}

.Mount_Head_Wolf-AutumnLeaf {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-AutumnLeaf.png");
}

.Mount_Head_Wolf-Base {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Base.png");
}

.Mount_Head_Wolf-BirchBark {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-BirchBark.png");
}

.Mount_Head_Wolf-BlackPearl {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-BlackPearl.png");
}

.Mount_Head_Wolf-Bronze {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Bronze.png");
}

.Mount_Head_Wolf-Celestial {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Celestial.png");
}

.Mount_Head_Wolf-CottonCandyBlue {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-CottonCandyBlue.png");
}

.Mount_Head_Wolf-CottonCandyPink {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-CottonCandyPink.png");
}

.Mount_Head_Wolf-Cupid {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Cupid.png");
}

.Mount_Head_Wolf-Desert {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Desert.png");
}

.Mount_Head_Wolf-Ember {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Ember.png");
}

.Mount_Head_Wolf-Fairy {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Fairy.png");
}

.Mount_Head_Wolf-Floral {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Floral.png");
}

.Mount_Head_Wolf-Fluorite {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Fluorite.png");
}

.Mount_Head_Wolf-Frost {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Frost.png");
}

.Mount_Head_Wolf-Ghost {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Ghost.png");
}

.Mount_Head_Wolf-Glass {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Glass.png");
}

.Mount_Head_Wolf-Glow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Glow.png");
}

.Mount_Head_Wolf-Golden {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Golden.png");
}

.Mount_Head_Wolf-Holly {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Holly.png");
}

.Mount_Head_Wolf-IcySnow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-IcySnow.png");
}

.Mount_Head_Wolf-Moonglow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Moonglow.png");
}

.Mount_Head_Wolf-MossyStone {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-MossyStone.png");
}

.Mount_Head_Wolf-Onyx {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Onyx.png");
}

.Mount_Head_Wolf-Peppermint {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Peppermint.png");
}

.Mount_Head_Wolf-PinkMarble {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-PinkMarble.png");
}

.Mount_Head_Wolf-PolkaDot {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-PolkaDot.png");
}

.Mount_Head_Wolf-Porcelain {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Porcelain.png");
}

.Mount_Head_Wolf-Rainbow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Rainbow.png");
}

.Mount_Head_Wolf-Red {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Red.png");
}

.Mount_Head_Wolf-RoseQuartz {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-RoseQuartz.png");
}

.Mount_Head_Wolf-RoyalPurple {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-RoyalPurple.png");
}

.Mount_Head_Wolf-Ruby {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Ruby.png");
}

.Mount_Head_Wolf-SandSculpture {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-SandSculpture.png");
}

.Mount_Head_Wolf-Shade {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Shade.png");
}

.Mount_Head_Wolf-Shadow {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Shadow.png");
}

.Mount_Head_Wolf-Shimmer {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Shimmer.png");
}

.Mount_Head_Wolf-Silver {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Silver.png");
}

.Mount_Head_Wolf-Skeleton {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Skeleton.png");
}

.Mount_Head_Wolf-SolarSystem {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-SolarSystem.png");
}

.Mount_Head_Wolf-Spooky {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Spooky.png");
}

.Mount_Head_Wolf-StainedGlass {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-StainedGlass.png");
}

.Mount_Head_Wolf-StarryNight {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-StarryNight.png");
}

.Mount_Head_Wolf-Sunset {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Sunset.png");
}

.Mount_Head_Wolf-Sunshine {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Sunshine.png");
}

.Mount_Head_Wolf-Thunderstorm {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Thunderstorm.png");
}

.Mount_Head_Wolf-Turquoise {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Turquoise.png");
}

.Mount_Head_Wolf-Vampire {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Vampire.png");
}

.Mount_Head_Wolf-Watery {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Watery.png");
}

.Mount_Head_Wolf-White {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-White.png");
}

.Mount_Head_Wolf-Windup {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Windup.png");
}

.Mount_Head_Wolf-Zombie {
  width: 135px;
  height: 135px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Wolf-Zombie.png");
}

.Mount_Head_Yarn-Base {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Base.png");
}

.Mount_Head_Yarn-CottonCandyBlue {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-CottonCandyBlue.png");
}

.Mount_Head_Yarn-CottonCandyPink {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-CottonCandyPink.png");
}

.Mount_Head_Yarn-Desert {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Desert.png");
}

.Mount_Head_Yarn-Golden {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Golden.png");
}

.Mount_Head_Yarn-Red {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Red.png");
}

.Mount_Head_Yarn-Shade {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Shade.png");
}

.Mount_Head_Yarn-Skeleton {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Skeleton.png");
}

.Mount_Head_Yarn-White {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-White.png");
}

.Mount_Head_Yarn-Zombie {
  width: 105px;
  height: 105px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Head_Yarn-Zombie.png");
}

.Mount_Icon_Aether-Invisible {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Aether-Invisible.png");
}

.Mount_Icon_Alligator-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Base.png");
}

.Mount_Icon_Alligator-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-CottonCandyBlue.png");
}

.Mount_Icon_Alligator-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-CottonCandyPink.png");
}

.Mount_Icon_Alligator-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Desert.png");
}

.Mount_Icon_Alligator-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Golden.png");
}

.Mount_Icon_Alligator-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Red.png");
}

.Mount_Icon_Alligator-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Shade.png");
}

.Mount_Icon_Alligator-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Skeleton.png");
}

.Mount_Icon_Alligator-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-White.png");
}

.Mount_Icon_Alligator-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Alligator-Zombie.png");
}

.Mount_Icon_Armadillo-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Base.png");
}

.Mount_Icon_Armadillo-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-CottonCandyBlue.png");
}

.Mount_Icon_Armadillo-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-CottonCandyPink.png");
}

.Mount_Icon_Armadillo-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Desert.png");
}

.Mount_Icon_Armadillo-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Golden.png");
}

.Mount_Icon_Armadillo-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Red.png");
}

.Mount_Icon_Armadillo-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Shade.png");
}

.Mount_Icon_Armadillo-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Skeleton.png");
}

.Mount_Icon_Armadillo-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-White.png");
}

.Mount_Icon_Armadillo-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Armadillo-Zombie.png");
}

.Mount_Icon_Axolotl-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Base.png");
}

.Mount_Icon_Axolotl-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-CottonCandyBlue.png");
}

.Mount_Icon_Axolotl-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-CottonCandyPink.png");
}

.Mount_Icon_Axolotl-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Desert.png");
}

.Mount_Icon_Axolotl-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Golden.png");
}

.Mount_Icon_Axolotl-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Red.png");
}

.Mount_Icon_Axolotl-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Shade.png");
}

.Mount_Icon_Axolotl-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Skeleton.png");
}

.Mount_Icon_Axolotl-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-White.png");
}

.Mount_Icon_Axolotl-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Axolotl-Zombie.png");
}

.Mount_Icon_Badger-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Base.png");
}

.Mount_Icon_Badger-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-CottonCandyBlue.png");
}

.Mount_Icon_Badger-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-CottonCandyPink.png");
}

.Mount_Icon_Badger-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Desert.png");
}

.Mount_Icon_Badger-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Golden.png");
}

.Mount_Icon_Badger-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Red.png");
}

.Mount_Icon_Badger-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Shade.png");
}

.Mount_Icon_Badger-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Skeleton.png");
}

.Mount_Icon_Badger-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-White.png");
}

.Mount_Icon_Badger-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Badger-Zombie.png");
}

.Mount_Icon_BearCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Amber.png");
}

.Mount_Icon_BearCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Aquatic.png");
}

.Mount_Icon_BearCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Aurora.png");
}

.Mount_Icon_BearCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-AutumnLeaf.png");
}

.Mount_Icon_BearCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Base.png");
}

.Mount_Icon_BearCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-BirchBark.png");
}

.Mount_Icon_BearCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-BlackPearl.png");
}

.Mount_Icon_BearCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Bronze.png");
}

.Mount_Icon_BearCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Celestial.png");
}

.Mount_Icon_BearCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-CottonCandyBlue.png");
}

.Mount_Icon_BearCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-CottonCandyPink.png");
}

.Mount_Icon_BearCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Cupid.png");
}

.Mount_Icon_BearCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Desert.png");
}

.Mount_Icon_BearCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Ember.png");
}

.Mount_Icon_BearCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Fairy.png");
}

.Mount_Icon_BearCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Floral.png");
}

.Mount_Icon_BearCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Fluorite.png");
}

.Mount_Icon_BearCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Frost.png");
}

.Mount_Icon_BearCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Ghost.png");
}

.Mount_Icon_BearCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Glass.png");
}

.Mount_Icon_BearCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Glow.png");
}

.Mount_Icon_BearCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Golden.png");
}

.Mount_Icon_BearCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Holly.png");
}

.Mount_Icon_BearCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-IcySnow.png");
}

.Mount_Icon_BearCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Moonglow.png");
}

.Mount_Icon_BearCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-MossyStone.png");
}

.Mount_Icon_BearCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Onyx.png");
}

.Mount_Icon_BearCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Peppermint.png");
}

.Mount_Icon_BearCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-PinkMarble.png");
}

.Mount_Icon_BearCub-Polar {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Polar.png");
}

.Mount_Icon_BearCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-PolkaDot.png");
}

.Mount_Icon_BearCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Porcelain.png");
}

.Mount_Icon_BearCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Rainbow.png");
}

.Mount_Icon_BearCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Red.png");
}

.Mount_Icon_BearCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-RoseQuartz.png");
}

.Mount_Icon_BearCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-RoyalPurple.png");
}

.Mount_Icon_BearCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Ruby.png");
}

.Mount_Icon_BearCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-SandSculpture.png");
}

.Mount_Icon_BearCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Shade.png");
}

.Mount_Icon_BearCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Shadow.png");
}

.Mount_Icon_BearCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Shimmer.png");
}

.Mount_Icon_BearCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Silver.png");
}

.Mount_Icon_BearCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Skeleton.png");
}

.Mount_Icon_BearCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-SolarSystem.png");
}

.Mount_Icon_BearCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Spooky.png");
}

.Mount_Icon_BearCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-StainedGlass.png");
}

.Mount_Icon_BearCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-StarryNight.png");
}

.Mount_Icon_BearCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Sunset.png");
}

.Mount_Icon_BearCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Sunshine.png");
}

.Mount_Icon_BearCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Thunderstorm.png");
}

.Mount_Icon_BearCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Turquoise.png");
}

.Mount_Icon_BearCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Vampire.png");
}

.Mount_Icon_BearCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Watery.png");
}

.Mount_Icon_BearCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-White.png");
}

.Mount_Icon_BearCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Windup.png");
}

.Mount_Icon_BearCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_BearCub-Zombie.png");
}

.Mount_Icon_Beetle-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Base.png");
}

.Mount_Icon_Beetle-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-CottonCandyBlue.png");
}

.Mount_Icon_Beetle-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-CottonCandyPink.png");
}

.Mount_Icon_Beetle-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Desert.png");
}

.Mount_Icon_Beetle-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Golden.png");
}

.Mount_Icon_Beetle-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Red.png");
}

.Mount_Icon_Beetle-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Shade.png");
}

.Mount_Icon_Beetle-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Skeleton.png");
}

.Mount_Icon_Beetle-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-White.png");
}

.Mount_Icon_Beetle-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Beetle-Zombie.png");
}

.Mount_Icon_Bunny-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Base.png");
}

.Mount_Icon_Bunny-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-CottonCandyBlue.png");
}

.Mount_Icon_Bunny-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-CottonCandyPink.png");
}

.Mount_Icon_Bunny-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Desert.png");
}

.Mount_Icon_Bunny-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Golden.png");
}

.Mount_Icon_Bunny-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Red.png");
}

.Mount_Icon_Bunny-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Shade.png");
}

.Mount_Icon_Bunny-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Skeleton.png");
}

.Mount_Icon_Bunny-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-White.png");
}

.Mount_Icon_Bunny-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Bunny-Zombie.png");
}

.Mount_Icon_Butterfly-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Base.png");
}

.Mount_Icon_Butterfly-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-CottonCandyBlue.png");
}

.Mount_Icon_Butterfly-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-CottonCandyPink.png");
}

.Mount_Icon_Butterfly-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Desert.png");
}

.Mount_Icon_Butterfly-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Golden.png");
}

.Mount_Icon_Butterfly-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Red.png");
}

.Mount_Icon_Butterfly-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Shade.png");
}

.Mount_Icon_Butterfly-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Skeleton.png");
}

.Mount_Icon_Butterfly-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-White.png");
}

.Mount_Icon_Butterfly-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Butterfly-Zombie.png");
}

.Mount_Icon_Cactus-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Amber.png");
}

.Mount_Icon_Cactus-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Aquatic.png");
}

.Mount_Icon_Cactus-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Aurora.png");
}

.Mount_Icon_Cactus-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-AutumnLeaf.png");
}

.Mount_Icon_Cactus-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Base.png");
}

.Mount_Icon_Cactus-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-BirchBark.png");
}

.Mount_Icon_Cactus-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-BlackPearl.png");
}

.Mount_Icon_Cactus-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Bronze.png");
}

.Mount_Icon_Cactus-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Celestial.png");
}

.Mount_Icon_Cactus-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-CottonCandyBlue.png");
}

.Mount_Icon_Cactus-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-CottonCandyPink.png");
}

.Mount_Icon_Cactus-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Cupid.png");
}

.Mount_Icon_Cactus-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Desert.png");
}

.Mount_Icon_Cactus-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Ember.png");
}

.Mount_Icon_Cactus-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Fairy.png");
}

.Mount_Icon_Cactus-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Floral.png");
}

.Mount_Icon_Cactus-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Fluorite.png");
}

.Mount_Icon_Cactus-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Frost.png");
}

.Mount_Icon_Cactus-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Ghost.png");
}

.Mount_Icon_Cactus-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Glass.png");
}

.Mount_Icon_Cactus-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Glow.png");
}

.Mount_Icon_Cactus-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Golden.png");
}

.Mount_Icon_Cactus-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Holly.png");
}

.Mount_Icon_Cactus-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-IcySnow.png");
}

.Mount_Icon_Cactus-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Moonglow.png");
}

.Mount_Icon_Cactus-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-MossyStone.png");
}

.Mount_Icon_Cactus-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Onyx.png");
}

.Mount_Icon_Cactus-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Peppermint.png");
}

.Mount_Icon_Cactus-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-PinkMarble.png");
}

.Mount_Icon_Cactus-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-PolkaDot.png");
}

.Mount_Icon_Cactus-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Porcelain.png");
}

.Mount_Icon_Cactus-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Rainbow.png");
}

.Mount_Icon_Cactus-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Red.png");
}

.Mount_Icon_Cactus-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-RoseQuartz.png");
}

.Mount_Icon_Cactus-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-RoyalPurple.png");
}

.Mount_Icon_Cactus-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Ruby.png");
}

.Mount_Icon_Cactus-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-SandSculpture.png");
}

.Mount_Icon_Cactus-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Shade.png");
}

.Mount_Icon_Cactus-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Shadow.png");
}

.Mount_Icon_Cactus-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Shimmer.png");
}

.Mount_Icon_Cactus-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Silver.png");
}

.Mount_Icon_Cactus-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Skeleton.png");
}

.Mount_Icon_Cactus-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-SolarSystem.png");
}

.Mount_Icon_Cactus-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Spooky.png");
}

.Mount_Icon_Cactus-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-StainedGlass.png");
}

.Mount_Icon_Cactus-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-StarryNight.png");
}

.Mount_Icon_Cactus-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Sunset.png");
}

.Mount_Icon_Cactus-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Sunshine.png");
}

.Mount_Icon_Cactus-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Thunderstorm.png");
}

.Mount_Icon_Cactus-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Turquoise.png");
}

.Mount_Icon_Cactus-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Vampire.png");
}

.Mount_Icon_Cactus-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Watery.png");
}

.Mount_Icon_Cactus-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-White.png");
}

.Mount_Icon_Cactus-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Windup.png");
}

.Mount_Icon_Cactus-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cactus-Zombie.png");
}

.Mount_Icon_Cheetah-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Base.png");
}

.Mount_Icon_Cheetah-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-CottonCandyBlue.png");
}

.Mount_Icon_Cheetah-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-CottonCandyPink.png");
}

.Mount_Icon_Cheetah-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Desert.png");
}

.Mount_Icon_Cheetah-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Golden.png");
}

.Mount_Icon_Cheetah-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Red.png");
}

.Mount_Icon_Cheetah-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Shade.png");
}

.Mount_Icon_Cheetah-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Skeleton.png");
}

.Mount_Icon_Cheetah-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-White.png");
}

.Mount_Icon_Cheetah-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cheetah-Zombie.png");
}

.Mount_Icon_Cow-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Base.png");
}

.Mount_Icon_Cow-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-CottonCandyBlue.png");
}

.Mount_Icon_Cow-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-CottonCandyPink.png");
}

.Mount_Icon_Cow-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Desert.png");
}

.Mount_Icon_Cow-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Golden.png");
}

.Mount_Icon_Cow-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Red.png");
}

.Mount_Icon_Cow-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Shade.png");
}

.Mount_Icon_Cow-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Skeleton.png");
}

.Mount_Icon_Cow-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-White.png");
}

.Mount_Icon_Cow-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cow-Zombie.png");
}

.Mount_Icon_Cuttlefish-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Base.png");
}

.Mount_Icon_Cuttlefish-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-CottonCandyBlue.png");
}

.Mount_Icon_Cuttlefish-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-CottonCandyPink.png");
}

.Mount_Icon_Cuttlefish-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Desert.png");
}

.Mount_Icon_Cuttlefish-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Golden.png");
}

.Mount_Icon_Cuttlefish-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Red.png");
}

.Mount_Icon_Cuttlefish-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Shade.png");
}

.Mount_Icon_Cuttlefish-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Skeleton.png");
}

.Mount_Icon_Cuttlefish-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-White.png");
}

.Mount_Icon_Cuttlefish-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Cuttlefish-Zombie.png");
}

.Mount_Icon_Deer-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Base.png");
}

.Mount_Icon_Deer-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-CottonCandyBlue.png");
}

.Mount_Icon_Deer-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-CottonCandyPink.png");
}

.Mount_Icon_Deer-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Desert.png");
}

.Mount_Icon_Deer-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Golden.png");
}

.Mount_Icon_Deer-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Red.png");
}

.Mount_Icon_Deer-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Shade.png");
}

.Mount_Icon_Deer-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Skeleton.png");
}

.Mount_Icon_Deer-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-White.png");
}

.Mount_Icon_Deer-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Deer-Zombie.png");
}

.Mount_Icon_Dolphin-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Base.png");
}

.Mount_Icon_Dolphin-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-CottonCandyBlue.png");
}

.Mount_Icon_Dolphin-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-CottonCandyPink.png");
}

.Mount_Icon_Dolphin-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Desert.png");
}

.Mount_Icon_Dolphin-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Golden.png");
}

.Mount_Icon_Dolphin-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Red.png");
}

.Mount_Icon_Dolphin-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Shade.png");
}

.Mount_Icon_Dolphin-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Skeleton.png");
}

.Mount_Icon_Dolphin-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-White.png");
}

.Mount_Icon_Dolphin-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dolphin-Zombie.png");
}

.Mount_Icon_Dragon-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Amber.png");
}

.Mount_Icon_Dragon-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Aquatic.png");
}

.Mount_Icon_Dragon-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Aurora.png");
}

.Mount_Icon_Dragon-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-AutumnLeaf.png");
}

.Mount_Icon_Dragon-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Base.png");
}

.Mount_Icon_Dragon-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-BirchBark.png");
}

.Mount_Icon_Dragon-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-BlackPearl.png");
}

.Mount_Icon_Dragon-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Bronze.png");
}

.Mount_Icon_Dragon-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Celestial.png");
}

.Mount_Icon_Dragon-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-CottonCandyBlue.png");
}

.Mount_Icon_Dragon-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-CottonCandyPink.png");
}

.Mount_Icon_Dragon-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Cupid.png");
}

.Mount_Icon_Dragon-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Desert.png");
}

.Mount_Icon_Dragon-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Ember.png");
}

.Mount_Icon_Dragon-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Fairy.png");
}

.Mount_Icon_Dragon-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Floral.png");
}

.Mount_Icon_Dragon-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Fluorite.png");
}

.Mount_Icon_Dragon-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Frost.png");
}

.Mount_Icon_Dragon-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Ghost.png");
}

.Mount_Icon_Dragon-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Glass.png");
}

.Mount_Icon_Dragon-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Glow.png");
}

.Mount_Icon_Dragon-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Golden.png");
}

.Mount_Icon_Dragon-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Holly.png");
}

.Mount_Icon_Dragon-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-IcySnow.png");
}

.Mount_Icon_Dragon-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Moonglow.png");
}

.Mount_Icon_Dragon-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-MossyStone.png");
}

.Mount_Icon_Dragon-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Onyx.png");
}

.Mount_Icon_Dragon-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Peppermint.png");
}

.Mount_Icon_Dragon-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-PinkMarble.png");
}

.Mount_Icon_Dragon-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-PolkaDot.png");
}

.Mount_Icon_Dragon-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Porcelain.png");
}

.Mount_Icon_Dragon-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Rainbow.png");
}

.Mount_Icon_Dragon-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Red.png");
}

.Mount_Icon_Dragon-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-RoseQuartz.png");
}

.Mount_Icon_Dragon-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-RoyalPurple.png");
}

.Mount_Icon_Dragon-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Ruby.png");
}

.Mount_Icon_Dragon-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-SandSculpture.png");
}

.Mount_Icon_Dragon-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Shade.png");
}

.Mount_Icon_Dragon-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Shadow.png");
}

.Mount_Icon_Dragon-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Shimmer.png");
}

.Mount_Icon_Dragon-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Silver.png");
}

.Mount_Icon_Dragon-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Skeleton.png");
}

.Mount_Icon_Dragon-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-SolarSystem.png");
}

.Mount_Icon_Dragon-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Spooky.png");
}

.Mount_Icon_Dragon-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-StainedGlass.png");
}

.Mount_Icon_Dragon-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-StarryNight.png");
}

.Mount_Icon_Dragon-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Sunset.png");
}

.Mount_Icon_Dragon-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Sunshine.png");
}

.Mount_Icon_Dragon-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Thunderstorm.png");
}

.Mount_Icon_Dragon-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Turquoise.png");
}

.Mount_Icon_Dragon-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Vampire.png");
}

.Mount_Icon_Dragon-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Watery.png");
}

.Mount_Icon_Dragon-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-White.png");
}

.Mount_Icon_Dragon-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Windup.png");
}

.Mount_Icon_Dragon-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Dragon-Zombie.png");
}

.Mount_Icon_Egg-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Base.png");
}

.Mount_Icon_Egg-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-CottonCandyBlue.png");
}

.Mount_Icon_Egg-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-CottonCandyPink.png");
}

.Mount_Icon_Egg-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Desert.png");
}

.Mount_Icon_Egg-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Golden.png");
}

.Mount_Icon_Egg-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Red.png");
}

.Mount_Icon_Egg-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Shade.png");
}

.Mount_Icon_Egg-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Skeleton.png");
}

.Mount_Icon_Egg-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-White.png");
}

.Mount_Icon_Egg-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Egg-Zombie.png");
}

.Mount_Icon_Falcon-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Base.png");
}

.Mount_Icon_Falcon-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-CottonCandyBlue.png");
}

.Mount_Icon_Falcon-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-CottonCandyPink.png");
}

.Mount_Icon_Falcon-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Desert.png");
}

.Mount_Icon_Falcon-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Golden.png");
}

.Mount_Icon_Falcon-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Red.png");
}

.Mount_Icon_Falcon-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Shade.png");
}

.Mount_Icon_Falcon-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Skeleton.png");
}

.Mount_Icon_Falcon-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-White.png");
}

.Mount_Icon_Falcon-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Falcon-Zombie.png");
}

.Mount_Icon_Ferret-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Base.png");
}

.Mount_Icon_Ferret-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-CottonCandyBlue.png");
}

.Mount_Icon_Ferret-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-CottonCandyPink.png");
}

.Mount_Icon_Ferret-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Desert.png");
}

.Mount_Icon_Ferret-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Golden.png");
}

.Mount_Icon_Ferret-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Red.png");
}

.Mount_Icon_Ferret-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Shade.png");
}

.Mount_Icon_Ferret-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Skeleton.png");
}

.Mount_Icon_Ferret-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-White.png");
}

.Mount_Icon_Ferret-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Ferret-Zombie.png");
}

.Mount_Icon_FlyingPig-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Amber.png");
}

.Mount_Icon_FlyingPig-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Aquatic.png");
}

.Mount_Icon_FlyingPig-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Aurora.png");
}

.Mount_Icon_FlyingPig-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-AutumnLeaf.png");
}

.Mount_Icon_FlyingPig-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Base.png");
}

.Mount_Icon_FlyingPig-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-BirchBark.png");
}

.Mount_Icon_FlyingPig-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-BlackPearl.png");
}

.Mount_Icon_FlyingPig-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Bronze.png");
}

.Mount_Icon_FlyingPig-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Celestial.png");
}

.Mount_Icon_FlyingPig-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-CottonCandyBlue.png");
}

.Mount_Icon_FlyingPig-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-CottonCandyPink.png");
}

.Mount_Icon_FlyingPig-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Cupid.png");
}

.Mount_Icon_FlyingPig-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Desert.png");
}

.Mount_Icon_FlyingPig-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Ember.png");
}

.Mount_Icon_FlyingPig-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Fairy.png");
}

.Mount_Icon_FlyingPig-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Floral.png");
}

.Mount_Icon_FlyingPig-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Fluorite.png");
}

.Mount_Icon_FlyingPig-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Frost.png");
}

.Mount_Icon_FlyingPig-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Ghost.png");
}

.Mount_Icon_FlyingPig-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Glass.png");
}

.Mount_Icon_FlyingPig-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Glow.png");
}

.Mount_Icon_FlyingPig-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Golden.png");
}

.Mount_Icon_FlyingPig-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Holly.png");
}

.Mount_Icon_FlyingPig-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-IcySnow.png");
}

.Mount_Icon_FlyingPig-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Moonglow.png");
}

.Mount_Icon_FlyingPig-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-MossyStone.png");
}

.Mount_Icon_FlyingPig-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Onyx.png");
}

.Mount_Icon_FlyingPig-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Peppermint.png");
}

.Mount_Icon_FlyingPig-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-PinkMarble.png");
}

.Mount_Icon_FlyingPig-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-PolkaDot.png");
}

.Mount_Icon_FlyingPig-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Porcelain.png");
}

.Mount_Icon_FlyingPig-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Rainbow.png");
}

.Mount_Icon_FlyingPig-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Red.png");
}

.Mount_Icon_FlyingPig-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-RoseQuartz.png");
}

.Mount_Icon_FlyingPig-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-RoyalPurple.png");
}

.Mount_Icon_FlyingPig-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Ruby.png");
}

.Mount_Icon_FlyingPig-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-SandSculpture.png");
}

.Mount_Icon_FlyingPig-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Shade.png");
}

.Mount_Icon_FlyingPig-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Shadow.png");
}

.Mount_Icon_FlyingPig-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Shimmer.png");
}

.Mount_Icon_FlyingPig-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Silver.png");
}

.Mount_Icon_FlyingPig-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Skeleton.png");
}

.Mount_Icon_FlyingPig-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-SolarSystem.png");
}

.Mount_Icon_FlyingPig-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Spooky.png");
}

.Mount_Icon_FlyingPig-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-StainedGlass.png");
}

.Mount_Icon_FlyingPig-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-StarryNight.png");
}

.Mount_Icon_FlyingPig-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Sunset.png");
}

.Mount_Icon_FlyingPig-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Sunshine.png");
}

.Mount_Icon_FlyingPig-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Thunderstorm.png");
}

.Mount_Icon_FlyingPig-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Turquoise.png");
}

.Mount_Icon_FlyingPig-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Vampire.png");
}

.Mount_Icon_FlyingPig-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Watery.png");
}

.Mount_Icon_FlyingPig-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-White.png");
}

.Mount_Icon_FlyingPig-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Windup.png");
}

.Mount_Icon_FlyingPig-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_FlyingPig-Zombie.png");
}

.Mount_Icon_Fox-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Amber.png");
}

.Mount_Icon_Fox-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Aquatic.png");
}

.Mount_Icon_Fox-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Aurora.png");
}

.Mount_Icon_Fox-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-AutumnLeaf.png");
}

.Mount_Icon_Fox-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Base.png");
}

.Mount_Icon_Fox-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-BirchBark.png");
}

.Mount_Icon_Fox-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-BlackPearl.png");
}

.Mount_Icon_Fox-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Bronze.png");
}

.Mount_Icon_Fox-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Celestial.png");
}

.Mount_Icon_Fox-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-CottonCandyBlue.png");
}

.Mount_Icon_Fox-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-CottonCandyPink.png");
}

.Mount_Icon_Fox-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Cupid.png");
}

.Mount_Icon_Fox-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Desert.png");
}

.Mount_Icon_Fox-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Ember.png");
}

.Mount_Icon_Fox-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Fairy.png");
}

.Mount_Icon_Fox-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Floral.png");
}

.Mount_Icon_Fox-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Fluorite.png");
}

.Mount_Icon_Fox-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Frost.png");
}

.Mount_Icon_Fox-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Ghost.png");
}

.Mount_Icon_Fox-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Glass.png");
}

.Mount_Icon_Fox-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Glow.png");
}

.Mount_Icon_Fox-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Golden.png");
}

.Mount_Icon_Fox-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Holly.png");
}

.Mount_Icon_Fox-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-IcySnow.png");
}

.Mount_Icon_Fox-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Moonglow.png");
}

.Mount_Icon_Fox-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-MossyStone.png");
}

.Mount_Icon_Fox-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Onyx.png");
}

.Mount_Icon_Fox-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Peppermint.png");
}

.Mount_Icon_Fox-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-PinkMarble.png");
}

.Mount_Icon_Fox-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-PolkaDot.png");
}

.Mount_Icon_Fox-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Porcelain.png");
}

.Mount_Icon_Fox-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Rainbow.png");
}

.Mount_Icon_Fox-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Red.png");
}

.Mount_Icon_Fox-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-RoseQuartz.png");
}

.Mount_Icon_Fox-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-RoyalPurple.png");
}

.Mount_Icon_Fox-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Ruby.png");
}

.Mount_Icon_Fox-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-SandSculpture.png");
}

.Mount_Icon_Fox-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Shade.png");
}

.Mount_Icon_Fox-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Shadow.png");
}

.Mount_Icon_Fox-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Shimmer.png");
}

.Mount_Icon_Fox-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Silver.png");
}

.Mount_Icon_Fox-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Skeleton.png");
}

.Mount_Icon_Fox-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-SolarSystem.png");
}

.Mount_Icon_Fox-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Spooky.png");
}

.Mount_Icon_Fox-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-StainedGlass.png");
}

.Mount_Icon_Fox-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-StarryNight.png");
}

.Mount_Icon_Fox-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Sunset.png");
}

.Mount_Icon_Fox-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Sunshine.png");
}

.Mount_Icon_Fox-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Thunderstorm.png");
}

.Mount_Icon_Fox-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Turquoise.png");
}

.Mount_Icon_Fox-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Vampire.png");
}

.Mount_Icon_Fox-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Watery.png");
}

.Mount_Icon_Fox-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-White.png");
}

.Mount_Icon_Fox-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Windup.png");
}

.Mount_Icon_Fox-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Fox-Zombie.png");
}

.Mount_Icon_Frog-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Base.png");
}

.Mount_Icon_Frog-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-CottonCandyBlue.png");
}

.Mount_Icon_Frog-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-CottonCandyPink.png");
}

.Mount_Icon_Frog-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Desert.png");
}

.Mount_Icon_Frog-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Golden.png");
}

.Mount_Icon_Frog-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Red.png");
}

.Mount_Icon_Frog-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Shade.png");
}

.Mount_Icon_Frog-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Skeleton.png");
}

.Mount_Icon_Frog-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-White.png");
}

.Mount_Icon_Frog-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Frog-Zombie.png");
}

.Mount_Icon_Gryphon-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Base.png");
}

.Mount_Icon_Gryphon-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-CottonCandyBlue.png");
}

.Mount_Icon_Gryphon-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-CottonCandyPink.png");
}

.Mount_Icon_Gryphon-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Desert.png");
}

.Mount_Icon_Gryphon-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Golden.png");
}

.Mount_Icon_Gryphon-Gryphatrice {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Gryphatrice.png");
}

.Mount_Icon_Gryphon-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Red.png");
}

.Mount_Icon_Gryphon-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-RoyalPurple.png");
}

.Mount_Icon_Gryphon-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Shade.png");
}

.Mount_Icon_Gryphon-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Skeleton.png");
}

.Mount_Icon_Gryphon-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-White.png");
}

.Mount_Icon_Gryphon-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Gryphon-Zombie.png");
}

.Mount_Icon_GuineaPig-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Base.png");
}

.Mount_Icon_GuineaPig-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-CottonCandyBlue.png");
}

.Mount_Icon_GuineaPig-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-CottonCandyPink.png");
}

.Mount_Icon_GuineaPig-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Desert.png");
}

.Mount_Icon_GuineaPig-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Golden.png");
}

.Mount_Icon_GuineaPig-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Red.png");
}

.Mount_Icon_GuineaPig-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Shade.png");
}

.Mount_Icon_GuineaPig-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Skeleton.png");
}

.Mount_Icon_GuineaPig-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-White.png");
}

.Mount_Icon_GuineaPig-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_GuineaPig-Zombie.png");
}

.Mount_Icon_Hedgehog-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Base.png");
}

.Mount_Icon_Hedgehog-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-CottonCandyBlue.png");
}

.Mount_Icon_Hedgehog-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-CottonCandyPink.png");
}

.Mount_Icon_Hedgehog-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Desert.png");
}

.Mount_Icon_Hedgehog-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Golden.png");
}

.Mount_Icon_Hedgehog-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Red.png");
}

.Mount_Icon_Hedgehog-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Shade.png");
}

.Mount_Icon_Hedgehog-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Skeleton.png");
}

.Mount_Icon_Hedgehog-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-White.png");
}

.Mount_Icon_Hedgehog-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hedgehog-Zombie.png");
}

.Mount_Icon_Hippo-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Base.png");
}

.Mount_Icon_Hippo-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-CottonCandyBlue.png");
}

.Mount_Icon_Hippo-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-CottonCandyPink.png");
}

.Mount_Icon_Hippo-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Desert.png");
}

.Mount_Icon_Hippo-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Golden.png");
}

.Mount_Icon_Hippo-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Red.png");
}

.Mount_Icon_Hippo-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Shade.png");
}

.Mount_Icon_Hippo-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Skeleton.png");
}

.Mount_Icon_Hippo-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-White.png");
}

.Mount_Icon_Hippo-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippo-Zombie.png");
}

.Mount_Icon_Hippogriff-Hopeful {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Hippogriff-Hopeful.png");
}

.Mount_Icon_Horse-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Base.png");
}

.Mount_Icon_Horse-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-CottonCandyBlue.png");
}

.Mount_Icon_Horse-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-CottonCandyPink.png");
}

.Mount_Icon_Horse-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Desert.png");
}

.Mount_Icon_Horse-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Golden.png");
}

.Mount_Icon_Horse-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Red.png");
}

.Mount_Icon_Horse-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Shade.png");
}

.Mount_Icon_Horse-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Skeleton.png");
}

.Mount_Icon_Horse-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-White.png");
}

.Mount_Icon_Horse-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Horse-Zombie.png");
}

.Mount_Icon_JackOLantern-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-Base.png");
}

.Mount_Icon_JackOLantern-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-Ghost.png");
}

.Mount_Icon_JackOLantern-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-Glow.png");
}

.Mount_Icon_JackOLantern-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_JackOLantern-RoyalPurple.png");
}

.Mount_Icon_Jackalope-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Jackalope-RoyalPurple.png");
}

.Mount_Icon_Kangaroo-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Base.png");
}

.Mount_Icon_Kangaroo-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-CottonCandyBlue.png");
}

.Mount_Icon_Kangaroo-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-CottonCandyPink.png");
}

.Mount_Icon_Kangaroo-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Desert.png");
}

.Mount_Icon_Kangaroo-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Golden.png");
}

.Mount_Icon_Kangaroo-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Red.png");
}

.Mount_Icon_Kangaroo-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Shade.png");
}

.Mount_Icon_Kangaroo-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Skeleton.png");
}

.Mount_Icon_Kangaroo-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-White.png");
}

.Mount_Icon_Kangaroo-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Kangaroo-Zombie.png");
}

.Mount_Icon_LionCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Amber.png");
}

.Mount_Icon_LionCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Aquatic.png");
}

.Mount_Icon_LionCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Aurora.png");
}

.Mount_Icon_LionCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-AutumnLeaf.png");
}

.Mount_Icon_LionCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Base.png");
}

.Mount_Icon_LionCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-BirchBark.png");
}

.Mount_Icon_LionCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-BlackPearl.png");
}

.Mount_Icon_LionCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Bronze.png");
}

.Mount_Icon_LionCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Celestial.png");
}

.Mount_Icon_LionCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-CottonCandyBlue.png");
}

.Mount_Icon_LionCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-CottonCandyPink.png");
}

.Mount_Icon_LionCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Cupid.png");
}

.Mount_Icon_LionCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Desert.png");
}

.Mount_Icon_LionCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ember.png");
}

.Mount_Icon_LionCub-Ethereal {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ethereal.png");
}

.Mount_Icon_LionCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Fairy.png");
}

.Mount_Icon_LionCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Floral.png");
}

.Mount_Icon_LionCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Fluorite.png");
}

.Mount_Icon_LionCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Frost.png");
}

.Mount_Icon_LionCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ghost.png");
}

.Mount_Icon_LionCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Glass.png");
}

.Mount_Icon_LionCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Glow.png");
}

.Mount_Icon_LionCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Golden.png");
}

.Mount_Icon_LionCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Holly.png");
}

.Mount_Icon_LionCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-IcySnow.png");
}

.Mount_Icon_LionCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Moonglow.png");
}

.Mount_Icon_LionCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-MossyStone.png");
}

.Mount_Icon_LionCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Onyx.png");
}

.Mount_Icon_LionCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Peppermint.png");
}

.Mount_Icon_LionCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-PinkMarble.png");
}

.Mount_Icon_LionCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-PolkaDot.png");
}

.Mount_Icon_LionCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Porcelain.png");
}

.Mount_Icon_LionCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Rainbow.png");
}

.Mount_Icon_LionCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Red.png");
}

.Mount_Icon_LionCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-RoseQuartz.png");
}

.Mount_Icon_LionCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-RoyalPurple.png");
}

.Mount_Icon_LionCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Ruby.png");
}

.Mount_Icon_LionCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-SandSculpture.png");
}

.Mount_Icon_LionCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Shade.png");
}

.Mount_Icon_LionCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Shadow.png");
}

.Mount_Icon_LionCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Shimmer.png");
}

.Mount_Icon_LionCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Silver.png");
}

.Mount_Icon_LionCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Skeleton.png");
}

.Mount_Icon_LionCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-SolarSystem.png");
}

.Mount_Icon_LionCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Spooky.png");
}

.Mount_Icon_LionCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-StainedGlass.png");
}

.Mount_Icon_LionCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-StarryNight.png");
}

.Mount_Icon_LionCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Sunset.png");
}

.Mount_Icon_LionCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Sunshine.png");
}

.Mount_Icon_LionCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Thunderstorm.png");
}

.Mount_Icon_LionCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Turquoise.png");
}

.Mount_Icon_LionCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Vampire.png");
}

.Mount_Icon_LionCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Watery.png");
}

.Mount_Icon_LionCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-White.png");
}

.Mount_Icon_LionCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Windup.png");
}

.Mount_Icon_LionCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_LionCub-Zombie.png");
}

.Mount_Icon_MagicalBee-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_MagicalBee-Base.png");
}

.Mount_Icon_Mammoth-Base {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Mammoth-Base.png");
}

.Mount_Icon_MantisShrimp-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_MantisShrimp-Base.png");
}

.Mount_Icon_Monkey-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Base.png");
}

.Mount_Icon_Monkey-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-CottonCandyBlue.png");
}

.Mount_Icon_Monkey-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-CottonCandyPink.png");
}

.Mount_Icon_Monkey-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Desert.png");
}

.Mount_Icon_Monkey-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Golden.png");
}

.Mount_Icon_Monkey-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Red.png");
}

.Mount_Icon_Monkey-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Shade.png");
}

.Mount_Icon_Monkey-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Skeleton.png");
}

.Mount_Icon_Monkey-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-White.png");
}

.Mount_Icon_Monkey-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Monkey-Zombie.png");
}

.Mount_Icon_Nudibranch-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Base.png");
}

.Mount_Icon_Nudibranch-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-CottonCandyBlue.png");
}

.Mount_Icon_Nudibranch-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-CottonCandyPink.png");
}

.Mount_Icon_Nudibranch-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Desert.png");
}

.Mount_Icon_Nudibranch-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Golden.png");
}

.Mount_Icon_Nudibranch-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Red.png");
}

.Mount_Icon_Nudibranch-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Shade.png");
}

.Mount_Icon_Nudibranch-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Skeleton.png");
}

.Mount_Icon_Nudibranch-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-White.png");
}

.Mount_Icon_Nudibranch-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Nudibranch-Zombie.png");
}

.Mount_Icon_Octopus-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Base.png");
}

.Mount_Icon_Octopus-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-CottonCandyBlue.png");
}

.Mount_Icon_Octopus-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-CottonCandyPink.png");
}

.Mount_Icon_Octopus-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Desert.png");
}

.Mount_Icon_Octopus-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Golden.png");
}

.Mount_Icon_Octopus-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Red.png");
}

.Mount_Icon_Octopus-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Shade.png");
}

.Mount_Icon_Octopus-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Skeleton.png");
}

.Mount_Icon_Octopus-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-White.png");
}

.Mount_Icon_Octopus-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Octopus-Zombie.png");
}

.Mount_Icon_Orca-Base {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Orca-Base.png");
}

.Mount_Icon_Owl-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Base.png");
}

.Mount_Icon_Owl-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-CottonCandyBlue.png");
}

.Mount_Icon_Owl-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-CottonCandyPink.png");
}

.Mount_Icon_Owl-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Desert.png");
}

.Mount_Icon_Owl-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Golden.png");
}

.Mount_Icon_Owl-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Red.png");
}

.Mount_Icon_Owl-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Shade.png");
}

.Mount_Icon_Owl-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Skeleton.png");
}

.Mount_Icon_Owl-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-White.png");
}

.Mount_Icon_Owl-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Owl-Zombie.png");
}

.Mount_Icon_PandaCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Amber.png");
}

.Mount_Icon_PandaCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Aquatic.png");
}

.Mount_Icon_PandaCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Aurora.png");
}

.Mount_Icon_PandaCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-AutumnLeaf.png");
}

.Mount_Icon_PandaCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Base.png");
}

.Mount_Icon_PandaCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-BirchBark.png");
}

.Mount_Icon_PandaCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-BlackPearl.png");
}

.Mount_Icon_PandaCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Bronze.png");
}

.Mount_Icon_PandaCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Celestial.png");
}

.Mount_Icon_PandaCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-CottonCandyBlue.png");
}

.Mount_Icon_PandaCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-CottonCandyPink.png");
}

.Mount_Icon_PandaCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Cupid.png");
}

.Mount_Icon_PandaCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Desert.png");
}

.Mount_Icon_PandaCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Ember.png");
}

.Mount_Icon_PandaCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Fairy.png");
}

.Mount_Icon_PandaCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Floral.png");
}

.Mount_Icon_PandaCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Fluorite.png");
}

.Mount_Icon_PandaCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Frost.png");
}

.Mount_Icon_PandaCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Ghost.png");
}

.Mount_Icon_PandaCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Glass.png");
}

.Mount_Icon_PandaCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Glow.png");
}

.Mount_Icon_PandaCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Golden.png");
}

.Mount_Icon_PandaCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Holly.png");
}

.Mount_Icon_PandaCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-IcySnow.png");
}

.Mount_Icon_PandaCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Moonglow.png");
}

.Mount_Icon_PandaCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-MossyStone.png");
}

.Mount_Icon_PandaCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Onyx.png");
}

.Mount_Icon_PandaCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Peppermint.png");
}

.Mount_Icon_PandaCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-PinkMarble.png");
}

.Mount_Icon_PandaCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-PolkaDot.png");
}

.Mount_Icon_PandaCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Porcelain.png");
}

.Mount_Icon_PandaCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Rainbow.png");
}

.Mount_Icon_PandaCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Red.png");
}

.Mount_Icon_PandaCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-RoseQuartz.png");
}

.Mount_Icon_PandaCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-RoyalPurple.png");
}

.Mount_Icon_PandaCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Ruby.png");
}

.Mount_Icon_PandaCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-SandSculpture.png");
}

.Mount_Icon_PandaCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Shade.png");
}

.Mount_Icon_PandaCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Shadow.png");
}

.Mount_Icon_PandaCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Shimmer.png");
}

.Mount_Icon_PandaCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Silver.png");
}

.Mount_Icon_PandaCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Skeleton.png");
}

.Mount_Icon_PandaCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-SolarSystem.png");
}

.Mount_Icon_PandaCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Spooky.png");
}

.Mount_Icon_PandaCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-StainedGlass.png");
}

.Mount_Icon_PandaCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-StarryNight.png");
}

.Mount_Icon_PandaCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Sunset.png");
}

.Mount_Icon_PandaCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Sunshine.png");
}

.Mount_Icon_PandaCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Thunderstorm.png");
}

.Mount_Icon_PandaCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Turquoise.png");
}

.Mount_Icon_PandaCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Vampire.png");
}

.Mount_Icon_PandaCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Watery.png");
}

.Mount_Icon_PandaCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-White.png");
}

.Mount_Icon_PandaCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Windup.png");
}

.Mount_Icon_PandaCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_PandaCub-Zombie.png");
}

.Mount_Icon_Parrot-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Base.png");
}

.Mount_Icon_Parrot-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-CottonCandyBlue.png");
}

.Mount_Icon_Parrot-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-CottonCandyPink.png");
}

.Mount_Icon_Parrot-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Desert.png");
}

.Mount_Icon_Parrot-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Golden.png");
}

.Mount_Icon_Parrot-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Red.png");
}

.Mount_Icon_Parrot-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Shade.png");
}

.Mount_Icon_Parrot-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Skeleton.png");
}

.Mount_Icon_Parrot-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-White.png");
}

.Mount_Icon_Parrot-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Parrot-Zombie.png");
}

.Mount_Icon_Peacock-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Base.png");
}

.Mount_Icon_Peacock-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-CottonCandyBlue.png");
}

.Mount_Icon_Peacock-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-CottonCandyPink.png");
}

.Mount_Icon_Peacock-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Desert.png");
}

.Mount_Icon_Peacock-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Golden.png");
}

.Mount_Icon_Peacock-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Red.png");
}

.Mount_Icon_Peacock-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Shade.png");
}

.Mount_Icon_Peacock-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Skeleton.png");
}

.Mount_Icon_Peacock-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-White.png");
}

.Mount_Icon_Peacock-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Peacock-Zombie.png");
}

.Mount_Icon_Penguin-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Base.png");
}

.Mount_Icon_Penguin-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-CottonCandyBlue.png");
}

.Mount_Icon_Penguin-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-CottonCandyPink.png");
}

.Mount_Icon_Penguin-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Desert.png");
}

.Mount_Icon_Penguin-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Golden.png");
}

.Mount_Icon_Penguin-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Red.png");
}

.Mount_Icon_Penguin-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Shade.png");
}

.Mount_Icon_Penguin-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Skeleton.png");
}

.Mount_Icon_Penguin-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-White.png");
}

.Mount_Icon_Penguin-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Penguin-Zombie.png");
}

.Mount_Icon_Phoenix-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Phoenix-Base.png");
}

.Mount_Icon_Pterodactyl-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Base.png");
}

.Mount_Icon_Pterodactyl-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-CottonCandyBlue.png");
}

.Mount_Icon_Pterodactyl-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-CottonCandyPink.png");
}

.Mount_Icon_Pterodactyl-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Desert.png");
}

.Mount_Icon_Pterodactyl-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Golden.png");
}

.Mount_Icon_Pterodactyl-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Red.png");
}

.Mount_Icon_Pterodactyl-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Shade.png");
}

.Mount_Icon_Pterodactyl-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Skeleton.png");
}

.Mount_Icon_Pterodactyl-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-White.png");
}

.Mount_Icon_Pterodactyl-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Pterodactyl-Zombie.png");
}

.Mount_Icon_Rat-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Base.png");
}

.Mount_Icon_Rat-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-CottonCandyBlue.png");
}

.Mount_Icon_Rat-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-CottonCandyPink.png");
}

.Mount_Icon_Rat-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Desert.png");
}

.Mount_Icon_Rat-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Golden.png");
}

.Mount_Icon_Rat-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Red.png");
}

.Mount_Icon_Rat-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Shade.png");
}

.Mount_Icon_Rat-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Skeleton.png");
}

.Mount_Icon_Rat-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-White.png");
}

.Mount_Icon_Rat-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rat-Zombie.png");
}

.Mount_Icon_Robot-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Base.png");
}

.Mount_Icon_Robot-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-CottonCandyBlue.png");
}

.Mount_Icon_Robot-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-CottonCandyPink.png");
}

.Mount_Icon_Robot-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Desert.png");
}

.Mount_Icon_Robot-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Golden.png");
}

.Mount_Icon_Robot-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Red.png");
}

.Mount_Icon_Robot-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Shade.png");
}

.Mount_Icon_Robot-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Skeleton.png");
}

.Mount_Icon_Robot-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-White.png");
}

.Mount_Icon_Robot-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Robot-Zombie.png");
}

.Mount_Icon_Rock-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Base.png");
}

.Mount_Icon_Rock-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-CottonCandyBlue.png");
}

.Mount_Icon_Rock-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-CottonCandyPink.png");
}

.Mount_Icon_Rock-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Desert.png");
}

.Mount_Icon_Rock-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Golden.png");
}

.Mount_Icon_Rock-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Red.png");
}

.Mount_Icon_Rock-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Shade.png");
}

.Mount_Icon_Rock-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Skeleton.png");
}

.Mount_Icon_Rock-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-White.png");
}

.Mount_Icon_Rock-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rock-Zombie.png");
}

.Mount_Icon_Rooster-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Base.png");
}

.Mount_Icon_Rooster-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-CottonCandyBlue.png");
}

.Mount_Icon_Rooster-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-CottonCandyPink.png");
}

.Mount_Icon_Rooster-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Desert.png");
}

.Mount_Icon_Rooster-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Golden.png");
}

.Mount_Icon_Rooster-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Red.png");
}

.Mount_Icon_Rooster-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Shade.png");
}

.Mount_Icon_Rooster-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Skeleton.png");
}

.Mount_Icon_Rooster-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-White.png");
}

.Mount_Icon_Rooster-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Rooster-Zombie.png");
}

.Mount_Icon_Sabretooth-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Base.png");
}

.Mount_Icon_Sabretooth-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-CottonCandyBlue.png");
}

.Mount_Icon_Sabretooth-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-CottonCandyPink.png");
}

.Mount_Icon_Sabretooth-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Desert.png");
}

.Mount_Icon_Sabretooth-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Golden.png");
}

.Mount_Icon_Sabretooth-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Red.png");
}

.Mount_Icon_Sabretooth-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Shade.png");
}

.Mount_Icon_Sabretooth-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Skeleton.png");
}

.Mount_Icon_Sabretooth-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-White.png");
}

.Mount_Icon_Sabretooth-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sabretooth-Zombie.png");
}

.Mount_Icon_SeaSerpent-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Base.png");
}

.Mount_Icon_SeaSerpent-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-CottonCandyBlue.png");
}

.Mount_Icon_SeaSerpent-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-CottonCandyPink.png");
}

.Mount_Icon_SeaSerpent-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Desert.png");
}

.Mount_Icon_SeaSerpent-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Golden.png");
}

.Mount_Icon_SeaSerpent-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Red.png");
}

.Mount_Icon_SeaSerpent-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Shade.png");
}

.Mount_Icon_SeaSerpent-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Skeleton.png");
}

.Mount_Icon_SeaSerpent-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-White.png");
}

.Mount_Icon_SeaSerpent-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_SeaSerpent-Zombie.png");
}

.Mount_Icon_Seahorse-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Base.png");
}

.Mount_Icon_Seahorse-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-CottonCandyBlue.png");
}

.Mount_Icon_Seahorse-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-CottonCandyPink.png");
}

.Mount_Icon_Seahorse-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Desert.png");
}

.Mount_Icon_Seahorse-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Golden.png");
}

.Mount_Icon_Seahorse-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Red.png");
}

.Mount_Icon_Seahorse-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Shade.png");
}

.Mount_Icon_Seahorse-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Skeleton.png");
}

.Mount_Icon_Seahorse-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-White.png");
}

.Mount_Icon_Seahorse-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Seahorse-Zombie.png");
}

.Mount_Icon_Sheep-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Base.png");
}

.Mount_Icon_Sheep-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-CottonCandyBlue.png");
}

.Mount_Icon_Sheep-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-CottonCandyPink.png");
}

.Mount_Icon_Sheep-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Desert.png");
}

.Mount_Icon_Sheep-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Golden.png");
}

.Mount_Icon_Sheep-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Red.png");
}

.Mount_Icon_Sheep-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Shade.png");
}

.Mount_Icon_Sheep-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Skeleton.png");
}

.Mount_Icon_Sheep-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-White.png");
}

.Mount_Icon_Sheep-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sheep-Zombie.png");
}

.Mount_Icon_Slime-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Base.png");
}

.Mount_Icon_Slime-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-CottonCandyBlue.png");
}

.Mount_Icon_Slime-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-CottonCandyPink.png");
}

.Mount_Icon_Slime-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Desert.png");
}

.Mount_Icon_Slime-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Golden.png");
}

.Mount_Icon_Slime-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Red.png");
}

.Mount_Icon_Slime-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Shade.png");
}

.Mount_Icon_Slime-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Skeleton.png");
}

.Mount_Icon_Slime-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-White.png");
}

.Mount_Icon_Slime-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Slime-Zombie.png");
}

.Mount_Icon_Sloth-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Base.png");
}

.Mount_Icon_Sloth-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-CottonCandyBlue.png");
}

.Mount_Icon_Sloth-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-CottonCandyPink.png");
}

.Mount_Icon_Sloth-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Desert.png");
}

.Mount_Icon_Sloth-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Golden.png");
}

.Mount_Icon_Sloth-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Red.png");
}

.Mount_Icon_Sloth-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Shade.png");
}

.Mount_Icon_Sloth-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Skeleton.png");
}

.Mount_Icon_Sloth-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-White.png");
}

.Mount_Icon_Sloth-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Sloth-Zombie.png");
}

.Mount_Icon_Snail-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Base.png");
}

.Mount_Icon_Snail-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-CottonCandyBlue.png");
}

.Mount_Icon_Snail-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-CottonCandyPink.png");
}

.Mount_Icon_Snail-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Desert.png");
}

.Mount_Icon_Snail-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Golden.png");
}

.Mount_Icon_Snail-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Red.png");
}

.Mount_Icon_Snail-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Shade.png");
}

.Mount_Icon_Snail-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Skeleton.png");
}

.Mount_Icon_Snail-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-White.png");
}

.Mount_Icon_Snail-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snail-Zombie.png");
}

.Mount_Icon_Snake-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Base.png");
}

.Mount_Icon_Snake-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-CottonCandyBlue.png");
}

.Mount_Icon_Snake-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-CottonCandyPink.png");
}

.Mount_Icon_Snake-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Desert.png");
}

.Mount_Icon_Snake-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Golden.png");
}

.Mount_Icon_Snake-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Red.png");
}

.Mount_Icon_Snake-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Shade.png");
}

.Mount_Icon_Snake-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Skeleton.png");
}

.Mount_Icon_Snake-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-White.png");
}

.Mount_Icon_Snake-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Snake-Zombie.png");
}

.Mount_Icon_Spider-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Base.png");
}

.Mount_Icon_Spider-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-CottonCandyBlue.png");
}

.Mount_Icon_Spider-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-CottonCandyPink.png");
}

.Mount_Icon_Spider-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Desert.png");
}

.Mount_Icon_Spider-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Golden.png");
}

.Mount_Icon_Spider-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Red.png");
}

.Mount_Icon_Spider-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Shade.png");
}

.Mount_Icon_Spider-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Skeleton.png");
}

.Mount_Icon_Spider-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-White.png");
}

.Mount_Icon_Spider-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Spider-Zombie.png");
}

.Mount_Icon_Squirrel-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Base.png");
}

.Mount_Icon_Squirrel-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-CottonCandyBlue.png");
}

.Mount_Icon_Squirrel-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-CottonCandyPink.png");
}

.Mount_Icon_Squirrel-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Desert.png");
}

.Mount_Icon_Squirrel-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Golden.png");
}

.Mount_Icon_Squirrel-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Red.png");
}

.Mount_Icon_Squirrel-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Shade.png");
}

.Mount_Icon_Squirrel-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Skeleton.png");
}

.Mount_Icon_Squirrel-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-White.png");
}

.Mount_Icon_Squirrel-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Squirrel-Zombie.png");
}

.Mount_Icon_TRex-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Base.png");
}

.Mount_Icon_TRex-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-CottonCandyBlue.png");
}

.Mount_Icon_TRex-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-CottonCandyPink.png");
}

.Mount_Icon_TRex-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Desert.png");
}

.Mount_Icon_TRex-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Golden.png");
}

.Mount_Icon_TRex-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Red.png");
}

.Mount_Icon_TRex-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Shade.png");
}

.Mount_Icon_TRex-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Skeleton.png");
}

.Mount_Icon_TRex-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-White.png");
}

.Mount_Icon_TRex-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TRex-Zombie.png");
}

.Mount_Icon_TigerCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Amber.png");
}

.Mount_Icon_TigerCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Aquatic.png");
}

.Mount_Icon_TigerCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Aurora.png");
}

.Mount_Icon_TigerCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-AutumnLeaf.png");
}

.Mount_Icon_TigerCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Base.png");
}

.Mount_Icon_TigerCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-BirchBark.png");
}

.Mount_Icon_TigerCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-BlackPearl.png");
}

.Mount_Icon_TigerCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Bronze.png");
}

.Mount_Icon_TigerCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Celestial.png");
}

.Mount_Icon_TigerCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-CottonCandyBlue.png");
}

.Mount_Icon_TigerCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-CottonCandyPink.png");
}

.Mount_Icon_TigerCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Cupid.png");
}

.Mount_Icon_TigerCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Desert.png");
}

.Mount_Icon_TigerCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Ember.png");
}

.Mount_Icon_TigerCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Fairy.png");
}

.Mount_Icon_TigerCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Floral.png");
}

.Mount_Icon_TigerCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Fluorite.png");
}

.Mount_Icon_TigerCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Frost.png");
}

.Mount_Icon_TigerCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Ghost.png");
}

.Mount_Icon_TigerCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Glass.png");
}

.Mount_Icon_TigerCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Glow.png");
}

.Mount_Icon_TigerCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Golden.png");
}

.Mount_Icon_TigerCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Holly.png");
}

.Mount_Icon_TigerCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-IcySnow.png");
}

.Mount_Icon_TigerCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Moonglow.png");
}

.Mount_Icon_TigerCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-MossyStone.png");
}

.Mount_Icon_TigerCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Onyx.png");
}

.Mount_Icon_TigerCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Peppermint.png");
}

.Mount_Icon_TigerCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-PinkMarble.png");
}

.Mount_Icon_TigerCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-PolkaDot.png");
}

.Mount_Icon_TigerCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Porcelain.png");
}

.Mount_Icon_TigerCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Rainbow.png");
}

.Mount_Icon_TigerCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Red.png");
}

.Mount_Icon_TigerCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-RoseQuartz.png");
}

.Mount_Icon_TigerCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-RoyalPurple.png");
}

.Mount_Icon_TigerCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Ruby.png");
}

.Mount_Icon_TigerCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-SandSculpture.png");
}

.Mount_Icon_TigerCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Shade.png");
}

.Mount_Icon_TigerCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Shadow.png");
}

.Mount_Icon_TigerCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Shimmer.png");
}

.Mount_Icon_TigerCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Silver.png");
}

.Mount_Icon_TigerCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Skeleton.png");
}

.Mount_Icon_TigerCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-SolarSystem.png");
}

.Mount_Icon_TigerCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Spooky.png");
}

.Mount_Icon_TigerCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-StainedGlass.png");
}

.Mount_Icon_TigerCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-StarryNight.png");
}

.Mount_Icon_TigerCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Sunset.png");
}

.Mount_Icon_TigerCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Sunshine.png");
}

.Mount_Icon_TigerCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Thunderstorm.png");
}

.Mount_Icon_TigerCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Turquoise.png");
}

.Mount_Icon_TigerCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Vampire.png");
}

.Mount_Icon_TigerCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Watery.png");
}

.Mount_Icon_TigerCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-White.png");
}

.Mount_Icon_TigerCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Windup.png");
}

.Mount_Icon_TigerCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_TigerCub-Zombie.png");
}

.Mount_Icon_Treeling-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Base.png");
}

.Mount_Icon_Treeling-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-CottonCandyBlue.png");
}

.Mount_Icon_Treeling-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-CottonCandyPink.png");
}

.Mount_Icon_Treeling-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Desert.png");
}

.Mount_Icon_Treeling-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Golden.png");
}

.Mount_Icon_Treeling-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Red.png");
}

.Mount_Icon_Treeling-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Shade.png");
}

.Mount_Icon_Treeling-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Skeleton.png");
}

.Mount_Icon_Treeling-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-White.png");
}

.Mount_Icon_Treeling-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Treeling-Zombie.png");
}

.Mount_Icon_Triceratops-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Base.png");
}

.Mount_Icon_Triceratops-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-CottonCandyBlue.png");
}

.Mount_Icon_Triceratops-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-CottonCandyPink.png");
}

.Mount_Icon_Triceratops-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Desert.png");
}

.Mount_Icon_Triceratops-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Golden.png");
}

.Mount_Icon_Triceratops-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Red.png");
}

.Mount_Icon_Triceratops-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Shade.png");
}

.Mount_Icon_Triceratops-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Skeleton.png");
}

.Mount_Icon_Triceratops-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-White.png");
}

.Mount_Icon_Triceratops-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Triceratops-Zombie.png");
}

.Mount_Icon_Turkey-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turkey-Base.png");
}

.Mount_Icon_Turkey-Gilded {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turkey-Gilded.png");
}

.Mount_Icon_Turtle-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Base.png");
}

.Mount_Icon_Turtle-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-CottonCandyBlue.png");
}

.Mount_Icon_Turtle-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-CottonCandyPink.png");
}

.Mount_Icon_Turtle-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Desert.png");
}

.Mount_Icon_Turtle-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Golden.png");
}

.Mount_Icon_Turtle-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Red.png");
}

.Mount_Icon_Turtle-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Shade.png");
}

.Mount_Icon_Turtle-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Skeleton.png");
}

.Mount_Icon_Turtle-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-White.png");
}

.Mount_Icon_Turtle-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Turtle-Zombie.png");
}

.Mount_Icon_Unicorn-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Base.png");
}

.Mount_Icon_Unicorn-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-CottonCandyBlue.png");
}

.Mount_Icon_Unicorn-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-CottonCandyPink.png");
}

.Mount_Icon_Unicorn-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Desert.png");
}

.Mount_Icon_Unicorn-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Golden.png");
}

.Mount_Icon_Unicorn-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Red.png");
}

.Mount_Icon_Unicorn-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Shade.png");
}

.Mount_Icon_Unicorn-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Skeleton.png");
}

.Mount_Icon_Unicorn-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-White.png");
}

.Mount_Icon_Unicorn-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Unicorn-Zombie.png");
}

.Mount_Icon_Velociraptor-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Base.png");
}

.Mount_Icon_Velociraptor-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-CottonCandyBlue.png");
}

.Mount_Icon_Velociraptor-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-CottonCandyPink.png");
}

.Mount_Icon_Velociraptor-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Desert.png");
}

.Mount_Icon_Velociraptor-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Golden.png");
}

.Mount_Icon_Velociraptor-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Red.png");
}

.Mount_Icon_Velociraptor-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Shade.png");
}

.Mount_Icon_Velociraptor-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Skeleton.png");
}

.Mount_Icon_Velociraptor-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-White.png");
}

.Mount_Icon_Velociraptor-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Velociraptor-Zombie.png");
}

.Mount_Icon_Whale-Base {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Base.png");
}

.Mount_Icon_Whale-CottonCandyBlue {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-CottonCandyBlue.png");
}

.Mount_Icon_Whale-CottonCandyPink {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-CottonCandyPink.png");
}

.Mount_Icon_Whale-Desert {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Desert.png");
}

.Mount_Icon_Whale-Golden {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Golden.png");
}

.Mount_Icon_Whale-Red {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Red.png");
}

.Mount_Icon_Whale-Shade {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Shade.png");
}

.Mount_Icon_Whale-Skeleton {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Skeleton.png");
}

.Mount_Icon_Whale-White {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-White.png");
}

.Mount_Icon_Whale-Zombie {
  width: 78px;
  height: 86px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Whale-Zombie.png");
}

.Mount_Icon_Wolf-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Amber.png");
}

.Mount_Icon_Wolf-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Aquatic.png");
}

.Mount_Icon_Wolf-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Aurora.png");
}

.Mount_Icon_Wolf-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-AutumnLeaf.png");
}

.Mount_Icon_Wolf-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Base.png");
}

.Mount_Icon_Wolf-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-BirchBark.png");
}

.Mount_Icon_Wolf-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-BlackPearl.png");
}

.Mount_Icon_Wolf-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Bronze.png");
}

.Mount_Icon_Wolf-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Celestial.png");
}

.Mount_Icon_Wolf-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-CottonCandyBlue.png");
}

.Mount_Icon_Wolf-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-CottonCandyPink.png");
}

.Mount_Icon_Wolf-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Cupid.png");
}

.Mount_Icon_Wolf-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Desert.png");
}

.Mount_Icon_Wolf-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Ember.png");
}

.Mount_Icon_Wolf-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Fairy.png");
}

.Mount_Icon_Wolf-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Floral.png");
}

.Mount_Icon_Wolf-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Fluorite.png");
}

.Mount_Icon_Wolf-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Frost.png");
}

.Mount_Icon_Wolf-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Ghost.png");
}

.Mount_Icon_Wolf-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Glass.png");
}

.Mount_Icon_Wolf-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Glow.png");
}

.Mount_Icon_Wolf-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Golden.png");
}

.Mount_Icon_Wolf-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Holly.png");
}

.Mount_Icon_Wolf-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-IcySnow.png");
}

.Mount_Icon_Wolf-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Moonglow.png");
}

.Mount_Icon_Wolf-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-MossyStone.png");
}

.Mount_Icon_Wolf-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Onyx.png");
}

.Mount_Icon_Wolf-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Peppermint.png");
}

.Mount_Icon_Wolf-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-PinkMarble.png");
}

.Mount_Icon_Wolf-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-PolkaDot.png");
}

.Mount_Icon_Wolf-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Porcelain.png");
}

.Mount_Icon_Wolf-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Rainbow.png");
}

.Mount_Icon_Wolf-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Red.png");
}

.Mount_Icon_Wolf-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-RoseQuartz.png");
}

.Mount_Icon_Wolf-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-RoyalPurple.png");
}

.Mount_Icon_Wolf-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Ruby.png");
}

.Mount_Icon_Wolf-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-SandSculpture.png");
}

.Mount_Icon_Wolf-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Shade.png");
}

.Mount_Icon_Wolf-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Shadow.png");
}

.Mount_Icon_Wolf-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Shimmer.png");
}

.Mount_Icon_Wolf-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Silver.png");
}

.Mount_Icon_Wolf-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Skeleton.png");
}

.Mount_Icon_Wolf-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-SolarSystem.png");
}

.Mount_Icon_Wolf-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Spooky.png");
}

.Mount_Icon_Wolf-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-StainedGlass.png");
}

.Mount_Icon_Wolf-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-StarryNight.png");
}

.Mount_Icon_Wolf-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Sunset.png");
}

.Mount_Icon_Wolf-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Sunshine.png");
}

.Mount_Icon_Wolf-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Thunderstorm.png");
}

.Mount_Icon_Wolf-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Turquoise.png");
}

.Mount_Icon_Wolf-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Vampire.png");
}

.Mount_Icon_Wolf-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Watery.png");
}

.Mount_Icon_Wolf-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-White.png");
}

.Mount_Icon_Wolf-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Windup.png");
}

.Mount_Icon_Wolf-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Wolf-Zombie.png");
}

.Mount_Icon_Yarn-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Base.png");
}

.Mount_Icon_Yarn-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-CottonCandyBlue.png");
}

.Mount_Icon_Yarn-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-CottonCandyPink.png");
}

.Mount_Icon_Yarn-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Desert.png");
}

.Mount_Icon_Yarn-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Golden.png");
}

.Mount_Icon_Yarn-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Red.png");
}

.Mount_Icon_Yarn-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Shade.png");
}

.Mount_Icon_Yarn-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Skeleton.png");
}

.Mount_Icon_Yarn-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-White.png");
}

.Mount_Icon_Yarn-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Mount_Icon_Yarn-Zombie.png");
}

.Pet-Alligator-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Base.png");
}

.Pet-Alligator-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-CottonCandyBlue.png");
}

.Pet-Alligator-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-CottonCandyPink.png");
}

.Pet-Alligator-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Desert.png");
}

.Pet-Alligator-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Golden.png");
}

.Pet-Alligator-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Red.png");
}

.Pet-Alligator-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Shade.png");
}

.Pet-Alligator-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Skeleton.png");
}

.Pet-Alligator-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-White.png");
}

.Pet-Alligator-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Alligator-Zombie.png");
}

.Pet-Armadillo-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Base.png");
}

.Pet-Armadillo-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-CottonCandyBlue.png");
}

.Pet-Armadillo-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-CottonCandyPink.png");
}

.Pet-Armadillo-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Desert.png");
}

.Pet-Armadillo-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Golden.png");
}

.Pet-Armadillo-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Red.png");
}

.Pet-Armadillo-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Shade.png");
}

.Pet-Armadillo-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Skeleton.png");
}

.Pet-Armadillo-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-White.png");
}

.Pet-Armadillo-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Armadillo-Zombie.png");
}

.Pet-Axolotl-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Base.png");
}

.Pet-Axolotl-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-CottonCandyBlue.png");
}

.Pet-Axolotl-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-CottonCandyPink.png");
}

.Pet-Axolotl-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Desert.png");
}

.Pet-Axolotl-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Golden.png");
}

.Pet-Axolotl-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Red.png");
}

.Pet-Axolotl-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Shade.png");
}

.Pet-Axolotl-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Skeleton.png");
}

.Pet-Axolotl-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-White.png");
}

.Pet-Axolotl-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Axolotl-Zombie.png");
}

.Pet-Badger-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Base.png");
}

.Pet-Badger-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-CottonCandyBlue.png");
}

.Pet-Badger-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-CottonCandyPink.png");
}

.Pet-Badger-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Desert.png");
}

.Pet-Badger-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Golden.png");
}

.Pet-Badger-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Red.png");
}

.Pet-Badger-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Shade.png");
}

.Pet-Badger-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Skeleton.png");
}

.Pet-Badger-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-White.png");
}

.Pet-Badger-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Badger-Zombie.png");
}

.Pet-Bear-Veteran {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bear-Veteran.png");
}

.Pet-BearCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Amber.png");
}

.Pet-BearCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Aquatic.png");
}

.Pet-BearCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Aurora.png");
}

.Pet-BearCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-AutumnLeaf.png");
}

.Pet-BearCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Base.png");
}

.Pet-BearCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-BirchBark.png");
}

.Pet-BearCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-BlackPearl.png");
}

.Pet-BearCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Bronze.png");
}

.Pet-BearCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Celestial.png");
}

.Pet-BearCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-CottonCandyBlue.png");
}

.Pet-BearCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-CottonCandyPink.png");
}

.Pet-BearCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Cupid.png");
}

.Pet-BearCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Desert.png");
}

.Pet-BearCub-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Dessert.png");
}

.Pet-BearCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Ember.png");
}

.Pet-BearCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Fairy.png");
}

.Pet-BearCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Floral.png");
}

.Pet-BearCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Fluorite.png");
}

.Pet-BearCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Frost.png");
}

.Pet-BearCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Ghost.png");
}

.Pet-BearCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Glass.png");
}

.Pet-BearCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Glow.png");
}

.Pet-BearCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Golden.png");
}

.Pet-BearCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Holly.png");
}

.Pet-BearCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-IcySnow.png");
}

.Pet-BearCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Moonglow.png");
}

.Pet-BearCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-MossyStone.png");
}

.Pet-BearCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Onyx.png");
}

.Pet-BearCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Peppermint.png");
}

.Pet-BearCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-PinkMarble.png");
}

.Pet-BearCub-Polar {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Polar.png");
}

.Pet-BearCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-PolkaDot.png");
}

.Pet-BearCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Porcelain.png");
}

.Pet-BearCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Rainbow.png");
}

.Pet-BearCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Red.png");
}

.Pet-BearCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-RoseQuartz.png");
}

.Pet-BearCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-RoyalPurple.png");
}

.Pet-BearCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Ruby.png");
}

.Pet-BearCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-SandSculpture.png");
}

.Pet-BearCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Shade.png");
}

.Pet-BearCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Shadow.png");
}

.Pet-BearCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Shimmer.png");
}

.Pet-BearCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Silver.png");
}

.Pet-BearCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Skeleton.png");
}

.Pet-BearCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-SolarSystem.png");
}

.Pet-BearCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Spooky.png");
}

.Pet-BearCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-StainedGlass.png");
}

.Pet-BearCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-StarryNight.png");
}

.Pet-BearCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Sunset.png");
}

.Pet-BearCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Sunshine.png");
}

.Pet-BearCub-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-TeaShop.png");
}

.Pet-BearCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Thunderstorm.png");
}

.Pet-BearCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Turquoise.png");
}

.Pet-BearCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Vampire.png");
}

.Pet-BearCub-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Veggie.png");
}

.Pet-BearCub-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-VirtualPet.png");
}

.Pet-BearCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Watery.png");
}

.Pet-BearCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-White.png");
}

.Pet-BearCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Windup.png");
}

.Pet-BearCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-BearCub-Zombie.png");
}

.Pet-Beetle-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Base.png");
}

.Pet-Beetle-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-CottonCandyBlue.png");
}

.Pet-Beetle-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-CottonCandyPink.png");
}

.Pet-Beetle-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Desert.png");
}

.Pet-Beetle-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Golden.png");
}

.Pet-Beetle-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Red.png");
}

.Pet-Beetle-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Shade.png");
}

.Pet-Beetle-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Skeleton.png");
}

.Pet-Beetle-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-White.png");
}

.Pet-Beetle-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Beetle-Zombie.png");
}

.Pet-Bunny-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Base.png");
}

.Pet-Bunny-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-CottonCandyBlue.png");
}

.Pet-Bunny-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-CottonCandyPink.png");
}

.Pet-Bunny-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Desert.png");
}

.Pet-Bunny-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Golden.png");
}

.Pet-Bunny-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Red.png");
}

.Pet-Bunny-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Shade.png");
}

.Pet-Bunny-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Skeleton.png");
}

.Pet-Bunny-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-White.png");
}

.Pet-Bunny-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Bunny-Zombie.png");
}

.Pet-Butterfly-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Base.png");
}

.Pet-Butterfly-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-CottonCandyBlue.png");
}

.Pet-Butterfly-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-CottonCandyPink.png");
}

.Pet-Butterfly-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Desert.png");
}

.Pet-Butterfly-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Golden.png");
}

.Pet-Butterfly-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Red.png");
}

.Pet-Butterfly-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Shade.png");
}

.Pet-Butterfly-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Skeleton.png");
}

.Pet-Butterfly-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-White.png");
}

.Pet-Butterfly-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Butterfly-Zombie.png");
}

.Pet-Cactus-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Amber.png");
}

.Pet-Cactus-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Aquatic.png");
}

.Pet-Cactus-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Aurora.png");
}

.Pet-Cactus-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-AutumnLeaf.png");
}

.Pet-Cactus-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Base.png");
}

.Pet-Cactus-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-BirchBark.png");
}

.Pet-Cactus-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-BlackPearl.png");
}

.Pet-Cactus-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Bronze.png");
}

.Pet-Cactus-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Celestial.png");
}

.Pet-Cactus-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-CottonCandyBlue.png");
}

.Pet-Cactus-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-CottonCandyPink.png");
}

.Pet-Cactus-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Cupid.png");
}

.Pet-Cactus-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Desert.png");
}

.Pet-Cactus-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Dessert.png");
}

.Pet-Cactus-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Ember.png");
}

.Pet-Cactus-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Fairy.png");
}

.Pet-Cactus-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Floral.png");
}

.Pet-Cactus-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Fluorite.png");
}

.Pet-Cactus-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Frost.png");
}

.Pet-Cactus-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Ghost.png");
}

.Pet-Cactus-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Glass.png");
}

.Pet-Cactus-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Glow.png");
}

.Pet-Cactus-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Golden.png");
}

.Pet-Cactus-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Holly.png");
}

.Pet-Cactus-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-IcySnow.png");
}

.Pet-Cactus-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Moonglow.png");
}

.Pet-Cactus-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-MossyStone.png");
}

.Pet-Cactus-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Onyx.png");
}

.Pet-Cactus-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Peppermint.png");
}

.Pet-Cactus-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-PinkMarble.png");
}

.Pet-Cactus-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-PolkaDot.png");
}

.Pet-Cactus-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Porcelain.png");
}

.Pet-Cactus-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Rainbow.png");
}

.Pet-Cactus-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Red.png");
}

.Pet-Cactus-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-RoseQuartz.png");
}

.Pet-Cactus-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-RoyalPurple.png");
}

.Pet-Cactus-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Ruby.png");
}

.Pet-Cactus-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-SandSculpture.png");
}

.Pet-Cactus-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Shade.png");
}

.Pet-Cactus-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Shadow.png");
}

.Pet-Cactus-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Shimmer.png");
}

.Pet-Cactus-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Silver.png");
}

.Pet-Cactus-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Skeleton.png");
}

.Pet-Cactus-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-SolarSystem.png");
}

.Pet-Cactus-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Spooky.png");
}

.Pet-Cactus-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-StainedGlass.png");
}

.Pet-Cactus-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-StarryNight.png");
}

.Pet-Cactus-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Sunset.png");
}

.Pet-Cactus-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Sunshine.png");
}

.Pet-Cactus-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-TeaShop.png");
}

.Pet-Cactus-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Thunderstorm.png");
}

.Pet-Cactus-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Turquoise.png");
}

.Pet-Cactus-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Vampire.png");
}

.Pet-Cactus-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Veggie.png");
}

.Pet-Cactus-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-VirtualPet.png");
}

.Pet-Cactus-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Watery.png");
}

.Pet-Cactus-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-White.png");
}

.Pet-Cactus-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Windup.png");
}

.Pet-Cactus-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cactus-Zombie.png");
}

.Pet-Cheetah-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Base.png");
}

.Pet-Cheetah-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-CottonCandyBlue.png");
}

.Pet-Cheetah-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-CottonCandyPink.png");
}

.Pet-Cheetah-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Desert.png");
}

.Pet-Cheetah-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Golden.png");
}

.Pet-Cheetah-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Red.png");
}

.Pet-Cheetah-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Shade.png");
}

.Pet-Cheetah-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Skeleton.png");
}

.Pet-Cheetah-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-White.png");
}

.Pet-Cheetah-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cheetah-Zombie.png");
}

.Pet-Cow-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Base.png");
}

.Pet-Cow-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-CottonCandyBlue.png");
}

.Pet-Cow-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-CottonCandyPink.png");
}

.Pet-Cow-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Desert.png");
}

.Pet-Cow-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Golden.png");
}

.Pet-Cow-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Red.png");
}

.Pet-Cow-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Shade.png");
}

.Pet-Cow-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Skeleton.png");
}

.Pet-Cow-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-White.png");
}

.Pet-Cow-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cow-Zombie.png");
}

.Pet-Cuttlefish-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Base.png");
}

.Pet-Cuttlefish-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-CottonCandyBlue.png");
}

.Pet-Cuttlefish-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-CottonCandyPink.png");
}

.Pet-Cuttlefish-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Desert.png");
}

.Pet-Cuttlefish-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Golden.png");
}

.Pet-Cuttlefish-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Red.png");
}

.Pet-Cuttlefish-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Shade.png");
}

.Pet-Cuttlefish-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Skeleton.png");
}

.Pet-Cuttlefish-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-White.png");
}

.Pet-Cuttlefish-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Cuttlefish-Zombie.png");
}

.Pet-Deer-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Base.png");
}

.Pet-Deer-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-CottonCandyBlue.png");
}

.Pet-Deer-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-CottonCandyPink.png");
}

.Pet-Deer-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Desert.png");
}

.Pet-Deer-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Golden.png");
}

.Pet-Deer-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Red.png");
}

.Pet-Deer-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Shade.png");
}

.Pet-Deer-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Skeleton.png");
}

.Pet-Deer-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-White.png");
}

.Pet-Deer-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Deer-Zombie.png");
}

.Pet-Dolphin-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Base.png");
}

.Pet-Dolphin-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-CottonCandyBlue.png");
}

.Pet-Dolphin-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-CottonCandyPink.png");
}

.Pet-Dolphin-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Desert.png");
}

.Pet-Dolphin-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Golden.png");
}

.Pet-Dolphin-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Red.png");
}

.Pet-Dolphin-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Shade.png");
}

.Pet-Dolphin-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Skeleton.png");
}

.Pet-Dolphin-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-White.png");
}

.Pet-Dolphin-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dolphin-Zombie.png");
}

.Pet-Dragon-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Amber.png");
}

.Pet-Dragon-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Aquatic.png");
}

.Pet-Dragon-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Aurora.png");
}

.Pet-Dragon-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-AutumnLeaf.png");
}

.Pet-Dragon-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Base.png");
}

.Pet-Dragon-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-BirchBark.png");
}

.Pet-Dragon-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-BlackPearl.png");
}

.Pet-Dragon-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Bronze.png");
}

.Pet-Dragon-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Celestial.png");
}

.Pet-Dragon-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-CottonCandyBlue.png");
}

.Pet-Dragon-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-CottonCandyPink.png");
}

.Pet-Dragon-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Cupid.png");
}

.Pet-Dragon-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Desert.png");
}

.Pet-Dragon-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Dessert.png");
}

.Pet-Dragon-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Ember.png");
}

.Pet-Dragon-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Fairy.png");
}

.Pet-Dragon-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Floral.png");
}

.Pet-Dragon-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Fluorite.png");
}

.Pet-Dragon-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Frost.png");
}

.Pet-Dragon-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Ghost.png");
}

.Pet-Dragon-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Glass.png");
}

.Pet-Dragon-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Glow.png");
}

.Pet-Dragon-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Golden.png");
}

.Pet-Dragon-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Holly.png");
}

.Pet-Dragon-Hydra {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Hydra.png");
}

.Pet-Dragon-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-IcySnow.png");
}

.Pet-Dragon-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Moonglow.png");
}

.Pet-Dragon-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-MossyStone.png");
}

.Pet-Dragon-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Onyx.png");
}

.Pet-Dragon-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Peppermint.png");
}

.Pet-Dragon-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-PinkMarble.png");
}

.Pet-Dragon-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-PolkaDot.png");
}

.Pet-Dragon-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Porcelain.png");
}

.Pet-Dragon-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Rainbow.png");
}

.Pet-Dragon-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Red.png");
}

.Pet-Dragon-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-RoseQuartz.png");
}

.Pet-Dragon-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-RoyalPurple.png");
}

.Pet-Dragon-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Ruby.png");
}

.Pet-Dragon-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-SandSculpture.png");
}

.Pet-Dragon-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Shade.png");
}

.Pet-Dragon-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Shadow.png");
}

.Pet-Dragon-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Shimmer.png");
}

.Pet-Dragon-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Silver.png");
}

.Pet-Dragon-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Skeleton.png");
}

.Pet-Dragon-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-SolarSystem.png");
}

.Pet-Dragon-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Spooky.png");
}

.Pet-Dragon-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-StainedGlass.png");
}

.Pet-Dragon-StarryNight {
  width: 81px;
  height: 102px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-StarryNight.png");
}

.Pet-Dragon-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Sunset.png");
}

.Pet-Dragon-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Sunshine.png");
}

.Pet-Dragon-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-TeaShop.png");
}

.Pet-Dragon-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Thunderstorm.png");
}

.Pet-Dragon-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Turquoise.png");
}

.Pet-Dragon-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Vampire.png");
}

.Pet-Dragon-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Veggie.png");
}

.Pet-Dragon-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-VirtualPet.png");
}

.Pet-Dragon-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Watery.png");
}

.Pet-Dragon-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-White.png");
}

.Pet-Dragon-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Windup.png");
}

.Pet-Dragon-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Dragon-Zombie.png");
}

.Pet-Egg-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Base.png");
}

.Pet-Egg-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-CottonCandyBlue.png");
}

.Pet-Egg-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-CottonCandyPink.png");
}

.Pet-Egg-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Desert.png");
}

.Pet-Egg-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Golden.png");
}

.Pet-Egg-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Red.png");
}

.Pet-Egg-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Shade.png");
}

.Pet-Egg-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Skeleton.png");
}

.Pet-Egg-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-White.png");
}

.Pet-Egg-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Egg-Zombie.png");
}

.Pet-Falcon-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Base.png");
}

.Pet-Falcon-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-CottonCandyBlue.png");
}

.Pet-Falcon-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-CottonCandyPink.png");
}

.Pet-Falcon-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Desert.png");
}

.Pet-Falcon-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Golden.png");
}

.Pet-Falcon-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Red.png");
}

.Pet-Falcon-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Shade.png");
}

.Pet-Falcon-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Skeleton.png");
}

.Pet-Falcon-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-White.png");
}

.Pet-Falcon-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Falcon-Zombie.png");
}

.Pet-Ferret-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Base.png");
}

.Pet-Ferret-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-CottonCandyBlue.png");
}

.Pet-Ferret-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-CottonCandyPink.png");
}

.Pet-Ferret-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Desert.png");
}

.Pet-Ferret-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Golden.png");
}

.Pet-Ferret-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Red.png");
}

.Pet-Ferret-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Shade.png");
}

.Pet-Ferret-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Skeleton.png");
}

.Pet-Ferret-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-White.png");
}

.Pet-Ferret-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Ferret-Zombie.png");
}

.Pet-FlyingPig-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Amber.png");
}

.Pet-FlyingPig-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Aquatic.png");
}

.Pet-FlyingPig-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Aurora.png");
}

.Pet-FlyingPig-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-AutumnLeaf.png");
}

.Pet-FlyingPig-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Base.png");
}

.Pet-FlyingPig-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-BirchBark.png");
}

.Pet-FlyingPig-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-BlackPearl.png");
}

.Pet-FlyingPig-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Bronze.png");
}

.Pet-FlyingPig-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Celestial.png");
}

.Pet-FlyingPig-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-CottonCandyBlue.png");
}

.Pet-FlyingPig-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-CottonCandyPink.png");
}

.Pet-FlyingPig-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Cupid.png");
}

.Pet-FlyingPig-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Desert.png");
}

.Pet-FlyingPig-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Dessert.png");
}

.Pet-FlyingPig-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Ember.png");
}

.Pet-FlyingPig-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Fairy.png");
}

.Pet-FlyingPig-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Floral.png");
}

.Pet-FlyingPig-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Fluorite.png");
}

.Pet-FlyingPig-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Frost.png");
}

.Pet-FlyingPig-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Ghost.png");
}

.Pet-FlyingPig-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Glass.png");
}

.Pet-FlyingPig-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Glow.png");
}

.Pet-FlyingPig-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Golden.png");
}

.Pet-FlyingPig-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Holly.png");
}

.Pet-FlyingPig-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-IcySnow.png");
}

.Pet-FlyingPig-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Moonglow.png");
}

.Pet-FlyingPig-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-MossyStone.png");
}

.Pet-FlyingPig-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Onyx.png");
}

.Pet-FlyingPig-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Peppermint.png");
}

.Pet-FlyingPig-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-PinkMarble.png");
}

.Pet-FlyingPig-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-PolkaDot.png");
}

.Pet-FlyingPig-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Porcelain.png");
}

.Pet-FlyingPig-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Rainbow.png");
}

.Pet-FlyingPig-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Red.png");
}

.Pet-FlyingPig-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-RoseQuartz.png");
}

.Pet-FlyingPig-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-RoyalPurple.png");
}

.Pet-FlyingPig-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Ruby.png");
}

.Pet-FlyingPig-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-SandSculpture.png");
}

.Pet-FlyingPig-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Shade.png");
}

.Pet-FlyingPig-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Shadow.png");
}

.Pet-FlyingPig-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Shimmer.png");
}

.Pet-FlyingPig-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Silver.png");
}

.Pet-FlyingPig-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Skeleton.png");
}

.Pet-FlyingPig-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-SolarSystem.png");
}

.Pet-FlyingPig-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Spooky.png");
}

.Pet-FlyingPig-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-StainedGlass.png");
}

.Pet-FlyingPig-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-StarryNight.png");
}

.Pet-FlyingPig-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Sunset.png");
}

.Pet-FlyingPig-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Sunshine.png");
}

.Pet-FlyingPig-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-TeaShop.png");
}

.Pet-FlyingPig-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Thunderstorm.png");
}

.Pet-FlyingPig-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Turquoise.png");
}

.Pet-FlyingPig-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Vampire.png");
}

.Pet-FlyingPig-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Veggie.png");
}

.Pet-FlyingPig-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-VirtualPet.png");
}

.Pet-FlyingPig-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Watery.png");
}

.Pet-FlyingPig-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-White.png");
}

.Pet-FlyingPig-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Windup.png");
}

.Pet-FlyingPig-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-FlyingPig-Zombie.png");
}

.Pet-Fox-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Amber.png");
}

.Pet-Fox-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Aquatic.png");
}

.Pet-Fox-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Aurora.png");
}

.Pet-Fox-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-AutumnLeaf.png");
}

.Pet-Fox-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Base.png");
}

.Pet-Fox-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-BirchBark.png");
}

.Pet-Fox-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-BlackPearl.png");
}

.Pet-Fox-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Bronze.png");
}

.Pet-Fox-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Celestial.png");
}

.Pet-Fox-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-CottonCandyBlue.png");
}

.Pet-Fox-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-CottonCandyPink.png");
}

.Pet-Fox-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Cupid.png");
}

.Pet-Fox-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Desert.png");
}

.Pet-Fox-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Dessert.png");
}

.Pet-Fox-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Ember.png");
}

.Pet-Fox-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Fairy.png");
}

.Pet-Fox-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Floral.png");
}

.Pet-Fox-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Fluorite.png");
}

.Pet-Fox-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Frost.png");
}

.Pet-Fox-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Ghost.png");
}

.Pet-Fox-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Glass.png");
}

.Pet-Fox-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Glow.png");
}

.Pet-Fox-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Golden.png");
}

.Pet-Fox-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Holly.png");
}

.Pet-Fox-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-IcySnow.png");
}

.Pet-Fox-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Moonglow.png");
}

.Pet-Fox-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-MossyStone.png");
}

.Pet-Fox-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Onyx.png");
}

.Pet-Fox-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Peppermint.png");
}

.Pet-Fox-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-PinkMarble.png");
}

.Pet-Fox-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-PolkaDot.png");
}

.Pet-Fox-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Porcelain.png");
}

.Pet-Fox-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Rainbow.png");
}

.Pet-Fox-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Red.png");
}

.Pet-Fox-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-RoseQuartz.png");
}

.Pet-Fox-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-RoyalPurple.png");
}

.Pet-Fox-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Ruby.png");
}

.Pet-Fox-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-SandSculpture.png");
}

.Pet-Fox-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Shade.png");
}

.Pet-Fox-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Shadow.png");
}

.Pet-Fox-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Shimmer.png");
}

.Pet-Fox-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Silver.png");
}

.Pet-Fox-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Skeleton.png");
}

.Pet-Fox-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-SolarSystem.png");
}

.Pet-Fox-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Spooky.png");
}

.Pet-Fox-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-StainedGlass.png");
}

.Pet-Fox-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-StarryNight.png");
}

.Pet-Fox-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Sunset.png");
}

.Pet-Fox-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Sunshine.png");
}

.Pet-Fox-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-TeaShop.png");
}

.Pet-Fox-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Thunderstorm.png");
}

.Pet-Fox-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Turquoise.png");
}

.Pet-Fox-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Vampire.png");
}

.Pet-Fox-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Veggie.png");
}

.Pet-Fox-Veteran {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Veteran.png");
}

.Pet-Fox-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-VirtualPet.png");
}

.Pet-Fox-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Watery.png");
}

.Pet-Fox-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-White.png");
}

.Pet-Fox-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Windup.png");
}

.Pet-Fox-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Fox-Zombie.png");
}

.Pet-Frog-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Base.png");
}

.Pet-Frog-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-CottonCandyBlue.png");
}

.Pet-Frog-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-CottonCandyPink.png");
}

.Pet-Frog-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Desert.png");
}

.Pet-Frog-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Golden.png");
}

.Pet-Frog-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Red.png");
}

.Pet-Frog-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Shade.png");
}

.Pet-Frog-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Skeleton.png");
}

.Pet-Frog-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-White.png");
}

.Pet-Frog-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Frog-Zombie.png");
}

.Pet-Gryphon-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Base.png");
}

.Pet-Gryphon-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-CottonCandyBlue.png");
}

.Pet-Gryphon-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-CottonCandyPink.png");
}

.Pet-Gryphon-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Desert.png");
}

.Pet-Gryphon-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Golden.png");
}

.Pet-Gryphon-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Red.png");
}

.Pet-Gryphon-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-RoyalPurple.png");
}

.Pet-Gryphon-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Shade.png");
}

.Pet-Gryphon-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Skeleton.png");
}

.Pet-Gryphon-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-White.png");
}

.Pet-Gryphon-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Gryphon-Zombie.png");
}

.Pet-GuineaPig-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Base.png");
}

.Pet-GuineaPig-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-CottonCandyBlue.png");
}

.Pet-GuineaPig-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-CottonCandyPink.png");
}

.Pet-GuineaPig-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Desert.png");
}

.Pet-GuineaPig-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Golden.png");
}

.Pet-GuineaPig-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Red.png");
}

.Pet-GuineaPig-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Shade.png");
}

.Pet-GuineaPig-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Skeleton.png");
}

.Pet-GuineaPig-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-White.png");
}

.Pet-GuineaPig-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-GuineaPig-Zombie.png");
}

.Pet-Hedgehog-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Base.png");
}

.Pet-Hedgehog-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-CottonCandyBlue.png");
}

.Pet-Hedgehog-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-CottonCandyPink.png");
}

.Pet-Hedgehog-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Desert.png");
}

.Pet-Hedgehog-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Golden.png");
}

.Pet-Hedgehog-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Red.png");
}

.Pet-Hedgehog-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Shade.png");
}

.Pet-Hedgehog-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Skeleton.png");
}

.Pet-Hedgehog-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-White.png");
}

.Pet-Hedgehog-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hedgehog-Zombie.png");
}

.Pet-Hippo-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Base.png");
}

.Pet-Hippo-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-CottonCandyBlue.png");
}

.Pet-Hippo-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-CottonCandyPink.png");
}

.Pet-Hippo-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Desert.png");
}

.Pet-Hippo-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Golden.png");
}

.Pet-Hippo-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Red.png");
}

.Pet-Hippo-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Shade.png");
}

.Pet-Hippo-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Skeleton.png");
}

.Pet-Hippo-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-White.png");
}

.Pet-Hippo-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippo-Zombie.png");
}

.Pet-Hippogriff-Hopeful {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Hippogriff-Hopeful.png");
}

.Pet-Horse-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Base.png");
}

.Pet-Horse-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-CottonCandyBlue.png");
}

.Pet-Horse-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-CottonCandyPink.png");
}

.Pet-Horse-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Desert.png");
}

.Pet-Horse-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Golden.png");
}

.Pet-Horse-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Red.png");
}

.Pet-Horse-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Shade.png");
}

.Pet-Horse-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Skeleton.png");
}

.Pet-Horse-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-White.png");
}

.Pet-Horse-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Horse-Zombie.png");
}

.Pet-JackOLantern-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-Base.png");
}

.Pet-JackOLantern-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-Ghost.png");
}

.Pet-JackOLantern-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-Glow.png");
}

.Pet-JackOLantern-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-JackOLantern-RoyalPurple.png");
}

.Pet-Jackalope-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Jackalope-RoyalPurple.png");
}

.Pet-Kangaroo-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Base.png");
}

.Pet-Kangaroo-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-CottonCandyBlue.png");
}

.Pet-Kangaroo-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-CottonCandyPink.png");
}

.Pet-Kangaroo-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Desert.png");
}

.Pet-Kangaroo-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Golden.png");
}

.Pet-Kangaroo-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Red.png");
}

.Pet-Kangaroo-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Shade.png");
}

.Pet-Kangaroo-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Skeleton.png");
}

.Pet-Kangaroo-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-White.png");
}

.Pet-Kangaroo-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Kangaroo-Zombie.png");
}

.Pet-Lion-Veteran {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Lion-Veteran.png");
}

.Pet-LionCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Amber.png");
}

.Pet-LionCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Aquatic.png");
}

.Pet-LionCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Aurora.png");
}

.Pet-LionCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-AutumnLeaf.png");
}

.Pet-LionCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Base.png");
}

.Pet-LionCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-BirchBark.png");
}

.Pet-LionCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-BlackPearl.png");
}

.Pet-LionCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Bronze.png");
}

.Pet-LionCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Celestial.png");
}

.Pet-LionCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-CottonCandyBlue.png");
}

.Pet-LionCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-CottonCandyPink.png");
}

.Pet-LionCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Cupid.png");
}

.Pet-LionCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Desert.png");
}

.Pet-LionCub-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Dessert.png");
}

.Pet-LionCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Ember.png");
}

.Pet-LionCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Fairy.png");
}

.Pet-LionCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Floral.png");
}

.Pet-LionCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Fluorite.png");
}

.Pet-LionCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Frost.png");
}

.Pet-LionCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Ghost.png");
}

.Pet-LionCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Glass.png");
}

.Pet-LionCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Glow.png");
}

.Pet-LionCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Golden.png");
}

.Pet-LionCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Holly.png");
}

.Pet-LionCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-IcySnow.png");
}

.Pet-LionCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Moonglow.png");
}

.Pet-LionCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-MossyStone.png");
}

.Pet-LionCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Onyx.png");
}

.Pet-LionCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Peppermint.png");
}

.Pet-LionCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-PinkMarble.png");
}

.Pet-LionCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-PolkaDot.png");
}

.Pet-LionCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Porcelain.png");
}

.Pet-LionCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Rainbow.png");
}

.Pet-LionCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Red.png");
}

.Pet-LionCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-RoseQuartz.png");
}

.Pet-LionCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-RoyalPurple.png");
}

.Pet-LionCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Ruby.png");
}

.Pet-LionCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-SandSculpture.png");
}

.Pet-LionCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Shade.png");
}

.Pet-LionCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Shadow.png");
}

.Pet-LionCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Shimmer.png");
}

.Pet-LionCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Silver.png");
}

.Pet-LionCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Skeleton.png");
}

.Pet-LionCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-SolarSystem.png");
}

.Pet-LionCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Spooky.png");
}

.Pet-LionCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-StainedGlass.png");
}

.Pet-LionCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-StarryNight.png");
}

.Pet-LionCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Sunset.png");
}

.Pet-LionCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Sunshine.png");
}

.Pet-LionCub-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-TeaShop.png");
}

.Pet-LionCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Thunderstorm.png");
}

.Pet-LionCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Turquoise.png");
}

.Pet-LionCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Vampire.png");
}

.Pet-LionCub-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Veggie.png");
}

.Pet-LionCub-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-VirtualPet.png");
}

.Pet-LionCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Watery.png");
}

.Pet-LionCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-White.png");
}

.Pet-LionCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Windup.png");
}

.Pet-LionCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-LionCub-Zombie.png");
}

.Pet-MagicalBee-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-MagicalBee-Base.png");
}

.Pet-Mammoth-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Mammoth-Base.png");
}

.Pet-MantisShrimp-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-MantisShrimp-Base.png");
}

.Pet-Monkey-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Base.png");
}

.Pet-Monkey-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-CottonCandyBlue.png");
}

.Pet-Monkey-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-CottonCandyPink.png");
}

.Pet-Monkey-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Desert.png");
}

.Pet-Monkey-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Golden.png");
}

.Pet-Monkey-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Red.png");
}

.Pet-Monkey-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Shade.png");
}

.Pet-Monkey-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Skeleton.png");
}

.Pet-Monkey-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-White.png");
}

.Pet-Monkey-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Monkey-Zombie.png");
}

.Pet-Nudibranch-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Base.png");
}

.Pet-Nudibranch-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-CottonCandyBlue.png");
}

.Pet-Nudibranch-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-CottonCandyPink.png");
}

.Pet-Nudibranch-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Desert.png");
}

.Pet-Nudibranch-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Golden.png");
}

.Pet-Nudibranch-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Red.png");
}

.Pet-Nudibranch-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Shade.png");
}

.Pet-Nudibranch-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Skeleton.png");
}

.Pet-Nudibranch-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-White.png");
}

.Pet-Nudibranch-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Nudibranch-Zombie.png");
}

.Pet-Octopus-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Base.png");
}

.Pet-Octopus-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-CottonCandyBlue.png");
}

.Pet-Octopus-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-CottonCandyPink.png");
}

.Pet-Octopus-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Desert.png");
}

.Pet-Octopus-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Golden.png");
}

.Pet-Octopus-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Red.png");
}

.Pet-Octopus-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Shade.png");
}

.Pet-Octopus-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Skeleton.png");
}

.Pet-Octopus-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-White.png");
}

.Pet-Octopus-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Octopus-Zombie.png");
}

.Pet-Orca-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Orca-Base.png");
}

.Pet-Owl-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Base.png");
}

.Pet-Owl-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-CottonCandyBlue.png");
}

.Pet-Owl-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-CottonCandyPink.png");
}

.Pet-Owl-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Desert.png");
}

.Pet-Owl-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Golden.png");
}

.Pet-Owl-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Red.png");
}

.Pet-Owl-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Shade.png");
}

.Pet-Owl-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Skeleton.png");
}

.Pet-Owl-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-White.png");
}

.Pet-Owl-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Owl-Zombie.png");
}

.Pet-PandaCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Amber.png");
}

.Pet-PandaCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Aquatic.png");
}

.Pet-PandaCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Aurora.png");
}

.Pet-PandaCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-AutumnLeaf.png");
}

.Pet-PandaCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Base.png");
}

.Pet-PandaCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-BirchBark.png");
}

.Pet-PandaCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-BlackPearl.png");
}

.Pet-PandaCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Bronze.png");
}

.Pet-PandaCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Celestial.png");
}

.Pet-PandaCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-CottonCandyBlue.png");
}

.Pet-PandaCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-CottonCandyPink.png");
}

.Pet-PandaCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Cupid.png");
}

.Pet-PandaCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Desert.png");
}

.Pet-PandaCub-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Dessert.png");
}

.Pet-PandaCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Ember.png");
}

.Pet-PandaCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Fairy.png");
}

.Pet-PandaCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Floral.png");
}

.Pet-PandaCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Fluorite.png");
}

.Pet-PandaCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Frost.png");
}

.Pet-PandaCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Ghost.png");
}

.Pet-PandaCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Glass.png");
}

.Pet-PandaCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Glow.png");
}

.Pet-PandaCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Golden.png");
}

.Pet-PandaCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Holly.png");
}

.Pet-PandaCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-IcySnow.png");
}

.Pet-PandaCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Moonglow.png");
}

.Pet-PandaCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-MossyStone.png");
}

.Pet-PandaCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Onyx.png");
}

.Pet-PandaCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Peppermint.png");
}

.Pet-PandaCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-PinkMarble.png");
}

.Pet-PandaCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-PolkaDot.png");
}

.Pet-PandaCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Porcelain.png");
}

.Pet-PandaCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Rainbow.png");
}

.Pet-PandaCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Red.png");
}

.Pet-PandaCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-RoseQuartz.png");
}

.Pet-PandaCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-RoyalPurple.png");
}

.Pet-PandaCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Ruby.png");
}

.Pet-PandaCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-SandSculpture.png");
}

.Pet-PandaCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Shade.png");
}

.Pet-PandaCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Shadow.png");
}

.Pet-PandaCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Shimmer.png");
}

.Pet-PandaCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Silver.png");
}

.Pet-PandaCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Skeleton.png");
}

.Pet-PandaCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-SolarSystem.png");
}

.Pet-PandaCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Spooky.png");
}

.Pet-PandaCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-StainedGlass.png");
}

.Pet-PandaCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-StarryNight.png");
}

.Pet-PandaCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Sunset.png");
}

.Pet-PandaCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Sunshine.png");
}

.Pet-PandaCub-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-TeaShop.png");
}

.Pet-PandaCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Thunderstorm.png");
}

.Pet-PandaCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Turquoise.png");
}

.Pet-PandaCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Vampire.png");
}

.Pet-PandaCub-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Veggie.png");
}

.Pet-PandaCub-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-VirtualPet.png");
}

.Pet-PandaCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Watery.png");
}

.Pet-PandaCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-White.png");
}

.Pet-PandaCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Windup.png");
}

.Pet-PandaCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-PandaCub-Zombie.png");
}

.Pet-Parrot-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Base.png");
}

.Pet-Parrot-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-CottonCandyBlue.png");
}

.Pet-Parrot-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-CottonCandyPink.png");
}

.Pet-Parrot-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Desert.png");
}

.Pet-Parrot-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Golden.png");
}

.Pet-Parrot-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Red.png");
}

.Pet-Parrot-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Shade.png");
}

.Pet-Parrot-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Skeleton.png");
}

.Pet-Parrot-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-White.png");
}

.Pet-Parrot-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Parrot-Zombie.png");
}

.Pet-Peacock-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Base.png");
}

.Pet-Peacock-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-CottonCandyBlue.png");
}

.Pet-Peacock-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-CottonCandyPink.png");
}

.Pet-Peacock-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Desert.png");
}

.Pet-Peacock-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Golden.png");
}

.Pet-Peacock-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Red.png");
}

.Pet-Peacock-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Shade.png");
}

.Pet-Peacock-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Skeleton.png");
}

.Pet-Peacock-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-White.png");
}

.Pet-Peacock-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Peacock-Zombie.png");
}

.Pet-Penguin-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Base.png");
}

.Pet-Penguin-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-CottonCandyBlue.png");
}

.Pet-Penguin-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-CottonCandyPink.png");
}

.Pet-Penguin-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Desert.png");
}

.Pet-Penguin-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Golden.png");
}

.Pet-Penguin-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Red.png");
}

.Pet-Penguin-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Shade.png");
}

.Pet-Penguin-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Skeleton.png");
}

.Pet-Penguin-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-White.png");
}

.Pet-Penguin-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Penguin-Zombie.png");
}

.Pet-Phoenix-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Phoenix-Base.png");
}

.Pet-Pterodactyl-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Base.png");
}

.Pet-Pterodactyl-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-CottonCandyBlue.png");
}

.Pet-Pterodactyl-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-CottonCandyPink.png");
}

.Pet-Pterodactyl-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Desert.png");
}

.Pet-Pterodactyl-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Golden.png");
}

.Pet-Pterodactyl-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Red.png");
}

.Pet-Pterodactyl-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Shade.png");
}

.Pet-Pterodactyl-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Skeleton.png");
}

.Pet-Pterodactyl-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-White.png");
}

.Pet-Pterodactyl-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Pterodactyl-Zombie.png");
}

.Pet-Rat-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Base.png");
}

.Pet-Rat-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-CottonCandyBlue.png");
}

.Pet-Rat-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-CottonCandyPink.png");
}

.Pet-Rat-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Desert.png");
}

.Pet-Rat-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Golden.png");
}

.Pet-Rat-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Red.png");
}

.Pet-Rat-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Shade.png");
}

.Pet-Rat-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Skeleton.png");
}

.Pet-Rat-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-White.png");
}

.Pet-Rat-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rat-Zombie.png");
}

.Pet-Robot-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Base.png");
}

.Pet-Robot-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-CottonCandyBlue.png");
}

.Pet-Robot-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-CottonCandyPink.png");
}

.Pet-Robot-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Desert.png");
}

.Pet-Robot-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Golden.png");
}

.Pet-Robot-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Red.png");
}

.Pet-Robot-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Shade.png");
}

.Pet-Robot-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Skeleton.png");
}

.Pet-Robot-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-White.png");
}

.Pet-Robot-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Robot-Zombie.png");
}

.Pet-Rock-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Base.png");
}

.Pet-Rock-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-CottonCandyBlue.png");
}

.Pet-Rock-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-CottonCandyPink.png");
}

.Pet-Rock-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Desert.png");
}

.Pet-Rock-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Golden.png");
}

.Pet-Rock-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Red.png");
}

.Pet-Rock-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Shade.png");
}

.Pet-Rock-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Skeleton.png");
}

.Pet-Rock-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-White.png");
}

.Pet-Rock-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rock-Zombie.png");
}

.Pet-Rooster-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Base.png");
}

.Pet-Rooster-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-CottonCandyBlue.png");
}

.Pet-Rooster-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-CottonCandyPink.png");
}

.Pet-Rooster-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Desert.png");
}

.Pet-Rooster-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Golden.png");
}

.Pet-Rooster-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Red.png");
}

.Pet-Rooster-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Shade.png");
}

.Pet-Rooster-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Skeleton.png");
}

.Pet-Rooster-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-White.png");
}

.Pet-Rooster-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Rooster-Zombie.png");
}

.Pet-Sabretooth-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Base.png");
}

.Pet-Sabretooth-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-CottonCandyBlue.png");
}

.Pet-Sabretooth-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-CottonCandyPink.png");
}

.Pet-Sabretooth-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Desert.png");
}

.Pet-Sabretooth-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Golden.png");
}

.Pet-Sabretooth-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Red.png");
}

.Pet-Sabretooth-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Shade.png");
}

.Pet-Sabretooth-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Skeleton.png");
}

.Pet-Sabretooth-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-White.png");
}

.Pet-Sabretooth-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sabretooth-Zombie.png");
}

.Pet-SeaSerpent-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Base.png");
}

.Pet-SeaSerpent-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-CottonCandyBlue.png");
}

.Pet-SeaSerpent-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-CottonCandyPink.png");
}

.Pet-SeaSerpent-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Desert.png");
}

.Pet-SeaSerpent-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Golden.png");
}

.Pet-SeaSerpent-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Red.png");
}

.Pet-SeaSerpent-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Shade.png");
}

.Pet-SeaSerpent-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Skeleton.png");
}

.Pet-SeaSerpent-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-White.png");
}

.Pet-SeaSerpent-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-SeaSerpent-Zombie.png");
}

.Pet-Seahorse-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Base.png");
}

.Pet-Seahorse-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-CottonCandyBlue.png");
}

.Pet-Seahorse-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-CottonCandyPink.png");
}

.Pet-Seahorse-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Desert.png");
}

.Pet-Seahorse-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Golden.png");
}

.Pet-Seahorse-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Red.png");
}

.Pet-Seahorse-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Shade.png");
}

.Pet-Seahorse-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Skeleton.png");
}

.Pet-Seahorse-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-White.png");
}

.Pet-Seahorse-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Seahorse-Zombie.png");
}

.Pet-Sheep-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Base.png");
}

.Pet-Sheep-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-CottonCandyBlue.png");
}

.Pet-Sheep-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-CottonCandyPink.png");
}

.Pet-Sheep-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Desert.png");
}

.Pet-Sheep-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Golden.png");
}

.Pet-Sheep-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Red.png");
}

.Pet-Sheep-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Shade.png");
}

.Pet-Sheep-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Skeleton.png");
}

.Pet-Sheep-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-White.png");
}

.Pet-Sheep-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sheep-Zombie.png");
}

.Pet-Slime-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Base.png");
}

.Pet-Slime-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-CottonCandyBlue.png");
}

.Pet-Slime-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-CottonCandyPink.png");
}

.Pet-Slime-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Desert.png");
}

.Pet-Slime-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Golden.png");
}

.Pet-Slime-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Red.png");
}

.Pet-Slime-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Shade.png");
}

.Pet-Slime-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Skeleton.png");
}

.Pet-Slime-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-White.png");
}

.Pet-Slime-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Slime-Zombie.png");
}

.Pet-Sloth-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Base.png");
}

.Pet-Sloth-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-CottonCandyBlue.png");
}

.Pet-Sloth-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-CottonCandyPink.png");
}

.Pet-Sloth-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Desert.png");
}

.Pet-Sloth-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Golden.png");
}

.Pet-Sloth-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Red.png");
}

.Pet-Sloth-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Shade.png");
}

.Pet-Sloth-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Skeleton.png");
}

.Pet-Sloth-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-White.png");
}

.Pet-Sloth-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Sloth-Zombie.png");
}

.Pet-Snail-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Base.png");
}

.Pet-Snail-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-CottonCandyBlue.png");
}

.Pet-Snail-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-CottonCandyPink.png");
}

.Pet-Snail-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Desert.png");
}

.Pet-Snail-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Golden.png");
}

.Pet-Snail-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Red.png");
}

.Pet-Snail-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Shade.png");
}

.Pet-Snail-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Skeleton.png");
}

.Pet-Snail-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-White.png");
}

.Pet-Snail-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snail-Zombie.png");
}

.Pet-Snake-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Base.png");
}

.Pet-Snake-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-CottonCandyBlue.png");
}

.Pet-Snake-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-CottonCandyPink.png");
}

.Pet-Snake-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Desert.png");
}

.Pet-Snake-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Golden.png");
}

.Pet-Snake-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Red.png");
}

.Pet-Snake-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Shade.png");
}

.Pet-Snake-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Skeleton.png");
}

.Pet-Snake-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-White.png");
}

.Pet-Snake-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Snake-Zombie.png");
}

.Pet-Spider-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Base.png");
}

.Pet-Spider-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-CottonCandyBlue.png");
}

.Pet-Spider-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-CottonCandyPink.png");
}

.Pet-Spider-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Desert.png");
}

.Pet-Spider-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Golden.png");
}

.Pet-Spider-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Red.png");
}

.Pet-Spider-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Shade.png");
}

.Pet-Spider-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Skeleton.png");
}

.Pet-Spider-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-White.png");
}

.Pet-Spider-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Spider-Zombie.png");
}

.Pet-Squirrel-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Base.png");
}

.Pet-Squirrel-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-CottonCandyBlue.png");
}

.Pet-Squirrel-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-CottonCandyPink.png");
}

.Pet-Squirrel-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Desert.png");
}

.Pet-Squirrel-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Golden.png");
}

.Pet-Squirrel-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Red.png");
}

.Pet-Squirrel-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Shade.png");
}

.Pet-Squirrel-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Skeleton.png");
}

.Pet-Squirrel-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-White.png");
}

.Pet-Squirrel-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Squirrel-Zombie.png");
}

.Pet-TRex-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Base.png");
}

.Pet-TRex-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-CottonCandyBlue.png");
}

.Pet-TRex-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-CottonCandyPink.png");
}

.Pet-TRex-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Desert.png");
}

.Pet-TRex-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Golden.png");
}

.Pet-TRex-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Red.png");
}

.Pet-TRex-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Shade.png");
}

.Pet-TRex-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Skeleton.png");
}

.Pet-TRex-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-White.png");
}

.Pet-TRex-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TRex-Zombie.png");
}

.Pet-Tiger-Veteran {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Tiger-Veteran.png");
}

.Pet-TigerCub-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Amber.png");
}

.Pet-TigerCub-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Aquatic.png");
}

.Pet-TigerCub-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Aurora.png");
}

.Pet-TigerCub-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-AutumnLeaf.png");
}

.Pet-TigerCub-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Base.png");
}

.Pet-TigerCub-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-BirchBark.png");
}

.Pet-TigerCub-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-BlackPearl.png");
}

.Pet-TigerCub-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Bronze.png");
}

.Pet-TigerCub-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Celestial.png");
}

.Pet-TigerCub-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-CottonCandyBlue.png");
}

.Pet-TigerCub-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-CottonCandyPink.png");
}

.Pet-TigerCub-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Cupid.png");
}

.Pet-TigerCub-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Desert.png");
}

.Pet-TigerCub-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Dessert.png");
}

.Pet-TigerCub-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Ember.png");
}

.Pet-TigerCub-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Fairy.png");
}

.Pet-TigerCub-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Floral.png");
}

.Pet-TigerCub-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Fluorite.png");
}

.Pet-TigerCub-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Frost.png");
}

.Pet-TigerCub-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Ghost.png");
}

.Pet-TigerCub-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Glass.png");
}

.Pet-TigerCub-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Glow.png");
}

.Pet-TigerCub-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Golden.png");
}

.Pet-TigerCub-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Holly.png");
}

.Pet-TigerCub-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-IcySnow.png");
}

.Pet-TigerCub-Koi {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Koi.png");
}

.Pet-TigerCub-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Moonglow.png");
}

.Pet-TigerCub-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-MossyStone.png");
}

.Pet-TigerCub-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Onyx.png");
}

.Pet-TigerCub-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Peppermint.png");
}

.Pet-TigerCub-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-PinkMarble.png");
}

.Pet-TigerCub-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-PolkaDot.png");
}

.Pet-TigerCub-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Porcelain.png");
}

.Pet-TigerCub-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Rainbow.png");
}

.Pet-TigerCub-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Red.png");
}

.Pet-TigerCub-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-RoseQuartz.png");
}

.Pet-TigerCub-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-RoyalPurple.png");
}

.Pet-TigerCub-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Ruby.png");
}

.Pet-TigerCub-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-SandSculpture.png");
}

.Pet-TigerCub-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Shade.png");
}

.Pet-TigerCub-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Shadow.png");
}

.Pet-TigerCub-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Shimmer.png");
}

.Pet-TigerCub-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Silver.png");
}

.Pet-TigerCub-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Skeleton.png");
}

.Pet-TigerCub-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-SolarSystem.png");
}

.Pet-TigerCub-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Spooky.png");
}

.Pet-TigerCub-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-StainedGlass.png");
}

.Pet-TigerCub-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-StarryNight.png");
}

.Pet-TigerCub-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Sunset.png");
}

.Pet-TigerCub-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Sunshine.png");
}

.Pet-TigerCub-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-TeaShop.png");
}

.Pet-TigerCub-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Thunderstorm.png");
}

.Pet-TigerCub-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Turquoise.png");
}

.Pet-TigerCub-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Vampire.png");
}

.Pet-TigerCub-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Veggie.png");
}

.Pet-TigerCub-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-VirtualPet.png");
}

.Pet-TigerCub-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Watery.png");
}

.Pet-TigerCub-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-White.png");
}

.Pet-TigerCub-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Windup.png");
}

.Pet-TigerCub-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-TigerCub-Zombie.png");
}

.Pet-Treeling-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Base.png");
}

.Pet-Treeling-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-CottonCandyBlue.png");
}

.Pet-Treeling-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-CottonCandyPink.png");
}

.Pet-Treeling-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Desert.png");
}

.Pet-Treeling-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Golden.png");
}

.Pet-Treeling-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Red.png");
}

.Pet-Treeling-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Shade.png");
}

.Pet-Treeling-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Skeleton.png");
}

.Pet-Treeling-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-White.png");
}

.Pet-Treeling-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Treeling-Zombie.png");
}

.Pet-Triceratops-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Base.png");
}

.Pet-Triceratops-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-CottonCandyBlue.png");
}

.Pet-Triceratops-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-CottonCandyPink.png");
}

.Pet-Triceratops-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Desert.png");
}

.Pet-Triceratops-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Golden.png");
}

.Pet-Triceratops-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Red.png");
}

.Pet-Triceratops-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Shade.png");
}

.Pet-Triceratops-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Skeleton.png");
}

.Pet-Triceratops-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-White.png");
}

.Pet-Triceratops-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Triceratops-Zombie.png");
}

.Pet-Turkey-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turkey-Base.png");
}

.Pet-Turkey-Gilded {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turkey-Gilded.png");
}

.Pet-Turtle-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Base.png");
}

.Pet-Turtle-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-CottonCandyBlue.png");
}

.Pet-Turtle-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-CottonCandyPink.png");
}

.Pet-Turtle-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Desert.png");
}

.Pet-Turtle-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Golden.png");
}

.Pet-Turtle-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Red.png");
}

.Pet-Turtle-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Shade.png");
}

.Pet-Turtle-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Skeleton.png");
}

.Pet-Turtle-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-White.png");
}

.Pet-Turtle-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Turtle-Zombie.png");
}

.Pet-Unicorn-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Base.png");
}

.Pet-Unicorn-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-CottonCandyBlue.png");
}

.Pet-Unicorn-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-CottonCandyPink.png");
}

.Pet-Unicorn-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Desert.png");
}

.Pet-Unicorn-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Golden.png");
}

.Pet-Unicorn-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Red.png");
}

.Pet-Unicorn-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Shade.png");
}

.Pet-Unicorn-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Skeleton.png");
}

.Pet-Unicorn-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-White.png");
}

.Pet-Unicorn-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Unicorn-Zombie.png");
}

.Pet-Velociraptor-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Base.png");
}

.Pet-Velociraptor-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-CottonCandyBlue.png");
}

.Pet-Velociraptor-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-CottonCandyPink.png");
}

.Pet-Velociraptor-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Desert.png");
}

.Pet-Velociraptor-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Golden.png");
}

.Pet-Velociraptor-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Red.png");
}

.Pet-Velociraptor-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Shade.png");
}

.Pet-Velociraptor-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Skeleton.png");
}

.Pet-Velociraptor-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-White.png");
}

.Pet-Velociraptor-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Velociraptor-Zombie.png");
}

.Pet-Whale-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Base.png");
}

.Pet-Whale-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-CottonCandyBlue.png");
}

.Pet-Whale-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-CottonCandyPink.png");
}

.Pet-Whale-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Desert.png");
}

.Pet-Whale-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Golden.png");
}

.Pet-Whale-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Red.png");
}

.Pet-Whale-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Shade.png");
}

.Pet-Whale-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Skeleton.png");
}

.Pet-Whale-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-White.png");
}

.Pet-Whale-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Whale-Zombie.png");
}

.Pet-Wolf-Amber {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Amber.png");
}

.Pet-Wolf-Aquatic {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Aquatic.png");
}

.Pet-Wolf-Aurora {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Aurora.png");
}

.Pet-Wolf-AutumnLeaf {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-AutumnLeaf.png");
}

.Pet-Wolf-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Base.png");
}

.Pet-Wolf-BirchBark {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-BirchBark.png");
}

.Pet-Wolf-BlackPearl {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-BlackPearl.png");
}

.Pet-Wolf-Bronze {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Bronze.png");
}

.Pet-Wolf-Celestial {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Celestial.png");
}

.Pet-Wolf-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-CottonCandyBlue.png");
}

.Pet-Wolf-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-CottonCandyPink.png");
}

.Pet-Wolf-Cupid {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Cupid.png");
}

.Pet-Wolf-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Desert.png");
}

.Pet-Wolf-Dessert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Dessert.png");
}

.Pet-Wolf-Ember {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Ember.png");
}

.Pet-Wolf-Fairy {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Fairy.png");
}

.Pet-Wolf-Floral {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Floral.png");
}

.Pet-Wolf-Fluorite {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Fluorite.png");
}

.Pet-Wolf-Frost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Frost.png");
}

.Pet-Wolf-Ghost {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Ghost.png");
}

.Pet-Wolf-Glass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Glass.png");
}

.Pet-Wolf-Glow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Glow.png");
}

.Pet-Wolf-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Golden.png");
}

.Pet-Wolf-Holly {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Holly.png");
}

.Pet-Wolf-IcySnow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-IcySnow.png");
}

.Pet-Wolf-Koi {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Koi.png");
}

.Pet-Wolf-Moonglow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Moonglow.png");
}

.Pet-Wolf-MossyStone {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-MossyStone.png");
}

.Pet-Wolf-Onyx {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Onyx.png");
}

.Pet-Wolf-Peppermint {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Peppermint.png");
}

.Pet-Wolf-PinkMarble {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-PinkMarble.png");
}

.Pet-Wolf-PolkaDot {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-PolkaDot.png");
}

.Pet-Wolf-Porcelain {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Porcelain.png");
}

.Pet-Wolf-Rainbow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Rainbow.png");
}

.Pet-Wolf-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Red.png");
}

.Pet-Wolf-RoseQuartz {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-RoseQuartz.png");
}

.Pet-Wolf-RoyalPurple {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-RoyalPurple.png");
}

.Pet-Wolf-Ruby {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Ruby.png");
}

.Pet-Wolf-SandSculpture {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-SandSculpture.png");
}

.Pet-Wolf-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Shade.png");
}

.Pet-Wolf-Shadow {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Shadow.png");
}

.Pet-Wolf-Shimmer {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Shimmer.png");
}

.Pet-Wolf-Silver {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Silver.png");
}

.Pet-Wolf-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Skeleton.png");
}

.Pet-Wolf-SolarSystem {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-SolarSystem.png");
}

.Pet-Wolf-Spooky {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Spooky.png");
}

.Pet-Wolf-StainedGlass {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-StainedGlass.png");
}

.Pet-Wolf-StarryNight {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-StarryNight.png");
}

.Pet-Wolf-Sunset {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Sunset.png");
}

.Pet-Wolf-Sunshine {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Sunshine.png");
}

.Pet-Wolf-TeaShop {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-TeaShop.png");
}

.Pet-Wolf-Thunderstorm {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Thunderstorm.png");
}

.Pet-Wolf-Turquoise {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Turquoise.png");
}

.Pet-Wolf-Vampire {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Vampire.png");
}

.Pet-Wolf-Veggie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Veggie.png");
}

.Pet-Wolf-Veteran {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Veteran.png");
}

.Pet-Wolf-VirtualPet {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-VirtualPet.png");
}

.Pet-Wolf-Watery {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Watery.png");
}

.Pet-Wolf-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-White.png");
}

.Pet-Wolf-Windup {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Windup.png");
}

.Pet-Wolf-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Wolf-Zombie.png");
}

.Pet-Yarn-Base {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Base.png");
}

.Pet-Yarn-CottonCandyBlue {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-CottonCandyBlue.png");
}

.Pet-Yarn-CottonCandyPink {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-CottonCandyPink.png");
}

.Pet-Yarn-Desert {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Desert.png");
}

.Pet-Yarn-Golden {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Golden.png");
}

.Pet-Yarn-Red {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Red.png");
}

.Pet-Yarn-Shade {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Shade.png");
}

.Pet-Yarn-Skeleton {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Skeleton.png");
}

.Pet-Yarn-White {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-White.png");
}

.Pet-Yarn-Zombie {
  width: 81px;
  height: 99px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet-Yarn-Zombie.png");
}

.Pet_HatchingPotion_Amber {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Amber.png");
}

.Pet_HatchingPotion_Aquatic {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Aquatic.png");
}

.Pet_HatchingPotion_Aurora {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Aurora.png");
}

.Pet_HatchingPotion_AutumnLeaf {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_AutumnLeaf.png");
}

.Pet_HatchingPotion_Base {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Base.png");
}

.Pet_HatchingPotion_BirchBark {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_BirchBark.png");
}

.Pet_HatchingPotion_BlackPearl {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_BlackPearl.png");
}

.Pet_HatchingPotion_Bronze {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Bronze.png");
}

.Pet_HatchingPotion_Celestial {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Celestial.png");
}

.Pet_HatchingPotion_CottonCandyBlue {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_CottonCandyBlue.png");
}

.Pet_HatchingPotion_CottonCandyPink {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_CottonCandyPink.png");
}

.Pet_HatchingPotion_Cupid {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Cupid.png");
}

.Pet_HatchingPotion_Desert {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Desert.png");
}

.Pet_HatchingPotion_Ember {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Ember.png");
}

.Pet_HatchingPotion_Fairy {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Fairy.png");
}

.Pet_HatchingPotion_Floral {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Floral.png");
}

.Pet_HatchingPotion_Fluorite {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Fluorite.png");
}

.Pet_HatchingPotion_Frost {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Frost.png");
}

.Pet_HatchingPotion_Ghost {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Ghost.png");
}

.Pet_HatchingPotion_Glass {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Glass.png");
}

.Pet_HatchingPotion_Glow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Glow.png");
}

.Pet_HatchingPotion_Golden {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Golden.png");
}

.Pet_HatchingPotion_Holly {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Holly.png");
}

.Pet_HatchingPotion_IcySnow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_IcySnow.png");
}

.Pet_HatchingPotion_Moonglow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Moonglow.png");
}

.Pet_HatchingPotion_MossyStone {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_MossyStone.png");
}

.Pet_HatchingPotion_Onyx {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Onyx.png");
}

.Pet_HatchingPotion_Peppermint {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Peppermint.png");
}

.Pet_HatchingPotion_PinkMarble {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_PinkMarble.png");
}

.Pet_HatchingPotion_PolkaDot {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_PolkaDot.png");
}

.Pet_HatchingPotion_Porcelain {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Porcelain.png");
}

.Pet_HatchingPotion_Purple {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Purple.png");
}

.Pet_HatchingPotion_Rainbow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Rainbow.png");
}

.Pet_HatchingPotion_Red {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Red.png");
}

.Pet_HatchingPotion_RoseQuartz {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_RoseQuartz.png");
}

.Pet_HatchingPotion_RoyalPurple {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_RoyalPurple.png");
}

.Pet_HatchingPotion_Ruby {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Ruby.png");
}

.Pet_HatchingPotion_SandSculpture {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_SandSculpture.png");
}

.Pet_HatchingPotion_Shade {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shade.png");
}

.Pet_HatchingPotion_Shadow {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shadow.png");
}

.Pet_HatchingPotion_Shimmer {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Shimmer.png");
}

.Pet_HatchingPotion_Silver {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Silver.png");
}

.Pet_HatchingPotion_Skeleton {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Skeleton.png");
}

.Pet_HatchingPotion_SolarSystem {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_SolarSystem.png");
}

.Pet_HatchingPotion_Spooky {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Spooky.png");
}

.Pet_HatchingPotion_StainedGlass {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_StainedGlass.png");
}

.Pet_HatchingPotion_StarryNight {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_StarryNight.png");
}

.Pet_HatchingPotion_Sunset {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Sunset.png");
}

.Pet_HatchingPotion_Sunshine {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Sunshine.png");
}

.Pet_HatchingPotion_TeaShop {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_TeaShop.png");
}

.Pet_HatchingPotion_Thunderstorm {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Thunderstorm.png");
}

.Pet_HatchingPotion_Turquoise {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Turquoise.png");
}

.Pet_HatchingPotion_Vampire {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Vampire.png");
}

.Pet_HatchingPotion_Watery {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Watery.png");
}

.Pet_HatchingPotion_White {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_White.png");
}

.Pet_HatchingPotion_Zombie {
  width: 68px;
  height: 68px;
  background-image: url("https://habitica-assets.s3.amazonaws.com/mobileApp/images/Pet_HatchingPotion_Zombie.png");
}

.avatar-container {
  height: 100px;
  width: 100px;
}

.avatar-container .avatar {
  width: 141px;
  height: 147px;
  image-rendering: pixelated;
  cursor: pointer;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  position: relative;
  -webkit-transform: scale(.680272);
  transform: scale(.680272);
}

.avatar-container .avatar .character-sprites {
  margin: 0 auto 0 24px;
}

.avatar-container .avatar .character-sprites > span {
  position: absolute;
}

.avatar-container .avatar .character-sprites .current-pet {
  bottom: 0;
  left: 0;
}

.member-details .bg-health {
  background: #f74e52;
}

.member-details .bg-experience {
  background: #ffbe5d;
}

.member-details .bg-mana {
  background: #50b5e9;
}

.member-details .stats-bar .label {
  width: 30px;
  display: inline-block;
}

.member-details .stats-bar .progress {
  width: 100px;
  height: 10px;
  background-color: #271b3d;
  display: inline-block;
}

.member-details .stats-bar .progress .progress-bar {
  height: 10px;
}

.member-details .stats-bar .value {
  margin-left: 8px;
}

.member-details .icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-size: contain;
  display: inline-block;
}

.member-details .icon.class.healer {
  background-image: url("healer.3fee1a28.svg");
}

.member-details .icon.class.rogue {
  background-image: url("rogue.74e651f5.svg");
}

.member-details .icon.class.warrior {
  background-image: url("warrior.3772ab36.svg");
}

.member-details .icon.class.wizard {
  background-image: url("wizard.2766ce49.svg");
}

.member-details .icon.balance {
  background-image: url("icons8-dollar-coin-32.d814d35f.png");
}

.member-details .icon.gold {
  background-image: url("gold.b4b13e34.svg");
}

.member-details .icon.gem {
  background-image: url("gem.5ac6c902.svg");
}

.member-details .icon.sword {
  background-image: url("sword.00e59af5.svg");
}

.member-details .name {
  font-weight: bold;
}

.member-details .name.perfect {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-animation: 2s infinite rainbow;
  animation: 2s infinite rainbow;
}

@-webkit-keyframes rainbow {
  0% {
    background-image: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  }

  11% {
    background-image: linear-gradient(to right, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350);
  }

  22% {
    background-image: linear-gradient(to right, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1);
  }

  33% {
    background-image: linear-gradient(to right, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2);
  }

  44% {
    background-image: linear-gradient(to right, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3);
  }

  55% {
    background-image: linear-gradient(to right, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da);
  }

  66% {
    background-image: linear-gradient(to right, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047);
  }

  77% {
    background-image: linear-gradient(to right, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41);
  }

  88% {
    background-image: linear-gradient(to right, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825);
  }
}

@keyframes rainbow {
  0% {
    background-image: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  }

  11% {
    background-image: linear-gradient(to right, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350);
  }

  22% {
    background-image: linear-gradient(to right, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1);
  }

  33% {
    background-image: linear-gradient(to right, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2);
  }

  44% {
    background-image: linear-gradient(to right, #26c6da, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3);
  }

  55% {
    background-image: linear-gradient(to right, #43a047, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da);
  }

  66% {
    background-image: linear-gradient(to right, #eeff41, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047);
  }

  77% {
    background-image: linear-gradient(to right, #f9a825, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41);
  }

  88% {
    background-image: linear-gradient(to right, #ff5722, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825);
  }
}

#overview {
  grid-template: "nights" 200px
                 "quest" 120px
                 "lock"
                 "user-0"
                 "user-1"
                 "user-2"
                 "user-3"
                 / auto;
  margin-bottom: 90px;
  display: grid;
}

@media (min-width: 490px) {
  #overview {
    grid-template-rows: 100px 120px auto auto auto auto;
  }
}

@media (min-width: 1000px) {
  #overview {
    grid-template: "nights user-0 user-1 quest"
                   "nights user-2 user-3 lock"
                   / 120px auto auto 140px;
  }

  #overview .nights {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  #overview .nights .night {
    position: relative;
  }

  #overview .nights .night .day-of-the-week {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: rotate(-90deg)translateX(-70px);
    transform: rotate(-90deg)translateX(-70px);
  }

  #overview .nights .night .pic {
    margin-left: 30px;
  }
}

#overview > .nights {
  grid-area: nights;
}

#overview > .quest {
  grid-area: quest;
}

#overview > .lock {
  grid-area: lock;
}

#overview > .user-0 {
  grid-area: user-0;
}

#overview > .user-1 {
  grid-area: user-1;
}

#overview > .user-2 {
  grid-area: user-2;
}

#overview > .user-3 {
  grid-area: user-3;
}

#overview .nights .night {
  color: #fff;
  display: inline-block;
}

#overview .nights .night .day-of-the-week {
  width: 70px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
}

#overview .nights .night .pic {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

#overview .nights .night.previous-week {
  color: gray;
}

#overview .nights .night.previous-week .pic {
  opacity: .3;
}

#overview .user-panel {
  min-height: 200px;
  padding: 5px;
}

#overview .user-panel .tasks {
  vertical-align: baseline;
  margin: 10px 0;
}

#overview .user-panel .task-icon-container {
  height: 32px;
  width: 32px;
  margin: 5px;
  display: inline-block;
  position: relative;
}

#overview .user-panel .task-icon-container .badge {
  height: 19.2px;
  width: 19.2px;
  position: absolute;
  top: -5.76px;
  right: -5.76px;
}

#overview .user-panel .task-icon-container .badge.pending {
  background: url("blue_circle_lock_privacy_safe_icon.e60bd071.svg");
}

#overview .user-panel .task-icon-container .badge.completed {
  background: url("approved_check_checkbox_checkmark_confirm_icon.2195c441.svg");
}

#overview .user-panel .task-icon-container .badge.down {
  background: url("cancel_cercle_close_delete_dismiss_icon.9bab0241.svg");
}

#overview .user-panel .task-icon {
  height: 32px;
  width: 32px;
  line-height: 32px;
  overflow: hidden;
}

#overview .user-panel .task-icon.img {
  vertical-align: baseline;
}

#overview .user-panel .task-icon.emoji {
  text-align: center;
  font-size: 25.6px;
}

#overview .user-panel .task-icon.letter {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

#overview .quest {
  grid-template: "boss-pic boss-name" 2em
                 "boss-pic boss-hp"
                 / 120px auto;
  display: grid;
}

#overview .quest .boss {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: boss-pic;
}

#overview .quest .boss-name {
  text-align: center;
  vertical-align: middle;
  grid-area: boss-name;
  line-height: 2em;
}

#overview .quest .stats-bar .label {
  width: 30px;
  display: inline-block;
}

#overview .quest .stats-bar .progress {
  height: 10px;
  width: 100%;
  display: inline-block;
}

#overview .quest .stats-bar .progress .progress-bar {
  height: 10px;
}

#overview .quest .stats-bar .value {
  text-align: center;
  display: block;
}

#overview .quest .boss-health {
  grid-area: boss-hp;
}

#overview .quest .boss-health .stats-bar .progress {
  background: #ffb445;
}

#overview .quest .boss-health .stats-bar .progress .progress-bar {
  background: #f74e52;
}

#overview .quest .quest-collect {
  display: flex;
}

#overview .quest .quest-collect .stats-bar {
  flex: 1;
}

#overview .quest .quest-collect .stats-bar .progress {
  background: #4e4a57;
}

#overview .quest .quest-collect .stats-bar .progress .progress-bar {
  background: #24cc8f;
}

#overview .quest .quest-collect .quest-item-icon {
  width: 32px;
  height: 32px;
  background-size: cover;
  margin-right: 10px;
}

@media (min-width: 1000px) {
  #overview .quest {
    grid-template: "boss-pic" 120px
                   "boss-name" max-content
                   "boss-hp"
                   / auto;
    padding-left: 20px;
  }
}

#overview .lock .icon {
  width: 64px;
  height: 64px;
  background-size: cover;
  margin: auto;
}

#overview .lock .icon.locked {
  background-image: url("Paomedia-Small-N-Flat-Lock.160e45aa.svg");
}

#overview .lock .icon.unlocked {
  background-image: url("Paomedia-Small-N-Flat-Lock-open.ec801a9c.svg");
}

#overview .lock .timer {
  width: 100px;
  margin: 10px auto 0;
}

#user-page a {
  text-decoration: none !important;
}

#user-page .tasks-table {
  border-spacing: 5px;
  border-collapse: separate;
  color: #fff;
}

#user-page .tasks-table td {
  padding: 0;
}

#user-page .tasks-table .section {
  padding-left: 10px;
  font-size: 24px;
}

@media (min-width: 500px) {
  #user-page .tasks-table .section {
    font-size: 40px;
  }
}

#user-page .tasks-table .dow {
  text-align: center;
  padding: 0;
  font-size: 16px;
}

#user-page .tasks-table .task {
  height: 40px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 40px;
}

#user-page .tasks-table .task .task-name {
  height: 40px;
  width: 70px;
  padding-left: 10px;
  overflow: visible;
}

#user-page .tasks-table .task .task-name p {
  margin: 0;
}

#user-page .tasks-table .task .task-name img {
  max-width: 32px;
  max-height: 32px;
}

#user-page .tasks-table .task .day {
  text-align: center;
  width: 40px;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
}

#user-page .tasks-table .task .day.empty, #user-page .tasks-table .task .day .today {
  background-color: #ffdfae;
}

#user-page .tasks-table .task .day.empty.skip {
  background-color: rgba(0, 0, 0, 0);
}

#user-page .tasks-table .task .day .up, #user-page .tasks-table .task .day .down {
  height: 100%;
}

#user-page .tasks-table .task .day .up {
  background-color: #24cc8f;
}

#user-page .tasks-table .task .day .down {
  background-color: #db2955;
}

#user-page .tasks-table .task .day .today, #user-page .tasks-table .task .day .completed, #user-page .tasks-table .task .day .is-due {
  height: 100%;
}

#user-page .tasks-table .task .day .completed {
  background-color: #24cc8f;
}

#user-page .tasks-table .task .day .is-due {
  background-color: #db2955;
}

#user-page .header {
  display: flex;
}

#user-page .header .back {
  height: 100px;
  width: 100px;
  text-align: center;
  vertical-align: middle;
  font-size: 70px;
  line-height: 100px;
}

#user-page {
  width: 100%;
  min-height: 100%;
  grid-template: "header" 115px
                 "table-todo"
                 "table-habit"
                 "table-daily"
                 / auto;
  margin-bottom: 90px;
  display: grid;
}

@media (min-width: 1000px) {
  #user-page {
    grid-template: "header table-todo" 115px
                   "table-habit table-daily"
                   / calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
  }
}

#user-page .header {
  grid-area: header;
  align-items: center;
}

#user-page > .table-daily {
  grid-area: table-daily;
  overflow: hidden;
}

#user-page > .table-habit {
  grid-area: table-habit;
  overflow: hidden;
}

#user-page > .table-todo {
  grid-area: table-todo;
  overflow: hidden;
}

.DialogOverlay {
  -webkit-animation: .15s cubic-bezier(.16, 1, .3, 1) overlayShow;
  animation: .15s cubic-bezier(.16, 1, .3, 1) overlayShow;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.DialogContent {
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  background-color: #5c418b;
  border-radius: 6px;
  padding: 25px;
  -webkit-animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 38px -10px rgba(14, 18, 22, .35), 0 10px 20px -15px rgba(14, 18, 22, .2);
}

.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  color: #fff;
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}

.DialogDescription {
  color: #fff;
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 1.5;
}

.Button {
  height: 35px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
}

.Button.violet {
  box-shadow: 0 2px 10px var(--blackA7);
  background-color: #fff;
}

.Button.violet:focus {
  box-shadow: 0 0 0 2px #000;
}

.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green7);
}

.IconButton {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  display: inline-flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.IconButton:focus {
  box-shadow: 0 0 0 2px var(--violet7);
}

.Fieldset {
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  display: flex;
}

.Label {
  width: 90px;
  text-align: right;
  font-size: 15px;
}

.Input {
  width: 100%;
  box-shadow: 0 0 0 1px var(--violet7);
  height: 35px;
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  display: inline-flex;
}

.Input:focus {
  box-shadow: 0 0 0 2px var(--violet8);
}

@-webkit-keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes contentShow {
  from {
    opacity: 0;
    -webkit-transform: translate(-50%, -48%)scale(.96);
    transform: translate(-50%, -48%)scale(.96);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%)scale(1);
    transform: translate(-50%, -50%)scale(1);
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    -webkit-transform: translate(-50%, -48%)scale(.96);
    transform: translate(-50%, -48%)scale(.96);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%)scale(1);
    transform: translate(-50%, -50%)scale(1);
  }
}

.task-name img {
  vertical-align: middle;
}

/*# sourceMappingURL=index.2bff2fb2.css.map */
