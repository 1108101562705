.bg-health {
  background: $red-50;
}

.bg-experience {
  background: $yellow-100;
}

.bg-mana {
  background: $blue-100;
}