@use 'sass:math';

#bank-page {
  a {
    text-decoration: none !important;
  }

}

$header-h: 100px; // same as avatar size
#bank-page {
  .header {
    display: flex;

    .back {
      height: $header-h;
      line-height: $header-h;
      width: $header-h;
      font-size: $header-h * 0.7;
      text-align: center;
      vertical-align: middle;
    }
  }
}

#bank-page {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'details';
  grid-template-columns: auto;
  grid-template-rows: $header-h auto;

  // fix scroll
  margin-bottom: 90px;

  .header {
    grid-area: header;
    align-items: center;
  }

  & > .bank-details {
    grid-area: details;
  }
}
